import {Link} from 'react-router-dom';
import {Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const StyledBreadcrumbs = styled(Breadcrumbs)(({theme}) => ({
  padding: '24px 0px',
  '& a': {
    padding: '0',
    color:
      theme.palette.mode === 'light'
        ? theme.palette.primary.dark
        : theme.palette.primary.light,
  },
  '& a:focus': {
    outline: `solid 2px  ${theme.palette.primary.main}`,
    //padding: '5px',
  },
  '& .MuiBreadcrumbs-separator': {
    color: theme.palette.mode === 'light' ? 'black' : 'white',
  },
}));

const StyledListItem = styled(Typography)(({theme}) => ({
  color: theme.palette.text.primary,
}));

const AppBreadcrumb = ({dataList, showHome}) => {
  const theme = useTheme();
  return (
    <StyledBreadcrumbs
      aria-label='breadcrumb'
      separator={
        // <FiberManualRecordIcon
        //   sx={{
        //     fontSize: '8px',
        //     color:
        //       theme.palette.mode === 'dark' ? 'white' : theme.palette.grey.main,
        //   }}
        // />
        <HorizontalRuleIcon
          sx={{
            margin: '0px -4px',
            fontSize: 'medium',
            transform: 'rotate(120deg) scale(1.25)',
            color:
              theme.palette.mode === 'dark' ? 'white' : theme.palette.grey.main,
          }}
        />
      }
      paddingTop={{xs: 1.5}}
      paddingBottom={{xs: 2}}>
      {showHome && <Link to='/'>Home</Link>}
      {dataList &&
        dataList.map((item, idx) => {
          if (item.name && item.path) {
            return (
              <Typography variant='body1'>
                <Link to={item.path} key={idx}>
                  {item.name}
                </Link>
              </Typography>
            );
          }
          if (item.name && item.goto) {
            return (
              <Typography variant='body1'>
                <Link to={item.goto} key={idx}>
                  {item.name}
                </Link>
              </Typography>
            );
          }
          if (item.name && !item.goto && !item.path) {
            return (
              <StyledListItem variant='body1' key={idx}>
                {item.name}
              </StyledListItem>
            );
          }
        })}
    </StyledBreadcrumbs>
  );
};

export default AppBreadcrumb;

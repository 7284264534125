import * as TYPES from './constants';
import {API} from 'config';

import {
  requestCourses,
  recieveCourses,
  recieveActivePaths,
  requestCoursesError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.COURSES_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.COURSES_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.COURSES_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchCourses = () => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
      pathways: {
        activePaths: {data},
      },
    } = getState();
    let endPoint = `${API.gps.student_onboard}`;
    if (token) {
      dispatch(requestCourses());
      let activePath = data;
      if (!activePath) {
        activePath = await services.fetchRecords(endPoint, token);
        dispatch(recieveActivePaths(activePath));
      }
      if (activePath && activePath.length) {
        endPoint = `${API.srm.courses}?institution_uuid=${activePath[0].institute_uuid}`;
        const courses = await services.fetchRecords(endPoint, null);
        dispatch(recieveCourses(courses));
        return;
      }
      dispatch(requestCoursesError({error: 'something went wrong'}));
    }
  } catch (err) {
    console.log(err);
    dispatch(requestCoursesError(err));
  }
};

import {ExpandMore} from '@mui/icons-material';
import {Accordion, BackgroundPaper, themeColorMode} from 'mui-core';
import {
  Box,
  styled,
  Divider,
  useTheme,
  Container,
  Typography,
} from '@mui/material';

const CustomAccordion = styled(Accordion)(({theme}) => ({
  margin: '0px',
  width: '100%',
  padding: '0px',
  boxShadow: 'none',
  '& .MuiAccordionSummary-root': {
    padding: '0px',
    paddingLeft: '0px',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .MuiAccordionDetails-root': {
    paddingTop: '0px',
    paddingLeft: '16px',
  },
}));

const Faq = ({data = []}) => {
  const theme = useTheme();
  return (
    <>
      {Array.isArray(data) && data?.length > 0 ? (
        <Box
          pb={10}
          width='100%'
          borderRadius={4}
          className='faq-wrapper'
          sx={{
            backgroundColor: themeColorMode(
              theme,
              theme.palette.background.paper,
              theme.palette.dialogDarkBG.main,
            ),
          }}>
          <Container sx={{maxWidth: '970px !important'}}>
            <Typography
              mb='56px'
              component='h2'
              textAlign='center'
              variant='header-md-bold'
              color={themeColorMode(
                theme,
                theme.palette.shadeyBlack.main,
                theme.palette.midGray.main,
              )}>
              Frequently Asked Questions
            </Typography>
            <BackgroundPaper
              pb={2}
              borderRadius={4}
              sx={{
                maxHeight: 600,
                overflowY: 'scroll',
                'scrollbar-width': 'none',
                '-ms-overflow-style': 'none' /* IE and Edge */,
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
              background={theme.palette.grey.contrastTex}>
              {data?.map((paragraph, index) => (
                <Box key={index}>
                  <CustomAccordion
                    defaultOpen={true}
                    titleDivider={false}
                    expandIcon={<ExpandMore />}
                    sx={{background: 'transparent', px: 0}}
                    header={
                      <Typography
                        component='p'
                        variant='title-md-bold'
                        color={themeColorMode(
                          theme,
                          theme.palette.shadeyBlack.main,
                          theme.palette.midGray.main,
                        )}>
                        {paragraph.question}
                      </Typography>
                    }>
                    <Typography
                      py={1}
                      component='p'
                      textAlign='left'
                      variant='text-lg-regular'
                      color={themeColorMode(
                        theme,
                        theme.palette.shadeyBlack.main,
                        theme.palette.grey.main,
                      )}
                      dangerouslySetInnerHTML={{
                        __html: paragraph.answer,
                      }}
                    />
                  </CustomAccordion>
                  {index !== data.length - 1 && <Divider />}
                </Box>
              ))}
            </BackgroundPaper>
          </Container>
        </Box>
      ) : null}
    </>
  );
};

export default Faq;

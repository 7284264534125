import {combineReducers} from 'redux';

import carrerInterestSurvey from './carrerInterestSurvey';
import goSurveyQuestion from './goSurveyQuestion';
import goSurveyResults from './goSurveyResults';
import goSurveyAnswers from './goSurveyAnswers';
import goSurveyResultDetails from './goSurveyResultDetails';

export const surveyReducer = combineReducers({
  goSurveyResultDetails,
  carrerInterestSurvey,
  goSurveyQuestion,
  goSurveyResults,
  goSurveyAnswers,
});

import * as TYPES from './constants';
import {API} from 'config';
import {
  requestPrivacySetting,
  recievePrivacySetting,
  requestPrivacySettingError,
  requestSavePrivacySetting,
  recieveSavePrivacySetting,
  requestSavePrivacySettingError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, saverequest: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_PRIVACY_SETTING:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_PRIVACY_SETTING:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_PRIVACY_SETTING_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.REQUEST_SAVE_PRIVACY_SETTING:
      return {
        ...state,
        saverequest: true,
        saveerror: false,
      };
    case TYPES.RECIEVE_SAVE_PRIVACY_SETTING:
      return {
        ...state,
        saverequest: false,
        saveerror: false,
        data: action.payload,
      };
    case TYPES.REQUEST_SAVE_PRIVACY_SETTING_ERROR:
      return {
        ...state,
        saverequest: false,
        saveerror: false,
      };
    default:
      return state;
  }
};

export const fetchPrivacySetting = (userName = '') => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();

  let endPoint = userName
    ? `${API.gps.public_privacy_settings}?username=${userName}`
    : `${API.gps.privacysetting}`;
  dispatch(requestPrivacySetting());
  try {
    return services.fetchRecords(endPoint, token).then(res => {
      if (res) {
        dispatch(recievePrivacySetting(res));
        return;
      }
      dispatch(requestPrivacySettingError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestPrivacySettingError(err));
  }
};

export const updatePrivacySetting = (privacy_uuid, body, callback) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    let endPoint = `${API.gps.privacysetting}${privacy_uuid}`;
    dispatch(requestSavePrivacySetting());
    return services
      .createUpdateRecord(token, endPoint, body, 'PATCH')
      .then(res => {
        if (callback) callback(res);
        dispatch(recieveSavePrivacySetting(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestSavePrivacySettingError(err));
  }
};

import {API} from 'config';
import * as TYPES from './constants';

import {
  errorChangePassword,
  requestChangePassword,
  receiveChangePassword,
} from './actions';

import {Services} from 'core/Services';

const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_CHANGE_PASSWORD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECEIVED_CHANGE_PASSWORD:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.ERROR_CHANGE_PASSWORD:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updatePassword = (student_uuid, body, callback) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    const endPoint = `${API.gps.change_password}${student_uuid}`;
    dispatch(requestChangePassword());
    return services
      .createUpdateRecord(token, endPoint, body, 'PATCH')
      .then(res => {
        if (callback) callback(res);
        dispatch(receiveChangePassword(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(errorChangePassword(err));
  }
};

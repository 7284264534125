export const getPrograms = state =>
  state.programs && state.programs.ProgramsData;

export const getProgramDetail = state =>
  state.programs && state.programs.ProgramDetail;

export const getSignedUpPrograms = state =>
  state.programs && state.programs.SignedUpPrograms;

export const getSavedPrograms = state =>
  state.programs && state.programs.SavedPrograms;

export const getComparedPrograms = state =>
  state.programs && state.programs.ComparePrograms;

export const getCareerAreaList = state => {
  let finalData = [];
  let activePathsCount = 0;
  const careerAreas =
    (state.programs && state.programs.CareerAreaList?.data) || [];
  const totalCount = (careerAreas && careerAreas?.length) || 0;

  if (totalCount > 0) {
    finalData = careerAreas.sort((a, b) =>
      a.cluster_name > b.cluster_name ? 1 : -1,
    );
    activePathsCount = finalData?.reduce((counter, value) => {
      if (value?.active_path_count > 0) counter += value?.active_path_count;
      return counter;
    }, 0);
    if (activePathsCount > 0) {
      return finalData;
    }
    return [];
  }
  return [];
};

export const getAllCareerAreaListLoader = state => {
  return state.programs.CareerAreaList?.request;
};

export const getAllCareerAreaList = state => {
  let finalData = [];
  const careerAreas =
    (state.programs && state.programs.CareerAreaList?.data) || [];
  const totalCount = (careerAreas && careerAreas?.length) || 0;

  if (totalCount > 0) {
    finalData = careerAreas.sort((a, b) =>
      a.cluster_name > b.cluster_name ? 1 : -1,
    );
    return finalData;
  }
  return [];
};

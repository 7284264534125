import * as TYPES from './constants';

// INSTANCE ACTIONS
export const requestMetrics = () => ({
  type: TYPES.METRICS_REQUEST,
});
export const recieveMetrics = json => ({
  type: TYPES.METRICS_SUCCESS,
  payload: json,
});
export const errorMetrics = err => ({
  type: TYPES.METRICS_ERROR,
  err,
});
export const clearMetrics = () => ({
  type: TYPES.CLEAR_METRICS,
});

// Testimonials Actions
export const requestTestimonials = () => ({
  type: TYPES.REQUEST_TESTIMONIALS,
});
export const recieveTestimonials = json => ({
  type: TYPES.TESTIMONIALS_SUCCESS,
  payload: json,
});
export const testimonialsError = err => ({
  type: TYPES.TESTIMONIALS_ERROR,
  err,
});
export const clearTestimonials = () => ({
  type: TYPES.CLEAR_TESTIMONIALS,
});

// Empowering Communities Actions
export const requestEmpoweringCommunities = () => ({
  type: TYPES.REQUEST_EMPOWERING_COMMUNITIES,
});
export const recieveEmpoweringCommunities = json => ({
  type: TYPES.EMPOWERING_COMMUNITIES_SUCCESS,
  payload: json,
});
export const empoweringCommunitiesError = err => ({
  type: TYPES.EMPOWERING_COMMUNITIES_ERROR,
  err,
});
export const clearEmpoweringCommunities = () => ({
  type: TYPES.CLEAR_EMPOWERING_COMMUNITIES,
});

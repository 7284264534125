import {useAuth} from 'core/hooks';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {goResume} from 'data/goResume.json';
import {openLoginScreen} from 'redux/modules/auth';
import {
  Box,
  Stack,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ATSIcon,
  CloudIcon,
  CheckDoneIcon,
  StripedBriefCase,
} from 'mui-core/Icons';
import {ArrowRight, goResumeVideoBanner} from 'assets/images';
import {Faq, VideoBanner, BackgroundPaper, LayoutStaticBanner} from 'mui-core';

const {
  faq,
  banners = [],
  purpleBanner = [],
  goResumeBanner: {title = '', description = ''} = {},
} = goResume;
const Icons = [StripedBriefCase, ATSIcon, CloudIcon, CheckDoneIcon];

const updatedArray = purpleBanner.map((item, index) => ({
  ...item,
  SVGIcon: Icons[index],
}));

const GOResume = () => {
  const theme = useTheme();
  const [token] = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenLogin = () => {
    if (!token) {
      dispatch(
        openLoginScreen({
          callback: () => history.push('/resume-builder'),
        }),
      );
    } else {
      history.push('/resume-builder');
    }
  };

  return (
    <BackgroundPaper>
      <VideoBanner bannerURL={goResumeVideoBanner}>
        <Stack gap={{xs: 2, md: 5}}>
          <Typography
            color='white'
            component='h1'
            variant='header-lg-bold'
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Typography
            color='white'
            component='p'
            variant='text-xl-regular'
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Stack>
      </VideoBanner>
      <Stack mt={{xs: 2, sm: 5, md: '100px'}}>
        <Stack gap={{xs: '32px', md: '150px'}}>
          {banners.map((banner, index) => (
            <LayoutStaticBanner
              key={banner.key}
              gap={{xs: 4, md: 6}}
              buttonEndIcon={ArrowRight}
              button={banner.buttonLabel}
              imageFirst={banner.imageFirst}
              imageWidth={banner.imageWidth}
              buttonAction={handleOpenLogin}
              sectionPadding={banner.sectionPadding}
              backgroundColor={banner.backgroundColor}
              textContentWidth={banner.textContentWidth}
              padding={banner.imageFirst && '0px 0px 0px 0px'}
              detail={{
                heading: banner.title,
                subHeading: banner.description,
                headingColor: banner.headingColor,
                descriptionColor: banner.descriptionColor,
                imgpath:
                  index === 0
                    ? theme.palette.mode === 'light'
                      ? banner.image
                      : banner.imageDark
                    : banner.image,
              }}
            />
          ))}
          <Box width='100%'>
            <Container maxWidth='lg'>
              <Stack
                gap={5}
                padding={5}
                borderRadius={3}
                justifyContent='center'
                direction={{xs: 'column', md: 'row'}}
                boxShadow='0px 24px 48px -12px #1018282E'
                sx={{
                  backgroundImage: `linear-gradient(270deg, #5E92F4 0%, #A290F6 76%)`,
                }}>
                {updatedArray?.map(({title, desc, SVGIcon}, index) => {
                  return (
                    <Stack gap={{xs: 2, md: 4}} key={index}>
                      <Stack
                        bgcolor='white'
                        borderRadius='50%'
                        alignItems='center'
                        justifyContent='center'
                        mx={{xs: 'auto', md: '60px'}}
                        width={isMobile ? '40px' : '72px'}
                        height={isMobile ? '40px' : '72px'}>
                        <SVGIcon
                          endColor='#A290F6'
                          startColor='#5E92F4'
                          fontSize={isMobile ? '24px' : '40px'}
                        />
                      </Stack>
                      <Stack
                        gap={{xs: 2, md: 3}}
                        alignItems={{xs: 'center', md: 'flex-start'}}>
                        <Typography
                          color='white'
                          component='h6'
                          variant='title-sm-bold'>
                          {title}
                        </Typography>
                        <Typography
                          color='white'
                          component='p'
                          variant='text-md-regular'>
                          {desc}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Container>
          </Box>
          <Faq data={faq} />
        </Stack>
      </Stack>
    </BackgroundPaper>
  );
};

export default GOResume;

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {numberInUSFormat} from 'core/utils';
import {
  Box,
  Paper,
  Stack,
  Divider,
  useTheme,
  Typography,
  CardActionArea,
} from '@mui/material';
import {CLOUDINARY} from 'core/cloudinary';
import {AdvancedImage} from '@cloudinary/react';
import {LocationPinIcon, BuildingIcon} from 'assets/images';
import {
  Card,
  SvgIcon,
  LazyImage,
  ErrorBoundary,
  themeColorMode,
} from 'mui-core';

const CompanyLogo = styled(Box)(({theme, leftLogo}) => {
  return leftLogo
    ? {
        left: '24px',
        top: '-34px',
        position: 'relative',
        '& img': {
          width: 72,
          height: 72,
          maxWidth: '100%',
          objectFit: 'contain',
          borderRadius: '100%',
          backgroundColor: theme.palette.background.paper,
        },
      }
    : {
        '& img': {
          width: 40,
          height: 40,
          maxWidth: '100%',
          objectFit: 'contain',
          borderRadius: '100%',
          backgroundColor: theme.palette.background.paper,
        },
      };
});

const StyledTypography = styled(Typography)(({}) => ({
  overflow: 'hidden',
  color: 'text.primary',
  display: '-webkit-box',
  wordBreak: 'break-all',
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': '1',
  '-webkit-box-orient': 'vertical',
}));

const renderInfoItem = (infoItems, textColor) => {
  const {Icon, value, count = 0, imgIcon = null, label = ''} = infoItems || {};
  let countValue = count;
  if (countValue >= 10000) {
    countValue = '10,000+';
  } else {
    countValue = numberInUSFormat(count);
  }
  return (
    <Stack direction='row' spacing={0.5} flexWrap='wrap' alignItems='center'>
      {Icon && (
        <Icon
          sx={{width: 16, height: 16, marginBottom: '4px', color: textColor}}
        />
      )}
      {imgIcon && imgIcon}
      <Typography variant='label-sm' color={theme => theme.palette.teal.main}>
        {countValue}
      </Typography>
      <Typography
        variant='label-sm'
        color={theme =>
          themeColorMode(
            theme,
            theme.palette.Gray600.main,
            theme.palette.iconLightColor.main,
          )
        }>
        {label}
      </Typography>
    </Stack>
  );
};

const CompanyCard = ({
  data,
  width,
  children,
  infoItems,
  verifiedIcon,
  leftLogo = false,
  target = '_blank',
  centeredLogo = true,
  enableNavigation = false,
}) => {
  const {
    path,
    title,
    companyLogo,
    companyName,
    companyBanner,
    location = '',
    industrySector = '',
    physicalAddress: {address: {city = '', state = ''} = {}} = {},
  } = data || {};

  const theme = useTheme();
  const history = useHistory();
  const {
    location: {pathname},
  } = history;

  const handleNavigation = () => {
    if (target === '_self' || pathname === '/') {
      history.push(path);
    } else {
      window.open(path, target);
    }
  };

  const themeTextColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey.main
      : theme.palette.grey.contrastText;

  const logoImage = CLOUDINARY.getLogoImage(companyLogo);

  return (
    <ErrorBoundary nameOfComponent='CompanyCard'>
      <Card
        sx={{
          width,
          textAlign: 'left',
          position: 'relative',
          display: 'inline-block',
        }}
        component={CardActionArea}
        onClick={() => enableNavigation && handleNavigation()}>
        <Box height={180}>
          <LazyImage renderSrcSet alt='company-banner' src={companyBanner} />
        </Box>
        {leftLogo && (
          <CompanyLogo leftLogo={leftLogo}>
            <LazyImage
              src={companyLogo}
              alt='company-logo'
              imageType='cardLogo'
            />
          </CompanyLogo>
        )}
        {centeredLogo && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Paper
              sx={{
                width: 200,
                height: 60,
                padding: '5px',
                display: 'flex',
                marginTop: '-30px',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <AdvancedImage
                width='auto'
                height='100%'
                cldImg={logoImage}
                alt={`${companyName}-Company-logo`}
                style={{maxWidth: '100%', objectFit: 'contain'}}
              />
            </Paper>
          </Box>
        )}
        <Stack
          mx={3}
          my={2}
          spacing={1}
          height={centeredLogo ? 96 : 130}
          sx={{marginTop: leftLogo ? '-16px' : '16px'}}>
          {title && (
            <StyledTypography
              component='h4'
              title={title || ''}
              color='text.primary'
              variant='text-lg-bold'>
              {title}
            </StyledTypography>
          )}
          <Stack direction='row' spacing={1} alignItems='center'>
            {!leftLogo && !centeredLogo && (
              <CompanyLogo>
                <LazyImage
                  src={companyLogo}
                  alt='company-logo'
                  imageType='cardLogo'
                />
              </CompanyLogo>
            )}
            {companyName && (
              <>
                <StyledTypography
                  component='p'
                  title={companyName || ''}
                  textTransform='capitalize'
                  variant={centeredLogo ? 'text-md-bold' : 'text-md-regular'}
                  color={themeColorMode(
                    theme,
                    theme.palette.Gray600.main,
                    theme.palette.Gray300.main,
                  )}>
                  {companyName}
                </StyledTypography>
                {/* {verifiedIcon && (
                  <Box>
                    <img
                      width={18}
                      height={18}
                      src={verifiedIcon}
                      alt='verified Checkmark icon'
                    />
                  </Box>
                )} */}
              </>
            )}
          </Stack>
          <Box sx={{my: centeredLogo ? '10px !important' : 'auto'}}>
            {industrySector && (
              <Stack direction='row' mt={1} spacing={1} alignItems='center'>
                <SvgIcon
                  src={BuildingIcon}
                  altText='building icon'
                  style={{
                    width: '20px',
                    height: '18px',
                    filter: theme.palette.iconBrandColor.main,
                  }}
                />
                <StyledTypography
                  component='p'
                  mt='4px !important'
                  variant='text-sm-regular'
                  title={industrySector || ''}
                  color={themeColorMode(
                    theme,
                    theme.palette.Gray600.main,
                    theme.palette.Gray300.main,
                  )}>
                  {industrySector || ''}
                </StyledTypography>
              </Stack>
            )}
            {(location || (city && state)) && (
              <Stack direction='row' mt={1} spacing={1} alignItems='center'>
                <SvgIcon
                  src={LocationPinIcon}
                  altText='location icon'
                  style={{
                    width: '20px',
                    height: '20px',
                    filter: theme.palette.iconBrandColor.main,
                  }}
                />
                <Typography
                  noWrap
                  component='p'
                  variant='text-sm-regular'
                  textTransform='capitalize'
                  color={themeColorMode(
                    theme,
                    theme.palette.Gray600.main,
                    theme.palette.Gray300.main,
                  )}
                  title={location ? location : `${city}, ${state}`}>
                  {location ? location : `${city}, ${state}`}
                </Typography>
              </Stack>
            )}
          </Box>
        </Stack>
        {(infoItems || children) && <Divider sx={{borderStyle: 'dashed'}} />}
        {infoItems && (
          <Stack
            mx={3}
            direction='row'
            sx={{height: 60}}
            spacing={infoItems?.length > 1 ? 3 : 0}>
            {infoItems &&
              Array.isArray(infoItems) &&
              infoItems?.length > 0 &&
              infoItems.map(infoItems =>
                renderInfoItem(infoItems, themeTextColor),
              )}
          </Stack>
        )}
        {children}
      </Card>
    </ErrorBoundary>
  );
};

CompanyCard.propTypes = {
  data: PropTypes.object,
  width: PropTypes.number,
  enableNavigation: PropTypes.bool,
};

export default CompanyCard;

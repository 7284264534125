import {SubRouter} from 'core/components';
import {Navigation as ProfileNavigation} from './components';
import {getProfileCompletedTabs} from 'core/utils';
import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  fetchSkills,
  fetchSeekingData,
  fetchProfileData,
  awardTypesSelector,
  profileDataSelector,
  fetchAwardTypes as fetchProfileAwardTypes,
  // About Me Section
  skillsSelector,
  fetchStudentCredentials,
  studentCredentialsSelector,
} from 'redux/modules/profile';

import {useSeeking} from 'core/hooks';

import {fetchAwardTypes, getAwardTypes} from 'redux/modules/search';
import {getActivePath, fetchActivePaths} from 'redux/modules/pathways';
import {Box} from '@mui/material';

const MyProfileContainer = props => {
  const {
    skills,
    profileData,
    studentCredentials,
    awardTypes,
    srmAwardTypes,
    fetchProfileAwardTypes,
    profileData: {data: student_profile, request: profileRequest},
    activePaths: {data: activePathsData = [], request: activePathsRequest} = {},
  } = props;
  const seekingData = useSeeking();
  const [ready, setReady] = useState(false);
  const [completedTabs, setCompletedTabs] = useState({});

  useEffect(() => {
    setCompletedTabs(
      getProfileCompletedTabs(profileData, studentCredentials, activePathsData),
    );
  }, [activePathsData, profileData, studentCredentials]);
  useEffect(() => {
    !student_profile && !profileRequest && fetchProfileData();
    (!srmAwardTypes?.data || !srmAwardTypes?.data?.length) &&
      !srmAwardTypes?.request &&
      fetchAwardTypes(false, true);
    (!seekingData?.data || !seekingData?.data?.length) &&
      !seekingData?.request &&
      fetchSeekingData();
    (!activePathsData || !activePathsData?.length) &&
      !activePathsRequest &&
      fetchActivePaths();
    (!awardTypes?.data || !awardTypes.data?.length) &&
      !awardTypes?.request &&
      fetchProfileAwardTypes();
    (!skills?.data || !skills.data?.length) &&
      !skills?.request &&
      fetchSkills();

    setTimeout(() => {
      setReady(true);
    }, 5000);
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <ProfileNavigation
        completedTabs={completedTabs || {}}
        ready={ready}
        awardTypes={awardTypes || []}
        srmAwardTypes={srmAwardTypes}
        student_profile={student_profile || {}}
      />
      <Box id={'profile-sub-view'}>
        <SubRouter
          modules={props.submodules}
          defaultRedirection='/dashboard/profile/details'
        />
      </Box>
      <svg style={{position: 'absolute'}}>
        {/* required for gradient svg border inside <Progress /> componet*/}
        <linearGradient id='linearColors' x1='0' y1='0' x2='1' y2='1'>
          <stop offset='30%' stopColor='#91B70C' />
          <stop offset='90%' stopColor='#C2F01D' />
        </linearGradient>
      </svg>
    </>
  );
};

const mapStateToProps = state => ({
  awardTypes: awardTypesSelector(state),
  profileData: profileDataSelector(state),
  // About Me Section
  skills: skillsSelector(state),
  activePaths: getActivePath(state),
  srmAwardTypes: getAwardTypes(state),
  studentCredentials: studentCredentialsSelector(state),
});

export default connect(mapStateToProps, {
  fetchSkills,
  fetchProfileData,
  fetchSeekingData,
  // About Me Section
  fetchAwardTypes,
  fetchActivePaths,
  fetchProfileAwardTypes,
  fetchStudentCredentials,
})(MyProfileContainer);

import * as TYPES from './constants';
import {API} from 'config';

import {recieveAwardTypes, requestAwardTypes, awardTypesError} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.STUDENT_AWARD_TYPES_LOAD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.STUDENT_AWARD_TYPES_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.STUDENT_AWARD_TYPE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchAwardTypes = () => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();

    let endPoint = `${API.gps.student_award_type}`;
    dispatch(requestAwardTypes());
    return services.fetchRecords(endPoint, token).then(res => {
      if (Array.isArray(res)) dispatch(recieveAwardTypes(res));
      else dispatch(awardTypesError());
    });
  } catch (err) {
    console.log(err);
    dispatch(awardTypesError(err));
  }
};

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {licenseTypeKeys} from 'config';
import {isArray, isEmpty} from 'lodash';
import {Box, Stack, Typography, useTheme} from '@mui/material';
import {
  SvgIcon,
  PathCard,
  Carousel,
  EventCard,
  IconButton,
  CompanyCard,
  EducatorCard,
  ErrorBoundary,
  themeColorMode,
  RequestErrorLoader,
  BusinessPartnerCard,
} from 'mui-core';
import {FactoryOutlined, BusinessCenterOutlined} from '@mui/icons-material';
import {Calendar, ArrowCircleRight, verifiedBlueCheckmark} from 'assets/images';

const CardCarousel = ({
  dataset,
  heading,
  showDots,
  autoplay,
  fullWidth,
  showArrows,
  description,
  redirectURL,
  disablePebbleShadow,
  fontType = {
    heading: {
      variant: 'h4',
    },
    description: {
      variant: 'body1',
    },
  },
  cardType = 'path',
  slidesToShow = 4,
}) => {
  const theme = useTheme();
  const {request, data: pathData = {}} = dataset || {};
  const {path_details = []} = pathData || {};

  const errorCompLabel = useMemo(() => {
    switch (heading) {
      case 'New Programs': {
        return 'home-CardCarousel-newest';
      }
      case 'Popular Programs': {
        return 'home-CardCarousel-popular';
      }
      case 'In-Demand Programs': {
        return 'home-CardCarousel-inDemand';
      }
      default: {
        return 'home-CardCarousel';
      }
    }
  }, [heading]);

  const renderContent = useMemo(() => {
    switch (cardType) {
      case 'path': {
        return path_details?.map(path => {
          const isCriminalJustice =
            path?.institute_details?.super_type_name ===
            licenseTypeKeys?.criminalJustice;
          return (
            <PathCard
              key={path?.uuid}
              enableNavigation
              data={path || {}}
              hideLocation={isCriminalJustice}
              hideAwardType={!path?.award_type_name}
            />
          );
        });
      }
      case 'educator': {
        return path_details?.map(path => (
          <EducatorCard key={path?.uuid} enableNavigation data={path || {}} />
        ));
      }
      case 'event': {
        return path_details?.map(path => (
          <EventCard key={path?.uuid} enableNavigation data={path || {}} />
        ));
      }
      case 'businessPartner': {
        return path_details?.map(path => (
          <BusinessPartnerCard
            key={path?.uuid}
            enableNavigation
            data={path || {}}
          />
        ));
      }
      case 'company': {
        return path_details?.map(path => (
          <CompanyCard
            enableNavigation
            key={path?.job_id}
            verifiedIcon={
              path?.verification === 'Approved' ? verifiedBlueCheckmark : null
            }
            data={{
              path: path.company_url,
              companyName: path?.name,
              companyLogo: path?.logo_cloudinary,
              industrySector: path?.industry_sector,
              companyBanner: path?.banner_cloudinary,
              physicalAddress: {
                address: path?.physical_address,
              },
            }}
            infoItems={[
              {
                label: 'Jobs',
                count: path?.active_jobs || 0,
                Icon: BusinessCenterOutlined,
              },
              {
                Icon: FactoryOutlined,
                label: 'Apprenticeships',
                count: path?.active_apprenticeships || 0,
              },
            ].filter(Boolean)}
          />
        ));
      }
      case 'apprenticeships': {
        return path_details?.map(path => {
          const [durationCount = '', durationLabel = ''] =
            path?.program_length.split(' ') || [];
          return (
            <CompanyCard
              leftLogo={true}
              centeredLogo={false}
              data={{
                path: path?.path,
                title: path?.job_title,
                location: path?.location,
                companyName: path?.company,
                companyBanner: path?.banner,
                companyLogo: path?.company_logo,
                industrySector: path?.company_industry_sector,
              }}
              infoItems={[
                path?.program_length && {
                  imgIcon: (
                    <SvgIcon
                      src={Calendar}
                      altText='calendar icon'
                      style={{
                        width: 16,
                        height: 16,
                        marginBottom: '2px',
                        filter: themeColorMode(
                          theme,
                          theme.palette.iconGrayColor.main,
                          theme.palette.iconLightColor.main,
                        ),
                      }}
                    />
                  ),
                  label: durationLabel,
                  count: durationCount || 0,
                  // value: path?.program_length,
                },
              ].filter(Boolean)}
              key={path?.job_id}
              target='_self'
              enableNavigation
            />
          );
        });
      }
      default: {
        return [];
      }
    }
  }, [cardType, path_details]);

  return (
    <ErrorBoundary nameOfComponent={errorCompLabel}>
      <RequestErrorLoader hideEmpty minHeight={100} body={dataset}>
        {!request &&
          path_details &&
          isArray(path_details) &&
          !isEmpty(path_details) && (
            <Stack spacing={2} my={4}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent={heading ? 'space-between' : 'flex-end'}>
                {heading && description && (
                  <Box>
                    {heading && (
                      <Typography {...fontType?.heading} color='text.primary'>
                        {heading}
                      </Typography>
                    )}
                    {description && (
                      <Typography
                        mt={1.8}
                        color='text.primary'
                        {...fontType?.description}>
                        {description}
                      </Typography>
                    )}
                  </Box>
                )}
                {heading && !description && (
                  <Typography {...fontType?.heading} color='text.primary'>
                    {heading}
                  </Typography>
                )}
                <Link to={redirectURL} style={{minWidth: '152px'}}>
                  <IconButton
                    textCapital
                    label='View More'
                    endIcon={ArrowCircleRight}
                  />
                </Link>
              </Stack>
              <Carousel
                slidesToScroll={1}
                autoplay={autoplay}
                showDots={showDots}
                fullWidth={fullWidth}
                showArrows={showArrows}
                content={renderContent}
                slidesToShow={slidesToShow}
                disablePebbleShadow={disablePebbleShadow}
              />
            </Stack>
          )}
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

CardCarousel.propTypes = {
  autoplay: PropTypes.bool,
  heading: PropTypes.string,
  cardType: PropTypes.string,
  description: PropTypes.string,
  slidesToShow: PropTypes.number,
  dataset: PropTypes.object.isRequired,
  redirectURL: PropTypes.string.isRequired,
};

export default CardCarousel;

import {combineReducers} from 'redux';

import pathDetails from './pathDetails';
import activePaths from './activePaths';
import comparePaths from './comparePaths';
import courses from './courses';
import myPlan from './myPlan';
import recommendedPlan from './recommendedPlan';
import genedOptions from './genedOptions';
import onboardTypes from './onboardTypes';
import studentOnboard from './studentOnboard';
import occupations from './occupations';
import savedPath from './savePath';
import pathwayOpportunities from './pathwayOpportunities';
export const pathwaysReducer = combineReducers({
  pathDetails,
  activePaths,
  comparePaths,
  courses,
  myPlan,
  recommendedPlan,
  genedOptions,
  onboardTypes,
  studentOnboard,
  occupations,
  savedPath,
  pathwayOpportunities,
});

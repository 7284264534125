import React from 'react';
import ReactPlayer from 'react-player';
import {VIDEO_BASEURL} from 'assets/images';
import {Typography, styled, Box} from '@mui/material';
import {videoBanner} from 'data/talent-exchange.json';

const VideoBannerWrapper = styled(Box)(({theme}) => ({
  width: '100%',
  height: '425px',
  overflow: 'hidden',
  position: 'relative',
  backgroundPosition: 'center',
  background: 'rgba(0, 0, 0, 0.87)',
  [`& .overlay`]: {
    opacity: 0.5,
    width: '100%',
    marginTop: '-12%',
    marginBottom: '-10px',
  },
  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}));

const TextWrapper = styled(Box)(({theme}) => ({
  top: '50%',
  left: '50%',
  width: '100%',
  textAlign: 'center',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
}));

const TalentExchangeBanner = ({activeCard}) => {
  const {
    title,
    employerDesc,
    jobSeekerDesc,
    employerVideo,
    jobSeekerVideo,
  } = videoBanner;
  return (
    <VideoBannerWrapper>
      <Box className='overlay'>
        <ReactPlayer
          loop
          muted
          playing
          playsinline
          width='100%'
          height='100%'
          url={`${VIDEO_BASEURL}/${
            activeCard === 'jobSeeker' ? jobSeekerVideo : employerVideo
          }`}
        />
      </Box>
      <TextWrapper>
        <Typography
          variant='h1'
          color='white'
          textAlign='center'
          lineHeight={{xs: 1.5, sm: 2}}
          fontSize={{
            xs: '24px !important',
            sm: '30px !important',
            md: '50px !important',
          }}>
          {title}
        </Typography>
        <Typography mt={1} variant='h3' color='white' textAlign='center'>
          {activeCard === 'jobSeeker' ? jobSeekerDesc : employerDesc}
        </Typography>
      </TextWrapper>
    </VideoBannerWrapper>
  );
};

export default TalentExchangeBanner;

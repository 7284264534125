import {useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {ErrorBoundary, RequestErrorLoader, NoContentNavigator} from 'mui-core';

import isString from 'lodash/isString';
import {getFilesAndTotalSize} from 'core/utils';
import {Typography, Box, Divider} from '@mui/material';
import {
  useUser,
  useSeeking,
  useAuth,
  useFormatCredentailData,
} from 'core/hooks';
import {
  fetchSeekingData,
  awardTypesSelector,
  profileDataSelector,
  fetchSharedProfileData,
  studentCredentialsSelector,
  fetchSharedStudentCredentials,
  fetchAwardTypes as fetchProfileAwardTypes,
} from 'redux/modules/profile';

import {getAppConfig} from 'redux/modules/general';
import {fetchAwardTypes, getAwardTypes} from 'redux/modules/search';
import {getActivePath, fetchActivePaths} from 'redux/modules/pathways';

import {
  Course,
  Education,
  Interests,
  Portfolio,
  Transcript,
  OverviewInfo,
  DeclaredSkills,
  WorkExperiences,
  AwardsLeadership,
  ResumeCoverLetter,
  VolunteerExperiences,
  LicenseAndCertifications,
} from './components';

import {Grid} from '@mui/material';
import styled from '@emotion/styled';
import profilePreviewData from 'data/settings-profilePreview.json';

const PreviewProfile = ({
  awardTypes,
  profileData,
  srmAwardTypes,
  fetchAwardTypes,
  fetchSeekingData,
  fetchActivePaths,
  studentCredentials,
  fetchProfileAwardTypes,
  fetchSharedProfileData,
  fetchSharedStudentCredentials,
  profileData: {data: student_profile, request: profileLoading},
  activePaths: {data: activePathsData = [], request: activePathsRequest} = {},
}) => {
  const seekingData = useSeeking();
  const {userName = ''} = useParams();
  const [isAuthenticated] = useAuth();
  const {data: student_Info} = useUser();
  const {data: document_files = []} = getFilesAndTotalSize(
    student_profile,
    'related_document',
  );
  const {data: portfolio_document_files = []} = getFilesAndTotalSize(
    student_profile,
    'portfolio_document',
  );
  const {data: transcript_document_files = []} = getFilesAndTotalSize(
    student_profile,
    'transcript',
  );

  const {data: credentialsInfo = [], request: credentialsRequest} =
    studentCredentials || {};

  let resultCertificationInfo =
    credentialsInfo &&
    Array.isArray(credentialsInfo) &&
    credentialsInfo.filter(element => {
      return element.credential_type === 'certification';
    });

  let resultCourseInfo =
    credentialsInfo &&
    Array.isArray(credentialsInfo) &&
    credentialsInfo.filter(element => {
      return element.credential_type === 'course';
    });

  const educationDataWithActivePaths = useFormatCredentailData(
    credentialsInfo,
    activePathsData,
  );

  const getAwardType = id => {
    let value = id;
    if (Array.isArray(srmAwardTypes.data)) {
      const d = srmAwardTypes.data.filter(i => i.uuid === id)[0];
      if (d) {
        value = d.options;
      }
    }
    return value;
  };

  const OverviewInfoStyle = styled(Grid)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
  }));

  const DividerColor = styled(Divider)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
  }));

  const LabelStyles = styled(Typography)(({theme}) => ({
    fontSize: 22,
    fontWeight: 400,
    marginBottom: 10,
    color: theme.palette.primary.main,
  }));

  useEffect(() => {
    !srmAwardTypes?.data &&
      !srmAwardTypes?.request &&
      fetchAwardTypes(false, true);
    (!activePathsData || !activePathsData?.length) &&
      !activePathsRequest &&
      fetchActivePaths();
    userName &&
      (!credentialsInfo || !credentialsInfo?.length) &&
      !credentialsRequest &&
      fetchSharedStudentCredentials(userName);
    !seekingData?.data && !seekingData?.request && fetchSeekingData();
    !awardTypes?.data && !awardTypes?.request && fetchProfileAwardTypes();

    const validUserName =
      userName && userName !== 'null' && userName !== 'undefined';

    !student_profile &&
      validUserName &&
      fetchSharedProfileData(userName, isAuthenticated);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <ErrorBoundary
      typeOfUi='subPage'
      nameOfComponent='module-settings-my-profile-preview'>
      <RequestErrorLoader
        hideBackground
        body={{
          data: student_profile && student_Info,
          request: profileData.request || profileLoading,
        }}
        overrideNoDataContainer={
          <NoContentNavigator
            showImg
            pathTo={'/'}
            label={`${profilePreviewData?.noContentData?.goToHomePageLabel}`}
            heading={`${profilePreviewData?.noContentData?.noContentHeading}`}
            subHeading={`${profilePreviewData?.noContentData?.noContentSubHeading} ${userName}`}
          />
        }>
        <Grid container>
          <OverviewInfoStyle
            item
            minHeight={312}
            maxWidth={1600}
            lg={12}
            xs={12}
            sm={12}
            md={12}>
            <OverviewInfo
              student_profile={student_profile}
              student_Info={student_Info}
            />
          </OverviewInfoStyle>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box m={4}>
              {student_profile?.bio && (
                <>
                  <LabelStyles variant='h4'>Professional Summary</LabelStyles>
                  <DividerColor />
                  <Box mt={4}>
                    <Typography variant='body1' fontSize={16} fontWeight={400}>
                      {student_profile.bio}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>

            <Box m={4}>
              {educationDataWithActivePaths.education &&
                Array.isArray(educationDataWithActivePaths.education) &&
                educationDataWithActivePaths.education.length > 0 && (
                  <>
                    <LabelStyles variant='h4'>My Education</LabelStyles>
                    <DividerColor />
                    <Box mt={4}>
                      <Education
                        getAwardType={getAwardType}
                        data={educationDataWithActivePaths.education}
                      />
                    </Box>
                  </>
                )}
            </Box>

            <Box m={4}>
              {resultCertificationInfo &&
                Array.isArray(resultCertificationInfo) &&
                resultCertificationInfo.length > 0 && (
                  <>
                    <LabelStyles variant='h4'>
                      License & Certifications{' '}
                    </LabelStyles>

                    <DividerColor />
                    <Box mt={4}>
                      <LicenseAndCertifications
                        getAwardType={getAwardType}
                        data={resultCertificationInfo}
                      />
                    </Box>
                  </>
                )}
            </Box>

            <Box m={4}>
              {resultCourseInfo &&
                Array.isArray(resultCourseInfo) &&
                resultCourseInfo.length > 0 && (
                  <>
                    <LabelStyles variant='h4'>Courses </LabelStyles>

                    <DividerColor />
                    <Box mt={4}>
                      <Course
                        getAwardType={getAwardType}
                        data={resultCourseInfo}
                      />
                    </Box>
                  </>
                )}
            </Box>

            <Box m={4}>
              {student_profile?.skills &&
                Array.isArray(student_profile?.skills) &&
                student_profile?.skills.length > 0 && (
                  <>
                    <LabelStyles variant='h4'>Declared Skills </LabelStyles>

                    <DividerColor />
                    <Box mt={4}>
                      <DeclaredSkills profileData={profileData} />
                    </Box>
                  </>
                )}
            </Box>

            <Box m={4}>
              {student_profile?.work_exp &&
                Array.isArray(student_profile?.work_exp) &&
                student_profile?.work_exp.length > 0 && (
                  <>
                    <LabelStyles variant='h4'>Work Experience </LabelStyles>
                    <DividerColor />
                    <Box mt={4}>
                      <WorkExperiences
                        title={profilePreviewData.workExperienceHeading}
                        data={student_profile}
                      />
                    </Box>
                  </>
                )}
            </Box>

            <Box m={4}>
              {student_profile?.voluntary_exp &&
                Array.isArray(student_profile?.voluntary_exp) &&
                student_profile?.voluntary_exp.length > 0 && (
                  <>
                    <LabelStyles variant='h4'>
                      Volunteer Experience{' '}
                    </LabelStyles>
                    <DividerColor />
                    <Box mt={4}>
                      <VolunteerExperiences
                        data={student_profile?.voluntary_exp}
                      />
                    </Box>
                  </>
                )}
            </Box>

            <Box m={4}>
              {(student_profile?.portfolio_url ||
                (portfolio_document_files &&
                  Array.isArray(portfolio_document_files) &&
                  portfolio_document_files.length > 0)) && (
                <>
                  <LabelStyles variant='h4'>Portfolio </LabelStyles>
                  <DividerColor />
                  <Box mt={4}>
                    <Portfolio profileData={profileData} />
                  </Box>
                </>
              )}
            </Box>

            <Box m={4}>
              {transcript_document_files &&
                Array.isArray(transcript_document_files) &&
                transcript_document_files.length > 0 && (
                  <>
                    <LabelStyles variant='h4'>Transcript </LabelStyles>
                    <DividerColor />
                    <Box mt={4}>
                      <Transcript profileData={profileData} />
                    </Box>
                  </>
                )}
            </Box>

            <Box m={4}>
              {document_files &&
                Array.isArray(document_files) &&
                document_files.length > 0 && (
                  <>
                    <LabelStyles variant='h4'>
                      Resume, Cover Letter & Related Documents{' '}
                    </LabelStyles>
                    <DividerColor />
                    <Box mt={4}>
                      <ResumeCoverLetter profileData={profileData} />
                    </Box>
                  </>
                )}
            </Box>

            <Box m={4}>
              {student_profile?.awards_leadership_projects_publications &&
                Array.isArray(
                  student_profile?.awards_leadership_projects_publications,
                ) &&
                student_profile?.awards_leadership_projects_publications
                  .length > 0 && (
                  <>
                    <LabelStyles variant='h4'>
                      Awards, Leadership, Projects & Publications{' '}
                    </LabelStyles>
                    <DividerColor />
                    <Box mt={4}>
                      <AwardsLeadership
                        heading={profilePreviewData.awardsLeadershipHeading}
                        data={student_profile}
                      />
                    </Box>
                  </>
                )}
            </Box>

            <Box m={4}>
              {student_profile?.interest &&
                Array.isArray(student_profile?.interest) &&
                student_profile?.interest.length > 0 && (
                  <>
                    <LabelStyles variant='h4'>Interests </LabelStyles>
                    <DividerColor />
                    <Box mt={4}>
                      <Interests data={student_profile?.interest} />
                    </Box>
                  </>
                )}
            </Box>
          </Grid>
        </Grid>
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

const mapStateToProps = state => ({
  appConfig: getAppConfig(state),
  awardTypes: awardTypesSelector(state),
  profileData: profileDataSelector(state),
  activePaths: getActivePath(state),
  srmAwardTypes: getAwardTypes(state),
  studentCredentials: studentCredentialsSelector(state),
});
export default connect(mapStateToProps, {
  fetchSharedProfileData,
  fetchSeekingData,
  fetchAwardTypes,
  fetchActivePaths,
  fetchProfileAwardTypes,
  fetchSharedStudentCredentials,
})(PreviewProfile);

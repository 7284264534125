import * as TYPES from './constants';

// INSTANCE ACTIONS
export const requestInstance = () => ({
  type: TYPES.INSTANCE_REQUEST,
});
export const recieveInstance = json => ({
  type: TYPES.INSTANCE_SUCCESS,
  payload: json,
});
export const errorInstance = err => ({
  type: TYPES.INSTANCE_ERROR,
  err,
});
export const clearInstance = () => ({
  type: TYPES.CLEAR_INSTANCE,
});

// VERSION ACTIONS
export const recieveVersion = json => ({
  type: TYPES.VERSION_SUCCESS,
  payload: json,
});

import {Box, OutlinedInput, Stack, Typography} from '@mui/material';
import {PATTERN} from 'core/regex';
import {Button} from 'mui-core';
import {createField} from 'mui-core/Form';
import {useForm} from 'react-hook-form';

const AddPortfolio = ({onSubmit, data}) => {
  const form = useForm();
  const {handleSubmit} = form;
  const onFinish = values => {
    onSubmit(values);
  };
  return (
    <Box mt={3}>
      <Typography variant='h5' fontSize={17} mb={2}>
        {data ? 'Update' : 'Add'} Portfolio URL
      </Typography>
      <Stack direction={'row'} justifyContent='center'>
        <form style={{width: '90%'}} onSubmit={handleSubmit(onFinish)}>
          {createField('portfolio_url', {
            form,
            label: 'Portfolio URL',
            defaultValue: data || '',
            rules: {
              required: [true, 'Portfolio URL is required.'],
              pattern: [PATTERN?.Website, 'Invalid URL format, use https://'],
            },
          })(<OutlinedInput label='Portfolio URL' placeholder='http://' />)}
          <Stack direction={'row'} justifyContent='center' spacing={2} mt={2}>
            <Button onClick={() => onSubmit(false)}>Cancel</Button>
            <Button formNoValidate type='submit' variant={'contained'}>
              {data ? 'Update' : 'Add'}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};
export default AddPortfolio;

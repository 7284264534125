import {useRef, useImperativeHandle, forwardRef} from 'react';
import {AutoComplete, Input, Row, Col, Typography} from 'antd';
import {CloseOutlined, ClockCircleFilled} from '@ant-design/icons';

const SearchComponent = forwardRef(
  (
    {
      results,
      loading,
      onEnter,
      onSearch,
      onSelect,
      searchKey,
      placeholder,
      floatingHeader,
      globalSearchHistory,
      deleteGlobalSearchHistoryItem,
    },
    ref,
  ) => {
    const searchRef = useRef();
    const renderTitle = title => <span className='title-type'>{title}</span>;
    const renderItem = title => ({
      value: title,
      label: (
        <Row>
          <Col span={1}>
            <ClockCircleFilled className='history-icon' />
          </Col>
          <Col span={22}>
            <Typography.Paragraph ellipsis className='title-main' title={title}>
              {title}
            </Typography.Paragraph>
          </Col>
          <Col span={1}>
            <CloseOutlined
              onClick={event => deleteGlobalSearchHistoryItem(title, event)}
            />
          </Col>
        </Row>
      ),
    });
    const showSearchHistory =
      Array.isArray(globalSearchHistory) &&
      globalSearchHistory.length &&
      !searchKey;

    // const resetSearchField = () => {
    //   // Try to find some solution
    //   //console.log('REF::', searchRef);
    //   // searchRef.current.focus();
    //   // searchRef.current.state = '';
    //   //searchRef.current.setValue('');
    //   // searchRef.current.input.value = '';
    //   // searchRef.current.state.value = '';
    //   // searchRef.current.state.prevValue = '';
    // };

    // useImperativeHandle(ref, () => ({
    //   resetSearchField,
    // }));

    return (
      <div
        className={`d-block global-search text-center ${
          floatingHeader ? 'floating-global-search' : ''
        }`}
        data-cy='global-search'>
        <div className='path-search-grp careerSearch mt-1'>
          <AutoComplete
            className='s-auto-complete'
            maxLength={100}
            options={
              showSearchHistory
                ? [
                    {
                      label: renderTitle('Recent Searches'),
                      options: globalSearchHistory.map(text =>
                        renderItem(text),
                      ),
                    },
                  ]
                : results
            }
            dropdownClassName='path-s-au-dropdown'
            value={searchKey}
            onSelect={onSelect}
            onSearch={onSearch}
            ref={searchRef}>
            <Input.Search
              placeholder={placeholder || ''}
              onPressEnter={onEnter}
              onSearch={onEnter}
              loading={loading}
            />
          </AutoComplete>
        </div>
      </div>
    );
  },
);

export default SearchComponent;

import {useEffect} from 'react';
import {Stack} from '@mui/material';
import {connect} from 'react-redux';
import {ContentBox} from 'mui-core';
import isEmpty from 'lodash/isEmpty';
import SkillsStats from './SkillsStats';
import CourseSkills from './CourseSkills';
import ProgramSkills from './ProgramSkills';
import PersonalSkill from './PersonalSkill';
import EducationSkills from './EducationSkills';
import LicensesertificationSkills from './LicensesCertificationSkills';
import {
  fetchSkills,
  skillsSelector,
  saveStudentSkill,
  fetchStudentSkills,
  updateStudentSkill,
  deleteStudentSkill,
  fetchStudentMetrics,
  studentSkillsSelector,
  studentMetricsSelector,
  fetchStudentCredentials,
  studentCredentialsSelector,
} from 'redux/modules/profile';
import {fetchActivePaths, getActivePath} from 'redux/modules/pathways';

const MySkills = props => {
  const {
    studentCredentials,
    activePaths,
    fetchStudentCredentials,
    fetchSkills,
    fetchStudentSkills,
    fetchActivePaths,
    studentSkills: {
      request: studentSkillsRequest,
      data: studentSkillsData = [],
    } = {},
    studentMetrics,
    fetchStudentMetrics,
  } = props;
  const {data: credentialsInfo = [], request: credentialsRequest} =
    studentCredentials || {};
  const {request: activePathsRequest, data: activePathsData = []} = activePaths;
  const {request: skillsRequest, data: skillsData = []} = {};
  const {
    data: studentMetricsData,
    request: studentMetricsRequest,
  } = studentMetrics;
  useEffect(() => {
    (!skillsData || !skillsData?.length) && !skillsRequest && fetchSkills();
    fetchStudentCredentials();
    (!studentSkillsData || !studentSkillsData?.length) &&
      !studentSkillsRequest &&
      fetchStudentSkills();
    (!activePathsData || !activePathsData?.length) && fetchActivePaths();
    (!studentMetricsData || isEmpty(studentMetricsData)) &&
      !studentMetricsRequest &&
      fetchStudentMetrics();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let courseInfo =
    credentialsInfo &&
    Array.isArray(credentialsInfo) &&
    credentialsInfo.filter(element => {
      return element.credential_type === 'course';
    });

  let certificationInfo =
    credentialsInfo &&
    Array.isArray(credentialsInfo) &&
    credentialsInfo.filter(element => {
      return element.credential_type === 'certification';
    });

  return (
    <ContentBox variant='outlined'>
      <Stack spacing={2} sx={{p: 3}}>
        <SkillsStats studentMetrics={studentMetrics} />
        <EducationSkills {...props} />
        <ProgramSkills
          activePathData={activePathsData}
          request={activePathsRequest}
        />
        <CourseSkills
          courseSkillsData={courseInfo}
          request={credentialsRequest}
        />
        <LicensesertificationSkills
          certificationSkillsData={certificationInfo}
          request={credentialsRequest}
        />
        <PersonalSkill {...props} />
      </Stack>
    </ContentBox>
  );
};

const mapStateToProps = state => ({
  skills: skillsSelector(state),
  activePaths: getActivePath(state),
  studentSkills: studentSkillsSelector(state),
  studentMetrics: studentMetricsSelector(state),
  studentCredentials: studentCredentialsSelector(state),
});

export default connect(mapStateToProps, {
  fetchSkills,
  fetchActivePaths,
  saveStudentSkill,
  updateStudentSkill,
  deleteStudentSkill,
  fetchStudentSkills,
  fetchStudentMetrics,
  fetchStudentCredentials,
})(MySkills);

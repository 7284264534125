import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Stats, ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {useMemo} from 'react';

const StyledStatsContainer = styled(Box)(() => ({
  '& .GridContainer': {
    padding: '0px !important',
  },
}));

const DashboardStats = ({calcData, studentMetrics}) => {
  const {totalRecords} = calcData || {};
  const {data: {job_matches = 0} = {}} = studentMetrics || {};

  const getJobMatchesCount = () => {
    let outputValue = job_matches;
    if (totalRecords) {
      outputValue = totalRecords;
    }
    return outputValue === 10000 ? '10,000+' : outputValue;
  };

  const statsData = useMemo(() => {
    const statsData = [
      {
        title: 'Job Matches',
        count: getJobMatchesCount(),
        color: 'rgb(125, 25, 234)',
      },
    ];
    return statsData;
  }, [totalRecords, job_matches]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledStatsContainer>
      <ErrorBoundary nameOfComponent='mod-comp-myJobs-stats'>
        <RequestErrorLoader
          body={studentMetrics}
          emptyMsg='No Results found for Job Match Metrics'>
          <Stats data={statsData} gridWidth={{lg: 2.3}} />
        </RequestErrorLoader>
      </ErrorBoundary>
    </StyledStatsContainer>
  );
};
export default DashboardStats;

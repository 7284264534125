import React, {lazy, Suspense, useEffect} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useClearCache} from 'react-clear-cache';
import {isMobile} from 'core/utils';
import {ErrorBoundary} from 'mui-core';
import ScrollToTop from './scroll';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from 'config/theme.config';
import GaugeChart from 'react-gauge-chart';

import {RequestErrorLoader, HelpWidget} from 'core/components';
import ThemeContext from './ThemeContext';

const App = lazy(() => import('./app'));

// basename={'/college_name'/'college_id'}
const Root = props => <InIt {...props} />;

const InIt = ({store}) => {
  const {isLatestVersion, emptyCacheStorage} = useClearCache();

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [emptyCacheStorage, isLatestVersion]);

  return (
    <ErrorBoundary nameOfComponent='super-component' typeOfUi='fullPage'>
      <Provider store={store}>
        <ThemeContext>
          <Router>
            <React.StrictMode>
              <ScrollToTop>
                <Suspense
                  fallback={<RequestErrorLoader body={{request: true}} />}>
                  <Switch>
                    <Route path='/' component={App} />
                  </Switch>
                </Suspense>
              </ScrollToTop>
            </React.StrictMode>
          </Router>
        </ThemeContext>
      </Provider>
      {!isMobile && process.env.NODE_ENV !== 'development' && <HelpWidget />}
    </ErrorBoundary>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestOnboardTypes,
  recieveOnboardTypes,
  onboardTypesErr,
  clearOnboardTypes as clear,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

let colors = ['#30B710', '#8062EA', '#F0844C', '#107FD3', '#EEB63C', '#54bfac'];

let types = {
  'New Student': () => ({
    details: "You've never attended college before.",
    color: '#30B710',
  }),
  'Returning Student': college => ({
    details: `You've attended ${college && college.name} but, 
    for any reason, have missed two or 
    more semesters in a row.`,
    color: '#8062EA',
  }),
  'Transferring Student': college => ({
    details: `You've attended college before but are new to ${
      college && college.name
    }.`,
    color: '#F0844C',
  }),
  'International Students': college => ({
    details: `You live outside of the U.S. but want to go to college at ${
      college && college.name
    }.`,
    color: '#107FD3',
  }),
  'Concurrent Enrollment Students': () => ({
    details: `You're a junior or senior in high school and want to take college classes for college credit.`,
    color: '#EEB63C',
  }),
  Student: () => ({
    details: `You are attending or would consider attending this school.`,
    color: '#54bfac',
  }),
};

const formatOnboardTypes = (institute_details, data) => {
  if (institute_details && Array.isArray(data) && data.length > 0) {
    const onboardTypeData = [];
    try {
      data.forEach((i, idx) => {
        onboardTypeData.push({
          ...i,
          // ...types[i.OnboardingType.name](institute_details),
          color: colors[idx % colors.length],
        });
      });
      return onboardTypeData;
    } catch (err) {
      return [];
    }
  } else {
    return [];
  }
};

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ONBOARD_TYPE_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.ONBOARD_TYPE_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.ONBOARD_TYPE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.ONBOARD_TYPE_CLEAR:
      return {
        ...state,
        request: false,
        error: true,
        data: null,
      };
    default:
      return state;
  }
};

export const fetchOnboardTypes = institute_details => (dispatch, getState) => {
  try {
    const {institution_id} = institute_details || {};
    const endPoint = `${API.srm.onboarding_path}/?institute_uuid=${institution_id}&is_published=true`;
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      dispatch(requestOnboardTypes());
      return services.fetchRecords(endPoint, token).then(res => {
        dispatch(
          recieveOnboardTypes(formatOnboardTypes(institute_details, res)),
        );
        return res;
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(onboardTypesErr(err));
  }
};

export const clearOnboardTypes = () => dispatch => {
  dispatch(clear());
};

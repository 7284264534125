import {lazy} from 'react';
import {retry} from 'core/utils';

const WhoIsHiring = lazy(() =>
  retry(() => import('./subModules/CompaniesList')),
);
const WhoIsHiringDetails = lazy(() =>
  retry(() => import('./subModules/CompanyDetails')),
);

export {WhoIsHiring, WhoIsHiringDetails};

import * as React from 'react';
import {IconButton} from 'mui-core';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import {Check} from '@mui/icons-material';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import {Typography, useTheme, Box, useMediaQuery} from '@mui/material';
import {ArrowRight} from 'assets/images';
import {ForEmployer} from 'data/partner.json';
const {comparePlans, comparePlansRows} = ForEmployer;
const {heading, subHeading} = comparePlans || {};

const ComparePlans = ({setShowSignupModal}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box py={{xs: 5, md: '100px'}}>
      {heading && (
        <Typography
          component='h2'
          variant='header-md-bold'
          textTransform='capitalize'
          dangerouslySetInnerHTML={{__html: heading}}
        />
      )}
      {subHeading && (
        <Typography
          component='p'
          variant='text-lg-regular'
          dangerouslySetInnerHTML={{__html: subHeading}}
        />
      )}
      <TableContainer
        component={Paper}
        sx={{py: {xs: 2, md: 4}, boxShadow: 'none'}}>
        <Table size='small' aria-label='compare plans table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{py: {xs: 2, sm: 5}, px: 0, minWidth: '96px'}}>
                <Typography
                  variant='title-xs-bold'
                  color={theme.palette.primary.main}>
                  Feature
                </Typography>
              </TableCell>
              <TableCell
                align='center'
                sx={{py: {xs: 2, sm: 5}, minWidth: '128px'}}>
                <Typography variant='header-sm-bold' component='h3'>
                  Free
                </Typography>
              </TableCell>
              <TableCell
                align='center'
                sx={{
                  minWidth: '128px',
                  py: {xs: 2, sm: 5},
                  borderRadius: '16px 16px 0 0',
                  background:
                    theme.palette.mode === 'light'
                      ? theme.palette.shadeyGrey.main
                      : theme.palette.dialogDarkBG.main,
                }}>
                <Typography variant='header-sm-bold' component='h3'>
                  Recruitment
                </Typography>
              </TableCell>
              <TableCell
                align='center'
                sx={{py: {xs: 2, sm: 5}, minWidth: '128px'}}>
                <Typography variant='header-sm-bold' component='h3'>
                  Enterprise
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {comparePlansRows.map((row, index) => (
              <>
                {index < comparePlansRows.length - 1 ? (
                  <TableRow
                    key={row.feature}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell component='th' scope='row' sx={{py: 2, px: 0}}>
                      <Typography variant='text-md-regular'>
                        {row.feature}
                      </Typography>
                    </TableCell>
                    <TableCell align='center' sx={{py: 2}}>
                      <Typography variant='text-md-regular'>
                        {row.free || '-'}
                      </Typography>
                      {row.free && (
                        <Check
                          sx={{
                            ml: 2,
                            width: '24px',
                            height: '24px',
                            marginBottom: '-6px',
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{
                        py: 2,
                        background:
                          theme.palette.mode === 'light'
                            ? theme.palette.shadeyGrey.main
                            : theme.palette.dialogDarkBG.main,
                      }}>
                      <Typography variant='text-md-regular'>
                        {row.recruitment || '-'}
                      </Typography>
                      {row.recruitment && (
                        <Check
                          sx={{
                            ml: 2,
                            width: '24px',
                            height: '24px',
                            marginBottom: '-6px',
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell align='center' sx={{py: 2}}>
                      <Typography variant='text-md-regular'>
                        {row.enterprise || '-'}
                      </Typography>
                      {row.enterprise && (
                        <Check
                          sx={{
                            ml: 2,
                            width: '24px',
                            height: '24px',
                            marginBottom: '-6px',
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell
                      scope='row'
                      component='th'
                      sx={{
                        py: {xs: 2, sm: 5},
                        textAlign: 'center',
                        borderBottom: 'none',
                      }}></TableCell>
                    <TableCell
                      scope='row'
                      component='th'
                      sx={{
                        py: {xs: 2, sm: 5},
                        textAlign: 'center',
                        borderBottom: 'none',
                      }}>
                      <IconButton
                        size='small'
                        label={row.free}
                        variant='contained'
                        endIcon={ArrowRight}
                        sx={{py: 1, px: {xs: 1, sm: 6}}}
                        iconSize={isMobile ? '12px' : '14px'}
                        onClick={() => setShowSignupModal(true)}
                      />
                    </TableCell>
                    <TableCell
                      scope='row'
                      component='th'
                      sx={{
                        py: {xs: 2, sm: 5},
                        textAlign: 'center',
                        borderBottom: 'none',
                        background:
                          theme.palette.mode === 'light'
                            ? theme.palette.shadeyGrey.main
                            : theme.palette.dialogDarkBG.main,
                      }}>
                      <IconButton
                        size='small'
                        variant='contained'
                        endIcon={ArrowRight}
                        label={row.recruitment}
                        sx={{py: 1, px: {xs: 1, sm: 6}}}
                        iconSize={isMobile ? '12px' : '14px'}
                        onClick={() => setShowSignupModal(true)}
                      />
                    </TableCell>
                    <TableCell
                      scope='row'
                      component='th'
                      sx={{
                        py: {xs: 2, sm: 5},
                        textAlign: 'center',
                        borderBottom: 'none',
                      }}>
                      <IconButton
                        size='small'
                        variant='contained'
                        endIcon={ArrowRight}
                        label={row.enterprise}
                        sx={{py: 1, px: {xs: 1, sm: 6}}}
                        iconSize={isMobile ? '12px' : '14px'}
                        onClick={() => setShowSignupModal(true)}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ComparePlans;

import React, {useState, useEffect} from 'react';
import {API} from 'config';
import {useSnackbar} from 'notistack';
import {styled} from '@mui/material/styles';
import {useCheckMobileView} from 'core/hooks';
import {Grid, Button, MUIList} from 'mui-core';
import {Edit, Delete} from '@mui/icons-material';
import AddVolExperience from './AddVolExperience';
import {volunteerExperienceData} from 'data/settings-profile.json';
import {Box, Stack, Divider, IconButton, Typography} from '@mui/material';

const StyledBox = styled(Box)(() => ({
  ul: {
    padding: '0px',
    li: {
      alignItems: 'baseline',
      '> div': {
        svg: {
          fontSize: '12px',
        },
        width: '24px',
        height: '24px',
        background: 'transparent',
      },
    },
  },
}));

const VolunteerExperience = ({data, onChange, viewOnly}) => {
  const {enqueueSnackbar} = useSnackbar();
  const {isMobileView} = useCheckMobileView();
  const [editIndex, setEditIndex] = useState(-1);
  const {voluntary_exp: volunteer_experience} = data || {};
  const [isAdd, setAdd] = useState(
    volunteer_experience?.length === 0 ? true : false,
  );
  const {addVolunteerExperienceBtn, rolesResponsibilities, experience, hours} =
    volunteerExperienceData || {};

  useEffect(() => {
    if (volunteer_experience?.length === 0) setAdd(true);
    else setAdd(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatData = (expData, id) => {
    const data = {...expData};
    if (
      expData.roles_responsibilities &&
      expData.roles_responsibilities.length > 0
    ) {
      data['roles_responsibilities'] = expData.roles_responsibilities
        .map(role => role.roles_responsibilities)
        .filter(item => {
          if (item) return item;
        });
    }
    if (id) data['volunteer_exp_uuid'] = id;
    return data;
  };

  const cancelAddEdit = () => {
    setAdd(false);
    setEditIndex(-1);
  };

  const editData = idx => {
    setAdd(false);
    setEditIndex(idx);
  };

  const onAddVolunteerExperience = async expData => {
    if (onChange) {
      const resp = await onChange(
        false,
        API.gps.volunteer_experience,
        formatData(expData),
        'POST',
        false,
      );
      cancelAddEdit();
      if (resp?.company_name === expData?.company_name) {
        enqueueSnackbar('Volunteer Experience added successfully.', {
          variant: 'success',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      } else {
        enqueueSnackbar('Something went wrong, please try again.', {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    }
  };

  const onEdit = async (item, id) => {
    if (onChange) {
      const resp = await onChange(
        false,
        API.gps.volunteer_experience,
        formatData(item, id),
        'PATCH',
        id,
      );
      cancelAddEdit();
      if (resp?.company_name === item?.company_name) {
        enqueueSnackbar('Volunteer Experience saved successfully.', {
          variant: 'success',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      } else {
        enqueueSnackbar('Something went wrong, please try again.', {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    }
  };

  const onDeleteExp = async exp => {
    if (onChange) {
      const resp = await onChange(
        false,
        API.gps.volunteer_experience,
        {},
        'DELETE',
        exp.volunteer_exp_uuid,
      );
      cancelAddEdit();
      if (resp?.Success) {
        enqueueSnackbar(resp.Success, {
          variant: 'success',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      } else {
        enqueueSnackbar('Something went wrong, please try again.', {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    }
  };

  return (
    <Stack>
      {volunteer_experience &&
        Array.isArray(volunteer_experience) &&
        volunteer_experience?.length > 0 &&
        volunteer_experience?.map((exp, idx) => {
          let getRolesResponsibilities = [];
          exp.roles_responsibilities &&
            exp.roles_responsibilities
              .sort((a, b) => a.display_order - b.display_order)
              .map(exp =>
                getRolesResponsibilities.push(exp.roles_responsibilities),
              );
          return editIndex === idx ? (
            <Box key={idx}>
              <AddVolExperience
                data={exp}
                edit={true}
                onCancel={cancelAddEdit}
                onSubmit={workExp => onEdit(workExp, exp?.volunteer_exp_uuid)}
              />
              <Divider sx={{my: 2}} />
            </Box>
          ) : (
            <Box key={idx}>
              <Grid
                sx={{
                  paddingLeft: {md: '48px', sm: '48px', lg: '48px', xs: '0px'},
                }}
                py={3}
                container
                spacing={2}>
                <Grid item xs={12} py={0}>
                  <div>
                    <Grid container p={0} alignItems='center'>
                      <Grid item xs={9} sm={9} md={8} lg={9}>
                        <Typography
                          variant='h5'
                          color='text.primary'
                          // fontFamily='fontFamilyBold'
                          fontSize={{xs: 12, sm: 14, md: 18}}>
                          {`${experience.label} - ${exp?.name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={4} lg={3} p={0}>
                        {!viewOnly && (
                          <Stack direction='row'>
                            {isMobileView ? (
                              <>
                                <IconButton onClick={() => editData(idx)}>
                                  <Edit
                                    sx={{fontSize: {xs: 16, sm: 18}}}
                                    color='primary'
                                  />
                                </IconButton>
                                <IconButton onClick={() => onDeleteExp(exp)}>
                                  <Delete
                                    sx={{fontSize: {xs: 16, sm: 18}}}
                                    color='primary'
                                  />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <Button
                                  size='large'
                                  onClick={() => editData(idx)}>
                                  Edit
                                </Button>
                                <Button
                                  size='large'
                                  onClick={() => onDeleteExp(exp)}>
                                  Delete
                                </Button>
                              </>
                            )}
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    color='text.primary'
                    fontSize={{xs: 12, sm: 14, md: 15}}>
                    {`${hours.label} - ${
                      exp.hours % 1 != 0 ? exp.hours : Math.round(exp.hours)
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12} py={0}>
                  <Typography
                    variant='body1'
                    color='text.primary'
                    fontSize={{xs: 12, sm: 14, md: 16}}>
                    {rolesResponsibilities.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={9} py={0}>
                  <StyledBox>
                    <MUIList
                      isBubble
                      emptyMsg='-'
                      fontSize={16}
                      list={getRolesResponsibilities}
                    />
                  </StyledBox>
                </Grid>
              </Grid>
              <Divider />
            </Box>
          );
        })}
      {!viewOnly && (
        <Box py={3}>
          {isAdd && (
            <AddVolExperience
              onCancel={cancelAddEdit}
              onSubmit={onAddVolunteerExperience}
              firstTime={volunteer_experience.length === 0}
            />
          )}
          {!isAdd && editIndex === -1 && (
            <Button
              variant='contained'
              sx={{width: '225px'}}
              onClick={() => setAdd(true)}
              size={isMobileView ? 'small' : 'large'}>
              {addVolunteerExperienceBtn}
            </Button>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default VolunteerExperience;

import * as TYPES from './constants';
import {API} from 'config';
import {
  requestStudentEventSignUpStatus,
  receiveStudentEventSignUpStatus,
  requestStudentEventSignUpStatusError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {
  request: false,
  error: false,
  data: {},
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENT_EVENT_SIGN_UP_STATUS:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECEIVE_STUDENT_EVENT_SIGN_UP_STATUS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_STUDENT_EVENT_SIGN_UP_STATUS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchStudentEventSignUpStatus = event_id => (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    const endPoint = `${API.srm.my_signedup_events}?event_id=${event_id}`;
    if (token) {
      dispatch(requestStudentEventSignUpStatus());
      return services.fetchRecords(endPoint, token).then(res => {
        if (res) {
          dispatch(receiveStudentEventSignUpStatus(res));
          return res;
        }
        dispatch(requestStudentEventSignUpStatusError(res));
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestStudentEventSignUpStatusError(err));
  }
};

import {Fragment} from 'react';
import {Carousel} from 'antd';
import {LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons';

import {carouselLeftArrow, carouselRightArrow} from 'assets/images';

import {ErrorBoundary} from 'core/components';

import style from './style.module.less';
import './style.less';

const Next = ({onClick, className}) => (
  <img
    src={carouselRightArrow}
    alt='right-arrow'
    onClick={onClick}
    className={
      className?.includes('slick-disabled')
        ? style.disabled
        : '' + ' slike-next'
    }
    data-cy='carousel-right-arrow'
  />
);

const Prev = ({onClick, className}) => (
  <img
    src={carouselLeftArrow}
    alt='left-arrow'
    onClick={onClick}
    className={
      className?.includes('slick-disabled')
        ? style.disabled
        : '' + ' slike-prev'
    }
    data-cy='carousel-left-arrow'
  />
);

const settings = {
  autoplay: false,
  arrows: true,
  dots: false,
  infinite: false,
  lazyLoad: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Next />,
  prevArrow: <Prev />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const CarouselSlider = ({data = [], config = {}, className = ''}) => {
  if (config?.slidesToShow > data.length) {
    config.slidesToShow = data.length;
  }

  if (
    config?.rows &&
    Math.round(data.length / config.rows) <= config?.slidesToShow
  ) {
    if (config?.rows > 1) {
      className = ' smallView';
    }
    if (config?.rows == 1) {
      className = 'smallerView smallView';
    }
  }

  const sliderConfig = Object.assign({}, settings, config);

  if (sliderConfig?.slidesToShow <= 0) {
    return <></>;
  }

  return (
    data &&
    Array.isArray(data) &&
    data?.length && (
      <ErrorBoundary nameOfComponent='core-carousel'>
        <Carousel
          className={`${style.gopathways_slider} ${className}`}
          {...sliderConfig}>
          {data.map((slide, idx) => (
            <Fragment key={`slide-${idx}`}>{slide}</Fragment>
          ))}
        </Carousel>
      </ErrorBoundary>
    )
  );
};

export default CarouselSlider;

/* Try with following example
==============================================================
* Ref Doc: https://react-slick.neostack.com/docs/get-started  *
==============================================================
*   <Carousel                       *
*    data={[                        *
*      <div>Slide 1</div>,          *
*      <div>Slide 2</div>,          *
*      <div>Slide 3</div>,          *
*      <div>Slide 4</div>,          *
*      <div>Slide 5</div>,          *
*    ]}                             *
*    config={{ slidesToShow: 3 }}   *
*   />                              *
=====================================
*/

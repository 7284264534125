import {Button} from 'mui-core';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {noSearchResults} from 'assets/images';
import {Box, Typography} from '@mui/material';

const NoContentNavigator = ({
  label,
  pathTo,
  imgSrc,
  showImg,
  heading,
  message,
  subHeading,
  noDataIcon,
}) => {
  const history = useHistory();
  const onNavigation = () => history.push(pathTo);

  return (
    <Box p={2} textAlign='center'>
      {showImg && (
        <img
          width='40'
          height='40'
          alt={subHeading}
          src={imgSrc || noSearchResults}
        />
      )}
      {noDataIcon ? noDataIcon : null}
      {heading && (
        <Typography
          my={2}
          variant='h1'
          lineHeight={1.5}
          color='text.primary'
          fontSize={{
            xs: '18px !important',
            sm: '18px !important',
            md: '20px !important',
          }}>
          {heading}
        </Typography>
      )}
      {message && (
        <Typography mb={2} variant='body1' color='text.secondary' fontSize={16}>
          {message}
          {subHeading && (
            <Typography mb={2} variant='body2' fontSize={16}>
              {subHeading}
            </Typography>
          )}
        </Typography>
      )}
      {pathTo && label && (
        <Button variant='contained' color='primary' onClick={onNavigation}>
          {label}
        </Button>
      )}
    </Box>
  );
};

NoContentNavigator.prototype = {
  showImg: PropTypes.bool,
  label: PropTypes.string,
  pathTo: PropTypes.string,
  imgSrc: PropTypes.string,
  heading: PropTypes.string,
  message: PropTypes.string,
  subHeading: PropTypes.string,
  noDataIcon: PropTypes.element,
};

export default NoContentNavigator;

import React from 'react';
import {ignoreList} from 'config';
import {Link, useHistory} from 'react-router-dom';
import {styled, Stack, Button} from '@mui/material';

const StyledButton = styled(Button)(({mobile}) => ({
  color: 'white',
  padding: '6px 16px',
  minHeight: '36px !important',
}));

const PartnersMenu = ({isCriminalJustice}) => {
  const {location: {pathname = ''} = {}} = useHistory();

  return (
    <Stack
      px={3}
      gap={2}
      height='50px'
      direction='row'
      alignItems='center'
      sx={{
        zIndex: 32,
        position: 'relative',
        background: 'linear-gradient(180deg, #635BFF 0%, #4E36F5 100%)',
      }}>
      <Link to='/'>
        <StyledButton
          variant={!ignoreList.includes(pathname) ? 'contained' : 'text'}
          color={!ignoreList.includes(pathname) ? 'secondary' : 'primary'}>
          For Individuals
        </StyledButton>
      </Link>
      {!isCriminalJustice && (
        <Link to='/employers'>
          <StyledButton
            variant={pathname === '/employers' ? 'contained' : 'text'}
            color={pathname === '/employers' ? 'secondary' : 'primary'}>
            For Employers
          </StyledButton>
        </Link>
      )}
    </Stack>
  );
};
export default PartnersMenu;

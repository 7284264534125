import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
// components
import {
  Box,
  Chip,
  Stack,
  Paper,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CarouselSlider from './Carousel';
import {SvgIcon, IconButton, ErrorBoundary, RequestErrorLoader} from 'mui-core';
// utils
import {numberInUSFormat} from 'core/utils';
import {styled, useTheme} from '@mui/material/styles';
import {
  DiamondIcon,
  BuildingIcon,
  BriefcaseIcon,
  CurrencyCircle,
  ArrowCircleRight,
  GlowingLightBulb,
  defaultBannerImage,
  ArrowCircleBrokenRight,
} from 'assets/images';
import {getGoBanner} from 'mui-core/utils';

const BannerContent = styled(Box)(({isMobile, isExtraSmall}) => {
  let styles = {};
  if (!isMobile)
    styles = {
      minWidth: 0,
      width: 'auto',
      height: '100%',
      margin: 'auto',
      aspectRatio: '2.1 / 1',
    };
  let mobileStyles = {};
  if (isMobile && !isExtraSmall) {
    mobileStyles = {
      width: 'auto',
      height: '100%',
      aspectRatio: '2 / 1',
    };
  }
  let extraSmallStyles = {};
  if (isExtraSmall) {
    extraSmallStyles = {
      width: 'auto',
      height: '100%',
      aspectRatio: '0.8 / 1',
    };
  }
  return {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '24px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ...styles,
    ...mobileStyles,
    ...extraSmallStyles,
  };
});

const StyledPaper = styled(Paper)(({theme, darkMode}) => ({
  maxWidth: '65%',
  minWidth: '60%',
  width: 'fit-content',
  padding: '16px 32px',
  borderRadius: '24px',
  backgroundColor: darkMode
    ? 'rgba(12, 17, 29, 0.8)'
    : theme.palette.background.paper,
  [theme.breakpoints.down('lg')]: {
    maxHeight: '330px',
  },
  [theme.breakpoints.down('md')]: {
    padding: 12,
    maxWidth: '85%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: 12,
    maxWidth: '100%',
    maxHeight: '360px',
  },
}));

const StyledHeader = styled(Typography)(({}) => ({
  overflow: 'hidden',
  display: '-webkit-box',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
}));

const StyledChip = styled(Chip)(({theme}) => ({
  '& .MuiChip-label': {
    padding: '0 10px',
  },
  padding: '0 10px',
  marginBottom: '8px',
  marginRight: '16px',
  border: '1.7px solid white',
}));

const CustomChip = ({value, label, icon}) => {
  return (
    <StyledChip
      variant='outlined'
      icon={
        <SvgIcon
          src={icon}
          mode='dark'
          altText={`${label} icon`}
          sx={{height: '18px', width: '18px'}}
        />
      }
      label={
        <>
          <Typography
            variant='label-sm'
            color={theme => theme.palette.teal.main}>
            {value === null || value === 0 ? '00' : numberInUSFormat(value)}
          </Typography>
          <Typography
            pl={0.8}
            color='white'
            variant='label-sm'
            sx={{textTransform: 'uppercase'}}>
            {label}
          </Typography>
        </>
      }
    />
  );
};

const CarouselBanner = ({
  dataset,
  redirectURL = '',
  sectionHeading = '',
  sectionSubHeading = '',
  dotInsideCarousel = false,
  disablePebbleShadow = true,
}) => {
  //hooks
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  //vars
  const {data} = dataset || {};

  //states
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    data && data?.length && setBanners(data);
  }, [data]);

  return (
    <ErrorBoundary nameOfComponent='carousel-banner'>
      <RequestErrorLoader hideEmpty minHeight={100} body={dataset}>
        <Box
          sx={{
            mt: 3,
            mb: dotInsideCarousel ? 6 : 8,
            '& .slick-list': {
              borderRadius: '24px',
              boxShadow:
                !disablePebbleShadow &&
                `0px 0px 12px ${theme.palette.shadow20.main}`,
            },
            '& .slick-dots': {
              boxShadow: 'none',
              position: 'relative',
              bottom: dotInsideCarousel
                ? '32px !important'
                : '-24px !important',
              '& button': {
                minWidth: isMobile ? '24px' : '32px',
                backgroundColor: !dotInsideCarousel
                  ? 'none !important'
                  : `${theme.palette.midGray.main} !important`,
                border: dotInsideCarousel
                  ? `1px solid white !important`
                  : `1px solid ${theme.palette.primary.main} !important`,
                boxShadow: !disablePebbleShadow
                  ? dotInsideCarousel
                    ? `0px 0px 2px ${theme.palette.grayText.main} !important`
                    : `0px 0px 8px ${theme.palette.grayText.main} !important`
                  : 'none !important',
              },
              '& .slick-active button': {
                boxShadow: 'none',
                backgroundColor: `${theme.palette.primary.main} !important`,
              },
            },
          }}>
          {(sectionHeading || sectionSubHeading || redirectURL) && (
            <Stack
              mt={2}
              mb={4}
              direction='row'
              alignItems='center'
              justifyContent='space-between'>
              <Box display='grid'>
                <Typography component='h3' variant='header-sm-bold'>
                  {sectionHeading}
                </Typography>
                {sectionSubHeading && (
                  <Typography mt={1} component='p' variant='text-lg-regular'>
                    {sectionSubHeading}
                  </Typography>
                )}
              </Box>
              {redirectURL && (
                <Box display='flex' justifyContent='flex-end' minWidth='152px'>
                  <Link to={redirectURL}>
                    <IconButton
                      textCapital
                      label='View More'
                      endIcon={ArrowCircleRight}
                    />
                  </Link>
                </Box>
              )}
            </Stack>
          )}
          <CarouselSlider
            fullWidth
            autoplay
            config={{
              dots: true,
              speed: 1000,
              arrows: false,
              autoplay: true,
              lazyLoad: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplaySpeed: 5000,
            }}
            data={banners.map(
              ({
                url,
                clickText,
                dark_mode,
                video_code,
                banner_url,
                banner_uuid,
                byline_text,
                video_details,
                headline_text,
                metrics = {},
                chips = [],
              }) => {
                const {
                  jobs = 0,
                  salary = 0,
                  programs = 0,
                  apprenticeships = 0,
                } = metrics;
                let imgURL = '';
                if (video_code) {
                  imgURL = `https://cdn.careeronestop.org/OccVids/OccupationVideos/${video_code}.jpg`;
                }
                if (video_details?.[0]?.VideoCode) {
                  imgURL = `https://cdn.careeronestop.org/OccVids/OccupationVideos/${video_details?.[0]?.VideoCode}.jpg`;
                }
                return (
                  <BannerContent
                    key={banner_uuid}
                    isMobile={isMobile}
                    isExtraSmall={isExtraSmall}
                    sx={{
                      backgroundImage: `url(${
                        banner_url
                          ? getGoBanner(banner_url, {width: 1440, height: 680})
                          : imgURL
                          ? imgURL
                          : defaultBannerImage
                      })`,
                    }}>
                    {(headline_text || byline_text) && (
                      <Container>
                        <StyledPaper elevation={16} darkMode={dark_mode}>
                          <StyledHeader
                            color='white'
                            component='h4'
                            variant='title-lg-bold'>
                            {headline_text}
                          </StyledHeader>
                          <Stack
                            minHeight={24}
                            direction='row'
                            alignItems='center'
                            my={isMobile ? 2 : 3}>
                            {byline_text && (
                              <>
                                <SvgIcon
                                  src={BuildingIcon}
                                  altText='building icon'
                                  sx={{
                                    width: '20px',
                                    height: '18px',
                                    marginRight: '8px',
                                    filter: theme.palette.iconBrandColor.main,
                                  }}
                                />
                                <Typography
                                  component='p'
                                  color='white'
                                  variant='text-md-regular'>
                                  {byline_text}
                                </Typography>
                              </>
                            )}
                          </Stack>
                          <Stack pt={1} direction='row' display='inline-block'>
                            {chips.includes('jobs') && (
                              <CustomChip
                                value={jobs}
                                label='jobs'
                                icon={BriefcaseIcon}
                              />
                            )}
                            {chips.includes('programs') && (
                              <CustomChip
                                value={programs}
                                label='programs'
                                icon={DiamondIcon}
                              />
                            )}
                            {chips.includes('apprenticeships') && (
                              <CustomChip
                                icon={GlowingLightBulb}
                                value={apprenticeships}
                                label='apprenticeships'
                              />
                            )}
                            {chips.includes('salary') && (
                              <CustomChip
                                value={salary}
                                label='salary'
                                icon={CurrencyCircle}
                              />
                            )}
                          </Stack>
                          <Box
                            my={2}
                            display='flex'
                            alignItems='center'
                            justifyContent='center'>
                            <IconButton
                              label={clickText}
                              variant='contained'
                              endIcon={ArrowCircleBrokenRight}
                              onClick={() => history.push(url)}
                            />
                          </Box>
                        </StyledPaper>
                      </Container>
                    )}
                  </BannerContent>
                );
              },
            )}
          />
        </Box>
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default CarouselBanner;

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  CardActionArea,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {Card, Grid} from 'mui-core';
const OnBoardType = ({onBoardData}) => {
  const {onCancel, onOk, onboardTypes, visible} = onBoardData || {};
  const {data: onboardTypeData} = onboardTypes || {};
  return (
    <Dialog open={visible} onClose={() => {}} maxWidth={false}>
      <DialogTitle sx={{m: 0, p: 4}}>
        <Typography variant='h4' color={'text.primary'}>
          Please select the student type that best describes you:
        </Typography>
        {onCancel ? (
          <IconButton
            aria-label='close'
            onClick={onCancel}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: theme => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{width: {sm: 700, md: 836, lg: 1100}, pb: 2}}>
        <Grid container spacing={3} sx={{my: 1}}>
          {Array.isArray(onboardTypeData) &&
            onboardTypeData.map(type => (
              <Grid key={type.uuid} sm={6} md={4} xs={12}>
                <Card sx={{borderRadius: 5}} onClick={() => onOk(type)}>
                  <CardActionArea sx={{p: 4, pb: 1, borderRadius: 5}}>
                    <Stack alignItems={'center'} spacing={2}>
                      <ProfileIcon fill={type.color} />
                      <Box height={45}>
                        <Typography
                          textAlign={'center'}
                          variant='h4'
                          color={'text.primary'}>
                          {type?.OnboardingType?.name}
                        </Typography>
                      </Box>
                    </Stack>
                    <Box minHeight={110} sx={{mt: 2}}>
                      <Typography variant='body2' color={'GrayText'}>
                        {type?.OnboardingType?.description}
                      </Typography>
                    </Box>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      {/* <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={handleClose}>Subscribe</Button>
    </DialogActions> */}
    </Dialog>
  );
};
export default OnBoardType;

const ProfileIcon = ({fill = 'none'}) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 42 42'
    fill={fill}
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.98 41.96C32.5669 41.96 41.96 32.5669 41.96 20.98C41.96 9.39306 32.5669 0 20.98 0C9.39307 0 0 9.39306 0 20.98C0 32.5669 9.39307 41.96 20.98 41.96Z'
      //   fill='#8063E6'
    />
    <path
      d='M20.8162 19.2848C24.7533 19.2848 27.9449 16.0931 27.9449 12.1561C27.9449 8.21898 24.7533 5.02734 20.8162 5.02734C16.8791 5.02734 13.6875 8.21898 13.6875 12.1561C13.6875 16.0931 16.8791 19.2848 20.8162 19.2848Z'
      fill='#F7F6FB'
    />
    <path
      d='M34.3272 28.8931C34.3272 31.036 24.2714 36.7922 21.1061 36.7922C18.3051 36.7922 7.87109 31.19 7.87109 28.8931C7.87109 25.1117 13.7954 22.0305 21.1061 22.0305C28.4169 22.0305 34.3272 25.1117 34.3272 28.8931Z'
      fill='#F7F6FB'
    />
  </svg>
);

import {
  Box,
  Stack,
  styled,
  Popover,
  useTheme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useHistory} from 'react-router';
import {useEffect, useRef, useState} from 'react';
import {themeColorMode} from 'mui-core/utils';

const mobileWidth = 900;

const FlowRootContainer = styled(Box)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    transform: 'rotateZ(90deg)',
    marginBottom: 900,
    marginTop: 270,
  },
  [theme.breakpoints.down('sm')]: {
    transformOrigin: 230,
    marginBottom: 1000,
    marginTop: 80,
  },
}));

const FlowRoot = styled(Box)(({theme}) => ({
  display: 'flex',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    minWidth: 1300,
    width: 1300,
    height: 270,
  },
  [theme.breakpoints.down('xs')]: {
    minWidth: 1000,
    width: 1000,
    height: 356,
  },
  '@keyframes openFadeAnim': {
    '0%': {
      opacity: 0,
      transform: 'translateY(19px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0px)',
    },
  },
  '@keyframes leftToRightAnim': {
    '0%': {
      opacity: 0,
      clipPath: `inset(0 100% 0 0)`,
    },
    '100%': {
      opacity: 1,
      clipPath: `inset(0 0 0 0)`,
    },
  },
  ['&.intersecting']: {
    ['& .item-c > .item-circle']: {
      animationName: 'openFadeAnim',
      animationDuration: '1s',
      animationFillMode: 'forwards',
    },
    ['& .svg-holder']: {
      animationName: 'leftToRightAnim',
      animationDuration: '2s',
      animationDelay: '0.1s',
      animationFillMode: 'forwards',
    },
  },
  ['& .svg-holder']: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    opacity: 0,
    ['& svg']: {
      width: '100%',
      height: '100%',
    },
  },
  ['& .item-c']: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      transform: 'rotateZ(270deg)',
    },
    ['&:hover']: {
      transition: '0.3s all!important',
      [theme.breakpoints.up('md')]: {
        transform: 'scale(1.1)!important',
      },
    },
    ['&>.item-circle ']: {
      cursor: 'pointer',
      borderRadius: '50em',
      boxShadow: '11px 29px 48px -12px rgba(16, 24, 40, 0.78)',
      width: 'calc(100% - 20px)',
      height: 'calc(100% - 20px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      position: 'relative',
      zIndex: '3',
      border: `5px solid ${themeColorMode(theme, '#fff', '#666')}`,
      backgroundColor: '#fff',
      opacity: 0,
      ['&:hover']: {
        boxShadow: '11px 19px 88px -2px rgb(39 17 95 / 90%)',
      },
      ['&>img']: {
        height: '100%',
        width: '100%',
        borderRadius: '50em',
        position: 'absolute',
      },
      ['& > .gradient-holder']: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: '50em',
        background:
          'linear-gradient(180deg, rgba(39, 17, 95, 0) 0%, #27115F 73.12%)',
      },
      ['& > .item-text']: {
        padding: '8px',
        textAlign: 'center',
        position: 'relative',
        zIndex: 11,
        color: '#fff',
        fontWeight: 700,
      },
      ['& > .item-number']: {
        position: 'absolute',
        top: 'calc(100% - 25px)',
        height: '50px',
        width: '50px',
        color: themeColorMode(theme, 'rgba(121, 121, 121, 1)', '#000'),
        fontWeight: 700,
        fontSize: '24px',
        fontFamily: 'Fira Sans',
        border: '1px solid #d9d9d999',
        boxShadow:
          '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
        borderRadius: '50em',
        backgroundColor: themeColorMode(theme, '#fff', '#999'),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          fontSize: '16px',
        },
      },
    },
  },
}));

const RenderBackgroundImage = ({paths, theme}) => (
  <Box className='svg-holder'>
    <svg version='1.1' xmlns='http://www.w3.org/2000/svg'>
      {paths.map(path => (
        <path
          stroke={themeColorMode(theme, '#eeedff', '#444')}
          strokeWidth={40}
          d={path}
          fill='transparent'
        />
      ))}
    </svg>
  </Box>
);

function JourneyFlow({items}) {
  const theme = useTheme();
  const history = useHistory();
  const containerRef = useRef();
  const itemLength = items?.length || 0;
  const [openIdx, setOpenIdx] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handlePopoverOpen = (event, idx) => {
    setOpenIdx(idx);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getContainerWidth = () => {
    if (containerRef?.current) {
      const rect = containerRef?.current?.getBoundingClientRect();
      return window.innerWidth < mobileWidth ? 1300 : rect.width;
    }
    return 0;
  };

  useEffect(() => {
    setContainerWidth(getContainerWidth());
    const onWindowResize = () => {
      setContainerWidth(getContainerWidth());
    };
    window.addEventListener('resize', onWindowResize);
    setTimeout(() => {
      if (containerWidth === 0) setContainerWidth(getContainerWidth());
    }, 1400);

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('intersecting');
        } else {
          entry.target.classList.remove('intersecting');
        }
      });
    });
    if (containerRef?.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  let itemWidth = itemLength > 0 ? containerWidth / itemLength : containerWidth;
  let leftGut = null;
  if (itemLength === 5) {
    leftGut = (itemWidth * 0.3 * itemLength) / 2;
    itemWidth = itemWidth * 0.7;
  }

  const paths = [];
  items.forEach((itm, idx) => {
    let isEven = idx % 2 === 0;
    if (idx === items.length - 1) return;
    if (isEven) {
      paths.push(
        `M${itemWidth * idx + itemWidth / 2} ${itemWidth / 1.5} Q${
          itemWidth * idx + itemWidth
        } ${itemWidth / 1.5}  ${itemWidth * idx + itemWidth} ${itemWidth} T${
          itemWidth / 2 + itemWidth + itemWidth * idx
        } ${itemWidth / 3.5 + itemWidth} `,
      );
    } else {
      paths.push(
        `M${itemWidth / 2 + itemWidth * idx} ${itemWidth / 3.5 + itemWidth} Q${
          itemWidth * (idx + 1)
        } ${itemWidth / 3.5 + itemWidth}, ${
          itemWidth * (idx + 1)
        } ${itemWidth} T${(idx + 1) * itemWidth + itemWidth / 2} ${
          itemWidth / 1.5
        } `,
      );
    }
  });

  const getFontSize = () => {
    let fontSize = 20;
    const width = containerWidth > 1300 ? 1300 : containerWidth;
    if (width < 1300) {
      fontSize = (containerWidth * 20) / 1300;
    }
    return fontSize.toFixed();
  };
  const redirectTo = item => {
    history.push(item.url);
  };

  // const openPopUpOnMobile = (e, idx) => {
  //   e.stopPropagation();
  //   const width = getContainerWidth();
  //   if (width < mobileWidth) {
  //     handlePopoverOpen(e, idx);
  //   }
  // };

  const popupSize = itemWidth < 180 ? 360 : itemWidth * 2;

  return (
    <FlowRootContainer>
      <FlowRoot
        className='flow-container'
        ref={containerRef}
        style={{position: 'relative', ...(leftGut ? {left: leftGut} : {})}}>
        <RenderBackgroundImage paths={paths} theme={theme} />
        {items.map((item, idx) => {
          const itemClassNames = ['item-c'];
          let isEven = false;
          if (idx % 2 === 0) {
            isEven = true;
            itemClassNames.push('is-even');
          }
          return (
            <Box
              key={item.title}
              style={{
                width: itemWidth,
                height: itemWidth,
                ...(!isEven ? {marginTop: itemWidth} : {}),
              }}
              className={itemClassNames.join(' ')}>
              <Box
                className='item-circle'
                onClick={() => redirectTo(item)}
                sx={{animationDelay: `${130 * idx + 1}ms`}}
                onMouseLeave={isMobile ? () => {} : handlePopoverClose}
                onMouseEnter={e =>
                  isMobile ? () => {} : handlePopoverOpen(e, idx)
                }>
                <img src={item.image} alt={`${item.imgAltText}`} />
                <Box className='gradient-holder' />
                <Typography
                  component='h5'
                  className='item-text'
                  style={{fontSize: `${getFontSize()}px`}}
                  variant='title-md-bold'>
                  {item.title}
                </Typography>
                <Typography component='h5' className='item-number'>
                  {idx + 1}
                </Typography>
              </Box>
            </Box>
          );
        })}
        <Popover
          open={open}
          anchorEl={anchorEl}
          disableRestoreFocus
          id='mouse-over-popover'
          sx={{pointerEvents: 'none'}}
          onClose={handlePopoverClose}
          style={{backgroundColor: 'transparent'}}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              boxShadow: '15px 17px 25px 0px rgba(0, 0, 0, 0.25)',
              borderRadius: '50em',
            },
          }}>
          <Stack
            spacing={3}
            textAlign='center'
            padding='27px 52px'
            alignItems='center'
            justifyContent='center'
            sx={{height: popupSize, width: popupSize}}>
            <Typography component='h3' variant='header-sm-medium'>
              {items?.[openIdx]?.title}
            </Typography>
            <Typography
              component='p'
              color='GrayText'
              variant='text-md-regular'>
              {items?.[openIdx]?.description}
            </Typography>
          </Stack>
        </Popover>
      </FlowRoot>
    </FlowRootContainer>
  );
}

export default JourneyFlow;

import React, {useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {useHistory} from 'react-router-dom';
import {LocationIcon} from 'mui-core/Icons';
import {MoreHoriz} from '@mui/icons-material';
import {capitalizeFirstLetter} from 'core/utils';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import {Box, Stack, IconButton, Avatar} from '@mui/material';
import {Card, Menu, LazyImage, ErrorBoundary} from 'mui-core';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

const TagContainer = styled(Stack)(({theme}) => ({
  top: 24,
  left: 0,
  zIndex: 1,
  position: 'absolute',
}));

const DateContainer = styled(Stack)(({theme}) => ({
  zIndex: 1,
  width: 62,
  height: 62,
  borderRadius: 62,
  position: 'absolute',
  left: 'calc(50% - 31px)',
  bottom: 'calc(100% - 31px)',
  backgroundColor: theme.palette.secondary.main,
}));

const Tag = styled(Stack)(({theme, tagType}) => ({
  padding: '4px 24px',
  borderRadius: '0 13px 13px 0',
  background:
    tagType === 'virtual'
      ? `${theme.palette.warn.dark}`
      : `${theme.palette.secondary.main}`,
}));

const TitleStyle = styled(Stack)(({theme, tagType}) => ({
  width: '100%',
  overflow: 'hidden',
  WebkitLineClamp: '2',
  display: '-webkit-box',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
}));

const StyledAddress = styled(Typography)(() => ({
  width: '100%',
  overflow: 'hidden',
  WebkitLineClamp: '1',
  display: '-webkit-box',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
}));

const EllipsesIcon = styled(IconButton)(({theme}) => ({
  zIndex: 1,
  top: '0px',
  right: '12px',
  padding: '2px',
  float: 'right',
  marginTop: '13px',
  marginRight: '10px',
  position: 'absolute',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
}));

const EventCard = ({data, width, displayEllipses, enableNavigation}) => {
  const {
    title = '',
    end_date,
    event_type,
    start_date,
    institute_id,
    banner_cloudinary,
    institute_details: {
      name,
      default_msa = {},
      institution_super_type,
      address_details: {city: inst_city, state: inst_state} = {},
    } = {},
    business_partner_name: {company_name, city: bp_city, state: bp_state} = {},
  } = data || {};

  let address = '';
  let inst_name = name;
  const history = useHistory();
  const cardWidth = width ? {width} : {};
  const [anchorEl, setAnchorEl] = useState(null);
  const hideEllipse = moment().isAfter(end_date, 'second');
  const event_date = start_date ? moment(start_date).format('DD') : '';
  const event_time = start_date ? moment(start_date).format('h:mm A') : '';
  const event_date_month = start_date ? moment(start_date).format('MMM') : '';
  const open = Boolean(anchorEl);
  const {city: msa_city = '', state_code: msa_state = ''} = default_msa || {};
  if (inst_city && inst_state) {
    address = inst_city + ', ' + inst_state;
  }
  if (!institute_id) {
    inst_name = company_name;
    address = bp_city + ', ' + bp_state;
  }
  if (
    institution_super_type &&
    typeof institution_super_type === 'string' &&
    institution_super_type === 'Consortium' &&
    default_msa
  ) {
    address = `${msa_city}, ${msa_state}`;
  }

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const pathNavigateTo = resData => {
    history.push(
      `/events?event_id=${resData.event_id}&event_name=${resData.title}`,
    );
  };

  const getMenu = () => {
    let menu = [];
    if (displayEllipses?.length > 0) {
      menu = displayEllipses.map(item => {
        if (item.hasOwnProperty('passData')) {
          item['dataPassed'] = data[item.passData] || data;
        }
        return item;
      });
    }
    return (
      <Menu data={menu} open={open} anchorEl={anchorEl} onClose={handleClose} />
    );
  };

  return (
    <ErrorBoundary nameOfComponent='core-eventcard'>
      <Card
        sx={{
          ...cardWidth,
          position: 'relative',
        }}
        component={CardActionArea}
        onClick={() => enableNavigation && pathNavigateTo(data)}>
        <TagContainer spacing={1}>
          {event_type && (
            <>
              {event_type === 'virtual' && (
                <Tag alignItems='center' tagType='virtual'>
                  <Typography variant='body2' color='primary.contrastText'>
                    Virtual
                  </Typography>
                </Tag>
              )}
              {event_type === 'in-person' && (
                <Tag alignItems='center' tagType='in-person'>
                  <Typography variant='body2' color='primary.contrastText'>
                    In Person
                  </Typography>
                </Tag>
              )}
            </>
          )}
        </TagContainer>
        {displayEllipses && !hideEllipse && (
          <>
            <EllipsesIcon
              size='small'
              id='basic-button'
              component='label'
              aria-haspopup='true'
              onClick={handleClick}
              aria-expanded={open ? 'true' : undefined}
              aria-controls={open ? 'basic-menu' : undefined}>
              <MoreHoriz />
            </EllipsesIcon>
            {getMenu()}
          </>
        )}
        <Box height={160}>
          <LazyImage
            renderSrcSet
            src={banner_cloudinary}
            alt={`${title}-event-banner`}
          />
        </Box>
        <Box p={2} pt={3} minHeight='186px' position='relative'>
          <DateContainer
            alignItems='center'
            direction={'column'}
            justifyContent='center'>
            <Typography variant='body1' color='white'>
              {event_date}
            </Typography>
            <Typography variant='body1' color='white'>
              {event_date_month}
            </Typography>
          </DateContainer>
          <TitleStyle>
            {title && (
              <Typography
                mt={2}
                noWrap
                variant='h6'
                title={`${title || ''} `}
                color='text.primary'>
                {capitalizeFirstLetter(title)}
              </Typography>
            )}
          </TitleStyle>

          <TitleStyle direction='row' spacing={0.5}>
            {inst_name && (
              <>
                <Typography variant='Subtitle2' whiteSpace='nowrap'>
                  Hosted By:
                </Typography>
                <Typography variant='Subtitle2' color='primary.main'>
                  {capitalizeFirstLetter(inst_name)}
                </Typography>
              </>
            )}
          </TitleStyle>

          <Stack mt={1} display='flex' direction='row' alignItems='self-start'>
            {address && (
              <>
                <LocationIcon fontSize='small' sx={{mt: '3px', ml: '4px'}} />
                <StyledAddress
                  ml={0.6}
                  variant='Subtitle2'
                  title={`${address || ''} `}
                  color='text.secondary'>
                  {capitalizeFirstLetter(address)}
                </StyledAddress>
              </>
            )}
          </Stack>
          <Stack
            mt={1}
            display='flex'
            direction='row'
            flexWrap='wrap'
            alignItems='center'>
            {event_time && (
              <>
                <QueryBuilderIcon fontSize='small' />
                <Typography ml={1} variant='Subtitle2' color='text.secondary'>
                  {event_time}
                </Typography>
              </>
            )}
          </Stack>
        </Box>
      </Card>
    </ErrorBoundary>
  );
};

EventCard.propTypes = {
  data: PropTypes.object,
  enableNavigation: PropTypes.bool,
  displayEllipses: PropTypes.bool || PropTypes.array,
};

export default EventCard;

import {lazy} from 'react';
import {retry} from 'core/utils';

const MyCareerSurvey = lazy(() =>
  retry(() => import('./CareerSurveyHome/MyCareerSurvey')),
);
const CareerSurveyHome = lazy(() => retry(() => import('./CareerSurveyHome')));
const ClusterArea = lazy(() => retry(() => import('./ClusterArea')));

export {MyCareerSurvey, CareerSurveyHome, ClusterArea};

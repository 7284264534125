import * as TYPES from './constants';
import {API} from 'config';

import {
  requestOccupationDetails,
  recieveOccupationDetails,
  requestOccupationDetailsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OCCUPATION_DETAILS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.OCCUPATION_DETAILS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.OCCUPATION_DETAILS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.CLEAR_OCCUPATION_DETAILS:
      return {
        request: false,
        error: false,
        data: undefined,
      };
    default:
      return state;
  }
};

export const fetchOccupationDetails = (
  {query, msa_code, cip_code, location},
  callback,
) => (dispatch, getState) => {
  let endPoint = API.srm.occupations;
  if (query) {
    endPoint += `?occupation_name=${query}`;
  } else if (cip_code) {
    endPoint += `?cip_code=${cip_code}`;
  }
  if (msa_code) {
    endPoint += `&msa_code=${msa_code}`;
  }
  if (location) {
    endPoint += `&location=${location}`;
  }

  try {
    if (query || cip_code) {
      dispatch(requestOccupationDetails());
      return services.fetchRecords(endPoint, null, true).then(res => {
        if (res) {
          dispatch(recieveOccupationDetails(res));
          callback && callback(res);
          return res;
        }
        dispatch(requestOccupationDetailsError(res));
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestOccupationDetailsError(err));
  }
};

import React, {useState} from 'react';
import AwardsList from './AwardsList';
import {useSelector} from 'react-redux';
import {ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {awardTypesSelector as getAwardTypes} from 'redux/modules/profile';
import {getResumeData} from 'redux/modules/resume-builder';

const Awards = ({formRef, handleSave}) => {
  const [isLoading, setisLoading] = useState(false);
  const {data: resumeData, error} = useSelector(getResumeData);

  const {
    error: awardError,
    data: awardTypes = [],
    request: awardRequest,
  } = useSelector(getAwardTypes);

  let awardsList = [];
  let publicationsList = [];
  const awardItem = awardTypes?.find(i => i.item_type === 'Award');
  resumeData?.awards_publications?.forEach(ele => {
    if (ele.item_type === awardItem?.item_type_uuid) {
      awardsList?.push(ele);
    } else {
      publicationsList?.push(ele);
    }
  });

  const onSubmit = (data, action) => {
    const payload = getPayload(data, action);
    handleSave(payload);
  };

  const getPayload = (data, action) => {
    let awardsData = [...awardsList];
    if (action === 'edit') {
      awardsData = awardsData.map(item =>
        item.display_order === data.display_order ? data : item,
      );
    } else if (action === 'delete') {
      const newArr = awardsData.filter(
        item => item.display_order !== data.display_order,
      );
      awardsData = newArr.map((item, index) => {
        return {...item, display_order: index + 1};
      });
    } else {
      awardsData.push({
        ...data,
        display_order: awardsData.length + 1,
      });
    }

    return {awards_publications: [...publicationsList, ...awardsData]};
  };

  return (
    <ErrorBoundary typeOfUi='subPage' nameOfComponent='resume-builder-awards'>
      <RequestErrorLoader
        hideEmpty
        fullWidth
        body={{
          error: error || awardError,
          request: isLoading || awardRequest,
          data: resumeData.awards_publications,
        }}>
        <AwardsList
          formRef={formRef}
          data={resumeData}
          onChange={onSubmit}
          awardTypes={awardTypes}
          getPayload={getPayload}
        />
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};
export default Awards;

import React, {Suspense, useEffect, useState} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {RequestErrorLoader} from 'core/components';
import {isTokenExpire} from 'core/utils';

import {AppRoutes} from 'config';

const Routes = ({
  routeData = AppRoutes,
  url = '',
  defaultRedirection = '/',
}) => (
  <Suspense fallback={<RequestErrorLoader body={{request: true}} />}>
    <Switch>
      {routeData &&
        routeData.map(route => {
          return route.protected ? (
            <PrivateRoute
              {...route}
              path={`${url}${route.path}`}
              defaultRedirection={defaultRedirection}
            />
          ) : (
            <NonPrivateRoute {...route} path={`${url}${route.path}`} />
          );
        })}
      <Redirect to={defaultRedirection} />
    </Switch>
  </Suspense>
);

const NonPrivateRoute = ({component: Component, subModules = [], ...rest}) => (
  <Route
    {...rest}
    render={props => <Component {...props} submodules={subModules} />}
  />
);
const PrivateRoute = ({
  component: Component,
  subModules = [],
  defaultRedirection,
  ...rest
}) => {
  const authToken = useSelector(
    state => state.auth && state.auth.auth && state.auth.auth.token,
  );
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const {isExpired} = isTokenExpire(authToken);
  useEffect(() => {
    if (authToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [authToken, isExpired]); // eslint-disable-next-line

  if (isAuthenticated === null) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={props => {
        return !isAuthenticated ? (
          <Redirect
            to={{
              pathname: defaultRedirection,
              state: {from: props.location},
            }}
          />
        ) : (
          <Component {...props} submodules={subModules} />
        );
      }}
    />
  );
};

export default Routes;

import React from 'react';
import {Box, Divider, Typography} from '@mui/material';
import {
  ContentBox,
  ErrorBoundary,
  ProgramCourseMap,
  NoContentNavigator,
  RequestErrorLoader,
} from 'mui-core';
import {
  NoCourseMapFound,
  NoActivePathFound,
  ExploreProgramsBtnText,
} from 'data/pathway';

const Requirements = ({programData}) => {
  return (
    <ContentBox variant='outlined' sx={{mb: 2, p: 3}}>
      <Typography variant='h4' color={'text.primary'}>
        Course Sequence by Term
      </Typography>
      <Typography variant='body1' color={'text.primary'} my={1}>
        The following map displays courses that are required for the completion
        of this program.
      </Typography>
      <Divider />
      <Box mt={2}>
        <ErrorBoundary nameOfComponent='mod-comp-settings-myeducation-mycoursemap'>
          <RequestErrorLoader
            body={{
              data: programData?.terms || [],
            }}
            overideNoDataContainer={
              <NoContentNavigator
                pathTo='/programs'
                message={NoCourseMapFound}
                label={ExploreProgramsBtnText}
              />
            }>
            {programData?.terms &&
            Array.isArray(programData.terms) &&
            programData?.terms.length ? (
              <ProgramCourseMap
                pathInfo={{path_details: programData}}
                showModal={true}
              />
            ) : (
              <></>
            )}
          </RequestErrorLoader>
        </ErrorBoundary>
      </Box>
    </ContentBox>
  );
};

export default Requirements;

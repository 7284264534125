import {useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {Box, Stack} from '@mui/material';
import {createField} from 'mui-core/Form';
import {useImperativeHandle} from 'react';
import resumeBuilderData from 'data/resumeBuilder.json';
import {Button, ErrorBoundary, AutoCompleteWrapper} from 'mui-core';
import {getResumeData, getLinkedInDetails} from 'redux/modules/resume-builder';
import {skillsSelector} from 'redux/modules/profile';

const Skills = ({formRef, handleSave}) => {
  const form = useForm();
  const {data: resumeData} = useSelector(getResumeData) || {};
  const {data: skill_name = []} = useSelector(skillsSelector) || {};
  const {trigger, getValues, formState, handleSubmit} = form;
  const {skills = []} = resumeData;
  const {isValid, isDirty} = formState;
  const {
    stepsMeta: {
      skills: {addBtnTxt},
    },
  } = resumeBuilderData || {};

  const {
    stepsMeta: {
      skills: {placeholder},
    },
  } = resumeBuilderData || {};

  useImperativeHandle(formRef, () => ({
    validateForm() {
      trigger();
      return isValid;
    },
    getFormData() {
      let {skills} = getValues();
      return isDirty ? {skills} : null;
    },
  }));

  const saveSkills = (data, e) => {
    handleSave(data);
  };

  const filteredSkillsData =
    skills &&
    Array.isArray(skills) &&
    skills.length > 0 &&
    skills.map(item => {
      return {
        ...item,
        key: item.uuid,
        label: item.skill,
        title: item.skill,
      };
    });

  return (
    <ErrorBoundary typeOfUi='subPage' nameOfComponent='resume-builder-skills'>
      <form onSubmit={handleSubmit(saveSkills)}>
        <Box>
          {createField('skills', {
            form,
            size: 'small',
          })(
            <AutoCompleteWrapper
              required
              multiple
              form={form}
              label='Add Skill'
              fieldName='skills'
              placeholder={placeholder}
              keyNameIfMultiple='skill'
              disableCloseOnSelect={true}
              options={
                skill_name && Array.isArray(skill_name) && skill_name.length > 0
                  ? skill_name?.map(item => {
                      return {
                        ...item,
                        key: item.id,
                        label: item.skill_name || item.name,
                        title: item.skill_name || item.name,
                        skill: item.skill_name || item.name,
                      };
                    })
                  : []
              }
              value={filteredSkillsData || []}
              defaultValue={filteredSkillsData || []}
            />,
          )}
          <Stack mt={4} direction='row' justifyContent='center'>
            <Button
              type='submit'
              formNoValidate
              disabled={!isDirty}
              variant='contained'>
              {addBtnTxt}
            </Button>
          </Stack>
        </Box>
      </form>
    </ErrorBoundary>
  );
};

export default Skills;

import React, {useEffect, createRef} from 'react';
import PropTypes from 'prop-types';
import {SearchOutlined, CloseOutlined} from '@ant-design/icons';
import debounce from 'debounce';

import {ErrorBoundary} from 'core/components';

const SearchField = ({
  onSearch,
  selectedFilter,
  placeholder,
  classname,
  value,
  allowClear,
}) => {
  let searchref = createRef();
  const debounceEvent = (...args) => {
    const debouncedEvent = debounce(...args);
    return e => {
      e.persist();
      return debouncedEvent(e);
    };
  };

  const changeHandler = e => onSearch(e.target.value);

  const crossHandler = () => {
    if (searchref.value) {
      searchref.value = '';
      onSearch('');
    }
  };

  useEffect(() => {
    searchref.value = '';
  }, [searchref.value, selectedFilter]);
  useEffect(() => {
    if (value) {
      searchref.value = value;
    } else {
      searchref.value = '';
    }
  }, [searchref.value, value]);

  return (
    <ErrorBoundary nameOfComponent='core-search-field'>
      <div className='search-placeholder'>
        <SearchOutlined className='search-icon' />
        <input
          type='text'
          className={classname}
          placeholder={placeholder}
          ref={ref => (searchref = ref)}
          onChange={debounceEvent(changeHandler, 800)}
        />
        {allowClear && (
          <CloseOutlined className='close-icon' onClick={crossHandler} />
        )}
      </div>
    </ErrorBoundary>
  );
};

SearchField.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

SearchField.defaultProps = {
  selectedFilter: 'all',
  placeholder: 'Search',
};

export default SearchField;

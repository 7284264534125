import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {themeColorMode} from 'mui-core';
import {styled, useTheme} from '@mui/material/styles';
import {
  Tab,
  Tabs,
  Paper,
  Typography,
  Box,
  useScrollTrigger,
} from '@mui/material';
import {useLocation, useRouteMatch} from 'react-router-dom';

const MenuBar = ({submodules, width}) => {
  const menuContainerRef = useRef();
  const theme = useTheme();
  const {url} = useRouteMatch();
  const {pathname} = useLocation();

  const path = pathname && pathname.trim().split(url).join('');
  const [tabIndex, setTabIndex] = useState(path || '/');

  useEffect(() => {
    if (path) {
      setTabIndex(path);
    } else {
      setTabIndex('/');
    }
  }, [path]);

  const MenuContainer = styled(Paper)(({theme}) => ({
    paddingTop: 0,
    boxShadow: 'none',

    [`&.stick`]: {
      [`& > div.hidden-box`]: {
        minHeight: '32px',
        height: '32px',
        position: 'relative',
      },
      [`& > .stick-header`]: {
        top: 64,
        zIndex: 9,
        display: 'flex',
        position: 'fixed',
        width: '100%',
        left: 0,
        height: '32px',
        borderRadius: 0,
        ['& > .tab-container-out']: {
          width: '100%',
          maxWidth: '1400px',
          margin: '0 auto',
          paddingLeft: '24px',
          paddingRight: '24px',
          [`& > div.tab-container`]: {
            borderRadius: '0 0 15px 15px',
            backgroundColor: themeColorMode(
              theme,
              theme.palette.background.paper,
              theme.palette.darkGray.main,
            ),
            boxShadow:
              '0px 3px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 1px 10px 9px 0px rgb(0 0 0 / 3%)',
            filter: `drop-shadow(0px 9px 2px rgba(123, 96, 223, 0.2))`,
          },
          [theme.breakpoints.down('sm')]: {
            padding: 0,
          },
        },
        [theme.breakpoints.down('sm')]: {
          top: 56,
        },
      },
    },
  }));

  return (
    <MenuContainer id='dashboard-menu-container' ref={menuContainerRef}>
      <Box className='hidden-box' />
      <Box className='stick-header'>
        <Box className='tab-container-out'>
          <Tabs
            value={tabIndex}
            variant='scrollable'
            scrollButtons='auto'
            allowScrollButtonsMobile
            className='tab-container'
            aria-label='dashboard-menu-tabs'
            sx={{
              minHeight: '32px',
              paddingLeft: {sm: '24px'},
              transition: 'transform 0.3s ease-in-out',
            }}>
            {submodules.map(({key, label, path}) => (
              <Tab
                id={key}
                key={key}
                value={path}
                component={Link}
                aria-controls={key}
                to={`/dashboard${path}`}
                sx={{
                  minHeight: '32px',
                  padding: {xs: '0px 16px', sm: '0px 22px'},
                }}
                label={
                  <Typography
                    variant='body1'
                    textTransform='initial'
                    sx={{
                      color: 'inherit',
                      [theme.breakpoints.only('xs')]: {
                        fontSize: 11,
                      },
                    }}>
                    {label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </MenuContainer>
  );
};

export default MenuBar;

import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {numberInUSFormat} from 'core/utils';
import {Grid, themeColorMode} from 'mui-core';
import {Paper, Stack, Typography} from '@mui/material';

const defaultGridWidth = {xs: 6, sm: 6, md: 3, lg: 2};

const Item = styled(Paper)(({theme, color}) => ({
  border: '0px',
  textAlign: 'left',
  borderLeft: '4px',
  boxShadow: 'none',
  borderColor: color,
  borderRadius: '0px',
  borderStyle: 'solid',
  padding: `0px ${theme.spacing(1)}`,
  color: theme.palette.text.secondary,
}));

const StatsCount = styled(Typography)(({theme}) => ({
  margin: '0px',
  fontSize: '28px',
  fontWeight: '400',
  lineHeight: '32px',
  color: themeColorMode(
    theme,
    theme.palette.text.primary,
    theme.palette.text.secondary,
  ),
  fontFamily: theme.typography.fontFamily,
}));

const StatsLabel = styled(Typography)(({theme}) => ({
  marginTop: '2px',
  fontWeight: '400',
  fontSize: '12.8px',
  color: themeColorMode(
    theme,
    theme.palette.text.primary,
    theme.palette.text.secondary,
  ),
  fontFamily: theme.typography.fontFamily,
}));

const Stats = props => {
  const {data = [], gridWidth = {}} = props;
  const formatCount = count => {
    try {
      if (!isFinite(count)) {
        return count;
      }
      const formattedNumber = numberInUSFormat(count);
      return formattedNumber?.toString()?.padStart(2, '0');
    } catch (err) {
      return 0;
    }
  };
  return (
    <Stack flexGrow={1} className='StatsContainer'>
      <Grid className='GridContainer' container p={0}>
        {data &&
          data.map(({title = '', count = '0', color}, index) => (
            <Grid
              item
              xs={gridWidth.xs || defaultGridWidth.xs}
              sm={gridWidth.sm || defaultGridWidth.sm}
              md={gridWidth.md || defaultGridWidth.md}
              lg={gridWidth.lg || defaultGridWidth.lg}
              key={index}>
              <Item color={color}>
                <StatsCount variant='h4'>{formatCount(count)}</StatsCount>
                <StatsLabel variant='body2'>{title}</StatsLabel>
              </Item>
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
};

Stats.propTypes = {
  data: PropTypes.array,
};

export default Stats;

import {Typography} from '@mui/material';
import {ContentBox, ErrorBoundary} from 'mui-core';
import {connect} from 'react-redux';
import {
  profileDataSelector,
  updateProfile,
  uploadUserDocs,
  deleteUserDocs,
} from 'redux/modules/profile';
import {default as PortfolioComponent} from './Portfolio';

const Portfolio = props => {
  const {profileData} = props;
  return (
    <ContentBox variant='outlined' sx={{mb: 2, p: 3}}>
      <Typography color={'text.primary'} variant='h5' fontSize={18}>
        Portfolio
      </Typography>
      <Typography color={'GrayText'} variant='body2' mt={1}>
        Adding a portfolio to your profile can help you demonstrate your skills
        and accomplishments in a concrete and tangible way. It can enhance your
        credibility, provide context to your resume, and help you stand out from
        other candidates.
      </Typography>
      <ErrorBoundary
        nameOfComponent='dashboard-profile-education'
        typeOfUi='subPage'>
        <PortfolioComponent {...props} />
      </ErrorBoundary>
    </ContentBox>
  );
};

const mapStateToProps = state => ({
  profileData: profileDataSelector(state),
});

export default connect(mapStateToProps, {
  updateProfile,
  uploadUserDocs,
  deleteUserDocs,
})(Portfolio);

import {useEffect, Fragment} from 'react';
import {withRouter} from 'react-router-dom';

// const avoidPathsToReset = ['/occupation', '/business-partners'];

const ScrollToTop = ({history, children}) => {
  const {
    location: {pathname, search},
  } = history;
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Fragment>{children}</Fragment>;
};

export default withRouter(ScrollToTop);

import * as TYPES from './constants';

// Pos List
export const requestPosList = () => ({
  type: TYPES.REQUEST_POS_LIST,
});
export const recievePosList = json => ({
  type: TYPES.RECIEVE_POS_LIST,
  payload: json,
});
export const requestPosListError = err => ({
  type: TYPES.REQUEST_POS_LIST_ERROR,
  err,
});
export const clearPosList = () => ({
  type: TYPES.CLEAR_POS_LIST,
});

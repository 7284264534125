import React from 'react';
import {FilterDrawer} from 'mui-core';
import {useInstance} from 'core/hooks';
import NLXFilterForm from './NLXFilterForm';
import LocalJobsFilterForm from './LocalJobsFilterForm';
import HonestJobsFilterForm from './HonestJobsFilterForm';
import {Select, MenuItem, InputLabel, FormControl} from '@mui/material';

const jobSourceList = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'National Labor Exchange (NLx)',
    value: 'cos',
  },
  {
    label: 'GoEducate Employers',
    value: 'local',
  },
];

const criminalJobSourceList = [
  {
    label: 'National Labor Exchange (NLx)',
    value: 'cos',
  },
  {
    label: 'Honest Jobs',
    value: 'honest',
  },
];

const RenderFilterLayout = ({children, source, sourceList, onSourceChange}) => (
  <>
    <FormControl fullWidth sx={{mb: 3}}>
      <InputLabel htmlFor='form-source-type'>Source</InputLabel>
      <Select
        fullWidth
        label='Source'
        id='form-source-type'
        placeholder='Select Source Type'
        onChange={e => onSourceChange(e)}
        value={source === '' ? sourceList[0]?.value : source}>
        {sourceList?.map(({label, value}) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    {children}
  </>
);

const FilterForm = ({
  form,
  source,
  filters,
  handleSubmit,
  onResetClick,
  isDrawerOpen,
  onDrawerClose,
  onSourceChange,
  hideCompanyFilter,
}) => {
  const {data: {nearby_msa = [], super_type_name = null} = {}} = useInstance();
  const isCriminalJustice = super_type_name === 'Criminal Justice Organization';
  const sourceList = isCriminalJustice ? criminalJobSourceList : jobSourceList;

  const formattedNearbyMSA = nearby_msa?.map((l, idx) => ({
    ...l,
    id: idx + 1,
    nearby_msa: 'Nearby Locations',
  }));

  const commonProps = {
    form: form,
    filters: filters,
    nearbyMSA: formattedNearbyMSA,
    hideCompanyFilter: hideCompanyFilter,
  };

  const getFilterForm = () => {
    if (isCriminalJustice) {
      if (source === 'honest') {
        return <HonestJobsFilterForm {...commonProps} />;
      } else {
        return <NLXFilterForm {...commonProps} />;
      }
    } else {
      if (source === 'all' || source === '' || source === 'cos') {
        return <NLXFilterForm {...commonProps} />;
      } else {
        return <LocalJobsFilterForm {...commonProps} />;
      }
    }
  };

  return (
    <FilterDrawer
      onReset={onResetClick}
      onClose={onDrawerClose}
      openFilter={isDrawerOpen}
      handleSubmit={handleSubmit}>
      <RenderFilterLayout
        source={source}
        sourceList={sourceList}
        onSourceChange={onSourceChange}>
        {getFilterForm()}
      </RenderFilterLayout>
    </FilterDrawer>
  );
};

export default FilterForm;

import React, {useEffect, useState} from 'react';
import {useAuth} from 'core/hooks';
import CountUp from 'react-countup';
import {themeColorMode} from 'mui-core';
import {InfoIconwhite} from 'assets/images';
import {styled} from '@mui/material/styles';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {Box, Divider, Stack, Slider, Typography} from '@mui/material';

const MetricDataContainer = styled(Box)(({theme}) => ({
  minHeight: 72,
  minWidth: 200,
  display: 'flex',
  borderRadius: 8,
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  border: '1px solid #601AAD',
  background: `linear-gradient(to bottom, #804FF3, #601AAD)`,
  [theme.breakpoints.only('sm')]: {
    minWidth: '30%',
  },
}));

const MetricDataLabel = styled(Typography)(({}) => ({
  paddingTop: 6,
  fontFamily: 'MyriadPro',
  color: 'white !important',
}));

const StyledDivider = styled(Divider)(({theme}) => ({
  background: theme.palette.primary.main,
}));

const HighlightedText = styled(Typography)(({theme}) => ({
  '& .highlighted-text': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.light,
  },
}));

const StyledInfoRounded = styled('img')(({}) => ({
  width: 15,
  height: 15,
  top: '5px',
  right: '5px',
  color: 'white',
  cursor: 'pointer',
  position: 'absolute',
}));

const LightTooltip = styled(({className, ...props}) => (
  <Tooltip
    arrow
    {...props}
    placement='bottom-start'
    classes={{popper: className}}
  />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 20,
    fontSize: 17,
    maxWidth: 520,
    boxShadow: theme.shadows[3],
    color: themeColorMode(theme, 'black', 'white'),
    backgroundColor: themeColorMode(
      theme,
      'white',
      theme.palette.darkGray.main,
    ),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}));

const MedianSalarySlider = styled(Slider)(() => ({
  '& .MuiSlider-thumb': {
    height: 7,
    width: 7,
  },
}));

const MetricsTiles = ({metricsData = [], blurData}) => {
  const [token] = useAuth();
  // const [hide, setHide] = useState();
  // const [complete, setComplete] = useState(false);
  // useEffect(() => {
  //   console.log('MetricsTiles -EEF', hide, blurData);
  //   if (!complete) return;
  //   if (blurData) {
  //     if (!hide) {
  //       setHide(true);
  //     }
  //   } else {
  //     if (hide) {
  //       setHide(false);
  //     }
  //   }
  // }, [blurData]); // eslint-disable-line react-hooks/exhaustive-deps

  // const onAnimationComplete = () => {
  //   console.log('MetricsTiles -complete', hide, blurData);
  //   if (blurData) {
  //     setHide(true);
  //     setComplete(true);
  //   }
  // };

  const renderMedianSalary = medianSalary => {
    const {min_wage = '', ave_wage = '', max_wage = ''} = medianSalary;
    const renderLabel = text => (
      <Typography component='span' fontWeight={700} color='primary.main'>
        {text}
      </Typography>
    );

    return (
      <Box>
        <Typography textAlign='center' fontWeight={500} color='grayText.main'>
          {renderLabel('Median')} {ave_wage}
        </Typography>
        <MedianSalarySlider
          sx={{pb: 0}}
          value={[50, 50]}
          getAriaLabel={() => 'median salary slider'}
        />
        <Stack direction='row' justifyContent='space-between'>
          <Typography fontWeight={500} color='grayText.main'>
            {renderLabel('Low')} {min_wage}
          </Typography>
          <Typography fontWeight={500} color='grayText.main'>
            {renderLabel('High')} {max_wage}
          </Typography>
        </Stack>
      </Box>
    );
  };

  const renderCountUp = (label, valueNumber, unitLabel) => {
    if (
      !valueNumber &&
      (label === 'PROJECTED SALARY' ||
        label === 'AVG. ANNUAL COST' ||
        label === 'PROGRAM LENGTH')
    ) {
      return 'Not Available';
    }

    if (label === 'PROGRAM LENGTH') {
      return (
        <>
          <CountUp delay={1} end={valueNumber} />
          <Typography color='white' variant='h5' pl={1}>
            {unitLabel}
          </Typography>
        </>
      );
    }

    if (label !== 'JOB OPENINGS') {
      return (
        <>
          <Typography color='white' variant='h5'>
            ${' '}
          </Typography>
          <CountUp delay={1} end={valueNumber} />
        </>
      );
    }
    return <CountUp delay={1} end={valueNumber} />;
  };

  return (
    <>
      {metricsData.map(
        (
          {
            label,
            value,
            valueObj,
            unitLabel,
            description,
            valueNumber,
            dataSourcetext,
          },
          idx,
        ) => (
          <MetricDataContainer key={label}>
            {label !== 'PROGRAM LENGTH' && (
              <LightTooltip
                title={
                  <Stack spacing={2}>
                    <Typography variant='h4' color='primary.main'>
                      {label}
                    </Typography>
                    <StyledDivider variant='middle' />
                    <HighlightedText
                      variant='body1'
                      dangerouslySetInnerHTML={{__html: description}}
                    />
                    {(label === 'PROJECTED SALARY' ||
                      label === 'MEDIAN SALARY') &&
                      renderMedianSalary(valueObj)}
                    <Typography variant='body2'>{dataSourcetext}</Typography>
                  </Stack>
                }>
                <StyledInfoRounded
                  src={InfoIconwhite}
                  alt={`${label}-'info-icon'`}
                />
              </LightTooltip>
            )}
            <MetricDataLabel variant='body2'>{label}</MetricDataLabel>
            <MetricDataLabel
              variant='h5'
              sx={blurData ? {filter: 'blur(5px)', userSelect: 'none'} : {}}>
              {!blurData || token ? (
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='center'>
                  {renderCountUp(label, valueNumber, unitLabel)}
                </Stack>
              ) : (
                <Typography variant='h5' color='white'>
                  #,###
                </Typography>
              )}
            </MetricDataLabel>
          </MetricDataContainer>
        ),
      )}
    </>
  );
};

export default MetricsTiles;

import {Suspense} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Routes from 'app/Routes';

import {RequestErrorLoader, ScrollReset} from 'core/components';

const SubRouter = ({modules, defaultRedirection}) => {
  const {path, url} = useRouteMatch();
  return (
    <Suspense fallback={<RequestErrorLoader body={{request: true}} />}>
      <ScrollReset>
        <Routes
          routeData={modules}
          url={url}
          defaultRedirection={defaultRedirection}
        />
      </ScrollReset>
    </Suspense>
  );
};
export default SubRouter;

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestNewOpportunities,
  receiveNewOpportunities,
  requestNewOpportunitiesError,
  clearNewOpportunities,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.NEW_OPPORTUNITIES_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.NEW_OPPORTUNITIES_RECEIVE:
      return {
        ...state,
        request: false,
        error: false,
        data: {
          Jobcount:
            (action.payload &&
              action.payload.page_details &&
              action.payload.page_details.total_results) ||
            0,
          data: (action.payload && action.payload.data) || [],
          datalength:
            (action.payload &&
              action.payload.data &&
              Array.isArray(action.payload.data) &&
              action.payload.data.length) ||
            0,
        },
      };
    case TYPES.NEW_OPPORTUNITIES_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.CLEAR_NEW_OPPROTUNITIES:
      return {
        data: null,
      };
    default:
      return state;
  }
};
export const fetchNewOpportunities = (reqParams, filters) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestNewOpportunities());
  const {sort = 'date_desc', min_perct = 50, page = 1, page_size = 10} =
    reqParams || {};
  try {
    let endPoint = `${API.srm.allJobs}`;
    const body = {};
    body['search'] = 'jobs';
    body['skills'] = 'true';
    body['page'] = page;
    body['sort_by'] = sort;
    body['page_size'] = page_size;
    body['min_perct'] = min_perct;
    if (filters) {
      const getFilterKeys = Object.keys(filters);
      if (getFilterKeys.length) {
        getFilterKeys.forEach(key => {
          if (filters[key]) {
            // endPoint += `&${key}=${filters[key]}`;
            body[key] = filters[key];
          }
        });
      }
    }
    return services
      .createUpdateRecord(token, endPoint, body, 'POST')
      .then(res => {
        if (res) {
          dispatch(receiveNewOpportunities(res));
          return;
        }
        dispatch(requestNewOpportunitiesError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestNewOpportunitiesError(err));
  }
};

export const resetNewOpportunities = () => dispatch => {
  dispatch(clearNewOpportunities());
};

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SuccessTickGreen = props => {
  return (
    <SvgIcon {...props}>
      <g clip-path='url(#clip0_6523_150646)'>
        <path
          d='M23.1315 15.2015L24.5554 13.0433C24.7758 12.7092 24.7758 12.2759 24.5554 11.9419L23.1315 9.7834C23.0791 9.70299 23.0533 9.60799 23.0579 9.51189L23.2025 6.91793C23.2246 6.52162 23.0102 6.14975 22.6561 5.97031L20.349 4.80094C20.2635 4.75786 20.1941 4.68849 20.151 4.60299L18.9868 2.29704C18.8076 1.94221 18.4353 1.72718 18.0384 1.7493L15.4456 1.89381C15.3495 1.89845 15.2545 1.87283 15.1741 1.82025L13.0098 0.395526C12.6758 0.175696 12.243 0.175898 11.9093 0.396041L9.7502 1.82025C9.66978 1.87283 9.57479 1.89845 9.47891 1.89381L6.88475 1.74923C6.48842 1.72714 6.11655 1.94156 5.93711 2.29562L4.76773 4.60299C4.72465 4.68849 4.65528 4.75786 4.56978 4.80094L2.26389 5.96499C1.90903 6.14412 1.69397 6.51644 1.71609 6.91333L1.86061 9.50637C1.86525 9.60225 1.83962 9.69724 1.78726 9.77788L0.362413 11.9421C0.142534 12.2761 0.142745 12.7089 0.362949 13.0427L1.78726 15.2015C1.83962 15.2822 1.86525 15.3769 1.86061 15.473L1.71609 18.0659C1.69397 18.4628 1.90901 18.8351 2.26384 19.0142L4.56978 20.1785C4.65528 20.2216 4.72465 20.2909 4.76773 20.3764L5.93196 22.6823C6.11113 23.0372 6.48346 23.2522 6.88036 23.2301L9.47316 23.0854H9.4957C9.58385 23.0865 9.67023 23.1119 9.74468 23.1589L11.9029 24.5829C12.237 24.8033 12.6703 24.8033 13.0043 24.5829L15.1628 23.1589C15.2432 23.1066 15.3382 23.081 15.4341 23.0854L18.0271 23.2301C18.424 23.2522 18.7964 23.0372 18.9755 22.6823L20.1397 20.3764C20.1828 20.2909 20.2522 20.2216 20.3377 20.1785L22.6434 19.0142C22.9983 18.8351 23.2133 18.4628 23.1912 18.0659L23.0466 15.473C23.0515 15.3767 23.0807 15.2833 23.1315 15.2015Z'
          fill='url(#paint0_linear_6523_150646)'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M16.0248 8.58451C16.2028 8.40716 16.4431 8.30734 16.6937 8.30664C16.9444 8.30595 17.1852 8.40443 17.3642 8.5808C17.5432 8.75717 17.6459 8.99725 17.6502 9.24914C17.6545 9.50104 17.56 9.7445 17.3871 9.9269L12.3093 16.3064C12.2221 16.4009 12.1167 16.4767 11.9996 16.5293C11.8825 16.582 11.7561 16.6103 11.6279 16.6127C11.4996 16.6151 11.3722 16.5915 11.2533 16.5432C11.1343 16.495 11.0263 16.4231 10.9356 16.332L7.57121 12.9492C7.47748 12.8614 7.40231 12.7555 7.35016 12.6379C7.29802 12.5203 7.26999 12.3933 7.26773 12.2646C7.26547 12.1358 7.28903 12.008 7.33701 11.8886C7.38499 11.7692 7.4564 11.6607 7.54699 11.5697C7.63758 11.4786 7.74549 11.4068 7.86428 11.3586C7.98306 11.3104 8.1103 11.2867 8.23839 11.289C8.36648 11.2912 8.49281 11.3194 8.60983 11.3718C8.72685 11.4242 8.83217 11.4998 8.9195 11.594L11.583 14.2698L16.0006 8.61264C16.0085 8.60274 16.017 8.59334 16.026 8.58451H16.0248Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_6523_150646'
          x1='12.4594'
          y1='0.0332031'
          x2='12.4594'
          y2='24.9462'
          gradientUnits='userSpaceOnUse'>
          <stop stop-color='#C2F01D' />
          <stop offset='1' stop-color='#91B70C' />
        </linearGradient>
        <clipPath id='clip0_6523_150646'>
          <rect width='24.9187' height='24.9187' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default SuccessTickGreen;

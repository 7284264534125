import {connect} from 'react-redux';
import {
  profileDataSelector,
  uploadUserDocs,
  deleteUserDocs,
} from 'redux/modules/profile';
import {getFilesAndTotalSize} from 'core/utils';
import DocUploader from 'app/modules/dashboard/components/DocUploader';
import profileStaticData from 'data/settings-profile.json';
import {ContentBox} from 'mui-core';
const document_type = 'related_document';
const Resume = props => {
  const {
    // updateProfile,
    profileData: {data, request, error},
  } = props;

  const {data: document_files, total_size_consumed} = getFilesAndTotalSize(
    data,
    document_type,
  );
  return (
    <ContentBox variant='outlined' sx={{p: 3}}>
      <DocUploader
        {...props}
        title={'Resumes, Cover Letter & Documents'}
        noLoader
        description={`Adding resumes, cover letters, and other documents to your profile can be an effective way to showcase your qualifications 
        and experience, enhance your credibility, and set yourself apart from other candidates.`}
        type={document_type}
        maxFileSize={profileStaticData.portfolioData.maxFileSize}
        profileData={{
          data: document_files,
          total_size_consumed,
          request,
          error,
        }}
      />
    </ContentBox>
  );
};

const mapStateToProps = state => ({
  profileData: profileDataSelector(state),
});
export default connect(mapStateToProps, {uploadUserDocs, deleteUserDocs})(
  Resume,
);

import {
  Button,
  ContentBox,
  RequestErrorLoader,
  AutoCompleteWrapper,
} from 'mui-core';
import {
  Box,
  Select,
  MenuItem,
  Typography,
  OutlinedInput,
  formHelperTextClasses,
} from '@mui/material';
import {useEffect} from 'react';
import {
  updateUserProfile,
  updatePrivacySetting,
  getPrivacySetting,
} from 'redux/modules/profile';
import {useUser} from 'core/hooks';
import {useSnackbar} from 'notistack';
import {
  getLinkedInDetails,
  fetchlinkedInDetails,
} from 'redux/modules/resume-builder';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {useDispatch, useSelector} from 'react-redux';
import {openToOptions, bannerInformation} from 'data/settings.json';
import {personalDetails, profileStatus} from 'data/dashboard-settings.json';

const {successMsg, errorMsg, saveBtn} = personalDetails;

const {
  desc,
  title,
  careerHeadlineLabel,
  jobSeekingStatusDesc,
  careerHeadlineMaxMsg,
  relatedIndustryLabel,
  jobSeekingStatusHeading,
  careerHeadlineHelperTxt,
  relatedIndustryHelperTxt,
  careerHeadlinePlaceholder,
  relatedIndustryPlaceholder,
  jobSeekingStatusPlaceholder,
} = bannerInformation;
// let headline_id = '';
const BannerInformation = ({}) => {
  // hooks
  const form = useForm();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const privacy = useSelector(getPrivacySetting) || {};
  const linkedInData = useSelector(getLinkedInDetails) || {};
  const {data: userData, request: userDataRequest} = useUser();

  // vars
  const {data: privacyData} = privacy || {};
  const {
    job_title: job_title_options = [],
    industry_name: industry_name_options = [],
  } = linkedInData;
  const {reset, getValues, handleSubmit} = form;
  const {privacy_uuid: privacyUUID, open_to: openTo} = privacyData || {};

  // effect
  useEffect(() => {
    dispatch(fetchlinkedInDetails('job_title', 'all'));
    dispatch(fetchlinkedInDetails('industry_name', 'all'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    reset({
      open_to: openTo,
      career_headline: userData?.career_headline,
      related_industry: userData?.related_industry,
    });
  }, [openTo, reset, userData?.career_headline, userData?.related_industry]);

  const onSubmit = async values => {
    const {open_to} = values;
    const relatedIndustryObj =
      Array.isArray(industry_name_options) &&
      industry_name_options.filter(
        i => i.industry_name === values.related_industry,
      )[0];
    const body = {career_headline: values?.career_headline};
    // headline_id = null;
    if (relatedIndustryObj) {
      body['related_industry'] = relatedIndustryObj.id;
    } else {
      body['related_industry'] = '';
    }
    await dispatch(
      updateUserProfile(body, success => {
        if (success)
          enqueueSnackbar(successMsg, {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        else
          enqueueSnackbar(errorMsg, {
            variant: 'error',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
      }),
    );
    if (open_to) {
      await dispatch(
        updatePrivacySetting(privacyUUID, {open_to}, res => {
          if (!res?.privacy_uuid) {
            enqueueSnackbar(errorMsg, {
              variant: 'error',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          }
        }),
      );
    }
  };
  //   const defaultIndustryValue =
  //     Array.isArray(industry_name_options) &&
  //     industry_name_options.filter(
  //       i => i.industry_name === userData.related_industry,
  //     )[0];
  return (
    <ContentBox
      elevation={0}
      style={{
        position: 'relative',
      }}
      sx={{p: 4, [`& .${formHelperTextClasses.root}`]: {marginLeft: 0}}}>
      <RequestErrorLoader
        hideEmpty
        fullScreen
        fitToParent
        body={{request: userDataRequest}}
      />
      <Typography variant='h4' color='text.primary'>
        {title}
      </Typography>
      <Typography mb={3} variant='body2' color='text.secondary'>
        {desc}
      </Typography>
      {createField('career_headline', {
        label: careerHeadlineLabel,
        form,
        helperText: careerHeadlineHelperTxt,
        rules: {
          maxLength: [200, careerHeadlineMaxMsg],
        },
        defaultValue: getValues('career_headline') || '',
      })(
        <OutlinedInput
          variant='outlined'
          id='outlined-basic'
          sx={{maxWidth: 530}}
          label={careerHeadlineLabel}
          placeholder={careerHeadlinePlaceholder}
        />,
      )}
      {/* {createField('career_headline', {
        form,
        // label: 'Career Headline',
        rules: {
          required: [true, careerHeadlineReqMsg],
        },
        defaultValue: userData?.career_headline || '',
        helperText:
          'Share a catchy career headline on your profile that sums up your skills and experience.',
      })(
        <AutoCompleteWrapper
          freeSolo
          form={form}
          // onValueSelect={v => {
          //   headline_id = v;
          // }}
          fieldName='career_headline'
          placeholder={'Example: Snr. Product Manager'}
          sx={{maxWidth: 500}}
          label='Career Headline *'
          options={
            job_title_options &&
            Array.isArray(job_title_options) &&
            job_title_options.length > 0
              ? job_title_options?.map(item => {
                  return {
                    ...item,
                    key: item.id,
                    label: item.job_title,
                    title: item.job_title,
                    career_headline: item.job_title,
                  };
                })
              : []
          }
        />,
      )} */}
      <Box sx={{height: 29}} />
      {createField('related_industry', {
        form,
        // rules: {
        //   required: [true, relatedIndReqMsg],
        // },
        helperText: relatedIndustryHelperTxt,
        defaultValue: getValues('related_industry') || null,
      })(
        <AutoCompleteWrapper
          form={form}
          sx={{maxWidth: 530}}
          fieldName='related_industry'
          label={relatedIndustryLabel}
          placeholder={relatedIndustryPlaceholder}
          options={
            industry_name_options &&
            Array.isArray(industry_name_options) &&
            industry_name_options.length > 0
              ? industry_name_options?.map(item => {
                  return {
                    ...item,
                    key: item.id,
                    label: item.industry_name,
                    title: item.industry_name,
                    related_industry: item.industry_name,
                  };
                })
              : []
          }
        />,
      )}
      <Box mt={3}>
        <Typography variant='h5' color='text.primary'>
          {jobSeekingStatusHeading}
        </Typography>
        <Typography variant='body2' color='text.secondary' mb={3}>
          {jobSeekingStatusDesc}
        </Typography>
        {createField('open_to', {
          form,
          label: jobSeekingStatusHeading,
          defaultValue: getValues('open_to') || '',
        })(
          <Select
            form={form}
            variant='outlined'
            sx={{maxWidth: 530}}
            fieldName='user_type'
            label={jobSeekingStatusHeading}>
            {openToOptions &&
              Array.isArray(openToOptions) &&
              openToOptions.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>,
        )}
      </Box>
      <Box sx={{height: 29}} />
      <Button
        size={'large'}
        variant={'outlined'}
        onClick={handleSubmit(onSubmit)}>
        {saveBtn}
      </Button>
    </ContentBox>
  );
};

export default BannerInformation;

import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import {NavLink} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Grid, Typography} from '@mui/material';
import {themeColorMode} from 'mui-core';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import {CheckStar} from 'mui-core/Icons';

const StyledListItemButton = styled(ListItemButton)(({theme}) => {
  return {
    borderRadius: 10,
    marginBottom: 3,
  };
});
const StyledListItem = styled(ListItem)(({theme}) => ({
  display: 'inline-flex',
  width: 'auto',
  minWidth: 300,
}));
const StyledListItemText = styled(ListItemText)(({theme}) => ({
  textTransform: 'capitalize',
}));

const scrollToContent = () => {
  const profileView = document.getElementById('profile-sub-view');
  if (profileView) {
    const {top} = profileView.getBoundingClientRect();
    if (top) {
      setTimeout(() => {
        window.scroll({top: top - 160, behavior: 'smooth'});
      }, 300);
    }
  }
};

const Item = ({module, key, completed}) => {
  return (
    <StyledListItem disablePadding key={key}>
      <StyledListItemButton
        to={`/dashboard/profile${module.path}`}
        activeClassName={'Mui-selected'}
        onClick={scrollToContent}
        dense
        component={NavLink}>
        <ListItemIcon sx={{minWidth: 34}}>
          {completed ? (
            <CheckStar />
          ) : (
            <CheckBoxOutlineBlankOutlinedIcon
              sx={{
                bgcolor: 'grey.200',
                border: '1px solid',
                borderColor: 'grey.200',
                borderRadius: '4px',
                color: 'grey.200',
                width: 18,
                height: 18,
              }}
            />
          )}
        </ListItemIcon>
        <StyledListItemText
          primary={
            <Typography
              variant='body1'
              sx={[
                theme => ({
                  color: themeColorMode(theme, 'grey.700', 'grey.400'),
                }),
              ]}>
              {module.label?.toLowerCase()}
            </Typography>
          }
        />
      </StyledListItemButton>
    </StyledListItem>
  );
};

const Lists = ({subModules, completedTabs}) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={6}>
        <List>
          {subModules.slice(0, 6).map(module => (
            <Item
              module={module}
              key={module.key}
              completed={completedTabs[module.key]}
            />
          ))}
        </List>
      </Grid>
      <Grid xs={12} sm={6}>
        <List>
          {subModules.slice(6, subModules.length).map(module => (
            <Item
              module={module}
              key={module.key}
              completed={completedTabs[module.key]}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default Lists;

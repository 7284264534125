import {Fragment} from 'react';
import Carousel from 'react-slick';
import {ErrorBoundary} from 'core/components';
import {Box, Stack, styled} from '@mui/material';

const settings = {
  autoplay: false,
  arrows: true,
  dots: false,
  infinite: false,
  lazyLoad: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  //   nextArrow: <Next />,
  //   prevArrow: <Prev />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& a`]: {
    color: theme.palette.secondary.dark,
  },
  [`& .slick-slider .slick-dots button:before`]: {
    display: 'none',
  },
  [`& .slick-slider .slick-dots`]: {
    bottom: 14,
  },
  [`& .slick-slider .slick-dots li`]: {
    width: 'initial',
    height: 7,
  },
  [`& .slick-slider .slick-dots button`]: {
    height: 7,
    padding: 0,
    borderRadius: 5,
    width: 20,
    border: '1px solid #fff',
    boxShadow:
      '0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)',
  },
  [`& .slick-slider .slick-dots li.slick-active button`]: {
    backgroundColor: 'white',
  },
}));

const CarouselSlider = ({data = [], config = {}, className = ''}) => {
  if (config?.slidesToShow > data.length) {
    config.slidesToShow = data.length;
  }

  if (
    config?.rows &&
    Math.round(data.length / config.rows) <= config?.slidesToShow
  ) {
    if (config?.rows > 1) {
      className = ' smallView';
    }
    if (config?.rows == 1) {
      className = 'smallerView smallView';
    }
  }

  const sliderConfig = Object.assign({}, settings, config);

  if (sliderConfig?.slidesToShow <= 0) {
    return <></>;
  }

  return (
    data &&
    Array.isArray(data) &&
    data?.length && (
      <ErrorBoundary nameOfComponent='core-carousel'>
        <StyledBox>
          <Carousel className={`${className}`} {...sliderConfig}>
            {data.map((slide, idx) => (
              <Fragment key={`slide-${idx}`}>{slide}</Fragment>
            ))}
          </Carousel>
        </StyledBox>
      </ErrorBoundary>
    )
  );
};

export default CarouselSlider;

/* Try with following example
==============================================================
* Ref Doc: https://react-slick.neostack.com/docs/get-started  *
==============================================================
*   <Carousel                       *
*    data={[                        *
*      <div>Slide 1</div>,          *
*      <div>Slide 2</div>,          *
*      <div>Slide 3</div>,          *
*      <div>Slide 4</div>,          *
*      <div>Slide 5</div>,          *
*    ]}                             *
*    config={{ slidesToShow: 3 }}   *
*   />                              *
=====================================
*/

import {useState} from 'react';
import {useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import {getResumeList} from 'redux/modules/resumes';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import {Typography, Box, TextField, Stack, IconButton} from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const StyledEditIcon = styled(ModeEditOutlinedIcon)(({theme}) => ({
  width: 20,
  height: 20,
  marginLeft: 4,
  cursor: 'pointer',
  marginTop: '-8px',
  color: theme.palette.grayText.main,
}));

const ResumeName = ({resumeName, handleSave}) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [name, setName] = useState(resumeName || '');
  const [isEditMode, setIsEditMode] = useState(false);
  const {data: resumesList = []} = useSelector(getResumeList) || {};

  const onEditClick = () => {
    setIsEditMode(true);
  };

  const onInputChange = event => {
    const {value} = event.target;
    setName(value);
    setErrorMsg('');
  };

  const onSaveClick = () => {
    if (!name) {
      setErrorMsg('Please enter resume name');
    } else if (!isNameUnique(name)) {
      setErrorMsg('Resume name already exists');
    } else {
      handleSave(name);
      setIsEditMode(false);
    }
  };

  const onCancelClick = () => {
    setIsEditMode(false);
    setName(resumeName);
  };

  const isNameUnique = resumeName => {
    const existingNames = resumesList.map(resume =>
      resume.resume_name?.toLowerCase(),
    );
    return !existingNames.includes(resumeName.toLowerCase());
  };

  return (
    <>
      {!isEditMode ? (
        <Stack direction='row'>
          <Typography variant='h3' color='text.primary'>
            {resumeName}
          </Typography>
          <StyledEditIcon onClick={onEditClick} />
        </Stack>
      ) : (
        <Stack direction='row'>
          <TextField
            required
            variant='outlined'
            error={!!errorMsg}
            label='Resume Name'
            defaultValue={name}
            id='outlined-required'
            onChange={onInputChange}
            helperText={errorMsg || ''}
            sx={{
              width: {xs: '200px', md: '250px'},
            }}
          />
          <Box mt={1} ml={1}>
            <IconButton color='success' onClick={onSaveClick}>
              <CheckCircleOutlinedIcon />
            </IconButton>
            <IconButton color='error' sx={{ml: 1}} onClick={onCancelClick}>
              <CancelOutlinedIcon />
            </IconButton>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default ResumeName;

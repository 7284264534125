// RESUME
export const RESUME_LIST_ERROR = 'RESUME_LIST_ERROR';
export const RESUME_LIST_REQUEST = 'RESUME_LIST_REQUEST';
export const RESUME_LIST_RECIEVE = 'RESUME_LIST_RECIEVE';
export const RESUME_LIST__CLEAR = 'RESUME_LIST__CLEAR';
// UPDATE RESUME
export const UPDATE_RESUME_LIST_ERROR = 'UPDATE_RESUME_LIST_ERROR';
export const UPDATE_RESUME_LIST_REQUEST = 'UPDATE_RESUME_LIST_REQUEST';
export const UPDATE_RESUME_LIST_RECIEVE = 'UPDATE_RESUME_LIST_RECIEVE';
export const UPDATE_RESUME_LIST__CLEAR = 'UPDATE_RESUME_LIST__CLEAR';

import React, {useState} from 'react';
import {
  Box,
  OutlinedInput,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {PATTERN} from 'core/regex';
import {createField} from 'mui-core/Form';
import {useForm} from 'react-hook-form';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {Button, Grid} from 'mui-core';
import Typography from '@mui/material/Typography';
import {useUser, useEmailTrigger} from 'core/hooks';
import moment from 'moment';
import profileData from 'data/settings-profile.json';
import {isArray, debounce} from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import {useSnackbar} from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

let updatedName = '';

const PersonalInfo = ({
  nativeLanguageData,
  ethnicityData,
  updateUserProfile,
  statesList,
  API,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const {data: userData, request} = useUser();
  const {onEmailVerify} = useEmailTrigger();
  const {
    last_name,
    first_name,
    ethnicity,
    dob,
    native_language,
    citizenship,
    phone_number,
    email,
    email_verified = false,
    gender,
    alternate_email_id,
    username,
    address,
  } = userData || {};
  const {city, state, zip} = address || {};
  //form
  const form = useForm();
  const {handleSubmit, clearErrors, setError} = form;
  //state
  const [usernameState, setUserNameState] = useState({
    request: false,
    message: '',
  });
  const [verifyRequest, setVerifyRequest] = useState(false);
  //other
  const {enqueueSnackbar} = useSnackbar();
  const {student_details = ''} = API.gps || {};
  const {data: nativeLanguageList} = nativeLanguageData;
  const {data: ethnicityList} = ethnicityData;

  // handlers
  const onSubmit = async (data = {}) => {
    updatedName = data.username;
    const formData = {
      username: data.username,
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      alternate_email_id: data.alternate_email_id,
      phone_number: data.phone_number,
    };
    if (data.dob) {
      try {
        formData['dob'] = moment(data.dob).format('YYYY-MM-DD');
      } catch (e) {
        console.error('dob catch');
      }
    }
    if (data.city && data.zip && data.state) {
      const state = statesList.filter(i => i.name === data.state)[0];
      if (state) {
        formData.address = {
          city: data.city,
          state: state.state_uuid,
          zip: data.zip,
        };
      }
    }
    if (data.native_language) {
      let native_language_id =
        nativeLanguageList &&
        isArray(nativeLanguageList) &&
        nativeLanguageList.length > 0
          ? nativeLanguageList.find(item => item.name === data.native_language)
              .native_lang_uuid
          : null;
      if (native_language_id) {
        formData['native_language'] = native_language_id;
      }
    }
    if (data.ethnicity) {
      let ethnicity_id =
        ethnicityList && isArray(ethnicityList) && ethnicityList.length > 0
          ? ethnicityList.find(item => item.name === data.ethnicity)
              .ethnicity_uuid
          : null;
      if (ethnicity_id) {
        formData['ethnicity'] = ethnicity_id;
      }
    }

    await updateUserProfile(formData, success => {
      if (success)
        enqueueSnackbar('Saved Successfully.', {
          variant: 'success',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
    });
  };
  const onVerifyEmail = async () => {
    setVerifyRequest(true);
    try {
      const res = await onEmailVerify();
      if (res?.Error) {
        enqueueSnackbar(res.Error ? res.Error : 'Error in network', {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
      if (res?.Success) {
        enqueueSnackbar(`Confimation email has been sent to ${email} id.`, {
          variant: 'success',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    } catch (err) {
      setVerifyRequest(false);
    }

    setVerifyRequest(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Grid container rowSpacing={{xs: 3, sm: 5}} columnSpacing={7}>
            <Grid xs={12} sm={4}>
              {createField('first_name', {
                label: 'First Name',
                form,
                rules: {
                  required: [true, profileData.nameData.firstName.requiredMsg],
                },
                defaultValue: first_name || '',
              })(
                <OutlinedInput
                  id='outlined-basic'
                  label='First Name'
                  variant='outlined'
                />,
              )}
            </Grid>
            <Grid xs={12} sm={4}>
              {createField('last_name', {
                label: 'Last Name',
                form,
                rules: {
                  required: [true, profileData.nameData.lastName.requiredMsg],
                },
                defaultValue: last_name || '',
              })(
                <OutlinedInput
                  id='outlined-basic'
                  label='Last Name'
                  variant='outlined'
                />,
              )}
            </Grid>
            <Grid xs={12} sm={4}>
              {createField('dob', {
                form,
                // label: 'Birth Date',
                rules: {
                  required: [true, 'Date of birth is required.'],
                },
                defaultValue: dob ? moment(dob) : dob,
              })(
                <DatePicker
                  label='Birth Date'
                  maxDate={moment()}
                  // defaultValue={moment(dob)}
                />,
              )}
            </Grid>
            <Grid xs={12} sm={4}>
              {createField('gender', {
                label: 'Gender',
                form,
                rules: {
                  required: [true, profileData.aboutMeData.gender.requiredMsg],
                },
                defaultValue: gender,
              })(
                <Select
                  variant='outlined'
                  label='Gender'
                  sx={{
                    fontSize: isMobile ? '12px' : '15px',
                  }}>
                  <MenuItem key='male' value='male'>
                    {profileData.aboutMeData.gender.options.male}
                  </MenuItem>
                  <MenuItem key='female' value='female'>
                    {profileData.aboutMeData.gender.options.female}
                  </MenuItem>
                  <MenuItem key='other' value='other'>
                    {profileData.aboutMeData.gender.options.other}
                  </MenuItem>
                  <MenuItem key='decline to state' value='decline to state'>
                    {profileData.aboutMeData.gender.options.declineToState}
                  </MenuItem>
                </Select>,
              )}
            </Grid>
            <Grid xs={12} sm={4}>
              {createField('native_language', {
                label: 'Native Language',
                form,
                defaultValue: native_language,
              })(
                <Select
                  variant='outlined'
                  label='Native Language'
                  sx={{
                    fontSize: isMobile ? '12px' : '15px',
                  }}>
                  {nativeLanguageList &&
                    isArray(nativeLanguageList) &&
                    nativeLanguageList.length > 0 &&
                    nativeLanguageList.map(
                      (item, index) =>
                        item && (
                          <MenuItem value={item.name} key={index}>
                            {item.name}
                          </MenuItem>
                        ),
                    )}
                </Select>,
              )}
            </Grid>
            <Grid xs={12} sm={4}>
              {createField('ethnicity', {
                label: 'Ethnicity',
                form,
                rules: {
                  required: [
                    true,
                    profileData.aboutMeData.raceEthnicity.requiredMsg,
                  ],
                },
                defaultValue: ethnicity,
              })(
                <Select
                  variant='outlined'
                  label='Ethnicity'
                  sx={{
                    fontSize: isMobile ? '12px' : '15px',
                  }}>
                  {ethnicityList &&
                    isArray(ethnicityList) &&
                    ethnicityList.length > 0 &&
                    ethnicityList.map(
                      (item, index) =>
                        item && (
                          <MenuItem value={item.name} key={index}>
                            {item.name}
                          </MenuItem>
                        ),
                    )}
                </Select>,
              )}
            </Grid>
            <Grid xs={12} sm={4}>
              {createField('phone_number', {
                label: 'Phone Number',
                form,
                rules: {
                  pattern: [
                    PATTERN?.Phone,
                    profileData.aboutMeData.myPhoneNumber.requiredMsg,
                  ],
                  required: [true, 'Required!'],
                },
                defaultValue: phone_number,
              })(
                <OutlinedInput
                  id='outlined-basic'
                  label='Phone Number'
                  variant='outlined'
                />,
              )}
            </Grid>
          </Grid>
          <Grid container rowSpacing={{xs: 3, sm: 5}} columnSpacing={7}>
            <Grid xs={12} sm={4}>
              {createField('city', {
                label: 'City',
                form,
                defaultValue: city,
              })(
                <OutlinedInput
                  id='outlined-basic'
                  label='City'
                  variant='outlined'
                />,
              )}
            </Grid>
            <Grid xs={12} sm={4}>
              {createField('state', {
                label: 'State',
                form,
                defaultValue: state,
              })(
                <Select
                  variant='outlined'
                  label='State'
                  sx={{
                    fontSize: isMobile ? '12px' : '15px',
                  }}>
                  {statesList &&
                    isArray(statesList) &&
                    statesList.length > 0 &&
                    statesList.map(item => (
                      <MenuItem value={item.name} key={item.state_uuid}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>,
              )}
            </Grid>
            <Grid xs={12} sm={4}>
              {createField('zip', {
                label: 'ZipCode',
                form,
                defaultValue: zip,
                rules: {
                  pattern: [
                    PATTERN?.Zip_Code,
                    profileData.aboutMeData.zip.validationMsg,
                  ],
                },
              })(
                <OutlinedInput
                  id='outlined-basic'
                  type={'number'}
                  label='ZipCode'
                  variant='outlined'
                />,
              )}
            </Grid>
          </Grid>
          <Grid container rowSpacing={5} columnSpacing={3}>
            <Grid xs={7} sm={5}>
              {createField('email', {
                label: 'Email Address',
                form,
                defaultValue: email,
                disabled: true,
              })(
                <OutlinedInput
                  id='outlined-basic'
                  label='Email Address'
                  variant='outlined'
                />,
              )}
            </Grid>
            <Grid xs={5} sm={4} alignItems='center'>
              <Button
                variant={'outlined'}
                onClick={!email_verified ? onVerifyEmail : () => {}}
                color={email_verified ? 'success' : 'primary'}
                disableRipple={email_verified}
                loading={verifyRequest}
                startIcon={email_verified && <CheckCircleIcon />}
                size='large'
                sx={{
                  fontSize: isMobile ? '9px' : '14px',
                  height: '90%',
                }}>
                {email_verified === false ? 'Verify Email' : 'Verified'}
              </Button>
            </Grid>
          </Grid>
          <Grid container rowSpacing={5} columnSpacing={3}>
            <Grid xs={8} sm={5}>
              {createField('alternate_email_id', {
                label: 'Secondary Email Address',
                form,
                rules: {
                  pattern: [
                    PATTERN?.Email,
                    profileData.aboutMeData.secondaryEmail.requiredMsg,
                  ],
                },
                defaultValue: alternate_email_id,
              })(
                <OutlinedInput
                  id='outlined-basic'
                  label='Secondary Email Address'
                  variant='outlined'
                />,
              )}
            </Grid>
          </Grid>
          <Grid container rowSpacing={5} columnSpacing={3}>
            <Grid>
              <Box mb={1}>
                <Typography color={'text.secondary'} variant='body1'>
                  * Profile URL
                </Typography>
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  alignItems='center'
                  gap={1}>
                  <Typography
                    color={'text.primary'}
                    variant='subtitle2'
                    sx={{
                      fontSize: isMobile ? '9px' : '16px',
                    }}>
                    https://www.goeducate.com/profile/
                  </Typography>
                  {createField('username', {
                    form,
                    rules: {
                      required: [true, 'Required'],
                      validate: [
                        debounce(async value => {
                          if (
                            !value ||
                            !PATTERN.UserName.test(value) ||
                            (!updatedName
                              ? userData?.username === value
                              : value === updatedName)
                          ) {
                            clearErrors('username');
                            return true;
                          }

                          clearErrors('username');
                          setUserNameState({request: true, message: ''});
                          try {
                            const data = await fetch(
                              `${student_details}?check_username=${value}`,
                            ).then(res => res.json());
                            if (data && data.Success) {
                              setUserNameState({
                                request: false,
                                message: '',
                              });
                              return true;
                            }
                            // setLoading(false);
                            setUserNameState({
                              request: false,
                              message:
                                data && data.Error
                                  ? data.Error
                                  : 'Username error.',
                            });
                            setError('username', {type: 'validate'});
                            return false;
                          } catch (err) {
                            setUserNameState({
                              request: false,
                              message: 'Failed to fetch',
                            });
                            setError('username', {type: 'validate'});
                            return false;
                          }
                        }, 1000),
                        usernameState.message,
                      ],
                    },
                    defaultValue: username,
                  })(
                    <OutlinedInput
                      id='outlined-basic'
                      endAdornment={
                        usernameState.request && (
                          <InputAdornment position='end'>
                            <CircularProgress color='warning' size={20} />
                          </InputAdornment>
                        )
                      }
                      variant='outlined'
                      size='small'
                      sx={{width: 100}}
                    />,
                  )}
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Grid container rowSpacing={5} columnSpacing={3}>
            <Grid>
              <Button
                variant='contained'
                formNoValidate
                loading={request}
                color='primary'
                type='submit'
                sx={{minWidth: 200}}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
};

export default PersonalInfo;

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const StarIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d='M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z' />
    </SvgIcon>
  );
};

export default StarIcon;

import * as TYPES from './constants';
import {API, DEFAULT_PAGE_SIZE} from 'config';

import {
  clearPathSearch,
  requestPathsSearch,
  recievePathsSearch,
  requestPathsSearchError,
  recieveNewestPathsSearch,
  // Popular Paths
  requestPopularPaths,
  recievePopularPaths,
  requestPopularPathsError,
  // In-Demand Paths
  requestInDemandPaths,
  recieveInDemandPaths,
  requestInDemandPathsError,
  recievePathsFilterOptions,
  requestPathsFilterOptions,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {
  request: false,
  data: null,
  newestData: null,
  popularData: null,
  inDemandData: null,
  error: false,
  initialLoad: true,
  filterOptions: null,
  filterOptionsRequest: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PATHS_SEARCH_REQUEST:
      return {
        ...state,
        request: true,
        pathsSearchRequest: true,
        error: false,
      };
    case TYPES.NEWEST_PATHS_SEARCH_SUCCESS:
      let newestData = action.payload;
      return {
        ...state,
        request: false,
        error: false,
        newestData,
      };
    case TYPES.PATH_FILTERS_OPTION_REQUEST:
      return {
        ...state,
        filterOptionsRequest: true,
      };
    case TYPES.PATH_FILTERS_OPTION_SUCCESS:
      return {
        ...state,
        filterOptionsRequest: false,
        filterOptions: action.payload,
      };
    case TYPES.POPULAR_PATH_SUCCESS:
      let popularData = action.payload;
      return {
        ...state,
        request: false,
        error: false,
        popularData,
      };
    case TYPES.INDEMAND_PATH_SUCCESS:
      let inDemandData = action.payload;
      return {
        ...state,
        inDemandData,
        error: false,
        request: false,
      };
    case TYPES.PATHS_SEARCH_SUCCESS:
      let data = action.payload;
      if (state.data && state.data.path_details) {
        data.path_details = [...state.data.path_details, ...data.path_details];
      }
      return {
        ...state,
        request: false,
        pathsSearchRequest: false,
        error: false,
        initialLoad: false,
        data,
      };
    case TYPES.PATHS_SEARCH_CLEAR:
      let retainData = {};
      if (state.data?.cluster_associated) {
        retainData = {
          cluster_associated: state.data.cluster_associated,
        };
      }
      if (state.data && state.data.connected_institutions) {
        retainData = {
          ...retainData,
          connected_institutions: state.data.connected_institutions,
        };
      }
      if (state.data && state.data.duration_list) {
        retainData = {
          ...retainData,
          duration_list: state.data.duration_list,
        };
      }
      if (state.data && state.data.award_type_list) {
        retainData = {
          ...retainData,
          award_type_list: state.data.award_type_list,
        };
      }
      return {
        ...state,
        error: false,
        pathsSearchRequest: false,
        request: false,
        newestData: null,
        popularData: null,
        inDemandData: null,
        data: Object.keys(retainData).length > 0 ? retainData : null,
      };
    case TYPES.PATHS_SEARCH_ERROR:
      return {
        ...state,
        request: false,
        pathsSearchRequest: false,
        error: true,
      };
    case TYPES.RESET_INITIAL_LOAD:
      return {
        ...state,
        initialLoad: true,
        pathsSearchRequest: false,
      };

    default:
      return state;
  }
};

export const fetchPathsSearch = (
  params = {},
  filters = [],
  pageNo = 1,
) => async (dispatch, getState) => {
  const pageSize = DEFAULT_PAGE_SIZE;

  if (pageNo === 1) {
    dispatch(clearPathSearch());
  }

  dispatch(requestPathsSearch());
  try {
    if (!params.type && !params.value) {
      dispatch(recievePathsSearch({path_details: []}));
      return;
    }
    let cluster_associated = null;
    if (params?.type === 'cluster_name') {
      const ep = `${API.srm.clusters}?${params.type}=${encodeURIComponent(
        params.value,
      )}`;
      cluster_associated = await services.fetchRecords(ep, null, true);
    }
    let endPoint = `${API.srm.pathways}?${params.type}=${encodeURIComponent(
      params.value,
    )}&page_size=${pageSize}&page=${pageNo}`;
    if (filters && filters.length) {
      filters.forEach(filter => {
        endPoint = `${endPoint}&${filter.type}=${encodeURIComponent(
          filter.title,
        )}`;
      });
    }
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res && res.path_details && res.page_details) {
        res.cluster_associated = cluster_associated?.[0] || null;
        dispatch(recievePathsSearch(res));
        return;
      }
      dispatch(requestPathsSearchError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestPathsSearchError(err));
  }
};

const getPathSearchEndPoint = filters => {
  let ep = `${API.srm.pathways}?`;
  let query = '';
  const keys = Object.keys(filters);
  if (keys.length > 0) {
    // console.log('path_search filter keys', keys);
    keys.forEach(key => {
      if (filters[key] && filters[key] !== 'all') {
        if (key === 'type') {
          query += `${filters[key]}=true&`;
        } else {
          query += `${key}=${encodeURIComponent(filters[key])}&`;
        }
      }
    });
  }
  ep += query;
  return ep;
};

export const fetchPathFilterOptions = (filters = {}) => async (
  dispatch,
  getState,
) => {
  dispatch(requestPathsFilterOptions());
  try {
    let ep = getPathSearchEndPoint(filters);
    return services.fetchRecords(ep, null, true).then(res => {
      dispatch(recievePathsFilterOptions(res));
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchPathsSearchWithFilters = (filters = {}) => async (
  dispatch,
  getState,
) => {
  const pageSize = DEFAULT_PAGE_SIZE;

  if (filters && filters.page === 1) {
    dispatch(clearPathSearch());
  }

  dispatch(requestPathsSearch());
  try {
    let ep = getPathSearchEndPoint(filters);
    // console.log('path_search - query & filters', query, filters);
    // if (!params.type && !params.value) {
    //   dispatch(recievePathsSearch({path_details: []}));
    //   return;
    // }
    // let cluster_associated = null;
    // if (params?.type === 'cluster_name') {
    //   const ep = `${API.srm.clusters}?${params.type}=${encodeURIComponent(
    //     params.value,
    //   )}`;
    //   cluster_associated = await services.fetchRecords(ep, null, true);
    // }
    // let endPoint = `${API.srm.pathways}?${params.type}=${encodeURIComponent(
    //   params.value,
    // )}&page_size=${pageSize}&page=${pageNo}`;
    // if (filters && filters.length) {
    //   filters.forEach(filter => {
    //     endPoint = `${endPoint}&${filter.type}=${encodeURIComponent(
    //       filter.title,
    //     )}`;
    //   });
    // }
    return services.fetchRecords(ep, null, true).then(res => {
      if (res && res.path_details && res.page_details) {
        // res.cluster_associated = cluster_associated?.[0] || null;
        dispatch(recievePathsSearch({...res, currentPage: filters['page']}));
        return;
      }
      dispatch(requestPathsSearchError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestPathsSearchError(err));
  }
};

export const fetchNewestPrograms = (pageSize, pageNo = 1, sort_by = '') => (
  dispatch,
  getState,
) => {
  const page_size = pageSize ? pageSize : DEFAULT_PAGE_SIZE;
  dispatch(requestPathsSearch());
  try {
    let endPoint = `${API.srm.pathways}?page_size=${page_size}&page=${pageNo}&sort_by=${sort_by}&newest_path=true`;
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res && res.path_details && res.page_details) {
        dispatch(recieveNewestPathsSearch(res));
        return;
      }
      dispatch(requestPathsSearchError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestPathsSearchError(err));
  }
};

export const fetchPopularPrograms = (pageSize, pageNo = 1, sort_by = '') => (
  dispatch,
  getState,
) => {
  const page_size = pageSize ? pageSize : DEFAULT_PAGE_SIZE;
  dispatch(requestPopularPaths());
  try {
    let endPoint = `${API.srm.pathways}?page_size=${page_size}&page=${pageNo}&sort_by=${sort_by}&popular_path=true`;
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res && res.path_details && res.page_details) {
        dispatch(recievePopularPaths(res));
        return;
      }
      dispatch(requestPopularPathsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestPopularPathsError(err));
  }
};

export const fetchInDemandPrograms = (pageSize, pageNo = 1, sort_by = '') => (
  dispatch,
  getState,
) => {
  const page_size = pageSize ? pageSize : DEFAULT_PAGE_SIZE;
  dispatch(requestInDemandPaths());
  try {
    let endPoint = `${API.srm.pathways}?page_size=${page_size}&page=${pageNo}&sort_by=${sort_by}&in_demand=true`;
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res && res.path_details && res.page_details) {
        dispatch(recieveInDemandPaths(res));
        return;
      }
      dispatch(requestInDemandPathsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestInDemandPathsError(err));
  }
};

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestLinkedInDetails,
  recieveLinkedInDetails,
  linkedInDetailsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();
const defaultMinimumCharacter = 2;
const initialState = {
  job_title_request: false,
  skill_name_request: false,
  degree_name_request: false,
  field_of_study_request: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LINKEDIN_DETAILS_REQUEST:
      return {
        ...state,
        // request: true,
        error: false,
        ...action.payload,
      };
    case TYPES.LINKEDIN_DETAILS_RECEIVE:
      return {
        ...state,
        // request: false,
        error: false,
        ...action.payload,
      };
    case TYPES.LINKEDIN_DETAILS_ERROR:
      return {
        ...state,
        // request: false,
        error: true,
      };
    case TYPES.LINKEDIN_DETAILS_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchlinkedInDetails = (key, keyword) => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  try {
    let endPoint = `${API.gps.linkedin_details}`;
    dispatch(requestLinkedInDetails({[`${key}_request`]: true}));
    return services
      .fetchRecords(`${endPoint}/?${key}=${keyword}`, token, true)
      .then(res => {
        if (res) {
          dispatch(
            recieveLinkedInDetails({[key]: res, [`${key}_request`]: false}),
          );
          return;
        }
        dispatch(linkedInDetailsError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(linkedInDetailsError(err));
  }
};

import {useEffect} from 'react';
// import {isEmpty} from 'lodash';
import {connect} from 'react-redux';
import {Stack} from '@mui/material';
import {ContentBox} from 'mui-core';
import SavedPrograms from './components/SavedPrograms';
import ActivePrograms from './components/ActivePrograms';
import DashboardStats from './components/DashboardStats';
import {
  fetchStudentMetrics,
  studentMetricsSelector,
} from 'redux/modules/profile';
import {
  //api calls
  fetchMyPlan,
  fetchSavedPaths,
  fetchActivePaths,
  //actions
  clearMyPlan,
  removeSavedPath,
  deleteActivePath,
  //selectors
  getMyPlan,
  getActivePath,
  savedPathSelector,
} from 'redux/modules/pathways';

const Dashboard = props => {
  const {
    savedPaths,
    activePaths,
    studentMetrics,
    removeSavedPath,
    fetchSavedPaths,
    fetchActivePaths,
    fetchStudentMetrics,
    deleteActivePath,
  } = props;
  const {data: activePathData, request: activePathRequest} = activePaths || {};
  const {data: savedProgramsData, request: savedProgramsRequest} = savedPaths;
  // const {
  //   data: studentMetricsData,
  //   request: studentMetricsRequest,
  // } = studentMetrics;
  const initScreen = () => {
    (!activePathData || !activePathData?.length) &&
      !activePathRequest &&
      fetchActivePaths();
    (!savedProgramsData || !savedProgramsData?.length) &&
      !savedProgramsRequest &&
      fetchSavedPaths();
    // (!studentMetricsData || isEmpty(studentMetricsData)) &&
    //   !studentMetricsRequest &&
    fetchStudentMetrics(); // we need to make a fresh fetch everytime, to show the updated Job Metrics data
  };
  useEffect(() => {
    initScreen();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack spacing={3}>
      <ContentBox variant='outlined'>
        <DashboardStats studentMetrics={studentMetrics} />
      </ContentBox>
      <ContentBox variant='outlined'>
        <ActivePrograms
          activePaths={activePaths}
          fetchActivePaths={fetchActivePaths}
          deleteActivePath={deleteActivePath}
          fetchStudentMetrics={fetchStudentMetrics}
        />
      </ContentBox>
      <ContentBox variant='outlined'>
        <SavedPrograms
          savedPaths={savedPaths}
          fetchSavedPaths={fetchSavedPaths}
          removeSavedPath={removeSavedPath}
        />
      </ContentBox>
    </Stack>
  );
};

const mapStateToProps = state => ({
  myCoursePlan: getMyPlan(state),
  activePaths: getActivePath(state),
  savedPaths: savedPathSelector(state),
  studentMetrics: studentMetricsSelector(state),
});

export default connect(mapStateToProps, {
  fetchMyPlan,
  clearMyPlan,
  fetchSavedPaths,
  fetchActivePaths,
  removeSavedPath,
  deleteActivePath,
  fetchStudentMetrics,
})(Dashboard);

import React from 'react';
import {Close} from '@mui/icons-material';
import {
  Box,
  Stack,
  Drawer,
  Divider,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {Button} from 'mui-core';

export const FilterDrawer = ({
  children,
  onClose,
  onReset,
  handleSubmit,
  openFilter = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Drawer
      PaperProps={{
        sx: {
          top: 105,
          minHeight: 360,
          position: 'fixed',
          height: 'fit-content',
          backgroundColor: theme.palette.background.paper,
          maxHeight: 'calc(100vh - 105px)', // Ensure the drawer doesn't exceed the screen height
        },
      }}
      anchor='right'
      open={openFilter}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile
        disableScrollLock: true, // Allow background scrolling
      }}>
      <form onSubmit={handleSubmit}>
        <Stack
          pb={2}
          spacing={2}
          sx={{
            height: '100%',
            width: {xs: 350, sm: 450},
          }}>
          <Box
            p={3}
            pb={0}
            top={0}
            zIndex={2}
            position='sticky'
            bgcolor={theme.palette.background.paper}>
            <Stack
              pb={1}
              direction='row'
              alignItems='center'
              justifyContent='space-between'>
              <Typography variant='h3' color='text.primary'>
                Filters
              </Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
            <Divider />
          </Box>
          <Box p={3}>{children}</Box>
          <Stack
            spacing={2}
            alignItems={'center'}
            justifyContent={{xs: 'center', sm: 'start'}}>
            <Button
              size='medium'
              type='submit'
              formNoValidate
              textAlign='center'
              variant='contained'
              width='fit-content'
              sx={!isMobile ? {height: '100%', minWidth: 160} : {}}>
              <Typography variant='body1' color={'#ffffff'}>
                Show Results
              </Typography>
            </Button>
            <Button size='medium' sx={{height: '100%'}} onClick={onReset}>
              <Typography variant='body1'>Clear Filters</Typography>
            </Button>
          </Stack>
        </Stack>
      </form>
    </Drawer>
  );
};

export default FilterDrawer;

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EducationAward = props => {
  return (
    <SvgIcon
      {...props}
      viewBox='0 0 41 41'
      fontSize='inherit'
      style={{fontSize: props.fontSize || '1em'}}>
      <g id='Education / award-03'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='41'
          height='41'
          viewBox='0 0 41 41'
          fill='none'>
          <path
            d='M13.4423 26.412L11.9583 37.5846L19.7969 32.8815C20.0525 32.7281 20.1803 32.6514 20.3168 32.6215C20.4375 32.595 20.5625 32.595 20.6832 32.6215C20.8197 32.6514 20.9475 32.7281 21.2031 32.8815L29.0417 37.5846L27.5587 26.4072M28.0609 7.25981C28.3247 7.8979 28.8311 8.4051 29.4688 8.6699L31.7048 9.59613C32.343 9.86046 32.8499 10.3675 33.1143 11.0056C33.3786 11.6438 33.3786 12.3608 33.1143 12.9989L32.1887 15.2334C31.9243 15.8718 31.9239 16.5896 32.1896 17.2277L33.1135 19.4615C33.2445 19.7776 33.312 20.1164 33.312 20.4585C33.3121 20.8007 33.2447 21.1395 33.1138 21.4556C32.9829 21.7717 32.7909 22.0589 32.549 22.3008C32.307 22.5427 32.0197 22.7346 31.7036 22.8654L29.4692 23.7909C28.8311 24.0548 28.3239 24.5612 28.0592 25.1989L27.133 27.435C26.8686 28.0732 26.3617 28.5802 25.7235 28.8445C25.0854 29.1088 24.3684 29.1088 23.7303 28.8445L21.4959 27.9189C20.8578 27.6553 20.141 27.6558 19.5033 27.9204L17.2673 28.8453C16.6295 29.1091 15.9131 29.1088 15.2755 28.8447C14.6379 28.5806 14.1312 28.0742 13.8667 27.4367L12.9403 25.2C12.6764 24.5619 12.17 24.0547 11.5323 23.7899L9.29632 22.8637C8.65848 22.5994 8.15164 22.0928 7.88722 21.455C7.62281 20.8172 7.62245 20.1005 7.88623 19.4625L8.81175 17.228C9.07542 16.5898 9.07488 15.8731 8.81025 15.2353L7.88606 12.9976C7.75503 12.6815 7.68755 12.3427 7.6875 12.0006C7.68745 11.6584 7.75481 11.3196 7.88574 11.0035C8.01667 10.6874 8.2086 10.4002 8.45057 10.1583C8.69254 9.91641 8.9798 9.72456 9.29594 9.59373L11.5303 8.66817C12.1679 8.40456 12.6747 7.89876 12.9397 7.26179L13.8659 5.02569C14.1302 4.38755 14.6372 3.88054 15.2753 3.61622C15.9135 3.35189 16.6305 3.35189 17.2686 3.61622L19.503 4.54177C20.1411 4.80545 20.8579 4.80491 21.4956 4.54027L23.7326 3.61765C24.3706 3.35347 25.0874 3.35352 25.7254 3.6178C26.3634 3.88208 26.8703 4.38894 27.1347 5.02692L28.0612 7.26368L28.0609 7.25981Z'
            stroke='url(#paint0_linear_31880_105923)'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <defs>
            <linearGradient
              id='paint0_linear_31880_105923'
              x1='33.3125'
              y1='20.5013'
              x2='7.6875'
              y2='20.5013'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color={props.startColor || '#000'} />
              <stop offset='0.76' stop-color={props.endColor || '#000'} />
            </linearGradient>
          </defs>
        </svg>
      </g>
    </SvgIcon>
  );
};

export default EducationAward;

import React, {useRef, useEffect} from 'react';
import {LocationSearch} from 'mui-core';
import {styled} from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import {exploreCareer} from 'data/exploreCareer.json';
import {
  Stack,
  Select,
  MenuItem,
  useTheme,
  TextField,
  InputLabel,
  IconButton,
  FormControl,
  useMediaQuery,
  InputAdornment,
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
const StyledLocation = styled(FormControl)(({theme}) => ({
  width: '100%',
  marginBottom: '16px',
}));

const CareerFilter = ({
  reqParams,
  handleFilterChange,
  FilterOptions = [],
  careerAreaList = [],
  LocationOptions = [],
}) => {
  const theme = useTheme();
  const searchRef = useRef('');
  const {selectedTab, location, title, cluster_uuid} = reqParams || {};
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    filterByLabel,
    careerAreaLabel,
    defaultCareerArea,
    searchPlaceholderLabel,
  } = exploreCareer;

  useEffect(() => {
    searchRef.current.value = title;
  }, [isMobile, title]);

  const handleSearch = event => {
    if (event.key === 'Enter' || event.type === 'click') {
      handleFilterChange('title', searchRef.current.value);
    }
  };

  return (
    <Stack gap={3}>
      {careerAreaList &&
        Array.isArray(careerAreaList) &&
        careerAreaList.length > 0 && (
          <FormControl fullWidth>
            <InputLabel htmlFor={careerAreaLabel} id='cluster-area-label'>
              {careerAreaLabel}
            </InputLabel>
            <Select
              defaultValue='all'
              MenuProps={menuProps}
              label={careerAreaLabel}
              value={cluster_uuid}
              aria-label={careerAreaLabel}
              inputProps={{id: careerAreaLabel}}
              onChange={event =>
                handleFilterChange('cluster_uuid', event.target.value)
              }>
              <MenuItem value='all' key='all'>
                {defaultCareerArea}
              </MenuItem>
              {careerAreaList.map(option => (
                <MenuItem key={option.cluster_uuid} value={option.cluster_uuid}>
                  {option.cluster_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      <FormControl fullWidth>
        <InputLabel id='sort-by-label' htmlFor={filterByLabel}>
          {filterByLabel}
        </InputLabel>
        <Select
          label={filterByLabel}
          value={selectedTab.value}
          aria-label={filterByLabel}
          inputProps={{id: filterByLabel}}
          onChange={event =>
            handleFilterChange('selectedTab', event.target.value)
          }>
          {FilterOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          key={isMobile}
          variant='outlined'
          inputRef={searchRef}
          onKeyPress={handleSearch}
          label={searchPlaceholderLabel}
          placeholder={searchPlaceholderLabel}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  edge='end'
                  onClick={handleSearch}
                  aria-label='search button'>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <StyledLocation>
        <LocationSearch
          filters={reqParams}
          LocationOptions={LocationOptions}
          placeholder='Search By City or Zip Code'
          onClear={() => handleFilterChange('location', '')}
          onEnterPress={v => handleFilterChange('location', v)}
          onBlur={() => handleFilterChange('location', 'reset')}
          onLocationChange={val => handleFilterChange('location', val)}
        />
      </StyledLocation>
    </Stack>
  );
};

export default CareerFilter;

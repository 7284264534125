import {
  URLConfig,
  CloudConfig,
  Transformation,
  CloudinaryImage,
} from '@cloudinary/url-gen';
import {fill} from '@cloudinary/url-gen/actions/resize';
import {sharpen} from '@cloudinary/url-gen/actions/adjust';
import {focusOn} from '@cloudinary/url-gen/qualifiers/gravity';
import {FocusOn} from '@cloudinary/url-gen/qualifiers/focusOn';
import {ifCondition} from '@cloudinary/url-gen/actions/conditional';

const cloudConfig = new CloudConfig({
  cloudName: 'goeducate-inc',
  apiKey: '947238351767175',
  apiSecret: 'QdQ7L_xPQ1CFICYvdNOTOZ0nm1A',
});
const urlConfig = new URLConfig({secure: true});
const cld = url => new CloudinaryImage(url, cloudConfig, urlConfig);

export const CLOUDINARY = {
  getBannerURL: url => {
    return (
      cld(url)
        // .conditional(
        //   ifCondition(
        //     'face_count >= 1',
        //     new Transformation().resize(
        //       fill(2500, 800).gravity(focusOn(FocusOn.faces())),
        //     ),
        //   ).otherwise(
        //     new Transformation().resize(fill(2500, 800).gravity('auto')),
        //   ),
        // )
        .addTransformation('dpr_auto,q_100')
        // .adjust(sharpen(85))
        // .format('auto')
        // .quality(100)
        .toURL()
    );
  },
  getLogoImage: url => {
    return cld(url);
  },
};

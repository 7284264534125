import React, {useState, useEffect} from 'react';
import {
  Box,
  Paper,
  Stack,
  styled,
  useTheme,
  Pagination,
  Typography,
  paperClasses,
  useMediaQuery,
} from '@mui/material';
import {themeColorMode} from 'mui-core';
import {numberInUSFormat} from 'core/utils';
import {CareerCard, CareerDetails} from './components';
import careerData from 'data/exploreCareer.json';

const {
  relatedCareers: {relatedCareersLabel},
} = careerData;

const ListLayout = styled(Box)(({theme, variant}) => ({
  position: 'relative',
  minHeight: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '36%',
    minWidth: '36%',
  },
  [`&>.${paperClasses.root}`]: {
    minHeight: 500,
    backgroundColor: themeColorMode(
      theme,
      theme.palette.purplebg.main,
      theme.palette.background.paper,
    ),
    borderRadius: '20px',
    padding: '16px 20px',
  },
}));

const DetailsLayout = styled(Box)(({theme, isMobileOpen}) => ({
  flexGrow: 1,
}));

const RelatedCareersLayout = ({
  pageOptions,
  onPageChange,
  careerRequest,
  relatedCareerList,
}) => {
  const theme = useTheme();
  const [init, setInit] = useState();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedCareer, setSelectedCareer] = useState(null);
  const [detailsOnMobile, setDetailsOnMobile] = useState(false);
  const resultCount = relatedCareerList.length;
  const pages = Math.round(pageOptions?.total_results / pageOptions?.page_size);

  const emptyList =
    init &&
    relatedCareerList &&
    Array.isArray(relatedCareerList) &&
    relatedCareerList.length === 0;

  useEffect(() => {
    if (relatedCareerList && relatedCareerList.length > 0) {
      const firstCareer = relatedCareerList[0];
      setSelectedCareer(firstCareer);
    } else {
      setSelectedCareer(null);
    }
  }, [relatedCareerList]);

  const onCardClick = (career, openOnMobileView) => {
    if (openOnMobileView && isMobile) setDetailsOnMobile(true);
    setSelectedCareer(career);
  };

  const onMobileDetailsClose = () => {
    setDetailsOnMobile(false);
  };

  return (
    <Stack my={3} direction={{xs: 'column', sm: 'row'}} spacing={6}>
      <ListLayout display={emptyList ? 'none' : 'block'}>
        {Array.isArray(relatedCareerList) && relatedCareerList.length > 0 && (
          <Paper variant='elevation' elevation={0}>
            <Typography variant='h5' color='text.primary'>
              {relatedCareersLabel}
            </Typography>
            <Typography color='text.primary' my={1}>
              Showing {numberInUSFormat(resultCount)} of{' '}
              {pageOptions?.total_results} Results
            </Typography>
            <Box mt={2} position={'relative'} minHeight={200}>
              {relatedCareerList.map(career => (
                <CareerCard
                  data={career}
                  key={career.id}
                  onClickCard={data => onCardClick(data, true)}
                  selectedItem={selectedCareer}
                />
              ))}
            </Box>
          </Paper>
        )}
        {!careerRequest && pages > 0 && (
          <Stack direction={'row'} justifyContent={'center'} my={2}>
            <Pagination
              count={pages}
              shape='rounded'
              variant='outlined'
              page={pageOptions?.page || 1}
              onChange={(e, page) => onPageChange(page)}
              sx={{
                '.Mui-selected': {
                  color: 'white',
                  backgroundColor: `${theme.palette.primary.main} !important`,
                },
              }}
            />
          </Stack>
        )}
      </ListLayout>

      <DetailsLayout
        isMobileOpen={detailsOnMobile}
        display={emptyList ? 'none' : 'block'}>
        <Box position={'sticky'} top={'120px'}>
          {selectedCareer && (
            <CareerDetails
              isMobile={isMobile}
              cardInfo={selectedCareer}
              careerRequest={careerRequest}
              isMobileOpen={detailsOnMobile}
              onMobileDetailsClose={onMobileDetailsClose}
            />
          )}
        </Box>
      </DetailsLayout>
    </Stack>
  );
};

export default RelatedCareersLayout;

import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Row, Col, Button, Popover} from 'antd';

import {
  getPathsToCompare,
  forceClearComparePaths,
  getComparePathDetails,
  removePathFromCompare,
} from 'redux/modules/pathways';

import {PathCard, ErrorBoundary} from 'core/components';

import {iconplus} from 'assets/images';

import style from './style.module.less';
import './style.less';

const ComparePaths = props => {
  const {
    APIFlag,
    comparePathsList,
    removePathFromCompare,
    forceClearComparePaths,
  } = props;
  const history = useHistory();
  const {location: {pathname} = {}} = history;

  const addPathToCompareList = () => {
    if (pathname && pathname !== '/search') {
      history.push('/search');
    }
  };

  const onRemovePathToCompare = pathData => removePathFromCompare(pathData);

  const onLetsCompareClick = () => history.push('/compare-paths');

  const onClearAllClick = () => forceClearComparePaths();

  return (
    <ErrorBoundary nameOfComponent='core-compare-paths'>
      {!APIFlag &&
        comparePathsList &&
        Array.isArray(comparePathsList) &&
        comparePathsList.length > 0 && (
          <Row justify='center' className='comparePath-footer text-center p-3'>
            <Col xs={24} sm={24} md={24} lg={7}>
              <h2>
                {`Compare Paths (${
                  comparePathsList.length || 0
                } Paths Selected)`}
              </h2>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <ul className='compare-path-card p-0'>
                {comparePathsList.map((path, index) => {
                  return (
                    <Popover
                      placement='top'
                      key={`Popover-${index + 1}`}
                      overlayClassName={`comparePath_hover ${style.custompopover}`}
                      content={() => (
                        <PathCard key={`pathCard-${index + 1}`} data={path} />
                      )}>
                      <span>
                        <PathCard
                          key={`pathCard-${index + 1}`}
                          data={path}
                          onClosePath={onRemovePathToCompare}
                          displayThumbNail
                        />
                      </span>
                    </Popover>
                  );
                })}
                {comparePathsList.length <= 2 && (
                  <li className='addComparePath' onClick={addPathToCompareList}>
                    <div>
                      <img src={iconplus} alt='plus icon' />
                    </div>
                  </li>
                )}
              </ul>
            </Col>
            <Col xs={24} sm={24} md={24} lg={5} className='pt-3'>
              <Row justify='space-around' className='py-3'>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <Button
                    type='primary'
                    disabled={comparePathsList.length < 2}
                    onClick={onLetsCompareClick}>
                    LET'S COMPARE
                  </Button>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <Button
                    className='btn-transparent clear_web'
                    onClick={onClearAllClick}>
                    CLEAR ALL
                  </Button>
                  <Button
                    className='btn-transparent clear_mob d-none'
                    onClick={onClearAllClick}>
                    X
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
    </ErrorBoundary>
  );
};

const mapStateToProps = state => ({
  comparePathsList: getPathsToCompare(state),
  APIFlag: getComparePathDetails(state).flag,
});

export default connect(mapStateToProps, {
  removePathFromCompare,
  forceClearComparePaths,
})(ComparePaths);

import React from 'react';
import {grey} from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import {styled} from '@mui/material/styles';
import {Typography, Grid} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const StyledLeftItem = styled(Grid)(({theme}) => ({
  textAlign: 'end',
  padding: '8px 0px',
  paddingRight: '8px',
  color: grey[600],
  '& .MuiTypography-root': {
    fontSize: '12px',
  },
}));
const StyledRightItem = styled(Grid)(({theme}) => ({
  textAlign: 'start',
  padding: '8px 0px',
  paddingLeft: '8px',
  '& .MuiTypography-root': {
    fontSize: '14px',
  },
}));

export const InfoModal = props => {
  const {onClose, selectedValue, open} = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const renderItem = (title, value) => {
    if (value) {
      return (
        <>
          <StyledLeftItem item xs={3}>
            <Typography variant='body1'>{title}</Typography>
          </StyledLeftItem>
          <StyledRightItem item xs={9}>
            <Typography variant='body1' color='text.primary'>
              {Array.isArray(value) && value.length
                ? value.toString().replaceAll(',', ', ')
                : value}
            </Typography>
          </StyledRightItem>
        </>
      );
    } else {
      return '';
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth='md' fullWidth={true}>
      <DialogTitle sx={{m: 0, p: 2}}>
        <Typography variant='h4' color='text.primary'>
          Course Details
        </Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              top: 8,
              right: 8,
              position: 'absolute',
              //   color: theme => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{padding: {xs: '8px', sm: '16px', md: '24px'}}}>
        <Grid container>
          {renderItem('School:', selectedValue?.institution_name)}
          {renderItem('Course ID:', selectedValue?.course_id)}
          {renderItem('Course Title:', selectedValue?.course_name)}
          {renderItem('Course Description:', selectedValue?.description)}
          {renderItem('Credits/Units:', selectedValue?.units)}
          {renderItem('Lecture Hours:', selectedValue?.lecture_hours)}
          {renderItem('Laboratory Hours:', selectedValue?.laboratory_hours)}
          {renderItem('Discussion Hours:', selectedValue?.discussion_hours)}
          {renderItem('Transfer Type:', selectedValue?.transferable)}
          {renderItem(
            'Skills Learned:',
            selectedValue?.skills_learned?.length
              ? selectedValue?.skills_learned
              : '',
          )}
          {renderItem(
            'Pre-requisite(s):',
            selectedValue?.pre_requisites?.length
              ? selectedValue?.pre_requisites
              : '',
          )}
          {renderItem(
            'Co-requisite(s):',
            selectedValue?.co_requisites?.length
              ? selectedValue?.co_requisites
              : '',
          )}
          {renderItem('Course TOP Code:', selectedValue?.course_top_code)}
          {renderItem('Credit Status:', selectedValue?.credit_status)}
          {renderItem('Learning Outcomes:', selectedValue?.learning_outcomes)}
          {renderItem('Course Notes:', selectedValue?.course_notes)}
          {renderItem('Catalog Title:', selectedValue?.catalog_title)}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

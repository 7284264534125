import {Box, useTheme} from '@mui/material';
import {
  Grid,
  Button,
  CompanyCard,
  ErrorBoundary,
  themeColorMode,
} from 'mui-core';
import {Calendar} from 'assets/images';
import {apprenticeshipsListData} from 'data/apprenticeshipsList.json';

const ApprenticeshipsList = ({reqParams, onLoadMore, apprenticeshipsData}) => {
  const theme = useTheme();
  const {loadMoreBtnText} = apprenticeshipsListData;
  const {data: apprenticeshipsList, error, request} = apprenticeshipsData || {};
  const {data = [], page_details = {}} = apprenticeshipsList || {};
  const recordsCount = data?.length || 0;
  const totalCount = page_details?.total_results || 0;
  const hideLoadMore =
    (data?.page_details &&
      reqParams?.page >= data?.page_details?.page_size &&
      !request &&
      !error) ||
    recordsCount === totalCount ||
    totalCount <= 12;

  let defaultDisplay = true;
  if (request && !(reqParams?.page > 1)) {
    defaultDisplay = false;
  }

  return (
    <ErrorBoundary nameOfComponent='module-companies' typeOfUi='subPage'>
      {defaultDisplay && data?.length > 0 && (
        <>
          <Grid
            container
            paddingBottom={3}
            rowSpacing={{xs: 2, sm: 3}}
            columnSpacing={{xs: 2, sm: 3}}
            justifyContent={{xs: 'center', sm: 'left'}}>
            {data?.map(
              ({
                job_id,
                banner,
                company,
                location,
                job_title,
                company_logo,
                program_length,
                institution_id,
                company_industry_sector,
              }) => {
                let path = `/apprenticeships/${encodeURIComponent(
                  job_title,
                )}/${encodeURIComponent(job_id)}`;
                const [durationCount = '', durationLabel = ''] =
                  program_length.split(' ') || [];
                return (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    key={institution_id}
                    justifyContent='center'
                    sx={{textAlign: 'center'}}>
                    <CompanyCard
                      leftLogo={true}
                      centeredLogo={false}
                      data={{
                        path: path,
                        title: job_title,
                        location: location,
                        companyName: company,
                        companyBanner: banner,
                        companyLogo: company_logo,
                        industrySector: company_industry_sector,
                      }}
                      // width={{xs: 300, md: 320, lg: 360}}
                      infoItems={[
                        program_length && {
                          imgIcon: (
                            <img
                              src={Calendar}
                              style={{
                                width: 16,
                                height: 16,
                                marginBottom: '2px',
                                filter: themeColorMode(
                                  theme,
                                  theme.palette.iconGrayColor.main,
                                  theme.palette.iconLightColor.main,
                                ),
                              }}
                            />
                          ),
                          label: durationLabel,
                          count: durationCount || 0,
                          // value: program_length,
                        },
                      ].filter(Boolean)}
                      key={job_id}
                      target='_self'
                      enableNavigation
                    />
                  </Grid>
                );
              },
            )}
          </Grid>
          {!hideLoadMore && (
            <Box
              pb={3}
              display='flex'
              alignContent='center'
              justifyContent='center'>
              <Button
                size='large'
                variant='outlined'
                onClick={onLoadMore}
                sx={{padding: '8px 24px'}}>
                {request ? loadMoreBtnText.loading : loadMoreBtnText.loadMore}
              </Button>
            </Box>
          )}
        </>
      )}
    </ErrorBoundary>
  );
};

export default ApprenticeshipsList;

import React from 'react';
import {View, Text} from '@react-pdf/renderer';
import {formatViewDate} from 'core/utils';
import SettingsModule from 'data/settings.json';
import SkillTag from './SkillTag';
import Divider from './Divider';

export default ({styles, awardTypes = {}, credentialsInfo}) => {
  const {data: AwardTypesList = []} = awardTypes;
  const educationItems = credentialsInfo?.filter(
    item => item.credential_type === 'education',
  );
  const courseItems = credentialsInfo?.filter(
    item => item.credential_type === 'course',
  );
  const licenseItems = credentialsInfo?.filter(
    item =>
      item.credential_type === 'license' ||
      item.credential_type === 'certification',
  );

  return (
    <View style={styles.container}>
      {educationItems &&
        Array.isArray(educationItems) &&
        educationItems?.length > 0 && (
          <View>
            <View style={styles.header}>
              <Text break style={styles.headerText}>
                My Education
              </Text>
            </View>
            <View style={styles.content}>
              {educationItems &&
              Array.isArray(educationItems) &&
              educationItems?.length > 0 ? (
                educationItems.map((ed, idx) => {
                  const EdSkills =
                    ed?.skills &&
                    Array.isArray(ed?.skills) &&
                    ed?.skills?.length > 0 &&
                    ed.skills;
                  const getAwardType = id => {
                    let value = id;
                    if (Array.isArray(AwardTypesList)) {
                      const awardItem = AwardTypesList.filter(
                        i => i.uuid === id,
                      )[0];
                      if (awardItem) {
                        value = awardItem.options;
                      }
                    }
                    return value;
                  };

                  return (
                    <View key={'edu-profile-' + idx}>
                      {ed?.org_name && (
                        <View break style={styles.educationInfo}>
                          <Text style={styles.educationLabel}>School:</Text>
                          <Text style={{...styles.value, ...styles.largeText}}>
                            {ed?.org_name}
                          </Text>
                        </View>
                      )}
                      {ed?.school_start_date &&
                        (ed?.school_end_date || ed?.is_present) && (
                          <View style={styles.educationInfo}>
                            <Text style={styles.educationLabel}>
                              Date Completed:
                            </Text>
                            <Text style={styles.value}>
                              {`${formatViewDate(ed?.school_start_date)} - ${
                                ed?.is_present
                                  ? 'Present'
                                  : formatViewDate(ed?.school_end_date)
                              }`}
                            </Text>
                          </View>
                        )}
                      {ed?.award_type && (
                        <View style={styles.educationInfo}>
                          <Text style={styles.educationLabel}>
                            Degree/Award:
                          </Text>
                          <Text style={styles.value}>
                            {ed?.award_type && getAwardType(ed?.award_type)}
                          </Text>
                        </View>
                      )}
                      {ed?.area_of_focus && (
                        <View style={styles.educationInfo}>
                          <Text style={styles.educationLabel}>
                            Area of Focus/Major:
                          </Text>
                          <Text
                            wrap
                            style={{...styles.value, ...styles.largeText}}>
                            {ed.area_of_focus}
                          </Text>
                        </View>
                      )}
                      {ed?.comment && (
                        <View style={styles.educationInfo}>
                          <Text style={styles.educationLabel}>Comments:</Text>
                          <Text style={{...styles.value, ...styles.largeText}}>
                            {ed.comment}
                          </Text>
                        </View>
                      )}

                      {EdSkills &&
                        Array.isArray(EdSkills) &&
                        EdSkills?.length > 0 && (
                          <View style={styles.educationInfo}>
                            <Text style={styles.educationLabel}>
                              Skills Learned:
                            </Text>
                            <View
                              style={{
                                ...styles.SkillsList,
                                ...styles.largeText,
                              }}>
                              {EdSkills?.length !== 0 ? (
                                EdSkills?.map((skill, skillIndex) => (
                                  <SkillTag key={skillIndex}>
                                    <Text>{skill}</Text>
                                  </SkillTag>
                                ))
                              ) : (
                                <Text>No Skills Available</Text>
                              )}
                            </View>
                          </View>
                        )}

                      {idx + 1 !== educationItems?.length && <Divider />}
                    </View>
                  );
                })
              ) : (
                <Text style={styles.value}>N/A</Text>
              )}
            </View>
          </View>
        )}
      {licenseItems && Array.isArray(licenseItems) && licenseItems?.length > 0 && (
        <View>
          <View style={styles.header}>
            <Text break style={styles.headerText}>
              License and Certifications
            </Text>
          </View>
          <View style={styles.content}>
            {licenseItems &&
            Array.isArray(licenseItems) &&
            licenseItems?.length > 0 ? (
              licenseItems.map((license, idx) => {
                const licenseSkills =
                  license?.skills &&
                  Array.isArray(license?.skills) &&
                  license?.skills?.length > 0 &&
                  license.skills;

                return (
                  <View key={'license-profile-' + idx}>
                    {license?.program_name && (
                      <View break style={styles.educationInfo}>
                        <Text style={styles.educationLabel}>license Name:</Text>
                        <Text style={{...styles.value, ...styles.largeText}}>
                          {license?.program_name}
                        </Text>
                      </View>
                    )}
                    {license?.org_name && (
                      <View style={styles.educationInfo}>
                        <Text style={styles.educationLabel}>
                          Issuing Organization:
                        </Text>
                        <Text style={{...styles.value, ...styles.largeText}}>
                          {license?.org_name}
                        </Text>
                      </View>
                    )}
                    {license?.date_completed_month &&
                      license?.date_completed_year && (
                        <View style={styles.educationInfo}>
                          <Text style={styles.educationLabel}>
                            Date Issued:
                          </Text>
                          <Text style={styles.value}>
                            {license.date_completed_month &&
                            license.date_completed_year
                              ? `${
                                  SettingsModule.months[
                                    license.date_completed_month - 1
                                  ]
                                } ${license.date_completed_year}`
                              : '-'}
                          </Text>
                        </View>
                      )}
                    {license?.award_type && (
                      <View style={styles.educationInfo}>
                        <Text style={styles.educationLabel}>Award Type:</Text>
                        <Text style={{...styles.value, ...styles.capitalText}}>
                          {license?.award_type}
                        </Text>
                      </View>
                    )}
                    {license?.credential_url && (
                      <View style={styles.educationInfo}>
                        <Text style={styles.educationLabel}>
                          Credential URL:
                        </Text>
                        <Text style={{...styles.value, ...styles.largeText}}>
                          {license?.credential_url}
                        </Text>
                      </View>
                    )}
                    {licenseSkills &&
                      Array.isArray(licenseSkills) &&
                      licenseSkills?.length > 0 && (
                        <View style={styles.educationInfo}>
                          <Text style={styles.educationLabel}>
                            Skills Learned:
                          </Text>
                          <View
                            style={{
                              ...styles.SkillsList,
                              ...styles.largeText,
                            }}>
                            {licenseSkills?.length !== 0 ? (
                              licenseSkills?.map((skill, skillIndex) => (
                                <SkillTag key={skillIndex}>
                                  <Text>{skill}</Text>
                                </SkillTag>
                              ))
                            ) : (
                              <Text>No Skills Available</Text>
                            )}
                          </View>
                        </View>
                      )}

                    {idx + 1 !== licenseItems?.length && <Divider />}
                  </View>
                );
              })
            ) : (
              <Text style={styles.value}>N/A</Text>
            )}
          </View>
        </View>
      )}
      {courseItems && Array.isArray(courseItems) && courseItems?.length > 0 && (
        <View>
          <View style={styles.header}>
            <Text break style={styles.headerText}>
              Courses
            </Text>
          </View>
          <View style={styles.content}>
            {courseItems &&
            Array.isArray(courseItems) &&
            courseItems?.length > 0 ? (
              courseItems.map((course, idx) => {
                const courseSkills =
                  course?.skills &&
                  Array.isArray(course?.skills) &&
                  course?.skills?.length > 0 &&
                  course.skills;

                return (
                  <View key={'course-profile-' + idx}>
                    {course?.program_name && (
                      <View break style={styles.educationInfo}>
                        <Text style={styles.educationLabel}>Course Name:</Text>
                        <Text style={{...styles.value, ...styles.largeText}}>
                          {course?.program_name}
                        </Text>
                      </View>
                    )}
                    {course?.org_name && (
                      <View style={styles.educationInfo}>
                        <Text style={styles.educationLabel}>
                          School/Organization:
                        </Text>
                        <Text style={{...styles.value, ...styles.largeText}}>
                          {course?.org_name}
                        </Text>
                      </View>
                    )}
                    {course?.date_completed_month &&
                      course?.date_completed_year && (
                        <View style={styles.educationInfo}>
                          <Text style={styles.educationLabel}>
                            Date Completed:
                          </Text>
                          <Text style={styles.value}>
                            {course.date_completed_month &&
                            course.date_completed_year
                              ? `${
                                  SettingsModule.months[
                                    course.date_completed_month - 1
                                  ]
                                } ${course.date_completed_year}`
                              : '-'}
                          </Text>
                        </View>
                      )}
                    {courseSkills &&
                      Array.isArray(courseSkills) &&
                      courseSkills?.length > 0 && (
                        <View style={styles.educationInfo}>
                          <Text style={styles.educationLabel}>
                            Skills Learned:
                          </Text>
                          <View
                            style={{
                              ...styles.SkillsList,
                              ...styles.largeText,
                            }}>
                            {courseSkills?.length !== 0 ? (
                              courseSkills?.map((skill, skillIndex) => (
                                <SkillTag key={skillIndex}>
                                  <Text>{skill}</Text>
                                </SkillTag>
                              ))
                            ) : (
                              <Text>No Skills Available</Text>
                            )}
                          </View>
                        </View>
                      )}

                    {idx + 1 !== courseItems?.length && <Divider />}
                  </View>
                );
              })
            ) : (
              <Text style={styles.value}>N/A</Text>
            )}
          </View>
        </View>
      )}
    </View>
  );
};

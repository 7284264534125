import * as TYPES from './constants';
import {API} from 'config';

import {recieveEthnicity, requestEthnicity, ethnicityError} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: [], error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ETHNICITY_LOAD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.ETHNICITY_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.ETHNICITY_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchEthnicity = () => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    let endPoint = `${API.gps.ethnicity}`;
    dispatch(requestEthnicity());
    return services.fetchRecords(endPoint, token).then(res => {
      if (Array.isArray(res) && res.length) {
        let Obj = res.find(item => item.name === 'Decline to State');
        let filteredArr =
          res
            .filter(item => item.name !== 'Decline to State')
            .sort((a, b) => (a.name > b.name ? 1 : -1)) || [];
        // To display the option 'Decline to State' at the bottom of the list
        dispatch(recieveEthnicity([...filteredArr, Obj]));
      } else dispatch(ethnicityError());
    });
  } catch (err) {
    console.log(err);
    dispatch(ethnicityError(err));
  }
};

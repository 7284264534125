import {API} from 'config';
import * as TYPES from './constants';
import {Services} from 'core/Services';
import {
  clearCareerCraft,
  requestCareerCraft,
  recieveCareerCraft,
  saveRequestedParams,
  requestCareerCraftError,
} from './actions';

const services = new Services();
const initialState = {request: false, error: false, data: null, params: null};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CAREER_CRAFT_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.CAREER_CRAFT_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CAREER_CRAFT_CLEAR:
      return {
        request: false,
        error: false,
        data: null,
      };
    case TYPES.CAREER_CRAFT_ERROR:
      return {
        ...initialState,
        request: false,
        error: false,
      };
    case TYPES.CAREER_CRAFT_PARAMS:
      return {
        ...state,
        params: action.payload,
      };
    default:
      return state;
  }
};

export const fetchCareerCraft = (type, reqParams) => (dispatch, getState) => {
  let endPoint = `${API.srm.student_resume_career_craft}${type}`;
  Object.keys(reqParams).forEach((key, i) => {
    endPoint += `&${key}=${reqParams[key]}`;
  });
  const {
    auth: {auth: {token = null} = {}},
    resumeBuilder: {
      CareerCraft: {params = null},
    },
  } = getState();
  if (JSON.stringify(params) === JSON.stringify(reqParams)) {
    return;
  }
  dispatch(clearCareerCraft());
  dispatch(requestCareerCraft());
  try {
    return services.fetchRecords(endPoint, token).then(res => {
      console.log('student_resume_career_craft: ', res);
      if (res?.Error) {
        if (typeof res.Error === 'string') {
          alert(res.Error);
        } else {
          alert('Error in getting AI response, Please try again.');
        }
        dispatch(requestCareerCraftError(res));
        return;
      }
      if (Array.isArray(res?.response)) {
        dispatch(recieveCareerCraft(res.response));
        dispatch(saveRequestedParams(reqParams));
      } else dispatch(requestCareerCraftError(res));
    });
  } catch (err) {
    console.log(err);
    if (err?.Error) {
      if (typeof err.Error === 'string') {
        alert(err.Error);
      } else {
        alert('Error in getting AI response, Please try again.');
      }
      return;
    }
    dispatch(requestCareerCraftError(err));
  }
};

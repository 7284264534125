import React from 'react';
import {Text, View, StyleSheet} from '@react-pdf/renderer';
import {formatCount, getGraphData, validateGenEduPlanned} from 'core/utils';

const styles = StyleSheet.create({
  metricsBlock: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '90%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#3BC4FF',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30',
    marginTop: 20,
    marginBottom: 10,
    //borderRight: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  block: {
    flexDirection: 'column',
    width: '50%',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#EAEDF1',
    //height: '100%',
    padding: 15,
  },
  three_block: {
    flexDirection: 'column',
    width: '33.3%',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#EAEDF1',
    //height: '100%',
    padding: 15,
  },
  description: {
    flexDirection: 'row',
    marginBottom: 10,
    paddingRight: 5,
    paddingLeft: 0,
  },
  label: {
    width: '90%',
    color: '#333333',
    fontSize: 10,
    marginRight: 5,
    textTransform: 'capitalize',
  },
  value: {
    width: '10%',
    color: '#3BC4FF',
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  removeRtBorder: {
    borderRightWidth: 0,
  },
});

const prepareTermsData = data => {
  let terms = [];
  data &&
    data.length > 0 &&
    data.forEach(d => {
      const term = {
        term_name: d.term_name,
        data: [...d.courses],
      };
      terms.push(term);
    });
  return terms;
};

// const calTotalUnitsPlanned = data => {
//   let count = 0;
//   if (data && data.length > 0) {
//     data.forEach(t => {
//       t.courses &&
//         t.courses.length &&
//         t.courses.forEach(c => {
//           count += 1;
//         });
//     });
//   }
//   return count;
// };

// const calTotalCoreUnitsPlanned = data => {
//   let count = 0;
//   let units = 0;
//   if (data && data.length > 0) {
//     data.forEach(t => {
//       t.courses &&
//         t.courses.length &&
//         t.courses.forEach(c => {
//           count++;
//           units += parseFloat(c.units ? c.units : 0);
//         });
//     });
//   }
//   return count;
// };

// const calTotalCoreUnits = data => {
//   let count = 0;
//   let units = 0;
//   data &&
//     data.req_courses &&
//     Array.isArray(data.req_courses) &&
//     data.req_courses.map(c => {
//       count++;
//       units += parseFloat(c.units ? c.units : 0);
//     });
//   return count;
// };

// const calTotalAdditionalCoreUnits = data => {
//   let units = 0;
//   if (data && data.segment) {
//     data.segment.map(seg => {
//       const sortedCourses =
//         seg &&
//         seg.courses &&
//         seg.courses.sort((a, b) =>
//           parseFloat(a.units) > parseFloat(b.units) ? 1 : -1,
//         );
//       if (seg && seg.rule_type === 'Choose number of course(s)') {
//         const requiredCourses = parseInt(seg.number_of_required_course);
//         units += requiredCourses;
//         //   sortedCourses.map((course, idx) => {
//         //   if (idx <= requiredCourses) {
//         //     units += parseFloat(course.units);
//         //   }
//         // });
//       } else if (seg && seg.rule_type === 'Complete number of unit(s)') {
//         let localUnits = 0;
//         let count = 0;
//         const requiredUnits = parseFloat(seg.number_of_required_course);
//         sortedCourses.map(course => {
//           if (localUnits < requiredUnits) {
//             count++;
//             localUnits += parseFloat(course.units);
//           }
//         });
//         units += count;
//       }
//     });
//   }
//   return units;
// };

const getCoreCoursesMetrics = (terms, path) => {
  const reqCourses = path && path.req_courses && path.req_courses.length;
  const segments = path && path.segment;
  let total = 0;
  if (reqCourses) {
    total += reqCourses;
  }
  if (segments && segments.length > 0) {
    total += segments.length;
  }

  const {requiredCoures, termsCompletedReqCourses} = getGraphData({
    terms: prepareTermsData(terms),
    path,
  });

  const val = requiredCoures + termsCompletedReqCourses;

  return {den: total || 0, num: val || 0};
};

const getGenedMetrics = (terms, path, genedData) => {
  if (!genedData) return {num: 0, den: 0};
  const data = prepareTermsData(terms);
  const {completed, total} = validateGenEduPlanned(genedData, data);
  return {num: completed || 0, den: total || 0};
};

const getTotalMetrics = (terms, path) => {
  const {totalUnits} = getGraphData({
    terms: prepareTermsData(terms),
    path,
  });

  const val = totalUnits;
  const den = path && path.total_units_required;

  return {num: val || 0, den: den || 0};
};

export default ({data, path}) => {
  const {terms, gen_ed, gen_ed_opted} = data || {};
  // const total_units_req = (path && path.total_units_required) || 0;
  // const total_units_planned = calTotalUnitsPlanned(terms);
  // const diffUnits = total_units_req - total_units_planned;
  // const total_units_remaining = diffUnits > 0 ? diffUnits : 0;
  // const total_core_units = calTotalCoreUnits(path);
  // const total_additional_core_units = calTotalAdditionalCoreUnits(path);
  // const all_core_units = total_core_units + total_additional_core_units;
  // const total_core_units_planned = calTotalCoreUnitsPlanned(terms);
  // const diffCoreUnits = all_core_units - total_units_planned;
  // const allCoreUnitsRemaining = diffCoreUnits > 0 ? diffCoreUnits : 0;

  const genedData = gen_ed_opted ? gen_ed : null;
  const core_courses_metrics = getCoreCoursesMetrics(terms, path);
  const gened_metrics = getGenedMetrics(terms, path, genedData);
  const total_metrics = getTotalMetrics(terms, path);

  const dataArray = [
    [
      {
        label: `${path?.score_system || 'Units'} Required:`,
        value: formatCount(total_metrics.den) || 0,
      },
      {
        label: `${path?.score_system || 'Units'} Planned:`,
        value: formatCount(total_metrics.num) || 0,
      },
      {
        label: `${path?.score_system || 'Units'} Remaining:`,
        value: formatCount(Math.max(total_metrics.den - total_metrics.num, 0)),
      },
    ],
    [
      {
        label: 'Core Courses Required:',
        value: formatCount(core_courses_metrics.den) || 0,
      },
      {
        label: 'Core Courses Planned:',
        value: formatCount(core_courses_metrics.num) || 0,
      },
      {
        label: 'Courses Remaining:',
        value: formatCount(
          Math.max(core_courses_metrics.den - core_courses_metrics.num, 0),
        ),
      },
    ],
  ];

  if (genedData) {
    dataArray.push([
      {
        label: 'GenEd Courses Required:',
        value: formatCount(gened_metrics.den),
      },
      {
        label: 'GenEd Courses Planned:',
        value: formatCount(gened_metrics.num) || 0,
      },
      {
        label: 'Courses Remaining:',
        value: formatCount(Math.max(gened_metrics.den - gened_metrics.num, 0)),
      },
    ]);
  }

  return (
    <View style={styles.metricsBlock}>
      {dataArray &&
        dataArray.map((dA, idx) => {
          let combineStyles = genedData ? styles.three_block : styles.block;
          if (genedData && idx === 2) {
            combineStyles = {
              ...combineStyles,
              borderRightWidth: 0,
            };
          }
          if (!genedData && idx === 1) {
            combineStyles = {
              ...combineStyles,
              borderRightWidth: 0,
            };
          }

          return (
            <View style={combineStyles} key={`block-${idx}`}>
              {dA &&
                dA.map(({label, value}, index) => {
                  return (
                    <View
                      style={[
                        styles.description,
                        {marginBottom: index === 2 ? '0' : '10'},
                      ]}
                      key={`metrics-${idx}-${index}`}>
                      <View style={styles.label}>
                        <Text>{label}</Text>
                      </View>
                      <View style={styles.value}>
                        <Text>{value}</Text>
                      </View>
                    </View>
                  );
                })}
            </View>
          );
        })}
    </View>
  );
};

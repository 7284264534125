import {Button} from 'mui-core';
import PropTypes from 'prop-types';
import {Grid, TextField} from '@mui/material';
import {jobsData} from 'data/settings-profile';

const FilterForm = ({
  request,
  onReset,
  onFinish,
  register,
  showReset,
  isMobileView,
  handleSubmit,
}) => {
  const {filtersFormStaticData} = jobsData;
  return (
    <>
      <Grid item xs={12} sm={9} md={9} lg={9}>
        <form onSubmit={handleSubmit(onFinish)}>
          <Grid
            container
            spacing={2}
            direction={isMobileView ? 'column' : 'row'}>
            <Grid item xs={12} sm={5} md={5} lg={5}>
              <TextField
                fullWidth
                size='small'
                label='What'
                variant='outlined'
                disabled={request}
                {...register('title_or_company')}
                placeholder={filtersFormStaticData.titleInputPlaceholder}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5}>
              <TextField
                fullWidth
                size='small'
                label='Where'
                variant='outlined'
                disabled={request}
                {...register('location')}
                placeholder={filtersFormStaticData.locationInputPlaceholder}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <Button
                fullWidth={isMobileView}
                variant='contained'
                disabled={request}
                type='submit'>
                {filtersFormStaticData.searchBtnTxt}
              </Button>
              {isMobileView && showReset && (
                <Button
                  sx={{marginTop: '16px'}}
                  disabled={request}
                  variant='outlined'
                  onClick={onReset}
                  color='primary'
                  fullWidth>
                  {filtersFormStaticData.resetFilterBtnTxt}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

FilterForm.propTypes = {
  isMobileView: PropTypes.bool,
  handleSubmit: PropTypes.func,
  register: PropTypes.object,
  showReset: PropTypes.bool,
  onFinish: PropTypes.func,
  onReset: PropTypes.func,
  request: PropTypes.bool,
};

export default FilterForm;

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LineArrowDown = props => {
  return (
    <SvgIcon {...props}>
      <path d='M4.0872 22.4757L4.0872 20.9433L4.0872 20.4325L5.10882 20.4325L5.10882 20.9433L5.10882 22.4757L9.19531 22.4757L4.59801 31.6703L0.000706644 22.4757L4.0872 22.4757ZM4.0872 0.510811L4.0872 -2.23283e-07L5.10882 -1.78626e-07L5.10882 0.510811L5.10882 2.55406L5.10882 3.06487L4.0872 3.06487L4.0872 2.55406L4.0872 0.510811ZM4.0872 4.5973L4.0872 4.08649L5.10882 4.08649L5.10882 4.5973L5.10882 6.64055L5.10882 7.15136L4.0872 7.15136L4.0872 6.64055L4.0872 4.5973ZM4.0872 8.6838L4.0872 8.17299L5.10882 8.17299L5.10882 8.6838L5.10882 10.727L5.10882 11.2379L4.0872 11.2379L4.0872 10.727L4.0872 8.6838ZM4.0872 12.7703L4.0872 12.2595L5.10882 12.2595L5.10882 12.7703L5.10882 14.8135L5.10882 15.3243L4.0872 15.3243L4.0872 14.8135L4.0872 12.7703ZM4.0872 16.8568L4.0872 16.346L5.10882 16.346L5.10882 16.8568L5.10882 18.9L5.10882 19.4108L4.0872 19.4108L4.0872 18.9L4.0872 16.8568Z' />
    </SvgIcon>
  );
};

export default LineArrowDown;

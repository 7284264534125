import {Grid} from 'mui-core';
import {avatarGray} from 'assets/images';
import {styled} from '@mui/material/styles';
import {Typography, Box} from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {overviewData} from 'data/settings-profilePreview.json';
import {formatPhoneNumber, getProfilePhoto, getLogo} from 'core/utils';

const OverviewInfo = ({student_Info, student_profile}) => {
  const {address, phone_number, email} = student_Info || {};
  const phoneNumber = formatPhoneNumber(phone_number);
  let location = null;
  const qrCodeImage = student_Info?.qr_code && getLogo(student_Info?.qr_code);

  let userPhoto = avatarGray;
  if (student_Info && student_Info.photo) {
    userPhoto = getProfilePhoto(student_Info.photo);
  }

  if (student_Info && address) {
    if (address?.city && address?.state)
      location = address.city + ', ' + address.state;
  }

  const StyledOverviewDetails = styled(Grid)(() => ({
    '@media (min-width: 769px) and (max-width: 1400px)': {
      alignItems: 'center',
    },
    '@media (min-width: 320px) and (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: '0px !important',
      padding: '10px',
    },
  }));

  const StyledOverviewProfile = styled(Grid)(() => ({
    '@media (min-width: 320px) and (max-width: 768px)': {
      marginTop: '20px',
    },
  }));
  return (
    <StyledOverviewDetails mt={3} container>
      <StyledOverviewProfile
        item
        lg={3}
        md={12}
        sm={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography
          variant='h2'
          fontSize={{xs: 30, sm: 30, md: 30, lg: 30}}
          color='white'>
          {student_Info.first_name} {student_Info.last_name}
        </Typography>
        {userPhoto && (
          <Box
            mt={3}
            component='img'
            sx={{
              height: 191,
              width: 191,
              maxHeight: {xs: 119, md: 167, lg: 191},
              maxWidth: {xs: 119, md: 167, lg: 191},
            }}
            src={userPhoto}
          />
        )}
      </StyledOverviewProfile>

      <StyledOverviewDetails item lg={3} pl={3} md={4} mt={6}>
        <Typography
          my={3}
          variant='h3'
          fontSize={24}
          color='white'
          fontWeight={600}>
          Contact
        </Typography>{' '}
        {email && (
          <Typography
            variant='body1'
            fontSize={18}
            color='white'
            fontWeight={400}>
            {email}
          </Typography>
        )}
        {location && (
          <Typography
            variant='body1'
            fontSize={18}
            color='white'
            fontWeight={400}>
            {location}
          </Typography>
        )}
        {phoneNumber && phoneNumber !== '-' && (
          <Typography
            variant='body1'
            fontSize={18}
            color='white'
            fontWeight={400}>
            {phoneNumber}
          </Typography>
        )}
      </StyledOverviewDetails>

      <StyledOverviewDetails pl={3} item mt={6} lg={3} md={4}>
        {student_profile?.portfolio_url && (
          <>
            <Typography
              variant='h3'
              my={3}
              fontSize={24}
              color='white'
              fontWeight={600}>
              {overviewData.profileurlLabel}
            </Typography>
            <Typography
              variant='body1'
              component={'a'}
              sx={{textDecoration: 'underline'}}
              href={student_profile.portfolio_url}
              fontSize={18}
              color='white'
              target='_blank'
              fontWeight={400}>
              {student_profile.portfolio_url}
            </Typography>
          </>
        )}
      </StyledOverviewDetails>

      <StyledOverviewDetails pl={3} item mt={6} lg={3} md={4}>
        {qrCodeImage && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <ImageList
              sx={{
                border: '2px solid #24bafa',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '140px',
                height: '140px',
              }}>
              <ImageListItem>
                <img
                  src={qrCodeImage}
                  srcSet={qrCodeImage}
                  alt='qrCodeImage'
                  loading='lazy'
                />
              </ImageListItem>
            </ImageList>
            <Typography
              variant='h5'
              fontSize={18}
              color='white'
              fontWeight={600}>
              {overviewData.profileqrLabel}
            </Typography>
          </Box>
        )}
      </StyledOverviewDetails>
    </StyledOverviewDetails>
  );
};

export default OverviewInfo;

import {Accordion} from 'mui-core';
import MyActivePrograms from './MyActivePrograms';

const ActivePrograms = props => {
  // const {fetchActivePaths} = props;
  return (
    <>
      <Accordion
        defaultOpen
        outlined={false}
        id='active_programs'
        // onChange={fetchActivePaths}
        header='Active Programs'>
        <MyActivePrograms {...props} />
      </Accordion>
    </>
  );
};
export default ActivePrograms;

import * as TYPES from './constants';

import {
  openLoginScreen as openLoginScreenModal,
  closeLoginScreen as closeLoginScreenModal,
} from './actions';

function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

const initialState = {visible: false, config: {}, savedCredentials: {}};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LOGIN_SCREEN_OPEN:
      return {
        ...state,
        visible: true,
        config: action.payload,
      };
    case TYPES.LOGIN_SCREEN_CLOSE:
      return {
        ...state,
        visible: false,
      };
    case TYPES.LOGIN_SUCCESS_CALLBACK:
      const callback = state.config ? state.config.callback : null;
      if (state.config && state.config.callback) {
        setTimeout(() => {
          callback(action.payload);
        }, 500);
      }
      return {
        ...state,
        config: {},
      };
    case TYPES.REMEMBER_CREDENTIAL:
      return {
        ...state,
        savedCredentials: action.payload,
      };
    default:
      return state;
  }
};

export const updateAuthURL = screen => {
  const {
    location: {search},
  } = window;
  const searchParams = new URLSearchParams(search);
  const signin = searchParams.get('signin');
  const signup = searchParams.get('signup');
  if (window.history.pushState) {
    if (!signin && screen === 'signin') {
      // var newurl =
      //   window.location.protocol +
      //   '//' +
      //   window.location.host +
      //   window.location.pathname +
      //   `?signin=true`;
      // window.history.pushState({path: newurl}, '', newurl);
      var newurl =
        window.location.search === ''
          ? window.location.href + `?signin=true`
          : window.location.href + `&signin=true`;
      window.history.pushState({path: newurl}, '', newurl);
    }
    if (!signup && screen !== 'signin') {
      // var newurl =
      //   window.location.protocol +
      //   '//' +
      //   window.location.host +
      //   window.location.pathname +
      //   `?signup=true`;
      var newurl =
        window.location.search === ''
          ? window.location.href + `?signup=true`
          : window.location.href + `&signup=true`;
      window.history.pushState({path: newurl}, '', newurl);
      window.history.pushState({path: newurl}, '', newurl);
    }
  }
};

export const openLoginScreen = config => (dispatch, getState) => {
  //   OPEN LOGIN CONFIG
  //   {
  //     defaultTab: < -- default tab to open , signin/signup
  //     callback: < -- function to call after successfull sign in , returns auth obj if success
  //   }
  updateAuthURL(config.screen);
  dispatch(openLoginScreenModal(config));
};

export const closeLoginScreen = () => (dispatch, getState) => {
  if (window.history.pushState) {
    const {
      location: {search},
    } = window;
    const searchParams = new URLSearchParams(search);
    const signin = searchParams.get('signin');
    const signup = searchParams.get('signup');
    if (signin) {
      const newurl = removeURLParameter(window.location.href, 'signin');
      window.history.pushState({path: newurl}, '', newurl);
    }
    if (signup) {
      const newurl = removeURLParameter(window.location.href, 'signup');
      window.history.pushState({path: newurl}, '', newurl);
    }
  }
  dispatch(closeLoginScreenModal());
};

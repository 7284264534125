import {ContentBox, ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {volunteerExperienceData} from 'data/settings-profile.json';
import VolunteerExperience from './VolunteerExperience';
import {EditProfileWrapper} from '../../components';
import {useSaveProfileLoader} from 'core/hooks';
import {Typography} from '@mui/material';

const VolunteerExp = ({profileData, onProfileDataSubmit}) => {
  const {request, data, error} = profileData;
  const {collapseBarHeading, subHeading} = volunteerExperienceData || {};
  const [isLoading, onSubmit] = useSaveProfileLoader(
    request,
    onProfileDataSubmit,
  );

  return (
    <ContentBox variant='outlined' sx={{mb: 2, p: 3}}>
      <Typography
        mb={1}
        variant='h5'
        color='text.primary'
        title={collapseBarHeading}
        // fontFamily='fontFamilyBold'
      >
        {collapseBarHeading}
      </Typography>
      <Typography variant='body1' title={subHeading} color='text.secondary'>
        {subHeading}
      </Typography>
      <ErrorBoundary
        nameOfComponent='dashboard-profile-volunteer-experience'
        typeOfUi='subPage'>
        <RequestErrorLoader body={{...profileData, request: isLoading, error}}>
          <VolunteerExperience data={data} onChange={onSubmit} />
        </RequestErrorLoader>
      </ErrorBoundary>
    </ContentBox>
  );
};

export default EditProfileWrapper(VolunteerExp);

import React, {useState, useEffect} from 'react';
import {useSnackbar} from 'notistack';
import AddLicense from './AddLicense';
import {blueGrey} from '@mui/material/colors';
import {useCheckMobileView} from 'core/hooks';
import SettingsModule from 'data/settings.json';
import {Edit, Delete} from '@mui/icons-material';
import {licensesAndCertifications} from 'data/settings-profile.json';
import {Grid, Button, SkillsStack, RequestErrorLoader} from 'mui-core';
import {Box, Stack, Divider, IconButton, Typography} from '@mui/material';

const LicenseList = ({
  skills,
  viewOnly,
  licenseData = [],
  fetchStudentMetrics,
  saveStudentCredential,
  updateStudentCredential,
  fetchStudentCredentials,
  deleteStudentCredential,
  studentCredentials: {request: studentCredentialRequest},
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const {isMobileView} = useCheckMobileView();
  const [editIndex, setEditIndex] = useState(-1);
  const [isAdd, setAdd] = useState(
    !licenseData || licenseData.length === 0 ? true : false,
  );
  const {addLicensesBtn} = licensesAndCertifications || {};

  useEffect(() => {
    if (!licenseData || licenseData.length === 0) setAdd(true);
    else setAdd(false);
  }, [licenseData]);

  const cancelAddEdit = () => {
    setAdd(false);
    setEditIndex(-1);
  };

  const editData = idx => {
    setAdd(false);
    setEditIndex(idx);
  };

  const formatData = item => {
    const data = {...item};
    if (item?.skills?.length)
      data['skills'] = item?.skills.map(skill => skill.name);
    return data;
  };

  const onAdd = async formValues => {
    let licenseObject = formatData(formValues);
    if (editIndex !== -1) {
      licenseObject['uuid'] = licenseData[editIndex].student_credential_uuid;
      if (licenseObject?.date_completed_month?.length > 2) {
        licenseObject['date_completed_month'] =
          SettingsModule.months.indexOf(licenseObject.date_completed_month) + 1;
      }
      updateStudentCredential(licenseObject, () => {
        fetchStudentCredentials();
        fetchStudentMetrics();
      });
    } else {
      saveStudentCredential(
        {...licenseObject, credential_type: 'certification'},
        () => {
          fetchStudentCredentials();
          fetchStudentMetrics();
        },
      );
    }
  };

  const deleteData = license => {
    deleteStudentCredential(license.student_credential_uuid, res => {
      if (res?.Error) {
        enqueueSnackbar(res.Error, {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      } else {
        fetchStudentCredentials();
        fetchStudentMetrics();
      }
    });
  };

  return (
    <Stack>
      {/* <RequestErrorLoader
        hideEmpty
        fullWidth
        body={{
          data: licenseData,
          request: studentCredentialRequest,
        }}> */}
      <Stack>
        {licenseData &&
          Array.isArray(licenseData) &&
          licenseData.length > 0 &&
          licenseData.map((license, idx) =>
            editIndex === idx ? (
              <Box key={idx}>
                <AddLicense
                  edit
                  data={license}
                  skills={skills}
                  onSubmit={onAdd}
                  onCancel={cancelAddEdit}
                />
                <Divider sx={{my: 2}} />
              </Box>
            ) : (
              <Box key={idx}>
                <Grid
                  container
                  key={license.student_credential_uuid}
                  py={3}
                  spacing={2}>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      fontSize={{xs: 12, sm: 14, md: 16}}
                      color='text.primary'>
                      License Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    <Box>
                      <Grid
                        py={0}
                        container
                        alignItems='center'
                        spacing={2}
                        sx={{marginLeft: 0}}>
                        <Grid item xs={9} sm={9} md={8} lg={9} px={0}>
                          <Typography
                            variant='h6'
                            fontSize={{xs: 12, sm: 14, md: 16}}
                            color='text.primary'>
                            {license.program_name || '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={4} lg={3} p={0}>
                          {!viewOnly && (
                            <Stack direction='row'>
                              {isMobileView ? (
                                <>
                                  <IconButton onClick={() => editData(idx)}>
                                    <Edit
                                      sx={{fontSize: {xs: 16, sm: 18}}}
                                      color='primary'
                                    />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => deleteData(license)}>
                                    <Delete
                                      sx={{fontSize: {xs: 16, sm: 18}}}
                                      color='primary'
                                    />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <Button
                                    size='large'
                                    onClick={() => editData(idx)}>
                                    Edit
                                  </Button>
                                  <Button
                                    size='large'
                                    onClick={() => deleteData(license)}>
                                    Delete
                                  </Button>
                                </>
                              )}
                            </Stack>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      School:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      {license.org_name || '-'}
                    </Typography>
                  </Grid>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      Date Issued:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      {license.date_completed_month &&
                      license.date_completed_year
                        ? `${
                            SettingsModule.months[
                              license.date_completed_month - 1
                            ]
                          }, ${license.date_completed_year}`
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      Additional Notes:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      {license.comment || '-'}
                    </Typography>
                  </Grid>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      Skills Learned:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    <SkillsStack
                      backgroundColor={blueGrey[400]}
                      color='primary.contrastText'
                      skillSet={license.skills}
                      margin='0px !important'
                      displayLimit={15}
                      displayShowAll
                      emptyMsg='-'
                    />
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            ),
          )}
      </Stack>
      {!viewOnly && (
        <Box py={3}>
          {isAdd && (
            <AddLicense
              skills={skills}
              onSubmit={onAdd}
              onCancel={cancelAddEdit}
              firstTime={!licenseData || licenseData.length === 0}
            />
          )}
          {!isAdd && editIndex === -1 && (
            <Button
              variant='contained'
              sx={{width: '290px'}}
              onClick={() => setAdd(true)}
              size={isMobileView ? 'small' : 'large'}>
              {addLicensesBtn}
            </Button>
          )}
        </Box>
      )}
      {/* </RequestErrorLoader> */}
    </Stack>
  );
};

export default LicenseList;

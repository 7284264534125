import {combineReducers} from 'redux';
import industryList from './industryList';
import nonProfitSubTypes from './nonProfitSubTypes';
import nonProfitCompanyList from './nonProfitCompanyList';
import businessPartnersList from './businessPartnersList';
import becomeBusinessPartner from './becomeBusinessPartner';
import businessPartnerDetails from './businessPartnerDetails';
import nonProfitCompanyDetails from './nonProfitCompanyDetails';
import nonProfitCompanyMembersList from './nonProfitCompanyMembersList';

export const businessPartnerReducer = combineReducers({
  industryList,
  nonProfitSubTypes,
  nonProfitCompanyList,
  businessPartnersList,
  becomeBusinessPartner,
  businessPartnerDetails,
  nonProfitCompanyDetails,
  nonProfitCompanyMembersList,
});

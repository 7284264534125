import React from 'react';
import {BASE_URL} from 'assets/images';
import {
  Box,
  Stack,
  styled,
  useTheme,
  Typography,
  useMediaQuery,
} from '@mui/material';

const Wrapper = styled(Stack)(({theme}) => ({
  justifyContent: 'center',
  padding: '60px 24px 24px',
  [theme.breakpoints.down('md')]: {
    padding: '16px 12px',
  },
}));

const Image = styled('img')(({theme, isShadowLarge}) => ({
  width: '100%',
  maxWidth: '560px',
  objectFit: 'cover',
  borderRadius: '20px',
  boxShadow: isShadowLarge && '1px 5px 60px #c7b3fc',
  [theme.breakpoints.down('lg')]: {
    maxWidth: 450,
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 350,
  },
  boxShadow: '0px 5px 24px 0px rgba(0, 0, 0, 0.08)',
}));

const TalentDetails = ({
  imageFirst = false,
  isShadowLarge = false,
  detail: {heading = '', subHeading = '', imgpath = ''},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Wrapper
        alignItems='center'
        spacing={{sm: 2, md: 6}}
        direction={
          isMobile ? 'column-reverse' : imageFirst ? 'row-reverse' : 'row'
        }>
        <Stack spacing={{xs: 2, sm: 3, md: 6}}>
          <Box sx={{mt: {xs: 2, sm: 3, md: 9}, mb: {xs: 7, sm: 7, md: 15}}}>
            <Typography
              variant='h1'
              lineHeight={1.2}
              fontWeight='normal'
              color='text.primary'
              mb={2}>
              {heading}
            </Typography>
            <Typography
              variant='body1'
              lineHeight={1.5}
              color='text.primary'
              fontSize={{
                xs: '12px !important',
                md: '16px !important',
                lg: '20px !important',
              }}>
              {subHeading}
            </Typography>
          </Box>
        </Stack>
        {imgpath && (
          <Image
            alt={heading}
            isShadowLarge={isShadowLarge}
            src={`${BASE_URL}/${imgpath}`}
          />
        )}
      </Wrapper>
    </>
  );
};

export default TalentDetails;

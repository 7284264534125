import {useCallback, useEffect} from 'react';
import {isEmpty} from 'lodash';
import {connect} from 'react-redux';
import {ContentBox} from 'mui-core';
import Stack from '@mui/material/Stack';
import JobMatches from './components/JobMatches';
import SavedOpportunities from './components/SavedOpportunities';
import AppliedOpportunities from './components/AppliedOpportunities';
import {
  updateProfile,
  fetchStudentSkills,
  fetchStudentMetrics,
  profileDataSelector,
  studentSkillsSelector,
  studentMetricsSelector,
  fetchStudentCredentials,
  studentCredentialsSelector,
} from 'redux/modules/profile';
import {
  getNewOpportunities,
  fetchNewOpportunities,
  resetNewOpportunities,
  fetchAppliedOpportunities,
  resetAppliedOpportunities,
  fetchSavedOpportunities,
  resetSavedOpportunities,
  getAppliedOpportunities,
  getSavedOpportunities,
} from 'redux/modules/occupation';

const MyOpportunities = props => {
  const {
    studentSkills,
    studentMetrics,
    studentCredentials,
    fetchStudentSkills,
    fetchStudentMetrics,
    fetchStudentCredentials,
    appliedOpportunities,
    fetchAppliedOpportunities,
    fetchSavedOpportunities,
    savedOpportunities,
  } = props;
  const {data: studentMetricsData = {}, request: studentMetricsRequest} =
    studentMetrics || {};
  const {
    data: studentCredentialsData = [],
    request: studentCredentialsRequest,
  } = studentCredentials || {};
  const {data: studentSkillsData = [], request: studentSkillsRequest} =
    studentSkills || {};
  const initScreen = useCallback(() => {
    (!studentSkillsData || !studentSkillsData?.length) &&
      !studentSkillsRequest &&
      fetchStudentSkills();
    (!studentCredentialsData || !studentCredentialsData?.length) &&
      !studentCredentialsRequest &&
      fetchStudentCredentials(); // Once the skill is updated, then fetch the latest skills data
    (!studentMetricsData || isEmpty(studentMetricsData)) &&
      !studentMetricsRequest &&
      fetchStudentMetrics();
  });

  useEffect(() => {
    setTimeout(() => {
      initScreen();
    }, 300);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack spacing={3}>
      <ContentBox variant='outlined'>
        <JobMatches {...props} />
      </ContentBox>
      <ContentBox variant='outlined'>
        <AppliedOpportunities
          appliedOpportunities={appliedOpportunities}
          fetchAppliedOpportunities={fetchAppliedOpportunities}
        />
      </ContentBox>
      <ContentBox variant='outlined'>
        <SavedOpportunities
          savedOpportunities={savedOpportunities}
          fetchSavedOpportunities={fetchSavedOpportunities}
        />
      </ContentBox>
    </Stack>
  );
};

const mapStateToProps = state => ({
  jobMatches: getNewOpportunities(state),
  profileData: profileDataSelector(state),
  studentSkills: studentSkillsSelector(state),
  studentMetrics: studentMetricsSelector(state),
  savedOpportunities: getSavedOpportunities(state),
  appliedOpportunities: getAppliedOpportunities(state),
  studentCredentials: studentCredentialsSelector(state),
});

export default connect(mapStateToProps, {
  updateProfile,
  fetchStudentSkills,
  fetchStudentMetrics,
  fetchNewOpportunities,
  resetNewOpportunities,
  fetchStudentCredentials,
  fetchAppliedOpportunities,
  resetAppliedOpportunities,
  fetchSavedOpportunities,
  resetSavedOpportunities,
})(MyOpportunities);

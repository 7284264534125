// components
import {Button, PathCard, ErrorBoundary} from 'mui-core';
import {Box, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
// utils
import PropTypes from 'prop-types';
import {licenseTypeKeys} from 'config';
import {explorePrograms} from 'data/explorePrograms.json';

const {
  qualifyingPrograms: {noResultsMsg, requestTitle, loadingLbl, loadMoreBtn},
} = explorePrograms;

const QualifyingProgramsList = ({
  filters,
  programs,
  refetchData,
  category = '',
  searchKey = '',
  // fixedScrollHeight,
  careerAreaList = [],
  noResults = noResultsMsg,
}) => {
  let hideLoadMore = true;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {data: programsData, pathsSearchRequest} = programs || {};

  const programsList = programsData?.path_details
    ? programsData?.path_details
    : [];
  const recordsCount = programsList?.length || 0;
  const programsTotalCount = programsData?.page_details?.total_count || 0;
  const durationOptions = programsData?.duration_list
    ? programsData?.duration_list
    : [];
  const awardTypeOptions = programsData?.award_type_list
    ? programsData?.award_type_list
    : [];

  const loadMorePrograms = () => {
    if (!hideLoadMore && !pathsSearchRequest) {
      const newPage = filters.page + 1;
      // const Obj = Object.assign({}, reqParams, {
      //   page: newPage,
      // });
      // setFilters({...filters, page: newPage});
      refetchData({...filters, page: newPage});
    }
  };

  // key => {
  //   const Obj = Object.assign({}, reqParams, {
  //     page: 1,
  //     sort: key,
  //   });
  //   let newFilter = filters.map(filter =>
  //     filter.type === 'sort_by' ? {...filter, title: key} : filter,
  //   );
  //   setFilters(newFilter);
  //   setReqParams({...reqParams, ...Obj});
  //   refetchData(Obj, newFilter, 1);
  // },
  // [filters, refetchData],

  if (programsData) {
    hideLoadMore =
      programsData?.page_details &&
      filters?.page >= programsData?.page_details?.no_of_pages;
  }

  const filterLength = (filters && Object.keys(filters).length) || 0;

  return (
    <ErrorBoundary
      nameOfComponent='module-qualifying-programs-list'
      typeOfUi='subPage'>
      {!pathsSearchRequest &&
      filters &&
      (category ? filterLength <= 4 : filterLength <= 3) &&
      !(
        programsList?.length &&
        durationOptions?.length > 0 &&
        awardTypeOptions?.length > 0
      ) ? (
        <Box
          py={5}
          display='flex'
          minHeight={500}
          textAlign='center'
          alignItems='center'
          justifyContent='center'>
          <Typography color='text.primary' variant='body1'>
            {noResults}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={
            !pathsSearchRequest && programsList?.length === 0
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }
              : {}
          }>
          {recordsCount > 0 && programsTotalCount > 0 && (
            <>
              <Grid
                container
                sx={{py: 2}}
                columnSpacing={{xs: 2, sm: 2}}
                rowSpacing={{xs: 2, sm: 2, md: 3, lg: 4}}
                justifyContent={isMobile ? 'center' : 'left'}>
                {programsList?.length > 0 &&
                  programsList.map(path => {
                    const isCriminalJustice =
                      path?.institute_details?.super_type_name ===
                      licenseTypeKeys?.criminalJustice;
                    return (
                      <Grid
                        item
                        lg={3}
                        xs={12}
                        sm='auto'
                        key={path?.id}
                        justifyContent='center'
                        sx={{
                          flexBasis: 'fit-content',
                          textAlign: {xs: 'center', sm: 'left'},
                        }}>
                        <PathCard
                          width={320}
                          data={path}
                          key={path?.id}
                          enableNavigation
                          hideLocation={isCriminalJustice}
                          hideAwardType={!path?.award_type_name}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
              {!hideLoadMore && (
                <Box
                  pt={4}
                  pb={3}
                  display='flex'
                  alignContent='center'
                  justifyContent='center'>
                  {(filters?.page === 1 ? !pathsSearchRequest : true) && (
                    <Button
                      size='large'
                      color='primary'
                      variant='outlined'
                      sx={{
                        padding: '8px 54px',
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.grayLight.main
                            : theme.palette.primary.dark,
                      }}
                      onClick={loadMorePrograms}>
                      {pathsSearchRequest ? loadingLbl : loadMoreBtn}
                    </Button>
                  )}
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </ErrorBoundary>
  );
};

QualifyingProgramsList.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  noResults: PropTypes.string,
  searchKey: PropTypes.string,
  // fixedScrollHeight: PropTypes.number,
};

export default QualifyingProgramsList;

import {isObject, isEmpty} from 'lodash';
import * as TYPES from './constants';
import {API} from 'config';

import {requestSchools, recieveSchools, requestSchoolsError} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SCHOOLS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.SCHOOLS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.SCHOOLS_CLEAR:
      return {
        ...state,
        request: false,
        error: false,
        data: null,
      };
    case TYPES.SCHOOLS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchSchools = params => (dispatch, getState) => {
  const {search: {schools: {request} = {}} = {}} = getState();
  try {
    if (!request && params && isObject(params) && !isEmpty(params)) {
      dispatch(requestSchools());
      const endPoint = `${API.srm.institute}?student_register=true&${
        params.type
      }=${encodeURIComponent(params.value)}`;
      return services.fetchRecords(endPoint, null, true).then(res => {
        if (res && Array.isArray(res)) {
          dispatch(recieveSchools(res));
          return;
        }
        dispatch(requestSchoolsError(res));
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestSchoolsError(err));
  }
};

import {Tabs} from 'antd';

import './style.less';

const {TabPane} = Tabs;

const CustomTabs = ({onChange, tabsList = [], type = 'card', activeKey}) => {
  return (
    <Tabs onChange={onChange} type={type} activeKey={activeKey}>
      {tabsList?.map(({key, title, children}) => (
        <TabPane tab={title} key={key}>
          {children}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default CustomTabs;

import * as React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {ArrowDropDown} from '@mui/icons-material';
import {
  Stack,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {Button} from 'mui-core/Button';

const StyledAccordion = styled(Accordion)(
  ({theme, outlined, rounded, isAccordion}) => {
    let style = {
      margin: '1px',
      boxShadow: outlined ? `0px 0px 0px 1px ${theme.palette.divider}` : 'none',
      '&::before': {
        height: '0px !important',
      },
    };
    if (!isAccordion) {
      style = {
        borderRadius: rounded ? 16 : 'none',
        '&:last-of-type': {
          borderRadius: rounded ? 16 : 'none',
        },
        ...style,
      };
    }
    return style;
  },
);

const StyledAccordionSummary = styled(props => (
  <AccordionSummary
    expandIcon={<ArrowDropDown fontSize='large' color='text.primary' />}
    {...props}
  />
))(({isExpanded, titleDivider, collpsable, theme}) => ({
  paddingTop: 8,
  paddingBottom: 8,
  borderBottom:
    titleDivider && isExpanded ? `1px solid ${theme.palette.divider}` : `none`,
  cursor: collpsable ? 'pointer' : 'default !important',
  '& .MuiAccordionSummary-content': {
    margin: '0px',
    cursor: collpsable ? 'pointer' : 'default',
    '& .MuiTypography-root': {
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    display: collpsable ? 'block' : 'none',
  },
}));

const StyledAccordionDetails = styled(props => <AccordionDetails {...props} />)(
  ({theme}) => ({
    padding: '16px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px',
    },
  }),
);

const CustomizedAccordions = props => {
  const {
    id,
    icon,
    header,
    onChange,
    children,
    isButton,
    isExpand,
    buttonText,
    buttonProps,
    controlled,
    description,
    elevation = 0,
    rounded = true,
    outlined = true,
    collpsable = true,
    titleDivider = true,
    defaultOpen = false,
    TransitionProps = {},
    expandIcon = <ArrowDropDown fontSize='large' color='text.primary' />,
  } = props;
  const [expanded, setExpanded] = React.useState(defaultOpen);
  React.useEffect(() => {
    if (props.expanded !== undefined) setExpanded(props.expanded);
  }, [props.expanded]);

  const handleChange = () => {
    collpsable && setExpanded(!expanded);
    if (collpsable && !expanded && onChange) {
      onChange(!expanded);
    }
  };

  return (
    <StyledAccordion
      {...props}
      disableGutters
      rounded={rounded}
      outlined={outlined}
      elevation={elevation}
      TransitionProps={TransitionProps}
      expanded={controlled ? isExpand : expanded}
      onChange={controlled ? onChange : handleChange}>
      <StyledAccordionSummary
        isExpanded={expanded}
        expandIcon={expandIcon}
        collpsable={collpsable}
        id={`panel${id}-header`}
        titleDivider={titleDivider}
        aria-controls={`panel${id}-content`}>
        <Stack alignItems='left' width='100%' spacing={1} py={1} px={2}>
          <Stack
            spacing={2}
            direction={'row'}
            alignItems={'center'}
            justifyContent={isButton ? 'space-between' : 'flex-start'}>
            {icon}
            {header &&
              (typeof header === 'string' ? (
                <Typography variant='h4' title={header} color='text.primary'>
                  {header}
                </Typography>
              ) : (
                header
              ))}
            {isButton && <Button {...buttonProps}>{buttonText}</Button>}
          </Stack>
          {description && (
            <Typography
              variant='body2'
              fontWeight={300}
              title={description}
              color='text.primary'>
              {description}
            </Typography>
          )}
        </Stack>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

CustomizedAccordions.propTypes = {
  ...Accordion.propTypes,
  id: PropTypes.string,
  icon: PropTypes.node,
  rounded: PropTypes.bool,
  children: PropTypes.node,
  outlined: PropTypes.bool,
  onChange: PropTypes.func,
  collpsable: PropTypes.bool,
  expandIcon: PropTypes.node,
  elevation: PropTypes.number,
  defaultOpen: PropTypes.bool,
  titleDivider: PropTypes.bool,
  description: PropTypes.string,
  header: PropTypes.string.isRequired,
  controlled: PropTypes.bool, // if true MUI expanded pros assigned with isExpand prop, and onChange is triggered to parent onChnage
  isExpand: PropTypes.bool,
};

export default CustomizedAccordions;

import * as TYPES from './constants';
import {API} from 'config';
import {
  requestExistStatusCheck,
  receiveExistStatusCheck,
  requestExistStatusCheckError,
} from './actions';
import {Services} from 'core/Services';

const services = new Services();

const initialState = {
  data: null,
  error: false,
  request: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_EXIST_STATUS_CHECK:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECEIVE_EXIST_STATUS_CHECK:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_EXIST_STATUS_CHECK_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };

    default:
      return state;
  }
};

export const checkExistsStatus = (obj, callBack) => dispatch => {
  dispatch(requestExistStatusCheck());
  const endPoint = `${API.srm.license}`;
  //   const body = obj;
  try {
    return services.createUpdateRecord('', endPoint, obj, 'POST').then(res => {
      if (res) {
        dispatch(receiveExistStatusCheck(res));
        if (callBack) callBack(res);
        return;
      } else {
        dispatch(requestExistStatusCheckError('No data available'));
      }
    });
  } catch (err) {
    console.log(err);
    dispatch(requestExistStatusCheckError(err));
  }
};

// export const checkEIN = (ein_no, callBack) => dispatch => {
//   dispatch(requestEINCheck());

//   const endPoint = `${API.srm.license}`;
//   const body = {ein_no: ein_no};
//   try {
//     return services.createUpdateRecord('', endPoint, body, 'POST').then(res => {
//       if (res) {
//         dispatch(receiveEINCheck(res));
//         if (callBack) callBack(res);
//         return;
//       } else {
//         dispatch(requestEINCheckError('No data available'));
//       }
//     });
//   } catch (err) {
//     console.log(err);
//     dispatch(requestEINCheckError(err));
//   }
// };

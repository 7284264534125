import React from 'react';
import {Collapse} from 'antd';
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';

import {ErrorBoundary} from 'core/components';

import './style.less';

const {Panel} = Collapse;
const CustomCollapse = ({
  header,
  children,
  defaultOpen,
  hideExpandIcon,
  extraNode,
  onChange,
}) => (
  <ErrorBoundary nameOfComponent='core-custom-collapse'>
    <Collapse
      onChange={onChange ? onChange : () => {}}
      collapsible={extraNode && 'header'}
      defaultActiveKey={(defaultOpen && 'self') || null}
      expandIcon={({isActive}) =>
        !hideExpandIcon && (isActive ? <MinusOutlined /> : <PlusOutlined />)
      }
      expandIconPosition='right'>
      <Panel header={header} key='self' extra={extraNode}>
        {children}
      </Panel>
    </Collapse>
  </ErrorBoundary>
);

export default CustomCollapse;

import React, {useCallback, useEffect, useMemo, useState} from 'react';
// hooks
import {useInstance} from 'core/hooks';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
// redux
import {
  fetchCareerAreaList,
  getAllCareerAreaList,
} from 'redux/modules/programs';
import {
  getCareerRank,
  clearCareerRank,
  fetchOccupationRank,
} from 'redux/modules/career-destination';
// Components
import {
  CareerList,
  FilterForm,
} from './subModules/CareerListContainer/components';
import {
  Box,
  alpha,
  Stack,
  styled,
  Button,
  Tooltip,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
  tooltipClasses,
  ClickAwayListener,
} from '@mui/material';
import {VideoBanner, LayoutContainer, themeColorMode} from 'mui-core';
import {InfoIconwhite, goAIButtonIcon, careerVideoBanner} from 'assets/images';
// utils
import {exploreCareer} from 'data/exploreCareer.json';

const {
  careerBannerInfo: {
    ribbonDesc,
    subHeading1,
    ribbonTitle,
    ribbonBtnText,
    bannerHeading,
  },
  FilterOptions,
  defaultLocation,
} = exploreCareer;

const StyledRibbon = styled(Stack)(({theme}) => ({
  zIndex: 1,
  bottom: 0,
  width: '100%',
  position: 'absolute',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.primary.dark, 0.7),
  [theme.breakpoints.down('lg')]: {
    bottom: '5px',
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    backgroundColor: alpha(theme.palette.primary.dark, 0.9),
  },
}));

const StyledInfoRounded = styled('img')(({}) => ({
  marginLeft: 3,
  width: 15,
  height: 15,
  cursor: 'pointer',
}));

const sortOptions = [
  {
    title: 'A - Z',
    value: 'name_asc',
  },
  {
    title: 'Z - A',
    value: 'name_desc',
  },
];

const defaultParams = {
  page: 1,
  page_size: 16,
  sort: 'name_asc',
  selectedTab: FilterOptions[0],
};

const tabKeys = ['', 'in-demand', 'highest-paying'];

const ExploreCareer = ({gSearch = ''}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const {activeView = ''} = useParams();
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  //redux
  const careerRank = useSelector(getCareerRank) || {};
  const careerAreaList = useSelector(getAllCareerAreaList) || {};
  const {data = {}, request: requestCareerRank} = careerRank || {};
  const {
    data: careerRankList = [],
    page_details: careerRankPageDetails = {},
  } = data;
  const recordsCount = careerRankList?.length || 0;
  const totalCount = careerRankPageDetails?.total_results || 0;
  const {
    data: {
      super_type_name = null,
      default_msa: {city = '', state_code = '', msa_code = ''} = {},
    } = {},
  } = useInstance();
  let defaultLoc = defaultLocation;
  if (super_type_name && msa_code) {
    defaultLoc = `${city}, ${state_code}`;
  }
  const defaultFilters = useMemo(
    () => ({
      title: '',
      cluster_uuid: 'all',
      location: defaultLoc,
      msa_code: msa_code || '',
    }),
    [defaultLoc, msa_code],
  );

  const [filters, setFilters] = useState(defaultFilters);
  const [reqParams, setReqParams] = useState(defaultParams);

  const formatLabels = {
    'in-demand': 'In-Demand',
    'highest-paying': 'Highest Paying',
  };
  const formatDescriptions = {
    'in-demand':
      'Discover the most sought-after career paths and job opportunities in today’s market.',
    'highest-paying':
      'Discover top earning careers and unlock your path to success.',
  };

  const formatText = () => formatLabels[activeView] || '';
  const formatDescription = () =>
    formatDescriptions[activeView] ||
    'Browse a comprehensive list of career paths to find the one that aligns with your skills and goals';

  useEffect(() => {
    if (tabKeys?.includes(activeView)) {
      dispatch(fetchCareerAreaList(true));
    } else {
      history.push(`/careers`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newParams = {...defaultParams};
    let newFilters = {...defaultFilters};
    if (gSearch) {
      newFilters = {...newFilters, title: gSearch};
    }
    if (activeView) {
      const careerType = FilterOptions?.find(
        item => item?.route === activeView,
      );
      newParams = {
        ...newParams,
        selectedTab: careerType,
      };
    }
    refetchData(newParams, newFilters);
    return () => {
      dispatch(clearCareerRank());
    };
  }, [activeView, gSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const refetchData = (newReqParams, newFilters, loadMore) => {
    let updatedFilters = {...filters, ...newFilters};
    if (updatedFilters?.location === '') {
      updatedFilters = {
        ...updatedFilters,
        location: defaultLoc,
        msa_code: msa_code || '',
      };
    }
    setReqParams(newReqParams);
    setFilters(updatedFilters);
    dispatch(fetchOccupationRank(newReqParams, updatedFilters, loadMore));
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const onFilterReset = resetObj => {
    const Obj = {...reqParams, page: 1};
    refetchData(Obj, resetObj);
    onDrawerClose();
  };

  const onFinish = useCallback(
    values => {
      let filterApply = false;
      let newFilters = {...filters, ...values};
      const locationObj = values.location;
      if (values.location === 'United States' || values.location === '') {
        newFilters = {
          ...newFilters,
          msa_code: '',
          location: defaultLocation,
        };
      } else {
        if (locationObj?.location) {
          if (locationObj?.msa_code) {
            newFilters = {
              ...newFilters,
              msa_code: locationObj?.msa_code,
              location: locationObj.location,
            };
          } else {
            newFilters = {
              ...newFilters,
              location: locationObj.location,
              msa_code: '',
            };
          }
        }
      }
      if (newFilters) {
        const filterKeys = Object.keys(newFilters);
        filterKeys.forEach(key => {
          if (newFilters[key] || newFilters[key] === '') {
            filterApply = true;
          }
        });
        if (filterApply) {
          const Obj = {...reqParams, page: 1};
          refetchData(Obj, newFilters);
          onDrawerClose();
        }
      }
      /*    if (values) {
        const filterKeys = Object.keys(values);
        filterKeys.forEach(key => {
          if (values[key] || values[key] === '') {
            filterApply = true;
          }
        });
        if (filterApply) {
          const Obj = {...reqParams, page: 1};
          refetchData(Obj, values);
          onDrawerClose();
        }
      } */
    },
    [filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const tabs = useMemo(() => {
    return [
      {
        key: '',
        tabName: 'All',
        component: (
          <Box pt={2}>
            <CareerList
              filters={filters}
              reqParams={reqParams}
              careerRank={careerRank}
              refetchData={refetchData}
            />
          </Box>
        ),
      },
      {
        key: 'in-demand',
        tabName: 'In-Demand',
        component: (
          <Box pt={2}>
            <CareerList
              filters={filters}
              reqParams={reqParams}
              careerRank={careerRank}
              refetchData={refetchData}
            />
          </Box>
        ),
      },
      {
        key: 'highest-paying',
        tabName: 'Highest Paying',
        component: (
          <Box pt={2}>
            <CareerList
              filters={filters}
              reqParams={reqParams}
              careerRank={careerRank}
              refetchData={refetchData}
            />
          </Box>
        ),
      },
    ];
  }, [careerRank, activeView]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTabChange = (e, path) => {
    if (tabKeys.includes(path)) {
      history.push(`/careers/${path}`);
    } else {
      history.push(`/careers`);
    }
  };

  const onSortChange = value => {
    const newParams = {...reqParams, sort: value, page: 1};
    refetchData(newParams, filters);
  };

  const handleJoinNowClick = () => {
    history.push(`/GOSurvey`);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(prev => !prev);
  };

  const RenderBannerRibbon = () => (
    <StyledRibbon sx={{height: {xs: '100px', sm: '80px', md: '100px'}}}>
      <Container maxWidth='xl'>
        <Stack
          alignItems='center'
          justifyContent='center'
          gap={{xs: 1, sm: 2, md: 3, lg: 4}}
          direction={{xs: 'column', sm: 'row'}}>
          <Typography
            color='white'
            component='h3'
            variant='header-sm-medium'
            sx={{textShadow: '0px 4px 4px #00000040'}}
            fontSize={{xs: '20px', sm: '25px', lg: '30px'}}
            minWidth={{xs: '190px', sm: '228px', lg: '265px'}}>
            {ribbonTitle}
          </Typography>
          <Button
            variant='contained'
            onClick={handleJoinNowClick}
            endIcon={<img src={goAIButtonIcon} width={16} alt='GoAI Icon' />}
            sx={{
              textTransform: 'uppercase',
              padding: {xs: '4px 12px', lg: '12px 16px'},
              minWidth: {xs: '114px', md: '200px', lg: '324px'},
            }}>
            {ribbonBtnText}
          </Button>
        </Stack>
      </Container>
    </StyledRibbon>
  );

  const dataSet = {
    data: careerRankList,
    request: requestCareerRank,
  };

  return (
    <LayoutContainer
      dataSet={dataSet}
      typeOfUi='fullPage'
      showSort={!gSearch}
      showSearch={!gSearch}
      activeTab={activeView}
      showDivider={!gSearch}
      disableBGcolor={!!gSearch}
      tabArr={gSearch ? [] : tabs}
      showFilterBtn={gSearch ? !!recordsCount : true}
      nameOfComponent={!gSearch ? 'explore careers' : 'career searches'}
      banner={
        gSearch ? null : (
          <VideoBanner
            bannerURL={careerVideoBanner}
            customRibbon={<RenderBannerRibbon />}>
            <Stack gap={{xs: 2, md: 5}}>
              <Typography
                color='white'
                component='h1'
                variant='header-lg-bold'
                width={{xs: '100%', md: '90%', lg: '100%'}}
                dangerouslySetInnerHTML={{
                  __html: bannerHeading,
                }}
              />
              <Typography
                color='white'
                component='p'
                variant='text-xl-regular'
                width={{xs: '100%', sm: '95%', md: '75%', lg: '80%'}}
                dangerouslySetInnerHTML={{
                  __html: subHeading1,
                }}
              />
            </Stack>
          </VideoBanner>
        )
      }
      searchResultProps={{
        totalCount: totalCount,
        recordsCount: recordsCount,
      }}
      onChange={(e, index) => onTabChange(e, index)}
      searchProps={{
        filters,
        onFinish,
        onDrawerOpen,
        onFilterReset,
        name: 'title',
        searchPlaceholder: 'Search by career name',
      }}
      sortProps={{
        onSortChange,
        sortArr: sortOptions,
        sort: reqParams?.sort,
      }}
      filterForm={
        <FilterForm
          filters={filters}
          onFinish={onFinish}
          isDrawerOpen={isDrawerOpen}
          onDrawerClose={onDrawerClose}
          onFilterReset={onFilterReset}
          defaultFilters={defaultFilters}
          careerAreaList={careerAreaList}
        />
      }
      title={gSearch ? '' : `Explore ${formatText() || 'All'} Careers`}
      subTitle={gSearch ? '' : formatDescription()}>
      {gSearch && (
        <CareerList
          reqParams={reqParams}
          careerRank={careerRank}
          refetchData={refetchData}
        />
      )}
    </LayoutContainer>
  );
};

export default ExploreCareer;

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestForgotPassword,
  successForgotPassword,
  errorForgotPassword,
  errorConfirmForgotPassword,
  requestConfirmForgotPassword,
  successConfirmForgotPassword,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {
  request: false,
  data: null,
  error: false,
  requestConfirm: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_FORGOT_PASSOWORD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.SUCCESS_FORGOT_PASSOWORD:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.ERROR_FORGOT_PASSOWORD:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.REQUEST_CONFIRM_FORGOT_PASSOWORD:
      return {
        ...state,
        requestConfirm: true,
        error: false,
      };
    case TYPES.SUCCESS_CONFIRM_FORGOT_PASSOWORD:
      return {
        ...state,
        requestConfirm: false,
        error: false,
      };
    case TYPES.ERROR_CONFIRM_FORGOT_PASSOWORD:
      return {
        ...state,
        requestConfirm: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const forgotPassword = (data, callback) => async dispatch => {
  try {
    let endPoint = `${API.gps.forgot_password}`;
    dispatch(requestForgotPassword());
    return services
      .createUpdateRecord(null, endPoint, data, 'POST')
      .then(res => {
        if (res && (res.success || res.Success)) {
          dispatch(successForgotPassword(res));
        } else {
          dispatch(errorForgotPassword(res.Error ? res : 'Error'));
        }
        callback && callback(res);
      });
  } catch (err) {
    console.log(err);
    dispatch(errorForgotPassword(err));
  }
};

export const confirmForgotPassword = (
  data,
  callback,
  errorCallBack,
) => async dispatch => {
  try {
    let endPoint = `${API.gps.confirm_forgot_password}`;
    dispatch(requestConfirmForgotPassword());
    return services
      .createUpdateRecord(null, endPoint, data, 'POST')
      .then(res => {
        if (res && (res.success || res.Success)) {
          dispatch(successConfirmForgotPassword(res));
          if (callback) callback(res);
          return;
        }
        if (errorCallBack) errorCallBack(res);
        dispatch(errorConfirmForgotPassword(res.Failed ? res : 'Error'));
      })
      .catch(err => {
        console.log(err);
      });
  } catch (err) {
    dispatch(errorConfirmForgotPassword(err));
  }
};

//EDUCATOR_LIST
export const CLEAR_EDUCATOR_LIST = 'CLEAR_EDUCATOR_LIST';
export const REQUEST_EDUCATOR_LIST = 'REQUEST_EDUCATOR_LIST';
export const RECIEVE_EDUCATOR_LIST = 'RECIEVE_EDUCATOR_LIST';
export const RECIEVE_ALL_EDUCATOR_LIST = 'RECIEVE_ALL_EDUCATOR_LIST';
export const REQUEST_EDUCATOR_LIST_ERROR = 'REQUEST_EDUCATOR_LIST_ERROR';

// INSTITUTION_SUBTYPES
export const CLEAR_INSTITUTION_SUBTYPES_LIST =
  'CLEAR_INSTITUTION_SUBTYPES_LIST';
export const REQUEST_INSTITUTION_SUBTYPES_LIST =
  'REQUEST_INSTITUTION_SUBTYPES_LIST';
export const RECIEVE_INSTITUTION_SUBTYPES_LIST =
  'RECIEVE_INSTITUTION_SUBTYPES_LIST';
export const REQUEST_INSTITUTION_SUBTYPES_LIST_ERROR =
  'REQUEST_INSTITUTION_SUBTYPES_LIST_ERROR';

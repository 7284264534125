import {useEffect, useImperativeHandle, useState} from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {createField} from 'mui-core/Form';
import resumeBuilderData from 'data/resumeBuilder.json';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {datePickerSlotProp} from '../../sharedComponents';
import {getLinkedInDetails} from 'redux/modules/resume-builder';
import {Box, Stack, Typography, OutlinedInput} from '@mui/material';
import {Grid, Button, TextEditor, AutoCompleteWrapper} from 'mui-core';
const {
  stepsMeta: {
    education: {formData = {}},
  },
} = resumeBuilderData || {};
const {
  endDate,
  addBtnTxt,
  startDate,
  awardType,
  schoolName,
  fieldOfStudy,
  optionalText,
  cancelBtnTxt,
  descriptionObj,
  AddEducationTxt,
  addAdditionalDetailsText,
} = formData || {};

const AddEducation = ({
  edit,
  formRef,
  onSubmit,
  onCancel,
  firstTime,
  data = {},
  getPayload,
}) => {
  const form = useForm({mode: 'all'});
  const {
    watch,
    trigger,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: {errors, isValid},
  } = form;
  const [openAward, setOpenAward] = useState(false);
  const linkedInData = useSelector(getLinkedInDetails) || {};
  const [openAreaOfFocus, setOpenAreaOfFocus] = useState(false);

  const {
    org_name,
    award_type,
    description,
    area_of_focus,
    school_end_date,
    school_start_date,
  } = data || {};
  const {
    degree_name: degree_name_options = [],
    field_of_study: field_of_study_options = [],
  } = linkedInData;

  const watchEndDate = watch(
    'school_end_date',
    school_end_date ? moment(school_end_date) : null,
  );
  const watchStartDate = watch(
    'school_start_date',
    school_start_date ? moment(school_start_date) : null,
  );

  useEffect(() => {
    watch(values => {
      const isFormEmpty = Object.values(values).every(value =>
        Array.isArray(value) ? value.length === 0 : !value,
      );

      if (isFormEmpty) {
        setTimeout(clearErrors);
      }
    });
  }, [watch]);

  const onFinish = (newData, e) => {
    if (onSubmit && isEmpty(errors)) {
      const updatedData = {...data, ...newData};
      if (updatedData.school_start_date) {
        const {years: eduStartYear, months: eduStartMonth} = moment(
          updatedData.school_start_date,
        ).toObject();
        updatedData.school_start_date = moment()
          .date(5)
          .year(eduStartYear)
          .month(eduStartMonth)
          .toISOString();
      }
      if (updatedData.school_end_date) {
        const {years: eduEndYear, months: eduEndMonth} = moment(
          updatedData.school_end_date,
        ).toObject();
        updatedData.school_end_date = moment()
          .date(5)
          .year(eduEndYear)
          .month(eduEndMonth)
          .toISOString();
      }
      onSubmit(updatedData);
    }
  };

  useImperativeHandle(formRef, () => ({
    validateForm() {
      let isFormValid = true;
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      if (isFormFilled) {
        trigger();
        isFormValid = isValid;
      }
      return isFormValid;
    },
    getFormData() {
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      return isFormFilled ? getPayload({...data, ...getValues()}) : null;
    },
  }));

  return (
    <Box>
      <Stack>
        {!edit && !firstTime && (
          <Typography
            mt={1}
            variant='h5'
            color='text.primary'
            title={AddEducationTxt}>
            {AddEducationTxt}
          </Typography>
        )}
        <Box mx='auto' my={2}>
          <form onSubmit={handleSubmit(onFinish)}>
            <Grid
              container
              spacing={1}
              display='flex'
              rowSpacing={3}
              alignItems='center'
              justifyContent='start'>
              <Grid item xs={12}>
                {createField('org_name', {
                  form,
                  size: 'small',
                  label: schoolName.label,
                  defaultValue: org_name || '',
                  rules: {
                    required: [true, schoolName.requiredMsg],
                    maxLength: [120, schoolName.maxLengthMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    label={schoolName.label}
                    id='resume-education-school-name'
                    placeholder={schoolName.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {createField('award_type', {
                  form,
                  size: 'small',
                  defaultValue: award_type || '',
                })(
                  <AutoCompleteWrapper
                    form={form}
                    fieldName='award_type'
                    value={award_type || ''}
                    label={awardType.label}
                    placeholder={awardType.placeholder}
                    options={
                      degree_name_options &&
                      Array.isArray(degree_name_options) &&
                      degree_name_options.length > 0
                        ? degree_name_options?.map(item => {
                            return {
                              ...item,
                              key: item.id,
                              label: item.degree_name,
                              title: item.degree_name,
                              award_type: item.degree_name,
                            };
                          })
                        : []
                    }
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {createField('area_of_focus', {
                  form,
                  size: 'small',
                  defaultValue: area_of_focus || '',
                })(
                  <AutoCompleteWrapper
                    form={form}
                    value={area_of_focus || ''}
                    fieldName='area_of_focus'
                    options={
                      field_of_study_options?.map(item => {
                        return {
                          ...item,
                          key: item.id,
                          label: item.field_of_study,
                          title: item.field_of_study,
                          area_of_focus: item.field_of_study,
                        };
                      }) || []
                    }
                    label={fieldOfStudy.label}
                    placeholder={fieldOfStudy.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12} sm={10} md={12} lg={8}>
                <Grid item xs={12}>
                  {createField('school_start_date', {
                    form,
                    rules: {
                      maxDate: [watchEndDate, startDate.maxDateMsg],
                    },
                    defaultValue: school_start_date
                      ? moment(school_start_date)
                      : null,
                  })(
                    <DatePicker
                      maxDate={watchEndDate}
                      label={startDate.label}
                      views={['year', 'month']}
                      showDaysOutsideCurrentMonth
                      slotProps={datePickerSlotProp}
                    />,
                  )}
                </Grid>
                <Grid item xs={12}>
                  {createField('school_end_date', {
                    form,
                    size: 'small',
                    dependent: ['school_start_date'],
                    defaultValue: school_end_date
                      ? moment(school_end_date)
                      : null,
                    rules: {
                      required: [true, endDate.requiredMsg],
                      minDate: [watchStartDate, endDate.minDateMsg],
                    },
                  })(
                    <DatePicker
                      label={endDate.label}
                      minDate={watchStartDate}
                      views={['year', 'month']}
                      showDaysOutsideCurrentMonth
                      slotProps={datePickerSlotProp}
                    />,
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  spacing={1}
                  direction='row'
                  alignItems='center'
                  justifyContent='start'>
                  <Typography
                    variant='h5'
                    color='text.primary'
                    title={addAdditionalDetailsText}>
                    {addAdditionalDetailsText}
                  </Typography>
                  <Typography
                    variant='body1'
                    color='text.primary'
                    title={optionalText}>
                    {`(${optionalText})`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {createField('description', {
                  form,
                  defaultValue: description || '',
                })(
                  <TextEditor
                    hint={descriptionObj.hint}
                    label={descriptionObj.label}
                  />,
                )}
              </Grid>
              <Grid
                item
                xs={12}
                display='flex'
                alignItems='center'
                justifyContent='center'>
                {!firstTime && (
                  <Button
                    variant='outlined'
                    onClick={onCancel}
                    sx={{mr: 2, minWidth: '100px'}}>
                    {cancelBtnTxt}
                  </Button>
                )}
                <Button
                  type='submit'
                  formNoValidate
                  variant='contained'
                  sx={{minWidth: '100px'}}>
                  {addBtnTxt}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default AddEducation;

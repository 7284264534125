import * as React from 'react';
import {useTheme} from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

const EventIcon = props => {
  const theme = useTheme();
  const fillColor = theme?.palette?.mode === 'light' ? 'black' : 'white';

  return (
    <SvgIcon {...props}>
      <path
        d='M14.0935 8.42956C13.983 8.42956 13.8786 8.38809 13.8008 8.31278C13.722 8.23746 13.6796 8.13845 13.6796 8.03267L13.6822 6.45104C13.6822 6.3368 13.7335 6.22763 13.8246 6.15316L15.5688 4.70101C16.4117 3.99863 17.0883 3.10669 17.5244 2.12166L17.8764 1.32788C17.9277 1.2111 17.8746 1.0757 17.7561 1.01985C17.7207 1.00377 17.6836 0.995307 17.6455 0.995307C17.5544 0.995307 17.4704 1.0427 17.4271 1.1197L17.1582 1.59106C16.5647 2.6311 15.7174 3.54504 14.7082 4.23219C14.3854 4.45221 14.0051 4.56899 13.6079 4.56899H10.3708C9.9719 4.56899 9.58981 4.45137 9.26433 4.22881C8.26576 3.54335 7.42641 2.63617 6.83824 1.60376L6.56228 1.12055C6.51894 1.04354 6.43492 0.995307 6.34293 0.995307C6.30402 0.995307 6.26687 1.00292 6.23238 1.01985C6.11386 1.0757 6.06079 1.2111 6.11209 1.32788L6.46411 2.12166C6.90103 3.10669 7.57676 3.99863 8.41966 4.70101L10.1647 6.15316C10.2549 6.22848 10.3071 6.3368 10.3071 6.45189V8.03352C10.3071 8.25185 10.1223 8.42956 9.89318 8.42956C9.66411 8.42956 9.48014 8.25269 9.48014 8.03352V6.61098L7.87837 5.29676C6.94083 4.51653 6.18815 3.52473 5.70346 2.42969L5.35145 1.63507C5.12768 1.13155 5.35587 0.54849 5.86886 0.30731C6.01922 0.237072 6.17842 0.20153 6.34382 0.20153C6.73741 0.20153 7.10004 0.408013 7.28843 0.739741L7.56438 1.22295C8.09418 2.15128 8.84863 2.9679 9.74636 3.58397C9.92945 3.71006 10.1461 3.77606 10.3708 3.77606H13.6079C13.8317 3.77606 14.0458 3.71006 14.2288 3.58566C15.1354 2.96706 15.8978 2.14535 16.432 1.20941L16.7009 0.738895C16.8902 0.407167 17.2519 0.200684 17.6455 0.200684C17.8109 0.200684 17.971 0.237072 18.1205 0.306464C18.6344 0.547644 18.8617 1.13071 18.6379 1.63422L18.2859 2.42884C17.8012 3.52388 17.0494 4.51568 16.111 5.29592L14.4924 6.64398L14.5066 8.03267C14.5066 8.251 14.3217 8.42787 14.0935 8.42787V8.42956Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M11.9882 3.48229C10.9843 3.48229 10.168 2.70121 10.168 1.74072C10.168 0.780237 10.9843 0 11.9882 0C12.9921 0 13.8084 0.781083 13.8084 1.74157C13.8084 2.70206 12.9921 3.48314 11.9882 3.48314V3.48229ZM11.9882 0.791238C11.4407 0.791238 10.9949 1.2169 10.9949 1.74072C10.9949 2.26455 11.4398 2.69106 11.9882 2.69106C12.5366 2.69106 12.9806 2.2654 12.9806 1.74072C12.9806 1.21605 12.5357 0.791238 11.9882 0.791238Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M8.1371 23.9997C7.90891 23.9997 7.72317 23.8228 7.72317 23.6045V21.3019C7.72317 20.6181 7.14119 20.0613 6.42654 20.0613H2.12361C1.40807 20.0613 0.826977 20.6181 0.826977 21.3019V23.6045C0.826977 23.8228 0.642123 23.9997 0.413931 23.9997C0.185738 23.9997 0 23.8228 0 23.6045V21.3019C0 20.1814 0.952571 19.27 2.12361 19.27H6.42654C7.59757 19.27 8.55015 20.1814 8.55015 21.3019V23.6045C8.55015 23.8228 8.36529 23.9997 8.1371 23.9997Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M4.27623 18.9465C3.25202 18.9465 2.41797 18.1494 2.41797 17.1686C2.41797 16.1878 3.25114 15.3906 4.27623 15.3906C5.30133 15.3906 6.1345 16.1878 6.1345 17.1686C6.1345 18.1494 5.30133 18.9465 4.27623 18.9465ZM4.27623 16.1819C3.70752 16.1819 3.24495 16.6244 3.24495 17.1686C3.24495 17.7127 3.70752 18.1553 4.27623 18.1553C4.84495 18.1553 5.30752 17.7127 5.30752 17.1686C5.30752 16.6244 4.84495 16.1819 4.27623 16.1819Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M2.01549 24C1.7873 24 1.60156 23.8232 1.60156 23.6048V21.5552C1.60156 21.3369 1.78642 21.1592 2.01549 21.1592C2.24457 21.1592 2.42854 21.336 2.42854 21.5552V23.6048C2.42854 23.8232 2.24369 24 2.01549 24Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M6.53893 24C6.31074 24 6.125 23.8232 6.125 23.6048V21.5552C6.125 21.3369 6.30985 21.1592 6.53893 21.1592C6.76801 21.1592 6.95198 21.336 6.95198 21.5552V23.6048C6.95198 23.8232 6.76712 24 6.53893 24Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M15.8637 23.9997C15.6355 23.9997 15.4497 23.8228 15.4497 23.6045V21.3019C15.4497 20.6181 14.8678 20.0613 14.1531 20.0613H9.85017C9.13463 20.0613 8.55354 20.6181 8.55354 21.3019V23.6045C8.55354 23.8228 8.36869 23.9997 8.14049 23.9997C7.9123 23.9997 7.72656 23.8228 7.72656 23.6045V21.3019C7.72656 20.1814 8.67913 19.27 9.85017 19.27H14.1531C15.3241 19.27 16.2767 20.1814 16.2767 21.3019V23.6045C16.2767 23.8228 16.0919 23.9997 15.8637 23.9997Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M12.0028 18.9465C10.9786 18.9465 10.1445 18.1494 10.1445 17.1686C10.1445 16.1878 10.9777 15.3906 12.0028 15.3906C13.0279 15.3906 13.8611 16.1878 13.8611 17.1686C13.8611 18.1494 13.0279 18.9465 12.0028 18.9465ZM12.0028 16.1819C11.4341 16.1819 10.9715 16.6244 10.9715 17.1686C10.9715 17.7127 11.4341 18.1553 12.0028 18.1553C12.5715 18.1553 13.0341 17.7127 13.0341 17.1686C13.0341 16.6244 12.5715 16.1819 12.0028 16.1819Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M9.74206 24C9.51386 24 9.32812 23.8232 9.32812 23.6048V21.5552C9.32812 21.3369 9.51298 21.1592 9.74206 21.1592C9.97113 21.1592 10.1551 21.336 10.1551 21.5552V23.6048C10.1551 23.8232 9.97025 24 9.74206 24Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M14.2646 24C14.0364 24 13.8516 23.8232 13.8516 23.6048V21.5552C13.8516 21.3369 14.0364 21.1592 14.2646 21.1592C14.4928 21.1592 14.6785 21.336 14.6785 21.5552V23.6048C14.6785 23.8232 14.4937 24 14.2646 24Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M23.5854 23.9997C23.3572 23.9997 23.1715 23.8228 23.1715 23.6045V21.3019C23.1715 20.6181 22.5904 20.0613 21.8758 20.0613H17.5728C16.8573 20.0613 16.2762 20.6181 16.2762 21.3019V23.6045C16.2762 23.8228 16.0913 23.9997 15.8631 23.9997C15.635 23.9997 15.4492 23.8228 15.4492 23.6045V21.3019C15.4492 20.1814 16.4018 19.27 17.5728 19.27H21.8758C23.0468 19.27 23.9994 20.1814 23.9994 21.3019V23.6045C23.9994 23.8228 23.8145 23.9997 23.5854 23.9997Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M19.7255 18.9465C18.7012 18.9465 17.8672 18.1494 17.8672 17.1686C17.8672 16.1878 18.7004 15.3906 19.7255 15.3906C20.7506 15.3906 21.5837 16.1878 21.5837 17.1686C21.5837 18.1494 20.7506 18.9465 19.7255 18.9465ZM19.7255 16.1819C19.1567 16.1819 18.6942 16.6244 18.6942 17.1686C18.6942 17.7127 19.1567 18.1553 19.7255 18.1553C20.2942 18.1553 20.7567 17.7127 20.7567 17.1686C20.7567 16.6244 20.2942 16.1819 19.7255 16.1819Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M17.4608 24C17.2326 24 17.0469 23.8232 17.0469 23.6048V21.5552C17.0469 21.3369 17.2317 21.1592 17.4608 21.1592C17.6899 21.1592 17.8739 21.336 17.8739 21.5552V23.6048C17.8739 23.8232 17.689 24 17.4608 24Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M21.9842 24C21.7561 24 21.5703 23.8232 21.5703 23.6048V21.5552C21.5703 21.3369 21.7552 21.1592 21.9842 21.1592C22.2133 21.1592 22.3973 21.336 22.3973 21.5552V23.6048C22.3973 23.8232 22.2124 24 21.9842 24Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
      <path
        d='M9.60579 14.0934C9.39705 14.0934 9.22105 13.9445 9.1954 13.7464L8.46394 8.16632C8.44979 8.05377 8.48605 7.93952 8.56389 7.8549C8.64172 7.77027 8.75582 7.72119 8.87433 7.72119H15.116C15.2345 7.72119 15.3478 7.77027 15.4265 7.8549C15.5052 7.94037 15.5414 8.05377 15.5264 8.16632L14.795 13.7464C14.7684 13.9445 14.5924 14.0934 14.3837 14.0934H9.60579ZM9.97638 13.3419L14.0617 13.3022L14.6534 8.46166L9.3431 8.51243L9.97638 13.3419Z'
        fill={fillColor}
        fill-opacity='0.87'
      />
    </SvgIcon>
  );
};

export default EventIcon;

import {Typography} from '@mui/material';
import {FmdGood} from '@mui/icons-material';
import mySkillsData from 'data/mySkills.json';
import {Accordion, SkillsChip} from 'mui-core';
import {Link} from 'react-router-dom';

const MyProgramSkills = props => {
  const {activePathData, request} = props;
  let activePathSkills = [];
  activePathData &&
    Array.isArray(activePathData) &&
    activePathData.length > 0 &&
    activePathData
      .filter(path => path.completed_stage === 'complete')
      .map(res => {
        return res.path_details?.skills_learned?.map(skill =>
          activePathSkills.push(skill.name),
        );
      });
  const uniqueProgramSkills = [...new Set(activePathSkills)];
  return (
    <Accordion
      defaultOpen
      outlined={true}
      icon={
        <FmdGood
          fontSize='large'
          sx={{
            color: 'white',
            padding: '6px',
            borderRadius: '4px',
            marginRight: '10px',
            backgroundColor: 'primary.main',
          }}
        />
      }
      header={mySkillsData.myProgramSkills.Heading}>
      <Typography variant='body1' color='text.primary' mb={2}>
        Program skills are acquired from accredited education institution
        program. Program skills are located in your Profile under the{' '}
        <Link to='/dashboard' underline='none'>
          Active Program{' '}
        </Link>
        section.{' '}
      </Typography>
      <SkillsChip
        data={{
          data: uniqueProgramSkills,
          request,
        }}
        backgroundColor='primary.main'
        color={'white'}
      />
    </Accordion>
  );
};
export default MyProgramSkills;

import moment from 'moment';
import {useEffect, useState} from 'react';
import {
  getMyOpportunities,
  removeMyOpportunity,
  fetchMyOpportunities,
} from 'redux/modules/occupation';
import {Link} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {Stack, Typography} from '@mui/material';
import {memo, useCallback, useMemo} from 'react';
import {opportunities} from 'data/dashboard.json';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {useDispatch, useSelector} from 'react-redux';
import {numberInUSFormat, capitalizeFirstLetter} from 'core/utils';
import {DataGrid, Accordion, DialogBox, ContentBox, Button} from 'mui-core';
import {
  removeJobModalProps,
  removeSavedJobModalProps,
} from 'app/modules/job-opportunities/subModules/OpportunityDetails/Actions';

const STATUS = {
  save: 'Saved',
  apply: 'Applied',
};

const {myOpportunities} = opportunities;

const defaultConfirmModalProps = {
  title: '',
  okText: '',
  cancelText: '',
  visible: false,
  description: [''],
};

let confirmModalCallback = null;

const MyOpportunities = ({}) => {
  const [loader, setLoader] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(
    defaultConfirmModalProps,
  );
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {data = [], request} = useSelector(getMyOpportunities);

  const fetchOpportunities = () => {
    dispatch(fetchMyOpportunities());
  };

  const fetchJobs = useCallback(expanded => {
    if (expanded) fetchOpportunities();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(() => [
    {
      flex: 0.4,
      sortable: true,
      editable: false,
      filterable: false,
      headerName: 'Title',
      field: 'opportunity_name',
      renderCell: (record = {}) => {
        // http://localhost:3000/opportunities/apprenticeships/CAD%20designer/golden
        // http://localhost:3000/opportunities/jobs?jobId=d80a293e764f&title=Cashier%2520%28Contractor%29&company=Wall%2520and%2uct%2520Company%2520Partner&source=local
        const {institute_name, opportunity_name, opportunity_type} =
          record?.row || {};
        let opportunityPath;
        if (opportunity_type === 'apprenticeship')
          opportunityPath =
            opportunity_name && institute_name && opportunity_type
              ? `/apprenticeships/${encodeURIComponent(
                  opportunity_name,
                )}/${encodeURIComponent(institute_name)}`
              : '';
        if (opportunity_type === 'job')
          opportunityPath =
            opportunity_name && institute_name && opportunity_type
              ? `/jobs?=jobId=${encodeURIComponent(
                  opportunity_name,
                )}&title=${encodeURIComponent(
                  opportunity_name,
                )}&company=${encodeURIComponent(institute_name)}&source=local`
              : '';

        return (
          <Link
            tabIndex={0}
            target='_target'
            to={opportunityPath}
            title={opportunity_name}>
            {opportunity_name}
          </Link>
        );
      },
    },
    {
      flex: 0.3,
      sortable: true,
      editable: false,
      headerName: 'Company',
      field: 'institute_name',
    },
    {
      flex: 0.15,
      sortable: true,
      editable: false,
      headerName: 'Type',
      field: 'opportunity_type',
      valueFormatter: params => {
        return capitalizeFirstLetter(params.value);
      },
    },
    {
      flex: 0.15,
      sortable: true,
      editable: false,
      field: 'action_type',
      headerName: 'Status',
      valueFormatter: params => {
        return capitalizeFirstLetter(STATUS[params.value]);
      },
    },
    {
      flex: 0.15,
      sortable: true,
      editable: false,
      field: 'apply_date',
      headerName: 'Date Added',
      valueFormatter: params => {
        return moment(params.value).format('MM/DD/YYYY');
      },
    },
    {
      headerName: 'Action',
      flex: 0.15,
      field: 'opp_application_uuid',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: params => {
        const {opportunity_type, action_type} = params.row;
        return (
          <IconButton
            sx={{padding: 0}}
            disabled={request || loader}
            aria-label='delete'
            onClick={() =>
              onRemoveOpportunityClick(
                params.value,
                opportunity_type,
                action_type,
              )
            }>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ]);

  const onConfirmModalOk = () => {
    setConfirmModalProps(defaultConfirmModalProps);
    if (confirmModalCallback) confirmModalCallback();
    confirmModalCallback = null;
  };

  const onConfirmModalCancel = () => {
    setConfirmModalProps(defaultConfirmModalProps);
  };

  const openConfirmModal = (modalProps, callback) => {
    setConfirmModalProps({...modalProps, visible: true});
    confirmModalCallback = callback;
  };

  const onRemoveOpportunityClick = async (
    opportunityId,
    opportunityType,
    actionType,
  ) => {
    const confirmProps =
      actionType === 'save'
        ? removeSavedJobModalProps({job_type: opportunityType})
        : removeJobModalProps({job_type: opportunityType});
    const callback = async () => {
      const label =
        opportunityType === 'other' ? 'other opportunity' : opportunityType;
      try {
        setLoader(true);
        dispatch(
          removeMyOpportunity(opportunityId, data => {
            if (data.Success) {
              enqueueSnackbar(
                `${capitalizeFirstLetter(label)} was successfully removed.`,
                {
                  variant: 'success',
                  anchorOrigin: {horizontal: 'center', vertical: 'top'},
                },
              );
              setLoader(false);
              fetchOpportunities();
            }
          }),
        );
      } catch (error) {
        setLoader(false);
      }
    };
    openConfirmModal(confirmProps, callback);
  };

  return (
    <>
      <Accordion
        outlined={false}
        onChange={fetchJobs}
        id='my_opportunities'
        header={myOpportunities.header}
        description={myOpportunities.description}>
        <ContentBox variant='outlined'>
          <Typography m={2} variant='body1' color='text.primary'>
            {`${numberInUSFormat(data?.length || 0)} Result${
              data?.length > 1 ? 's' : ''
            }`}
          </Typography>
          <DataGrid
            pageSize={10}
            columns={columns}
            pagination={true}
            hideFooter={false}
            sx={{border: 'none'}}
            paginationMode='client'
            rows={data?.length ? data : []}
            loading={request || loader}
            rowsPerPageOptions={[5, 10, 20]}
            totalRowCount={data?.length || 0}
            getRowId={params => params.opp_application_uuid}
          />
        </ContentBox>
      </Accordion>
      {confirmModalProps?.visible && (
        <DialogBox
          showIcon={confirmModalProps.icon}
          closeDialog={onConfirmModalCancel}
          openDialog={confirmModalProps.visible}
          actions={
            <>
              <Button
                variant='outlined'
                onClick={onConfirmModalCancel}
                sx={{minWidth: {xs: '80px', md: '168px'}}}>
                {confirmModalProps.cancelText}
              </Button>
              <Button
                variant='contained'
                onClick={onConfirmModalOk}
                sx={{minWidth: {xs: '80px', md: '168px'}}}>
                {confirmModalProps.okText}
              </Button>
            </>
          }>
          <Stack spacing={1}>
            <Typography textAlign='center' variant='h4' color='text.primary'>
              {confirmModalProps.title}
            </Typography>
            <Typography variant='body1' textAlign='center' color='text.primary'>
              {confirmModalProps.description}
            </Typography>
          </Stack>
        </DialogBox>
      )}
    </>
  );
};
export default memo(MyOpportunities);

import {isArray} from 'lodash';
import * as TYPES from './constants';
import {API} from 'config';

import {
  requestOccupationRoles,
  recieveOccupationRoles,
  requestOccupationRolesError,
  requestSaveRateOcuupation,
  recieveSaveRateOcuupation,
  requestSaveRateOcuupationError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, requestSave: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OCCUPATION_ROLE_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.OCCUPATION_RATE_SAVE_REQUEST:
      return {
        ...state,
        requestSave: true,
      };
    case TYPES.OCCUPATION_ROLE_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.OCCUPATION_ROLE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.OCCUPATION_RATE_SAVE_ERROR:
    case TYPES.OCCUPATION_RATE_SAVE_SUCCESS:
      return {
        ...state,
        requestSave: false,
      };
    default:
      return state;
  }
};

export const fetchOccupationRoles = onnet => (dispatch, getState) => {
  try {
    if (onnet) {
      dispatch(requestOccupationRoles());
      let endPoint = `${API.srm.occupation_roles}?onnet=${onnet}`;
      return services.fetchRecords(endPoint, null, true).then(res => {
        if (res && isArray(res)) {
          dispatch(recieveOccupationRoles(res));
          return;
        }
        dispatch(requestOccupationRolesError(res));
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestOccupationRolesError(err));
  }
};

export const saveOccupationRates = (data, callback) => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestSaveRateOcuupation());
  try {
    let endPoint = `${API.srm.student_survey}`;
    return services
      .createUpdateRecord(token, endPoint, data, 'POST')
      .then(res => {
        if (res) {
          dispatch(recieveSaveRateOcuupation(res));
          callback(res);
          return;
        }
        dispatch(requestSaveRateOcuupationError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestSaveRateOcuupationError(err));
  }
};

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestJobApplied,
  recieveJobJobApplied,
  requestJobAppliedError,
  requestJobApply,
  requestJobApplyError,
  recieveJobJobApply,
  clearJobApplied,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.JOB_APPLIED_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.JOB_APPLIED_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLEAR_APPLIED_DETAILS:
      return {
        request: false,
        error: false,
        data: null,
      };
    case TYPES.JOB_APPLIED_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.JOB_APPLY_REQUEST:
      return {
        applyRequest: true,
        applyErr: false,
      };
    case TYPES.JOB_APPLY_SUCCESS:
      return {
        applyRequest: false,
        error: false,
      };
    case TYPES.JOB_APPLY_ERROR:
      return {
        applyRequest: false,
        applyErr: true,
      };
    default:
      return state;
  }
};

export const fetchAppliedJobs = callback => (dispatch, getState) => {
  dispatch(requestJobApplied());
  const {
    auth: {auth: {token = null, user = {}} = {}},
  } = getState();
  try {
    let endPoint = `${API.srm.applied_jobs}apply/?action_type=apply`;
    return services.fetchRecords(endPoint, token, true).then(res => {
      if (res) {
        if (callback) callback(res);
        dispatch(recieveJobJobApplied(res));
        return;
      }
      dispatch(requestJobAppliedError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestJobAppliedError(err));
  }
};

export const applyJob = (job_uuid, callback) => (dispatch, getState) => {
  dispatch(requestJobApply());
  const {
    auth: {auth: {token = null, user = {}} = {}},
  } = getState();
  try {
    let endPoint = `${API.srm.applied_jobs}apply`;
    const data = {
      action_type: 'apply',
      jobs_uuid: job_uuid,
    };
    return services
      .createUpdateRecord(token, endPoint, data, 'POST')
      .then(res => {
        if (res) {
          dispatch(recieveJobJobApply(res));
          if (callback) {
            if (res.business_partner_job_application_uuid) {
              callback(false);
            } else {
              callback(true);
            }
          }

          return;
        }
        dispatch(requestJobApplyError(res));
      })
      .catch(err => {
        console.log(err);
        dispatch(requestJobApplyError(err));
      });
  } catch (err) {}
};

export const resetAppliedJobs = () => dispatch => {
  dispatch(clearJobApplied());
};

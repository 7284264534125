//States
export const REQUEST_STATES = 'REQUEST_STATES';
export const RECIEVE_STATES = 'RECIEVE_STATES';
export const REQUEST_STATES_ERROR = 'REQUEST_STATES_ERROR';

//Flash Message
export const FLASH_MESSAGE = 'FLASH_MESSAGE';
export const RESET_FLASH_MESSAGE = 'RESET_FLASH_MESSAGE';

//App Config
export const RESET_CONFIG = 'RESET_CONFIG';
export const CHANGE_LANG = 'CHANGE_LANG';

//user input
export const GLOBAL_SEARCH_STORAGE_NAME = '_GLOBAL_SEARCH_';
export const GET_GLOBAL_SEARCH_ITEMS = 'GET_GLOBAL_SEARCH_ITEMS';
export const ADD_GLOBAL_SEARCH_ITEM = 'ADD_GLOBAL_SEARCH_ITEM';
export const DELETE_GLOBAL_SEARCH_ITEM = 'DELETE_GLOBAL_SEARCH_ITEM';

// theme
export const CHANGE_APP_THEME_MODE = 'CHANGE_APP_THEME_MODE';

// user history
export const INPUT_HISTORY_STORAGE_NAME = 'INPUT_HISTORY_STORAGE_NAME';
export const GET_INPUT_HISTORY = 'GET_INPUT_HISTORY';

import React, {useState} from 'react';
import {useUser} from 'core/hooks';
import PropTypes from 'prop-types';
import {useSnackbar} from 'notistack';
import {useDispatch, useSelector} from 'react-redux';
// redux
import {
  getOccupationRoles,
  saveFitAssessmentSurvey,
} from 'redux/modules/occupation';
// components
import {
  Box,
  Stack,
  Table,
  Paper,
  Radio,
  Dialog,
  Divider,
  Tooltip,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  RadioGroup,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  TableContainer,
  LinearProgress,
  tooltipClasses,
  FormControlLabel,
  linearProgressClasses,
} from '@mui/material';
import {Close} from '@mui/icons-material';
import {Button, ErrorBoundary} from 'mui-core';
import {styled, useTheme} from '@mui/material/styles';
// utils
import {rateMyInterestData} from 'data/occupation.json';

const getScoreTitle = score => {
  if (score <= 1.5) return rateMyInterestData.notInterestedTxt;
  if (score > 1.5 && score <= 2.5)
    return rateMyInterestData.slightlyInterestedTxt;
  if (score > 2.5 && score <= 3.5)
    return rateMyInterestData.moderatelyInterestedTxt;
  if (score > 3.5 && score <= 4.5) return rateMyInterestData.veryInterestedTxt;
  if (score > 4.5) return rateMyInterestData.extremelyInterestedTxt;
};

const StyledMarketToolTip = styled(({className, ...props}) => (
  <Tooltip arrow placement='top' {...props} classes={{popper: className}} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'white',
    padding: '6px, 12px, 6px, 12px',
    background: `linear-gradient(to bottom, #804FF3, #601AAD)`,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: `#601AAD`,
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({}) => ({
  height: 14,
  borderRadius: 50,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'gba(123, 96, 223, 0.12)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 50,
    background: `linear-gradient(to right, #357AF4, #B026FE)`,
  },
}));

const FitAssessment = ({
  tasks = [],
  career_name = '',
  cip_code = '',
  openAssessmentDialog = false,
  setOpenAssessmentDialog,
  cip4_image_cloudinary = '',
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {data: profileData} = useUser();
  const {enqueueSnackbar} = useSnackbar();
  const [values, setValues] = useState({});
  const [hover, setHover] = useState(false);
  const [avrRate, setAvgRate] = useState(null);
  const careerName = decodeURIComponent(career_name);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const occupationRoles = useSelector(getOccupationRoles);

  const careerRoles = {
    ...occupationRoles,
    data: tasks.map((t, i) => {
      return {id: i, role: t};
    }),
  };
  const {data: careerRolesData = [], requestSave = false, error = false} =
    careerRoles || {};
  const hasCareerRoles =
    careerRolesData &&
    Array.isArray(careerRolesData) &&
    careerRolesData?.length > 0
      ? true
      : false;

  const handleRadioChange = (ev, rowId) => {
    const currValue = parseInt(ev.target.value);
    const updatedValues = {...values};
    updatedValues[rowId] = currValue;
    setValues(updatedValues);
    setAverageCareerScore(updatedValues);
  };

  const setAverageCareerScore = selectedRoles => {
    console.log('occupationDetails_score_', selectedRoles);
    let roles = Object.keys(selectedRoles) || [];
    if (hasCareerRoles && roles && roles.length > 0) {
      roles = roles.filter(
        i => selectedRoles[i] !== undefined && selectedRoles[i] !== null,
      );
      if (roles.length === careerRolesData.length) {
        let total = 0;
        roles.forEach(role => {
          total += selectedRoles[role];
        });
        const avg = ((total * 5) / (careerRolesData.length * 5)).toFixed(1);
        setAvgRate(avg);
      } else {
        setAvgRate(null);
      }
    } else {
      setAvgRate(null);
    }
  };

  const onCancel = () => {
    setOpenAssessmentDialog(false);
    setAvgRate(null);
  };
  const onSaveCareerInterest = (score, callback) => {
    if (score && cip_code && careerName && profileData?.student_uuid) {
      setIsSubmitting(true);
      const data = {
        cip_code,
        cip4_image_cloudinary,
        score: parseFloat(score),
        career_name: careerName,
        student_uuid: profileData.student_uuid,
      };
      dispatch(
        saveFitAssessmentSurvey(data, res => {
          setIsSubmitting(false);
          if (res?.Success) {
            enqueueSnackbar(res.Success, {
              variant: 'success',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          }
          callback && callback(res);
          setAvgRate(null);
          setOpenAssessmentDialog(false);
        }),
      );
    }
  };

  return (
    <Dialog
      open={openAssessmentDialog}
      fullWidth={true}
      maxWidth={hasCareerRoles ? 'lg' : 'md'}
      sx={{borderRadius: '24px'}}
      aria-labelledby='rate-my-interest-dialog'>
      <DialogTitle sx={{m: 0, p: 4, pb: 0, background: '#111'}}>
        <Stack>
          <Stack spacing={1} direction='row' sx={{display: 'inline'}}>
            <Typography
              variant='h4'
              color='white'
              sx={{display: 'inline', whiteSpace: 'break-spaces'}}>
              {rateMyInterestData.title}
            </Typography>
          </Stack>
          {onCancel ? (
            <IconButton
              aria-label='close'
              onClick={onCancel}
              sx={{
                top: 32,
                right: 32,
                color: 'white',
                position: 'absolute',
              }}>
              <Close />
            </IconButton>
          ) : null}
        </Stack>
        <Divider sx={{my: 2}} />
      </DialogTitle>
      <Stack spacing={1} p={3} sx={{background: '#1111'}}>
        <Typography
          variant='h4'
          sx={{display: 'inline', whiteSpace: 'break-spaces'}}
          fontWeight='bold'>
          {careerName} {rateMyInterestData.rolesResponsibilitiesTxt}
        </Typography>
        <Typography
          variant='subtitle1'
          color='text.primary'
          sx={{display: 'inline', whiteSpace: 'break-spaces'}}>
          {`${rateMyInterestData.decriptionPre} ${careerName} ${rateMyInterestData.descriptionPost}`}
        </Typography>
      </Stack>
      <DialogContent display='flex' alignItems='center'>
        <ErrorBoundary nameOfComponent='career-rate-my-interest'>
          {!requestSave && !error && hasCareerRoles && !isSubmitting ? (
            <TableContainer component={Paper} sx={{maxHeight: 480}}>
              <Table stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor:
                          theme.palette.mode === 'dark'
                            ? theme.palette.background.paper
                            : theme.palette.purplebg8perc.main,
                      }}>
                      <Typography variant='h6' color='text.primary'>
                        {rateMyInterestData.taskTxt}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor:
                          theme.palette.mode === 'dark'
                            ? theme.palette.background.paper
                            : theme.palette.purplebg8perc.main,
                      }}>
                      <Stack
                        spacing={2}
                        direction='row'
                        alignItems='center'
                        divider={<Divider orientation='vertical' flexItem />}>
                        <>
                          <Divider orientation='vertical' flexItem />
                          <Typography
                            variant='h6'
                            textAlign='center'
                            sx={{width: '20%'}}
                            color='text.primary'>
                            {rateMyInterestData.notInterestedTxt}
                          </Typography>
                        </>
                        <Typography
                          variant='h6'
                          textAlign='center'
                          sx={{width: '20%'}}
                          color='text.primary'>
                          {rateMyInterestData.slightlyInterestedTxt}
                        </Typography>
                        <Typography
                          variant='h6'
                          textAlign='center'
                          sx={{width: '20%'}}
                          color='text.primary'>
                          {rateMyInterestData.moderatelyInterestedTxt}
                        </Typography>
                        <Typography
                          variant='h6'
                          textAlign='center'
                          sx={{width: '20%'}}
                          color='text.primary'>
                          {rateMyInterestData.veryInterestedTxt}
                        </Typography>
                        <Typography
                          variant='h6'
                          textAlign='center'
                          sx={{width: '20%'}}
                          color='text.primary'>
                          {rateMyInterestData.extremelyInterestedTxt}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hasCareerRoles &&
                    careerRolesData.map((row, rowIndex) => (
                      <TableRow key={row.id || row.role}>
                        <TableCell width='40%'>
                          <Typography variant='subtitle1' color='text.primary'>
                            {row.role}
                          </Typography>
                        </TableCell>
                        <TableCell width='60%'>
                          <RadioGroup
                            row
                            onChange={e => handleRadioChange(e, rowIndex)}>
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              sx={{
                                m: 0,
                                width: '20%',
                                justifyContent: 'center',
                              }}
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              sx={{
                                m: 0,
                                width: '20%',
                                justifyContent: 'center',
                              }}
                            />
                            <FormControlLabel
                              value={3}
                              control={<Radio />}
                              sx={{
                                m: 0,
                                width: '20%',
                                justifyContent: 'center',
                              }}
                            />
                            <FormControlLabel
                              value={4}
                              control={<Radio />}
                              sx={{
                                m: 0,
                                width: '20%',
                                justifyContent: 'center',
                              }}
                            />
                            <FormControlLabel
                              value={5}
                              control={<Radio />}
                              sx={{
                                m: 0,
                                width: '20%',
                                justifyContent: 'center',
                              }}
                            />
                          </RadioGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              height={200}
              display='flex'
              alignItems='center'
              justifyContent='center'>
              <Typography variant='h3' color='text.primary'>
                {isSubmitting
                  ? 'Please wait, while we are submitting your survey...'
                  : error
                  ? error
                  : 'No Survey found for this career.'}
              </Typography>
            </Box>
          )}
        </ErrorBoundary>
      </DialogContent>
      {hasCareerRoles && (
        <Stack
          width='100%'
          spacing={2}
          alignItems='center'
          sx={{px: 4, pb: 4}}
          direction={{xs: 'column', sm: 'row'}}
          justifyContent={avrRate ? 'space-between' : 'flex-end'}>
          {avrRate && (
            <Stack spacing={2}>
              <Stack spacing={1} alignItems='center' direction='row'>
                <Typography variant='h4' color='text.primary'>
                  {rateMyInterestData.interestScoreTxt}:
                </Typography>
                <Typography
                  variant='h4'
                  textAlign='left'
                  color={theme.palette.primary.main}>
                  {avrRate}
                </Typography>
              </Stack>
              <Stack spacing={2} direction={{xs: 'column', md: 'row'}}>
                <Typography
                  mt={-0.5}
                  variant='h4'
                  textAlign='left'
                  color={theme.palette.primary.main}>
                  {getScoreTitle(avrRate)}
                </Typography>
                <Box minWidth={200} maxWidth={300} sx={{mt: '2px !important'}}>
                  <Box
                    width='100%'
                    position='relative'
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
                    {hover && (
                      <StyledMarketToolTip title={avrRate} open={true}>
                        <Box
                          width={2}
                          height={1}
                          bottom='100%'
                          position='relative'
                          left={`${avrRate * 10 * 2}%`}
                          className='tooltip-progress'
                        />
                      </StyledMarketToolTip>
                    )}
                    <BorderLinearProgress
                      variant='determinate'
                      value={avrRate * 10 * 2}
                    />
                  </Box>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'>
                    <Typography
                      variant='body1'
                      textAlign='left'
                      color='text.primary'>
                      {1}
                    </Typography>
                    <Typography
                      variant='body1'
                      textAlign='right'
                      color='text.primary'>
                      {5}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          )}
          <Stack spacing={2} direction='row'>
            {!requestSave && (
              <Button
                sx={{px: 3}}
                color='midGray'
                onClick={onCancel}
                variant='contained'>
                Cancel
              </Button>
            )}
            <Button
              sx={{px: 4}}
              color='primary'
              variant='contained'
              loading={requestSave}
              disabled={requestSave || isSubmitting}
              onClick={() => onSaveCareerInterest(avrRate)}>
              {rateMyInterestData.saveBtnTxt}
            </Button>
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
};

FitAssessment.propTypes = {
  tasks: PropTypes.array,
  cip_code: PropTypes.string,
  career_name: PropTypes.string,
  openAssessmentDialog: PropTypes.bool,
  setOpenAssessmentDialog: PropTypes.func,
  cip4_image_cloudinary: PropTypes.string,
};

export default FitAssessment;

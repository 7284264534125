import {Stack, Typography} from '@mui/material';
import {Stats, ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {
  pink,
  lime,
  purple,
  orange,
  lightBlue,
  lightGreen,
} from '@mui/material/colors';

const DashboardStats = ({studentMetrics}) => {
  const {
    data: {
      events = 0,
      programs = 0,
      saved_jobs = 0,
      job_matches = 0,
      applied_jobs = 0,
      skills_metrics: {total_skills_count = 0} = {},
    } = {},
  } = studentMetrics || {};

  const prepareStatsData = () => {
    const statsData = [
      {
        title: 'Job Matches',
        count: job_matches === 10000 ? '10,000+' : job_matches,
        color: pink[600],
      },
      {
        title: 'Total Skills',
        count: total_skills_count,
        color: lightBlue[600],
      },
      {
        title: 'Applied Jobs',
        count: applied_jobs,
        color: lime[600],
      },
      {
        title: 'Saved Jobs',
        count: saved_jobs,
        color: purple[600],
      },
      {
        title: 'Active Programs',
        count: programs,
        color: lightGreen[600],
      },
      {
        title: 'Events',
        count: events,
        color: orange[600],
      },
    ];
    return statsData;
  };

  const statsData = prepareStatsData();

  return (
    <Stack spacing={3} p={3}>
      <Typography variant='h5' color='text.primary'>
        My Dashboard
      </Typography>
      <ErrorBoundary nameOfComponent='mod-comp-dashboard-stats'>
        <RequestErrorLoader hideEmpty fullWidth body={studentMetrics}>
          <Stats data={statsData} />
        </RequestErrorLoader>
      </ErrorBoundary>
    </Stack>
  );
};
export default DashboardStats;

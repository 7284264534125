import * as TYPES from './constants';
import {API} from 'config';

import {
  requestCareerAreaList,
  receiveCareerAreaList,
  receiveAllCareerAreaList,
  requestCareerAreaListError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CAREER_AREA_LIST_REQUEST:
      return {
        ...state,
        error: false,
        request: true,
      };
    case TYPES.CAREER_AREA_LIST_SUCCESS: {
      const dataSet = action.payload;
      let filterData = [];
      if (dataSet && Array.isArray(dataSet) && dataSet.length > 0) {
        filterData = dataSet.filter(cluster => cluster?.active_path_count > 0);
      }
      return {
        ...state,
        error: false,
        request: false,
        data: filterData,
      };
    }
    case TYPES.CAREER_AREA_ALL_LIST_SUCCESS: {
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    }
    case TYPES.CLEAR_PROGRAM_DETAIL:
      return {
        error: false,
        request: false,
      };
    case TYPES.CAREER_AREA_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_CAREER_AREA_LIST:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export const fetchCareerAreaList = (all = false, callback) => (
  dispatch,
  getState,
) => {
  dispatch(requestCareerAreaList());
  try {
    let endPoint = `${API.srm.programs_by_career}`;
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        if (callback) callback(res);
        if (all) {
          dispatch(receiveAllCareerAreaList(res));
        } else {
          dispatch(receiveCareerAreaList(res));
        }
        return;
      }
      dispatch(requestCareerAreaListError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestCareerAreaListError(err));
  }
};

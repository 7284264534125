import * as TYPES from './constants';
import {API} from 'config';

import {
  requestJobSaved,
  recieveJobSaved,
  requestJobSavedError,
  recieveJobSave,
  requestJobSaveError,
  requestJobSave,
  clearJobSaved,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, saveRequest: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.JOB_SAVED_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.JOB_SAVED_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.JOB_SAVED_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.JOB_SAVE_REQUEST:
      return {
        saveRequest: true,
        saveError: false,
      };
    case TYPES.JOB_SAVE_SUCCESS:
      return {
        saveRequest: false,
        saveError: false,
      };
    case TYPES.JOB_SAVE_ERROR:
      return {
        saveRequest: false,
        saveError: true,
      };
    case TYPES.CLEAR_JOB_SAVED:
      return {
        data: null,
      };
    default:
      return state;
  }
};

export const fetchSavedJobs = callback => (dispatch, getState) => {
  dispatch(requestJobSaved());
  const {
    auth: {auth: {token = null, user = {}} = {}},
  } = getState();
  try {
    let endPoint = `${API.srm.applied_jobs}apply/?action_type=save`;
    return services.fetchRecords(endPoint, token, true).then(res => {
      if (res) {
        if (callback) callback(res);
        dispatch(recieveJobSaved(res));
        return;
      }
      dispatch(requestJobSavedError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestJobSavedError(err));
  }
};

export const saveJob = (job_uuid, callback) => (dispatch, getState) => {
  dispatch(requestJobSave());
  const {
    auth: {auth: {token = null, user = {}} = {}},
  } = getState();
  try {
    let endPoint = `${API.srm.applied_jobs}apply`;
    const data = {
      action_type: 'save',
      jobs_uuid: job_uuid,
    };
    return services
      .createUpdateRecord(token, endPoint, data, 'POST')
      .then(res => {
        if (res) {
          dispatch(recieveJobSave(res));
          if (callback) {
            if (res.business_partner_job_application_uuid) {
              callback(false);
            } else {
              callback(res.error ? res.error : 'Error in saving the job');
            }
          }

          return;
        }
        dispatch(requestJobSaveError(res));
      })
      .catch(err => {
        dispatch(requestJobSaveError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestJobSaveError(err));
  }
};

export const unsaveJob = (job_uuid, callback) => (dispatch, getState) => {
  dispatch(requestJobSave());
  const {
    auth: {auth: {token = null, user = {}} = {}},
  } = getState();
  try {
    let endPoint = `${API.srm.applied_jobs}apply/${job_uuid}`;
    return services
      .createUpdateRecord(token, endPoint, {}, 'DELETE')
      .then(res => {
        if (res) {
          dispatch(recieveJobSave(res));
          if (callback) {
            if (res.business_partner_job_application_uuid) {
              callback(false);
            } else {
              callback(res.error ? res.error : 'Error in unsaving the job');
            }
          }

          return;
        }
        dispatch(requestJobSaveError(res));
      })
      .catch(err => {
        dispatch(requestJobSaveError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestJobSaveError(err));
  }
};

export const resetSavedJobs = () => dispatch => {
  dispatch(clearJobSaved());
};

import React, {useState} from 'react';
import {AutoComplete, Input, Select, Tag} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import _ from 'lodash';

import {ErrorBoundary} from 'core/components';

const {Option} = Select;

const SkillsSearch = ({
  skills: skillsData,
  selectedSkills,
  onSelect,
  onRemove,
}) => {
  const {data: skills = []} = skillsData || {};
  const [inputVal, setInputVal] = useState('');
  const [filteredSkills, setFilteredSkills] = useState([]);
  const checkSelected = d => {
    if (
      selectedSkills.filter(i => i.toLowerCase() === d.name.toLowerCase())
        .length > 0
    )
      return true;
    return false;
  };
  const options =
    filteredSkills &&
    filteredSkills.length &&
    filteredSkills.length > 0 &&
    filteredSkills.map(d => (
      <Option
        key={d.name}
        text={d.name}
        value={JSON.stringify(d)}
        disabled={checkSelected(d)}
        className={checkSelected(d) ? 'selected-opt' : ''}>
        {d.name}
      </Option>
    ));
  const onSearch = keyword => {
    setInputVal(keyword);
    if (keyword === '') {
      setFilteredSkills([]);
      return;
    }
    if (skills && skills.length > 0) {
      const filteredSkills = skills.filter(skill => {
        if (skill.name.toLowerCase().includes(keyword.toLowerCase())) {
          return skill;
        }
      });
      setFilteredSkills(_.uniqBy(filteredSkills.slice(0, 21), 'name'));
    }
  };

  return (
    <ErrorBoundary nameOfComponent='core-skills-search'>
      {selectedSkills &&
        _.isArray(selectedSkills) &&
        !_.isEmpty(selectedSkills) &&
        selectedSkills.map((d, idx) => (
          <Tag
            closable
            visible={true}
            key={idx}
            className={'tag-list-item'}
            color='#E6E7FF'
            onClose={() => onRemove(idx)}>
            {d}
          </Tag>
        ))}
      <br />
      <br />
      <AutoComplete
        dropdownClassName='certain-category-search-dropdown'
        dropdownMatchSelectWidth={500}
        className='field-input'
        value={inputVal}
        onSearch={onSearch}
        onSelect={onSelect}
        dataSource={options?.length > 0 ? options : []}
        style={{width: 250}}>
        <Input
          iconrender={<SearchOutlined />}
          size='large'
          placeholder='Search Skills'
        />
      </AutoComplete>
    </ErrorBoundary>
  );
};

export default SkillsSearch;

import * as TYPES from './constants';

export const requestUpdate = () => ({
  type: TYPES.UPDATE_RESUME_DATA_REQUEST,
});
export const recieveUpdate = json => ({
  type: TYPES.RECIEVED_UPDATE_RESUME_DATA_SUCCESS,
  payload: json,
});
export const requestUpdateError = err => ({
  type: TYPES.UPDATE_RESUME_DATA_ERROR,
  err,
});

export const initiateResumeAction = json => ({
  type: TYPES.INITIATE_RESUME_DATA,
  payload: json,
});

export const requestResume = () => ({
  type: TYPES.RESUME_DETAILS_REQUEST,
});

export const requestResumeError = err => ({
  type: TYPES.RESUME_DETAILS_ERROR,
  err,
});

export const requestCareerCraft = () => ({
  type: TYPES.CAREER_CRAFT_REQUEST,
});

export const recieveCareerCraft = json => ({
  type: TYPES.CAREER_CRAFT_SUCCESS,
  payload: json,
});

export const saveRequestedParams = json => ({
  type: TYPES.CAREER_CRAFT_PARAMS,
  payload: json,
});

export const requestCareerCraftError = err => ({
  type: TYPES.CAREER_CRAFT_ERROR,
  err,
});

export const clearCareerCraft = () => ({
  type: TYPES.CAREER_CRAFT_CLEAR,
});

// linkedIn Details
export const requestLinkedInDetails = json => ({
  type: TYPES.LINKEDIN_DETAILS_REQUEST,
  payload: json,
});

export const recieveLinkedInDetails = json => ({
  type: TYPES.LINKEDIN_DETAILS_RECEIVE,
  payload: json,
});

export const linkedInDetailsError = err => ({
  type: TYPES.LINKEDIN_DETAILS_ERROR,
  err,
});

export const clearLinkedInDetails = () => ({
  type: TYPES.LINKEDIN_DETAILS_CLEAR,
});

export const resetResumeData = () => ({
  type: TYPES.RESET_RESUME_DATA,
});

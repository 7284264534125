import {Fragment, useState, useCallback, useEffect} from 'react';
import {Row, Col, Checkbox, Switch, Select} from 'antd';
import {calculateCourseUnits} from 'core/utils';
import {ErrorBoundary} from 'core/components';

import {iconarrowPointer, icontrophy} from 'assets/images';
import './style.less';

const {Option} = Select;

const SelectOptionDisplay = ({title, collegeName}) => (
  <>
    <div className='program-title'>{title}</div>
    <div className='program-college'>{collegeName}</div>
  </>
);

const CourseMap = ({
  myPlanData,
  showFilters,
  onClick,
  allPrograms,
  onChange,
}) => {
  const [typesOfCourses, setTypesOfCourses] = useState([]);
  const [showLabels, setShowLabels] = useState([]);
  const [displayTerms, setDisplayTerms] = useState([]);
  const [mapView, setMapView] = useState(false);
  const [courseDropDownValue, setCourseDropDownValue] = useState({
    title: allPrograms?.[0]?.path_details?.title,
    collegeName: allPrograms?.[0]?.path_details?.institute_details?.name,
  });

  useEffect(() => {
    if (
      myPlanData.data &&
      Array.isArray(myPlanData.data.terms) &&
      myPlanData.data.terms.length
    ) {
      setDisplayTerms([...myPlanData.data.terms]);
      const labels = [];
      myPlanData.data.terms.map(term => {
        if (term.courses && term.courses.length) {
          term.courses.map(course => {
            if (
              course.course_type &&
              !labels.includes(course.course_type.toLowerCase())
            ) {
              labels.push(course.course_type.toLowerCase());
            }
          });
        }
      });
      const types = labels.map(label => {
        const Label = label.toLowerCase();
        const obj = {
          type: Label,
          label: Label === 'general' ? 'General Ed' : Label,
          className: `chk-${Label}`,
        };
        return obj;
      });
      setTypesOfCourses([...types]);
      setShowLabels([...labels]);
    } else {
      setDisplayTerms([]);
      setShowLabels([]);
      setTypesOfCourses([]);
    }
    setCourseDropDownValue({
      title: myPlanData?.data?.title,
      collegeName: myPlanData?.data?.institute_details?.name,
    });
    return () => {};
  }, [myPlanData.data]);

  const filterTermCourses = useCallback(
    labels => {
      if (
        myPlanData.data &&
        myPlanData.data.terms &&
        Array.isArray(myPlanData.data.terms)
      ) {
        const filteredTermCourses = [];
        const terms = Object.assign([], myPlanData.data.terms);
        terms &&
          terms.forEach(term => {
            const filteredCourses = [];
            term &&
              Array.isArray(term.courses) &&
              term.courses.forEach(course => {
                if (course.course_type && course.course_type.toLowerCase()) {
                  if (
                    labels &&
                    labels.indexOf(course.course_type.toLowerCase()) !== -1
                  )
                    filteredCourses.push(course);
                }
                if (course.course_type === null) {
                  filteredCourses.push(course);
                }
              });
            filteredTermCourses.push({
              ...term,
              courses: filteredCourses,
            });
          });
        setDisplayTerms(filteredTermCourses);
      }
    },
    [myPlanData.data],
  );

  const onLabelChange = useCallback(
    (filter, event) => {
      const value = event.target.checked;
      let newLabels = showLabels;
      newLabels = value
        ? [...showLabels, filter]
        : showLabels.filter(val => val !== filter);
      setShowLabels(newLabels);
      filterTermCourses(newLabels);
    },
    [filterTermCourses, showLabels],
  );

  return (
    <ErrorBoundary nameOfComponent='core-course-map'>
      <div className='course-map-view'>
        <Row className='py-3'>
          {allPrograms && Array.isArray(allPrograms) && (
            <Col xs={24} sm={24} md={12} lg={12} className='pl-4'>
              <div className='select-program-dropdown'>
                <span>Select Program</span>
                <Select
                  dropdownClassName='program-dropdown-options'
                  defaultValue={
                    <SelectOptionDisplay
                      title={courseDropDownValue?.title}
                      collegeName={courseDropDownValue?.collegeName}
                    />
                  }
                  value={
                    <SelectOptionDisplay
                      title={courseDropDownValue?.title}
                      collegeName={courseDropDownValue?.collegeName}
                    />
                  }
                  onChange={onChange}>
                  {allPrograms.map((program, ind) => {
                    const {
                      path_uuid,
                      path_details: {title, institute_details},
                    } = program;
                    return (
                      <Option value={path_uuid} key={ind}>
                        <SelectOptionDisplay
                          title={title}
                          collegeName={institute_details.name}
                        />
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
          )}
          {showFilters && (
            <Col xs={24} sm={24} md={12} lg={12}>
              <ul className='m-0 p-0 list-showCategory'>
                <li className='font-family-bold'>Show :</li>
                {typesOfCourses.map(courseType => {
                  return (
                    <li key={courseType.type}>
                      <Checkbox
                        defaultChecked={true}
                        disabled={
                          showLabels.indexOf(courseType.type) !== -1 &&
                          showLabels.length === 1
                        }
                        className={courseType.className}
                        onChange={event =>
                          onLabelChange(courseType.type, event)
                        }>
                        {courseType.label}
                      </Checkbox>
                    </li>
                  );
                })}
              </ul>
            </Col>
          )}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            className='text-right switchButton pr-4'>
            <span className={!mapView ? 'active' : ''}> List View </span>
            <Switch
              checked={mapView}
              onChange={checked => setMapView(checked)}
            />
            <span className={mapView ? 'active' : ''}> Map View </span>
          </Col>
        </Row>
      </div>
      {mapView ? (
        <div className='mapView-section py-3'>
          {displayTerms &&
            Array.isArray(displayTerms) &&
            displayTerms.length > 0 &&
            displayTerms.map((term, sIdx) => {
              return term.courses &&
                Array.isArray(term.courses) &&
                term.courses.length > 0 ? (
                <Fragment key={`segment-${sIdx}`}>
                  <div className='termsection_mapView mb-2'>
                    <ul className='px-4 pt-4 mb-0'>
                      <span>
                        Term {sIdx + 1} - {term.term_name} (
                        {calculateCourseUnits(term.courses)} Credits/Units)
                      </span>
                      {term.courses.map((course, cIdx) => {
                        const courseTypeClass =
                          course?.course_type?.toLowerCase() || 'none';
                        return (
                          <li
                            key={`course-${sIdx}-${cIdx}`}
                            className={courseTypeClass}
                            onClick={() => (onClick ? onClick(course) : {})}>
                            <div className={`flag ${courseTypeClass}`}>
                              {course.course_type &&
                              course.course_type.toLowerCase() === 'general'
                                ? 'General Ed'
                                : course.course_type}
                            </div>
                            <h3 className='py-2 pt-3 mb-0'>
                              {course.course_id}
                            </h3>
                            <h4 className='pb-5'>
                              {course.units} Credits/Units
                            </h4>
                            <h5 className='mb-0'>
                              <span title={course.course_name}>
                                {course.course_name}
                              </span>
                            </h5>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className='text-center py-3 d-block'>
                    <img src={iconarrowPointer} alt='trophy' />
                  </div>
                </Fragment>
              ) : (
                <Fragment key={`segment-${sIdx}`}>
                  <div className='termsection no_termsection mb-2'>
                    <span>
                      Term {sIdx + 1} - {term.term_name} (0 Credits/Units)
                    </span>
                    <p>No courses have been planned for this term.</p>
                  </div>
                  <div className='text-center py-3 d-block'>
                    <img src={iconarrowPointer} alt='trophy' />
                  </div>
                </Fragment>
              );
            })}
          <div className='text-center icon_award py-3 d-block'>
            <img src={icontrophy} alt='trophy' />
            <p>
              {(myPlanData.data && myPlanData.data.award_type_name) ||
                (myPlanData.data.terms &&
                  myPlanData.data.terms[0] &&
                  myPlanData.data.terms[0].award_type_name)}
            </p>
          </div>
        </div>
      ) : (
        <div className='listView-section'>
          {displayTerms &&
            Array.isArray(displayTerms) &&
            displayTerms.length > 0 &&
            displayTerms.map((term, sIdx) => {
              return (
                <ul key={`segment-${sIdx}`} className='termsection_listView'>
                  <li
                    className={
                      calculateCourseUnits(term?.courses) == 0 ? 'no-data' : ''
                    }>
                    <h4>
                      Term {sIdx + 1} - {term.term_name} (
                      {calculateCourseUnits(term.courses)} Credits/Units)
                    </h4>
                    {term.courses &&
                    Array.isArray(term.courses) &&
                    term.courses.length > 0 ? (
                      <table width='100%' className='list_termUnits'>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Course ID</th>
                            <th className='course_title_column'>
                              Course Title
                            </th>
                            <th>Credits/Units</th>
                          </tr>
                        </thead>
                        <tbody>
                          {term.courses.map((course, cIdx) => {
                            const TYPE =
                              course?.course_type?.toLowerCase() || 'none';

                            return (
                              <tr
                                key={`course-${sIdx}-${cIdx}`}
                                onClick={() =>
                                  onClick ? onClick(course) : {}
                                }>
                                <td>
                                  <span className={TYPE} />
                                </td>
                                <td>{course.course_id}</td>
                                <td>{course.course_name}</td>
                                <td>{course.units}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <h5>No courses have been planned for this term.</h5>
                    )}
                  </li>
                </ul>
              );
            })}
        </div>
      )}
    </ErrorBoundary>
  );
};

export default CourseMap;

import * as TYPES from './constants';
import {API} from 'config';

import {
  recieveNativeLanguage,
  requestNativeLanguage,
  nativeLanguageError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.NATIVE_LANGUAGE_LOAD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.NATIVE_LANGUAGE_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.NATIVE_LANGUAGE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchNativeLanguage = () => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    let endPoint = `${API.gps.native_language}`;
    dispatch(requestNativeLanguage());
    return services.fetchRecords(endPoint, token).then(res => {
      if (Array.isArray(res)) {
        let engObj =
          (res.length && res.find(item => item.name === 'English')) || {};
        let filteredArr =
          (res.length &&
            res
              .filter(item => item.name !== 'English')
              .sort((a, b) => (a.name > b.name ? 1 : -1))) ||
          [];
        dispatch(recieveNativeLanguage([engObj, ...filteredArr]));
      } else dispatch(nativeLanguageError());
    });
  } catch (err) {
    console.log(err);
    dispatch(nativeLanguageError(err));
  }
};

import React, {useRef} from 'react';
import {Table} from 'antd';
import './style.less';

const TableLazyLoading = ({
  columns,
  data,
  pagination,
  scrollData,
  className,
}) => {
  const ref = useRef();

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={pagination}
        scroll={scrollData}
        className={className}
        components={{
          table: props => (
            <div className='ant-table-body scroll' ref={ref}>
              <table id='222' {...props} />
            </div>
          ),
        }}
      />
    </>
  );
};

export default TableLazyLoading;

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestComparePrograms,
  recieveComparePrograms,
  requestCompareProgramsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.COMPARE_PROGRAMS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.COMPARE_PROGRAMS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLEAR_COMPARE_PROGRAMS:
      return {
        request: false,
        error: false,
      };
    case TYPES.COMPARE_PROGRAMS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchComparePrograms = programId => (dispatch, getState) => {
  const page_size = 8;
  const page = 1;
  dispatch(requestComparePrograms());
  try {
    let endPoint = `${API.srm.pathways}?page_size=${page_size}&page=${page}&sort_by=date_desc`;
    if (programId) {
      endPoint += `&path_uuid=${programId}&action=compare_path`;
    }
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res && res.path_details && res.page_details) {
        dispatch(recieveComparePrograms(res.path_details));
        return;
      }
      dispatch(requestCompareProgramsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestCompareProgramsError(err));
  }
};

const style = {
  left: {
    transform: `rotateY(180deg) rotateZ(90deg) rotateX(180deg)`,
  },
  right: {
    transform: `rotateZ(90deg) rotateX(180deg)`,
  },
  top: {
    transform: 'rotateZ(180deg)',
  },
  bottom: {},
};

const iconPositions = {
  right: {
    alignSelf: 'end',
    marginRight: '51px',
    transform: 'rotateY(180deg)',
  },
};

const Icon = ({direction = 'right', iconPosition}) => {
  console.log('diercing icon position', direction, iconPosition);
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='50px'
      height='50px'
      style={{
        ...style[direction],
        ...(iconPosition ? iconPositions[iconPosition] : {}),
      }}
      viewBox='0,0,256,256'>
      <g
        fill='#ffffff'
        fillRule='nonzero'
        stroke='none'
        strokeWidth='1'
        strokeLinecap='butt'
        strokeLinejoin='miter'
        strokeMiterlimit='10'
        strokeDasharray=''
        strokeDashoffset='0'
        fontFamily='none'
        fontWeight='none'
        fontSize='none'
        textAnchor='none'
        style={{mixBlendMode: 'normal'}}>
        <g transform='scale(5.12,5.12)'>
          <path d='M43.65234,50.00391c-3,0 -29.01953,-0.79297 -29.64062,-28.00391h-10.41797l16.40625,-21.65625l16.41016,21.65625h-10.39844c0.46094,24.44141 17.88281,25.98828 18.0625,26.00391l-0.03906,1.99609c0,0 -0.13281,0.00391 -0.38281,0.00391z'></path>
        </g>
      </g>
    </svg>
  );
};
export default Icon;

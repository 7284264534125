//SEARCH EVENTS
export const REQUEST_EVENT_LIST = 'REQUEST_EVENT_LIST';
export const RECIEVE_EVENT_LIST = 'RECIEVE_EVENT_LIST';
export const REQUEST_EVENT_LIST_ERROR = 'REQUEST_EVENT_LIST_ERROR';
export const CLEAR_EVENT_LIST = 'CLEAR_EVENT_LIST';
export const RECIEVE_ALL_EVENTS_LIST = 'RECIEVE_ALL_EVENTS_LIST';

//EVENT_DETAILS
export const CLEAR_EVENT_DETAILS = 'CLEAR_EVENT_DETAILS';
export const REQUEST_EVENT_DETAILS = 'REQUEST_EVENT_DETAILS';
export const RECEIVE_EVENT_DETAILS = 'RECEIVE_EVENT_DETAILS';
export const REQUEST_EVENT_DETAILS_ERROR = 'REQUEST_EVENT_DETAILS_ERROR';

//MY EVENTS
export const REQUEST_MY_EVENTS = 'REQUEST_MY_EVENTS';
export const RECIEVE_MY_EVENTS = 'RECIEVE_MY_EVENTS';
export const REQUEST_MY_EVENTS_ERROR = 'REQUEST_MY_EVENTS_ERROR';
export const CLEAR_MY_EVENTS = 'CLEAR_MY_EVENTS';
export const REMOVE_MY_EVENT = 'REMOVE_MY_EVENT';

//STUDENT_EVENT_SIGN_UP_STATUS
export const REQUEST_STUDENT_EVENT_SIGN_UP_STATUS =
  'REQUEST_STUDENT_EVENT_SIGN_UP_STATUS';
export const RECEIVE_STUDENT_EVENT_SIGN_UP_STATUS =
  'RECEIVE_STUDENT_EVENT_SIGN_UP_STATUS';
export const REQUEST_STUDENT_EVENT_SIGN_UP_STATUS_ERROR =
  'REQUEST_STUDENT_EVENT_SIGN_UP_STATUS_ERROR';

//SIGN_UP_EVENTS
export const REQUEST_EVENT_SIGN_UP = 'REQUEST_EVENT_SIGN_UP';
export const RECEIVE_EVENT_SIGN_UP = 'RECEIVE_EVENT_SIGN_UP';
export const REQUEST_EVENT_SIGN_UP_ERROR = 'REQUEST_EVENT_SIGN_UP_ERROR';

//WITHDRAW_EVENTS
export const REQUEST_EVENT_WITHDRAW = 'REQUEST_EVENT_WITHDRAW';
export const RECEIVE_EVENT_WITHDRAW = 'RECEIVE_EVENT_WITHDRAW';
export const REQUEST_EVENT_WITHDRAW_ERROR = 'REQUEST_EVENT_WITHDRAW_ERROR';

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LocationIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d='M7.5 0C3.63 0 0.5 3.13 0.5 7C0.5 12.25 7.5 20 7.5 20C7.5 20 14.5 12.25 14.5 7C14.5 3.13 11.37 0 7.5 0ZM7.5 9.5C6.12 9.5 5 8.38 5 7C5 5.62 6.12 4.5 7.5 4.5C8.88 4.5 10 5.62 10 7C10 8.38 8.88 9.5 7.5 9.5Z' />
    </SvgIcon>
  );
};

export default LocationIcon;

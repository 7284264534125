import * as TYPES from './constants';
import {API} from 'config';

import {
  requestOccupationInterest,
  recieveOccuaptionInterest,
  occuaptionInterestError,
  recieveOccuaptionDeleteInterest,
  requestOccupationDeleteInterest,
  occuaptionInterestDeleteError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {
  request: false,
  data: null,
  error: false,
  deleteRequest: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OCCUPATION_INTEREST_LOAD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.OCCUPATION_INTEREST_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.OCCUPATION_INTEREST_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.OCCUPATION_INTEREST_DELETE_LOAD:
      return {
        ...state,
        deleteRequest: true,
      };
    case TYPES.OCCUPATION_INTEREST_DELETE_SUCCESS:
      return {
        ...state,
        deleteRequest: false,
      };
    case TYPES.OCCUPATION_INTEREST_DELETE_ERROR:
      return {
        ...state,
        deleteRequest: false,
      };
    default:
      return state;
  }
};

export const fetchOccupationInterests = () => (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      dispatch(requestOccupationInterest());
      return services.fetchRecords(API.srm.student_survey, token).then(res => {
        dispatch(recieveOccuaptionInterest(res));
        return res;
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(occuaptionInterestError(err));
  }
};
export const deleteOccupationInterest = id => (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      dispatch(requestOccupationDeleteInterest());
      return services
        .createUpdateRecord(
          token,
          `${API.srm.student_survey}${id}`,
          {},
          'DELETE',
        )
        .then(res => {
          dispatch(recieveOccuaptionDeleteInterest(res));
          return res;
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(occuaptionInterestDeleteError(err));
  }
};

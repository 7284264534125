import {
  Paper,
  Stack,
  Typography,
  Button,
  Box,
  Grow,
  CircularProgress,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const AnimatedButton = styled(Button)(({theme}) => ({
  backgroundPosition: `100% 0`,
  backgroundSize: `200% 100%`,
  backgroundImage: `linear-gradient(to left,
    transparent,
    transparent 50%,
    ${theme.palette.success.main} 50%,
    ${theme.palette.success.main})`,
  transition: `all .5s ease-in`,
  ['&.animate-bg']: {
    backgroundPosition: `0 0`,
  },
}));

const ConfirmLayout = ({
  onConfirm,
  onCancel,
  open,
  confirmLayoutProps,
  loader,
  animate,
}) => {
  const {
    title = `Are you sure to confirm?`,
    description = '',
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    paperSx = {},
    buttonLayout = 'column',
  } = confirmLayoutProps || {};
  const animateClasses = [];
  if (animate) animateClasses.push('animate-bg');
  return (
    <Paper
      sx={{
        minWidth: 300,
        minHeight: 200,
        padding: 4,
        maxWidth: 350,
        ...paperSx,
      }}>
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        spacing={2}
        textAlign={'center'}>
        <Grow in={open} timeout={300}>
          <NearbyErrorIcon color='warning' fontSize='large' />
        </Grow>
        <Grow in={open} timeout={500}>
          <Typography variant='subtitle1' fontSize={'24px'} fontWeight={'600'}>
            {title}
          </Typography>
        </Grow>
        <Grow in={open} timeout={700}>
          <Typography color={'GrayText'}>{description}</Typography>
        </Grow>
        <Box sx={{height: 2}} />
        <Grow in={open} timeout={900}>
          <Stack
            spacing={1}
            sx={{width: '100%'}}
            direction={buttonLayout === 'row' ? 'row-reverse' : 'column'}>
            <AnimatedButton
              className={animateClasses.join(' ')}
              onClick={onConfirm}
              sx={{borderRadius: '50em'}}
              variant={'contained'}
              size='large'
              fullWidth>
              {loader ? (
                <CircularProgress color='inherit' size={28} />
              ) : animate ? (
                <Grow in={animate} timeout={500}>
                  <DoneOutlinedIcon
                    color='inherit'
                    size={28}
                    className='anim-ic'
                  />
                </Grow>
              ) : (
                confirmText
              )}
            </AnimatedButton>
            <Button
              onClick={onCancel}
              disabled={loader}
              fullWidth
              size='large'
              sx={{borderRadius: '50em'}}>
              {cancelText}
            </Button>
          </Stack>
        </Grow>
      </Stack>
    </Paper>
  );
};

export default ConfirmLayout;

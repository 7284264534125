import Container from './Container';
import Awards from './subModules/Awards';
import Courses from './subModules/Courses';
import Licenses from './subModules/Licenses';
import Education from './subModules/Education';
import ProfileDetails from './subModules/Details';
import WorkExperience from './subModules/WorkExperience';
import VolunteerExperience from './subModules/VolunteerExperience';
import ProfessionalSummary from './subModules/ProfessionalSummary';
import Portfolio from './subModules/Portfolio';
import Transcripts from './subModules/Transcripts';
import Resume from './subModules/Resume';
import PersonalInterests from './subModules/PersonalInterests';

export const subModules = [
  {
    exact: true,
    key: 'details',
    path: '/details',
    component: ProfileDetails,
    label: 'Details About You',
  },
  {
    path: '/summary',
    key: 'professional',
    label: 'Professional Summary',
    component: ProfessionalSummary,
  },
  {
    key: 'education',
    label: 'Education',
    path: '/education',
    component: Education,
  },
  {
    key: 'courses',
    label: 'Courses',
    path: '/courses',
    component: Courses,
  },
  {
    key: 'license',
    path: '/license',
    component: Licenses,
    label: 'Licenses and Certifications',
  },
  {
    key: 'work_exp',
    label: 'Work Experience',
    path: '/work-experience',
    component: WorkExperience,
  },
  {
    key: 'vol_exp',
    path: '/vol-experience',
    label: 'Volunteer Experience',
    component: VolunteerExperience,
  },
  {
    path: '/awards',
    component: Awards,
    key: 'awards_projects',
    label: `Awards, Leadership, Projects & Publications`,
  },
  {
    label: 'Portfolio',
    key: 'portfolio',
    component: Portfolio,
    path: '/portfolio',
  },
  {
    label: 'TRANSCRIPTS',
    key: 'trascripts',
    component: Transcripts,
    path: '/trascripts',
  },
  {
    label: `RESUME, COVER LETTER 
  & DOCUMENTS`,
    key: 'resume',
    component: Resume,
    path: '/resume',
  },
  {
    label: 'PERSONAL INTERESTS',
    key: 'interests',
    component: PersonalInterests,
    path: '/interests',
  },
];

export const profileModuleLength = subModules.length;

export const MyProfileModules = {
  Container,
  subModules,
};

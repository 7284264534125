import {getLogo, getCardImage} from 'core/utils';
import {LazyImage} from 'core/components';
import {useInstance} from 'core/hooks';
import {defaultbannerprofile} from 'assets/images';
import style from './style.module.less';

const EducatorCard = ({data, enableNavigation, block}) => {
  const {data: instanceData} = useInstance();
  const {
    name,
    subdomain,
    logo_cloudinary = '',
    banner_cloudinary = '',
    physical_address_details: {city, state} = {},
  } = data || {};

  let address = '-';
  address = city && state && `${city}, ${state}`;

  let pathNavigateTo = () => {
    const {host, protocol} = window.location;
    let redirectURL = '';
    if (instanceData && instanceData.institution_id) {
      let newHostArr = host.split('.');
      newHostArr.splice(0, 1);
      let newHost = newHostArr.join('.');
      redirectURL = `${protocol}//${subdomain}.${newHost}`;
    } else {
      redirectURL = `${protocol}//${subdomain}.${host}`;
    }
    subdomain && window.open(redirectURL, '_blank');
  };

  return (
    <div className={style.educators_section}>
      <div
        style={{width: block ? '100%' : ''}}
        onClick={() => enableNavigation && pathNavigateTo()}>
        <LazyImage
          renderSrcSet
          type='occupation'
          src={banner_cloudinary}
          alt='search banner'
          className='img-fluid'
        />
        <span>
          <img src={getLogo(logo_cloudinary)} alt='logo' />
        </span>
        <div className={style.educator_address}>
          <h3>{name}</h3>
          <h4>{address}</h4>
        </div>
      </div>
    </div>
  );
};

export default EducatorCard;

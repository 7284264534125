import * as TYPES from './constants';

// Educators List
export const requestEducatorList = () => ({
  type: TYPES.REQUEST_EDUCATOR_LIST,
});
export const recieveEducatorList = json => ({
  type: TYPES.RECIEVE_EDUCATOR_LIST,
  payload: json,
});
export const recieveAllEducatorsList = json => ({
  type: TYPES.RECIEVE_ALL_EDUCATOR_LIST,
  payload: json,
});
export const requestEducatorListError = err => ({
  type: TYPES.REQUEST_EDUCATOR_LIST_ERROR,
  err,
});
export const clearEducatorList = () => ({
  type: TYPES.CLEAR_EDUCATOR_LIST,
});

// Institution SubTypes List
export const requestInstitutionSubTypesList = () => ({
  type: TYPES.REQUEST_INSTITUTION_SUBTYPES_LIST,
});
export const recieveInstitutionSubTypesList = json => ({
  type: TYPES.RECIEVE_INSTITUTION_SUBTYPES_LIST,
  payload: json,
});
export const requestInstitutionSubTypesListError = err => ({
  type: TYPES.REQUEST_INSTITUTION_SUBTYPES_LIST_ERROR,
  err,
});
export const clearInstitutionSubTypesList = () => ({
  type: TYPES.CLEAR_INSTITUTION_SUBTYPES_LIST,
});

import {combineReducers} from 'redux';

import MetricsData from './metrics';
import Testimonials from './testimonials';
import EmpoweringCommunities from './empoweringCommunities';

export const homeReducer = combineReducers({
  MetricsData,
  Testimonials,
  EmpoweringCommunities,
});

import * as TYPES from './constants';
import {API} from 'config';

import {
  recieveSaveProgram,
  recieveSavedPrograms,
  requestSaveProgram,
  requestSaveProgramError,
  requestSavedPrograms,
  requestSavedProgramsError,
  clearSavedPrograms,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, requestSave: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SAVED_PROGRAMS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.SAVE_PROGRAM_REQUEST:
      return {
        ...state,
        requestSave: true,
        error: false,
      };
    case TYPES.SAVED_PROGRAMS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.SAVE_PROGRAM_SUCCESS:
      return {
        ...state,
        requestSave: false,
        error: false,
      };
    case TYPES.CLEAR_SAVED_PROGRAMS:
      return {
        ...state,
        data: null,
      };
    case TYPES.SAVED_PROGRAMS_ERROR:
    case TYPES.SAVE_PROGRAM_ERROR:
      return {
        ...state,
        request: false,
        error: true,
        requestSave: false,
      };
    default:
      return state;
  }
};

export const fetchSavedPrograms = callback => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestSavedPrograms());
  try {
    let endPoint = `${API.srm.signed_programs}/?action_type=save`;
    return services.fetchRecords(endPoint, token, true).then(res => {
      if (res) {
        if (callback) callback(res);
        dispatch(recieveSavedPrograms(res));
        return;
      }
      dispatch(requestSavedProgramsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestSavedProgramsError(err));
  }
};

export const saveProgram = (program_uuid, callback) => (dispatch, getState) => {
  const {
    auth: {auth: {token = null, user = {}} = {}},
  } = getState();
  dispatch(requestSaveProgram());
  try {
    let endPoint = `${API.srm.signed_programs}`;
    const body = {
      program_uuid,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      mobile: user.phone_number,
      action_type: 'save',
    };
    return services
      .createUpdateRecord(token, endPoint, body, 'POST')
      .then(res => {
        if (res) {
          dispatch(recieveSaveProgram(res));
          if (callback) callback(res.error, res);
          return;
        }
        dispatch(requestSaveProgramError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestSaveProgramError(err));
  }
};

export const unsaveProgram = (program_uuid, callback) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null, user = {}} = {}},
  } = getState();
  dispatch(requestSaveProgram());
  try {
    let endPoint = `${API.srm.signed_programs}/${program_uuid}`;
    return services
      .createUpdateRecord(token, endPoint, {}, 'DELETE')
      .then(res => {
        if (res) {
          dispatch(recieveSaveProgram(res));
          if (callback) callback(res.error, res);
          return;
        }
        dispatch(requestSaveProgramError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestSaveProgramError(err));
  }
};

export const resetSavedPrograms = () => dispatch => {
  dispatch(clearSavedPrograms());
};

import {API} from 'config';
import * as TYPES from './constants';
import {
  requestOccupationByCluster,
  recieveOccupationByCluster,
  requestOccupationByClusterError,
} from './actions';
import {Services} from 'core/Services';

const services = new Services();
const initialState = {request: false};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_OCCUPATION_BY_CLUSTER:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_OCCUPATION_BY_CLUSTER:
      if (action.loadMore) {
        return {
          ...state,
          request: false,
          error: false,
          data: {
            ...action.payload,
            data: [
              ...(state?.data?.data || []),
              ...(action.payload?.data || []),
            ],
            data_length:
              state?.data?.data_length +
                (action.payload &&
                  action.payload.data &&
                  Array.isArray(action.payload.data) &&
                  action.payload.data.length) || 0,
          },
        };
      }
      return {
        ...state,
        request: false,
        error: false,
        data: {
          ...action.payload,
          data_length:
            (action.payload &&
              action.payload.data &&
              Array.isArray(action.payload.data) &&
              action.payload.data.length) ||
            0,
        },
      };
    case TYPES.REQUEST_OCCUPATION_BY_CLUSTER_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchOccupationByCluster = (
  cluster_uuid,
  reqParams,
  loadMore = false,
) => (dispatch, getState) => {
  const {
    page = 1,
    title = '',
    msa_code = '',
    page_size = 16,
    location = 'United States',
    selectedTab: {apiKey = 'most_openings'} = {},
  } = reqParams || {};

  let endPoint = '';
  if (msa_code) {
    endPoint = `${API.srm.occupation_rank}?cluster_uuid=${cluster_uuid}&${apiKey}=true&msa_code=${msa_code}&page_size=${page_size}&page=${page}`;
  } else {
    endPoint = `${API.srm.occupation_rank}?cluster_uuid=${cluster_uuid}&${apiKey}=true&location=${location}&page_size=${page_size}&page=${page}`;
  }

  if (title) endPoint += `&title=${title}`;
  dispatch(requestOccupationByCluster());
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveOccupationByCluster(res, loadMore));
        return;
      }
      dispatch(requestOccupationByClusterError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestOccupationByClusterError(err));
  }
};

import {useState} from 'react';
import {API} from 'config';
import {debounce} from 'lodash';
import {useUser} from 'core/hooks';
import {PATTERN} from 'core/regex';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {styled} from '@mui/material/styles';
import {updateUserProfile} from 'redux/modules/profile';
import {Button, ContentBox, ErrorBoundary} from 'mui-core';
import {publicProfileUrl} from 'data/dashboard-settings.json';
import {
  Stack,
  useTheme,
  Typography,
  FormControl,
  OutlinedInput,
  useMediaQuery,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

let updatedName = '';
const {
  heading,
  errorMsg,
  successMsg,
  subHeading,
  profileUrl,
  requiredMsg,
  submitBtnTxt,
} = publicProfileUrl || {};

const StyledStack = styled(Stack)(({theme}) => ({
  borderRadius: '4px',
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.grayBorder.main}`,
  ':hover': {border: `1px solid ${theme.palette.grayText.main}`},
  ':focus-within': {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {border: 'none'},
}));

const PublicProfileURL = () => {
  const form = useForm();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const {enqueueSnackbar} = useSnackbar();
  const {data: userData, request} = useUser();
  const {username} = userData || {};
  const {handleSubmit, errors, control} = form;
  const {student_details = ''} = API.gps || {};

  const [inputValue, setInputValue] = useState(username);
  const [usernameState, setUserNameState] = useState({
    request: false,
    message: '',
  });

  const onSubmit = async () => {
    updatedName = inputValue;
    const formData = {
      username: updatedName,
    };
    if (inputValue !== username) {
      await dispatch(
        updateUserProfile(formData, success => {
          if (success) {
            enqueueSnackbar(successMsg, {
              variant: 'success',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          } else {
            enqueueSnackbar(errorMsg, {
              variant: 'error',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          }
        }),
      );
    }
  };

  const onUserNameChange = debounce(async value => {
    if (
      !PATTERN.UserName.test(value) ||
      value.toLowerCase() === username.toLowerCase()
    ) {
      setUserNameState({
        request: false,
        message: '',
      });
      return true;
    }
    setUserNameState({request: true, message: ''});
    try {
      const data = await fetch(
        `${student_details}?check_username=${value}`,
      ).then(res => res.json());
      if (data && data.Success) {
        setUserNameState({
          request: false,
          message: '',
        });
        return true;
      } else {
        setUserNameState({
          request: false,
          message: data && data.Error ? data.Error : 'Username error.',
        });
        return false;
      }
    } catch (err) {
      setUserNameState({
        request: false,
        message: 'Failed to fetch',
      });
      return false;
    }
  }, 1000);

  const handleInputChange = e => {
    const value = e.target.value.toLowerCase();
    setInputValue(value);
    if (!value) {
      setUserNameState({
        request: false,
        message: requiredMsg,
      });
    } else {
      onUserNameChange(value);
    }
  };

  return (
    <>
      <ContentBox sx={{p: 4}} elevation={0}>
        <ErrorBoundary nameOfComponent='dashboard-account-access'>
          <Typography mb={1} variant='h4' color='text.primary'>
            {heading}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {subHeading}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              mt={3}
              spacing={{xs: 3, sm: 2}}
              direction={{xs: 'column', sm: 'row'}}>
              <StyledStack
                alignItems='left'
                direction={{xs: 'column', sm: 'row'}}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='left'>
                  <Typography
                    px={1}
                    variant='subtitle2'
                    color='text.secondary'
                    sx={{
                      fontSize: isMobile ? '11px' : '14px',
                    }}>
                    {profileUrl}
                  </Typography>
                  <Typography color='text.secondary' variant='subtitle2'>
                    |
                  </Typography>
                </Stack>
                <FormControl fullWidth={true} error={errors}>
                  <Controller
                    name='username'
                    control={control}
                    render={({field: {onChange, value = ''}}) => {
                      return (
                        <OutlinedInput
                          size='small'
                          variant='outlined'
                          id='username-input'
                          value={inputValue}
                          inputProps={{'aria-label': 'input-profile-url'}}
                          onChange={handleInputChange}
                          disabled={request || usernameState.request}
                          endAdornment={
                            usernameState.request && (
                              <InputAdornment position='end'>
                                <CircularProgress color='primary' size={20} />
                              </InputAdornment>
                            )
                          }
                        />
                      );
                    }}
                  />
                </FormControl>
              </StyledStack>
              <Button
                type='submit'
                color='primary'
                formNoValidate
                disabled={usernameState.message || false}
                loading={request || usernameState.request}
                variant='outlined'
                sx={{fontSize: {xs: '11px', sm: '14px'}}}>
                {submitBtnTxt}
              </Button>
            </Stack>
            {usernameState && (
              <Typography variant='body2' color='error'>
                {usernameState.message}
              </Typography>
            )}
          </form>
        </ErrorBoundary>
      </ContentBox>
    </>
  );
};

export default PublicProfileURL;

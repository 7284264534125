import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CareerIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d='M13.0067 5.61184C14.2624 5.61184 15.2868 4.57585 15.2868 3.30592C15.2868 2.03599 14.2624 1 13.0067 1C11.751 1 10.7266 2.03599 10.7266 3.30592C10.7266 4.57585 11.751 5.61184 13.0067 5.61184Z'
        stroke-opacity='0.87'
        stroke-width='0.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.6808 14.4457L18.0407 9.62966C18.0407 8.46371 17.1301 7.49455 16.0102 7.46484H10.007C8.88347 7.49455 7.97656 8.46371 7.97656 9.62966L8.33639 14.4457'
        stroke-opacity='0.87'
        stroke-width='0.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.4258 15.9311L19.4109 15.9386L20.3288 13.0645C20.4059 12.8157 20.7547 12.8157 20.8318 13.0645L21.7497 15.9386L24.7348 15.9311C24.9918 15.9311 25.0983 16.2653 24.889 16.4176L22.4694 18.1888L23.3983 21.0591C23.4791 21.3079 23.1964 21.5121 22.9908 21.3599L20.5785 19.5775L18.1662 21.3599'
        stroke-opacity='0.87'
        stroke-width='0.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.83387 21.357L5.42156 19.5746L3.00926 21.357C2.79998 21.5092 2.52093 21.305 2.60171 21.0562L3.53064 18.1859L1.111 16.4146C0.901715 16.2624 1.00819 15.9282 1.26521 15.9282L4.2503 15.9356L5.16822 13.0616C5.24532 12.8128 5.59413 12.8128 5.67124 13.0616L6.58916 15.9356L9.57425 15.9282'
        stroke-opacity='0.87'
        stroke-width='0.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.9988 22.642L9.89625 24.933C9.62821 25.1298 9.26839 24.8662 9.37119 24.5469L10.5682 20.8559L7.45457 18.5797C7.18654 18.3829 7.32239 17.9559 7.65652 17.9559L11.4971 17.9633L12.6757 14.2649C12.7785 13.9456 13.2228 13.9456 13.3256 14.2649L14.5042 17.9633L18.3448 17.9559C18.6753 17.9559 18.8148 18.3829 18.5467 18.5797L15.4332 20.8559L16.6301 24.5469C16.7329 24.8662 16.3731 25.1298 16.1051 24.933L13.0025 22.642H12.9988Z'
        stroke-opacity='0.87'
        stroke-width='0.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </SvgIcon>
  );
};

export default CareerIcon;

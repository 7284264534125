import * as TYPES from './constants';
const STORAGE_KEY = '_SITE_THEME_';
const initialState = {
  mode: localStorage.getItem(STORAGE_KEY) || 'light',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CHANGE_APP_THEME_MODE:
      localStorage.setItem(STORAGE_KEY, action.payload);
      return {
        ...state,
        mode: action.payload,
      };
    default:
      return state;
  }
};

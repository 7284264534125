import {Typography} from '@mui/material';
import {Grid, SkillsStack} from 'mui-core';
import {educationData} from 'data/settings-profilePreview.json';

export default function DeclaredSkills(props) {
  const {
    profileData: {data: student_profile, request, error},
  } = props;

  const sortedSkills =
    student_profile?.skills && Array.isArray(student_profile?.skills)
      ? student_profile?.skills.sort((a, b) => b.experience - a.experience)
      : [];

  const skillsData = sortedSkills?.map(
    item =>
      `${item.skill}  (${item.experience}${
        item.experience > 1 ? ' yrs' : ' yr'
      })`,
  );
  return (
    <>
      <Grid container mt={3}>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={1}
          mt={2}
          sx={{textAlign: 'right', paddingRight: '20px'}}>
          <Typography
            variant='h6'
            fontSize={15}
            fontWeight={600}
            component='span'>
            {educationData.SkillsLabel}{' '}
          </Typography>
        </Grid>

        <Grid
          item
          xs={8}
          sm={8}
          md={8}
          lg={11}
          sx={{
            textAlign: 'left',
          }}>
          <SkillsStack
            displayLimit={15}
            skillSet={skillsData}
            displayShowAll={true}
          />
        </Grid>
      </Grid>
    </>
  );
}

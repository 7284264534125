import {Link} from 'react-router-dom';
import style from './style.module.less';
const RibbonNavigator = ({className, heading, btnTxt, btnPath, children}) => (
  <div className={` ${style.ribbon_navigator}`}>
    <div className='contentContainer text-center'>
      <span className={style.S_ttl}>{`${heading} >>`} </span>
      <span className={style.content}> {children}</span>
      <Link to={btnPath} className='ml-3 btn-purple'>
        {btnTxt}
      </Link>
    </div>
  </div>
);

export default RibbonNavigator;

import * as TYPES from './constants';

const initialState = {
  displayMessage: false,
  message: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.FLASH_MESSAGE:
      return {
        ...state,
        displayMessage: true,
        message: action.payload,
      };
    case TYPES.RESET_FLASH_MESSAGE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

import {useEffect, useRef} from 'react';
import {
  Stack,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Autocomplete,
  createFilterOptions,
  Box,
  CircularProgress,
} from '@mui/material';
import {debounce} from 'lodash';
import {useInstance} from 'core/hooks';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {styled} from '@mui/material/styles';
import {FilterDrawer, LocationSearch} from 'mui-core';
import {explorePrograms} from 'data/explorePrograms.json';

const {
  qualifyingPrograms: {
    filterData: {
      displayLabel,
      awardTypeLabel,
      careerAreaLabel,
      institutionLabel,
      programTypeLabel,
      programLengthLabel,
    },
  },
} = explorePrograms;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const StyledCompaniesFilter = styled(Stack)(({theme}) => ({
  width: '100%',
  [theme.breakpoints.only('sm')]: {
    width: '50%',
  },
  [theme.breakpoints.only('xs')]: {
    minWidth: '100%',
  },
}));

const filterBy = [
  {name: 'All', uuid: 'all'},
  {name: 'In-Demand', uuid: 'in_demand'},
  {name: 'Popular', uuid: 'popular_path'},
  {name: 'New', uuid: 'newest_path'},
];

const programTypes = [
  {name: 'All', uuid: 'all'},
  {name: 'Online', uuid: 'Online'},
  {name: 'InClass', uuid: 'InClass'},
  {name: 'Hybrid', uuid: 'Hybrid'},
];

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.options,
});

const FilterForm = ({
  filters,
  onFinish,
  isDrawerOpen,
  onFilterReset,
  onDrawerClose,
  filterOptionsRequest,
  careerAreaList = [],
  durationOptions = [],
  awardTypeOptions = [],
  connectedInstitutions = [],
}) => {
  const form = useForm();
  const autocompleteRef = useRef();
  const {data: instanceData} = useInstance();

  const {reset, watch, handleSubmit, setValue} = form;
  const changeForm = watch();

  useEffect(() => {
    const subscription = watch(
      debounce(values => onFinish(values, false, true), 500),
    );
    return () => subscription.unsubscribe();
  }, [changeForm]); // eslint-disable-line react-hooks/exhaustive-deps

  const onResetClick = () => {
    const resetObj = {
      type: 'all',
      location: '',
      inst_uuid: '',
      duration: 'all',
      award_type: null,
      location_type: 'all',
      cluster_name: 'all',
    };
    reset(resetObj);
    onFilterReset(resetObj);
    if (autocompleteRef && autocompleteRef.current) {
      const clearIcon = autocompleteRef.current.querySelector(
        '.MuiAutocomplete-clearIndicator',
      );
      if (clearIcon) clearIcon.click();
    }
  };

  const onCareerAreaChange = e => {
    reset({
      page: 1,
      type: 'all',
      duration: 'all',
      award_type: null,
      location_type: 'all',
      location: filters.location,
      inst_uuid: filters.inst_uuid,
      cluster_name: e.target.value,
    });
    if (autocompleteRef && autocompleteRef.current) {
      const clearIcon = autocompleteRef.current.querySelector(
        '.MuiAutocomplete-clearIndicator',
      );
      if (clearIcon) clearIcon.click();
    }
  };

  const onTypeChange = (e, v) => {
    reset({
      page: 1,
      duration: 'all',
      award_type: null,
      type: e.target.value,
      location: filters.location,
      inst_uuid: filters.inst_uuid,
      cluster_name: filters.cluster_name,
      location_type: filters.location_type,
    });
    if (autocompleteRef && autocompleteRef.current) {
      const clear = autocompleteRef.current.querySelector(
        '.MuiAutocomplete-clearIndicator',
      );
      if (clear) clear.click();
    }
  };

  return (
    <StyledCompaniesFilter display={isDrawerOpen ? 'block' : 'none'}>
      <FilterDrawer
        filters={filters}
        onReset={onResetClick}
        onClose={onDrawerClose}
        openFilter={isDrawerOpen}
        handleSubmit={handleSubmit(onFinish)}>
        <Stack gap={3}>
          {careerAreaList &&
            Array.isArray(careerAreaList) &&
            careerAreaList.length > 0 && (
              <>
                {createField('cluster_name', {
                  form,
                  isVirtual: true,
                  defaultValue: 'all',
                  label: careerAreaLabel,
                })}
                <FormControl fullWidth>
                  <InputLabel htmlFor='career_area' id='cluster-area-label'>
                    {careerAreaLabel}
                  </InputLabel>
                  <Select
                    MenuProps={menuProps}
                    label={careerAreaLabel}
                    aria-label='career_area'
                    onChange={onCareerAreaChange}
                    inputProps={{id: 'career_area'}}
                    value={watch('cluster_name') || 'all'}>
                    <MenuItem value='all' key='all'>
                      All
                    </MenuItem>
                    {careerAreaList.map(option => (
                      <MenuItem
                        key={option.cluster_uuid}
                        value={option.cluster_name}>
                        {option.cluster_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          {createField('location_type', {
            form,
            // isVirtual: true,
            defaultValue: 'all',
            label: programTypeLabel,
          })}
          <FormControl fullWidth>
            <InputLabel id='display-label' htmlFor='location_type'>
              {programTypeLabel}
            </InputLabel>
            <Select
              label={programTypeLabel}
              aria-label='location_type'
              inputProps={{id: 'location_type'}}
              value={watch('location_type') || 'all'}
              defaultValue={watch('location_type') || 'all'}
              onChange={e => setValue('location_type', e.target.value)}>
              {programTypes.map(option => (
                <MenuItem value={option.uuid} key={option.uuid}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {createField('type', {
            form,
            // isVirtual: true,
            label: displayLabel,
            defaultValue: 'all',
          })}
          <FormControl fullWidth>
            <InputLabel id='display-label' htmlFor='Display'>
              {displayLabel}
            </InputLabel>
            <Select
              label={displayLabel}
              aria-label='Display'
              value={filters?.type}
              onChange={onTypeChange}
              inputProps={{id: 'Display'}}
              defaultValue={filters?.type}>
              {filterBy.map(option => (
                <MenuItem value={option.uuid} key={option.uuid}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {createField('location', {
            form,
            defaultValue: null,
            isVirtual: true,
          })}
          {instanceData && !instanceData.super_type_name && (
            <LocationSearch
              filters={watch('location') ? {} : {location: watch('location')}}
              LocationOptions={[]}
              onEnterPress={v => {
                if (v) setValue('location', v);
              }}
              calledFromForm
              onInputChange={(e, r, t) => {}}
              onClear={() => setValue('location', '')}
              onLocationChange={val => {
                setValue('location', val.city ? val.city : val.location);
              }}
            />
          )}
          <Box
            position={'relative'}
            display='flex'
            alignItems={'center'}
            width={'100%'}>
            {filterOptionsRequest && (
              <Box position={'absolute'} right={35}>
                <CircularProgress size={20} />
              </Box>
            )}

            {createField('award_type', {
              form,
              defaultValue: null,
            })}
            <Autocomplete
              sx={{width: '100%'}}
              freeSolo
              disabled={filterOptionsRequest}
              disablePortal
              ref={autocompleteRef}
              filterOptions={filterOptions}
              onChange={(_e, v, r) => {
                if (r === 'clear' || r === 'selectOption') {
                  setValue('award_type', v && v.options ? v.options : '');
                }
              }}
              id={'uuid'}
              value={
                changeForm.award_type
                  ? awardTypeOptions.filter(
                      i => i.options === changeForm.award_type,
                    )[0]
                  : null
              }
              options={awardTypeOptions || []}
              getOptionLabel={option => option.options}
              renderInput={params => (
                <TextField
                  {...params}
                  label={awardTypeLabel}
                  placeholder={awardTypeLabel}
                />
              )}
            />
          </Box>
          {createField('inst_uuid', {
            form,
            isVirtual: !(
              instanceData && instanceData.super_type_name === 'Consortium'
            ),
            defaultValue: 'all',
          })(
            Array.isArray(connectedInstitutions) &&
              instanceData &&
              instanceData.super_type_name === 'Consortium' &&
              connectedInstitutions.length > 0 ? (
              <FormControl fullWidth>
                <InputLabel id='institution-label' htmlFor='institution'>
                  {instanceData && instanceData.super_type_name === 'Consortium'
                    ? institutionLabel
                    : ''}
                </InputLabel>
                <Select
                  value={watch('inst_uuid') || 'all'}
                  label={institutionLabel}
                  inputProps={{id: 'institution'}}
                  onChange={e => setValue('inst_uuid', e.target.value)}
                  defaultValue='all'
                  aria-label='institution'>
                  <MenuItem value={'all'} key={'all'}>
                    {'All'}
                  </MenuItem>
                  {Array.isArray(connectedInstitutions) &&
                    connectedInstitutions.map(option => (
                      <MenuItem
                        value={option.institution_id}
                        key={option.institution_id}>
                        {option.institute_name} - {option.city},{' '}
                        {option.state_code}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ) : (
              <div />
            ),
          )}
          <Box display={'flex'} alignItems={'center'}>
            {filterOptionsRequest && (
              <Box position={'absolute'} right={60}>
                <CircularProgress size={20} />
              </Box>
            )}
            {createField('duration', {
              form,
              defaultValue: 'all',
            })(
              <FormControl fullWidth>
                <InputLabel id='duration-label' htmlFor='program_ength'>
                  {programLengthLabel}
                </InputLabel>
                <Select
                  disabled={filterOptionsRequest}
                  defaultValue='all'
                  label={programLengthLabel}
                  aria-label='program_ength'
                  value={watch('duration') || 'all'}
                  inputProps={{id: 'program_ength'}}
                  onChange={e => setValue('duration', e.target.value)}>
                  <MenuItem value={'all'} key={'all'}>
                    All
                  </MenuItem>
                  {Array.isArray(durationOptions) &&
                    durationOptions.map(option => (
                      <MenuItem value={option.name} key={option.uuid}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>,
            )}
          </Box>
        </Stack>
      </FilterDrawer>
    </StyledCompaniesFilter>
  );
};

export default FilterForm;

import {Accordion} from 'mui-core';
import {FmdGood} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import mySkillsData from 'data/mySkills.json';
import {lightBlue} from '@mui/material/colors';
import AddSkills from './AddSkills';
import {useCallback, useState} from 'react';
import SkillsTable from './SkillsTable';
import EditSkill from './AddSkills/EditSkill';

let editData = null;
const PersonalSkills = ({
  skills,
  studentSkills,
  fetchStudentSkills,
  saveStudentSkill,
  updateStudentSkill,
  deleteStudentSkill,
  fetchStudentMetrics,
}) => {
  const [editSkillModal, setEditSkillModal] = useState(false);

  const {data = []} = studentSkills || {};
  const onSkillSubmit = useCallback(values => {
    if (editData && editSkillModal) {
      updateStudentSkill(
        {
          ...editData,
          ...values,
        },
        res => {
          if (res.Error) {
            // setSnackBarErrorMsg(res.Error);
            return;
          }
          fetchStudentSkills();
          fetchStudentMetrics();
          editData = null;
        },
      );
      toggleEditSkillModal();
    } else {
      if (data && data.length >= 16) {
        // Alert.error(<Typography>You can only add upto 16 skills</Typography>);
      } else {
        values &&
          saveStudentSkill(values, res => {
            if (res.Error) {
              // setSnackBarErrorMsg(res.Error);
              return;
            }
            fetchStudentSkills();
            fetchStudentMetrics();
          });
      }
    }
  });
  const toggleEditSkillModal = useCallback(() => {
    setEditSkillModal(!editSkillModal);
  });
  const editSkill = useCallback(data => {
    editData = data;
    toggleEditSkillModal();
  });
  const deleteSkill = async skill => {
    skill && (await deleteStudentSkill(skill, fetchStudentSkills));
    fetchStudentMetrics();
  };
  return (
    <Accordion
      defaultOpen
      outlined={true}
      icon={
        <FmdGood
          fontSize='large'
          sx={{
            color: 'white',
            padding: '6px',
            borderRadius: '4px',
            marginRight: '10px',
            backgroundColor: lightBlue[400],
          }}
        />
      }
      header={mySkillsData.MyPersonalSkills.Heading}>
      <Typography variant='body1' fontSize={16} color='text.primary' mb={2}>
        {mySkillsData.MyPersonalSkills.subHeading}
      </Typography>
      <AddSkills
        skills={skills}
        onSkillSubmit={onSkillSubmit}
        studentSkills={studentSkills}
      />
      <SkillsTable
        studentSkills={studentSkills}
        editSkill={editSkill}
        deleteSkill={deleteSkill}
      />
      <EditSkill
        skills={skills}
        onSkillSubmit={onSkillSubmit}
        studentSkills={studentSkills}
        data={editData}
        open={editSkillModal}
        toggleEditSkillModal={toggleEditSkillModal}
      />
    </Accordion>
  );
};
export default PersonalSkills;

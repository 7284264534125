import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {AutoComplete, Input} from 'antd';
import {fetchOccupations, getOccupations} from 'redux/modules/occupation';
import {RequestErrorLoader, ErrorBoundary} from 'core/components';
import {getModuleBasePath} from 'core/utils';

import debounce from 'debounce';
import {occupation} from 'data/occupation';

function OccupationSearch(props) {
  const {history, fetchOccupations, occupations} = props;
  const [searchData, setSearchData] = useState([]);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    let searchArray = [];
    const {data} = occupations;
    if (data) {
      searchArray = searchArray.concat(data);
    }

    setSearchData(searchArray.map(d => ({...d, label: d.occupation_name})));
    return () => {};
  }, [occupations]);

  const onSearch = debounce(value => {
    setSearchKey(value);
    if (value !== '') fetchOccupations(value);
    else setSearchData([]);
  }, 900);

  const navigateToSearch = value => {
    const BasePath = getModuleBasePath('occupation');
    history.push(`${BasePath}?query=${value}`);
  };
  const onSelect = (value, data) => navigateToSearch(data.value);

  const onKeyDown = event => {
    if (event.key === 'Enter' && event.code === 'Enter') {
      if (
        searchData &&
        Array.isArray(searchData) &&
        searchData[0] &&
        searchData[0].occupation_name
      ) {
        navigateToSearch(searchData[0].occupation_name);
      }
    }
  };

  return (
    <ErrorBoundary nameOfComponent='core-occupation-search'>
      <div className='d-block text-center'>
        <div className='path-search-grp careerSearch mt-1'>
          <AutoComplete
            className='s-auto-complete'
            options={
              searchData && Array.isArray(searchData) && searchData.length === 0
                ? []
                : searchData &&
                  Array.isArray(searchData) &&
                  searchData.map(({occupation_onnet, occupation_name}) => ({
                    key: occupation_onnet,
                    value: occupation_name,
                    label: occupation_name,
                  }))
            }
            optionLabelProp='label'
            dropdownClassName='path-s-au-dropdown'
            notFoundContent={
              occupations.request ? (
                <RequestErrorLoader body={{request: occupations.request}} />
              ) : searchKey === '' ? null : (
                <div>No results found</div>
              )
            }
            onSelect={onSelect}
            onSearch={onSearch}>
            <>
              <Input
                className='ant-input career_txt ant-input-lg'
                placeholder={props.placeholder || occupation.placeHolder}
                onKeyDown={onKeyDown}
              />
            </>
          </AutoComplete>
        </div>
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = state => ({
  occupations: getOccupations(state),
});

export default connect(mapStateToProps, {
  fetchOccupations,
})(OccupationSearch);

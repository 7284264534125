import * as TYPES from './constants';
import {API} from 'config';

import {
  requestStudentMetrics,
  recieveStudentMetrics,
  studentMetricsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: {}, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.STUDENT_METRICS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.STUDENT_METRICS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.STUDENT_METRICS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.STUDENT_METRICS_CLEAR:
      return {
        ...state,
        request: false,
        error: true,
        data: {},
      };
    default:
      return state;
  }
};

export const fetchStudentMetrics = () => (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      let endPoint = `${API.gps.student_metrics}`;
      dispatch(requestStudentMetrics());
      return services.fetchRecords(endPoint, token).then(res => {
        if (res && typeof res === 'object')
          dispatch(recieveStudentMetrics(res));
        else dispatch(studentMetricsError());
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(studentMetricsError(err));
  }
};

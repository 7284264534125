import {Component} from 'react';
// import {useHistory} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import {ErrorPage, NoEvents} from 'core/components';

import './style.less';

const dialogOptions = {};

const DefaultFallBack = ({error, typeOfBoundary, resetError}) => (
  <div className='component_crash'>
    <div className='error_label'>You have encountered an error</div>
    <div className='error_message'>{error?.toString()}</div>
    {/* <div className='error_trace'>{componentStack}</div> */}
    {typeOfBoundary === 'sentry' && (
      <button
        type='button'
        className='error_reset ant-btn ant-btn-primary'
        onClick={() => resetError()}>
        Reset
      </button>
    )}
  </div>
);

const Fallback = ({
  error,
  componentStack,
  resetError,
  typeOfBoundary,
  showFallback = true,
  typeOfUi = 'default',
}) => {
  if (showFallback) {
    if (typeOfUi === 'fullPage') {
      return (
        <ErrorPage
          heading='Oops! An error occurred'
          subHeading='We are constantly working to improve your GoEducate experience and apologize for any inconvenience with this disruption. Please refresh your browser to view the latest site version.'
          list={null}
          pageRefresh={true}
        />
      );
    } else if (typeOfUi === 'subPage') {
      return (
        <div className='sub-error-ui'>
          <NoEvents
            title='Oops! An error occured'
            subTitle='We are constantly working to improve your GoEducate experience and apologize for any inconvenience with this disruption. Please refresh your browser to view the latest site version.'
            navPath={null}
            navTitle={null}
          />
        </div>
      );
    } else if (typeOfUi === 'default') {
      return (
        <DefaultFallBack
          typeOfBoundary={typeOfBoundary}
          error={error}
          resetError={resetError}
        />
      );
    } else {
    }
  } else {
    return <></>;
  }
};

const SentryErrorBoundary = ({
  children,
  nameOfComponent = 'notDefined',
  showDialog = false,
  showFallback = true,
  typeOfUi = 'default',
}) => {
  // const {location: {pathname = 'notDefined'} = {}} = useHistory() || {};

  return (
    <Sentry.ErrorBoundary
      beforeCapture={scope => {
        // scope.setTag('location', pathname);
        scope.setTag('componentName', nameOfComponent);
      }}
      dialogOptions={dialogOptions}
      fallback={props => (
        <Fallback
          {...props}
          showFallback={showFallback}
          typeOfUi={typeOfUi}
          typeOfBoundary='sentry'
        />
      )}
      showDialog={showDialog}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

class CustomErrorBoundary extends Component {
  state = {hasError: false, error: false, errorInfo: null};

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    // can send the logs to our own custom log service.
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    const {hasError, errorInfo, error} = this.state;
    if (hasError && errorInfo && error) {
      return (
        <Fallback
          {...this.props}
          error={error}
          componentStack={errorInfo?.componentStack}
          typeOfBoundary='custom'
        />
      );
    }
    return this.props.children;
  }
}

const ErrorBoundary = props => {
  const {typeOfBoundary = 'sentry'} = props || {};
  if (typeOfBoundary === 'sentry') {
    return <SentryErrorBoundary {...props} />;
  }
  return <CustomErrorBoundary {...props} />;
};

export default ErrorBoundary;

/*
************************************************************************
* Ref Doc: //https://docs.sentry.io/platforms/javascript/guides/       *
react/enriching-events/user-feedback/#customizing-the-widget           *
*                                                                      *
*                                                                      *
*                                                                      *
************************************************************************
*/

import AutoComplete from 'mui-core/AutoComplete';
import resumeBuilderData from 'data/resumeBuilder.json';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {
  clearLinkedInDetails,
  fetchlinkedInDetails,
  getLinkedInDetails,
} from 'redux/modules/resume-builder';
import {useEffect, useState} from 'react';

const {
  stepsMeta: {
    professionalExperience: {formData: formStaticData = {}},
  },
} = resumeBuilderData || {};

const JobTitleSelect = ({value, form, label, placeholder, ...rest}) => {
  const {
    setValue,
    setError,
    clearErrors,
    formState: {errors},
  } = form;
  // hooks
  const dispatch = useDispatch();
  //states
  const [keyword, setKeyword] = useState('');
  const [openTitle, setOpenTitle] = useState(false);
  const [params, setParams] = useState('');
  //   seelctors
  const linkedInData = useSelector(getLinkedInDetails) || {};
  //vars
  const {
    job_title: job_title_options = [],
    job_title_request,
    skill_name_request,
  } = linkedInData;
  const debouncedValue = useDebounce(keyword, 1000);
  //func
  function useDebounce(value, delay, initialValue) {
    const [state, setState] = useState(initialValue);

    useEffect(() => {
      const timer = setTimeout(() => setState(value), delay);
      // clear timeout should the value change while already debouncing
      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return state;
  }
  const onClearField = () => {
    setKeyword('');
    dispatch(clearLinkedInDetails());
  };
  const handleSearch = (_, value, r, key) => {
    if (value.length === 0) {
      if (openTitle) setOpenTitle(false);
    } else {
      if (!openTitle) setOpenTitle(true);
    }
    if (r === 'clear') {
      setKeyword('');
      setValue('job_title', null);
      // dispatch(clearLinkedInDetails());
      setError('job_title', {
        type: 'required',
        message: formStaticData.position.requiredMsg,
      });
    }
    if (r === 'input') {
      setKeyword(value);
      setParams(key);
      if (value.length === 0)
        setError('job_title', {
          type: 'required',
          message: formStaticData.position.requiredMsg,
        });
    }
  };
  useEffect(() => {
    if (debouncedValue && params) {
      dispatch(fetchlinkedInDetails(params, debouncedValue));
    }
  }, [debouncedValue]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AutoComplete
      fieldName='job_title'
      calledFromForm={true}
      forcePopupIcon={false}
      value={value || ''}
      clearErrors={clearErrors}
      onClearField={onClearField}
      request={job_title_request}
      errors={errors['job_title']}
      onClose={() => setOpenTitle(false)}
      freeSolo={
        !(keyword && !job_title_request && job_title_options?.length === 0)
      }
      noOptionsText={
        !skill_name_request && keyword?.length > 22 ? (
          'No Options'
        ) : (
          <Typography variant='body2'>Min 2 characters required</Typography>
        )
      }
      onInputChange={(e, value, r) => handleSearch(e, value, r, 'job_title')}
      setValue={val =>
        setValue('job_title', val, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
      options={
        job_title_options &&
        Array.isArray(job_title_options) &&
        job_title_options.length > 0
          ? job_title_options?.map(item => {
              return {
                ...item,
                key: item.id,
                label: item.job_title,
                title: item.job_title,
              };
            })
          : []
      }
      label={`${label}`}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default JobTitleSelect;

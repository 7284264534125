import {isObject, isEmpty} from 'lodash';
import * as TYPES from './constants';
import {API} from 'config';

import {
  requestDuration,
  recieveDuration,
  requestDurationError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.DURATION_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.DURATION_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.DURATION_CLEAR:
      return {
        ...state,
        request: false,
        error: false,
        data: null,
      };
    case TYPES.DURATION_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchDuration = params => (dispatch, getState) => {
  const {search: {duration: {request} = {}} = {}} = getState();
  try {
    if (!request && params && isObject(params) && !isEmpty(params)) {
      dispatch(requestDuration());
      const endPoint = `${API.srm.program_duration}?${
        params.type
      }=${encodeURIComponent(params.value)}`;
      return services.fetchRecords(endPoint).then(res => {
        if (res && Array.isArray(res)) {
          dispatch(recieveDuration(res));
          return;
        }
        dispatch(requestDurationError(res));
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestDurationError(err));
  }
};

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestOpportunityInterest,
  receiveOpportunityInterest,
  requestOpportunityInterestError,
  requestUnSaveOpportunity,
  receiveUnSaveOpportunity,
  handleUnSaveOpportunityError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPPORTUNITY_INTEREST_REQUEST:
    case TYPES.OPPORTUNITY_UNSAVE_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.OPPORTUNITY_INTEREST_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.OPPORTUNITY_INTEREST_ERROR:
    case TYPES.OPPORTUNITY_UNSAVE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

// Save or Apply for Opportunity
export const updateOpportunityInterest = (data, callback) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestOpportunityInterest());
  try {
    let endPoint = `${API.gps.opportunity}`;
    return services
      .createUpdateRecord(token, endPoint, data, 'POST')
      .then(res => {
        if (res) {
          dispatch(receiveOpportunityInterest(res));
          if (callback) {
            if (res.opp_application_uuid) {
              callback(false);
            } else {
              callback(
                res.Error ? res.Error : 'Error in saving the opportunity',
              );
            }
          }

          return;
        }
        dispatch(requestOpportunityInterestError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestOpportunityInterestError(err));
  }
};

// unSave an Opportunity
export const unSaveOpportunity = (opp_uuid, callback) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestUnSaveOpportunity());
  try {
    let endPoint = `${API.gps.opportunity}${opp_uuid}`;
    return services.deleteRecord(token, endPoint).then(res => {
      if (res.Success) {
        dispatch(receiveUnSaveOpportunity());
        if (callback) {
          if (res.Success) {
            callback(false);
          } else {
            callback(
              res.Error ? res.Error : 'Error in un-saving the opportunity',
            );
          }
        }
        return;
      }
      dispatch(handleUnSaveOpportunityError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(handleUnSaveOpportunityError(err));
  }
};

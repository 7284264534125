import {Link} from 'react-router-dom';
import {noResultsIcon} from 'assets/images';
//import './style.less';
import style from './style.module.less';

const NoEvents = ({
  title = 'No Events Found',
  subTitle = 'Search for a different events',
  navPath = '/events',
  navTitle = 'Explore Events',
}) => {
  return (
    <>
      <div className={style.no_events_found}>
        <div>
          <img src={noResultsIcon} />
          {title && <h5>{title}</h5>}
          {subTitle && <p>{subTitle}</p>}
          {navPath && navTitle && <Link to={navPath}>{navTitle}</Link>}
        </div>
      </div>
    </>
  );
};

export default NoEvents;

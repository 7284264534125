import {useEffect} from 'react';
import {connect} from 'react-redux';
import {ErrorBoundary} from 'mui-core';
import {
  // fetch
  fetchSkills,
  fetchProfileData,
  fetchStudentMetrics,
  fetchStudentCredentials,
  fetchAwardTypes as fetchProfileAwardTypes,
  // actions
  updateProfile,
  updateUserProfile,
  saveStudentCredential,
  updateStudentCredential,
  deleteStudentCredential,
  // selectors
  skillsSelector,
  awardTypesSelector,
  profileDataSelector,
  studentCredentialsSelector,
} from 'redux/modules/profile';
import {fetchStates, getStates} from 'redux/modules/general';
import {fetchAwardTypes, getAwardTypes} from 'redux/modules/search';
import {getActivePath, fetchActivePaths} from 'redux/modules/pathways';

const EditProfileWrapper = WrappedComponent => {
  const ProfileWrapper = props => {
    const {
      skills,
      awardTypes,
      fetchSkills,
      updateProfile,
      srmAwardTypes,
      fetchAwardTypes,
      fetchActivePaths,
      fetchProfileData,
      fetchProfileAwardTypes,
      fetchStudentCredentials,
      studentCredentials: {
        data: credentialsInfo = [],
        request: credentialsRequest,
      } = {},
      activePaths: {
        data: activePathsData = [],
        request: activePathsRequest,
      } = {},
      profileData: {data: student_profile, request: profileRequest},
    } = props;
    const {student_profile_uuid} = student_profile || {};

    useEffect(() => {
      !student_profile && !profileRequest && fetchProfileData();
      (!srmAwardTypes?.data || !srmAwardTypes?.data?.length) &&
        !srmAwardTypes?.request &&
        fetchAwardTypes(false, true);
      (!activePathsData || !activePathsData?.length) &&
        !activePathsRequest &&
        fetchActivePaths();
      (!credentialsInfo || !credentialsInfo?.length) &&
        !credentialsRequest &&
        fetchStudentCredentials();
      (!awardTypes?.data || !awardTypes.data?.length) &&
        !awardTypes?.request &&
        fetchProfileAwardTypes();
      (!skills?.data || !skills.data?.length) &&
        !skills?.request &&
        fetchSkills();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onProfileDataSubmit = async (key, end_point, data, action, id) => {
      let endPoint = `${end_point}/${key ? student_profile_uuid : id || ''}`;
      let body = data;
      if (key) {
        body = {
          [key]: data,
        };
      }
      return await updateProfile(endPoint, action, body);
    };

    return (
      <ErrorBoundary nameOfComponent='new-dashboard-myprofile-editprofilewrapper'>
        <WrappedComponent
          {...props}
          onProfileDataSubmit={onProfileDataSubmit}
        />
      </ErrorBoundary>
    );
  };

  const mapStateToProps = state => ({
    skills: skillsSelector(state),
    activePaths: getActivePath(state),
    statesList: getStates(state).data,
    srmAwardTypes: getAwardTypes(state),
    awardTypes: awardTypesSelector(state),
    profileData: profileDataSelector(state),
    studentCredentials: studentCredentialsSelector(state),
  });

  return connect(mapStateToProps, {
    // fetch
    fetchStates,
    fetchSkills,
    fetchAwardTypes,
    fetchActivePaths,
    fetchProfileData,
    fetchStudentMetrics,
    fetchProfileAwardTypes,
    fetchStudentCredentials,
    // actions
    updateProfile,
    updateUserProfile,
    saveStudentCredential,
    updateStudentCredential,
    deleteStudentCredential,
  })(ProfileWrapper);
};

export default EditProfileWrapper;

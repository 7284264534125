import {useAuth} from 'core/hooks';
import {Stack} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {BackgroundPaper} from 'mui-core';
import {useHistory} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import TalentCard from './components/TalentCard';
import {openLoginScreen} from 'redux/modules/auth';
import TalentDetails from './components/TalentDetails';

import {talentCardDetails} from 'data/talent-exchange.json';
import {
  Box,
  styled,
  Button,
  Divider,
  Container,
  Typography,
} from '@mui/material';
import TalentExchangeBanner from './components/TalentExchangeBanner';

const GradientDivider = styled(Divider)(({theme}) => ({
  width: '440px',
  marginLeft: '50%',
  borderWidth: '2px',
  transform: 'translate(-50%, -50%)',
  borderImage:
    'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  borderImageSlice: '20%',
  borderImageWidth: '3px',
}));

const TalentButton = styled(Button)(({theme}) => ({
  width: '100%',
  flexShrink: 0,
  height: '50px',
  border: 'none',
  display: 'flex',
  marginLeft: '50%',
  cursor: 'pointer',
  maxWidth: '480px',
  borderRadius: '4px',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  transform: 'translate(-50%, -50%)',
  background: theme.palette.primary.main,
  color: theme.palette.success.contrastText,
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const Talent = () => {
  const theme = useTheme();
  const [token] = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeCard, setActiveCard] = useState('jobSeeker');

  const cardHandler = item => {
    setActiveCard(item);
  };

  const handleOpenLogin = () => {
    if (token) {
      history.push('/dashboard');
    } else {
      dispatch(
        openLoginScreen({
          screen: 'signin',
          callback: () => history.push('/dashboard'),
        }),
      );
    }
  };

  const actionSignInHandler = e => {
    e.stopPropagation();
    if (activeCard === 'employer') {
      const externalLink = {
        target: '_blank',
        href: 'https://www.goeducate.io/',
      };
      window.open(externalLink.href, externalLink.target);
    } else if (activeCard === 'jobSeeker') {
      handleOpenLogin();
    }
  };

  const cardDetails = talentCardDetails.find(item => item.key === activeCard);

  return (
    <BackgroundPaper background={theme.palette.purplebg4perc.main}>
      <TalentExchangeBanner activeCard={cardDetails.key} />
      <Container sx={{pb: {xs: 1, sm: 1, md: 2}}}>
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{mb: {xs: 1.5, md: 1}}}
          gap={{xs: '24px', md: '75px'}}
          direction={{xs: 'column', sm: 'row'}}>
          {talentCardDetails.map(cardItem => (
            <Box>
              <Typography
                variant='h1'
                lineHeight={2}
                textAlign='center'
                color='text.primary'
                fontSize={{
                  xs: '24px !important',
                  sm: '30px !important',
                  md: '45px !important',
                }}>
                {cardItem.title}
              </Typography>
              <TalentCard
                cardKey={cardItem.key}
                buttonText={cardItem.buttonText}
                isActive={cardItem.key === activeCard}
                description={cardItem.cardDescription}
                onClick={() => cardHandler(cardItem.key)}
              />
            </Box>
          ))}
        </Stack>
        <Divider variant='middle' sx={{my: {xs: 4, md: 6}}} />
        <Typography
          variant='h1'
          lineHeight={1.7}
          textAlign='center'
          color='text.primary'
          fontSize={{
            xs: '24px !important',
            sm: '30px !important',
            md: '55px !important',
          }}>
          {cardDetails.role}
        </Typography>

        <GradientDivider
          sx={{mb: {xs: 1, sm: 1, md: 2}, mt: {xs: 2, sm: 2, md: 1}}}
        />
        {cardDetails.cardContent.map((detail, index) => (
          <TalentDetails detail={detail} imageFirst={index % 2 !== 0} />
        ))}
        <TalentButton
          onClick={actionSignInHandler}
          sx={{mt: {xs: 4, sm: 8, md: 10}}}>
          {cardDetails.buttonText}
        </TalentButton>
      </Container>
    </BackgroundPaper>
  );
};

export default Talent;

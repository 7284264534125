import * as TYPES from './constants';
import {API} from 'config';
import {
  recieveCareerRank,
  requestCareerRank,
  requestCareerRankError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CLEAR_CAREER_RANK:
      return initialState;
    case TYPES.REQUEST_CAREER_RANK:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_CAREER_RANK:
      if (action.loadMore) {
        return {
          ...state,
          request: false,
          error: false,
          data: {
            ...action.payload,
            data: [...state?.data?.data, ...action.payload?.data],
            data_length:
              state.data.data_length +
                (action.payload &&
                  action.payload.data &&
                  Array.isArray(action.payload.data) &&
                  action.payload.data.length) || 0,
          },
        };
      }
      return {
        ...state,
        request: false,
        error: false,
        data: {
          ...action.payload,
          data_length:
            (action.payload &&
              action.payload.data &&
              Array.isArray(action.payload.data) &&
              action.payload.data.length) ||
            0,
        },
      };
    case TYPES.REQUEST_CAREER_RANK_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchOccupationRank = (reqParams, filters, loadMore = false) => (
  dispatch,
  getState,
) => {
  const {
    page = 1,
    page_size = 16,
    sort = 'name_asc',
    selectedTab: {apiKey = 'all'} = {},
  } = reqParams || {};
  const {
    title = '',
    msa_code = '',
    cluster_uuid = '',
    location = 'United States',
  } = filters || {};
  let endPoint = `${API.srm.occupation_rank}?${apiKey}=true&page_size=${page_size}&page=${page}&sort=${sort}`;
  if (msa_code) {
    endPoint += `&msa_code=${msa_code}`;
  } else {
    endPoint += `&location=${location}`;
  }
  // if (apiKey === 'all')
  //   endPoint = `${API.srm.occupation_rank}?${apiKey}=true&sort=title_asc&page_size=${page_size}&page=${page}`; // removing as per MUI functionality changes.
  if (title) endPoint += `&title=${title}`;
  if (cluster_uuid && cluster_uuid !== 'all')
    endPoint += `&cluster_uuid=${cluster_uuid}`;

  dispatch(requestCareerRank());
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveCareerRank(res, loadMore));
        return;
      }
      dispatch(requestCareerRankError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestCareerRankError(err));
  }
};

import {API} from 'config';
import * as TYPES from './constants';
import {Services} from 'core/Services';
import {
  resumeError,
  requestResume,
  recieveResume,
  resumeUpdatedError,
  requestUpdatedResume,
  recieveUpdatedResume,
} from './actions';

const services = new Services();
const initialState = {request: false, error: false, data: null};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.RESUME_LIST_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RESUME_LIST_RECIEVE:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.RESUME_LIST__CLEAR:
      return {
        request: false,
        error: false,
      };
    case TYPES.RESUME_LIST_ERROR:
      return {
        ...initialState,
        request: false,
        error: false,
      };
    default:
      return state;
  }
};

export const fetchResumeList = (userName = '') => (dispatch, getState) => {
  dispatch(requestResume());
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    let endPoint = userName
      ? `${API.gps.public_student_resume}?username=${userName}`
      : `${API.gps.student_resume}`;
    return services.fetchRecords(endPoint, token).then(res => {
      if (Array.isArray(res)) {
        dispatch(recieveResume(res));
      } else dispatch(resumeError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(resumeError(err));
  }
};

export const updateResumeList = (statusObj, resume_uuid, callBack) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    let endPoint = `${API.gps.student_resume}${resume_uuid}`;
    dispatch(requestUpdatedResume());
    await services.updateStatus(token, endPoint, statusObj).then(res => {
      if (res.success) {
        recieveUpdatedResume(res);
        dispatch(fetchResumeList());
        if (callBack) callBack(res);
        return;
      }
    });
  } catch (err) {
    dispatch(resumeUpdatedError(err));
  }
};

export const deleteSavedResume = (resumeId, callBack) => (
  dispatch,
  getState,
) => {
  dispatch(requestResume());
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    let endPoint = `${API.gps.student_resume}${resumeId}`;
    return services.deleteRecord(token, endPoint).then(res => {
      if (res) {
        dispatch(fetchResumeList());
        if (callBack) callBack(res);
        return;
      }
      dispatch(resumeError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(resumeError(err));
  }
};

export const downloadResume = (resumeId, onSuccess, onError) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    const endPoint = `${API.gps.student_resume}${resumeId}?action=download`;
    const response = await services.fetchRecords(
      endPoint,
      token,
      false,
      false,
      true,
    );
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError();
  }
};

import React, {useRef, useState} from 'react';
import {useSnackbar} from 'notistack';
import {avatarGray} from 'assets/images';
import DropdownMenu from '../DropdownMenu';
import {styled} from '@mui/material/styles';
import {MuiProfileUpload} from 'core/hooks';
import {uploadDocData} from 'data/dashboard-settings.json';
import {getProfilePhoto, bytesToMegaBytes} from 'mui-core/utils';
import {Box, Badge, Avatar, CircularProgress} from '@mui/material';
import ImageCropper from 'mui-core/Cropper';

let cropFile = null;

const UploadProfileImage = ({
  viewOnly,
  isLoader,
  deleteBanner,
  isMobileView,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [cropModal, setCropModal] = useState(false);
  const profileUploaderInputRef = useRef(null);
  const [progress, userData, uploadImage] = MuiProfileUpload('photo');
  const {data} = userData || {};
  let userPhoto = avatarGray;
  if (data && data.photo) {
    userPhoto = getProfilePhoto(data.photo);
  }

  const handleImageChange = e => {
    e.preventDefault();
    const maxFileSize = 10;
    const file = e.target.files[0];
    const FileSize = bytesToMegaBytes(file?.size);
    if (parseFloat(FileSize) > maxFileSize) {
      enqueueSnackbar(
        uploadDocData.sizeExceedErrorMsg.replace('{maxFileSize}', maxFileSize),
        {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        },
      );
    } else {
      // uploadImage(e.target.files[0]);
      cropFile = e.target.files[0];
      setCropModal(true);
    }
    profileUploaderInputRef.current.value = '';
  };

  const onCrop = crop => {
    const {file, image} = crop;
    uploadImage(file);
    setCropModal(false);
  };

  const handleDeletePhoto = async () => {
    const body = {
      photo: null,
    };
    await deleteBanner(body);
  };

  const UploadImagecls = styled(Box)(({theme}) => ({
    zIndex: 9,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    '& .avatarContainerStyle': {
      marginTop: '-60px',
      marginBottom: '21px',
      position: 'relative',
      display: 'inline-block',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-8px',
      '& .avatarContainerStyle': {
        top: '30px',
        position: 'relative',
        display: 'inline-block',
      },
    },
    '& .MuiBadge-badge': {
      left: '-40px',
      top: '-40px',
      bottom: '0px',
      display: 'flex',
      width: '20.5px',
      height: '20px',
      padding: '14px 6px',
      borderRadius: '50%',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',

      [theme.breakpoints.down('sm')]: {
        top: '-20px',
        left: '-27px',
      },
    },
  }));

  return (
    <>
      <input
        type='file'
        accept='image/*'
        id='profile-photo-upload'
        style={{display: 'none'}}
        onChange={handleImageChange}
        ref={profileUploaderInputRef}
        aria-label='profile-uploader'
      />
      <UploadImagecls>
        <Box className='avatarContainerStyle'>
          <Avatar
            alt='profile'
            src={userPhoto}
            sx={{
              bgcolor: 'white',
              border: '1px solid white',
              width: {xs: '80px', sm: '150px'},
              height: {xs: '80px', sm: '150px'},
            }}
          />
          {(!!progress || isLoader) && (
            <CircularProgress
              thickness={1.9}
              value={progress}
              variant='indeterminate'
              size={isMobileView ? 60 : 150}
              style={{position: 'absolute', top: 0, left: 0}}
            />
          )}
        </Box>
        <Badge
          overlap='circular'
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          badgeContent={
            !viewOnly && (
              <DropdownMenu
                id='profile-editor'
                deleteImage={handleDeletePhoto}
                htmlForLabel='profile-photo-upload'
              />
            )
          }></Badge>
      </UploadImagecls>
      <ImageCropper
        visible={cropModal}
        onCancel={() => setCropModal(false)}
        cropFile={cropFile}
        aspectRatio={1 / 1}
        onCrop={onCrop}
      />
    </>
  );
};

export default UploadProfileImage;

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestOccupations,
  recieveOccupations,
  recieveAllOccupations,
  requestOccupationsError,
} from './actions';

import {recieveClusters} from '../search/actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {
  request: false,
  data: null,
  allOccupations: null,
  error: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OCCUPATIONS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.OCCUPATIONS_SUCCESS:
      let data = action.payload;
      if (state.data && state.data.occupation) {
        data.occupation = [...state.data.occupation, ...data.occupation];
      }
      return {
        ...state,
        request: false,
        error: false,
        data,
      };
    case TYPES.OCCUPATIONS_ALL_SUCCESS:
      const AllOccupationsData = action.payload;
      return {
        ...state,
        request: false,
        error: false,
        allOccupations: AllOccupationsData,
      };
    case TYPES.OCCUPATIONS_CLEAR:
      return {
        ...state,
        request: false,
        error: false,
        data: null,
      };
    case TYPES.OCCUPATIONS_ALL_CLEAR:
      return {
        ...state,
        request: false,
        error: false,
        allOccupations: null,
      };
    case TYPES.OCCUPATIONS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchOccupations = (pageSize = 9, page = 1) => async (
  dispatch,
  getState,
) => {
  const {
    pathways: {pathDetails: {data = null} = {}} = {},
    search: {
      clusters: {data: clustersData},
    },
  } = getState();
  try {
    if (data) {
      let endPoint = `${API.srm.clusters}?cluster_type=1&path=true`;
      dispatch(requestOccupations());
      let clusters = clustersData;
      if (!clusters) {
        clusters = await services.fetchRecords(endPoint);
        dispatch(recieveClusters(clusters));
      }
      if (clusters && clusters.length) {
        endPoint = `${API.srm.program}${data.program_details?.program_uuid}?res=pathways&page_size=${pageSize}&page=${page}`;
        return services.fetchRecords(endPoint).then(async res => {
          if (res && res.uuid) {
            await clusters.filter(cluster => {
              if (cluster.cluster_uuid === res.cluster_id) {
                res.cluster_image = cluster.cluster_banner_url;
                res.cluster_name = cluster.cluster_name;
              }
            });
            dispatch(recieveOccupations(res));
            return;
          }
          dispatch(requestOccupationsError(res));
        });
      }
    }
  } catch (err) {
    console.log(err);
    dispatch(requestOccupationsError(err));
  }
};

export const fetchAllOccupations = () => async (dispatch, getState) => {
  const {pathways: {pathDetails: {data = null} = {}} = {}} = getState();
  try {
    if (data?.program_details?.program_uuid) {
      dispatch(requestOccupations());
      let endPoint = `${API.srm.program}${data.program_details?.program_uuid}?res=pathways`;
      return services.fetchRecords(endPoint).then(async res => {
        dispatch(recieveAllOccupations(res));
        return res;
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestOccupationsError(err));
  }
};

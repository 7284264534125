import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Stack,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material';
import {
  ContentBox,
  CareerBanner,
  BackgroundPaper,
  RequestErrorLoader,
} from 'mui-core';
import {getOccupations} from 'redux/modules/pathways';
import {
  getOccupationDetails,
  fetchOccupationRoles,
  fetchOccupationDetails,
  clearOccupationDetails,
} from 'redux/modules/occupation';
import {brightIcon} from 'assets/images';
import {useAuth, useInstance} from 'core/hooks';
import occupationData from 'data/occupation.json';
// remove these "Sharable components from Module - Occupation" after implementing CareerOverview in MUI
import {CareerOverview} from 'app/modules/occupation/components';
import 'app/modules/pathways/containers/ExplorePath/components/Career/style.less';
import {exploreCareer} from 'data/exploreCareer.json';

const {
  careerDetails,
  loadingOccupTxt,
  noDataAvailableTxt,
  loadingOccupationDetailsTxt,
} = occupationData;

const Careers = () => {
  const [token] = useAuth();
  const dispatch = useDispatch();
  const occupations = useSelector(getOccupations);
  const occupationDetails = useSelector(getOccupationDetails);
  const {request: occupDetailsRequest, data: {occupation_onnet = ''} = {}} =
    occupationDetails || {};
  const [location, setLocation] = useState('');
  const [occupationSelected, setOccupationSelected] = useState('');
  const {defaultLocation} = exploreCareer;
  const {
    data: {default_msa: {city = '', state_code = '', msa_code = ''} = {}} = {},
  } = useInstance();

  useEffect(() => {
    if (
      occupations?.allOccupations?.occupation &&
      occupations?.allOccupations?.occupation?.[0]?.name
    ) {
      setOccupationSelected(occupations.allOccupations?.occupation?.[0].name);
    }
  }, [occupations?.allOccupations?.occupation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const defLoc = city ? `${city}, ${state_code}` : defaultLocation;
    const reqParams = {
      msa_code: msa_code ?? '',
      query: occupationSelected ?? '',
      location: defLoc,
    };
    setLocation(defLoc);
    occupationSelected && dispatch(fetchOccupationDetails(reqParams));
    return () => {
      dispatch(clearOccupationDetails());
    };
  }, [msa_code, occupationSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    token &&
      occupation_onnet &&
      dispatch(fetchOccupationRoles(occupation_onnet));
  }, [token, occupation_onnet]); // eslint-disable-line react-hooks/exhaustive-deps

  const onOccupationChange = event => setOccupationSelected(event.target.value);

  return (
    <Stack spacing={3} direction='column' className='occupation-details'>
      <ContentBox>
        <BackgroundPaper isPurple p={4}>
          <Typography variant='h4' color='text.primary'>
            {careerDetails.heading}
          </Typography>
          <Typography my={2} variant='body1' color='text.primary'>
            {careerDetails.description}
          </Typography>
          <RequestErrorLoader
            minHeight={60}
            hideBackground
            title={loadingOccupTxt}
            body={{
              ...occupations,
              request: occupations.request,
              data: occupations.allOccupations,
            }}>
            <FormControl sx={{minWidth: {xs: 300, md: 550}}}>
              <InputLabel id='filter-by-career-label'>
                {careerDetails.filterLabel}
              </InputLabel>
              <Select
                value={occupationSelected}
                onChange={onOccupationChange}
                label={careerDetails.filterLabel}
                disabled={occupations.request || !occupationSelected}>
                {occupations?.allOccupations?.occupation &&
                  Array.isArray(occupations.allOccupations.occupation) &&
                  occupations.allOccupations.occupation.map((occ, idx) => (
                    <MenuItem value={occ.name} key={idx}>
                      <span>{occ.name}</span>
                      {occ.bright && (
                        <img
                          style={{
                            width: '20px',
                            height: '20px',
                            marginLeft: '8px',
                          }}
                          src={brightIcon}
                          alt='bright-icon'
                        />
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </RequestErrorLoader>
          <RequestErrorLoader
            fullScreen
            hideBackground
            title={loadingOccupationDetailsTxt}
            body={{
              ...occupationDetails,
              data: occupationDetails,
              request: occupDetailsRequest,
            }}>
            {occupationSelected &&
              occupationDetails &&
              occupationDetails.data &&
              !occupationDetails.error && (
                <CareerBanner occupationDetails={occupationDetails} />
              )}
          </RequestErrorLoader>
        </BackgroundPaper>
      </ContentBox>

      <ContentBox>
        <RequestErrorLoader
          emptyMsg={noDataAvailableTxt}
          body={{
            data: occupationDetails.data,
            error: occupationDetails.error,
          }}
          title={loadingOccupationDetailsTxt}>
          {occupationSelected &&
            occupationDetails &&
            occupationDetails.data &&
            !occupationDetails.error && (
              <div className='pathways-tabs contentContainer occupation_tabs pt-3 mx-auto'>
                <CareerOverview
                  occupationDetails={occupationDetails}
                  location={location}
                  setLocation={setLocation}
                />
              </div>
            )}
        </RequestErrorLoader>
      </ContentBox>
    </Stack>
  );
};

export default Careers;

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {useForm} from 'react-hook-form';
import {useEffect, useImperativeHandle} from 'react';
import {createField} from 'mui-core/Form';
import {Button, Grid, TextEditor} from 'mui-core';
import resumeBuilderData from 'data/resumeBuilder.json';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {datePickerSlotProp} from '../../sharedComponents';
import {Box, Stack, Typography, OutlinedInput} from '@mui/material';

const {
  stepsMeta: {awards: {formData = {}} = {}},
} = resumeBuilderData || {};
const {
  addBtnTxt,
  AddAwardTxt,
  orgName = {},
  optionalText,
  cancelBtnTxt,
  itemName = {},
  dateRecieved = {},
  descriptionObj = {},
  addAdditionalDetailsText,
} = formData || {};

const AddAwards = ({
  edit,
  formRef,
  onSubmit,
  onCancel,
  firstTime,
  data = {},
  getPayload,
}) => {
  const form = useForm({mode: 'all'});
  const {item_title, description, date_received, issuing_organization} = data;

  const {
    watch,
    trigger,
    getValues,
    clearErrors,
    handleSubmit,
    formState: {errors, isValid},
  } = form;

  useEffect(() => {
    watch(values => {
      const isFormEmpty = Object.values(values).every(value =>
        Array.isArray(value) ? value.length === 0 : !value,
      );

      if (isFormEmpty) {
        setTimeout(clearErrors);
      }
    });
  }, [watch]);

  const onFinish = (newData, e) => {
    if (onSubmit && isEmpty(errors)) {
      const updatedData = {...data, ...newData};
      if (updatedData.date_received) {
        const {years, months} = moment(updatedData.date_received).toObject();
        updatedData.date_received = moment()
          .date(5)
          .year(years)
          .month(months)
          .toISOString();
        onSubmit(updatedData);
      }
    }
  };

  useImperativeHandle(formRef, () => ({
    validateForm() {
      let isFormValid = true;
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      if (isFormFilled) {
        trigger();
        isFormValid = isValid;
      }
      return isFormValid;
    },
    getFormData() {
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      return isFormFilled ? getPayload({...data, ...getValues()}) : null;
    },
  }));

  return (
    <Stack>
      {!edit && !firstTime && (
        <Typography
          mt={1}
          variant='h5'
          color='text.primary'
          title={AddAwardTxt}>
          {AddAwardTxt}
        </Typography>
      )}
      <Box mx='auto' mt={1} sx={{width: '100%'}}>
        <form onSubmit={handleSubmit(onFinish)}>
          <Grid
            container
            spacing={4}
            display='flex'
            flexDirection='column'
            justifyContent='center'>
            <Grid item xs={12}>
              {createField('item_title', {
                form,
                size: 'small',
                label: itemName.label,
                defaultValue: item_title || '',
                rules: {
                  required: [true, itemName.requiredMsg],
                  maxLength: [60, itemName.maxLengthMsg],
                },
              })(
                <OutlinedInput
                  variant='outlined'
                  id='award-title/name'
                  label={itemName.label}
                  placeholder={itemName.placeholder}
                />,
              )}
            </Grid>
            <Grid item xs={12}>
              {createField('issuing_organization', {
                form,
                size: 'small',
                label: orgName.label,
                defaultValue: issuing_organization || '',
              })(
                <OutlinedInput
                  variant='outlined'
                  label={orgName.label}
                  placeholder={orgName.label}
                  id='award-issuing-organization'
                />,
              )}
            </Grid>
            <Grid item xs={5}>
              {createField('date_received', {
                form,
                rules: {
                  required: [true, dateRecieved.requiredMsg],
                },
                defaultValue: date_received ? moment(date_received) : null,
              })(
                <DatePicker
                  views={['year', 'month']}
                  label={dateRecieved.label}
                  showDaysOutsideCurrentMonth
                  maxDate={moment(new Date())}
                  slotProps={datePickerSlotProp}
                />,
              )}
            </Grid>
            <Grid item xs={12} pt={1}>
              <Stack
                spacing={1}
                direction='row'
                alignItems='center'
                justifyContent='start'>
                <Typography
                  variant='h5'
                  color='text.primary'
                  title={addAdditionalDetailsText}>
                  {addAdditionalDetailsText}
                </Typography>
                <Typography
                  variant='body1'
                  title={optionalText}
                  color='text.primary'>
                  {`(${optionalText})`}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {createField('description', {
                form,
                defaultValue: description || '',
              })(<TextEditor label={descriptionObj.label} />)}
            </Grid>
            <Grid item justifyContent='center' display='flex'>
              {!firstTime && (
                <Button
                  variant='outlined'
                  onClick={onCancel}
                  sx={{mr: 2, minWidth: '100px'}}>
                  {cancelBtnTxt}
                </Button>
              )}
              <Button
                type='submit'
                formNoValidate
                variant='contained'
                sx={{minWidth: '100px'}}>
                {addBtnTxt}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Stack>
  );
};

export default AddAwards;

import {Suspense} from 'react';
import Routes from 'app/Routes';
import {useRouteMatch} from 'react-router-dom';
import {ScrollReset, RequestErrorLoader} from 'mui-core';

const SubRouter = ({modules, defaultRedirection}) => {
  const {path, url} = useRouteMatch();
  return (
    <Suspense
      fallback={<RequestErrorLoader hideEmpty body={{request: true}} />}>
      <ScrollReset>
        <Routes
          url={url}
          routeData={modules}
          defaultRedirection={defaultRedirection}
        />
      </ScrollReset>
    </Suspense>
  );
};
export default SubRouter;

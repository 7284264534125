import {WarnModal} from 'core/components';
import {alertYellow, fireworksIcon, successTick} from 'assets/images';

export {WarnModal};

export const signInModalProps = {
  title: 'Please login/Signup before applying to job!',
  icon: alertYellow,
  description: [
    `It seems like you have not signed in. Please log in to apply for this job.`,
  ],
  buttonText: 'Log in',
};

export const signInSaveModalProps = {
  title: 'Please login/Signup before saving the job!',
  icon: alertYellow,
  description: [
    `It seems like you have not signed in. Please log in to save this job.`,
  ],
  buttonText: 'Log in',
};

export const applayConfirmModalProps = {
  title: 'Congratulations! You’re on your way!',
  icon: fireworksIcon,
  description: [
    `By clicking “apply” your profile will be visible to this employer and you will be added to their list of applicants.`,
  ],
  buttonText: 'Apply',
};

export const saveConfirmModalProps = {
  title: 'Are you sure want to save this job?',
  icon: alertYellow,
  buttonText: 'Save',
};

export const applyJobSuccessModalProps = ({job_title, company_name}) => {
  return {
    title: `Job Application Submitted!`,
    icon: successTick,
    description: [
      `You have successfully applied to the <span class="text-pink">${job_title}</span> job at <span class="text-pink">${company_name}</span>.`,
      `You can view the list of the jobs that you have applied to in your profile.`,
      `Be sure to check your email for any job application updates. Good luck!`,
    ],
    buttonText: 'View Applied Jobs',
  };
};

export const saveJobSuccessModalProps = ({job_title, company_name}) => {
  return {
    title: `Job Application Saved!`,
    icon: successTick,
    description: [
      `You have successfully saved to the <span class="text-pink">${job_title}</span> job at <span class="text-pink">${company_name}</span>.`,
      `You can view the list of the jobs that you have saved to in your profile.`,
    ],
    buttonText: 'View saved Jobs',
  };
};

export const removeJobModalProps = {
  title: 'Remove Job Application',
  description: [
    `Are you sure you want to remove yourself from this application? `,
    `You will be removed from this jobs applicant list.`,
  ],
  okText: 'Yes',
  cancelText: ' No',
};

export const removeSavedJobModalProps = {
  title: 'Remove Saved Job',
  description: [`Are you sure you want to remove this job?`],
  okText: 'Yes',
  cancelText: ' No',
};

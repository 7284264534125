import React from 'react';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {themeColorMode} from 'mui-core';
import {getCompleteDate} from 'core/utils';
import {Visibility} from '@mui/icons-material';
import SettingsMonths from 'data/settings.json';
import {lighten, styled} from '@mui/material/styles';
import resumeBuilderData from 'data/resumeBuilder.json';
import {awardTypesSelector} from 'redux/modules/profile';
import {Box, Stack, Typography as MUITypography} from '@mui/material';

import {
  sortBydateReceived,
  sortEducationBydate,
  sortVolunteerExperienceByDate,
  sortProfessionalExperienceBydate,
} from './utils';

const {
  previewMeta: {
    title,
    professionalSummary,
    education,
    professionalExperience,
    skills: skillsTitle,
    licenses,
    awards,
    volunteerExp,
    publications,
  },
} = resumeBuilderData;

const Typography = styled(MUITypography)(({theme}) => ({
  fontFamily: 'Arial',
  color: theme.palette.mode === 'light' ? 'black' : 'white',
}));
const ResumeHeader = styled(Typography)(({theme}) => ({
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '18px',
}));
const ResumeBody = styled(Typography)(({theme}) => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '18px',
}));
const SkillsWrapper = styled(Box)(({theme, list}) => ({
  '& ul': {
    margin: '0px',
    '& li': {
      float: 'left',
      fontSize: '12px',
      listStyle: 'disc',
      textAlign: 'left',
      marginRight: '8px',
      color: theme.palette.mode === 'light' ? 'black' : 'white',
      width: list.length > 14 ? '30%' : list.length > 7 ? '48%' : '100%',
    },
  },
}));
const StyledDescription = styled(Typography)(({theme}) => ({
  '& p': {
    marginLeft: 12,
    marginBottom: 0,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
  },
  '& ul, ol': {
    fontSize: '12px',
    marginBottom: '0 !important',
    paddingLeft: '32px !important',
    '& li': {
      color: theme.palette.mode === 'light' ? 'black' : 'white',
      '& p': {
        margin: 0,
        color: theme.palette.mode === 'light' ? 'black' : 'white',
      },
    },
  },
}));
const StyledPreviewButton = styled(Box)(({theme, purpleHeader}) => ({
  height: '58px',
  display: 'flex',
  marginTop: '-32px',
  padding: '9px 16px',
  alignItems: 'center',
  justifyContent: 'center',
  width: `calc(100% + 64px)`,
  backgroundColor: purpleHeader
    ? lighten(theme.palette.primary.dark, 0.5)
    : themeColorMode(theme, theme.palette.grey[200], theme.palette.grey[600]),
}));

const DataPreview = ({
  data: {
    city,
    state,
    email,
    zipcode,
    last_name,
    first_name,
    phone_number,
    summary = '',
    street_address,
    work_exp = [],
    credentials = [],
    volunteer_exp = [],
    awards_publications = [],
    show_skills,
    skills,
    show_awards,
    show_license,
    show_education,
    show_publication,
    show_volunteer_experience,
    show_professional_summary,
    show_professional_experience,
  },
  heading,
  isMobile,
  purpleHeader,
  isShareProfile,
  disableScroll = false,
  disableEyeIcon = false,
}) => {
  const address = street_address
    ? `${street_address}, ${city}, ${state} ${zipcode}`
    : '';
  // const {data: awardTypes = []} = useSelector(awardTypesSelector);
  // const awardItem = awardTypes?.find(i => i.item_type === 'Award');
  // const publicationItem = awardTypes?.find(i => i.item_type === 'Publication');
  const educationList =
    credentials.filter(item => item.credential_type === 'education') || [];
  const licensesList =
    credentials.filter(item => item.credential_type === 'license') || [];
  // const internShipList =
  //   credentials?.filter(item => item.credential_type === 'internship') || [];
  // const apprenticeshipList =
  //   credentials?.filter(item => item.credential_type === 'apprenticeship') ||
  //   [];
  const awardsList =
    awards_publications?.filter(item => item.item_type_name === 'Award') || [];
  const publicationsList =
    awards_publications?.filter(
      item => item.item_type_name === 'Publication',
    ) || [];

  const previewDateFormat = date => {
    return `${SettingsMonths.months[getCompleteDate(date).month - 1]} ${
      getCompleteDate(date).year
    }`;
  };

  let skillsData =
    skills && Array.isArray(skills) ? skills.map(item => item.skill) : [];
  if (work_exp && Array.isArray(work_exp) && work_exp.length > 0) {
    work_exp.forEach(item => {
      const {skills} = item;
      if (Array.isArray(skills) && skills.length > 0) {
        skills.forEach(sk => {
          if (skillsData.indexOf(sk.skill) === -1) skillsData.push(sk.skill);
        });
      }
    });
  }

  // const renderInternshipApprenticeshipSection = (list, label) => {
  //   if (list?.length > 0) {
  //     return (
  //       <>
  //         <ResumeHeader mt={2} >
  //           {label}
  //         </ResumeHeader>
  //         {list?.map(
  //           ({
  //             city,
  //             title,
  //             state,
  //             org_name,
  //             is_present,
  //             description,
  //             display_order,
  //             school_end_date,
  //             school_start_date,
  //           }) => {
  //             let internshipApprenticeshipDuration = '';
  //             if (school_start_date && (school_end_date || is_present)) {
  //               internshipApprenticeshipDuration = `${previewDateFormat(
  //                 school_start_date,
  //               )} - ${
  //                 is_present ? 'Present' : previewDateFormat(school_end_date)
  //               }`;
  //             }
  //             return (
  //               <Box mt={display_order === 1 ? 0 : 2} key={display_order}>
  //                 <Stack direction='row' justifyContent='space-between'>
  //                   <Typography variant='body2' >
  //                     {`${org_name}
  //                 ${` - ${city || ''}, ${state || ''}`}
  //                 ${title ? ` - ${title}` : ''}`}
  //                   </Typography>
  //                   <ResumeHeader >
  //                     {internshipApprenticeshipDuration
  //                       ? internshipApprenticeshipDuration
  //                       : ''}
  //                   </ResumeHeader>
  //                 </Stack>
  //                 {description ? (
  //                   <StyledDescription
  //
  //                     dangerouslySetInnerHTML={{__html: description}}
  //                   />
  //                 ) : (
  //                   <></>
  //                 )}
  //               </Box>
  //             );
  //           },
  //         )}
  //       </>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  const formattedPhoneNumber =
    phone_number &&
    `(${phone_number
      .toString()
      .substring(0, 3)}) ${phone_number
      .toString()
      .substring(3, 6)}-${phone_number.toString().substring(6)}`;
  const previewHeadLineText = isShareProfile ? isShareProfile : !isMobile;
  return (
    <Stack sx={{minHeight: '70%', height: '100%'}}>
      {previewHeadLineText && (
        <Stack alignItems='center'>
          <StyledPreviewButton
            rounded
            size='small'
            variant='outlined'
            purpleHeader={purpleHeader}
            endIcon={<Visibility fontSize={'large'} />}>
            <MUITypography variant='h6'>{heading || title}</MUITypography>
            {!disableEyeIcon && (
              <Visibility
                fontSize={'small'}
                sx={{marginLeft: '8px', marginBottom: '2px'}}
              />
            )}
          </StyledPreviewButton>
        </Stack>
      )}
      {/* Basic Information */}
      <Stack
        alignItems='center'
        gap={{xs: 0.5, md: 1}}
        sx={{my: {xs: 1, md: 2}}}>
        <Typography fontWeight='600' variant='h5' color='text.primary'>
          {`${first_name} ${last_name}`}
        </Typography>
        <ResumeBody textAlign='center'>
          {[formattedPhoneNumber, address, email].map((item, index, arr) => {
            return item ? `${item} ${arr.length - 1 > index ? ' | ' : ''}` : '';
          })}
        </ResumeBody>
      </Stack>
      <Box
        sx={
          disableScroll
            ? {paddingRight: '20px', paddingBottom: '20px'}
            : {
                overflow: 'auto',
                paddingRight: '20px',
              }
        }>
        {/* Professional Summary */}
        {show_professional_summary && summary && (
          <Stack sx={{mt: {xs: 2, md: 0}}}>
            <ResumeHeader mb={2}>{professionalSummary}</ResumeHeader>
            <ResumeBody>{summary || ''}</ResumeBody>
          </Stack>
        )}
        <Stack>
          {/* Education */}
          {show_education &&
            educationList &&
            Array.isArray(educationList) &&
            educationList?.length > 0 && (
              <>
                <ResumeHeader my={2}>{education}</ResumeHeader>
                {sortEducationBydate(educationList).map(
                  (
                    {
                      org_name,
                      award_type,
                      description,
                      area_of_focus,
                      display_order,
                      school_end_date,
                    },
                    index,
                  ) => {
                    let educationDuration = '';
                    if (school_end_date) {
                      educationDuration = previewDateFormat(school_end_date);
                    }
                    const isFuture = moment(school_end_date).isAfter(
                      new Date(),
                    );
                    return (
                      <Box mt={index === 0 ? 0 : 2} key={display_order}>
                        <ResumeBody>
                          {`${org_name}
                      ${award_type ? ` - ${award_type}` : ''}
                      ${area_of_focus ? ` / ${area_of_focus}` : ''} ${
                            educationDuration
                              ? ` - (${
                                  isFuture ? 'Expected' : 'Completed'
                                }: ${educationDuration})`
                              : ''
                          }`}
                        </ResumeBody>
                        {description && (
                          <StyledDescription
                            mt={2}
                            dangerouslySetInnerHTML={{__html: description}}
                          />
                        )}
                      </Box>
                    );
                  },
                )}
              </>
            )}
          {/* Professional Experience */}
          {show_professional_experience &&
            work_exp &&
            Array.isArray(work_exp) &&
            work_exp?.length > 0 && (
              <>
                <ResumeHeader my={2}>{professionalExperience}</ResumeHeader>
                {sortProfessionalExperienceBydate(work_exp).map(
                  (
                    {
                      city,
                      state,
                      end_date,
                      job_title,
                      start_date,
                      is_present,
                      description,
                      company_name,
                      display_order,
                    },
                    index,
                  ) => {
                    let empDuration = '';
                    if (start_date && (end_date || is_present)) {
                      empDuration = `${previewDateFormat(start_date)} - ${
                        is_present ? 'Present' : previewDateFormat(end_date)
                      }`;
                    }
                    let empLocation = '';
                    if (city && state) {
                      empLocation = `${city}, ${state}`;
                    }
                    return (
                      <Box mt={index === 0 ? 0 : 2} key={display_order}>
                        <Stack
                          width='100%'
                          direction='row'
                          justifyContent='space-between'>
                          <ResumeHeader width='72%' fontWeight={700}>
                            {`${company_name}
                      ${empLocation ? ` - ${empLocation}` : ''}`}
                          </ResumeHeader>
                          <ResumeHeader color='text.primary'>
                            {empDuration ? empDuration : ''}
                          </ResumeHeader>
                        </Stack>
                        <ResumeHeader
                          fontWeight={700}>{`${job_title}`}</ResumeHeader>
                        {description && (
                          <StyledDescription
                            mt={2}
                            color='text.primary'
                            dangerouslySetInnerHTML={{__html: description}}
                          />
                        )}
                      </Box>
                    );
                  },
                )}
              </>
            )}
          {show_skills &&
            skillsData &&
            Array.isArray(skillsData) &&
            skillsData?.length > 0 && (
              <>
                <ResumeHeader my={2}>{skillsTitle}</ResumeHeader>
                <StyledDescription>
                  <SkillsWrapper list={skillsData}>
                    <ul>
                      {skillsData.map(skill => (
                        <li>
                          <ResumeBody>{skill}</ResumeBody>
                        </li>
                      ))}
                    </ul>
                  </SkillsWrapper>
                </StyledDescription>
              </>
            )}
          {/* Licenses & Certifications */}
          {show_license &&
            licensesList &&
            Array.isArray(licensesList) &&
            licensesList?.length > 0 && (
              <>
                <ResumeHeader my={2}>{licenses}</ResumeHeader>
                {sortBydateReceived(licensesList).map(
                  (
                    {
                      org_name,
                      description,
                      program_name,
                      date_received,
                      display_order,
                    },
                    index,
                  ) => {
                    return (
                      <Box mt={index === 0 ? 0 : 2} key={display_order}>
                        <ResumeBody>
                          {`${program_name}
                      ${org_name ? ` - ${org_name}` : ''}
                      ${
                        date_received
                          ? ` - (Issued: ${previewDateFormat(date_received)})`
                          : ''
                      }`}
                        </ResumeBody>
                        {description && (
                          <StyledDescription
                            mt={2}
                            color='text.primary'
                            dangerouslySetInnerHTML={{__html: description}}
                          />
                        )}
                      </Box>
                    );
                  },
                )}
              </>
            )}
          {/* Awards */}
          {show_awards &&
            awardsList &&
            Array.isArray(awardsList) &&
            awardsList?.length > 0 && (
              <>
                <ResumeHeader my={2}>{awards}</ResumeHeader>
                {sortBydateReceived(awardsList).map(
                  (
                    {
                      item_title,
                      description,
                      date_received,
                      display_order,
                      issuing_organization,
                    },
                    index,
                  ) => {
                    return (
                      <Box mt={index === 0 ? 0 : 2} key={display_order}>
                        <ResumeBody>
                          {`${item_title}
                      ${
                        issuing_organization ? ` - ${issuing_organization}` : ''
                      }
                      ${
                        date_received
                          ? ` - (Issued: ${previewDateFormat(date_received)})`
                          : ''
                      }`}
                        </ResumeBody>
                        {description && (
                          <StyledDescription
                            mt={2}
                            color='text.primary'
                            dangerouslySetInnerHTML={{__html: description}}
                          />
                        )}
                      </Box>
                    );
                  },
                )}
              </>
            )}
          {/* Internship And Apprenticeship */}
          {/* {renderInternshipApprenticeshipSection(
            internShipList,
            'Internship Experience ',
          )}
          {renderInternshipApprenticeshipSection(
            apprenticeshipList,
            'Apprenticeship',
          )} */}
          {/* Volunteer Experience */}
          {show_volunteer_experience &&
            volunteer_exp &&
            Array.isArray(volunteer_exp) &&
            volunteer_exp?.length > 0 && (
              <>
                <ResumeHeader my={2}>{volunteerExp}</ResumeHeader>
                {sortVolunteerExperienceByDate(volunteer_exp).map(
                  (
                    {
                      name,
                      role,
                      end_date,
                      start_date,
                      description,
                      display_order,
                    },
                    index,
                  ) => {
                    let empDuration = '';
                    if (start_date && end_date) {
                      empDuration = `${previewDateFormat(
                        start_date,
                      )} - ${previewDateFormat(end_date)}`;
                    }
                    return (
                      <Box mt={index === 0 ? 0 : 2} key={display_order}>
                        <ResumeBody>
                          {`${name} ${role ? ` - ${role}` : ''} ${
                            empDuration ? ` - (${empDuration})` : ''
                          }`}
                        </ResumeBody>
                        {description && (
                          <StyledDescription
                            mt={2}
                            dangerouslySetInnerHTML={{__html: description}}
                          />
                        )}
                      </Box>
                    );
                  },
                )}
              </>
            )}
          {/* Publications */}
          {show_publication &&
            publicationsList &&
            Array.isArray(publicationsList) &&
            publicationsList?.length > 0 && (
              <>
                <ResumeHeader my={2}>{publications}</ResumeHeader>
                {sortBydateReceived(publicationsList)?.map(
                  (
                    {
                      role,
                      item_title,
                      description,
                      date_received,
                      display_order,
                    },
                    index,
                  ) => {
                    let publishDuration = '';
                    if (date_received) {
                      publishDuration = previewDateFormat(date_received);
                    }
                    return (
                      <Box mt={index === 0 ? 0 : 2} key={display_order}>
                        <ResumeBody>
                          {`${item_title}
                        ${role ? ` - ${role}` : ''}
                        ${
                          publishDuration
                            ? ` - (Published: ${publishDuration})`
                            : ''
                        }`}
                        </ResumeBody>
                        {description && (
                          <StyledDescription
                            mt={2}
                            dangerouslySetInnerHTML={{__html: description}}
                          />
                        )}
                      </Box>
                    );
                  },
                )}
              </>
            )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default DataPreview;

import React from 'react';

import {Image, Text, View, StyleSheet} from '@react-pdf/renderer';

import {getLogo} from 'core/utils';

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    backgroundColor: '#F9F8FF',
    borderBottomWidth: 0,
    borderBottomStyle: 'solid',
    borderBottomColor: '#DDDDDD',
    minHeight: '120px',
    width: '100%',
    padding: 20,
  },
  leftColumn: {
    width: '15%',
    height: 80,
    marginRight: 10,
  },
  rightColumn: {
    paddingTop: 10,
    height: 80,
    width: '85%',
  },
  image: {
    marginBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DDDDDD',
    borderRadius: 50,
    width: '80',
    height: '80',
    padding: 8,
  },
  name: {
    color: '#1B2438',
    fontSize: 24,
    //fontFamily: 'Source Sans Pro',
  },
  description: {
    flexDirection: 'row',
    marginTop: 5,
  },
  label: {
    color: '#A0A4B5',
    fontSize: 10,
    marginRight: 5,
  },
  value: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    color: '#DE4279',
    fontWeight: 'bold',
    fontSize: 10,
    //fontFamily: 'Source Sans Pro Semibold',
  },
});

//const imageURL = 'http://localhost:3000/college.jpg';
//+ '?noCache=' + Math.random().toString()
export default ({user, path}) => {
  return (
    <View style={styles.header}>
      <View style={styles.leftColumn}>
        <Image
          //src={path && path.institute_details && path.institute_details.logo}
          source={{
            uri: `${getLogo(
              path?.institute_details?.logo_cloudinary,
              'png',
            )}?noCache=${Math.random().toString()}`,
            method: 'GET',
            header: {
              'Access-Control-Allow-Origin': '*',
              'Cache-Control': 'no-cache',
            },
          }}
          style={styles.image}
        />
      </View>
      <View style={styles.rightColumn}>
        <Text style={styles.name}>
          {user && `${user.first_name} ${user.last_name}`}
        </Text>
        <View style={styles.description}>
          <View style={styles.label}>
            <Text>Path Name:</Text>
          </View>
          <View style={[styles.value, {width: '380px'}]}>
            <Text>{path && path.title}</Text>
          </View>
        </View>
        <View style={styles.description}>
          <View style={styles.label}>
            <Text>College:</Text>
          </View>
          <View style={[styles.value, {width: '390px'}]}>
            <Text>
              {path && path.institute_details && path.institute_details.name}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

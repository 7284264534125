import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {Download} from '@mui/icons-material';
import {downloadResume} from 'redux/modules/resumes';
import {downloadErrorMessage} from 'data/resume.json';
import DataPreview from 'app/modules/resume-builder/sharedComponents/Preview/DataPreview';
import {Stack, useTheme, IconButton, useMediaQuery, Box} from '@mui/material';

const ResumePreview = ({data}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleResumeDownload = (resumeId, resumeName) => {
    const onSuccess = response => {
      const blob = new Blob([response], {
        type: 'application/pdf',
        encoding: 'UTF-8',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = resumeName;
      link.click();
    };

    const onError = () => {
      enqueueSnackbar(downloadErrorMessage, {
        variant: 'error',
        anchorOrigin: {horizontal: 'center', vertical: 'top'},
      });
    };
    dispatch(downloadResume(resumeId, onSuccess, onError));
  };

  return (
    <Box
      sx={{
        height: '100%',
        borderRadius: '4px',
        position: 'relative',
      }}>
      <Stack
        sx={{
          top: -20,
          right: 0,
          zIndex: 9,
          position: 'absolute',
        }}>
        <IconButton
          size='medium'
          title='Download'
          aria-label='download'
          color='text.primary'
          onClick={() =>
            handleResumeDownload(data.resume_uuid, data.resume_name)
          }>
          <Download fontSize='medium' />
        </IconButton>
      </Stack>
      <DataPreview
        data={data}
        disableScroll
        isShareProfile
        disableEyeIcon
        heading='Resume'
        isMobile={isMobile}
      />
    </Box>
  );
};

export default ResumePreview;

import pdflogo from './logo_GoEducate.svg';
import logo from './logo_Goeducatewhite.svg';
import pdfaward from './award.png';
import pdfarrow from './arrow.png';
import icontrophy from './trophy.svg';
import iconarrowPointer from './arrow.png';
import iconPlay from './icons/videoicon.svg';
import GoMeterAsset from './GoMeterAsset.png';
import breadcrumbArrow from './icons/breadcrumbarrow.svg';
import emailSuccess from './icons/greentick_success.svg';
import talentIcons from './banner/talentexchangeimage.png';

import logo_amazonfeat from './banner/hm_amazonfeat.jpg';
import logo_gefeat from './banner/hm_gefeat.jpg';
import logo_googlefeat from './banner/hm_googlefeat.jpg';
import logo_hyattfeat from './banner/hm_hyattfeat.jpg';
import logo_metafeat from './banner/hm_metafeat.jpg';
import logo_shellfeat from './banner/hm_shellfeat.jpg';
import logo_tysonfeat from './banner/hm_tysonfeat.jpg';
import logo_wellsfargofeat from './banner/hm_wellsfargofeat.jpg';
import talentimages from './banner/talentimages.jpg';

import logo_apple_svg from './banner/apple_svg_logo.svg';
import logo_amazon_svg from './banner/amazon_svg_logo.svg';
import logo_boeing_svg from './banner/boeing_svg_logo.svg';
import logo_google_svg from './banner/Google_svg_logo.svg';
import logo_general_svg from './banner/General_svg_logo.svg';
import logo_Hyatt_svg from './banner/Hyatt_svg_logo.svg';
import logo_meta_svg from './banner/Meta_svg_logo.svg';
import logo_microsoft_svg from './banner/Microsoft_logo.svg';
import logo_tesla_svg from './banner/Tesla_logo.svg';
import logo_tyson_svg from './banner/Tyson_svg_logo.svg';
import logo_wells_svg from './banner/Wells_logo.svg';
import shadowImage from '../images/Shadow.png';
import GradientGoeducateLogo from './goeducate-gradient.png';
import verifiedBlueCheckmark from '../../assets/images/icons/verified-checkmark.svg';

const BASE_URL =
  'https://res.cloudinary.com/goeducate-inc/image/upload/q_auto/gps-graphics';

const VIDEO_BASEURL =
  'https://res.cloudinary.com/goeducate-inc/video/upload/q_auto/gps-graphics';

// LOGO
const appLogo = `${BASE_URL}/logo/logo_nanamg.svg`;
const appLogoWhite = `${BASE_URL}/logo/logo_white.svg`;
const goLogoWhite = `${BASE_URL}/icons/GO-WHITE-LOGO_dcxycz.png`;

const textloading = `${BASE_URL}/text_loading_lro0pg.gif`;
const avatar = `${BASE_URL}/avatarwhite_hoy93g.png`;
const avatarGray = `${BASE_URL}/avatarblack_tcinoc.png`;
const defaultbannerjob = `${BASE_URL}/banner/defaultbannerjob_mtukkl.jpg`;
const defaulGoBanner = `${BASE_URL}/banner/GO_Profile_Banner_oquifz.png`;

const iconbellGray = `${BASE_URL}/icons/notificationbellblackgrey_omsf35.svg`;
const iconbellWhite = `${BASE_URL}/icons/notificationbell_midasx.svg`;
const iconremove = `${BASE_URL}/icons/icon_remove_osm5vj.svg`;
const iconmail = `${BASE_URL}/icons/mailicon_ixuhrn.svg`;
const iconplus = `${BASE_URL}/icons/icon_plus_jlgkpv.svg`;
const explorePathSearch = `${BASE_URL}/icons/explorepathicon_bttj9o.svg`;

const heartPurpleBorder = `${BASE_URL}/icons/heartpurpleborder_fnjirz.svg`;
const heartWhite = `${BASE_URL}/icons/heartwhite_l0fpoo.svg`;
const searchPurple = `${BASE_URL}/icons/searchpurple_czfywo.svg`;
const arrowdown = `${BASE_URL}/icons/whitearrow_zeb9nc.svg`;
const skillCertify = `${BASE_URL}/icons/certificatetickicon_qfufkb.svg`;
const blueStar = `${BASE_URL}/icons/bluestar_od8wll.svg`;
const awardtype = `${BASE_URL}/icons/awardtypeicon_rqdghf.svg`;
const awardTypeWhite = `${BASE_URL}/icons/awardwhiteicon_qfx9vr.svg`;
const locationBlue = `${BASE_URL}/locationblueico_d7ztcr.svg`;

const defaultoccupation = `${BASE_URL}/occupationdefaultimg_vvro4e.jpg`;
const greenHeart = `${BASE_URL}/icons/greenheart_usza3k.svg`;
const editpurple = `${BASE_URL}/icons/editpurple_rkziek.svg`;
const arrowpurple = `${BASE_URL}/icons/arrowpurple_ypgdba.svg`;

const compare_pathIcon = `${BASE_URL}/icons/compare_dash_pfxniu.svg`;
const photoCam = `${BASE_URL}/icons/photoicon_iyhpfj.svg`;
const deleteIcon = `${BASE_URL}/icons/deleteicond_battxh.svg`;
const whitetick = `${BASE_URL}/icons/whitetick_gjwybe.svg`;
const clockgrey = `${BASE_URL}/icons/clockgrey_yz2pbx.svg`;

const mobileIcon = `${BASE_URL}/icons/mobileicon_qkozvo.svg`;
const successTick = `${BASE_URL}/icons/successtick_xjwolo.svg`;
const warningIcon = `${BASE_URL}/icons/warningicon_kpgjsn.svg`;
const maplocation = `${BASE_URL}/icons/locationgrey_okew9a.svg`;

const purpleFriendsIcon = `${BASE_URL}/icons/friends_icon_ji68up.svg`;
const purpleLocationIcon = `${BASE_URL}/icons/location_pruplebg_icon_jyv1ov.svg`;
const purpleDateCalenderIcon = `${BASE_URL}/icons/calendar_icon_pvnldr.svg`;

const noCollageLogo = `${BASE_URL}/defaultlogoimage_jn0gk9.svg`;
const noPathwayBanner = `${BASE_URL}/no_pathway_banner_siitlk.jpg`;
const noSearchResults = `${BASE_URL}/icons/nosearchicon_gc9vgt.svg`;
const noResultsIcon = `${BASE_URL}/icons/NoSearchResultIconHD_pkkjd2.png`;
const noSurveyIcon = `${BASE_URL}/icons/nosurveyicon_tmrryo.svg`;
const noBpIcon = `${BASE_URL}/icons/nooccupationicon_i8mdse.svg`;

const carrerOneStop = `${BASE_URL}/careerOneStop_blufee.svg`;
const brightIcon = `${BASE_URL}/icons/bright_sun_med_amu8fs.png`;
const avgIcon = `${BASE_URL}/icons/avg_small_v21dn2.png`;
const belowIcon = `${BASE_URL}/icons/below_small_p06ooq.png`;
const hotTechnology = `${BASE_URL}/icons/hotTechnology_fdzrfq.svg`;
const onetinit = `${BASE_URL}/icons/onet-in-it_nfsfuy.svg`;
const outlookbright = `${BASE_URL}/icons/outlook_bright_huge_npdple.png`;
const outlookaverage = `${BASE_URL}/icons/outlook_avg_huge_ryumu8.png`;
const outlookbelow = `${BASE_URL}/icons/outlook_below_huge_gsejya.png`;
const statemap = `${BASE_URL}/icons/statemap_h8jte2.svg`;
const localsalaryinfo = `${BASE_URL}/icons/localsalaryinfo_rpgifm.svg`;
const haticon = `${BASE_URL}/icons/haticon_vqimwg.svg`;
const salarychart = `${BASE_URL}/salary_chart_ftv1oq.png`;
const defaultbannerprofile = `${BASE_URL}/defaultbannerprofile_bsuyvy.jpg`;
const defaultExternalJobBanner = `${BASE_URL}/banner/externalJobsBanner_wqeza2.jpg`;
const infoIcon = `${BASE_URL}/icons/icon_info_esxjeu.svg`;
const InfoIconwhite = `${BASE_URL}/icons/icon_info_white_kxizw6.svg`;

const carouselLeftArrow = `${BASE_URL}/icons/arrowleft_w0grga.svg`;
const carouselRightArrow = `${BASE_URL}/icons/arrowright_zv59ya.svg`;
const carouselLeftArrowDark = `${BASE_URL}/icons/arrow_left_dark_kisk6x.svg`;
const carouselRightArrowDark = `${BASE_URL}/icons/arrow_right_dark_ze3exv.svg`;

const dash_home = `${BASE_URL}/icons/homegrey_hoxekv.svg`;
const dash_profile = `${BASE_URL}/icons/profilegrey_nnvpl4.svg`;
const dash_education = `${BASE_URL}/icons/educationgrey_xbyaid.svg`;
const dash_workforce = `${BASE_URL}/icons/workforcegrey_oqyma8.svg`;
const dash_skill = `${BASE_URL}/icons/skillsgrey_un1pcb.svg`;
const dash_job = `${BASE_URL}/icons/jobgrey_dk8wbl.svg`;
const dash_event = `${BASE_URL}/icons/eventsgrey_at83k7.svg`;

const nlx_logo = `${BASE_URL}/nlx_logo_boukce.png`;
const honest_logo = `${BASE_URL}/Honest_Jobs_-_Logo_euddxk.png`;

const facebook = `${BASE_URL}/icons/facebook_a7gfpm.svg`;
const black_facebook = `${BASE_URL}/icons/FacebookBlack_xyaxmm.svg`;

const instagram = `${BASE_URL}/icons/instagram_mdvl3g.svg`;
const x_logo_black = `${BASE_URL}/icons/X_logo_black_rawgdj.svg`;
const black_instagram = `${BASE_URL}/icons/insta_logo_black_q1wwc2.svg`;
const color_instagram = `${BASE_URL}/icons/insta__logo_color_kczxcz.svg`;
const black_medium = `${BASE_URL}/icons/medium_black_ihauug.svg`;
const color_medium = `${BASE_URL}/icons/medium_color_pktbyd.svg`;
const black_tiktok = `${BASE_URL}/icons/tiktok_black_npgxow.svg`;
const color_tiktok = `${BASE_URL}/icons/tiktok_color_ic1tgv.svg`;
const linkedin = `${BASE_URL}/icons/linkedin_rnchgt.svg`;
const black_linkedin = `${BASE_URL}/icons/LinkedInBlack_wjlsjf.svg`;
const white_facebook = `${BASE_URL}/icons/Facebookwhite_qwlfn4.svg`;
const white_twitter = `${BASE_URL}/icons/twitterwhite_c60onw.svg`;
const black_twitter = `${BASE_URL}/icons/TwitterBlack_vrdtfw.svg`;
const white_linkedin = `${BASE_URL}/icons/LinkedInwhite_epbzmp.svg`;
const goEducateLogoWhite = `${BASE_URL}/logo_Goeducatewhite_v9xu3k.svg`;
const goEducateLogo = `${BASE_URL}/goeducate_original_logo_lakti6.svg`;
const goEducateLogoLight = `${BASE_URL}/GO_LOGO_1222_V1_1_fg0aa6.svg`;
const goEducateLogoDark = `${BASE_URL}/logo-white_i1ecbh.png`;
const resourceCard1 = `${BASE_URL}/banner/resource1image_h0059g.jpg`;
const resourceCard2 = `${BASE_URL}/banner/h_discover_interests_ji1rgj.svg`;
const resourceCardStudentExplore = `${BASE_URL}/banner/h_job_seeker_k690l7.svg`;
const resourceCardJobSeeker = `${BASE_URL}/banner/h_student_zwbhfl.svg`;
const resourceCardBuildProfile = `${BASE_URL}/banner/h_marketable_profile_x5eqq3.svg`;
const resourceCardSearchevents = `${BASE_URL}/banner/h_search_events_yqw9wf.svg`;
const resourceCardCareer = `${BASE_URL}/banner/h_checkout_careers_ob0hzi.svg`;
const resourceCardLocalJobOpp = `${BASE_URL}/banner/h_find_open_jobs_l6wrvp.svg`;
const resourceCardExplorePrograms = `${BASE_URL}/banner/h_explore_programs_cpohux.svg`;
const studentBanner = `${BASE_URL}/banner/student_banner_xbyzda.jpg`;
const studentthumb1 = `${BASE_URL}/banner/makeprofile_m90yu7.png`;
const studentthumb2 = `${BASE_URL}/banner/findintern_ztilef.jpg`;
const studentthumb3 = `${BASE_URL}/banner/tellpeople_nsgcdh.jpg`;
const semiCircle = `${BASE_URL}/semicircles_u7u3kd.png`;
const survey1 = `${BASE_URL}/banner/survey_1_wuexor.jpg`;
const survey2 = `${BASE_URL}/banner/survey_2_ewueru.jpg`;
const survey3 = `${BASE_URL}/banner/survey_3_l6kbbh.jpg`;
const surveyBig = `${BASE_URL}/banner/survey_big_govg5t.jpg`;
const surveyCircle = `${BASE_URL}/banner/survey_small_circle_hu5kdk.svg`;
const defaultEvent = `${BASE_URL}/defaulteventimage_qdqytk.jpg`;
const instanceerroricon = `${BASE_URL}/icons/erroricon_pxquqy.svg`;
const resourceCardPartner = `${BASE_URL}/banner/i_am_a_partner_lph4lr.svg`;
const resourceCardEmployerPartner = `${BASE_URL}/banner/employer_partner_pvl7wa.svg`;
const resourceCardEducationPartner = `${BASE_URL}/banner/Education_PARTNER_j4ooqf.svg`;
const resourceBannerFutureWorkforce = `${BASE_URL}/banner/employerpartnerbanner_lkvglx.jpg`;
const resourceBannerEducationPartner = `${BASE_URL}/banner/partner_banner_vzfjqp.jpg`;
const clusterBanner = `${BASE_URL}/banner/pathway_default_banner_rspazn.jpg`;
const shareIcon = `${BASE_URL}/icons/shareiconpurple_h7mryq.svg`;
const plaintick = `${BASE_URL}/icons/plaintick_arjqx8.svg`;
const alertYellow = `${BASE_URL}/icons/alertyellow_gn3ywd.svg`;
const settingsGrey = `${BASE_URL}/icons/settingrey_a9xhcz.svg`;
const fireworksIcon = `${BASE_URL}/icons/fireworks_1_nkfmc1.svg`;
const greenStar = `${BASE_URL}/icons/greeenstar_mgrn4z.svg`;
const redWarning = `${BASE_URL}/icons/redwarningicon_nlgdm6.svg`;
const saveGreen = `${BASE_URL}/icons/greenwarningicon_gewu4d.svg`;
const rocketStarsIcon = `${BASE_URL}/icons/rocketandstars_shiwkk.svg`;
const tickGreenCircle = `${BASE_URL}/icons/tickgreencircle_np5lh6.svg`;
const defaultBannerImage = `${BASE_URL}/defaultCard_ws7ftz.png`;
const defaultClusterCard = `${BASE_URL}/clusterCardImage_laqnfz.svg`;
const companyBanner = `${BASE_URL}/banner/CompaniesHiringRoundedBanner_owhlpp.png`;
const growTalentBanner = `${BASE_URL}/banner/GrowTalentRoundedBanner_nukm5s.png`;
const talentExchangeBanner = `${BASE_URL}/banner/TalentExchangeRoundedBanner_h8ldon.png`;
const compareProgramsBanner = `${BASE_URL}/banner/CompareProgramsRoundedBanner_a9ukcn.png`;
const exploreProgramsBanner = `${BASE_URL}/banner/EducationProgramsRoundedBanner_ht5b09.png`;
const jobOpportunitiesBanner = `${BASE_URL}/banner/JobsOpportunitiesRoundedBanner_sh8owl.png`;
const industryIconBlack = `${BASE_URL}/icons/industryIcon_wef4ni.svg`;
const industryIconWhite = `${BASE_URL}/icons/industryIconWhite_tbl2ji.svg`;
const careerBanner = `${BASE_URL}/banner/ExploreCareerRoundedBanner_ofttpb.png`;
const CISBanner = `${BASE_URL}/banner/CISBanner_rxb55v.png`;
const goBanner = `${BASE_URL}/banner/GO_Profile_Banner_oquifz.png`;
const careerCTABanner = `${BASE_URL}/banner/CareerAssessmentHero_qh8iwb.png`;

// profile dashboard icons
const skillswhite = `${BASE_URL}/icons/profile-dashboard/skillswhite_deff1m.svg`;
const skillsblack = `${BASE_URL}/icons/profile-dashboard/skillsblack_jvay14.svg`;
const programsblack = `${BASE_URL}/icons/profile-dashboard/myprograms_dsxz3t.svg`;
const profilewhite = `${BASE_URL}/icons/profile-dashboard/profilewhite_feie50.svg`;
const profileblack = `${BASE_URL}/icons/profile-dashboard/profileblack_pdhofm.svg`;
const jobmatcheswhite = `${BASE_URL}/icons/profile-dashboard/jobmatcheswhite_ncpjfe.svg`;
const jobmatchesblack = `${BASE_URL}/icons/profile-dashboard/jobmatchesblack_yco2lw.svg`;
const eventswhite = `${BASE_URL}/icons/profile-dashboard/eventswhite_hkhdtl.svg`;
const eventsblack = `${BASE_URL}/icons/profile-dashboard/eventsblack_q3cov5.svg`;
const careerinterestwhite = `${BASE_URL}/icons/profile-dashboard/careerinterestwhite_gpjhil.svg`;
const careerinterestblack = `${BASE_URL}/icons/profile-dashboard/careerinterestblack_jdegca.svg`;
const careerfavwhite = `${BASE_URL}/icons/profile-dashboard/careerfavwhite_ye4kpt.svg`;
const careerfavblack = `${BASE_URL}/icons/profile-dashboard/careerfavblack_u4zwrz.svg`;
const appliedwhite = `${BASE_URL}/icons/profile-dashboard/appliedwhite_ikm7ir.svg`;
const appliedblack = `${BASE_URL}/icons/profile-dashboard/appliedblack_syvvcl.svg`;

// Home Screen Resources

// VideoBanner Resources
const homeVideoBanner = `${VIDEO_BASEURL}/design-system/videos/home_video_banner_mgapx0.mp4`;
const jobVideoBanner = `${VIDEO_BASEURL}/design-system/videos/jobs_video_banner_atvmam.mov`;
const careerVideoBanner = `${VIDEO_BASEURL}/design-system/videos/careers_video_banner_bhihhw.mov`;
const programsVideoBanner = `${VIDEO_BASEURL}/design-system/videos/programs_video_banner_m6r3t5.mov`;
const goResumeVideoBanner = `${VIDEO_BASEURL}/design-system/videos/goresume_video_banner_mmeu8d.mp4`;
const goSurveyVideoBanner = `${VIDEO_BASEURL}/design-system/videos/gosurvey_video_banner_rjcppb.mov`;
const whoIsHiringVideoBanner = `${VIDEO_BASEURL}/design-system/videos/whos_hiring_video_banner_i9yrdc.mp4`;
const apprenticeshipsVideoBanner = `${VIDEO_BASEURL}/design-system/videos/apprenticeships_video_banner_jbefcm.mp4`;
const employerVideoBanner = `${VIDEO_BASEURL}/design-system/videos/employer_partner_video_banner_q6pvkf.mov`;

// opportunities
const jobsBanner = `${BASE_URL}/banner/jobs_banner_guaar9.png`;
const apprenticeshipsBanner = `${BASE_URL}/banner/apprenticeship_banner_fnozpk.png`;
const apprenticeshipDetailsBanner = `${BASE_URL}/banner/apprenticeship_details_banner_x7zoqm.jpg`;

// company marketplace
const verifiedYellowCheckmark = `${BASE_URL}/icons/yellow_check_icon_sjiqnw.svg`;
const companyVideoBanner = `${VIDEO_BASEURL}/videos/companies_video_banner_dnzeeu.mp4`;

// talent exchange
const employerBanner = `${BASE_URL}/banner/employer_banner_ghg0q3.png`;
const jobseekerBanner = `${BASE_URL}/banner/jobseeker_banner_lmqfia.png`;

// Go-Resume images
const watchMagicImage = `${BASE_URL}/banner/watch_magic_g4rzcn.png`;
const resumeSampleImage = `${BASE_URL}/banner/resume_sample_sograj.svg`;
const resumeBuilderImage = `${BASE_URL}/banner/resume_builder_ai_zchrpv.png`;
const resumePreviewIcon = `${BASE_URL}/icons/prztnn5rjggauajnfaxt.svg`;
const createConnectionImage = `${BASE_URL}/banner/create_connection_qhmyge.png`;
const resumeBuilderGuidelines = `${BASE_URL}/banner/resume_welcome_modal_rh3bm5.png`;

// Resume-builder images
const briefCaseIcon = `${BASE_URL}/icons/AIBriefCaseIcon_not5uo.png`;
const goAIButtonBanner = `${BASE_URL}/banner/GOAI_ModalBanner_n56zvv.svg`;
const goAIButtonIcon = `${BASE_URL}/icons/GOAI_BtnIcon_xiatrf.svg`;
const publishButtonIcon = `${BASE_URL}/icons/publish_icon_c0zepq.svg`;
const completeResumeIcon = `${BASE_URL}/icons/CloudCheck_icon_zutirb.svg`;
const publicationSuccessIcon = `${BASE_URL}/icons/publish_success_sv5xua.svg`;
const handShakeIcon = `${BASE_URL}/icons/handshake_outlined_white_uuinve.svg`;
const binocularIcon = `${BASE_URL}/icons/binocular_outlined_white_eaof5c.svg`;
const briefcaseIcon = `${BASE_URL}/icons/briefcase_outlined_white_if63kp.svg`;
const publicationBackgroundImage = `${BASE_URL}/banner/dialog_bg_dark_ssofbp.png`;
const publishResumeNewIcon = `${BASE_URL}/icons/publish_success_icon_cp6pt4.png`;

// Go-Survey images
const surveyWelcomeBanner = `${BASE_URL}/banner/GoSurvey_Start_rfjvlu.png`;

// Become A Partner
const demoBanner = `${BASE_URL}/banner/demo_banner_bblrpb.jpg`;
const partnersBanner = `${BASE_URL}/banner/partnersBanner_bzto8o.png`;
const transparentRing = `${BASE_URL}/banner/TransparentRing_kb0zww.png`;
const einModalIcon = `${BASE_URL}/icons/connect_company_icon_cbtqzk.png`;
const multiStakeholder = `${BASE_URL}/banner/multiStakeholder_lx1il6.png`;
const EmployerPartnerBanner = `${BASE_URL}/banner/ab06c0272cdbe5ad3d5074471feae5e7_ml66ij.jpg`;

// New Design System Icons
const Calendar = `${BASE_URL}/design-system/icons/calendar_cizek0.svg`;
const ClockIcon = `${BASE_URL}/design-system/icons/clock_huga3a.svg`;
const LocationIcon = `${BASE_URL}/design-system/icons/location_gmcztt.svg`;
const ArrowRight = `${BASE_URL}/design-system/icons/arrow-right_bxf05z.svg`;
const LocationPinIcon = `${BASE_URL}/design-system/icons/locationPin_kvaiwc.svg`;
const ArrowCircleRight = `${BASE_URL}/design-system/icons/arrow-circle-right_uwwask.svg`;
const ArrowCircleBrokenRight = `${BASE_URL}/design-system/icons/arrow-circle-broken-right_zkadbg.svg`;
const BuildingIcon = `${BASE_URL}/design-system/icons/building-06_ibyw0d.svg`;
const GlowingLightBulb = `${BASE_URL}/design-system/icons/glowing-lightbulb_uztbgf.svg`;
const DiamondIcon = `${BASE_URL}/design-system/icons/diamond_qmil9o.svg`;
const CurrencyCircle = `${BASE_URL}/design-system/icons/currency-circle_tlhhrh.svg`;
const BriefcaseIcon = `${BASE_URL}/design-system/icons/striped-briefcase_luorml.svg`;
const MailIcon = `${BASE_URL}/design-system/icons/mail-01_vyzzvb.svg`;

// journey images
const rocket = `${BASE_URL}/icons/journey-images/33d0ddbda78ecafc244d95722a3fec63_ovx03h.jpg`;
const discover = `${BASE_URL}/icons/journey-images/319326e2ea1dd7cb0120a367e27d2486_d5h42d.jpg`;
const browse = `${BASE_URL}/icons/journey-images/71f8ffc890e90afa5fa3c1fbcceadc5d_hie1z2.jpg`;
const createResumne = `${BASE_URL}/icons/journey-images/0a80dd3f89338ead19eb37c51b9d457a_i0lpmp.png`;
const startApprenticeship = `${BASE_URL}/icons/journey-images/948a2ab3518244f04390b93548f93c24_autfg3.jpg`;
const searchJobs = `${BASE_URL}/icons/journey-images/04b304daef229fb3177880139e3560ea_nbfsxx.jpg`;
const whoHiring = `${BASE_URL}/icons/journey-images/208802d6abc05f1bfaf4f9009110340e_tlfmft.png`;

const showcaseOpportunity = `${BASE_URL}/design-system/images/opportunity_top_talent_vosfmc.png`;
const spotlightBgImg = `${BASE_URL}/design-system/images/spotlight_background_oqwpvg.jpg`;
const localWorkforce = `${BASE_URL}/design-system/images/local_workforce_c1zrug.png`;
const localTalentImg = `${BASE_URL}/design-system/images/local_talent_ntsg0r.png`;

export {
  BASE_URL,
  VIDEO_BASEURL,
  avgIcon,
  infoIcon,
  belowIcon,
  pdflogo,
  goEducateLogo,
  goEducateLogoWhite,
  goEducateLogoLight,
  goEducateLogoDark,
  pdfaward,
  pdfarrow,
  logo,
  resourceCard1,
  resourceCard2,
  facebook,
  instagram,
  linkedin,
  arrowdown,
  skillCertify,
  blueStar,
  awardtype,
  awardTypeWhite,
  locationBlue,
  noSearchResults,
  noPathwayBanner,
  noCollageLogo,
  iconbellGray,
  iconbellWhite,
  iconmail,
  icontrophy,
  iconarrowPointer,
  iconremove,
  iconplus,
  iconPlay,
  explorePathSearch,
  brightIcon,
  defaultbannerprofile,
  defaultExternalJobBanner,
  outlookbright,
  outlookaverage,
  outlookbelow,
  hotTechnology,
  onetinit,
  statemap,
  localsalaryinfo,
  haticon,
  salarychart,
  avatar,
  avatarGray,
  defaultbannerjob,
  successTick,
  warningIcon,
  maplocation,
  carrerOneStop,
  dash_profile,
  dash_home,
  dash_education,
  dash_workforce,
  dash_job,
  dash_event,
  dash_skill,
  white_facebook,
  white_twitter,
  white_linkedin,
  editpurple,
  arrowpurple,
  defaultoccupation,
  textloading,
  compare_pathIcon,
  photoCam,
  deleteIcon,
  studentBanner,
  studentthumb1,
  studentthumb2,
  studentthumb3,
  semiCircle,
  whitetick,
  instanceerroricon,
  searchPurple,
  heartPurpleBorder,
  heartWhite,
  resourceCardCareer,
  resourceCardLocalJobOpp,
  resourceCardStudentExplore,
  resourceCardExplorePrograms,
  resourceCardJobSeeker,
  resourceCardBuildProfile,
  resourceCardSearchevents,
  surveyBig,
  survey1,
  survey2,
  survey3,
  surveyCircle,
  clockgrey,
  defaultEvent,
  mobileIcon,
  noResultsIcon,
  noSurveyIcon,
  noBpIcon,
  greenHeart,
  growTalentBanner,
  resourceCardPartner,
  talentExchangeBanner,
  compareProgramsBanner,
  resourceCardEmployerPartner,
  resourceCardEducationPartner,
  resourceBannerFutureWorkforce,
  resourceBannerEducationPartner,
  carouselLeftArrow,
  carouselRightArrow,
  carouselLeftArrowDark,
  carouselRightArrowDark,
  color_instagram,
  color_medium,
  color_tiktok,
  x_logo_black,
  black_instagram,
  black_medium,
  black_tiktok,
  clusterBanner,
  breadcrumbArrow,
  emailSuccess,
  talentIcons,
  logo_amazon_svg,
  logo_apple_svg,
  logo_boeing_svg,
  logo_general_svg,
  logo_google_svg,
  logo_Hyatt_svg,
  logo_meta_svg,
  logo_microsoft_svg,
  logo_tesla_svg,
  logo_tyson_svg,
  logo_wells_svg,
  logo_amazonfeat,
  logo_gefeat,
  logo_googlefeat,
  logo_hyattfeat,
  logo_metafeat,
  logo_shellfeat,
  logo_tysonfeat,
  logo_wellsfargofeat,
  talentimages,
  shareIcon,
  plaintick,
  alertYellow,
  settingsGrey,
  fireworksIcon,
  skillswhite,
  skillsblack,
  programsblack,
  profilewhite,
  profileblack,
  jobmatcheswhite,
  jobmatchesblack,
  eventswhite,
  eventsblack,
  careerinterestwhite,
  careerinterestblack,
  careerfavwhite,
  careerfavblack,
  appliedwhite,
  appliedblack,
  black_facebook,
  black_linkedin,
  black_twitter,
  saveGreen,
  greenStar,
  redWarning,
  rocketStarsIcon,
  tickGreenCircle,
  goBanner,
  careerCTABanner,
  defaultBannerImage,
  defaultClusterCard,
  appLogo,
  shadowImage,
  GoMeterAsset,
  appLogoWhite,
  careerBanner,
  CISBanner,
  companyBanner,
  InfoIconwhite,
  exploreProgramsBanner,
  jobOpportunitiesBanner,
  industryIconBlack,
  industryIconWhite,
  purpleFriendsIcon,
  purpleLocationIcon,
  purpleDateCalenderIcon,
  resumeBuilderGuidelines,
  goAIButtonIcon,
  goAIButtonBanner,
  publicationSuccessIcon,
  briefcaseIcon,
  binocularIcon,
  handShakeIcon,
  publicationBackgroundImage,
  publishResumeNewIcon,
  publishButtonIcon,
  completeResumeIcon,
  verifiedBlueCheckmark,
  verifiedYellowCheckmark,
  // talent-exchange
  employerBanner,
  jobseekerBanner,
  // fallback banner and logo
  defaulGoBanner,
  GradientGoeducateLogo,
  // opportunity-assets
  jobsBanner,
  companyVideoBanner,
  apprenticeshipsBanner,
  apprenticeshipDetailsBanner,
  nlx_logo,
  honest_logo,
  goLogoWhite,
  //Go-Survey images
  surveyWelcomeBanner,
  briefCaseIcon,
  //Become A Partner
  demoBanner,
  einModalIcon,
  partnersBanner,
  transparentRing,
  multiStakeholder,
  EmployerPartnerBanner,
  // New Design System Icons
  Calendar,
  ClockIcon,
  ArrowRight,
  LocationIcon,
  BuildingIcon,
  LocationPinIcon,
  ArrowCircleRight,
  GlowingLightBulb,
  DiamondIcon,
  CurrencyCircle,
  BriefcaseIcon,
  ArrowCircleBrokenRight,
  showcaseOpportunity,
  localWorkforce,
  localTalentImg,
  // Video Banners
  jobVideoBanner,
  homeVideoBanner,
  careerVideoBanner,
  goResumeVideoBanner,
  goSurveyVideoBanner,
  programsVideoBanner,
  whoIsHiringVideoBanner,
  apprenticeshipsVideoBanner,
  // Go Resume
  watchMagicImage,
  resumePreviewIcon,
  resumeSampleImage,
  resumeBuilderImage,
  createConnectionImage,
  //
  employerVideoBanner,
  rocket,
  discover,
  browse,
  createResumne,
  startApprenticeship,
  searchJobs,
  whoHiring,
  MailIcon,
  spotlightBgImg,
};

import {API} from 'config';
import * as TYPES from './constants';
import {
  requestSaveCareer,
  receiveSaveCareer,
  requestSaveCareerError,
  requestSavedCareerList,
  receiveSavedCareerList,
  requestSavedCareerListError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, subscribeRequest: false, data: null};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SAVED_CAREER_LIST_REQUEST:
      return {
        ...state,
        error: false,
        request: true,
      };
    case TYPES.SAVED_CAREER_LIST_SUCCESS:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.SAVE_CAREER_REQUEST:
      return {
        ...state,
        subscribeRequest: true,
      };
    case TYPES.SAVE_CAREER_SUCCESS:
      return {
        ...state,
        subscribeRequest: false,
      };
    case TYPES.SAVE_CAREER_ERROR:
    case TYPES.SAVED_CAREER_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
        subscribeRequest: false,
      };
    case TYPES.SAVED_CAREER_LIST_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const fetchSavedCareers = id => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestSavedCareerList());
  try {
    let endPoint = `${API.gps.saved_careers}?student_uuid=${id}`;
    if (id) {
      return services.fetchRecords(endPoint, token).then(res => {
        if (res && Array.isArray(res)) {
          dispatch(receiveSavedCareerList(res));
          return res;
        }
        dispatch(requestSavedCareerListError(res));
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestSavedCareerListError(err));
  }
};

export const subscribeCareer = (data, callback) => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestSaveCareer());
  try {
    let endPoint = `${API.gps.saved_careers}`;
    return services
      .createUpdateRecord(token, endPoint, data, 'POST')
      .then(res => {
        if (res?.Success) {
          dispatch(receiveSaveCareer(res));
          if (callback) callback(res);
          return;
        }
        dispatch(requestSaveCareerError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestSaveCareerError(err));
  }
};

export const unSubscribeCareer = (id, callback) => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestSaveCareer());
  try {
    let endPoint = `${API.gps.saved_careers}${id}`;
    return services
      .createUpdateRecord(token, endPoint, {}, 'DELETE')
      .then(res => {
        if (res?.Success) {
          dispatch(receiveSaveCareer(res));
          if (callback) callback(res);
          return;
        }
        dispatch(requestSaveCareerError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestSaveCareerError(err));
  }
};

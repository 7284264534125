import Slider from 'react-slick';
import {ErrorBoundary} from 'mui-core';
import 'slick-carousel/slick/slick.css';
import {styled} from '@mui/material/styles';
import {Box, useTheme} from '@mui/material';
import 'slick-carousel/slick/slick-theme.css';
import {
  logo_meta_svg,
  logo_apple_svg,
  logo_Hyatt_svg,
  logo_tesla_svg,
  logo_tyson_svg,
  logo_wells_svg,
  logo_boeing_svg,
  logo_amazon_svg,
  logo_google_svg,
  logo_general_svg,
  logo_microsoft_svg,
  carouselLeftArrow,
  carouselRightArrow,
  carouselLeftArrowDark,
  carouselRightArrowDark,
} from 'assets/images';

const StyledCarouselContainer = styled(Box)(({}) => ({
  '& .slick-slider': {
    // margin: '0px 32px',
    '& .slick-list': {
      '& .slick-track': {
        margin: '0px',
        '& .slick-slide': {
          '& div': {
            display: 'flex',
            justifyContent: 'center',
            '& .imgStyle': {
              width: '160px!important',
              height: '60px!important',
            },
          },
        },
      },
    },
    '& .sliderBtn': {
      img: {
        width: '30px !important',
        height: '30px !important',
      },
    },
  },
}));

const CompaniesListSlider = ({slidesToShow = 4}) => {
  const theme = useTheme();
  const companiesList = [
    logo_amazon_svg,
    logo_apple_svg,
    logo_boeing_svg,
    logo_general_svg,
    logo_google_svg,
    logo_Hyatt_svg,
    logo_meta_svg,
    logo_microsoft_svg,
    logo_tesla_svg,
    logo_tyson_svg,
    logo_wells_svg,
  ];
  const PreviousBtn = ({onClick, className, style}) => {
    const leftImgSrc =
      theme.palette.mode === 'dark' ? carouselLeftArrowDark : carouselLeftArrow;
    return (
      <Box className='sliderBtn prevBtn' onClick={onClick}>
        <img alt='Prev' style={style} src={leftImgSrc} className={className} />
      </Box>
    );
  };

  const NextBtn = ({onClick, className, style}) => {
    const rightImgSrc =
      theme.palette.mode === 'dark'
        ? carouselRightArrowDark
        : carouselRightArrow;
    return (
      <Box className='sliderBtn nextBtn' onClick={onClick}>
        <img alt='Next' style={style} src={rightImgSrc} className={className} />
      </Box>
    );
  };

  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    lazyload: false,
    slidesToScroll: 1,
    nextArrow: <NextBtn />,
    prevArrow: <PreviousBtn />,
    slidesToShow: slidesToShow,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    companiesList &&
    Array.isArray(companiesList) &&
    companiesList?.length && (
      <StyledCarouselContainer>
        <ErrorBoundary nameOfComponent='core-carousel'>
          <Slider {...settings}>
            {companiesList.map((slideImg, index) => (
              <img
                src={slideImg}
                alt={`Company logo ${index + 1}`}
                className='imgStyle'
                key={`company-img-${index + 1}`}
              />
            ))}
          </Slider>
        </ErrorBoundary>
      </StyledCarouselContainer>
    )
  );
};

export default CompaniesListSlider;

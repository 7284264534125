import React from 'react';
import {Grid, SkillsStack} from 'mui-core';
import SettingsMonths from 'data/settings.json';
import {Typography, Divider} from '@mui/material';
import {educationData} from 'data/settings-profilePreview.json';

const Course = ({data: courseInfo}) => (
  <>
    {courseInfo &&
      Array.isArray(courseInfo) &&
      courseInfo.length > 0 &&
      courseInfo.map((ed, idx) => {
        return (
          <>
            {ed?.program_name && (
              <Grid container key={idx}>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={2}
                  sx={{textAlign: 'right', paddingRight: '20px'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'>
                    {educationData.courseeLabel}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={10}
                  sx={{textAlign: 'left'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'>
                    {ed?.program_name}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {ed?.org_name && (
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={2}
                  sx={{textAlign: 'right', paddingRight: '20px'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'>
                    {educationData.schoolLabel}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={10}
                  sx={{textAlign: 'left'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'>
                    {ed?.org_name}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {ed?.school_start_date && (ed?.school_end_date || ed?.is_present) && (
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={2}
                  sx={{textAlign: 'right', paddingRight: '20px'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'>
                    {educationData.dateissuedLabel}{' '}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={10}
                  sx={{textAlign: 'left'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'>
                    {ed.date_completed_month && ed.date_completed_year
                      ? `${
                          SettingsMonths.months[ed.date_completed_month - 1]
                        } ${ed.date_completed_year}`
                      : '-'}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {ed.skills?.length !== 0 && (
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={2}
                  mt={2}
                  sx={{textAlign: 'right', paddingRight: '20px'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'>
                    {educationData.SkillsLabel}{' '}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={10}
                  sx={{textAlign: 'left'}}>
                  <SkillsStack
                    displayLimit={15}
                    skillSet={ed.skills}
                    displayShowAll={true}
                  />
                </Grid>
              </Grid>
            )}
            {idx + 1 !== courseInfo.length && <Divider sx={{margin: '20px'}} />}
          </>
        );
      })}
  </>
);

export default Course;

import React from 'react';
import {Page, Document, Font, StyleSheet, View} from '@react-pdf/renderer';

import {ErrorBoundary} from 'core/components';

import Header from './Header';
import Metrics from './Metrics';
import ListView from './ListView';
import MapView from './MapView';
import Footer from './Footer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    height: '100%',
  },
  doc: {
    flexDirection: 'column',
    flex: '1',
  },
});

// Refrence::
// Font.register({
//   family: 'Source Sans Pro',
//   src: `./../fonts/sourcesanspro-regular-webfont.woff2`,
// });

// Font.register({
//   family: 'Source Sans Pro Semibold',
//   src: `./../fonts/sourcesanspro-semibold-webfont.woff2`,
// });

const MyDocument = props => (
  <ErrorBoundary nameOfComponent='core-pdf-document'>
    <Document title={props && props.path && props.path.title}>
      <Page size='A4' wrap style={styles.page}>
        {props && props.data && props.path && props.user && (
          <View style={styles.doc}>
            <Header {...props} />
            <Metrics {...props} />
            <ListView {...props} />
            <MapView {...props} />
            <Footer />
          </View>
        )}
      </Page>
    </Document>
  </ErrorBoundary>
);

export default MyDocument;

import {volunteerExperienceData} from 'data/settings-profilePreview.json';
import {List, Divider, Typography, Stack, Box} from '@mui/material';
import styled from '@emotion/styled';

const VolunteerExperiences = ({data: voluntary_exp}) => {
  const ListBlueDot = styled(List)(({theme}) => ({
    padding: '0px',
    paddingLeft: '15px',
    marginTop: '5px',
  }));
  return (
    <Box px={1}>
      {voluntary_exp &&
      Array.isArray(voluntary_exp) &&
      voluntary_exp.length > 0 ? (
        voluntary_exp.map((exp, idx) => {
          const Responsibilities =
            exp.roles_responsibilities.length > 0 &&
            exp.roles_responsibilities.filter(
              role => typeof role.roles_responsibilities === 'string',
            );
          return (
            <>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography
                  variant='h5'
                  fontSize={18}
                  fontWeight={600}
                  component='span'>
                  {volunteerExperienceData.experienceLabel}:{' '}
                </Typography>
                <Typography
                  variant='h5'
                  fontSize={16}
                  fontWeight={600}
                  component='span'>
                  {exp?.name}
                </Typography>
              </Stack>

              <Stack direction={'row'} alignItems={'center'} spacing={1} ml={1}>
                <Typography
                  variant='h5'
                  fontSize={15}
                  fontWeight={600}
                  component='span'>
                  {volunteerExperienceData.hoursLabel}:{' '}
                </Typography>
                <Typography
                  variant='body1'
                  fontSize={15}
                  fontWeight={400}
                  component='span'>
                  {exp?.hours}
                </Typography>
              </Stack>
              {Responsibilities && Responsibilities.length > 0 && (
                <Box mt={2}>
                  <Typography
                    variant='h5'
                    fontSize={18}
                    fontWeight={600}
                    component='span'>
                    {volunteerExperienceData.rolesResponsibilitiesLabel}
                  </Typography>
                  {Responsibilities.map((role, id) => (
                    <Stack
                      key={idx}
                      direction={'row'}
                      spacing={1}
                      mt={1}
                      ml={1}
                      alignItems={'center'}>
                      <Box
                        sx={{
                          minWidth: 8,
                          minHeight: 8,
                          backgroundColor: 'secondary.main',
                          borderRadius: 9,
                        }}
                      />
                      <Box>
                        <Typography variant='body1'>
                          {role.roles_responsibilities}
                        </Typography>
                      </Box>
                    </Stack>
                  ))}
                </Box>
              )}
              {idx + 1 !== voluntary_exp.length && (
                <Divider sx={{maxWidth: 500, my: 3}} />
              )}
            </>
          );
        })
      ) : (
        <div></div>
      )}
    </Box>
  );
};

export default VolunteerExperiences;

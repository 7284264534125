import {ContentBox} from 'mui-core';
import BannerUploader from './ProfileHeader/BannerUploader';

const Banner = props => {
  return (
    <>
      <ContentBox variant='outlined' sx={{mb: 3, border: 'none'}}>
        <BannerUploader {...props} />
      </ContentBox>
    </>
  );
};

export default Banner;

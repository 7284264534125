import React from 'react';
import PropTypes from 'prop-types';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {styled} from '@mui/material/styles';
import {useHistory} from 'react-router-dom';
import {defaultBannerImage} from 'assets/images';
import {getCloudinaryImage} from 'mui-core/utils';
import {fetchGoSurveyResultDetails} from 'redux/modules/survey';
import {themeColorMode, ErrorBoundary, Button, Card} from 'mui-core';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Box, Stack, useTheme, Typography, useMediaQuery} from '@mui/material';
import {goSurveyCard} from 'data/goSurvey.json';

const TagContainer = styled(Box)(({}) => ({
  zIndex: 1,
  paddingTop: '24px',
  position: 'absolute',
}));

const tagTypeColor = (theme, tagType) => {
  switch (tagType) {
    case 'in_demand': {
      return {background: `${theme.palette.success.dark}`, color: 'white'};
    }
    default:
      return {};
  }
};

const Tag = styled(Box)(({theme, type}) => ({
  padding: '4px 24px',
  borderRadius: '0 12px 12px 0',
  ...tagTypeColor(theme, type),
}));

const GoSurveyCardWrapper = styled(Card)(({theme}) => ({
  display: 'flex',
  cursor: 'pointer',
  marginBottom: '32px',
  borderRadius: '20px',
  justifyContent: 'left',
  padding: '32px 48px 24px 48px',
  backgroundColor: themeColorMode(
    theme,
    theme.palette.grayLight.main,
    theme.palette.darkGray.main,
  ),
  boxShadow: '0px 4px 8px rgba(0,0,0,0.08)',
  [theme.breakpoints.down('md')]: {
    padding: '24px 8px',
    marginBottom: '24px',
  },
}));

const Image = styled('img')(({theme}) => ({
  width: 430,
  height: 350,
  borderRadius: 16,
  boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
  [theme.breakpoints.down('lg')]: {
    width: 430,
    height: 350,
  },
  [theme.breakpoints.down('md')]: {
    width: 300,
    height: 240,
  },
}));

const NextButton = styled(Button)(({theme}) => ({
  width: 160,
  color: 'white',
  padding: '4px 24px',
  borderRadius: '30px',
  border: `0.5px solid ${themeColorMode(
    theme,
    theme.palette.lightGrey.light,
    theme.palette.primary.light,
  )}`,
  background: 'rgba(0, 0, 0, 0.87)',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  ':hover': {
    background: theme.palette.shadeyBlack.main,
  },
}));

const GoSurveyCard = ({data, index}) => {
  const {
    cip_code,
    skills = [],
    description,
    career_name,
    why_aligned,
    in_demand_tag = false,
    cip4_image_cloudinary = '',
  } = data || {};
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const skillsLength = skills?.length || 0;
  const {btnText, inDemandText} = goSurveyCard;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onLearnMore = (career_name, cip_code) => {
    dispatch(
      fetchGoSurveyResultDetails(career_name, cip_code, resp => {
        if (resp.Error || resp.error) {
          enqueueSnackbar(
            resp?.Error ||
              resp?.error ||
              'We’re currently experiencing high traffic. Please try again in a few minutes.',
            {
              variant: 'warning',
            },
          );
        } else {
          history.push(`/career-details/${career_name}/${cip_code}`);
        }
      }),
    );
  };

  return (
    <ErrorBoundary nameOfComponent='go-survey-card'>
      <GoSurveyCardWrapper>
        <Stack gap={{xs: 2, md: 5}} direction={{xs: 'column', md: 'row'}}>
          <Stack
            gap={2}
            display='flex'
            alignItems='center'
            justifyContent='center'>
            <Box>
              <TagContainer spacing={1}>
                {in_demand_tag && (
                  <Tag alignItems='center' type='in_demand'>
                    <Typography variant='body2'>{inDemandText}</Typography>
                  </Tag>
                )}
              </TagContainer>
              <Image
                alt={`${career_name?.slice(0, 50) || ''}-img`}
                src={getCloudinaryImage(cip4_image_cloudinary)}
              />
            </Box>
            {!isMobile && (
              <NextButton
                rounded
                onClick={() => onLearnMore(career_name, cip_code)}
                endIcon={
                  <ArrowForwardIosIcon
                    sx={{width: '12px', height: '12px', mb: '2px'}}
                  />
                }>
                {btnText}
              </NextButton>
            )}
          </Stack>
          <Stack gap={1.5} px={{xs: 2, md: 0}}>
            {career_name && (
              <Typography
                variant='h4'
                color='text.primary'
                title={`${career_name || ''} `}>
                {`${index + 1}. ${career_name}`}
              </Typography>
            )}
            {description && (
              <Typography variant='bodyL' color='text.primary'>
                {description}
              </Typography>
            )}
            {why_aligned && (
              <Typography variant='bodyL' color='text.primary'>
                {why_aligned}
              </Typography>
            )}
            <Typography variant='bodyL' color='text.primary' mt={1.5}>
              Here are the top {skillsLength} skills required for this suggested
              career:
            </Typography>
            <ul
              style={{
                margin: '0px',
                paddingLeft: '0px',
                lineHeight: '22px',
                listStyleType: 'none',
              }}>
              {skills &&
                skills.map(item => (
                  <li>
                    <Typography pl={1} variant='bodyL' color='text.primary'>
                      {`- ${item}`}
                    </Typography>
                  </li>
                ))}
            </ul>
            {isMobile && (
              <Stack display='flex' alignItems='center' my={1} mt={1.5}>
                <NextButton
                  rounded
                  onClick={() => onLearnMore(career_name, cip_code)}
                  endIcon={
                    <ArrowForwardIosIcon
                      sx={{width: '12px', height: '12px', mb: '2px'}}
                    />
                  }>
                  {btnText}
                </NextButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      </GoSurveyCardWrapper>
    </ErrorBoundary>
  );
};

GoSurveyCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GoSurveyCard;

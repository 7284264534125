import React from 'react';
import {Accordion, ContentBox} from 'mui-core';
import ResumeList from './components/ResumeList';
import {myResumeHeading} from 'data/resume.json';

const MyResume = () => {
  return (
    <ContentBox>
      <Accordion
        defaultOpen
        id='my_resume'
        outlined={false}
        collpsable={false}
        header={myResumeHeading}>
        <ResumeList />
      </Accordion>
    </ContentBox>
  );
};

export default MyResume;

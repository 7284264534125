import PropTypes from 'prop-types';
import {NoContentNavigator} from 'mui-core';
import {career} from 'data/dashboard.json';

const {featureHiddenData, exploreCareersBtnTxt} = career;

const NoDataContainer = ({
  noDataDesc,
  noDataTitle,
  featureHidden,
  pathTo = '/careers',
  btnLabelTxt = exploreCareersBtnTxt.toUpperCase(),
}) => (
  <NoContentNavigator
    pathTo={pathTo}
    label={featureHidden ? '' : btnLabelTxt}
    message={featureHidden ? featureHiddenData?.title : noDataTitle}
    subHeading={featureHidden ? featureHiddenData?.description : noDataDesc}
  />
);

NoDataContainer.propTypes = {
  pathTo: PropTypes.string,
  btnLabelTxt: PropTypes.string,
  featureHidden: PropTypes.bool,
  noDataDesc: PropTypes.string.isRequired,
  noDataTitle: PropTypes.string.isRequired,
};

export default NoDataContainer;

import {lazy} from 'react';
import {retry} from 'core/utils';
import './styles.less';

const Dashboard = lazy(() => retry(() => import('./dashboard')));
const Profile = lazy(() => retry(() => import('./profile')));
const Education = lazy(() => retry(() => import('./education')));
const Skills = lazy(() => retry(() => import('./skills')));
const DashboardJobs = lazy(() => retry(() => import('./jobs')));
const Workforce = lazy(() => retry(() => import('./workforce')));
const CareerFavorites = lazy(() => retry(() => import('./careerFavorites')));
const MyEvents = lazy(() => retry(() => import('./myEvents')));
const CareerInterestSurvey = lazy(() =>
  retry(() => import('./careerInterestSurvey')),
);
const ProfilePreview = lazy(() => retry(() => import('./profilePreview')));
const MySettings = lazy(() => retry(() => import('./mysettings/mySettings')));
const ProfileSetting = lazy(() => retry(() => import('./privacysetting')));
export {
  Dashboard,
  Profile,
  Education,
  Workforce,
  MyEvents,
  ProfilePreview,
  CareerFavorites,
  CareerInterestSurvey,
  Skills,
  DashboardJobs,
  MySettings,
  ProfileSetting,
};

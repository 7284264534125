import React, {useState, useEffect} from 'react';
import {API} from 'config';
import {useSnackbar} from 'notistack';
import {Services} from 'core/Services';
import AddEducation from './AddEducation';
import {formatViewDate} from 'core/utils';
import {green} from '@mui/material/colors';
import {Edit, Delete} from '@mui/icons-material';
import {useCheckMobileView, useAuth} from 'core/hooks';
import {Grid, Button, SkillsStack, RequestErrorLoader} from 'mui-core';
import {Box, Stack, Divider, IconButton, Typography} from '@mui/material';
import moment from 'moment';

const Education = ({
  skills,
  viewOnly,
  srmAwardTypes = {},
  educationData = [],
  fetchActivePaths,
  fetchStudentMetrics,
  saveStudentCredential,
  updateStudentCredential,
  fetchStudentCredentials,
  deleteStudentCredential,
  studentCredentials: {request},
}) => {
  const {isMobileView} = useCheckMobileView();
  const {data: srmAwardTypeData} = srmAwardTypes;
  const [editIndex, setEditIndex] = useState(-1);
  const {enqueueSnackbar} = useSnackbar();
  const [isAdd, setAdd] = useState(
    !educationData || educationData.length === 0 ? true : false,
  );
  const services = new Services();

  const [token] = useAuth();

  useEffect(() => {
    if (!educationData || educationData.length === 0) setAdd(true);
    else setAdd(false);
  }, [educationData]);

  const cancelAddEdit = () => {
    setAdd(false);
    setEditIndex(-1);
  };

  const editData = idx => {
    setAdd(false);
    setEditIndex(idx);
  };

  const formatData = (item, student_onboard_uuid) => {
    const data = {...item};
    if (student_onboard_uuid) {
      data['gpa'] = parseFloat(item['gpa']) || null;
      data['start_date'] = item['start_date']; //.format('YYYY-MM-DD');

      if (!item['is_present'] && item['end_date'])
        data['end_date'] = item['end_date']; //.format('YYYY-MM-DD');
      // if (
      //   item['is_present'] ||
      //   !item['end_date'] ||
      //   item['end_date'] === 'Invalid date'
      // )
      //   data['end_date'] = null;
      if (item['is_present']) {
        data['end_date'] = moment(new Date());
      }
      if (item?.skills?.length)
        data['skills'] = item?.skills.map(skill => skill.name);
      return data;
    } else {
      data['gpa'] = parseFloat(item['gpa']) || null;
      data['school_start_date'] = item['school_start_date'].format(
        'YYYY-MM-DD',
      );
      if (!item['is_present'] && item['school_end_date'])
        data['school_end_date'] = item['school_end_date'].format('YYYY-MM-DD');
      if (
        item['is_present'] ||
        !item['school_end_date'] ||
        item['school_end_date'] === 'Invalid date'
      )
        data['school_end_date'] = null;
      if (item?.skills?.length)
        data['skills'] = item?.skills.map(skill => skill.name);
      return data;
    }
  };

  const onOnBoardActivePathChange = async (
    onboardValue,
    student_onboard_uuid,
  ) => {
    const body =
      onboardValue === 'onboard' || ''
        ? {completed_stage: onboardValue}
        : onboardValue || {};
    services
      .createUpdateRecord(
        token,
        `${API.gps.student_onboard}/${student_onboard_uuid}`,
        body,
        'PATCH',
      )
      .then(res => {
        fetchActivePaths();
        fetchStudentMetrics();
      })
      .catch(err => {
        enqueueSnackbar(err, {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      });
  };

  const onAdd = async (obj, student_onboard_uuid) => {
    const eduData = formatData(obj, student_onboard_uuid);
    if (editIndex !== -1 && !student_onboard_uuid) {
      eduData['uuid'] = educationData[editIndex].student_credential_uuid;
      updateStudentCredential(eduData, res => {
        if (res?.Error) {
          enqueueSnackbar(res.Error, {
            variant: 'error',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        } else {
          fetchStudentCredentials();
          fetchStudentMetrics();
        }
      });
    } else if (editIndex !== -1 && student_onboard_uuid) {
      onOnBoardActivePathChange(eduData, student_onboard_uuid);
    } else {
      saveStudentCredential({...eduData, credential_type: 'education'}, () => {
        fetchStudentMetrics();
      });
    }
    cancelAddEdit();
  };

  const deleteData = education => {
    deleteStudentCredential(education.student_credential_uuid, () => {
      fetchStudentCredentials();
      fetchStudentMetrics();
    });
  };

  const getAwardType = id => {
    let value = id;
    if (Array.isArray(srmAwardTypeData)) {
      const d = srmAwardTypeData.filter(award => award.uuid === id)[0];
      if (d) {
        value = d.options;
      }
    }
    return value;
  };

  const getGPAValue = num => {
    let value = num
      ? Number.isInteger(parseFloat(num))
        ? parseFloat(num) + '.0'
        : parseFloat(num)
      : '-';
    return value;
  };

  return (
    <Stack>
      {/* <RequestErrorLoader
        hideEmpty
        body={{
          request,
          data: educationData,
        }}> */}
      <Stack>
        {educationData &&
          Array.isArray(educationData) &&
          educationData?.length > 0 &&
          educationData.map((education, idx) =>
            editIndex === idx ? (
              <Box key={idx}>
                <AddEducation
                  edit
                  skills={skills}
                  onSubmit={onAdd}
                  data={education}
                  onCancel={cancelAddEdit}
                  awardTypes={srmAwardTypes}
                />
                <Divider sx={{my: 2}} />
              </Box>
            ) : (
              <Box key={idx}>
                <Grid
                  container
                  key={education.student_credential_uuid}
                  spacing={2}
                  py={3}>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      fontSize={{xs: 12, sm: 14, md: 16}}
                      color='text.primary'>
                      School:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9} alignItems='center'>
                    <div>
                      <Grid
                        py={0}
                        container
                        alignItems='flex-start'
                        justifyContent='space-between'>
                        <Grid item xs={9} sm={9} md={8} lg={9} px={0}>
                          <Typography
                            variant='h6'
                            fontSize={{xs: 12, sm: 14, md: 16}}
                            color='text.primary'>
                            {education.org_name || '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={4} lg={3} p={0}>
                          {!education.active_path ? (
                            <Stack
                              direction='row'
                              style={{position: 'absolute'}}>
                              {isMobileView ? (
                                <>
                                  <IconButton onClick={() => editData(idx)}>
                                    <Edit
                                      sx={{fontSize: {xs: 16, sm: 18}}}
                                      color='primary'
                                    />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => deleteData(education)}>
                                    <Delete
                                      sx={{fontSize: {xs: 16, sm: 18}}}
                                      color='primary'
                                    />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <Button
                                    size='large'
                                    onClick={() => editData(idx)}>
                                    Edit
                                  </Button>
                                  <Button
                                    size='large'
                                    onClick={() => deleteData(education)}>
                                    Delete
                                  </Button>
                                </>
                              )}
                            </Stack>
                          ) : (
                            <Stack
                              direction='row'
                              style={{position: 'absolute'}}>
                              {isMobileView ? (
                                <>
                                  <IconButton onClick={() => editData(idx)}>
                                    <Edit
                                      sx={{fontSize: {xs: 16, sm: 18}}}
                                      color='primary'
                                    />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      onOnBoardActivePathChange(
                                        'onboard',
                                        education.student_onboard_uuid,
                                      )
                                    }>
                                    <Delete
                                      sx={{fontSize: {xs: 16, sm: 18}}}
                                      color='primary'
                                    />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <Button
                                    size='large'
                                    onClick={() => editData(idx)}>
                                    Edit
                                  </Button>
                                  <Button
                                    size='large'
                                    onClick={() =>
                                      onOnBoardActivePathChange(
                                        'onboard',
                                        education.student_onboard_uuid,
                                      )
                                    }>
                                    Delete
                                  </Button>
                                </>
                              )}
                            </Stack>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      Date Completed:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      {/* {education.active_path ? (
                          formatViewDate(education.updated_at)
                        ) : ( */}
                      {/* )} */}
                      {education.student_onboard_uuid ? (
                        <>
                          {education.start_date &&
                          (education.end_date || education.is_present)
                            ? `${formatViewDate(education.start_date)} - ${
                                education.is_present
                                  ? 'Present'
                                  : formatViewDate(education.end_date)
                              }`
                            : formatViewDate(education.updated_at)}
                        </>
                      ) : (
                        <>
                          {education.school_start_date &&
                          (education.school_end_date || education.is_present)
                            ? `${formatViewDate(
                                education.school_start_date,
                              )} - ${
                                education.is_present
                                  ? 'Present'
                                  : formatViewDate(education.school_end_date)
                              }`
                            : '-'}
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      Degree/Award:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      {getAwardType(education.award_type) || '-'}
                    </Typography>
                  </Grid>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      Area of Focus/Major:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      {education.area_of_focus || '-'}
                    </Typography>
                  </Grid>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      GPA:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      {getGPAValue(education?.gpa) || '-'}
                    </Typography>
                  </Grid>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      Comments:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      {education.comment || '-'}
                    </Typography>
                  </Grid>
                  <Grid item textAlign='right' xs={4} sm={3} md={3} lg={3}>
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontSize={{xs: 12, sm: 14, md: 16}}>
                      Skills Learned:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    <SkillsStack
                      backgroundColor={green[400]}
                      color='primary.contrastText'
                      skillSet={education.skills}
                      margin='0px !important'
                      displayLimit={15}
                      displayShowAll
                      emptyMsg='-'
                    />
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            ),
          )}
      </Stack>
      {!viewOnly && (
        <Box py={3}>
          {isAdd && (
            <AddEducation
              skills={skills}
              onSubmit={onAdd}
              onCancel={cancelAddEdit}
              awardTypes={srmAwardTypes}
              firstTime={!educationData || educationData.length === 0}
            />
          )}
          {!isAdd && editIndex === -1 && (
            <Button
              variant='contained'
              sx={{width: '225px'}}
              onClick={() => setAdd(true)}
              size={isMobileView ? 'small' : 'large'}>
              Add Education
            </Button>
          )}
        </Box>
      )}
      {/* </RequestErrorLoader> */}
    </Stack>
  );
};

export default Education;

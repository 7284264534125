import {Menu} from 'antd';
import {ErrorBoundary} from 'core/components';

const CreateMenu = menuData => {
  const {data} = menuData;
  return (
    <ErrorBoundary nameOfComponent='core-menu'>
      <Menu>
        {data &&
          Array.isArray(data) &&
          data?.length > 0 &&
          data.map((item, idx) => (
            <Menu.Item key={idx}>
              <span
                onClick={
                  item?.dataPassed
                    ? () => item?.action(item.dataPassed)
                    : item.action
                }>
                {item.title}
              </span>
            </Menu.Item>
          ))}
      </Menu>
    </ErrorBoundary>
  );
};

export default CreateMenu;

import goSurveyData from 'data/goSurvey.json';
import {surveyWelcomeBanner} from 'assets/images';
import {Help, Reply, AccessAlarm} from '@mui/icons-material';
import {DialogBox, themeColorMode, StatsWithIcon} from 'mui-core';
import {Stack, Button, useTheme, Typography} from '@mui/material';

const {welcomeModal} = goSurveyData;
const {
  title,
  stats,
  steps,
  subHeading,
  cancelledBtnLabel,
  getStartedBtnLabel,
} = welcomeModal;

const icons = [
  <AccessAlarm sx={{color: 'white'}} fontSize='small' />,
  <Reply sx={{color: 'white'}} fontSize='small' />,
  <Help sx={{color: 'white'}} fontSize='small' />,
];

const WelcomeGoSurvey = ({visible, onSubmit, onClose}) => {
  const theme = useTheme();

  return (
    <DialogBox
      size='md'
      customizeable
      openDialog={visible}
      closeDialog={onClose}
      closeIconColor='white'
      customDialogsx={{
        '& .MuiPaper-root': {
          borderRadius: '20px',
        },
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
      }}>
      <Stack
        alignItems='center'
        justifyContent='center'
        height={{xs: '100px', md: '150px'}}
        sx={{
          // backgroundImage: `url(${surveyWelcomeBanner})`,
          backgroundImage:
            'linear-gradient(180deg, rgba(100,48,157,1) 0%, rgba(59,110,226,1) 100%);',
        }}>
        <Typography
          variant='h1'
          color='white'
          sx={{textShadow: '2px 2px 2px rgba(0,0,0,0.25)'}}>
          {title}
        </Typography>
      </Stack>
      <Stack p={{xs: 2, md: 0}} alignItems='center'>
        <StatsWithIcon
          data={stats.map(({title, count}, index) => ({
            title,
            count,
            icon: icons[index],
          }))}
          titleStyles={{
            color: themeColorMode(
              theme,
              theme.palette.shadeyBlack.main,
              'white',
            ),
          }}
          countStyles={{
            color: themeColorMode(
              theme,
              theme.palette.shadeyBlack.main,
              'white',
            ),
          }}
        />
      </Stack>
      <Stack px={2} direction='column'>
        <Typography
          mb={1}
          variant='h5'
          color={themeColorMode(
            theme,
            theme.palette.shadeyBlack.main,
            'white',
          )}>
          {subHeading}
        </Typography>
        {steps.map((step, index) => (
          <Typography
            mb={1}
            key={index}
            variant='bodyL'
            color={themeColorMode(
              theme,
              theme.palette.shadeyBlack.main,
              'white',
            )}
            dangerouslySetInnerHTML={{__html: step}}
          />
        ))}
      </Stack>
      <Stack
        my={3}
        spacing={2}
        alignItems='center'
        justifyContent='center'
        direction={{xs: 'column-reverse', md: 'row'}}>
        <Button
          onClick={onClose}
          sx={{
            minWidth: '150px',
            color:
              theme.palette.mode === 'light'
                ? theme.palette.secondary.dark
                : theme.palette.secondary.light,
          }}>
          {cancelledBtnLabel}
        </Button>
        <Button variant='contained' onClick={onSubmit} sx={{minWidth: '150px'}}>
          {getStartedBtnLabel}
        </Button>
      </Stack>
    </DialogBox>
  );
};

export default WelcomeGoSurvey;

// LOGIN SCREEN
export const LOGIN_SCREEN_OPEN = 'LOGIN_SCREEN_OPEN';
export const LOGIN_SCREEN_CLOSE = 'LOGIN_SCREEN_CLOSE';
export const LOGIN_SUCCESS_CALLBACK = 'LOGIN_SUCCESS_CALLBACK';
export const REMEMBER_CREDENTIAL = 'REMEMBER_CREDENTIAL';

// LOGIN
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOG_OUT = 'LOG_OUT';
export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN';

//REFRESH TOKEN
export const REQUEST_NEW_TOKEN = 'REQUEST_NEW_TOKEN';
export const RECEIVE_NEW_TOKEN = 'RECEIVE_NEW_TOKEN';
export const ERROR_NEW_TOKEN = 'ERROR_NEW_TOKEN';

// FORGOT
export const REQUEST_FORGOT_PASSOWORD = 'REQUEST_FORGOT_PASSOWORD';
export const SUCCESS_FORGOT_PASSOWORD = 'SUCCESS_FORGOT_PASSOWORD';
export const ERROR_FORGOT_PASSOWORD = 'ERROR_FORGOT_PASSOWORD';

// CONFIRM FORGOT
export const REQUEST_CONFIRM_FORGOT_PASSOWORD =
  'REQUEST_CONFIRM_FORGOT_PASSOWORD';
export const SUCCESS_CONFIRM_FORGOT_PASSOWORD =
  'SUCCESS_CONFIRM_FORGOT_PASSOWORD';
export const ERROR_CONFIRM_FORGOT_PASSOWORD = 'ERROR_CONFIRM_FORGOT_PASSOWORD';

import React, {useState, useEffect} from 'react';
import {Popover, Button, Checkbox} from 'antd';
import {isObject, isEmpty} from 'lodash';
import {DownOutlined} from '@ant-design/icons';
import {RequestErrorLoader, ErrorBoundary} from 'core/components';
import styles from './style.module.less';

const List = props => {
  const {
    data,
    onSelect,
    selectedItems,
    fetchData,
    request,
    params,
    disableOptions,
  } = props;
  useEffect(() => {
    // || (data && data.length <= 0)
    if (!data) {
      !request && typeof fetchData === 'function' && fetchData(params);
    }
    return () => {
      // console.log('LIST UN-MOUNTED:');
    };
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {request ? (
        <RequestErrorLoader body={{request}} />
      ) : (
        <ul>
          {data &&
            data.map(item => {
              let isChecked = false;
              selectedItems.forEach(sItem => {
                if (sItem.uuid === item.uuid) {
                  isChecked = true;
                }
              });

              return (
                <ListItem
                  data={item}
                  key={item.uuid}
                  onSelect={onSelect}
                  checked={isChecked}
                  disable={disableOptions}>
                  {item.name}
                </ListItem>
              );
            })}
        </ul>
      )}
    </>
  );
};

const ListItem = ({data, onSelect, checked, disable}) => {
  // data.count > 0 &&
  return (
    data &&
    data.count !== null &&
    data.count !== undefined && (
      <li>
        <Checkbox
          disabled={disable}
          checked={checked}
          onChange={value => onSelect(value, data)}>
          <span className='txt'>{data.customTitle || data.title}</span>
          {/* {(data.count || data.count === 0) && (
            <span className='count'>({data.count})</span>
          )} */}
        </Checkbox>
      </li>
    )
  );
};

const Filter = props => {
  const {name, data, selectedItems, setSelectedItems, params} = props;
  const [list, setList] = useState([]);

  useEffect(() => {
    params && isObject(params) && !isEmpty(params) && setList(data);
  }, [params, data]);

  const selectAndDeselectItem = (event, data) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedItems([...selectedItems, data]);
    } else {
      const filterSelectedItem = selectedItems.filter(
        item => item.uuid !== data.uuid,
      );
      setSelectedItems([...filterSelectedItem]);
    }
  };
  return (
    <ErrorBoundary nameOfComponent='core-CustomMultiSelect'>
      <div className={styles.filter}>
        <Popover
          placement='bottomLeft'
          trigger='click'
          content={
            <List {...props} data={list} onSelect={selectAndDeselectItem} />
          }
          overlayClassName={styles.customMultiSelect}>
          <Button
            icon={<DownOutlined className={styles.anticondown} />}
            className={styles.antbtn}>
            {name}
          </Button>
        </Popover>
      </div>
    </ErrorBoundary>
  );
};

export default React.memo(Filter);

export const getPathDetails = ({pathways: {pathDetails = {}} = {}}) =>
  pathDetails.data;

export const isPathLoading = ({pathways: {pathDetails = {}} = {}}) =>
  pathDetails.request;

export const getActivePath = ({pathways: {activePaths}}) => activePaths;

export const getMyPlan = ({pathways: {myPlan}}) => myPlan;

export const getOccupations = ({pathways: {occupations}}) => occupations;

export const getGenedOptions = ({pathways: {genedOptions}}) => genedOptions;

export const getRecommendedPlan = ({pathways: {recommendedPlan}}) =>
  recommendedPlan;

export const getCourses = ({pathways: {courses}}) => courses;

export const getComparePathDetails = ({pathways: {comparePaths = {}} = {}}) =>
  comparePaths;

export const getPathsToCompare = ({
  pathways: {comparePaths: {data = []} = {}} = {},
}) => data;

export const getComparePathsList = ({
  pathways: {comparePaths: {list = []} = {}} = {},
}) => list;

export const onboardTypeSelector = ({pathways: {onboardTypes}}) => onboardTypes;

export const getStudentOnboard = ({pathways: {studentOnboard}}) =>
  studentOnboard;

export const getOnboardSteps = ({studentOnboard, onboardTypes}) => {
  const {data: studentOnboardData} = studentOnboard;
  const {data} = onboardTypes || {};
  if (
    Array.isArray(data) &&
    studentOnboardData &&
    studentOnboardData.onboarding_type
  ) {
    const index = data
      .map(i => i.OnboardingType?.uuid)
      // .map(i => i.uuid)
      .indexOf(studentOnboardData.onboarding_type);
    return data[index];
  }
  return null;
};

export const savedPathSelector = ({pathways: {savedPath}}) => savedPath;

export const getPathwayOpportunities = ({pathways: {pathwayOpportunities}}) =>
  pathwayOpportunities;

import {Grid, SkillsStack} from 'mui-core';
import {Typography} from '@mui/material';

const Interests = ({data: interest}) => (
  <Grid container>
    <Grid item textAlign='left' xs={4} sm={3} md={3} lg={1}>
      <Typography
        variant='h6'
        fontSize={15}
        fontWeight={600}
        color='text.primary'>
        Interests:
      </Typography>
    </Grid>
    <Grid item xs={8} sm={9} md={9} lg={11}>
      <SkillsStack
        emptyMsg='-'
        skillSet={interest}
        displayLimit={15}
        displayShowAll={false}
        margin='0px !important'
      />
    </Grid>
  </Grid>
);

export default Interests;

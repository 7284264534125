import React from 'react';
import {Typography} from '@mui/material';
import {FmdGood} from '@mui/icons-material';
import mySkillsData from 'data/mySkills.json';
import {Accordion, SkillsChip} from 'mui-core';
import {blueGrey} from '@mui/material/colors';
import {Link} from 'react-router-dom';

const LicenseCertificationSkills = props => {
  const {
    certificationSkillsData,
    request: licensesCertificationRequest,
  } = props;
  const LicensesSkills =
    certificationSkillsData &&
    Array.isArray(certificationSkillsData) &&
    certificationSkillsData.length > 0
      ? certificationSkillsData.map(res => {
          return res.skills;
        })
      : [];
  const licenseMergedSkills = Array.prototype.concat.apply([], LicensesSkills);
  const uniqueLicenseSkills = [...new Set(licenseMergedSkills)];

  return (
    <Accordion
      defaultOpen
      outlined={true}
      icon={
        <FmdGood
          fontSize='large'
          sx={{
            color: 'white',
            padding: '6px',
            borderRadius: '4px',
            marginRight: '10px',
            backgroundColor: blueGrey[400],
          }}
        />
      }
      header={mySkillsData.MyLicensesCertificationSkills.Heading}>
      <Typography variant='body1' color='text.primary' mb={2}>
        License and Certification skills are acquired from license and
        certification courses. License and Certification skills are located in
        your Profile in the{' '}
        <Link to='/dashboard/profile/license' underline='none'>
          License and Certification{' '}
        </Link>
        section.{' '}
      </Typography>
      <SkillsChip
        data={{
          data: uniqueLicenseSkills,
          request: licensesCertificationRequest,
        }}
        backgroundColor={blueGrey[400]}
        color='primary.contrastText'
      />
    </Accordion>
  );
};
export default LicenseCertificationSkills;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';
const {TextArea} = Input;

const styleCounter = {
  bottom: '-25px',
  position: 'absolute',
  right: '5px',
  zIndex: '100',
  color: 'rgb(0, 0, 0)',
};

const Counter = ({current, total}) => (
  <span className='counter' style={styleCounter}>
    {current}/{total}
  </span>
);

class FieldWithCount extends Component {
  state = {count: 0};

  componentDidUpdate = ({value}) => {
    const {value: newValue} = this.props;
    if (newValue !== value) {
      this.setFieldValue(newValue);
    }
  };

  componentDidMount = () => this.setFieldValue(this.props.value);

  setFieldValue = initialValue =>
    this.setState({
      initialValue,
      count: (initialValue && initialValue.length) || 0,
    });

  countChar = event => {
    const {onChange} = this.props;
    const value = event.target.value;
    const count = value.length;
    if (onChange) {
      this.setState({count}, () => onChange(value));
    }
  };

  getFiledType = () => {
    const {fieldtype, showcount, ...rest} = this.props;
    const {initialValue} = this.state;
    switch (fieldtype) {
      case 'TextArea':
        return (
          <TextArea
            onChange={this.countChar}
            value={initialValue}
            {...rest}
            style={{paddingRight: 15}}
          />
        );
      case 'Input':
        return (
          <Input
            onChange={this.countChar}
            value={initialValue}
            {...rest}
            style={{paddingRight: 50}}
          />
        );
      default:
        return 'Field Type does not match!';
    }
  };

  render() {
    const {showcount, fieldtype, ...rest} = this.props;
    const {count} = this.state;

    return (
      <React.Fragment>
        {this.getFiledType()}
        {rest && rest.maxLength && showcount && (
          <Counter total={rest.maxLength} current={count} />
        )}
      </React.Fragment>
    );
  }
}

FieldWithCount.propTypes = {
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  showcount: PropTypes.bool,
  fieldtype: PropTypes.string,
};

FieldWithCount.defaultProps = {
  fieldtype: 'Input',
  showcount: false,
};

export default FieldWithCount;

// component
import {Box, Stack, styled, Typography} from '@mui/material';
import {Grid, Button, EmptyBox, DialogBox, RequestErrorLoader} from 'mui-core';
// utils
import {getGoBanner} from 'mui-core/utils';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import careerData from 'data/exploreCareer.json';

const {
  relatedCareers: {learnMoreBtn, empOpportunitiesKey},
} = careerData;

const DescriptionContainer = styled(Box)(({theme}) => ({
  padding: 32,
  width: '100%',
  position: 'relative',
  borderRadius: '20px',
  background:
    theme.palette.mode === 'light'
      ? theme.palette.lightGrey.light
      : theme.palette.dialogDarkBG.main,
  boxShadow: '0px 5px 24px rgba(0, 0, 0, 0.08)',
}));

const LearnMoreButton = styled(Button)(({theme}) => ({
  width: '140px',
  height: '36px',
  position: 'relative',
  borderRadius: '100px',
  background: theme.palette.shadeyBlack.main,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    background: theme.palette.primary.main,
  },
}));

const CareerDetails = ({
  cardInfo,
  isMobile,
  isMobileOpen,
  careerRequest,
  onMobileDetailsClose,
}) => {
  const {cip_code, career_name, description} = cardInfo || {};
  const {
    wage = {},
    skills = [],
    what_is_title = '',
    occupation_details: {cip4_image_cloudinary = ''} = {},
  } = description || {};

  const image = getGoBanner(cip4_image_cloudinary);

  function getArticle(word = '') {
    const lowerWord = word.toLowerCase();
    return ['a', 'e', 'i', 'o', 'u'].includes(lowerWord[0]) ? 'An' : 'A';
  }

  const onNavigation = () => {
    let url = '';
    if (career_name && cip_code) {
      url = `/career-details/${encodeURIComponent(career_name)}/${cip_code}`;
      window.open(url, '_blank').focus();
    }
  };

  const renderDescriptionContainer = () => {
    return (
      <DescriptionContainer>
        <Stack gap={3}>
          <Typography variant='h4' component='h2'>
            {`What Is ${getArticle(career_name)} ${career_name}`}
          </Typography>

          <Typography variant='pagedesc'>{what_is_title}</Typography>

          <Stack mx={2} gap={3}>
            <Typography variant='pagedesc'>
              Here are the top {skills?.length} skills required for this
              suggested career:
            </Typography>
            <Stack mx={2} gap={1}>
              {skills?.map(skill => (
                <Typography variant='pagedesc'>- {skill}</Typography>
              ))}
            </Stack>
          </Stack>

          <Stack gap={3}>
            <Typography variant='h4' component='h2'>
              {empOpportunitiesKey}
            </Typography>
            <Typography variant='pagedesc'>
              {wage[empOpportunitiesKey]}
            </Typography>
          </Stack>

          <Stack mt={3} mb={2} alignItems='center'>
            <LearnMoreButton
              variant='contained'
              onClick={onNavigation}
              endIcon={<KeyboardArrowRightIcon />}>
              {learnMoreBtn}
            </LearnMoreButton>
          </Stack>
        </Stack>
      </DescriptionContainer>
    );
  };

  return (
    <Box>
      <RequestErrorLoader
        fullScreen
        hideBackground
        overrideNoDataContainer={<EmptyBox emptyText='emptyMsg' />}
        body={{
          request: careerRequest,
          data: {data: cardInfo},
        }}>
        <Grid sm={8}>{renderDescriptionContainer()}</Grid>

        {isMobile && (
          <DialogBox
            disableIcon
            contentPadding='0px'
            openDialog={isMobileOpen}
            closeDialog={onMobileDetailsClose}>
            {renderDescriptionContainer()}
          </DialogBox>
        )}
      </RequestErrorLoader>
    </Box>
  );
};

export default CareerDetails;

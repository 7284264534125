// Local Jobs
export const LOCAL_JOBS_REQUEST = 'LOCAL_JOBS_REQUEST';
export const LOCAL_JOBS_SUCCESS = 'LOCAL_JOBS_SUCCESS';
export const LOCAL_JOBS_ERROR = 'LOCAL_JOBS_ERROR';
export const CLEAR_LOCAL_JOBS = 'CLEAR_LOCAL_JOBS';

// Public Insight Jobs
export const PUBLIC_INSIGHT_JOBS_REQUEST = 'PUBLIC_INSIGHT_JOBS_REQUEST';
export const PUBLIC_INSIGHT_JOBS_SUCCESS = 'PUBLIC_INSIGHT_JOBS_SUCCESS';
export const PUBLIC_INSIGHT_JOBS_ERROR = 'PUBLIC_INSIGHT_JOBS_ERROR';
export const CLEAR_PUBLIC_INSIGHT_JOBS = 'CLEAR_PUBLIC_INSIGHT_JOBS';

//Third Party Jobs
export const JOB_BOARDS_REQUEST = 'JOB_BOARDS_REQUEST';
export const JOB_BOARDS_SUCCESS = 'JOB_BOARDS_SUCCESS';
export const JOB_BOARDS_ERROR = 'JOB_BOARDS_ERROR';
export const CLEAR_JOB_BOARDS = 'CLEAR_JOB_BOARDS';

//Third Party Jobs Details
export const JOB_BOARDS_DETAILS_REQUEST = 'JOB_BOARDS_DETAILS_REQUEST';
export const JOB_BOARDS_DETAILS_SUCCESS = 'JOB_BOARDS_DETAILS_SUCCESS';
export const JOB_BOARDS_DETAILS_ERROR = 'JOB_BOARDS_DETAILS_ERROR';
export const CLEAR_JOB_BOARDS_DETAILS = 'CLEAR_JOB_BOARDS_DETAILS';

//Applied JObs
export const JOB_APPLIED_REQUEST = 'JOB_APPLIED_REQUEST';
export const JOB_APPLIED_SUCCESS = 'JOB_APPLIED_SUCCESS';
export const JOB_APPLIED_ERROR = 'JOB_APPLIED_ERROR';
export const CLEAR_APPLIED_DETAILS = 'CLEAR_APPLIED_DETAILS';

//Applied JObs
export const JOB_APPLY_REQUEST = 'JOB_APPLY_REQUEST';
export const JOB_APPLY_SUCCESS = 'JOB_APPLY_SUCCESS';
export const JOB_APPLY_ERROR = 'JOB_APPLY_ERROR';

//Saved JObs
export const JOB_SAVED_REQUEST = 'JOB_SAVED_REQUEST';
export const JOB_SAVED_SUCCESS = 'JOB_SAVED_SUCCESS';
export const JOB_SAVED_ERROR = 'JOB_SAVED_ERROR';
export const CLEAR_JOB_SAVED = 'CLEAR_JOB_SAVED';

//Save JObs
export const JOB_SAVE_REQUEST = 'JOB_SAVE_REQUEST';
export const JOB_SAVE_SUCCESS = 'JOB_SAVE_SUCCESS';
export const JOB_SAVE_ERROR = 'JOB_SAVE_ERROR';

//all JObs
export const ALL_JOBS_REQUEST = 'ALL_JOBS_REQUEST';
export const ALL_JOBS_SUCCESS = 'ALL_JOBS_SUCCESS';
export const ALL_JOBS_ERROR = 'ALL_JOBS_ERROR';
export const CLEAR_ALL_JOBS = 'CLEAR_ALL_JOBS';

// hooks
import {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
// components
import {
  Box,
  Grow,
  List,
  ListItem,
  TextField,
  IconButton,
  FormControl,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  ListItemButton,
  FormControlLabel,
  ClickAwayListener,
  Slide,
  Paper,
  Backdrop,
  Container,
  InputBase,
  Stack,
  Fade,
  Typography,
} from '@mui/material';
import {Search, DeleteOutline} from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// redux
import {
  addGlobalSearchItem,
  getGlobalSearchItems,
  getGlobalSearchHistory,
  deleteGlobalSearchItem,
} from 'redux/modules/general';
// utils
import {debounce} from 'lodash';
import {styled} from '@mui/material/styles';
import {themeColorMode} from 'mui-core/utils';
import {queryStringParse, getModuleBasePath} from 'core/utils';
import ArrowNarrowRight from 'mui-core/Icons/ArrowNarrowRight';

const StyledSearchField = styled(InputBase)(
  ({theme, isdropdownopen, mobile}) => {
    return {
      marginLeft: '16px',
      width: '100%',
      zIndex: 2,
      position: 'relative',
      // backgroundColor: themeColorMode(theme, 'white', '#444'),
      // borderRadius: isdropdownopen ? '6px 6px 0px 0px' : 0,
      '& .MuiOutlinedInput-root': {
        fontSize: 14,
      },
      // '& fieldset': {
      //   borderColor:
      //     isdropdownopen || mobile
      //       ? 'transparent !important'
      //       : themeColorMode(theme, theme.palette.primary.light, '#555'),
      // },
      '& input': {
        fontSize: '21px',
        fontFamily: 'Inter',
      },
      '& input::placeholder': {
        color: themeColorMode(theme, '#000', '#fefefe'),
        opacity: themeColorMode(theme, 0.4, 0.3),
      },
    };
  },
);

let _mobileSearchOpen = false;

export const SearchContainer = ({
  children,
  mobileSearchOpen,
  appBarRef,
  toggleMobileSwipableSearch,
}) => {
  const clickAwayProps = useRef(() => {});
  useEffect(() => {
    function keyPress(e) {
      if (e.key === 'Escape') {
        if (_mobileSearchOpen) toggleMobileSwipableSearch(false);
      }
    }
    document.addEventListener('keyup', keyPress);
    return () => {
      document.removeEventListener('keyup', keyPress);
    };
  }, []);

  useEffect(() => {
    _mobileSearchOpen = mobileSearchOpen;
  }, [mobileSearchOpen]);

  useEffect(() => {
    if (mobileSearchOpen) {
      clickAwayProps.current.onClickAway = () => {
        if (mobileSearchOpen) toggleMobileSwipableSearch(false);
      };
    } else {
      clickAwayProps.current.onClickAway = () => {};
    }
  }, [mobileSearchOpen]);

  console.log('clickAwayProps');
  return (
    // <Slide
    //   direction='down'
    //   in={mobileSearchOpen}
    //   mountOnEnter
    //   unmountOnExit
    //   container={appBarRef?.current}>
    <Box>
      <ClickAwayListener {...clickAwayProps.current}>
        <Paper
          sx={{
            minHeight: 380,
            backgroundColor: theme =>
              themeColorMode(theme, theme.palette.background.paper, '#333333'),
            backgroundImage: 'none',
            maxHeight: 500,
            borderRadius: 0,
            transform: mobileSearchOpen
              ? 'translateY(0px)'
              : 'translateY(-600px)',
            position: 'absolute',
            width: '100%',
            top: '100%',
            zIndex: 4,
            transition: '0.3s all cubic-bezier(.4,0,.6,1) 80ms',
          }}>
          {children}
        </Paper>
      </ClickAwayListener>
      <Backdrop
        // transitionDuration={500}
        onClick={() => toggleMobileSwipableSearch(false)}
        sx={{
          backdropFilter: 'blur(7px)',
          transitionDelay: '700ms',
          height: 'calc(100vh + 50px)',
          // height: '100vh',
          // position: 'fixed',
          // transition:
          //   'opacity .32s cubic-bezier(.4,0,.6,1) 80ms,visibility .32s step-end 80ms',
        }}
        open={mobileSearchOpen}
      />
    </Box>
    // </Slide>
  );
};

const SearchBar = ({mobile, toggleMobileSwipableSearch, mobileSearchOpen}) => {
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  // states
  const [openDrop, setOpenDrop] = useState();
  const [searchKey, setSearchKey] = useState('');
  //selectors
  const globalSearchHistory = useSelector(getGlobalSearchHistory) || [];
  // utils
  const {
    location: {search},
  } = history;
  const {query = ''} = queryStringParse(search);
  const showDropDown =
    globalSearchHistory &&
    Array.isArray(globalSearchHistory) &&
    globalSearchHistory.length > 0 &&
    !searchKey;
  const decodedQuery = decodeURIComponent(query);

  useEffect(() => {
    setSearchKey(decodedQuery);
    if (mobile) {
      setOpenDrop(true);
    }
    return () => {
      setSearchKey();
    };
  }, [decodedQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // get search history from local storage
    dispatch(getGlobalSearchItems());
  }, [mobile]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigateToSearch = value => {
    toggleMobileSwipableSearch(false);
    dispatch(addGlobalSearchItem(value));
    // const BasePath = getModuleBasePath('search');
    // Default to program tab
    history.push(`/search/programs?query=${value}`);
    setSearchKey(value);
    // setOpenDrop(false);
    if (mobile) {
      toggleMobileSwipableSearch(false);
    }
  };

  const onSearch = e => {
    if (e.keyCode && e.keyCode == 13) {
      if (searchKey) {
        navigateToSearch(e.target.value);
      } else {
        // If no query redirect
        history.push(`/`);
      }
    } else {
      setSearchKey(e.target.value);
    }
  };

  const onSelect = debounce(data => navigateToSearch(data), 10);

  const deleteSearchHistory = title => {
    dispatch(deleteGlobalSearchItem(title));
  };

  return (
    // <ClickAwayListener
    // // onClickAway={() => setOpenDrop(false)}
    // >
    <Container>
      <Box
        // onClick={() => setOpenDrop(true)}
        sx={
          {
            // position: 'relative',
            // maxWidth: 600,
            // minWidth: mobile ? '100%' : 200,
            // width: '80%',
            // borderRadius: openDrop && showDropDown ? '6px 6px 0px 0px' : 0,
            // boxShadow: theme =>
            //   openDrop && showDropDown
            //     ? themeColorMode(theme, '0px 0px 5px 2px #d9d9d9', 'none')
            //     : 'none',
          }
        }>
        <Fade
          in={mobileSearchOpen}
          timeout={400}
          style={{
            transformOrigin: '0 0 0',
            transitionDelay: `350ms`,
          }}>
          <Stack direction={'row'} alignItems={'center'} spacing={1} mt={2}>
            <IconButton
              size='small'
              aria-label='Search'
              disabled={!searchKey}
              onClick={() => searchKey && navigateToSearch(searchKey)}>
              <Search color='primary' />
            </IconButton>

            <FormControl fullWidth sx={{position: 'relative', top: '2px'}}>
              <FormControlLabel
                sx={{marginRight: 0}}
                control={
                  <StyledSearchField
                    aria-invalid='true'
                    isdropdownopen={openDrop && showDropDown}
                    // variant='outlined'
                    value={searchKey}
                    autoFocus={mobile}
                    // color='primary'
                    mobile={mobile}
                    size={mobile ? 'medium' : 'small'}
                    onChange={onSearch}
                    onKeyUp={onSearch}
                    aria-label='Search GoEducate '
                    placeholder='Search GoEducate '
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          size='small'
                          aria-label='Search'
                          onClick={() =>
                            searchKey && navigateToSearch(searchKey)
                          }>
                          <Search color='primary' />
                        </IconButton>
                      ),
                    }}
                  />
                }
              />
            </FormControl>
          </Stack>
        </Fade>
        <Box
          sx={{
            mb: 2,
            // position: 'absolute',
            // top: '100%',
            // left: 0,
            // width: '100%',
            // marginTop: '-1px',
          }}>
          {/* {showDropDown && openDrop && ( */}
          <Fade
            in={mobileSearchOpen}
            timeout={400}
            style={{
              transformOrigin: '0 0 0',
              transitionDelay: `450ms`,
            }}>
            <Box
              sx={{
                mt: 3,
                // borderRadius:
                //   openDrop && showDropDown ? '0px 0px 6px 6px' : 0,
                // backgroundColor: theme =>
                //   themeColorMode(theme, 'white', '#444'),
              }}>
              <List
                dense
                sx={{
                  width: '100%',
                  bgcolor: 'transparent',
                  maxHeight: '80vh',
                }}
                component='nav'
                aria-labelledby='nested-list-subheader'
                subheader={
                  globalSearchHistory?.length > 0 && (
                    <ListSubheader
                      sx={{bgcolor: 'transparent', mb: 2}}
                      component='div'
                      id='nested-list-subheader'>
                      <Typography component={'p'} variant='text-md-bold'>
                        Recent Searches
                      </Typography>
                    </ListSubheader>
                  )
                }>
                {globalSearchHistory.map((key, idx) => (
                  <Fade
                    in={mobileSearchOpen}
                    style={{
                      transformOrigin: '0 0 0',
                      transitionDelay: `${idx + 1 * 300}ms`,
                    }}
                    {...(openDrop ? {timeout: 700} : {timeout: 1000})}>
                    <ListItem
                      key={key}
                      disableRipple
                      secondaryAction={
                        <IconButton
                          onClick={() => deleteSearchHistory(key)}
                          edge='end'
                          aria-label='comments'
                          size='small'>
                          <DeleteOutline fontSize='13' />
                        </IconButton>
                      }
                      disablePadding>
                      <ListItemButton
                        disableRipple
                        onClick={() => onSelect(key)}>
                        <ListItemIcon sx={{minWidth: 35}}>
                          <ArrowNarrowRight fontSize='small' />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant='text-md-regular'>
                              {key}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  </Fade>
                ))}
              </List>
            </Box>
          </Fade>
          {/* )} */}
        </Box>
      </Box>
    </Container>
    // </ClickAwayListener>
  );
};

export default SearchBar;

import {Typography} from '@mui/material';
import {FmdGood} from '@mui/icons-material';
import mySkillsData from 'data/mySkills.json';
import {Accordion, SkillsChip} from 'mui-core';
import {yellow} from '@mui/material/colors';
import {Link} from 'react-router-dom';

const MyCourseSkills = props => {
  const {courseSkillsData, request} = props;
  const courseSkills =
    courseSkillsData &&
    Array.isArray(courseSkillsData) &&
    courseSkillsData.length > 0
      ? courseSkillsData.map(res => {
          return res.skills;
        })
      : [];
  const courseMergedSkills = Array.prototype.concat.apply([], courseSkills);
  const uniqueCourseSkills = [...new Set(courseMergedSkills)];
  return (
    <Accordion
      defaultOpen
      outlined={true}
      icon={
        <FmdGood
          fontSize='large'
          sx={{
            color: 'white',
            padding: '6px',
            borderRadius: '4px',
            marginRight: '10px',
            backgroundColor: yellow[600],
          }}
        />
      }
      header={mySkillsData.myCourseSkills.Heading}>
      <Typography variant='body1' color='text.primary' mb={2}>
        Course skills are acquired from various courses. Course skills are
        located in your Profile under the{' '}
        <Link to='/dashboard/profile/courses' underline='none'>
          Course{' '}
        </Link>
        section.{' '}
      </Typography>
      <SkillsChip
        data={{
          data: uniqueCourseSkills,
          request,
        }}
        backgroundColor={yellow[600]}
      />
    </Accordion>
  );
};
export default MyCourseSkills;

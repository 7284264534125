import * as TYPES from './constants';
import {API} from 'config';

import {
  recieveActivePaths,
  requestStudentOnboard,
  recieveStudentOnboard,
  studentOnboardErr,
  studentOnboardClear as clear,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.STUDENT_ONBOARD_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.STUDENT_ONBOARD_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.STUDENT_ONBOARD_ERR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.STUDENT_ONBOARD_CLEAR:
      return {
        ...state,
        request: false,
        error: true,
        data: null,
      };
    default:
      return state;
  }
};

export const fetchStudentOnboard = pathData => (dispatch, getState) => {
  try {
    const endPoint = API.gps.student_onboard;
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      dispatch(requestStudentOnboard());
      return services.fetchRecords(endPoint, token).then(res => {
        // const data =
        //   res && res[0] && pathData && pathData.uuid === res[0].path_uuid
        //     ? res[0]
        //     : null;
        dispatch(recieveActivePaths(res));
        dispatch(recieveStudentOnboard(res));
        return res;
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(studentOnboardErr(err));
  }
};

export const clearStudentOnboard = () => dispatch => {
  dispatch(clear());
};

import {useState} from 'react';
import styled from '@emotion/styled';
import {banner} from 'data/dashboard';
import Menu from '@mui/material/Menu';
import {editpurple} from 'assets/images';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {useMediaQuery, useTheme} from '@mui/material';

const StyledMenu = styled(Menu)(() => ({
  '& .css-6hp17o-MuiList-root-MuiMenu-list': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const StyledMenuBar = styled(MenuItem)(({isMobile}) => ({
  variant: 'caption',
  minHeight: isMobile && '20px',
}));

const {
  DropdownMenu: {editBtnText, removeBtnText},
} = banner;

const DropdownMenu = ({id, deleteImage, htmlForLabel}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={`${id}-button`}
        aria-haspopup='true'
        onClick={handleClick}
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? `${id}-menu` : undefined}
        sx={{
          minWidth: {xs: '24px', sm: '38px'},
          minHeight: {xs: '24px', sm: '38px'},
          borderRadius: {xs: '12px', sm: '18px'},
          backgroundColor: `${theme.palette.primary.main} !important`,
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.primary.main}`,
            outlineOffset: '4px',
          },
        }}>
        <img
          alt='edit'
          src={editpurple}
          width={isMobile ? '12px' : '18px'}
          height={isMobile ? '12px' : '18px'}
          style={{
            color: 'white',
            cursor: 'pointer',
            filter:
              'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(279deg) brightness(108%) contrast(100%)',
          }}
        />
      </Button>
      <StyledMenu
        open={open}
        id={`${id}-menu`}
        anchorEl={anchorEl}
        onClose={handleClose}
        aria-labelledby={`${id}-button`}
        sx={{
          height: {xs: '120px', sm: '150px'},
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <label htmlFor={htmlForLabel}>
          <StyledMenuBar onClick={handleClose} isMobile={isMobile}>
            {editBtnText}
          </StyledMenuBar>
        </label>
        <StyledMenuBar
          isMobile={isMobile}
          onClick={() => {
            handleClose();
            deleteImage();
          }}>
          {removeBtnText}
        </StyledMenuBar>
      </StyledMenu>
    </>
  );
};

export default DropdownMenu;

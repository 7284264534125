import React, {memo, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Card, Accordion, ContentBox} from 'mui-core';
import {
  Box,
  Grow,
  Stack,
  useTheme,
  Typography,
  useMediaQuery,
  CardActionArea,
} from '@mui/material';
import {home as homeJson} from 'data/dashboard';

const StyledGradientBox = styled(Box)(({bgColor}) => ({
  top: 0,
  width: '100%',
  height: '48px',
  position: 'absolute',
  ...bgColor,
}));

const StyledCard = styled(Card)(({theme}) => ({
  margin: '0 2px',
  minWidth: '220px',
  cursor: 'pointer',
  minHeight: '220px',
  position: 'relative',
  borderRadius: '12px',
  border: `0.4px solid ${theme.palette.primary.light}`,
  ':hover': {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));
const {
  quickLinks: {quickLinksInfoData, description, header},
} = homeJson;

const QuickLinks = () => {
  const theme = useTheme();
  const history = useHistory();
  const [init, setInit] = useState();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigationClick = url => {
    history.push(url);
  };

  useEffect(() => {
    setTimeout(() => {
      setInit(true);
    }, 60);
  }, []);

  return (
    <ContentBox elevation={0}>
      <Accordion
        defaultOpen
        collpsable
        header={header}
        outlined={false}
        description={description}>
        <Stack
          pt={2}
          px={1}
          pb={4}
          spacing={3}
          direction='row'
          justifyContent='start'
          sx={{overflowX: isMobile ? 'scroll' : 'auto'}}>
          {quickLinksInfoData.map((ele, idx) => (
            <Grow in={init} timeout={200 * (idx + 2)}>
              <StyledCard
                elevation={0}
                key={ele.title}
                component={CardActionArea}
                onClick={() => navigationClick(ele.cta)}>
                <StyledGradientBox bgColor={ele.bgColor} />
                <Box p='48px 8px 8px'>
                  <Typography
                    variant='h4'
                    align='center'
                    color='text.primary'
                    px={ele.titlePadding}>
                    {ele.title}
                  </Typography>
                  <Typography pt={2} align='center' variant='body2'>
                    {ele.des}
                  </Typography>
                </Box>
              </StyledCard>
            </Grow>
          ))}
        </Stack>
      </Accordion>
    </ContentBox>
  );
};

export default memo(QuickLinks);

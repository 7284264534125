import {useEffect, useState} from 'react';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {downloadResume} from 'redux/modules/resumes';
import {downloadErrorMessage} from 'data/resume.json';
import {Visibility, Add, Edit, Delete, Download} from '@mui/icons-material';
import {redWarning, infoIcon, InfoIconwhite, warningIcon} from 'assets/images';
import {
  Box,
  Stack,
  Switch,
  Divider,
  Typography,
  IconButton,
  useMediaQuery,
  FormControlLabel,
} from '@mui/material';
import {
  clearResume,
  getResumeList,
  fetchResumeList,
  updateResumeList,
  deleteSavedResume,
} from 'redux/modules/resumes';
import {fetchProfileData, fetchPrivacySetting} from 'redux/modules/profile';
import {
  Grid,
  Button,
  DialogBox,
  ContentBox,
  Speedometer,
  themeColorMode,
  NoContentNavigator,
  RequestErrorLoader,
} from 'mui-core';
import ResumePreview from './ResumePreview';
import {styled, useTheme} from '@mui/material/styles';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {
  yesText,
  nameText,
  subHeading,
  cancelText,
  createdText,
  modifiedText,
  noResumeText,
  dialogHeading,
  myResumeHeading,
  resumeStatusText,
  addtoProfileText,
  deleteResumeText,
  createResumeText,
  dialogDescription,
  deleteErrorMessage,
  noResumeHelperText,
  resumeStrengthText,
  deleteSuccessMessage,
  resumeStatusTooltipText,
  resumeStatusToggleErrorText,
  dialogActiveResumeDescription,
  resumeStatusToggleWarningText,
} from 'data/resume.json';

const customLabelPlacementValues = {
  okPlacement: {
    lg: 20,
    md: 32,
    sm: 40,
    xs: 32,
  },
  goodPlacment: {
    lg: 45,
    md: 20,
    sm: -4,
    xs: 10,
  },
  greatPlacement: {
    lg: 0,
    md: 12,
    sm: 16,
    xs: 15,
  },
};

const labelSizeValues = {
  xs: 10,
  sm: 13,
  md: 12,
  lg: 13,
};

const StyledSpeedometerContainer = styled(Box)(() => ({
  height: 150,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInfoRounded = styled('img')(() => ({
  width: 15,
  height: 15,
  cursor: 'pointer',
}));

const GradientDivider = styled(Divider)(() => ({
  borderImage:
    'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  borderImageSlice: '20%',
  borderImageWidth: '2px',
}));

const LightTooltip = styled(({className, ...props}) => (
  <Tooltip arrow {...props} classes={{popper: className}} placement='bottom' />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    padding: '1px',
    borderRadius: '8px',
    color: themeColorMode(theme, 'black', 'white'),
    boxShadow: `1px 3px 12px ${theme.palette.grayText.main}`,
    backgroundColor: themeColorMode(
      theme,
      'white',
      theme.palette.darkGray.main,
    ),
    background:
      'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}));

const GoResume = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [previewData, setPreviewData] = useState({});
  const [deleteResume, setDeleteResume] = useState({});
  const [resumeStatusConfirm, setResumeStatusConfirm] = useState({});

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const InfoIconImage =
    theme?.palette?.mode === 'dark' ? InfoIconwhite : infoIcon;
  const {data: resumeList = [], request: requestResumeList} = useSelector(
    getResumeList,
  );

  useEffect(() => {
    dispatch(fetchResumeList());
    return () => {
      dispatch(clearResume());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // functions
  const handleAddToProfileToggle = item => {
    const {status, resume_name, resume_uuid} = item;
    if (resume_uuid) {
      dispatch(
        updateResumeList({status: !status}, resume_uuid, res => {
          if (res.success) {
            setResumeStatusConfirm({});
            dispatch(fetchProfileData());
            dispatch(fetchPrivacySetting());
            enqueueSnackbar(
              `${resume_name} is ${
                status ? 'remove from talent exchange.' : 'added to profile.'
              }`,
              {
                variant: 'success',
                anchorOrigin: {horizontal: 'center', vertical: 'top'},
              },
            );
          } else {
            enqueueSnackbar(resumeStatusToggleErrorText, {
              variant: 'error',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          }
        }),
      );
    }
  };

  const onEditResume = resumeId => {
    history.push(`/resume-builder?id=${resumeId}`);
  };

  const onDeleteResume = (resumeId, status) => {
    setDeleteResume({});
    dispatch(
      deleteSavedResume(resumeId, res => {
        if (res.Success) {
          status && dispatch(fetchProfileData());
          enqueueSnackbar(deleteSuccessMessage, {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        } else {
          enqueueSnackbar(deleteErrorMessage, {
            variant: 'error',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        }
      }),
    );
  };

  const handleResumeDownload = (resumeId, resumeName) => {
    const onSuccess = response => {
      const blob = new Blob([response], {
        type: 'application/pdf',
        encoding: 'UTF-8',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = resumeName;
      link.click();
    };

    const onError = () => {
      enqueueSnackbar(downloadErrorMessage, {
        variant: 'error',
        anchorOrigin: {horizontal: 'center', vertical: 'top'},
      });
    };
    dispatch(downloadResume(resumeId, onSuccess, onError));
  };

  // components
  const renderResumeStatusToggle = item => (
    <Box pl={{xs: 1, sm: 0}}>
      <Stack direction='row' spacing={1}>
        <Typography variant='h5' color='text.primary'>
          {resumeStatusText}
        </Typography>
        <LightTooltip
          title={
            <Stack
              spacing={2}
              sx={{
                padding: '16px',
                borderRadius: '8px',
                background: theme.palette.mode === 'light' ? 'white' : 'black',
              }}>
              <Typography variant='h3' fontWeight={300} color='text.primary'>
                {resumeStatusText}
              </Typography>
              <GradientDivider variant='middle' />
              <Typography variant='body1' color='grayText.main'>
                {resumeStatusTooltipText}
              </Typography>
            </Stack>
          }>
          <StyledInfoRounded alt='info-icon' src={InfoIconImage} />
        </LightTooltip>
      </Stack>
      <FormControlLabel
        control={
          <Switch
            checked={item?.status}
            onChange={() =>
              item?.status === true
                ? setResumeStatusConfirm(item)
                : handleAddToProfileToggle(item)
            }
          />
        }
        label={
          <Typography variant='body1' color='midGray'>
            {addtoProfileText}
          </Typography>
        }
      />
    </Box>
  );

  const renderResumeStrength = resume_strength => {
    return (
      <StyledSpeedometerContainer>
        <Speedometer
          width={230}
          size='small'
          showDialValue={false}
          labelSize={labelSizeValues}
          dialValue={resume_strength}
          badgeLabel={resumeStrengthText}
          customLabelPlacement={customLabelPlacementValues}
        />
      </StyledSpeedometerContainer>
    );
  };

  const renderGOResumeMenu = resumeInfo => (
    <Stack
      gap={1}
      mt={1.8}
      direction='row'
      borderRadius={1}
      alignSelf='baseline'
      sx={{border: `1px solid ${theme.palette.grayBorder.main}`}}
      divider={<Divider orientation='vertical' variant='middle' flexItem />}>
      <IconButton onClick={() => setPreviewData(resumeInfo)} title='Preview'>
        <Visibility color='primary' sx={{fontSize: 18}} />
      </IconButton>
      <IconButton
        onClick={() =>
          handleResumeDownload(resumeInfo.resume_uuid, resumeInfo.resume_name)
        }
        title='Download'>
        <Download color='primary' sx={{fontSize: 18}} />
      </IconButton>
      <IconButton
        onClick={() => onEditResume(resumeInfo?.resume_uuid)}
        title='Edit'>
        <Edit color='primary' sx={{fontSize: 18}} />
      </IconButton>
      <IconButton onClick={() => setDeleteResume(resumeInfo)} title='Delete'>
        <Delete color='primary' sx={{fontSize: 18}} />
      </IconButton>
    </Stack>
  );

  const renderNoResumefound = () => (
    <Box
      mt={2}
      mb={1}
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background:
          'linear-gradient(0deg, rgba(123, 96, 223, 0.08), rgba(123, 96, 223, 0.08))',
      }}>
      <Box pb={3}>
        <NoContentNavigator
          noDataIcon={
            <Visibility
              color='primary'
              fontSize={'large'}
              sx={{mt: 2, mb: -2}}
            />
          }
          heading={noResumeText}
          message={noResumeHelperText}
        />
      </Box>
    </Box>
  );

  const buttonProps = {
    color: 'primary',
    startIcon: <Add />,
    variant: 'contained',
    sx: {maxWidth: '200px'},
    size: isMobile ? 'small' : 'medium',
    onClick: () => history.push('/resume-builder'),
  };

  return (
    <ContentBox elevation={0} sx={{p: {xs: 2, md: 4}}}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='h4' color='text.primary'>
          {myResumeHeading}
        </Typography>
        <Button {...buttonProps}>{createResumeText}</Button>
      </Stack>
      <Typography
        my={1}
        mb={2}
        variant='body2'
        fontWeight={300}
        color='text.primary'>
        {subHeading}
      </Typography>
      <Divider sx={{my: 1}} />
      <RequestErrorLoader
        minHeight={100}
        body={{
          data: resumeList || [],
          request: requestResumeList,
        }}
        overrideNoDataContainer={renderNoResumefound()}>
        {resumeList &&
          Array.isArray(resumeList) &&
          resumeList.length > 0 &&
          resumeList.map(item => (
            <Box key={item?.resume_uuid}>
              <Grid
                container
                px={{xs: 0, md: 2}}
                alignItems='center'
                justifyContent='center'
                key={item?.resume_uuid}>
                <Grid item xs={12} md={12} lg={1.8}>
                  <Stack
                    direction={{xs: 'row', md: 'column'}}
                    justifyContent={{xs: 'space-between', md: 'center'}}>
                    {renderResumeStatusToggle(item)}
                    {isMobile && renderGOResumeMenu(item)}
                  </Stack>
                </Grid>
                {isDesktop && (
                  <Grid height={200} item xs={12} md={12} lg={0.2}>
                    <Divider orientation='vertical' />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4.5}
                  mt={3}
                  mb={{xs: 4, md: 3, lg: 0}}
                  lg={4}>
                  {renderResumeStrength(item?.resume_strength || 0)}
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={4}>
                  <Grid container alignItems='center'>
                    <Grid item xs={5} sm={3} md={3} lg={3} p={1}>
                      <Typography
                        variant='body2'
                        color='midGray'
                        textAlign='right'>
                        {nameText}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={9} md={9} lg={9} p={1}>
                      <Typography
                        variant='body2'
                        color='text.primary'
                        fontSize={{xs: 12, sm: 14, md: 16}}>
                        {(item?.resume_name && item?.resume_name) || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} sm={3} md={3} lg={3} p={1}>
                      <Typography
                        variant='body2'
                        textAlign='right'
                        color='midGray'>
                        {createdText}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={9} md={9} lg={9} p={1}>
                      <Typography
                        variant='body2'
                        color='text.primary'
                        fontSize={{xs: 12, sm: 14, md: 16}}>
                        {(item?.created_at &&
                          moment(item?.created_at).format('MM-DD-YYYY')) ||
                          '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} sm={3} md={3} lg={3} p={1}>
                      <Typography
                        variant='body2'
                        textAlign='right'
                        color='midGray'>
                        {modifiedText}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={9} md={9} lg={9} p={1}>
                      <Typography
                        variant='body2'
                        color='text.primary'
                        fontSize={{xs: 12, sm: 14, md: 16}}>
                        {(item?.updated_at &&
                          moment(item?.updated_at).format('MM-DD-YYYY')) ||
                          '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {!isMobile && renderGOResumeMenu(item)}
                {/* {!isMobile && (
                  <Grid item xs={12} sm={6} md={2.5} lg={2}>
                    <Stack gap={1}>
                      <Button
                        variant='outlined'
                        sx={{
                          maxWidth: '200px',
                        }}
                        onClick={() => onEditResume(item?.resume_uuid)}>
                        {editResumeText}
                      </Button>
                      <Button
                        variant='outlined'
                        sx={{
                          maxWidth: '200px',
                        }}
                        onClick={() => setPreviewData(item)}>
                        {resumePreviewLabel}
                      </Button>
                      <Button
                        variant='outlined'
                        sx={{
                          maxWidth: '200px',
                        }}
                        onClick={() => setDeleteResume(item)}>
                        {deleteResumeText}
                      </Button>
                    </Stack>
                  </Grid>
                )} */}
              </Grid>
              {resumeList.length > 1 && <Divider sx={{my: 2}} />}
            </Box>
          ))}

        {deleteResume?.resume_uuid && (
          <DialogBox
            openDialog={deleteResume?.resume_uuid}
            closeDialog={() => setDeleteResume({})}
            actions={
              <>
                <Button
                  variant='outlined'
                  onClick={() => setDeleteResume({})}
                  sx={{minWidth: {xs: '80px', md: '168px'}}}>
                  {cancelText}
                </Button>
                <Button
                  variant='contained'
                  onClick={() =>
                    onDeleteResume(
                      deleteResume?.resume_uuid,
                      deleteResume?.status,
                    )
                  }
                  sx={{minWidth: {xs: '80px', md: '168px'}}}>
                  {deleteResumeText}
                </Button>
              </>
            }
            showIcon={deleteResume?.status ? redWarning : warningIcon}>
            <Stack spacing={1}>
              <Typography textAlign='center' variant='h4' color='text.primary'>
                {`${dialogHeading} ${deleteResume.resume_name}?`}
              </Typography>
              <Typography
                variant='body1'
                textAlign='center'
                color='text.primary'>
                {deleteResume?.status
                  ? dialogActiveResumeDescription
                  : dialogDescription}
              </Typography>
            </Stack>
          </DialogBox>
        )}

        {resumeStatusConfirm?.resume_uuid && (
          <DialogBox
            showIcon={warningIcon}
            actions={
              <>
                <Button
                  variant='outlined'
                  onClick={() => setResumeStatusConfirm({})}
                  sx={{minWidth: {xs: '80px', md: '168px'}}}>
                  {cancelText}
                </Button>
                <Button
                  variant='contained'
                  onClick={() => handleAddToProfileToggle(resumeStatusConfirm)}
                  sx={{minWidth: {xs: '80px', md: '168px'}}}>
                  {yesText}
                </Button>
              </>
            }
            openDialog={resumeStatusConfirm?.resume_uuid}
            closeDialog={() => setResumeStatusConfirm({})}>
            <Stack spacing={1}>
              <Typography textAlign='center' variant='h4' color='text.primary'>
                {`Are you sure you want to turn off the ${resumeStatusConfirm.resume_name}?`}
              </Typography>
              <Typography
                variant='body1'
                textAlign='center'
                color='text.primary'>
                {resumeStatusToggleWarningText}
              </Typography>
            </Stack>
          </DialogBox>
        )}

        {previewData?.resume_uuid && (
          <ResumePreview
            data={previewData}
            onCloseModal={() => {
              setPreviewData({});
            }}
            onOpenModal={previewData?.resume_uuid}
          />
        )}
      </RequestErrorLoader>
    </ContentBox>
  );
};

export default GoResume;

import {useState, useEffect} from 'react';
import {Bar} from 'react-chartjs-2';
// import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-datalabels';
//chart.js": "2.9.3",
const getChartOptions = (
  yAxisLabelFormatter,
  toolTipFormatter,
  datalabelFormatter,
  barThickness,
) => ({
  responsive: true,
  layout: {
    padding: {
      top: 50,
    },
  },
  maintainAspectRatio: false,
  // aspectRatio: 1,
  cornerRadius: 0,
  // height: 200,
  legend: {
    display: false,
  },
  tooltips: {
    titleColor: 'rgb(0 0 0)',
    bodyColor: '#rgb(0 0 0)',
    borderColor: '#rgb(0 0 0)',
    cornerRadius: 2,
    footerColor: '#rgb(0 0 0)',
    backgroundColor: 'white',
    // color: '#000000',
    // textColor: '#000000',
    callbacks: {
      label: toolTipFormatter,
      labelTextColor: function (context) {
        return '#000000';
      },
    },
  },
  scales: {
    xAxes: [
      {
        barThickness: barThickness || 62,
        display: true,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: yAxisLabelFormatter,
          // max: 5,
          // min: 0,
          // stepSize: 1,
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      display: true,
      color: 'black',
      align: 'end',
      anchor: 'end',
      font: {size: '11'},
      formatter: value => {
        return datalabelFormatter(value);
      },
    },
  },
});

const getChartData = (canvas, datasets, labels) => {
  const data = {datasets, labels};
  return data;
};

const MultiBarChart = ({
  datasets,
  labels,
  yAxisLabelFormatter,
  datalabelFormatter,
  toolTipFormatter,
  barThickness,
}) => {
  const [options, setOptions] = useState({});
  useEffect(() => {
    setOptions(
      getChartOptions(
        yAxisLabelFormatter,
        toolTipFormatter,
        datalabelFormatter,
        barThickness,
      ),
    );
  }, [datasets]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      <div style={{height: 400}}>
        <Bar
          // plugins={[ChartDataLabels]}
          redraw={true}
          data={c => getChartData(c, datasets, labels)}
          options={options}
        />
      </div>
    </div>
  );
};
export default MultiBarChart;

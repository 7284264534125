import Home from './subModules/Home';
import Career from './subModules/Career';
import GoResume from './subModules/GoResume';
import Settings from './subModules/Settings';
import DashBoardContainer from './Container';
import Opportunities from './subModules/Opportunities';
import PreviewProfile from './subModules/PreviewProfile';

const NewDashboardModules = {
  Home,
  Career,
  GoResume,
  Settings,
  Opportunities,
  PreviewProfile,
  DashBoardContainer,
};

export default Home;
export {NewDashboardModules};

import {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  selectClasses,
} from '@mui/material';
import {styled} from '@mui/material/styles';

const StylesSelect = styled(Select)(({theme}) => ({
  // fontFamily: theme.typography.fontFamilyBold,
  paddingLeft: 8,
  minWidth: 216,
  paddingRight: 8,
}));
const ProgramStatusChanger = ({
  id,
  loadingPathStatus,
  options,
  defValue,
  onMenuSelection,
}) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defValue);

  const onSelect = item => {
    let userSelection = options.find(option => option.key === item);
    setOpen(false);
    setValue(userSelection);
    onMenuSelection(item, id);
  };

  const loading = loadingPathStatus === id;

  return (
    <Box>
      {/* <Paper> */}
      <FormControl sx={{mt: 1, minWidth: 80}}>
        <InputLabel id='demo-simple-select-autowidth-label'>Status</InputLabel>
        <StylesSelect
          labelId='program-status-select-label'
          id='program-status-select'
          value={value['key']}
          onChange={event => onSelect(event.target.value)}
          variant='outlined'
          label='Status'
          title='Program Status'
          aria-label='Program Status'
          aria-expanded={open ? 'true' : undefined}
          disabled={loading}
          sx={{
            // fontWeight: 600,
            // fontFamily: 'Nunito Bold',
            border: 'none !important',
            fontSize: {xs: 10, sm: 12, md: 15},
            whiteSpace: {xs: 'noWrap', sm: 'none'},
          }}>
          {options.map(({key, label}, index) => (
            <MenuItem
              key={`${id}-${key}-${index}`}
              value={key}
              disabled={value.key === key}
              selected={value.key === key}>
              {label}
            </MenuItem>
          ))}
        </StylesSelect>
      </FormControl>
      {/* </Paper> */}
    </Box>
  );
};

ProgramStatusChanger.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  defValue: PropTypes.string,
  onMenuSelection: PropTypes.func,
  options: PropTypes.array.isRequired,
};

export default ProgramStatusChanger;

import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {getProfileCompletedTabs} from 'core/utils';
import {profileModuleLength} from '../..';
import {connect} from 'react-redux';
import {
  profileDataSelector,
  studentCredentialsSelector,
} from 'redux/modules/profile';
import {getActivePath} from 'redux/modules/pathways';

function Progress({profileData, studentCredentials, activePaths}) {
  const {data: activePathsData = []} = activePaths || {};
  const [completedTabs, setCompletedTabs] = React.useState({});
  React.useEffect(() => {
    setCompletedTabs(
      getProfileCompletedTabs(profileData, studentCredentials, activePathsData),
    );
  }, [activePathsData, profileData, studentCredentials]);
  const completedLength =
    completedTabs && typeof completedTabs === 'object'
      ? Object.keys(completedTabs).length
      : 0;
  const getValue = () => {
    try {
      return (100 * completedLength) / profileModuleLength;
    } catch (err) {
      return 0;
    }
  };
  const value = getValue();
  console.log('Progress', profileData, studentCredentials, activePaths, value);
  return (
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
      <CircularProgress
        variant='determinate'
        value={value}
        thickness={5}
        sx={{
          zIndex: 4,
          position: 'relative',
          'svg circle': {stroke: 'url(#linearColors)', strokeLinecap: 'round'},
        }}
        size={160}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '100%',
          borderWidth: '18px',
          borderStyle: 'solid',
          borderColor: 'action.hover',
        }}>
        <Typography variant='body1' fontSize={12} color='text.secondary'>
          Completion
        </Typography>
        <Typography
          variant='subtitle2'
          mt={1}
          fontSize={24}
          component='div'
          color='text.secondary'>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
const mapStateToProps = state => ({
  profileData: profileDataSelector(state),
  activePaths: getActivePath(state),
  studentCredentials: studentCredentialsSelector(state),
});
export default connect(mapStateToProps, {})(Progress);

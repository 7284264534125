import * as React from 'react';
import PropTypes from 'prop-types';
import {icontrophy} from 'assets/images';
import {styled} from '@mui/material/styles';
import {LineArrowDown} from 'mui-core/Icons';
import {calculateCourseUnits} from 'core/utils';
import {Card, Grid, themeColorMode} from 'mui-core';
import {
  Box,
  Stack,
  Paper,
  Tooltip,
  useTheme,
  Typography,
  useMediaQuery,
} from '@mui/material';

const MapContainer = styled(Paper)(({theme}) => ({
  borderRadius: 15,
  padding: '24px 15px',
  position: 'relative',
}));
const MapTitle = styled(Box)(({theme}) => ({
  top: -15,
  left: 25,
  position: 'absolute',
  padding: '0px 11px 0px 7px',
  backgroundColor: theme.palette.background.paper,
}));
const BottomCardStack = styled(Stack)(({theme}) => ({
  height: 57,
  backgroundColor: themeColorMode(theme, '#E6E7FF', theme.palette.common.black),
}));
const Tag = styled(Box)(({theme, colors = {}, type}) => {
  console.log('Tag', theme, colors, type, theme.palette[colors[type]]);
  const color = type
    ? theme.palette[colors[type]]
      ? themeColorMode(
          theme,
          theme.palette[colors[type]].main,
          theme.palette[colors[type]].dark,
        )
      : theme.palette.grey[400]
    : theme.palette.grey[400];
  return {
    top: 18,
    left: 0,
    height: 19,
    display: 'flex',
    borderLeftWidth: 3,
    position: 'absolute',
    alignItems: 'center',
    borderLeftColor: color,
    borderLeftStyle: 'solid',
    backgroundColor: themeColorMode(theme, '#F5F5F5', theme.palette.grey[800]),
  };
});

const MapView = ({
  term,
  index,
  colors,
  isLast,
  showModal = false,
  awardTypeName = '',
  handleClickModalOpen,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box>
      <MapContainer variant='outlined' key={term.uuid}>
        <MapTitle>
          <Typography
            variant='h5'
            color='text.primary'
            sx={isMobile ? {fontSize: 15} : {}}>
            Term {index + 1} {term.term_name && `- ${term.term_name}`} - ({' '}
            {calculateCourseUnits(term.courses)} Credits/Units )
          </Typography>
        </MapTitle>
        {term.courses &&
        Array.isArray(term.courses) &&
        term.courses.length > 0 ? (
          <Grid container spacing={4}>
            {term.courses.map((course, cIdx) => {
              const TYPE = course?.course_type?.toLowerCase() || 'none';
              return (
                <Grid lg={3} md={4} sm={6} xs={12} key={course.course_id}>
                  <Card
                    onClick={() => handleClickModalOpen(course)}
                    sx={{
                      minHeight: 80,
                      position: 'relative',
                      borderRadius: '15px',
                      cursor: showModal ? 'pointer' : 'auto',
                    }}>
                    {course.course_type && (
                      <Tag type={TYPE} colors={colors}>
                        <Typography
                          px={2}
                          fontWeight={600}
                          textTransform={'capitalize'}
                          fontSize={'12px'}
                          color='text.primary'>
                          {course.course_type === 'general'
                            ? 'General Ed'
                            : course.course_type}
                        </Typography>
                      </Tag>
                    )}

                    {/* <Box sx={{height: 100}}> */}
                    <Stack
                      sx={{height: 110}}
                      overflow='hidden'
                      pt={4}
                      alignItems={'center'}
                      justifyContent='center'>
                      <Typography
                        variant='h6'
                        noWrap
                        align='center'
                        color={'text.primary'}
                        width='calc(100% - 24px)'>
                        {course.course_id}
                      </Typography>
                      <Typography mt={1} variant='body2' color={'GrayText'}>
                        {course.units} Credits/Units
                      </Typography>
                    </Stack>
                    {/* </Box> */}
                    <BottomCardStack
                      alignItems={'center'}
                      justifyContent='center'>
                      <Tooltip
                        title={
                          course.course_name && course.course_name.length >= 40
                            ? course.course_name
                            : ''
                        }>
                        <Typography
                          px={2}
                          variant='body2'
                          sx={{
                            display: '-webkit-box',
                            '-webkit-line-clamp': '2',
                            '-webkit-box-orient': 'vertical',
                            overflow: 'hidden',
                          }}>
                          {course.course_name && course.course_name.length >= 40
                            ? `${course.course_name.substr(0, 40)}...`
                            : course.course_name}
                        </Typography>
                      </Tooltip>
                    </BottomCardStack>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography variant='subtitle2' p={2}>
            No courses have been planned for this term.
          </Typography>
        )}
      </MapContainer>
      {!isLast ? (
        <Stack
          mb={2}
          height={90}
          direction='row'
          alignItems='center'
          justifyContent='center'>
          <LineArrowDown
            sx={{fontSize: 25, height: '2em', width: '1em', ml: '16px'}}
          />
        </Stack>
      ) : (
        <Stack
          mb={2}
          height='auto'
          direction='column'
          alignItems='center'
          justifyContent='center'>
          <LineArrowDown
            sx={{fontSize: 25, height: '2em', width: '1em', ml: '16px'}}
          />
          <img src={icontrophy} alt='trophy' />
          <Typography variant='h4' color='text.primary' mt={2}>
            {awardTypeName}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

MapView.propTypes = {
  term: PropTypes.object,
  isLast: PropTypes.bool,
  index: PropTypes.number,
  colors: PropTypes.string,
  showModal: PropTypes.bool,
  awardTypeName: PropTypes.string,
  handleClickModalOpen: PropTypes.func,
};

export default MapView;

//External imports
import {useEffect, useRef} from 'react';
import {GOOGLE_CLIENT_ID, API} from 'config';
import {Services} from 'core/Services';
import {message} from 'antd';
import './style.less';
import {useSnackbar} from 'notistack';
import {
  addQueryParamToURL,
  getURLQueryParams,
  handleSignUpNewUserURL,
  pushNewUserEvents,
  removeQueryParamToURL,
} from 'core/utils';

const services = new Services();

const loadScript = src =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = err => reject(err);
    document.body.appendChild(script);
  });

const GoogleButton = ({instance, login, onSubmitToggle = () => {}}) => {
  const {enqueueSnackbar} = useSnackbar();
  const {data: instanceData} = instance || {};
  const googleButton = useRef(null);

  const updateUser = currentUser => {
    console.log(currentUser);
    const access_token = currentUser.credential;
    if (access_token) {
      const data = {
        google_token: access_token,
      };
      data.instance = '';
      if (instanceData && instanceData.subdomain) {
        data.instance = instanceData.subdomain;
      }
      services
        .createUpdateRecord({}, API.gps.signup, data, 'POST')
        .then(res => {
          const {status, google_account} = res;
          if (status === 'Success') {
            if (!getURLQueryParams('signin')) pushNewUserEvents();
            onLogin(access_token);
          } else {
            onSubmitToggle(true);
          }
          if (google_account) {
            enqueueSnackbar('Success! Account Successfully registered.', {
              variant: 'success',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          }
          if (res && res.Error) {
            enqueueSnackbar(res.Error, {
              variant: 'error',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
            // setLoading(false);
          }
        })
        .catch(err => {
          onSubmitToggle(true);
          // setLoading(false);
        });
    }
  };

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';
    const id = GOOGLE_CLIENT_ID;

    loadScript(src)
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: id,
          callback: updateUser,
        });
        google.accounts.id.renderButton(googleButton.current, {
          theme: 'outline',
          size: 'large',
        });
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, [updateUser]);

  function handleCredentialResponse(response) {
    console.log('Encoded JWT ID token: ', response);
  }

  const onLogin = async access_token => {
    const loginData = {google_token: access_token};
    await login(loginData, false, {}, () => {
      removeQueryParamToURL(['signup', 'signin']);
    });
    onSubmitToggle(true);
    // setLoading(false);
  };

  return <div className='gbtn' ref={googleButton}></div>;
};

export default GoogleButton;

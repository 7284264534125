import * as TYPES from './constants';

// CAREER INTERREST SURVEY
export const requestCareerIntrestSurvey = () => ({
  type: TYPES.CAREER_INTEREST_SURVEY_REQUEST,
});
export const careerIntrestSurveySuccess = json => ({
  type: TYPES.CAREER_INTEREST_SURVEY_SUCCESS,
  payload: json,
});
export const careerIntrestSurveyError = err => ({
  type: TYPES.CAREER_INTEREST_SURVEY_ERROR,
  err,
});

export const requestCareerIntrestSurveyResult = () => ({
  type: TYPES.CAREER_INTEREST_SURVEY_RESULT_REQUEST,
});
export const careerIntrestSurveySuccessResult = json => ({
  type: TYPES.CAREER_INTEREST_SURVEY_RESULT_SUCCESS,
  payload: json,
});
export const careerIntrestSurveyErrorResult = err => ({
  type: TYPES.CAREER_INTEREST_SURVEY_RESULT_ERROR,
  err,
});

export const requestGoSurveyQuestion = () => ({
  type: TYPES.GO_SURVEY_QUESTIONS_REQUEST,
});
export const goSurveyQuestionSuccess = json => ({
  type: TYPES.GO_SURVEY_QUESTIONS_SUCCESS,
  payload: json,
});
export const goSurveyQuestionError = err => ({
  type: TYPES.GO_SURVEY_QUESTIONS_ERROR,
  err,
});

export const requestGoSurveyResults = () => ({
  type: TYPES.GO_SURVEY_RESULTS_REQUEST,
});
export const goSurveyResultsSuccess = json => ({
  type: TYPES.GO_SURVEY_RESULTS_SUCCESS,
  payload: json,
});
export const goSurveyResultsError = err => ({
  type: TYPES.GO_SURVEY_RESULTS_ERROR,
  err,
});
export const goSurveyResultsClear = () => ({
  type: TYPES.GO_SURVEY_RESULTS_CLEAR,
});

export const requestGoSurveyResultDetails = () => ({
  type: TYPES.GO_SURVEY_RESULT_DETAILS_REQUEST,
});
export const goSurveyResultDetailsSuccess = json => ({
  type: TYPES.GO_SURVEY_RESULT_DETAILS_SUCCESS,
  payload: json,
});
export const goSurveyResultDetailsError = err => ({
  type: TYPES.GO_SURVEY_RESULT_DETAILS_ERROR,
  err,
});
export const goSurveyResultDetailsClear = () => ({
  type: TYPES.GO_SURVEY_DETAILS_CLEAR,
});

export const requestGoSurveyAnswers = () => ({
  type: TYPES.GO_SURVEY_ANSWERS_REQUEST,
});
export const goSurveyAnswersSuccess = json => ({
  type: TYPES.GO_SURVEY_ANSWERS_SUCCESS,
  payload: json,
});
export const goSurveyAnswersError = err => ({
  type: TYPES.GO_SURVEY_ANSWERS_ERROR,
  err,
});
export const goSurveyAnswersClear = () => ({
  type: TYPES.GO_SURVEY_ANSWERS_CLEAR,
});
export const clearSurvey = () => dispatch => {
  dispatch(goSurveyAnswersClear());
  dispatch(goSurveyResultsClear());
};

import {Button} from 'antd';
import {plaintick, shareIcon} from 'assets/images';
const OpportunityActions = ({
  applied = false,
  saved = false,
  onApply,
  onSavePath,
  loaders,
  onRemoveApply,
  onUnSaveJob,
  appliedOpportunities,
  savedOpportunities,
  jobURL,
}) => {
  const applyClasses = ['actnbtn', 'applybtn'];
  if (applied) applyClasses.push('success');
  const saveClasses = ['actnbtn', 'applybtn'];
  if (saved) saveClasses.push('success');
  if (!saved) saveClasses.push('saveForLaterBtn');
  const openJobURL = () => {
    window.open(jobURL, '_blank');
  };
  return (
    <>
      <div className='job_opp_button'>
        {jobURL ? (
          <Button
            type='primary'
            loading={appliedOpportunities.request || loaders.apply}
            disabled={appliedOpportunities.request || loaders.apply}
            className={'actnbtn ex-url'}
            onClick={openJobURL}>
            {/* {applied && (
              <img src={plaintick} className='btnIcon pr-2' alt='plaintick' />
            )} */}
            <img
              src={shareIcon}
              className='btnIcon pr-2'
              alt='share'
              style={{filter: 'brightness(0) invert(1)', width: 30}}
            />
            Apply On Company Website
          </Button>
        ) : (
          <Button
            type='primary'
            loading={appliedOpportunities.request || loaders.apply}
            disabled={appliedOpportunities.request || loaders.apply}
            className={applyClasses.join(' ')}
            onClick={applied ? onRemoveApply : onApply}>
            {applied && (
              <img src={plaintick} className='btnIcon pr-2' alt='plaintick' />
            )}
            {applied ? 'Applied' : 'Apply'}
          </Button>
        )}

        {!applied && (
          <Button
            className={saveClasses.join(' ')}
            loading={savedOpportunities.request || loaders.save}
            disabled={savedOpportunities.request || loaders.save}
            onClick={saved ? onUnSaveJob : onSavePath}>
            {saved && (
              <img src={plaintick} className='btnIcon pr-2' alt='plaintick' />
            )}
            {saved ? 'Saved' : 'SAVE FOR LATER'}
          </Button>
        )}
      </div>
    </>
  );
};

export default OpportunityActions;

import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import {default as MUIButton} from '@mui/material/Button';

const StyledButton = styled(MUIButton)(({theme, variant}) => ({
  color:
    variant === 'outlined'
      ? theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main
      : variant === 'contained'
      ? 'white'
      : theme.palette.primary.main,
  '&:hover': {
    backgroundColor: variant === 'outlined' && theme.palette.primary.main,
    color: variant === 'outlined' && theme.palette.primary.contrastText,
  },
}));

const Button = props => {
  const {loading, children, ...restProps} = props;
  return (
    <>
      {!loading ? (
        <StyledButton {...restProps}>{children}</StyledButton>
      ) : (
        <LoadingButton loading {...restProps}>
          {children}
        </LoadingButton>
      )}
    </>
  );
};
Button.propTypes = {
  ...MUIButton.propTypes,
  rounded: PropTypes.bool,
  loading: PropTypes.bool,
  uppercase: PropTypes.bool,
  ...LoadingButton.propTypes,
};
export default Button;

import React, {Fragment, useEffect, useState} from 'react';
import {Row, Col, Popover} from 'antd';
import ReactPlayer from 'react-player';
import {useDispatch} from 'react-redux';
import {useCheckMobileView} from 'core/hooks';
import LocalSalaryModal from './LocalSalaryModal';
import {CareerTrends, ErrorBoundary} from 'mui-core';
import {hotTechnology, onetinit} from 'assets/images';
import {fetchOccupationDetails} from 'redux/modules/occupation';
import {careerOverviewData} from 'data/occupation.json';

const CareerOverview = ({
  history,
  location,
  setLocation,
  occupationDetails: {data},
  //jobZoneDetails: {data: {job_zone} = {}},
  // appConfig: {isMobileView},
}) => {
  const dispatch = useDispatch();
  const {isMobileView} = useCheckMobileView();
  const [videoError, setVideoErr] = useState(false);
  useEffect(() => {
    setVideoErr(false);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps
  const {
    occupation_name,
    occupation_onnet,
    occupation_details: {
      what_they_do,
      on_the_job,
      // education_job_zone,
      education_usually_needed,
      knowledge,
      skills,
      abilities,
      technology,
      work_values,
      work_styles,
      video_details,
      personality: {description, element} = {},
    } = {},
  } = data || {};

  const {VideoCode} = video_details && video_details[0] ? video_details[0] : {};
  //const considerJobZone = job_zone && job_zone[education_job_zone - 1];
  const [localSalaryModal, setLocalSalaryModal] = useState(false);

  const onLocalSalaryInfoClick = () => {
    setLocalSalaryModal(true);
  };

  const onLocationChange = location => {
    let updatedParams = {query: occupation_name};
    if (typeof location === 'object') {
      updatedParams = {
        ...updatedParams,
        msa_code: location.msa_code,
        location: location.location,
      };
    } else {
      updatedParams = {...updatedParams, location: location};
    }
    if (setLocation) {
      setLocation(updatedParams.location);
    }
    dispatch(fetchOccupationDetails(updatedParams));
  };

  return (
    <ErrorBoundary
      nameOfComponent='mod-comp-occupation-career-overview'
      showDialog={true}>
      <Row gutter={[52, 8]} className='my-4'>
        <Col lg={12} md={12} sm={24} xs={24} className='overview-content'>
          <div
            className='outerBorder'
            style={videoError ? {borderTop: 'double 1px transparent'} : {}}>
            {!videoError && (
              <div className='header'>
                <div>CAREER VIDEO</div>
              </div>
            )}

            <div className='content px-4 pt-1 pb-4 education'>
              {!videoError && (
                <>
                  <ReactPlayer
                    width={'100%'}
                    onError={() => setVideoErr(true)}
                    controls
                    url={`https://cdn.careeronestop.org/OccVids/OccupationVideos/${VideoCode}.mp4`}
                  />
                </>
              )}
              <br />
              <h2>{careerOverviewData.whatWouldYouDoTxt}:</h2>
              <p className='mb-4'>{what_they_do}</p>
            </div>
          </div>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <CareerTrends
            data={data}
            history={history}
            location={location}
            onLocationChange={onLocationChange}
            onLocalSalaryInfoClick={onLocalSalaryInfoClick}
          />
          <div className='outerBorder'>
            <div className='header'>
              <div>{careerOverviewData.educNeededTxt}</div>
            </div>
            <div className='content px-4 pt-3 pb-2 education'>
              {education_usually_needed &&
              Array.isArray(education_usually_needed) &&
              education_usually_needed.length > 0 ? (
                <div className='d-flex'>
                  <ul className='list_vertical'>
                    {education_usually_needed &&
                      Array.isArray(education_usually_needed) &&
                      education_usually_needed.map((element, idx) => (
                        <li key={`education-element-${idx}`}>{element}</li>
                      ))}
                  </ul>
                </div>
              ) : (
                <div className='NotAvailable'>
                  {careerOverviewData.noDataTxt}
                </div>
              )}
            </div>
          </div>

          <br />

          <div className='outerBorder'>
            <div className='header'>
              <div>{careerOverviewData.onTheJobTxt}</div>
            </div>

            <div className='content px-4 pt-3 pb-2 education'>
              <ul className='list mb-4'>
                {on_the_job &&
                  Array.isArray(on_the_job) &&
                  on_the_job.map((point, idx) => {
                    return <li key={idx}>{point}</li>;
                  })}
              </ul>
            </div>
          </div>
        </Col>
      </Row>

      <div className='d-block my-2 mt-4 knowledge'>
        <div className='layout-container'>
          <div className='mb-4 px-2 item'>
            <div className='outerBorder'>
              <div className='header'>
                <div>{careerOverviewData.knowledgeTxt}</div>
              </div>
              <div
                className='content px-4 pt-3 pb-2'
                style={{overflow: 'auto', height: '350px'}}>
                {knowledge &&
                Array.isArray(knowledge) &&
                knowledge.length > 0 ? (
                  knowledge.map(({title, element}, index) => (
                    <Fragment key={`knowledge-${index}`}>
                      <h5>{title}</h5>
                      <ul className='list'>
                        {element.map((el, idx) => (
                          <li key={`knowledge-element-${index}-${idx}`}>
                            {el}
                          </li>
                        ))}
                      </ul>
                    </Fragment>
                  ))
                ) : (
                  <div className='NotAvailable'>
                    {careerOverviewData.noDataTxt}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='mb-4 px-2 item'>
            <div className='outerBorder'>
              <div className='header'>
                <div>{careerOverviewData.technologyTxt}</div>
              </div>
              <div
                className='content px-4 pt-3 pb-2'
                style={{overflow: 'auto', height: '350px'}}>
                {technology &&
                Array.isArray(technology) &&
                technology.length > 0 ? (
                  <div>
                    <p>{careerOverviewData.youMightUseTxt}:</p>
                    {technology.map(({title, example}, index) => (
                      <Fragment key={`techno-${index}`}>
                        <h5>{title}</h5>
                        <ul className='list'>
                          {example &&
                            Array.isArray(example) &&
                            example.map(({name, hot_technology}, idx) => (
                              <li key={`technology-${index}-${idx}`}>
                                {name}
                                {hot_technology > 0 && (
                                  <Popover
                                    placement='bottomLeft'
                                    overlayClassName='hot-technology-pop-over'
                                    title={
                                      <div className='hot-technology-title pt-3'>
                                        <Row>
                                          <Col span={3}>
                                            <img
                                              src={hotTechnology}
                                              alt='logo'
                                              className='img-fluid hotTech mx-2'
                                              style={{width: '20px'}}
                                            />
                                          </Col>
                                          <Col span={21}>
                                            <h4>
                                              <span className='highlighter'>
                                                {name}
                                              </span>
                                              {` ${careerOverviewData.hotTechTxt}`}
                                            </h4>
                                          </Col>
                                        </Row>
                                      </div>
                                    }
                                    content={careerOverviewData.popUpContent}
                                    trigger={isMobileView ? 'click' : 'hover'}>
                                    <img
                                      src={hotTechnology}
                                      alt='logo'
                                      className='img-fluid hotTech mx-2'
                                    />
                                  </Popover>
                                )}
                              </li>
                            ))}
                        </ul>
                      </Fragment>
                    ))}
                  </div>
                ) : (
                  <div className='NotAvailable'>
                    {careerOverviewData.noDataTxt}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='mb-4 px-2 item'>
            <div className='outerBorder'>
              <div className='header'>
                <div>{careerOverviewData.workstyleTxt}</div>
              </div>
              <div
                className='content px-4 pt-3 pb-2'
                style={{overflow: 'auto', height: '350px'}}>
                {work_styles &&
                Array.isArray(work_styles) &&
                work_styles.length > 0 ? (
                  work_styles.map((res, index) => (
                    <Fragment key={`work-style-${index}`}>
                      <h5>{res.name}</h5>
                      <ul className='list'>
                        <li key={`work-style-${index}-${index}`}>
                          {res.description}
                        </li>
                      </ul>
                    </Fragment>
                  ))
                ) : (
                  <div className='NotAvailable'>
                    {careerOverviewData.noDataTxt}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='mb-4 px-2 item'>
            <div className='outerBorder'>
              <div className='header'>
                <div>{careerOverviewData.abilitiesTxt}</div>
              </div>
              <div
                className='content px-4 pt-3 pb-2'
                style={{overflow: 'auto', height: '350px'}}>
                {abilities &&
                Array.isArray(abilities) &&
                abilities.length > 0 ? (
                  abilities.map(({title, element}, index) => (
                    <Fragment key={`ability-${index}`}>
                      <h5>{title}</h5>
                      <ul className='list'>
                        {element.map((el, idx) => (
                          <li key={`ability-element-${index}-${idx}`}>{el}</li>
                        ))}
                      </ul>
                    </Fragment>
                  ))
                ) : (
                  <div className='NotAvailable'>
                    {careerOverviewData.noDataTxt}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='mb-4 px-2 item'>
            <div className='outerBorder'>
              <div className='header'>
                <div>{careerOverviewData.skillsTxt}</div>
              </div>
              <div
                className='content px-4 pt-3 pb-2'
                style={{overflow: 'auto', height: '350px'}}>
                {skills && Array.isArray(skills) && skills.length > 0 ? (
                  skills.map(({title, element}, index) => (
                    <Fragment key={`skill-${index}`}>
                      <h5>{title}</h5>
                      <ul className='list'>
                        {element.map((el, idx) => (
                          <li key={`skill-element-${index}-${idx}`}>{el}</li>
                        ))}
                      </ul>
                    </Fragment>
                  ))
                ) : (
                  <div className='NotAvailable'>
                    {careerOverviewData.noDataTxt}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='mb-4 px-2 item'>
            <div className='onet'>
              <div className='p-3'>
                <img src={onetinit} alt='logo' className='img-fluid' />
                <p>{careerOverviewData.siteInfo}</p>
              </div>
            </div>
          </div>
          <div className='mb-4 px-2 item'>
            <div className='outerBorder'>
              <div className='header'>
                <div>{careerOverviewData.personalityTxt}</div>
              </div>
              <div
                className='content px-4 pt-3 pb-2'
                style={{overflow: 'auto', height: '350px'}}>
                <p>{description}</p>
                {element && Array.isArray(element) && element.length > 0 ? (
                  <>
                    <p>{careerOverviewData.theyDoWellTxt}:</p>
                    <ul className='list_vertical multi-column'>
                      {element &&
                        Array.isArray(element) &&
                        element.map((el, idx) => (
                          <li key={`personality-${idx}`}>{el}</li>
                        ))}
                    </ul>
                  </>
                ) : (
                  <div className='NotAvailable'>
                    {careerOverviewData.noDataTxt}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='mb-4 px-2 item'>
            <div className='outerBorder'>
              <div className='header'>
                <div>{careerOverviewData.workvalueTxt}</div>
              </div>
              <div
                className='content px-4 pt-3 pb-2'
                style={{overflow: 'auto', height: '350px'}}>
                {work_values &&
                Array.isArray(work_values) &&
                work_values.length > 0 ? (
                  work_values.map((res, index) => (
                    <Fragment key={`workvalue-${index}`}>
                      <h5>{res.name}</h5>
                      <ul className='list'>
                        <li key={`workvalue-${index}-${index}`}>
                          {res.description}
                        </li>
                      </ul>
                    </Fragment>
                  ))
                ) : (
                  <div className='NotAvailable'>
                    {careerOverviewData.noDataTxt}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {localSalaryModal && (
        <LocalSalaryModal
          onCancel={() => setLocalSalaryModal(false)}
          occupationName={occupation_name}
        />
      )}
    </ErrorBoundary>
  );
};

export default CareerOverview;

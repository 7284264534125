import React, {useEffect, useState} from 'react';
import {API} from 'config';
import {Button} from 'mui-core';
import {TextField, Typography, Box} from '@mui/material';
import profileStaticData from 'data/settings-profile.json';

const Summary = ({profileData, onChange, viewOnly}) => {
  const {request, data} = profileData || {};
  const {bio = ''} = data || {};
  const [edit, setEdit] = useState(bio ? false : true);
  const [bioData, setBioData] = useState('');

  useEffect(() => {
    setBioData(bio);
    if (!bio) {
      setEdit(true);
    }
  }, [bio, data]);

  const onEdit = async () => {
    if (onChange) {
      await onChange('bio', API.gps.student_profile, bioData, 'PATCH');
      cancelEdit();
    }
  };
  const cancelEdit = () => {
    setEdit(false);
    setBioData('');
  };
  const openEdit = () => {
    setBioData(bio);
    setEdit(true);
  };

  return (
    <>
      {!edit ? (
        <Typography variant='body1' flexWrap='wrap'>
          {bio}
        </Typography>
      ) : (
        <>
          <TextField
            multiline
            fullWidth
            minRows={5}
            maxRows={15}
            margin='dense'
            value={bioData}
            placeholder='Write about you'
            inputProps={{maxLength: 5000}}
            onChange={e => setBioData(e.target.value)}
          />
          <Typography
            fontSize={15}
            variant='body1'
            fontWeight={400}
            textAlign='right'
            color='text.secondary'>
            {bioData?.length || 0}/5000
          </Typography>
        </>
      )}
      {!bio ||
        (bio === '' && (
          <Typography variant='body1'>
            {profileStaticData.bioData.noBioAddedTxt}
          </Typography>
        ))}
      {!viewOnly && (
        <Box display='flex' justifyContent='flex-start' mt={2}>
          {!edit ? (
            <Button variant='contained' onClick={openEdit}>
              {profileStaticData.bioData.editBioTxt}
            </Button>
          ) : (
            <>
              {bio && (
                <Button variant='outlined' onClick={cancelEdit} sx={{mr: 2}}>
                  {profileStaticData.cancelBtnTxt}
                </Button>
              )}
              <Button
                onClick={onEdit}
                loading={request}
                disabled={request}
                variant='contained'>
                {profileStaticData.saveBtnTxt}
              </Button>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default Summary;

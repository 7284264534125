import MyriadProBold from './MyriadProBold.OTF';
import MyriadProLight from './MyriadProLight.OTF';
import MyriadProRegular from './MyriadProRegular.OTF';
import MyriadProSemiBold from './MyriadProSemiBold.OTF';

import MyriadProBoldTTF from './MyriadProBold.ttf';
import MyriadProLightTTF from './MyriadProLight.ttf';
import MyriadProRegularTTF from './MyriadProRegular.ttf';
import MyriadProSemiBoldTTF from './MyriadProSemiBold.ttf';

export const MyriadProFonts = {
  LIGHT: {
    OTF: MyriadProLight,
    TTF: MyriadProLightTTF,
  },
  REGULAR: {
    OTF: MyriadProRegular,
    TTF: MyriadProRegularTTF,
  },
  SEMIBOLD: {
    OTF: MyriadProSemiBold,
    TTF: MyriadProSemiBoldTTF,
  },
  BOLD: {
    OTF: MyriadProBold,
    TTF: MyriadProBoldTTF,
  },
};

import React from 'react';
import {getLogo} from 'core/utils';
import {getGoBanner} from 'mui-core/utils';
import {MuiProfileUpload} from 'core/hooks';
import {styled} from '@mui/material/styles';
import ProfilePhotoUploader from '../ProfilePhotoUploader';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  goBanner,
  editpurple,
  black_medium,
  black_tiktok,
  black_twitter,
  black_facebook,
  black_linkedin,
  black_instagram,
} from 'assets/images';

import {
  Box,
  Stack,
  Paper,
  Button,
  Tooltip,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
  LinearProgress,
} from '@mui/material';

const BannerUploader = ({
  viewOnly,
  updateUserProfile,
  //appConfig: {isMobileView},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [progress, userData, uploadImage] = MuiProfileUpload('banner');
  const {data} = userData || {};
  const userName = `${data && data.first_name} ${data && data.last_name}`;
  const qrCodeImage = data?.qr_code && getLogo(data?.qr_code);
  const [showTooltip, setShowTooltip] = React.useState(false);
  let userBanner = goBanner;
  if (data && data.banner) {
    userBanner = getGoBanner(data.banner);
  }

  let location = null;
  if (data && data?.address?.city && data?.address?.state) {
    location = data.address.city + ', ' + data.address.state;
  }

  const StyledLeftBox = styled(Stack)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    maxWidth: isMobile ? '72%' : '36%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'start',
      justifyContent: 'center',
    },
  }));

  const StyledProfiler = styled(Paper)(({theme}) => ({
    left: '-8px',
    width: '100%',
    height: 'auto',
    paddingTop: '8px',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '15px',
    paddingBottom: '8px',
    paddingLeft: isMobile ? '8px' : '16px',
    paddingRight: isMobile ? '8px' : '16px',
  }));
  const IconButton = styled(Box)(({theme}) => ({
    filter: `invert(${theme.palette.mode === 'dark' ? 1 : 0})`,
    padding: isMobile ? 6 : 8,
    '.MuiButton-root': {
      padding: 0,
      minWidth: 0,
      minHeight: 0,
      justifyContent: 'start',
    },
  }));
  const StyledBanner = styled(Box)(() => ({
    backgroundImage: `url(${userBanner})`,
    width: 'auto',
    display: 'flex',
    minWidth: '175px',
    whiteSpace: 'nowrap',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: isMobile ? '200px' : '250px',
  }));

  const StyledQRBox = styled(Box)(() => ({
    [theme.breakpoints.down('sm')]: {
      marginTop: '56px',
    },
    '& img': {
      transition: 'all 0.5s ease-in-out',
      '&:hover': {
        zIndex: 4,
        width: '80px',
        height: '80px',
        objectFit: 'contain',
        transform:
          'scale3d(1.1, 1.1, 1) translate3d(-15%, 0%, 0) perspective(300px)',
      },
    },
  }));

  const StyledRightBox = styled(Box)(() => ({
    margin: 0,
    display: 'flex',
    minWidth: '90px',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'end',
  }));

  const ToolTipStyle = styled(Box)(() => ({
    zIndex: 9,
    top: '36px',
    position: 'absolute',
    backgroundColor: '#8050F4',
    borderRadius: isMobile ? '50%' : '20px',
    padding: isMobile ? '8px 8px 8px 9px' : '5px 8px',
    '& .MuiBox-root': {
      '& img': {
        filter:
          'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(279deg) brightness(108%) contrast(100%)',
      },
    },
  }));

  const handleImageChange = async e => {
    e.preventDefault();
    await uploadImage(e.target.files[0]);
  };

  const deleteBanner = async () => {
    const body = {
      banner: null,
    };
    await updateUserProfile(body);
  };

  const actions = () => (
    <>
      <Stack
        direction='row'
        divider={<Divider orientation='vertical' />}
        spacing={1}>
        <Typography
          color='primary'
          fontSize='14px'
          onClick={deleteBanner}
          sx={{
            pr: 1,
            cursor: 'pointer',
            borderRight: 'solid 1px #cccccc',
          }}>
          Remove
        </Typography>

        <label htmlFor='contained-button-file' style={{marginLeft: '2px'}}>
          <Typography sx={{cursor: 'pointer'}} color='primary' fontSize='14px'>
            Edit
          </Typography>
        </label>
      </Stack>
    </>
  );

  const SocialIconsButton = props => {
    const {link, src, alt} = props;
    return (
      <IconButton>
        <Button href={link} target='_blank'>
          <img src={src} alt={alt} />
        </Button>
      </IconButton>
    );
  };
  return (
    <>
      <input
        type='file'
        accept='image/*'
        id='contained-button-file'
        onChange={e => {
          handleImageChange(e);
        }}
        style={{display: 'none'}}
      />
      <StyledBanner>
        {progress > 0 && <LinearProgress percent={progress} />}
        <Stack
          width={'100%'}
          direction='row'
          px={isMobile ? 2 : 4}
          justifyContent='space-between'>
          <StyledLeftBox direction={isMobile ? 'column' : 'row'}>
            <ProfilePhotoUploader
              viewOnly={viewOnly}
              isMobileView={isMobile}
              updateUserProfile={updateUserProfile}
            />
            <StyledProfiler>
              {userName && (
                <Typography
                  variant='h5'
                  title={userName}
                  fontSize={isMobile ? 12 : 17}
                  color='text.primary'
                  sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                  {userName}
                </Typography>
              )}

              {location && (
                <Stack direction='row'>
                  <LocationOnIcon
                    sx={{
                      fontSize: isMobile ? '16px' : '24px',
                    }}
                  />
                  <Typography
                    variant='h6'
                    fontSize={isMobile ? 12 : 17}
                    color='primary'
                    sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {location}
                  </Typography>
                </Stack>
              )}

              <Stack direction='row'>
                {data && data.facebook_link && data.show_facebook_link && (
                  <SocialIconsButton
                    alt='fb'
                    src={black_facebook}
                    link={data.facebook_link}
                  />
                )}

                {data && data.twitter_link && data.show_twitter_link && (
                  <SocialIconsButton
                    alt='tw'
                    src={black_twitter}
                    link={data.twitter_link}
                  />
                )}

                {data && data.linkedin_link && data.show_linkedin_link && (
                  <SocialIconsButton
                    alt='li'
                    src={black_linkedin}
                    link={data.linkedin_link}
                  />
                )}

                {data && data.instagram_link && data.show_instagram_link && (
                  <SocialIconsButton
                    alt='ig'
                    src={black_instagram}
                    link={data.instagram_link}
                  />
                )}

                {data && data.tiktok_link && data.show_tiktok_link && (
                  <SocialIconsButton
                    alt='tt'
                    src={black_tiktok}
                    link={data.tiktok_link}
                  />
                )}

                {data && data.medium_link && data.show_medium_link && (
                  <SocialIconsButton
                    alt='med'
                    src={black_medium}
                    link={data.medium_link}
                  />
                )}
              </Stack>
            </StyledProfiler>
          </StyledLeftBox>
          <StyledRightBox>
            <ToolTipStyle>
              <Tooltip
                arrow
                placement='left'
                title={actions()}
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                componentsProps={{
                  tooltip: {
                    sx: {
                      color: 'primary',
                      fontSize: '12px',
                      backgroundColor: 'background.paper',
                    },
                  },
                }}>
                <Box>
                  <img
                    alt='edit'
                    src={editpurple}
                    style={{cursor: 'pointer'}}
                    onClick={() => setShowTooltip(!showTooltip)}
                  />
                </Box>
              </Tooltip>
            </ToolTipStyle>
            {qrCodeImage && (
              <StyledQRBox>
                <img src={qrCodeImage} width='78px' height='78px' />
              </StyledQRBox>
            )}
          </StyledRightBox>
        </Stack>
      </StyledBanner>
    </>
  );
};
export default BannerUploader;

import * as TYPES from './constants';
import {API} from 'config';
import isArray from 'lodash/isArray';

import {
  requestStudentSkills,
  receiveStudentSkills,
  studentSkillsError,
  requestSaveStudentSkills,
  receiveSaveStudentSkills,
  studentSaveSkillsError,
  requestUpdateStudentSkills,
  receiveUpdateStudentSkills,
  studentUpdateSkillsError,
  requestDeleteStudentSkills,
  receiveDeleteStudentSkills,
  studentDeleteSkillsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.STUDENT_SKILLS_LOAD:
    case TYPES.STUDENT_SKILLS_SAVE_LOAD:
    case TYPES.STUDENT_SKILLS_UPDATE_LOAD:
    case TYPES.STUDENT_SKILLS_DELETE_LOAD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.STUDENT_SKILLS_SUCCESS:
    case TYPES.STUDENT_SKILLS_SAVE_SUCCESS:
    case TYPES.STUDENT_SKILLS_UPDATE_SUCCESS:
    case TYPES.STUDENT_SKILLS_DELETE_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        ...(isArray(action.payload) && {data: action.payload}),
      };
    case TYPES.STUDENT_SKILLS_ERROR:
    case TYPES.STUDENT_SKILLS_SAVE_ERROR:
    case TYPES.STUDENT_SKILLS_UPDATE_ERROR:
    case TYPES.STUDENT_SKILLS_DELETE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.STUDENT_SKILLS_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchStudentSkills = () => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();

    let endPoint = `${API.gps.student_skills}`;
    dispatch(requestStudentSkills());
    return services.fetchRecords(endPoint, token).then(res => {
      if (Array.isArray(res)) dispatch(receiveStudentSkills(res));
      else dispatch(studentSkillsError());
    });
  } catch (err) {
    console.log(err);
    dispatch(studentSkillsError(err));
  }
};

export const saveStudentSkill = (body, callback) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();

    let endPoint = `${API.gps.student_skills}`;
    dispatch(requestSaveStudentSkills());
    return services
      .createUpdateRecord(token, endPoint, body, 'POST')
      .then(res => {
        if (callback) callback(res);
        dispatch(receiveSaveStudentSkills(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(studentSaveSkillsError(err));
  }
};

export const updateStudentSkill = (body, callback) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();

    let endPoint = `${API.gps.student_skills}/${body.uuid}`;
    dispatch(requestUpdateStudentSkills());
    return services
      .createUpdateRecord(token, endPoint, body, 'PATCH')
      .then(res => {
        if (callback) callback(res);
        dispatch(receiveUpdateStudentSkills(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(studentUpdateSkillsError(err));
  }
};

export const deleteStudentSkill = (body, callback) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    const endPoint = `${API.gps.student_skills}/${body.uuid}`;
    dispatch(requestDeleteStudentSkills());
    return services.deleteRecord(token, endPoint).then(res => {
      if (callback) callback(res);
      dispatch(receiveDeleteStudentSkills(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(studentDeleteSkillsError(err));
  }
};

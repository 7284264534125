import {Close} from '@mui/icons-material';
import PropTypes from 'prop-types';
import {Button} from 'mui-core';
import {
  Box,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

const StyledDialogTitle = props => {
  const {children, onClose, title, ...other} = props;
  return (
    <DialogTitle
      {...other}
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'>
      <Typography
        margin={0}
        variant='h2'
        gutterBottom
        fontSize={24}
        fontWeight={600}
        color='text.primary'>
        {title}
      </Typography>
      {onClose ? (
        <IconButton aria-label='close' onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const RemoveProgramDialog = ({
  title,
  loader,
  showDialog,
  description,
  deleteObject,
  onCloseDialog,
  onConfirmDialog,
  confirmText = 'Are you sure you want to delete',
}) => (
  <Dialog
    maxWidth='md'
    fullWidth={true}
    open={showDialog}
    aria-labelledby='customized-dialog-title'>
    <StyledDialogTitle
      title={title}
      id='customized-dialog-title'
      onClose={onCloseDialog}
    />
    <DialogContent display='flex' alignItems='center'>
      <Box padding='8px 40px 12px'>
        {confirmText && deleteObject?.title && (
          <Typography
            mb={2}
            variant='h5'
            fontSize={16}
            textAlign='center'
            color='text.primary'>
            {confirmText}{' '}
            <Typography
              variant='body2'
              display='inline'
              fontSize={16}
              fontWeight='600'
              sx={{
                color: '#de4279',
              }}>
              {deleteObject?.title}
            </Typography>
            &nbsp;?
          </Typography>
        )}
        {description && (
          <Typography
            mb={2}
            variant='h5'
            fontSize={16}
            textAlign='center'
            color='text.primary'>
            {description}
            &nbsp;?
          </Typography>
        )}
      </Box>
    </DialogContent>
    <DialogActions spacing={3} sx={{padding: '8px 32px 32px'}}>
      <Button color='midGray' variant='contained' onClick={onCloseDialog}>
        Cancel
      </Button>
      <Button
        color='primary'
        loading={loader}
        disabled={loader}
        variant='contained'
        onClick={onConfirmDialog}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

RemoveProgramDialog.prototype = {
  loader: PropTypes.bool,
  title: PropTypes.string,
  showDialog: PropTypes.bool,
  description: PropTypes.string,
  onCloseDialog: PropTypes.func,
  deleteObject: PropTypes.object,
  onConfirmDialog: PropTypes.func,
};

export default RemoveProgramDialog;

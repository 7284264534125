import React, {useMemo, useRef} from 'react';
import {Controller} from 'react-hook-form';
import FormControl, {useFormControl} from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import {Typography} from '@mui/material';
const createField = (
  name,
  {
    size,
    form,
    label,
    fullWidth = true,
    isVirtual,
    rules = {},
    defaultValue,
    disabled,
    dependent,
    valueField,
    hideErrors,
    helperText,
  },
) => {
  return Component => {
    const {
      control,
      formState: {errors, isSubmitted},
      trigger,
    } = form;
    const interval = useRef();
    const formatRules = useMemo(() => {
      const r = {};
      Object.keys(rules).forEach(key => (r[key] = rules[key][0]));
      if (isSubmitted) {
        clearTimeout(interval.current);
        interval.current = setTimeout(() => {
          trigger();
        }, 400);
      }
      return r;
    }, [dependent]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <FormControl
        disabled={disabled}
        sx={{position: isVirtual ? 'absolute' : 'relative'}}
        error={errors[name] ? true : false}
        fullWidth={fullWidth}
        variant='outlined'
        required={rules && rules['required'] && rules['required'][0]}>
        {label && (
          <InputLabel size={size} htmlFor={`field-${name}`}>
            {label}
          </InputLabel>
        )}
        <Controller
          render={({field: {onChange, value, ref}}) => {
            const {error} = useFormControl() || {};
            const otherProps = {};
            if (valueField) {
              otherProps[valueField] = value;
            }
            const Input = React.cloneElement(Component, {
              error,
              size: size,
              value: value,
              inputRef: ref,
              onChange: onChange,
              disabled: disabled,
              id: `field-${name}`,
              fullWidth: fullWidth,
              ...otherProps,
            });
            return Input;
          }}
          control={control}
          rules={formatRules}
          defaultValue={defaultValue}
          name={name}
        />
        {!hideErrors && (
          <FormHelperText
            sx={{
              position: helperText ? 'relative' : 'absolute',
              top: '100%',
              left: 0,
            }}>
            {errors[name] && (
              <>
                {rules[errors[name]['type']] && rules[errors[name]['type']][1]
                  ? rules[errors[name]['type']][1]
                  : 'error'}
              </>
            )}
          </FormHelperText>
        )}
        {helperText && !errors[name] && (
          <FormHelperText sx={{position: 'relative', top: '100%', left: 0}}>
            <Typography lineHeight={1} fontSize={12} variant='caption'>
              {helperText}
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
    );
  };
};

export default createField;

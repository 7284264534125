import * as TYPES from './constants';
import {API} from 'config';

import {
  recieveSignedPrograms,
  requestSignedPrograms,
  requestSignedProgramsError,
  recieveSignUpProgram,
  requestSignUpProgram,
  requestSignUpProgramError,
  clearSignedPrograms,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, requestApply: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SIGNED_PROGRAMS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.SIGN_UP_PROGRAM_REQUEST:
      return {
        ...state,
        requestApply: true,
        error: false,
      };
    case TYPES.SIGNED_PROGRAMS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.SIGN_UP_PROGRAM_SUCCESS:
      return {
        ...state,
        requestApply: false,
        error: false,
      };
    case TYPES.CLEAR_SIGNED_PROGRAMS:
      return {
        ...state,
        data: null,
      };
    case TYPES.SIGNED_PROGRAMS_ERROR:
    case TYPES.SIGN_UP_PROGRAM_ERROR:
      return {
        ...state,
        request: false,
        error: true,
        requestApply: false,
      };
    default:
      return state;
  }
};

export const fetchSignedUpPrograms = callback => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestSignedPrograms());
  try {
    let endPoint = `${API.srm.signed_programs}/?action_type=apply`;
    return services.fetchRecords(endPoint, token, true).then(res => {
      if (res) {
        if (callback) callback(res);
        dispatch(recieveSignedPrograms(res));
        return;
      }
      dispatch(requestSignedProgramsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestSignedProgramsError(err));
  }
};

export const signUpProgram = (program_uuid, callback) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null, user = {}} = {}},
  } = getState();
  dispatch(requestSignUpProgram());
  try {
    let endPoint = `${API.srm.signed_programs}`;
    const body = {
      program_uuid,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      mobile: user.phone_number,
      action_type: 'apply',
    };
    return services
      .createUpdateRecord(token, endPoint, body, 'POST')
      .then(res => {
        if (res) {
          dispatch(recieveSignUpProgram(res));
          if (callback) callback(res);
          return;
        }
        dispatch(requestSignUpProgramError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestSignUpProgramError(err));
  }
};

export const resetSignedPrograms = () => dispatch => {
  dispatch(clearSignedPrograms());
};

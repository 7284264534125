import React, {useEffect, useState} from 'react';
import {jobsData} from 'data/settings-profile';
import {Button, SearchResultsCounter} from 'mui-core';
import {calculateNoOfRecordsFetched} from 'core/utils';
import {FilterAlt, Info, Close} from '@mui/icons-material';
import {
  Box,
  Grid,
  Paper,
  Badge,
  Stack,
  Slider,
  Select,
  Tooltip,
  MenuItem,
  InputLabel,
  IconButton,
  Typography,
  FormControl,
} from '@mui/material';
import PropTypes from 'prop-types';
import FilterForm from './FilterForm';
import {useForm} from 'react-hook-form';

const PAGE_SIZE = 10;
const SortData = [
  {
    value: 'date_desc',
    text: jobsData.sorterStaticData.sortTxtData.datePostedLabel,
  },
  {
    value: 'skills_match',
    text: jobsData.sorterStaticData.sortTxtData.skillsMatchLabel,
  },
];

export const useEnableDisableScroll = isMobileView => {
  const [isFilterVisible, setIsFilterVisible] = useState(
    isMobileView ? false : true,
  );

  useEffect(() => {
    setIsFilterVisible(isMobileView ? false : true);
  }, [isMobileView]); // eslint-disable-line react-hooks/exhaustive-deps

  const disableScroll = () => {
    // let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    // let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = () => {
      window.scrollTo(0, 0);
    };
  };

  const enableScroll = () => {
    window.onscroll = () => {};
  };

  const onFilterClick = () => {
    if (!isFilterVisible) {
      window.scrollTo(0, 0);
      disableScroll();
    } else {
      enableScroll();
    }
    setIsFilterVisible(!isFilterVisible);
  };

  return {
    onFilterClick,
    isFilterVisible,
    setIsFilterVisible,
  };
};

const Sorter = ({
  sort,
  request,
  filters,
  onFinish,
  onChange,
  calcData,
  min_perct,
  isMobileView,
  onFilterReset,
  onSliderUpdate,
  isClubbedResults,
}) => {
  let showReset = false;
  const {totalRecords} = calcData || {};
  const formatter = value => `${value}%`;
  const {reset, register, handleSubmit} = useForm();
  const filterKeys = filters ? Object.keys(filters) : [];
  const {filtersFormStaticData, sliderStaticData} = jobsData;
  const onSliderChange = (_e, value) => onSliderUpdate(value);
  const {isFilterVisible, onFilterClick} = useEnableDisableScroll(isMobileView);
  const onFormSubmit = values => {
    handleSubmit(onFinish(values));
    isMobileView && onFilterClick();
  };
  const onFilterResetClick = () => {
    reset();
    onFilterReset();
    isMobileView && onFilterClick();
  };
  const renderFilters = () => (
    <FilterForm
      request={request}
      register={register}
      showReset={showReset}
      onFinish={onFormSubmit}
      handleSubmit={handleSubmit}
      isMobileView={isMobileView}
      onReset={onFilterResetClick}
    />
  );

  const SkillsMatchInfoContent = () => (
    <Stack color='secondary.contrast' p={2}>
      <Typography fontSize={{sm: 12, md: 14}}>
        {sliderStaticData.title}
      </Typography>
      <Typography my={2} fontSize={{sm: 12, md: 14}}>
        {sliderStaticData.blueText}
      </Typography>
      <Typography fontSize={{sm: 12, md: 14}}>
        {sliderStaticData.description}
      </Typography>
    </Stack>
  );

  if (filterKeys?.length) {
    filterKeys.forEach(key => {
      if (filters[key]) {
        showReset = true;
      }
    });
  }

  return (
    <Box>
      <Grid
        mb={2}
        container
        spacing={2}
        direction='row'
        alignItems='center'
        justifyContent='center'>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Stack
            direction='row'
            colSpacing={0.5}
            alignItems='center'
            justifyContent='center'>
            <Typography
              variant='h5'
              color='text.primary'
              mt={2}
              marginTop={isMobileView ? 2 : 0}>
              Skills Match Slider
            </Typography>
            {!isMobileView && (
              <Tooltip
                arrow
                placement='top-end'
                title={<SkillsMatchInfoContent />}>
                <IconButton>
                  <Info color='primary' />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Grid>
        <Grid
          item
          md={6}
          lg={5}
          xs={12}
          sm={12}
          colSpacing={1}
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='center'>
          <Typography
            variant='h6'
            color='text.primary'
            fontSize={isMobileView ? 12 : 15}>
            0%
          </Typography>
          <Box sx={{width: 450, mx: 2, mt: 0.5}}>
            <Slider
              min={0}
              max={100}
              step={10}
              color='secondary'
              disabled={request}
              aria-label='Default'
              valueLabelDisplay='auto'
              defaultValue={min_perct}
              valueLabelFormat={formatter}
              onChangeCommitted={onSliderChange}
              size={isMobileView ? 'small' : 'medium'}
            />
          </Box>
          <Typography
            variant='h6'
            color='text.primary'
            fontSize={isMobileView ? 12 : 15}>
            100%
          </Typography>
        </Grid>
      </Grid>
      <Grid
        mb={1}
        container
        spacing={2}
        direction='row'
        alignItems='center'
        justifyContent='center'>
        {!isMobileView && renderFilters()}
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <FormControl sx={{width: '100%'}}>
            <InputLabel id='demo-simple-select-label'>
              {jobsData.sortByLabel}
            </InputLabel>
            <Select
              size='small'
              value={sort}
              disabled={request}
              defaultValue={sort}
              label={jobsData.sortByLabel}
              onChange={event => onChange('sort', event.target.value)}>
              {SortData.map(({value, text}, idx) => {
                return (
                  <MenuItem value={value} key={idx}>
                    {text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Stack
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'>
        <SearchResultsCounter
          request={request}
          totalResults={totalRecords}
          isClubbedResults={isClubbedResults}
          currentLength={calculateNoOfRecordsFetched(calcData, PAGE_SIZE)}
        />
        {!isMobileView && showReset && (
          <Button onClick={onFilterResetClick} disabled={request}>
            {filtersFormStaticData.resetFilterBtnTxt}
          </Button>
        )}
        {isMobileView && (
          <>
            <Stack
              flexDirection='row'
              alignItems='center'
              sx={{position: 'relative'}}
              justifyContent='space-between'>
              <Typography
                mr={1}
                variant='h5'
                fontSize={16}
                fontWeight={600}
                color='text.secondary'>
                Filter Results
              </Typography>
              <Badge
                variant='dot'
                color='primary'
                invisible={!showReset}
                onClick={onFilterClick}>
                <FilterAlt variant='filled' />
              </Badge>
            </Stack>
            {isFilterVisible && (
              <Paper
                sx={{
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                  width: '100%',
                  height: '100%',
                  padding: '20px',
                  position: 'fixed',
                }}>
                <Grid
                  item
                  mb={2}
                  xs={12}
                  sm={12}
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='space-between'>
                  <Typography
                    variant='h2'
                    color='text.primary'
                    fontSize={{xs: 14, sm: 16}}>
                    Filter By
                  </Typography>
                  <IconButton aria-label='close' onClick={onFilterClick}>
                    <Close />
                  </IconButton>
                </Grid>
                {renderFilters()}
              </Paper>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

Sorter.propTypes = {
  sort: PropTypes.string,
  request: PropTypes.bool,
  onFinish: PropTypes.func,
  onChange: PropTypes.func,
  filters: PropTypes.object,
  calcData: PropTypes.object,
  min_perct: PropTypes.number,
  isMobileView: PropTypes.bool,
  onFilterReset: PropTypes.func,
  onSliderUpdate: PropTypes.func,
  isClubbedResults: PropTypes.bool,
};

export default Sorter;

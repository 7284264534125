import {useState} from 'react';
import {isArray, isEmpty} from 'lodash';
import {
  PathCard,
  Carousel,
  ErrorBoundary,
  RequestErrorLoader,
  NoContentNavigator,
} from 'mui-core';
import {Box} from '@mui/material';
import {
  RemoveSavedPath,
  NoSavedPathFound,
  ExploreProgramsBtnText,
  RemoveSavedPathMessage,
} from 'data/pathway';
import RemoveProgramDialog from '../ActivePrograms/RemoveProgramDialog';

const MySavedPrograms = ({savedPaths, fetchSavedPaths, removeSavedPath}) => {
  const [deletePath, setDeletePath] = useState(false);
  const [showDelSavedPathModal, setShowDelSavedPathModal] = useState(false);
  const {data: savedProgramsData, request: savedProgramsRequest} = savedPaths;

  const unSavePath = (title, uuid) => {
    setDeletePath({title, uuid});
    setShowDelSavedPathModal(true);
  };

  const onDeleteSavedPath = async () => {
    await removeSavedPath(deletePath?.uuid);
    await fetchSavedPaths();
    setShowDelSavedPathModal(false);
  };

  const onCloseDelSavedPathModal = () => {
    setDeletePath(false);
    setShowDelSavedPathModal(false);
  };

  return (
    <ErrorBoundary nameOfComponent='headermenu-viewdashboard-mydashboard-savedprograms'>
      <RequestErrorLoader hideEmpty minHeight={100} body={savedPaths}>
        {!savedProgramsRequest && (
          <>
            <Box>
              {savedProgramsData &&
              isArray(savedProgramsData) &&
              !isEmpty(savedProgramsData) ? (
                <Carousel
                  slidesToShow={4}
                  content={savedProgramsData.map(path => (
                    <Box key={path.path_uuid} sx={{padding: '8px 12px'}}>
                      <PathCard
                        enableNavigation
                        key={path.path_uuid}
                        customClass='sliderCard'
                        data={path?.path_details || {}}
                        displayEllipses={[
                          {
                            title: 'Remove',
                            action: e => {
                              e.stopPropagation();
                              unSavePath(
                                path?.path_details?.title,
                                path?.path_details?.uuid,
                              );
                            },
                          },
                        ]}
                      />
                    </Box>
                  ))}
                />
              ) : (
                <NoContentNavigator
                  pathTo='/programs'
                  message={NoSavedPathFound}
                  label={ExploreProgramsBtnText}
                />
              )}
            </Box>

            {showDelSavedPathModal && (
              <RemoveProgramDialog
                title={RemoveSavedPath}
                deleteObject={deletePath}
                loader={savedProgramsRequest}
                showDialog={showDelSavedPathModal}
                onConfirmDialog={onDeleteSavedPath}
                confirmText={RemoveSavedPathMessage}
                onCloseDialog={onCloseDelSavedPathModal}
              />
            )}
          </>
        )}
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default MySavedPrograms;

export const PATTERN = {
  Name_With_Space: /^[A-Za-z ]+$/,
  Website: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  Verification_Code: /^[0-9]{6}$/,
  Number_Pattern: /^\d*[0-9]\d*$/,
  Whole_Number_UpToTenThousands: /^([1-9][0-9]{0,3}|9999)$/,
  Float_Pattern: /^[0-9]+(\.[0-9]{1,2})*$/,
  Course_Top_Code: /^[0-9]{1,4}(\.[0-9]{1,2})*$/,
  Zip_Code: /(^[0-9]{5}(?:-[0-9]{4})?$)/,
  Phone: /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/,
  PhoneWithExt: /^([0|+[0-9]{1,6})?([0-9]{3})?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/,
  Number_With_Float: /^\d{0,5}(\.\d{1,2})?$/,
  Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
  Ext: /^([[0-9]{1,6})$/,
  Number_Up_To_One_Decimal: /^[0-9]+(\.[0-9]{1,1})?$/,
  Number_Up_To_Two_Decimal: /^[0-9]+(\.[0-9]{1,2})?$/,
  Alpha_Numeric: /^[a-z0-9 ]+$/i,
  UserName: /^[[a-zA-Z0-9._-]+$/i,
};

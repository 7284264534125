import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import {
  Carousel,
  CareerCard,
  ErrorBoundary,
  RequestErrorLoader,
  NoContentNavigator,
} from 'mui-core';
import {Box, Button} from '@mui/material';
import {getInstanceVersion} from 'redux/modules/instance';
import {noCareerSubscriptionResults} from 'data/occupation.json';

const StyledBtn = styled(Box)(({theme}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '& .exploreBtn:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SubscribedCards = ({onUnsubscribe, subscribedOccupation}) => {
  const {dashboard: {my_careers: {explore_career = false} = {}} = {}} =
    useSelector(getInstanceVersion) || {};
  const displayEllipses = [
    {
      title: 'Remove',
      action: onUnsubscribe,
      passingData: ['occupation_name', 'uuid'],
    },
  ];

  const isSubscriptions = useMemo(() =>
    subscribedOccupation?.data &&
    subscribedOccupation?.data?.length > 0 &&
    Array.isArray(subscribedOccupation?.data)
      ? subscribedOccupation?.data.map(cardInfo => {
          return cardInfo;
        })
      : [][subscribedOccupation.data],
  );

  const config = {
    type: 'occupation',
  };

  return (
    <>
      <ErrorBoundary
        typeOfUi='subPage'
        nameOfComponent='module-dashboard-career-careerFavorites-subscribedCareers'>
        <RequestErrorLoader
          minHeight={100}
          hideEmpty
          body={{
            data: subscribedOccupation.data,
            request: subscribedOccupation.request,
          }}>
          {!subscribedOccupation.error &&
            !subscribedOccupation.request &&
            subscribedOccupation.data && (
              <>
                {isSubscriptions && (
                  <StyledBtn>
                    <Box>
                      <Link to='/careers'>
                        <Button variant='outlined' className='exploreBtn'>
                          Explore Careers
                        </Button>
                      </Link>
                    </Box>
                  </StyledBtn>
                )}

                {!isSubscriptions ? (
                  <NoContentNavigator
                    pathTo='/careers'
                    label={explore_career ? '' : 'EXPLORE CAREERS'}
                    message={
                      explore_career
                        ? 'This feature is currently inactive.'
                        : noCareerSubscriptionResults.title
                    }
                    subHeading={
                      explore_career
                        ? 'Reach out to your institution to gain insights on how to activate this feature and enhance your user experience.'
                        : noCareerSubscriptionResults.description
                    }
                  />
                ) : (
                  <Carousel
                    slidesToShow={4}
                    content={isSubscriptions?.map((subscribedData, idx) => (
                      <Box p='10px'>
                        <CareerCard
                          enableNavigation
                          key={subscribedData.uuid}
                          config={config}
                          customClass='sliderCard'
                          data={subscribedData}
                          displayEllipses={displayEllipses}
                        />
                      </Box>
                    ))}
                  />
                )}
              </>
            )}
        </RequestErrorLoader>
      </ErrorBoundary>
    </>
  );
};

SubscribedCards.propTypes = {
  onUnsubscribe: PropTypes.func,
  subscribedOccupation: PropTypes.object,
};

export default SubscribedCards;

import React from 'react';
import {
  resumeStrengthText,
  resumeStrengthTip1,
  resumeStrengthTip2,
  resumeStrengthTipsText,
  resumeStrengthMeterText,
  watchYourResumeDescText,
  essentialInfoToolTipText,
  essentialInfoToolTipDesc,
  supportingDetailsTooltipText,
  supportingDetailsTooltipDesc,
  resumeStrengthMeterTooltipDesc,
} from 'data/resume.json';
import {Speedometer} from 'mui-core';
import {Speed} from '@mui/icons-material';
import DetailsSection from './DetailsSection';
import ResumeStrength from './ResumeStrength';
import {dashboardBtnTxt} from 'data/resumeBuilder.json';
import {Stack, Divider, Box, Typography} from '@mui/material';
import {styled, lighten, useTheme} from '@mui/material/styles';

const DashboardBanner = styled(Box)(({theme}) => ({
  display: 'flex',
  marginTop: '0px',
  padding: '10px 16px',
  alignItems: 'center',
  justifyContent: 'center',
  width: `calc(100% + 64px)`,
  backgroundColor: lighten(theme.palette.primary.dark, 0.5),
}));

const StyledSpeedometerContainer = styled(Box)(({theme}) => ({
  height: 180,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    marginLeft: '42px',
  },
}));

const HighlightedText = styled(Typography)(({theme}) => ({
  '& .highlighted-text': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.light,
  },
  color: theme.palette.grayText.main,
}));

const GradientDivider = styled(Divider)(() => ({
  borderImage:
    'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  borderImageSlice: '20%',
  borderImageWidth: '2px',
}));

const StyleListItem = styled('li')(({theme}) => ({
  '&::marker': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.light,
  },
}));

const labelSizeValues = {
  lg: 13,
  md: 12,
};

const customLabelPlacementValues = {
  okPlacement: {
    lg: 50,
    md: 50,
  },
  goodPlacment: {
    lg: 38,
    md: 30,
  },
  greatPlacement: {
    lg: 35,
    md: 45,
  },
};

const renderStrengthMeterToolTip = theme => (
  <Stack
    spacing={2}
    sx={{
      padding: '32px',
      borderRadius: '8px',
      background: theme.palette.mode === 'light' ? 'white' : 'black',
    }}>
    <Typography variant='h3' fontWeight={300} color='text.primary'>
      {resumeStrengthMeterText}
    </Typography>
    <GradientDivider variant='middle' />
    <Stack spacing={2}>
      <Stack
        alignItems='center'
        justifyContent='space-between'
        direction={{xs: 'column', sm: 'row'}}>
        <HighlightedText
          variant='h3'
          dangerouslySetInnerHTML={{
            __html: watchYourResumeDescText,
          }}
        />
        <StyledSpeedometerContainer>
          <Speedometer
            width={300}
            size='small'
            dialValue={85}
            showDialValue={false}
            labelSize={labelSizeValues}
            badgeLabel={resumeStrengthText}
            customLabelPlacement={customLabelPlacementValues}
          />
        </StyledSpeedometerContainer>
      </Stack>
      <Typography variant='body1' color='grayText.main'>
        {resumeStrengthMeterTooltipDesc}
      </Typography>

      <Typography variant='body1' color='grayText.main'>
        {resumeStrengthTipsText}
      </Typography>

      <ul>
        <StyleListItem>
          <HighlightedText
            variant='body1'
            color='grayText.main'
            dangerouslySetInnerHTML={{
              __html: resumeStrengthTip1,
            }}
          />
        </StyleListItem>
        <StyleListItem>
          <HighlightedText
            variant='body1'
            color='grayText.main'
            dangerouslySetInnerHTML={{
              __html: resumeStrengthTip2,
            }}
          />
        </StyleListItem>
      </ul>
    </Stack>
  </Stack>
);

const renderEssentialInfoToolTipData = () => (
  <Stack spacing={2}>
    <Typography variant='body1' color='grayText.main'>
      {essentialInfoToolTipDesc}
    </Typography>
    <StyledSpeedometerContainer>
      <Speedometer
        width={300}
        size='small'
        dialValue={85}
        showDialValue={false}
        labelSize={labelSizeValues}
        badgeLabel={resumeStrengthText}
        customLabelPlacement={customLabelPlacementValues}
      />
    </StyledSpeedometerContainer>
  </Stack>
);

const renderSupportingDetailsToolTipData = () => (
  <Stack spacing={2}>
    <HighlightedText
      variant='body1'
      dangerouslySetInnerHTML={{
        __html: supportingDetailsTooltipDesc,
      }}
    />
    <StyledSpeedometerContainer alignItems='center' justifyContent='center'>
      <Speedometer
        width={300}
        size='small'
        dialValue={85}
        showDialValue={false}
        labelSize={labelSizeValues}
        badgeLabel={resumeStrengthText}
        customLabelPlacement={customLabelPlacementValues}
      />
    </StyledSpeedometerContainer>
  </Stack>
);

const ResumeDetails = ({
  resumeName,
  handleStepChange,
  supportingDetails,
  resumeStrengthValue,
  essentialInformation,
}) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Stack alignItems='center'>
          <DashboardBanner
            rounded
            size='small'
            variant='outlined'
            endIcon={<Speed fontSize='large' />}>
            <Typography variant='h6'>{dashboardBtnTxt}</Typography>
            <Speed fontSize='small' sx={{margin: '0px 0px 4px 8px'}} />
          </DashboardBanner>
        </Stack>
        <Typography
          mt={2}
          noWrap
          variant='h5'
          textAlign='center'
          color='text.primary'>
          {resumeName}
        </Typography>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}>
          <ResumeStrength
            supportingDetails={supportingDetails}
            resumeStrengthValue={resumeStrengthValue}
            essentialInformation={essentialInformation}
            tooltipData={renderStrengthMeterToolTip(theme)}
          />
        </Stack>
        <Divider sx={{mb: 1, mt: -2}} />
        <Box>
          <DetailsSection
            title={essentialInfoToolTipText}
            sectionInfo={essentialInformation}
            handleStepChange={handleStepChange}
            tooltipData={renderEssentialInfoToolTipData()}
          />
          <Divider sx={{my: 1}} />
          <DetailsSection
            sectionInfo={supportingDetails}
            handleStepChange={handleStepChange}
            title={supportingDetailsTooltipText}
            tooltipData={renderSupportingDetailsToolTipData()}
          />
        </Box>
      </Box>
    </>
  );
};

export default ResumeDetails;

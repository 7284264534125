import {lazy} from 'react';
import {retry} from 'core/utils';
import {ErrorBoundary} from 'core/components';
const Search = lazy(() => retry(() => import('../../../search')));

const RelatedPathways = props => (
  <ErrorBoundary
    nameOfComponent='mod-comp-occupation-related-pathways'
    showDialog={true}>
    <Search {...props} relatedPathways={true} hideFilterTabs />
  </ErrorBoundary>
);

export default RelatedPathways;

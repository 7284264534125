import * as TYPES from './constants';
import {API} from 'config';

import {
  requestJobBoards,
  recieveJobBoards,
  requestJobBoardsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.JOB_BOARDS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.JOB_BOARDS_SUCCESS:
      const {page_details, data} = action.payload || {};
      return {
        ...state,
        request: false,
        error: false,
        data: {
          Jobcount: action.payload?.page_details?.total_jobs || 0,
          data: action?.payload?.data || [],
          datalength:
            (action?.payload?.data &&
              Array.isArray(action.payload.data) &&
              action.payload.data.length) ||
            0,
        },
      };
    case TYPES.CLEAR_JOB_BOARDS:
      return {
        request: false,
        error: false,
      };
    case TYPES.JOB_BOARDS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchJobBoards = (reqParams, filters) => (dispatch, getState) => {
  dispatch(requestJobBoards());
  const {page = 1, page_size = 25, sort = 'date_desc'} = reqParams || {};
  try {
    let endPoint = `${API.srm.job_boards}?sort_by=${sort}&page=${page}&page_size=${page_size}`;
    if (filters) {
      const getFilterKeys = Object.keys(filters);
      if (getFilterKeys.length) {
        getFilterKeys.map(key => {
          if (filters[key]) {
            endPoint += `&${key}=${encodeURIComponent(filters[key])}`;
          }
        });
      }
    }
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveJobBoards(res));
        return;
      }
      dispatch(requestJobBoardsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestJobBoardsError(err));
  }
};

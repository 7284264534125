import * as TYPES from './constants';
import {API} from 'config';
import {
  clearNonProfitCompanyDetails,
  requestNonProfitCompanyDetails,
  recieveNonProfitCompanyDetails,
  requestNonProfitCompanyDetailsError,
} from './actions';
import {Services} from 'core/Services';

const services = new Services();
const initialState = {request: false, error: false, data: null};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_NONPROFIT_COMPANY_DETAILS:
      return {
        ...state,
        error: false,
        request: true,
      };
    case TYPES.RECIEVE_NONPROFIT_COMPANY_DETAILS:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.REQUEST_NONPROFIT_COMPANY_DETAILS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_NONPROFIT_COMPANY_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export const fetchNonProfitCompanyDetails = subDomain => (
  dispatch,
  _getState,
) => {
  let endPoint = `${API.srm.nonProfit}/${subDomain}`;
  dispatch(requestNonProfitCompanyDetails());
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveNonProfitCompanyDetails(res));
        return;
      }
      dispatch(requestNonProfitCompanyDetailsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestNonProfitCompanyDetailsError(err));
  }
};

export const resetNonProfitCompanyDetails = () => (dispatch, getState) => {
  dispatch(clearNonProfitCompanyDetails());
};

import {Modal, Button} from 'antd';
import './style.less';

// visible = modal visibility
// onClose = backdrop and close button event
// onOk = OK button event
// icon = cloudinary or local image src adresss, if not specified it will hide
// title = title of the modal
// description = description of the modal in array (supports html tags) (all array items printed as seperated lines)
// buttonText = center ok button text

const WarnModal = ({
  visible = false,
  onClose,
  icon = '',
  onOk,
  title = '',
  description = [],
  buttonText = '',
}) => {
  return (
    <Modal
      wrapClassName='warn-modal-wrap'
      visible={visible}
      onCancel={onClose}
      footer={null}
      className='warn-modal'>
      <div className='d-content'>
        {icon && <img className='icn' src={icon} />}
        {title && <div className='ttl'>{title}</div>}
        {Array.isArray(description) &&
          description.length > 0 &&
          description.map(desc => (
            <div
              className='desc'
              key={desc}
              dangerouslySetInnerHTML={{__html: desc}}>
              {/* {desc} */}
            </div>
          ))}
        {buttonText && (
          <Button type='primary' className='login-btn' onClick={onOk}>
            {buttonText}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default WarnModal;

import React, {useState, useEffect} from 'react';
import {Accordion} from 'mui-core';
import {Check} from '@mui/icons-material';
import {Alert, Snackbar} from '@mui/material';
import SubscribedCareer from './SubscribedCareer';
import UnsubscribeCareer from './UnSubscribedCareer';

const CareerFavorites = props => {
  const {fetchSubscribedOccupation, unSubscribeForOccupation} = props;
  const [showUnsubscribeCareerDialog, setUnsubscribeCareerDialog] = useState(
    false,
  );
  const [selectedValue, setSelectedValue] = useState(null);
  const [snackBarSuccessMsg, setSnackBarSuccessMsg] = useState(null);
  const onUnsubscribe = data => {
    setUnsubscribeCareerDialog(true);
    setSelectedValue(data);
  };
  const unSubscribedCareerDialogClose = () => {
    setUnsubscribeCareerDialog(false);
  };
  const unSubscribeOccupation = async occupation_uuid => {
    if (occupation_uuid) {
      await unSubscribeForOccupation(occupation_uuid, res => {
        if (res === 'success') {
          setSnackBarSuccessMsg('Removed Successfully');
          unSubscribedCareerDialogClose();
        }
      });
      fetchSubscribedOccupation();
    }
  };
  useEffect(() => {
    fetchSubscribedOccupation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Accordion defaultOpen outlined={false} header='My Career Favorites'>
        <SubscribedCareer {...props} onUnsubscribe={onUnsubscribe} />
        {showUnsubscribeCareerDialog === true && (
          <UnsubscribeCareer
            selectedValue={selectedValue && selectedValue}
            open={showUnsubscribeCareerDialog}
            unSubscribedCareerDialogClose={unSubscribedCareerDialogClose}
            unSubscribedCareerDialogConfirm={unSubscribeOccupation}
          />
        )}
      </Accordion>
      {snackBarSuccessMsg && (
        <Snackbar
          autoHideDuration={5000}
          open={snackBarSuccessMsg}
          onClose={() => setSnackBarSuccessMsg('')}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
          <Alert
            variant='filled'
            severity='success'
            icon={<Check fontSize='inherit' />}
            sx={{width: '100%', color: 'white'}}>
            {snackBarSuccessMsg}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default CareerFavorites;

import {ContentBox} from 'mui-core';
import {useSelector} from 'react-redux';
import AddPortfolio from './AddPortfolioURL';
import {getFilesAndTotalSize} from 'core/utils';
import {portfolio} from 'data/dashboard-settings.json';
import {profileDataSelector} from 'redux/modules/profile';
import DocUploader from 'app/modules/newDashboard/components/DocUploader';

const {heading, subHeading, document_type} = portfolio || {};

const Portfolio = () => {
  const {data, request, error} = useSelector(profileDataSelector) || {};
  const {data: document_files, total_size_consumed} = getFilesAndTotalSize(
    data,
    document_type,
  );

  return (
    <ContentBox elevation={0} sx={{p: 4}}>
      <DocUploader
        title={heading}
        type={document_type}
        description={subHeading}
        profileData={{
          error,
          request,
          total_size_consumed,
          data: document_files,
        }}
      />
      {data && <AddPortfolio profileData={data} profileDataRequest={request} />}
    </ContentBox>
  );
};

export default Portfolio;

import React, {useState, useEffect, useRef, useCallback} from 'react';
import {connect} from 'react-redux';
import {Link, useLocation, useHistory} from 'react-router-dom';
import {Button, Dropdown, Menu, Skeleton, Select} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import {isString} from 'lodash';

import {openLoginScreen, logout} from 'redux/modules/auth';
import {getAppConfig, changeLang} from 'redux/modules/general';
import {useAuth, useScroll, useUser, usePrevious} from 'core/hooks';
import {
  getProfilePhoto,
  queryStringParse,
  getLogo,
  combineMenuLinksToOneBlock,
} from 'core/utils';
import {HeaderMenu, FooterLinks, LanguageMenu} from 'config';

import {avatarGray, goEducateLogo} from 'assets/images';

import {navigationMenus} from 'data/footer';
import {headerLoginDropdown} from 'data/header';

import './style.less';
import {Switch} from '@mui/material';
import {ThemeSwitch} from 'mui-core';

//const {Option} = Select;
const FloatingHeader = props => {
  const {
    data: instanceData,
    openLoginScreen,
    logout,
    hideHeaderFooter,
    appConfig,
    // changeLang,
  } = props || {};
  const history = useHistory();
  const [token, user] = useAuth();
  const userData = useUser();
  const appHeader = useRef();
  const YOffset = useScroll();
  const {search} = useLocation();
  const {sign_in} = queryStringParse(search);
  const prevValue = usePrevious(sign_in);
  const {isMobileView, lang} = appConfig || {};

  const [dropdownState, setDropdownState] = useState('close');

  const openLogin = useCallback((screen = 'signin', navigateTo) => {
    openLoginScreen({
      screen,
      callback: auth => {
        if (navigateTo && isString(navigateTo)) {
          history.push(navigateTo);
        }
      },
      redirect: false,
    });
  });

  useEffect(() => {
    if (!prevValue && sign_in && sign_in.toLowerCase() === 'true')
      openLogin('signin', '/');
    return () => {};
  }, [sign_in]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const header = appHeader.current;
    const bodyClassSticky = document.getElementsByTagName('body');
    if (YOffset > 80) {
      header.classList.add('sticky');
      bodyClassSticky[0].classList.add('stickyHeader');
    } else if (YOffset < 80) {
      header.classList.remove('sticky');
      bodyClassSticky[0].classList.remove('stickyHeader');
    }
  }, [YOffset]);

  // Need to imporove
  const onDropdownClick = val => setDropdownState(val ? 'open' : 'close');

  const onMenuClick = ({key}) => {
    if (key.toLowerCase() === 'logout') {
      logout();
    }
    setDropdownState('close');
  };

  const profileMenu = () => (
    <Menu onClick={onMenuClick} data-cy='header-dropdown-menu'>
      {HeaderMenu.map(({key, to}) => {
        const {[key]: {label = ''} = {}} = headerLoginDropdown || {};
        return (
          <Menu.Item key={key} data-cy={`header-menuitem-${key.toLowerCase()}`}>
            {to ? <Link to={to}>{label}</Link> : label}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const headerMenus = (title, links, rest) => (
    <Menu key={`__${title}__menu__`} data-cy='header-resources-links'>
      {/* {links?.map(({link, key, target = '_blank', anchor}) => {
        const {[key]: {name} = {}} = rest || {};
        return (
          <Menu.Item
            className='nav-item-dropdown-text'
            key={`__${title}__${name}__`}>
            {anchor ? (
              <Button
                className='mx-0 header__dropdown'
                href={link}
                target={target}>
                {name}
              </Button>
            ) : (
              <Link to={link}>{name}</Link>
            )}
          </Menu.Item>
        );
      })} */}
      {!token && isMobileView && (
        <>
          {FooterLinks.filter(link => link.isDirectLink).map(link =>
            link.links.map(({link, key, target = '_blank', anchor}) => (
              <Menu.Item className='nav-item-dropdown-text' key='key'>
                <Button
                  type='secondary'
                  href={link}
                  target={target}
                  className='mx-0 header__dropdown authBtn'
                  data-cy='sign-in-btn'>
                  {'BECOME A PARTNER'}
                </Button>
              </Menu.Item>
            )),
          )}
          <Menu.Item className='nav-item-dropdown-text' key='loginBtn'>
            <Button
              type='secondary'
              className='mx-0 header__dropdown authBtn'
              onClick={() => openLogin('signin')}
              data-cy='sign-in-btn'>
              {headerLoginDropdown?.signInText || ''}
            </Button>
          </Menu.Item>
          <Menu.Item className='nav-item-dropdown-text' key='SignUpBtn'>
            <Button
              type='secondary'
              className='mx-0 header__dropdown authBtn'
              onClick={() => openLogin('signup')}
              data-cy='sign-up-btn'>
              {headerLoginDropdown?.createFreeProfileText || ''}
            </Button>
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const USER_PHOTO =
    (userData?.data?.photo && getProfilePhoto(userData?.data?.photo)) ||
    avatarGray;

  const LOGO =
    (instanceData?.institution_id && getLogo(instanceData?.logo_cloudinary)) ||
    goEducateLogo;

  const dropDownMode = isMobileView ? 'click' : 'hover';

  // const onLangChange = lang => changeLang(lang);

  let MenuItemsDisplay = FooterLinks;
  if (isMobileView) {
    MenuItemsDisplay = combineMenuLinksToOneBlock(FooterLinks);
  }
  const outsideHeaderVisible = !hideHeaderFooter ? 'outsideHeader' : 'p-0';

  return (
    <div ref={appHeader} className='app-floating-header'>
      <div className={`header_flex  ${outsideHeaderVisible} mx-4`}>
        {!hideHeaderFooter && (
          <div data-cy='header-logo'>
            <Link to={hideHeaderFooter ? history.location.pathname : '/'}>
              <span
                className={`${
                  instanceData?.institution_id ? 'instance-logo' : ''
                } anc_pathwayLogo`}
                style={{
                  background: `url(${LOGO}) no-repeat left`,
                }}></span>
            </Link>
          </div>
        )}
        <div>{props.children || null}</div>

        <div>
          {!hideHeaderFooter && (
            <div className='user_top_menu' data-cy='header-menu'>
              <div
                className='justify-content-center align-items-center d-flex'
                justify={isMobileView ? 'space-between' : 'end'}
                align='middle'>
                {/* Future requirement */}
                {/* <Select
                placeholder={'Language'}
                defaultValue={lang}
                className='language-dropdown'
                value={lang}
                onChange={val => onLangChange(val)}>
                {LanguageMenu.map(({value, label, key}) => (
                  <Option
                    style={{color: '#000'}}
                    className='header__dropdown'
                    key={key}
                    value={value}>
                    {label}
                  </Option>
                ))}
              </Select> */}
                {/* Menu */}

                <ThemeSwitch />
                {MenuItemsDisplay?.map(
                  ({links = [], key: navKey, isDirectLink}, idx) => {
                    const {[navKey]: {title = '', ...rest} = {}} =
                      navigationMenus || {};

                    if (links.length) {
                      if (isDirectLink) {
                        return links.map(({link, key, target}) => {
                          const {[key]: {name} = {}} = rest || {};
                          return (
                            <Button key={name} href={link} target={target}>
                              {name}
                            </Button>
                          );
                        });
                      } else {
                        return isMobileView ? ( // added due to CR on hiding resourse tab menu
                          <Dropdown
                            overlayClassName='header-resource'
                            overlay={() => headerMenus(title, links, rest)}
                            key={`__${title}__dropdown__`}
                            trigger={[dropDownMode]}>
                            {isMobileView && idx === 0 ? (
                              <MenuOutlined />
                            ) : (
                              <div /> //added due to CR on hiding resourse tab menu
                              // <Button
                              //   className='ml-1 mr-3 header__dropdown'
                              //   data-cy='header-resources'>
                              //   {'title'}
                              //   <span
                              //     className='top_arrowdown resourcesMenu'
                              //     data-cy='header-arrowdown'></span>
                              // </Button>
                            )}
                          </Dropdown>
                        ) : (
                          <div />
                        );
                      }
                    }
                  },
                ).filter(Boolean)}
                {/* signIn signUp */}
                {!token && !isMobileView ? (
                  <>
                    <Button
                      type='secondary'
                      className='loginBtn'
                      onClick={() => openLogin('signin')}
                      data-cy='sign-in-btn'>
                      {headerLoginDropdown?.signInText || ''}
                    </Button>
                    <Button
                      type='primary'
                      className=''
                      onClick={() => openLogin('signup')}
                      data-cy='sign-up-btn'>
                      {headerLoginDropdown?.createFreeProfileText || ''}
                    </Button>
                  </>
                ) : (
                  token && (
                    <Dropdown
                      overlay={userData.request ? <></> : profileMenu}
                      overlayClassName='header-menu-list'
                      onVisibleChange={onDropdownClick}
                      trigger={['click', 'hover']}>
                      <ul
                        className='top_userProfile mr-2'
                        data-cy='header-user-profile'>
                        <li>
                          {userData.request && (
                            <Skeleton
                              active={true}
                              avatar={{
                                size: isMobileView ? 25 : 35,
                                shape: 'circle',
                              }}
                              title={{width: isMobileView ? 60 : 150}}
                              paragraph={false}
                            />
                          )}
                          {!userData.request && userData.data && (
                            <h3 className={`header-menu ${dropdownState}`}>
                              <img
                                src={USER_PHOTO}
                                alt='user'
                                className='userAvatar mr-2'
                              />
                              <span
                                className='top_userName'
                                data-cy='header-user-name'>{`${userData.data.first_name} ${userData.data.last_name}`}</span>
                              <span
                                className='top_arrowdown'
                                data-cy='header-arrowdown'></span>
                            </h3>
                          )}
                        </li>
                      </ul>
                    </Dropdown>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  appConfig: getAppConfig(state),
});

const mapDispatchToProps = dispatch => ({
  openLoginScreen: navigateTo => dispatch(openLoginScreen(navigateTo)),
  logout: () => dispatch(logout()),
  changeLang: lang => dispatch(changeLang(lang)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FloatingHeader);

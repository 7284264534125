import React, {useState} from 'react';
import {
  Box,
  Stack,
  Switch,
  styled,
  useTheme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {ArrowRight} from 'assets/images';
import {Card, IconButton} from 'mui-core';
import {Check} from '@mui/icons-material';
import {ForEmployer} from 'data/partner.json';

const {pricePlans} = ForEmployer;
const {pricePlansList, pricePlanTitle, pricePlanSubTitle} = pricePlans || {};

const TenureSwitch = styled(Switch)(({theme}) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '& + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
}));

const PlanLists = ({setShowSignupModal}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [activeCard, setActiveCard] = useState(1);
  const [planSwitch, setPlanSwitch] = useState(true);

  return (
    <Box>
      <Stack direction='column' justifyContent='space-between' gap={4}>
        <Box>
          {pricePlanTitle && (
            <Typography
              component='h2'
              variant='header-md-bold'
              textTransform='capitalize'
              dangerouslySetInnerHTML={{__html: pricePlanTitle}}
            />
          )}
          {pricePlanSubTitle && (
            <Typography
              component='p'
              variant='text-lg-regular'
              sx={{
                '& span': {
                  fontWeight: 700,
                },
                '& .colored': {
                  color: theme.palette.primary.main,
                },
              }}
              dangerouslySetInnerHTML={{__html: pricePlanSubTitle}}
            />
          )}
        </Box>
        <Stack
          spacing={1}
          direction='row'
          alignItems='center'
          justifyContent='center'>
          <Typography component='p' variant='text-lg-regular'>
            Monthly
          </Typography>
          <Box>
            <TenureSwitch
              inputProps={{'aria-label': 'plan switch'}}
              onChange={() => setPlanSwitch(!planSwitch)}
            />
          </Box>
          <Typography component='p' variant='text-lg-regular'>
            Annually (save 10%)
          </Typography>
        </Stack>
      </Stack>
      <Stack
        mt={{xs: 3, md: 7}}
        gap={{xs: 2.5, md: 3}}
        justifyContent='start'
        direction={{xs: 'column', sm: 'row'}}
        flexWrap={{sm: 'wrap', lg: 'nowrap'}}>
        {Array.isArray(pricePlansList) &&
          pricePlansList.length > 0 &&
          pricePlansList.map(
            (
              {
                addOns = [],
                pricePlanBtn,
                pricePlanRate,
                pricePlanRateYr,
                pricePlanOffer,
                pricePlansName,
                pricePlanTitle,
                pricePlanPoints,
                pricePlanLearnMore,
                pricePlanLearnSubText,
              },
              index,
            ) => (
              <Card
                key={index}
                sx={{
                  pt: {xs: '40px', sm: '50px'}, // Responsive top padding
                  pb: {xs: '30px', sm: '40px'},
                  maxWidth: {xs: '100%', sm: '33.3%'},
                  minWidth: {xs: '100%', sm: '33.3%'},
                  minHeight: {xs: 'auto', sm: '575px'}, // Flexible minHeight for mobile
                  px: {xs: '24px !important', sm: '12px !important', md: 5}, // Adjust padding for mobile
                  border:
                    activeCard === index
                      ? `1px solid ${theme.palette.primary.dark}`
                      : '1px solid transparent',
                }}>
                <Stack
                  gap={3}
                  direction='column'
                  sx={{height: '100%'}}
                  alignContent='space-around'
                  justifyContent='space-between'>
                  <Stack gap='15px' sx={{flexGrow: 1}} position='relative'>
                    {pricePlanOffer && (
                      <Typography
                        p='4px'
                        top='-8%'
                        component='p'
                        textAlign='left'
                        bgcolor='#06AED4'
                        borderRadius='6px'
                        position='absolute'
                        variant='text-sm-regular'
                        sx={{width: 'fit-content'}}
                        color={theme.palette.grey.contrastText}>
                        {pricePlanOffer}
                      </Typography>
                    )}
                    <Typography
                      component='h4'
                      textAlign='center'
                      variant='title-lg-bold'
                      color={theme.palette.primary.main}>
                      {pricePlansName}
                    </Typography>
                    <Stack
                      gap='4px'
                      direction='row'
                      alignItems='center'
                      justifyContent='center'>
                      <Typography variant='title-lg-bold' component='h4'>
                        {planSwitch ? pricePlanRate : pricePlanRateYr}
                      </Typography>
                      <Typography
                        component='h5'
                        variant='title-sm-medium'
                        sx={{color: '#919EAB'}}>
                        {planSwitch ? '/mo' : '/yr'}
                      </Typography>
                    </Stack>
                    <Typography
                      component='p'
                      variant='text-md-regular'
                      textAlign='center'
                      color={theme.palette.primary.main}>
                      {pricePlanLearnMore}
                    </Typography>
                    <Typography
                      component='p'
                      textAlign='center'
                      variant='text-md-regular'>
                      {pricePlanLearnSubText}
                    </Typography>
                    <Stack gap='10px' mt={1}>
                      {pricePlanTitle && (
                        <Typography
                          component='p'
                          variant='text-sm-regular'
                          sx={{
                            '& span': {
                              color: theme.palette.primary.main,
                              fontWeight: 700,
                            },
                          }}
                          dangerouslySetInnerHTML={{__html: pricePlanTitle}}
                        />
                      )}
                      {pricePlanPoints.map(item => (
                        <Stack
                          gap={1.5}
                          key={item}
                          direction='row'
                          alignItems='center'>
                          <Check
                            sx={{
                              width: '20px',
                              height: '20px',
                              color: theme.palette.primary.main,
                            }}
                          />
                          <Typography
                            component='p'
                            variant='text-sm-regular'
                            sx={{
                              '& span': {
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              },
                            }}
                            dangerouslySetInnerHTML={{__html: item}}
                          />
                        </Stack>
                      ))}
                      {addOns && addOns.length > 0 && (
                        <Stack gap='10px'>
                          <Typography component='p' variant='text-sm-bold'>
                            Optional Add-Ons
                          </Typography>
                          {addOns.map(item => (
                            <Stack
                              gap={1.5}
                              key={item}
                              direction='row'
                              alignItems='center'>
                              <Check
                                sx={{
                                  width: '20px',
                                  height: '20px',
                                  color: theme.palette.primary.main,
                                }}
                              />
                              <Typography
                                component='p'
                                variant='text-sm-regular'
                                sx={{
                                  '& span': {
                                    color: theme.palette.primary.main,
                                    fontWeight: 700,
                                  },
                                }}
                                dangerouslySetInnerHTML={{__html: item}}
                              />
                            </Stack>
                          ))}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                  <IconButton
                    size='small'
                    sx={{py: 1, px: 6}}
                    variant='contained'
                    label={pricePlanBtn}
                    endIcon={ArrowRight}
                    iconSize={isMobile ? '12px' : '14px'}
                    onClick={() => setShowSignupModal(true)}
                  />
                </Stack>
              </Card>
            ),
          )}
      </Stack>
    </Box>
  );
};

export default PlanLists;

import {
  Accordion,
  Button,
  Carousel,
  DialogBox,
  ContentBox,
  NoContentNavigator,
  PathCard,
} from 'mui-core';
import {home as homeJson} from 'data/dashboard';
import {
  Badge,
  Box,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
  badgeClasses,
  styled,
  tabClasses,
  tabsClasses,
} from '@mui/material';
import {isArray, isEmpty} from 'lodash';
import {
  yesTxt,
  cancelTxt,
  NoSavedPathFound,
  PathWillBeRemoved,
  ExploreProgramsBtnText,
  DeleteActivePathMessage,
  confirmToUnSaveProgramTxt,
  PathWillBeRemovedFromDashboard,
  confirmToDeleteEnrolledProgramTxt,
} from 'data/pathway';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  deleteActivePath,
  fetchActivePathData,
  fetchSavedPaths,
  getActivePath,
  removeSavedPath,
  savedPathSelector,
} from 'redux/modules/pathways';
import {useSnackbar} from 'notistack';
import {amber, lightBlue} from '@mui/material/colors';
import {fetchProfileData} from 'redux/modules/profile';

const {
  programs: {header},
} = homeJson;

const StyledTabs = styled(Tabs)(({theme}) => ({
  marginLeft: '21px',
  position: 'relative',
  top: '5px',
  [`& .${tabsClasses.flexContainer}`]: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 50,
    padding: '5px',
  },
  [`& .${tabsClasses.indicator}`]: {
    backgroundColor: theme.palette.primary.main,
    height: 'calc(100% - 15px)',
    top: '5px',
    borderRadius: 50,
  },
  [`& .${tabClasses.root}`]: {
    minHeight: '30px',
    borderRadius: 50,
    overflow: 'initial',
    paddingTop: '5px',
    paddingBottom: '5px',
    zIndex: 3,
    textTransform: 'initial',
    fontWeight: 500,
    [`&.${tabClasses.selected}`]: {
      color: '#fff',
    },
    // backgroundColor: theme.palette.primary.main,
  },
}));
const StyledBadge = styled(Badge)(({backgroundColor}) => ({
  [`& .${badgeClasses.badge}`]: {
    top: '-1px',
    right: '-10px',
    padding: '0px!important',
    width: '16px',
    minWidth: '16px',
    height: '16px',
    minHeight: '16px',
    fontSize: '10px',
    backgroundColor,
    color: 'black',
  },
}));
const Programs = ({}) => {
  // hooks
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  // state
  const [tab, setTab] = useState('Enrolled');
  const [deleteProps, setDeleteProps] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // vars
  const savedPaths = useSelector(savedPathSelector);
  const activePaths = useSelector(getActivePath);
  const [loader, setLoader] = useState(false);
  const enrolled = activePaths?.data;
  const saved = savedPaths?.data;
  const request = savedPaths?.request || activePaths?.request;
  let path_details = [];
  if (tab === 'Enrolled') {
    path_details = enrolled;
  } else {
    path_details = saved;
  }

  useEffect(() => {
    if (enrolled && enrolled.length === 0) {
      if (tab === 'Enrolled' && saved?.length > 0) {
        setTab('Saved');
      }
    }
    if (saved && saved.length === 0) {
      if (tab === 'Saved' && enrolled?.length > 0) {
        setTab('Enrolled');
      }
    }
  }, [savedPaths, activePaths, enrolled, saved, tab]);

  const removeProgramClick = (e, props) => {
    setDeleteProps(props);
    setDeleteModal(true);
  };
  const onConfirmDelete = async () => {
    setLoader(true);
    if (deleteProps.saved_or_enrolled === 'Enrolled') {
      dispatch(
        deleteActivePath(deleteProps?.student_onboard_uuid, async err => {
          setLoader(false);
          onCloseDeleteModal();
          // dispatch(fetchActivePathData());
          if (err) {
            enqueueSnackbar(err, {
              variant: 'error',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          } else {
            await dispatch(dispatch(fetchProfileData()));
            enqueueSnackbar(DeleteActivePathMessage, {
              variant: 'success',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          }
        }),
      );
    } else {
      await dispatch(removeSavedPath(deleteProps?.uuid));
      setLoader(false);
      onCloseDeleteModal();
      dispatch(fetchSavedPaths());
    }
  };
  const onCloseDeleteModal = () => {
    setDeleteProps(false);
    setDeleteModal(false);
  };
  console.log('saved_path render', request);
  return path_details && isArray(path_details) && !isEmpty(path_details) ? (
    <ContentBox elevation={0}>
      <Accordion
        defaultOpen
        collpsable
        header={
          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant='h5' title={header} color='text.primary'>
              {header}
            </Typography>
            {enrolled && enrolled.length > 0 && saved && saved.length > 0 && (
              <StyledTabs
                value={tab}
                onChange={(e, v) => {
                  e.stopPropagation();
                  setTab(v);
                }}
                aria-label='basic tabs example'>
                <Tab
                  label={
                    <StyledBadge
                      backgroundColor={amber[500]}
                      badgeContent={enrolled?.length}>
                      Enrolled
                    </StyledBadge>
                  }
                  value={'Enrolled'}
                />
                <Tab
                  sx={{marginLeft: '6px'}}
                  label={
                    <StyledBadge
                      backgroundColor={lightBlue[300]}
                      badgeContent={saved?.length}
                      color='info'>
                      Saved
                    </StyledBadge>
                  }
                  value={'Saved'}
                />
              </StyledTabs>
            )}
          </Stack>
        }
        outlined={false}>
        {request && (
          <Paper sx={{width: 240, marginRight: 0.5, my: 5, p: 1}} variant=''>
            <Skeleton variant='rectangular' width={'100%'} height={158} />
            <Box sx={{pt: 1}}>
              <Skeleton />
              <Skeleton width='60%' />
            </Box>
            <Box sx={{pt: 1.5}}>
              <Skeleton width={'50%'} />
              <Skeleton width='30%' />
            </Box>
          </Paper>
        )}
        <Box>
          {path_details &&
          isArray(path_details) &&
          !isEmpty(path_details) &&
          !request ? (
            <Carousel
              slidesToShow={4}
              content={path_details.map(path => (
                <Box key={path.path_uuid} sx={{padding: '8px 12px'}}>
                  <PathCard
                    enableNavigation
                    key={path.path_uuid}
                    customClass='sliderCard'
                    customTag={path.saved_or_enrolled}
                    data={path?.path_details || {}}
                    displayEllipses={[
                      {
                        title: 'Remove',
                        action: (e, ellipsisRef) => {
                          e.stopPropagation();
                          //   unSavePath(
                          //     path?.path_details?.title,
                          //     path?.path_details?.uuid,
                          //   );
                          removeProgramClick(e, {
                            title: path?.path_details?.title,
                            uuid: path?.path_details?.uuid,
                            saved_or_enrolled: path?.saved_or_enrolled,
                            student_onboard_uuid: path?.student_onboard_uuid,
                            anchorReference: ellipsisRef,
                          });
                        },
                      },
                    ]}
                  />
                </Box>
              ))}
            />
          ) : (
            !request && (
              <NoContentNavigator
                pathTo='/programs'
                message={NoSavedPathFound}
                label={ExploreProgramsBtnText}
              />
            )
          )}
        </Box>
      </Accordion>

      {deleteModal && (
        <DialogBox
          openDialog={deleteModal}
          closeDialog={onCloseDeleteModal}
          actions={
            <>
              <Button
                variant='outlined'
                onClick={onCloseDeleteModal}
                sx={{minWidth: {xs: '80px', md: '168px'}}}>
                {cancelTxt}
              </Button>
              <Button
                variant='contained'
                onClick={onConfirmDelete}
                sx={{minWidth: {xs: '80px', md: '168px'}}}>
                {yesTxt}
              </Button>
            </>
          }>
          <Stack spacing={1}>
            <Typography textAlign='center' variant='h4' color='text.primary'>
              {deleteProps.saved_or_enrolled === 'Enrolled'
                ? confirmToDeleteEnrolledProgramTxt
                : confirmToUnSaveProgramTxt}
            </Typography>
            <Typography variant='body1' textAlign='center' color='text.primary'>
              {deleteProps.saved_or_enrolled === 'Enrolled'
                ? PathWillBeRemoved
                : PathWillBeRemovedFromDashboard}
            </Typography>
          </Stack>
        </DialogBox>
      )}
    </ContentBox>
  ) : (
    <div />
  );
};
export default Programs;

import {
  Box,
  List,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {Button, themeColorMode} from 'mui-core';

const steps = [
  // `A pathway describes and links a degree, certification, or training
  //   program to the steps needed to pursue it. A pathway also connects
  //   you to related occupations, job opportunities, employers, and events
  //   (coming soon!).`,
  // `Completing a pathway on GoEducate gives you an education plan and a powerful way to explore career options.`,
  `To apply or enroll in a pathway program, follow the links below to the school website and complete the steps there.`,
  `You can skip to any of the next steps and explore the courses in this program. When you’re ready to apply or enroll, navigate back here for further guidance.`,
];

const Welcome = ({
  programData,
  completed,
  onboardStatus,
  onStart,
  onCancel,
  onGoBackOnBoard,
}) => {
  const {institute_details: {name} = {}} = programData || {};
  return (
    <Box>
      <Typography variant='h2' sx={{mb: 2}} color={'primary.main'}>
        Welcome to {name || ''} and your pathway to success!
      </Typography>
      <Typography variant='h5' sx={{mb: 2, ml: 1}} color={'text.primary'}>
        Getting Started
      </Typography>
      <List aria-label='contacts' sx={{mb: 2, ml: 1}}>
        {steps.map((st, idx) => (
          <ListItem
            disablePadding
            key={idx}
            alignItems='flex-start'
            sx={{mb: 1}}>
            <ListItemIcon sx={{minWidth: 32}}>
              <FiberManualRecordIcon fontSize='10' color='secondary' />
            </ListItemIcon>
            <ListItemText
              primary={st}
              sx={{
                color: theme =>
                  themeColorMode(theme, 'text.secondary', 'text.primary'),
              }}
            />
          </ListItem>
        ))}
      </List>
      {completed.completed_task_uuid.length === 0 &&
        onboardStatus.currentStep === -1 && (
          <Stack direction={'row'} sx={{pb: 3}} spacing={2}>
            <Button variant={'contained'} size={'large'} onClick={onStart}>
              Get Started
            </Button>
            {/* <Button size={'large'} onClick={onGoBackOnBoard}>
              Go Back
            </Button> */}
            <Button size={'large'} onClick={onCancel}>
              Cancel
            </Button>
          </Stack>
        )}
    </Box>
  );
};
export default Welcome;

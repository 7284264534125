import * as TYPES from './constants';

export const requestCareerRank = () => ({
  type: TYPES.REQUEST_CAREER_RANK,
});
export const recieveCareerRank = (json, loadMore = false) => ({
  type: TYPES.RECIEVE_CAREER_RANK,
  payload: json,
  loadMore,
});
export const requestCareerRankError = err => ({
  type: TYPES.REQUEST_CAREER_RANK_ERROR,
  err,
});
export const clearCareerRank = () => ({
  type: TYPES.CLEAR_CAREER_RANK,
});

// Occupation by cluster
export const requestOccupationByCluster = () => ({
  type: TYPES.REQUEST_OCCUPATION_BY_CLUSTER,
});
export const recieveOccupationByCluster = (json, loadMore = false) => ({
  type: TYPES.RECIEVE_OCCUPATION_BY_CLUSTER,
  payload: json,
  loadMore,
});
export const requestOccupationByClusterError = err => ({
  type: TYPES.REQUEST_OCCUPATION_BY_CLUSTER_ERROR,
  err,
});

// Related Career
export const requestRelatedCareers = () => ({
  type: TYPES.REQUEST_RELATED_CAREERS,
});
export const recieveRelatedCareers = json => ({
  type: TYPES.RECIEVE_RELATED_CAREERS,
  payload: json,
});
export const requestRelatedCareersError = err => ({
  type: TYPES.REQUEST_RELATED_CAREERS_ERROR,
  err,
});

// Save Career lists
export const requestSavedCareerList = () => ({
  type: TYPES.SAVED_CAREER_LIST_REQUEST,
});
export const receiveSavedCareerList = json => ({
  type: TYPES.SAVED_CAREER_LIST_SUCCESS,
  payload: json,
});
export const requestSavedCareerListError = err => ({
  type: TYPES.SAVED_CAREER_LIST_ERROR,
  err,
});
export const clearSavedCareerListError = () => ({
  type: TYPES.SAVED_CAREER_LIST_CLEAR,
});

// Save Career
export const requestSaveCareer = () => ({
  type: TYPES.SAVE_CAREER_REQUEST,
});
export const receiveSaveCareer = json => ({
  type: TYPES.SAVE_CAREER_SUCCESS,
  payload: json,
});
export const requestSaveCareerError = err => ({
  type: TYPES.SAVE_CAREER_ERROR,
  err,
});

import {lazy} from 'react';
import {retry} from 'core/utils';

const JobOpportunities = lazy(() =>
  retry(() => import('./subModules/JobOpportunities')),
);
const OpportunityDetails = lazy(() =>
  retry(() => import('./subModules/OpportunityDetails')),
);

export {JobOpportunities, OpportunityDetails};

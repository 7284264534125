import {ContentBox} from 'mui-core';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {Close, Download} from '@mui/icons-material';
import {downloadResume} from 'redux/modules/resumes';
import {downloadErrorMessage} from 'data/resume.json';
import DataPreview from 'app/modules/resume-builder/sharedComponents/Preview/DataPreview';
import {
  Stack,
  Dialog,
  useTheme,
  IconButton,
  useMediaQuery,
} from '@mui/material';

const ResumePreview = ({data, onOpenModal, onCloseModal}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleResumeDownload = (resumeId, resumeName) => {
    const onSuccess = response => {
      const blob = new Blob([response], {
        type: 'application/pdf',
        encoding: 'UTF-8',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = resumeName;
      link.click();
    };

    const onError = () => {
      enqueueSnackbar(downloadErrorMessage, {
        variant: 'error',
        anchorOrigin: {horizontal: 'center', vertical: 'top'},
      });
    };
    dispatch(downloadResume(resumeId, onSuccess, onError));
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={onOpenModal}
      fullScreen={isMobile}
      onClose={onCloseModal}>
      <ContentBox
        elevation={0}
        sx={{
          padding: '32px',
          borderRadius: '4px',
          height: isMobile ? '100%' : 835,
        }}>
        <Stack
          gap={2}
          direction='row'
          sx={{
            top: 2,
            right: 6,
            zIndex: 9,
            paddingRight: '24px',
            position: 'absolute',
          }}>
          <IconButton
            size='medium'
            title='Download'
            aria-label='download'
            color='text.primary'
            onClick={() =>
              handleResumeDownload(data.resume_uuid, data.resume_name)
            }>
            <Download fontSize='medium' />
          </IconButton>

          <IconButton
            title='Close'
            size='small'
            aria-label='close'
            color='text.primary'
            onClick={onCloseModal}>
            <Close fontSize='medium' />
          </IconButton>
        </Stack>
        <DataPreview data={data} isMobile={isMobile} purpleHeader />
      </ContentBox>
    </Dialog>
  );
};

export default ResumePreview;

import './style.less';

export default ({
  list,
  title,
  imgSrc,
  classes,
  btn_txt,
  heading,
  handleClick,
  description,
}) => (
  <li className={classes} key={`resource-${title}`} onClick={handleClick}>
    <img src={imgSrc} className='img-fluid' alt={`${title}`} />
    {heading && <h4>{heading}</h4>}
    {title && <h3 dangerouslySetInnerHTML={{__html: title}} />}
    {list?.length ? (
      <ul className='home_card_list'>
        {list.map((item, idx) => (
          <li key={`res-item-${idx}`}>{item}</li>
        ))}
      </ul>
    ) : (
      <p>{description}</p>
    )}
    {btn_txt && <span className='card_btn'>{btn_txt}</span>}
  </li>
);

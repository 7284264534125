import styles from './header.module.less';
import './style.less';

/**
 * Header Component is a functional component
 * @component
 * @alias Header
 * @property {any} children Needs children to render nested elements
 */

const Header = ({children, className, style}) => {
  return (
    <header className={`${className}`} style={style}>
      {/* <header className={className} style={style}> */}
      <div className={`${styles.header_container}`}>{children}</div>
    </header>
  );
};

export default Header;

import React from 'react';
import {
  Box,
  Chip,
  Select,
  Tooltip,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {RequestErrorLoader} from 'mui-core';
import ClearIcon from '@mui/icons-material/Clear';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const StyledSelect = styled(Select)(({theme}) => ({
  minWidth: 216,
  paddingLeft: 8,
  paddingRight: 8,
  border: 'none !important',
  whiteSpace: {xs: 'noWrap', sm: 'none'},
  [theme.breakpoints.down('md')]: {
    fontSize: '12px !important',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '15px',
  },
}));

const StyledCheckbox = styled(Checkbox)(({theme}) => ({
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledChip = styled(Chip)(({theme}) => ({
  color: 'white',
  fontWeight: 700,
  inlineSize: 'fit-content',
  margin: '0px 4px 8px 0px',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px !important',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '15px',
  },
  background: theme.palette.primary.main,
  ':hover': {
    background: theme.palette.primary.dark,
  },
}));

export default function MultiSelectWithChip({
  name,
  label,
  value,
  request,
  onDelete,
  onChange,
  options = [],
}) {
  return (
    <FormControl>
      <InputLabel id={name}>{label}</InputLabel>
      <StyledSelect
        multiple
        id={name}
        fullWidth
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        MenuProps={MenuProps}
        placeholder={`Select ${label}`}
        input={<OutlinedInput label={label} />}
        renderValue={selected => selected.join(', ')}>
        <RequestErrorLoader
          body={{
            request,
            data: options,
          }}
          hideBackground
        />
        {options.length ? (
          Array.isArray(options) &&
          options.length &&
          options.map(type => (
            <MenuItem key={type.options} value={type.options}>
              <StyledCheckbox
                size='small'
                checked={value.indexOf(type.options) > -1}
              />
              <Tooltip title={type.options} placement='right'>
                <ListItemText primary={type.options} color='text.primary' />
              </Tooltip>
            </MenuItem>
          ))
        ) : (
          // For displaying loader without any overlap
          <Box sx={{minHeight: 30}} />
        )}
      </StyledSelect>
      <Box pt={1} px={'4px'}>
        {value.map(val => (
          <StyledChip
            key={val}
            clickable
            label={val}
            onDelete={e => onDelete(e, val)}
            deleteIcon={<ClearIcon style={{color: 'inherit'}} />}
          />
        ))}
      </Box>
    </FormControl>
  );
}

import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Grid, Button} from 'mui-core';
import {getCompleteDate} from 'core/utils';
import AddExperience from './AddExperience';
import {useCheckMobileView} from 'core/hooks';
import SettingsMonths from 'data/settings.json';
import {Edit, Delete} from '@mui/icons-material';
import resumeBuilderData from 'data/resumeBuilder.json';
import {Box, Stack, Divider, IconButton, Typography} from '@mui/material';

const {
  stepsMeta: {
    professionalExperience: {formData: formStaticData = {}},
  },
} = resumeBuilderData || {};

const ProfessionalExperienceList = ({
  data,
  formRef,
  onChange,
  viewOnly,
  getPayload,
}) => {
  const {isMobileView} = useCheckMobileView();
  const {work_exp: work_experience} = data || {};
  const [editIndex, setEditIndex] = useState(-1);
  const [isAdd, setAdd] = useState(
    work_experience?.length === 0 ? true : false,
  );

  useEffect(() => {
    if (work_experience?.length === 0) setAdd(true);
    else setAdd(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatData = (item, id) => {
    const data = {...item};
    data['start_date'] = moment(item['start_date']).format('YYYY-MM-DD');
    if (item['end_date'])
      data['end_date'] = moment(item['end_date']).format('YYYY-MM-DD');
    if (!item['end_date']) data['end_date'] = null;
    return data;
  };

  const workExperienceDateFormat = exp_date => {
    return `${SettingsMonths.months[getCompleteDate(exp_date).month - 1]} ${
      getCompleteDate(exp_date).year
    }`;
  };

  const cancelAddEdit = () => {
    setAdd(false);
    setEditIndex(-1);
  };

  const editData = idx => {
    setAdd(false);
    setEditIndex(idx);
  };

  const onAddExperience = data => {
    if (onChange) {
      onChange(formatData(data), 'add');
      cancelAddEdit();
    }
  };

  const onEdit = item => {
    if (onChange) {
      onChange(formatData(item), 'edit');
      cancelAddEdit();
    }
  };

  const onDeleteWork = exp => {
    if (onChange) {
      onChange(formatData(exp), 'delete');
      cancelAddEdit();
    }
  };

  const getFormattedPayload = (data, action) => {
    return getPayload(formatData(data), action);
  };

  return (
    <Stack overflow='auto' maxHeight='600px' pr={1} sx={{overflowX: 'hidden'}}>
      {work_experience &&
        Array.isArray(work_experience) &&
        work_experience?.length > 0 &&
        work_experience?.map((work_exp, idx) => {
          return editIndex === idx ? (
            <Box key={idx}>
              <AddExperience
                edit={true}
                data={work_exp}
                formRef={formRef}
                onCancel={cancelAddEdit}
                onSubmit={workExp => onEdit(workExp)}
                getPayload={item => getFormattedPayload(item, 'edit')}
              />
              <Divider sx={{my: 2}} />
            </Box>
          ) : (
            <Box key={idx}>
              <Grid py={2} container spacing={2} key={work_exp?.company_name}>
                <Grid item xs={9} sm={9} md={8} lg={9}>
                  <Typography
                    variant='h5'
                    color='text.primary'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    fontSize={{xs: 12, sm: 14, md: 18}}>
                    {work_exp?.job_title}
                  </Typography>
                  <Typography variant='body1' color='text.primary'>
                    {work_exp?.company_name}
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={3} p={0}>
                  {!viewOnly && (
                    <Stack direction='row'>
                      {isMobileView ? (
                        <>
                          <IconButton onClick={() => editData(idx)}>
                            <Edit
                              sx={{fontSize: {xs: 16, sm: 18}}}
                              color='primary'
                            />
                          </IconButton>
                          <IconButton onClick={() => onDeleteWork(work_exp)}>
                            <Delete
                              sx={{fontSize: {xs: 16, sm: 18}}}
                              color='primary'
                            />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <Button size='large' onClick={() => editData(idx)}>
                            Edit
                          </Button>
                          <Button
                            size='large'
                            onClick={() => onDeleteWork(work_exp)}>
                            Delete
                          </Button>
                        </>
                      )}
                    </Stack>
                  )}
                </Grid>
              </Grid>
              <Divider />
            </Box>
          );
        })}
      {!viewOnly && (
        <Box>
          {isAdd && (
            <AddExperience
              formRef={formRef}
              onCancel={cancelAddEdit}
              onSubmit={onAddExperience}
              firstTime={work_experience?.length === 0}
              getPayload={formContent =>
                getFormattedPayload(formContent, 'add')
              }
            />
          )}
          {!isAdd && editIndex === -1 && (
            <Button
              variant='contained'
              sx={{width: '225px', mt: 2}}
              onClick={() => setAdd(true)}
              size={isMobileView ? 'small' : 'large'}>
              {formStaticData.addWorkExperienceBtn}
            </Button>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default ProfessionalExperienceList;

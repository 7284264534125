import * as TYPES from './constants';
import {API} from 'config';

import {
  addPathToCompareRequest,
  addPathToCompareError,
  addPathToCompareSuccess,
  requestPathDetails,
  recievePathDetails,
  requestPathDetailsError,
} from './actions';

import {Services} from 'core/Services';
import {newSortArrayOfObjPropertyBased} from 'core/utils';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PATH_DETAILS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.PATH_DETAILS_SUCCESS:
      let DATA = action.payload;
      if (DATA?.program_map) {
        DATA = Object.assign({}, DATA, {
          terms: [
            ...DATA.terms.map(term => {
              const electiveCourses = newSortArrayOfObjPropertyBased(
                term.electives,
                'sequence',
              ).map(el => {
                return {
                  course_id: el.elective_id,
                  course_type: el.course_type,
                  course_name: el.elective_name,
                  units: el.units,
                };
              });
              term.courses.push(...electiveCourses);
              return term;
            }),
          ],
        });
      }

      return {
        ...state,
        request: false,
        error: false,
        data: DATA,
      };
    case TYPES.PATH_DETAILS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchPathDetails = (
  collegeName,
  pathName,
  awardtypeName,
  id,
  addToCompare,
) => (dispatch, getState) => {
  addToCompare
    ? dispatch(addPathToCompareRequest())
    : dispatch(requestPathDetails());
  try {
    const body = {
      path_id: id,
    };
    if (pathName && pathName !== 'null') {
      body['path_name'] = pathName;
    }
    if (collegeName && collegeName !== 'null') {
      body['college_name'] = collegeName;
    }
    if (awardtypeName && awardtypeName !== 'null') {
      body['award_type_name'] = awardtypeName;
    }
    const endPoint = `${API.srm.pathways}`;
    return services
      .createUpdateRecord(null, endPoint, body, 'POST')
      .then(res => {
        if (res && res.length) {
          addToCompare
            ? dispatch(addPathToCompareSuccess(res[0]))
            : dispatch(recievePathDetails(res[0]));
          return;
        }
        addToCompare
          ? dispatch(addPathToCompareError())
          : dispatch(requestPathDetailsError());
      });
  } catch (err) {
    console.log(err);
    addToCompare
      ? dispatch(addPathToCompareError())
      : dispatch(requestPathDetailsError());
  }
};

export const clearPathDetails = () => (dispatch, getState) =>
  dispatch(recievePathDetails(null));

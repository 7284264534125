import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import {Close} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import {careerUnsubscribeData} from 'data/occupation.json';

const UnsubscribeCareer = props => {
  const {
    unSubscribedCareerDialogClose,
    selectedValue,
    open,
    unSubscribedCareerDialogConfirm,
  } = props;

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullWidth={true}
      onClose={unSubscribedCareerDialogClose}>
      <DialogTitle
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'>
        <Typography
          margin={0}
          variant='h5'
          gutterBottom
          fontSize={24}
          fontWeight={600}
          color='text.primary'
          //fontFamily='fontFamilyBold'
        >
          Unsubscribe Career
        </Typography>
        <IconButton aria-label='close' onClick={unSubscribedCareerDialogClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{height: '100px'}}>
        <Typography
          color='text.primary'
          variant='h6'
          textAlign='center'
          //fontFamily='fontFamilyBold'
          fontSize={14}>
          {careerUnsubscribeData.description}
        </Typography>
        <Typography
          color='#de4279'
          variant='h6'
          textAlign='center'
          fontSize={14}>
          {selectedValue && selectedValue?.occupation_name}&nbsp;?
        </Typography>
      </DialogContent>

      <DialogActions spacing={3} sx={{padding: '8px 32px 32px'}}>
        <Button
          color='midGray'
          variant='contained'
          onClick={unSubscribedCareerDialogClose}>
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() =>
            unSubscribedCareerDialogConfirm(
              selectedValue && selectedValue?.uuid,
            )
          }>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UnsubscribeCareer.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  withdrawDialogConfirm: PropTypes.func,
  unSubscribedCareerDialogClose: PropTypes.func.isRequired,
};
export default UnsubscribeCareer;

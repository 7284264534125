import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CheckStar = props => {
  return (
    <SvgIcon {...props}>
      <path
        d='M21.3504 14.0004L22.6367 12.0507C22.8571 11.7166 22.8571 11.2834 22.6367 10.9493L21.3504 8.99943C21.302 8.92521 21.2782 8.83752 21.2825 8.74882L21.4131 6.40483C21.4352 6.00851 21.2208 5.63665 20.8667 5.4572L18.7821 4.40061C18.7032 4.36085 18.6391 4.29682 18.5994 4.21791L17.5475 2.1345C17.3683 1.77967 16.996 1.56464 16.5992 1.58676L14.2563 1.71734C14.1676 1.72162 14.0799 1.69797 14.0057 1.64944L12.0504 0.362323C11.7165 0.142493 11.2837 0.142695 10.9499 0.362837L8.99943 1.64944C8.92521 1.69797 8.83753 1.72162 8.74903 1.71734L6.40485 1.58669C6.00852 1.56461 5.63665 1.77902 5.45721 2.13308L4.40062 4.21791C4.36085 4.29682 4.29682 4.36085 4.21791 4.40061L2.13456 5.45232C1.7797 5.63146 1.56464 6.00377 1.58676 6.40067L1.71734 8.74373C1.72163 8.83222 1.69797 8.91991 1.64964 8.99433L0.362413 10.9495C0.142534 11.2835 0.142745 11.7164 0.362949 12.0501L1.64964 14.0004C1.69797 14.0748 1.72163 14.1623 1.71734 14.251L1.58676 16.5939C1.56464 16.9907 1.77967 17.363 2.1345 17.5422L4.21791 18.5941C4.29682 18.6338 4.36085 18.6979 4.40062 18.7768L5.45249 20.8601C5.63165 21.215 6.00398 21.43 6.40088 21.4079L8.74373 21.2771H8.76453C8.84589 21.2782 8.92562 21.3016 8.99433 21.345L10.944 22.6314C11.2781 22.8518 11.7113 22.8518 12.0454 22.6314L13.9953 21.345C14.0695 21.2967 14.1572 21.2731 14.2457 21.2771L16.5887 21.4079C16.9856 21.43 17.3579 21.215 17.5371 20.8601L18.589 18.7768C18.6287 18.6979 18.6928 18.6338 18.7717 18.5941L20.8549 17.5422C21.2097 17.363 21.4248 16.9907 21.4026 16.5939L21.2721 14.251C21.2765 14.1621 21.3035 14.0758 21.3504 14.0004Z'
        fill='#98BF0E'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.7899 7.89124C14.9542 7.72754 15.176 7.63541 15.4074 7.63477C15.6387 7.63413 15.861 7.72503 16.0262 7.88781C16.1914 8.0506 16.2863 8.2722 16.2902 8.5047C16.2941 8.73719 16.2069 8.96191 16.0473 9.13026L11.3606 15.0186C11.28 15.1058 11.1828 15.1757 11.0747 15.2243C10.9666 15.2729 10.8499 15.2991 10.7316 15.3013C10.6132 15.3035 10.4956 15.2816 10.3858 15.2371C10.276 15.1926 10.1763 15.1263 10.0926 15.0422L6.98728 11.9198C6.90077 11.8388 6.83138 11.7411 6.78326 11.6325C6.73513 11.524 6.70925 11.4068 6.70717 11.288C6.70508 11.1691 6.72683 11.0511 6.77112 10.9409C6.8154 10.8307 6.88132 10.7306 6.96493 10.6465C7.04854 10.5625 7.14814 10.4962 7.25778 10.4517C7.36742 10.4072 7.48486 10.3854 7.60309 10.3875C7.72132 10.3896 7.83792 10.4156 7.94593 10.4639C8.05394 10.5123 8.15115 10.5821 8.23176 10.669L10.6902 13.1388L14.7676 7.9172C14.7749 7.90806 14.7827 7.89939 14.7911 7.89124H14.7899Z'
        fill='white'
      />
    </SvgIcon>
  );
};

export default CheckStar;

import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {fetchAwardTypes, awardTypesSelector} from 'redux/modules/profile';
import {Divider, Typography, Box, Stack} from '@mui/material';

const AwardsLeadership = ({data, awardTypes, fetchAwardTypes}) => {
  const {awards_leadership_projects_publications: awards} = data || {};

  useEffect(() => {
    (!awardTypes?.data || !awardTypes?.data?.length) &&
      !awardTypes?.request &&
      fetchAwardTypes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getAwardType = id => {
    const {data: awardTypesData = {}} = awardTypes || {};
    if (Array.isArray(awardTypesData)) {
      const idx = awardTypesData.map(i => i.item_type_uuid).indexOf(id);
      return awardTypesData[idx]?.item_type;
    } else {
      return id;
    }
  };
  return (
    <Box px={1}>
      {awards &&
        Array.isArray(awards) &&
        awards?.map((award, idx) => {
          return (
            <>
              <Stack direction={'row'} key={award.additional_info_uuid}>
                <Typography variant='h5' fontSize={18} fontWeight={600}>
                  {`${getAwardType(award.item_type)}: ${award.item_title}`}
                </Typography>
              </Stack>
              <Box ml={1} mt={1}>
                {award.comments &&
                  award.comments.map((info, idx) => {
                    if (info.additional_info)
                      return (
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={1}
                          key={info.additional_info_uuid}>
                          <Box
                            sx={{
                              minWidth: 8,
                              minHeight: 8,
                              backgroundColor: 'secondary.main',
                              borderRadius: 9,
                            }}
                          />
                          <Box>
                            <Typography variant='body1'>
                              {info.additional_info}
                            </Typography>
                          </Box>
                        </Stack>
                      );
                  })}
              </Box>
              {idx !== awards.length - 1 && (
                <Divider sx={{maxWidth: 500, my: 3}} />
              )}
            </>
          );
        })}
    </Box>
  );
};
const mapStateToProps = state => ({
  awardTypes: awardTypesSelector(state),
});
export default connect(mapStateToProps, {
  fetchAwardTypes,
})(AwardsLeadership);

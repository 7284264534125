import * as TYPES from './constants';
import {gapi, loadAuth2} from 'gapi-script';
import {saveTokens, removeTokens} from '../../localStorage';

// LOGIN SCREEEN
export const openLoginScreen = callback => ({
  type: TYPES.LOGIN_SCREEN_OPEN,
  payload: callback,
});

export const closeLoginScreen = json => ({
  type: TYPES.LOGIN_SCREEN_CLOSE,
  payload: json,
});

export const triggerLoginSuccessCallBack = authObj => ({
  type: TYPES.LOGIN_SUCCESS_CALLBACK,
  payload: authObj,
});

export const rememberCredentials = json => ({
  type: TYPES.REMEMBER_CREDENTIAL,
  payload: json,
});

// LOGIN
export const requestLogin = () => ({
  type: TYPES.REQUEST_LOGIN,
});

export const loginSuccess = json => {
  saveTokens(json);
  return {
    type: TYPES.LOGIN_SUCCESS,
    payload: json,
  };
};

export const loginError = json => ({
  type: TYPES.LOGIN_ERROR,
  payload: json,
});

//NEW TOKEN
export const requestNewToken = () => ({
  type: TYPES.REQUEST_NEW_TOKEN,
});

export const receiveNewToken = json => {
  saveTokens(json);
  return {
    type: TYPES.RECEIVE_NEW_TOKEN,
    payload: json,
  };
};

export const errorNewToken = error => ({
  type: TYPES.ERROR_NEW_TOKEN,
  payload: error,
});

//LOGOUT
export const doLogout = () => {
  const auth2 = gapi?.auth2?.getAuthInstance();
  auth2?.signOut().then(() => {
    console.log('User signed out.');
  });
  removeTokens();
  return {
    type: TYPES.LOG_OUT,
  };
};

// FORGOT
export const requestForgotPassword = () => ({
  type: TYPES.REQUEST_FORGOT_PASSOWORD,
});
export const successForgotPassword = payload => ({
  type: TYPES.SUCCESS_FORGOT_PASSOWORD,
  payload,
});
export const errorForgotPassword = err => ({
  type: TYPES.ERROR_FORGOT_PASSOWORD,
  payload: err,
});

// CONFIRM FORGOT
export const requestConfirmForgotPassword = () => ({
  type: TYPES.REQUEST_CONFIRM_FORGOT_PASSOWORD,
});
export const successConfirmForgotPassword = payload => ({
  type: TYPES.SUCCESS_CONFIRM_FORGOT_PASSOWORD,
  payload,
});
export const errorConfirmForgotPassword = err => ({
  type: TYPES.ERROR_CONFIRM_FORGOT_PASSOWORD,
  payload: err,
});

import {Button} from 'mui-core';
import PropTypes from 'prop-types';
import {shadowImage} from 'assets/images';
import {styled} from '@mui/material/styles';
import {Stack, Typography, useTheme, useMediaQuery, Box} from '@mui/material';

const Wrapper = styled(Stack)(({theme}) => ({
  justifyContent: 'center',
  padding: '0px 24px 24px',
  [theme.breakpoints.down('md')]: {
    padding: '0px 24px',
  },
}));

const GradientBackground = styled(Stack)(({theme, isShadowLarge}) => ({
  width: '94%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '10%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundPosition: isShadowLarge ? '5% 115%' : '70% -8px',
  paddingBottom: isShadowLarge && '50px',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${shadowImage})`,
  [theme.breakpoints.only('xs')]: {
    backgroundImage: 'none',
  },
}));

const Image = styled('img')(({theme, isShadowLarge}) => ({
  width: 480,
  height: 480,
  objectFit: 'cover',
  borderRadius: '50%',
  marginTop: '58px !important',
  boxShadow: isShadowLarge && '1px 5px 60px #c7b3fc',
  [theme.breakpoints.down('lg')]: {
    width: 380,
    height: 380,
    marginTop: '32px !important',
  },
  [theme.breakpoints.down('sm')]: {
    width: 280,
    height: 280,
    marginTop: '24px !important',
  },
}));

const Banner = ({
  children,
  title = '',
  bannerImage,
  buttonLabel = '',
  imageFirst = false,
  buttonEndIcon = null,
  onButtonClick = null,
  isShadowLarge = false,
  titleVariant = 'pagetitle',
}) => {
  let restProps = {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (onButtonClick?.href) {
    restProps = {...onButtonClick};
  } else {
    restProps.onClick = onButtonClick;
  }

  return (
    <Wrapper
      alignItems='center'
      spacing={{sm: 2, md: 6}}
      direction={
        isMobile ? 'column-reverse' : imageFirst ? 'row-reverse' : 'row'
      }>
      <Stack spacing={{xs: 2, sm: 3, md: 6}}>
        <Box sx={{mt: {xs: 2, sm: 3, md: 9}}}>
          <Typography
            color='text.primary'
            variant={titleVariant}
            dangerouslySetInnerHTML={{__html: title}}
          />
        </Box>
        {children}
        {buttonLabel && onButtonClick && (
          <Button
            {...restProps}
            variant='contained'
            endIcon={buttonEndIcon}
            size={isMobile ? 'small' : 'large'}
            sx={{py: isMobile ? 1 : 2, width: isMobile ? '100%' : '80%'}}>
            {buttonLabel}
          </Button>
        )}
      </Stack>
      {bannerImage && (
        <GradientBackground isShadowLarge={isShadowLarge}>
          <Image
            src={bannerImage}
            alt='resource banner'
            isShadowLarge={isShadowLarge}
          />
        </GradientBackground>
      )}
    </Wrapper>
  );
};

export default Banner;

Banner.propTypes = {
  title: PropTypes.string,
  imageFirst: PropTypes.bool,
  children: PropTypes.element,
  onButtonClick: PropTypes.func,
  isShadowLarge: PropTypes.bool,
  bannerImage: PropTypes.string,
  buttonLabel: PropTypes.string,
  titleVariant: PropTypes.string,
  buttonEndIcon: PropTypes.element,
};

const CustomTabSwitch = ({dataTabs = [], currentTab, onChange}) => (
  <div className='a-tab'>
    {dataTabs?.map(({key, value}) => {
      return (
        <div
          key={key}
          onClick={() => onChange(key)}
          className={`tb-itm ${currentTab === key ? 'active' : ''}`}
          data-cy={`${key}-tab`}>
          {value}
        </div>
      );
    })}
  </div>
);

export default CustomTabSwitch;

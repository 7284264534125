import './wdyr';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import {createBrowserHistory} from 'history';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

import * as Sentry from '@sentry/react';
import {Integrations as TracingIntegrations} from '@sentry/tracing';
import {disableReactDevTools} from '@fvilers/disable-react-devtools';

import configureStore from './redux/configureStore';
import {loadInitialState} from 'redux/localStorage';
import {GTAG_ID, MUI_X_PRO_KEY, ENV} from 'config';

import Root from './Root';
import {LicenseInfo} from '@mui/x-license-pro';
import reportWebVitals from './reportWebVitals';
// import './index.less';

// MUIX LICENCE
LicenseInfo.setLicenseKey(MUI_X_PRO_KEY);

//For GA_Tracking
const tagManagerArgs = {
  gtmId: 'GTM-WCZZ4J6P',
};

const initAnalytics = () => {
  const Window = window || {};
  const {hostname = ''} = Window.location || {};
  if (ENV === 'prod' && hostname !== 'localhost') {
    TagManager.initialize(tagManagerArgs);
    const analytics = Analytics({
      app: 'awesome-app',
      // plugins: [
      //   googleAnalytics({
      //     measurementIds: ['G-TV5RS9WCVH'],
      //   }),
      // ],
    });
    /* Track a page view */
    analytics.page();
  }
};

initAnalytics();

const persistedState = loadInitialState() || window.__INITIAL_STATE__;

const store = configureStore(persistedState);

const rootEl = document.querySelector('#root');
const history = createBrowserHistory();

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

ReactDOM.render(<Root store={store} history={history} />, rootEl);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== 'production') {
  reportWebVitals(console.log);
}

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  release: 'gopathways_gps@' + process.env.REACT_APP_VERSION,
  dsn: process.env.REACT_APP_SENTRY_DNS,
  // maxBreadcrumbs: 10,
  // beforeBreadcrumb(breadcrumb, hint) {
  //   if (breadcrumb.category === 'console') {
  //     return null;
  //   }
  //   return breadcrumb;
  // },
  integrations: [
    new TracingIntegrations.BrowserTracing({
      // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  normalizeDepth: 10, // Or however deep you want your state context to be.
});

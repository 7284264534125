import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CloudIcon = props => {
  return (
    <SvgIcon
      {...props}
      fontSize='inherit'
      viewBox='0 0 46 46'
      style={{fontSize: props.fontSize || '1em'}}>
      <g id='Cloud'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='46'
          height='46'
          viewBox='0 0 46 46'
          fill='none'>
          <path
            d='M12.457 36.4154C7.69358 36.4154 3.83203 32.5538 3.83203 27.7904C3.83203 23.2985 7.26576 19.6087 11.6515 19.2025C12.5487 13.7453 17.2875 9.58203 22.9987 9.58203C28.7099 9.58203 33.4487 13.7453 34.3459 19.2025C38.7316 19.6087 42.1654 23.2985 42.1654 27.7904C42.1654 32.5538 38.3038 36.4154 33.5404 36.4154C25.1265 36.4154 19.8234 36.4154 12.457 36.4154Z'
            stroke='url(#paint0_linear_31901_105914)'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <defs>
            <linearGradient
              id='paint0_linear_31901_105914'
              x1='42.1654'
              y1='22.9987'
              x2='3.83203'
              y2='22.9987'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color={props.startColor || '#000'} />
              <stop offset='0.76' stop-color={props.endColor || '#000'} />
            </linearGradient>
          </defs>
        </svg>
      </g>
    </SvgIcon>
  );
};

export default CloudIcon;

{
  /* <svg
  xmlns='http://www.w3.org/2000/svg'
  width='46'
  height='46'
  viewBox='0 0 46 46'
  fill='none'>
  <defs>
    <linearGradient
      id='paint0_linear_31901_105914'
      x1='42.1654'
      y1='22.9987'
      x2='3.83203'
      y2='22.9987'
      gradientUnits='userSpaceOnUse'>
      <stop stop-color='#5E92F4' />
      <stop offset='0.76' stop-color='#A290F6' />
    </linearGradient>
  </defs>
</svg>; */
}

import React, {useState, useEffect} from 'react';
import {
  Box,
  Stack,
  Divider,
  useTheme,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import {capitalize} from 'lodash';
import {Grid, Button} from 'mui-core';
import {Edit, Delete} from '@mui/icons-material';
import resumeBuilderData from 'data/resumeBuilder.json';
import AddInternshipAndApprenticeship from './AddInternshipAndApprenticeship';

const {
  stepsMeta: {
    internshipAndApprenticeship: {formData = {}},
  },
} = resumeBuilderData || {};
const {editTxt, deleteTxt, AddInternshipApprenticeshipTxt} = formData || {};

const InternshipAndApprenticeshipList = ({
  data,
  formRef,
  onChange,
  viewOnly,
  getPayload,
}) => {
  const theme = useTheme();
  const {credentials = []} = data || {};
  const [editIndex, setEditIndex] = useState(-1);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const filteredInternshipApprenticeshipList =
    credentials?.filter(
      ele =>
        ele.credential_type === 'internship' ||
        ele.credential_type === 'apprenticeship',
    ) || [];
  const [isAdd, setAdd] = useState(
    filteredInternshipApprenticeshipList?.length === 0 ? true : false,
  );

  useEffect(() => {
    if (filteredInternshipApprenticeshipList?.length === 0) setAdd(true);
    else setAdd(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatData = item => {
    const data = {...item};
    if (item['school_end_date'])
      data['school_end_date'] = moment(item['school_end_date']).format(
        'YYYY-MM-DD',
      );
    else data['school_end_date'] = null;
    if (item['school_start_date'])
      data['school_start_date'] = moment(item['school_start_date']).format(
        'YYYY-MM-DD',
      );
    else data['school_start_date'] = null;
    return data;
  };

  const cancelAddEdit = () => {
    setAdd(false);
    setEditIndex(-1);
  };

  const editData = idx => {
    setAdd(false);
    setEditIndex(idx);
  };

  const onFormContentUpdate = (data, action) => {
    if (onChange) {
      onChange(formatData(data), action);
      cancelAddEdit();
    }
  };

  const getFormattedPayload = (data, action) => {
    return getPayload(formatData(data), action);
  };

  return (
    <Stack overflow='auto' maxHeight='610px' pr={1} sx={{overflowX: 'hidden'}}>
      {filteredInternshipApprenticeshipList &&
        Array.isArray(filteredInternshipApprenticeshipList) &&
        filteredInternshipApprenticeshipList?.length > 0 &&
        filteredInternshipApprenticeshipList?.map(
          (internshipApprenticeship, idx) => {
            return editIndex === idx ? (
              <Box key={idx}>
                <AddInternshipAndApprenticeship
                  edit={true}
                  formRef={formRef}
                  onCancel={cancelAddEdit}
                  data={internshipApprenticeship}
                  onSubmit={item => onFormContentUpdate(item, 'edit')}
                  getPayload={item => getFormattedPayload(item, 'edit')}
                />
                <Divider sx={{my: 2}} />
              </Box>
            ) : (
              <Box key={idx}>
                <Grid
                  py={2}
                  container
                  spacing={2}
                  key={internshipApprenticeship?.title}>
                  <Grid item xs={9} sm={9} md={8} lg={9}>
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <Typography
                        variant='h5'
                        color='text.primary'
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        fontSize={{xs: 12, sm: 14, md: 18}}>
                        {internshipApprenticeship?.credential_type
                          ? `${capitalize(
                              internshipApprenticeship?.credential_type,
                            )} - `
                          : '-'}
                      </Typography>
                      <Typography variant='h5' color='text.primary'>
                        {internshipApprenticeship?.program_name || '-'}
                      </Typography>
                    </Stack>
                    <Typography variant='body1' color='text.primary'>
                      {internshipApprenticeship?.org_name || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={4} lg={3} p={0}>
                    {!viewOnly && (
                      <Stack direction='row'>
                        {isMobile ? (
                          <>
                            <IconButton onClick={() => editData(idx)}>
                              <Edit
                                color='primary'
                                sx={{fontSize: {xs: 16, sm: 18}}}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                onFormContentUpdate(
                                  internshipApprenticeship,
                                  'delete',
                                )
                              }>
                              <Delete
                                color='primary'
                                sx={{fontSize: {xs: 16, sm: 18}}}
                              />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <Button size='large' onClick={() => editData(idx)}>
                              {editTxt}
                            </Button>
                            <Button
                              size='large'
                              onClick={() =>
                                onFormContentUpdate(
                                  internshipApprenticeship,
                                  'delete',
                                )
                              }>
                              {deleteTxt}
                            </Button>
                          </>
                        )}
                      </Stack>
                    )}
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            );
          },
        )}
      {!viewOnly && (
        <Box>
          {isAdd && (
            <AddInternshipAndApprenticeship
              formRef={formRef}
              onCancel={cancelAddEdit}
              firstTime={credentials?.length === 0}
              onSubmit={formContent => onFormContentUpdate(formContent, 'add')}
              getPayload={formContent =>
                getFormattedPayload(formContent, 'add')
              }
            />
          )}
          {!isAdd && editIndex === -1 && (
            <Button
              variant='contained'
              onClick={() => setAdd(true)}
              sx={{width: 'fit-content', mt: 2}}
              size={isMobile ? 'small' : 'large'}>
              {AddInternshipApprenticeshipTxt}
            </Button>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default InternshipAndApprenticeshipList;

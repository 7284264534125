import * as TYPES from './constants';
import {API} from 'config';

import {
  requestRecommendedPlan,
  recieveRecommendedPlan,
  recieveActivePaths,
  requestRecommendedPlanError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.RECOMMENDED_PLAN_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECOMMENDED_PLAN_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLEAR_RECOMMENDED_PLAN:
      return {
        ...state,
        request: false,
        error: false,
        data: null,
      };
    case TYPES.RECOMMENDED_PLAN_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchRecommendedPlan = () => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
      pathways: {
        activePaths: {data},
      },
    } = getState();
    let endPoint = `${API.gps.student_onboard}`;

    if (token) {
      dispatch(requestRecommendedPlan());
      let activePath = data;
      if (!activePath) {
        activePath = await services.fetchRecords(endPoint, token);
        dispatch(recieveActivePaths(activePath));
      }
      if (activePath && activePath.length) {
        endPoint = `${API.srm.pathway_planner}?recommended=true&path_uuid=${activePath[0].path_uuid}&student_uuid=${activePath[0].student_uuid}`;
        const RecommendedPlan = await services.fetchRecords(endPoint, token);
        dispatch(recieveRecommendedPlan(RecommendedPlan[0]));
        return;
      }
      dispatch(requestRecommendedPlanError({error: 'something went wrong'}));
    }
  } catch (err) {
    console.log(err);
    dispatch(requestRecommendedPlanError(err));
  }
};

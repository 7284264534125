import {useState} from 'react';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {Button, DialogBox} from 'mui-core';
import {useHistory} from 'react-router-dom';
import ResumePreview from '../ResumePreview';
import {Stack, Typography} from '@mui/material';
import {deleteSavedResume} from 'redux/modules/resumes';
import {
  cancelText,
  dialogHeading,
  editResumeText,
  deleteResumeText,
  dialogDescription,
  deleteErrorMessage,
  resumePreviewLabel,
  deleteSuccessMessage,
} from 'data/resume.json';

const MenuOptions = ({resumeContent}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [deleteResume, setDeleteResume] = useState({});
  const [previewResume, setPreviewResume] = useState({});

  const onEditResume = resumeId => {
    history.push(`/resume-builder?id=${resumeId}`);
  };

  const onDeleteResume = resumeId => {
    setDeleteResume({});
    dispatch(
      deleteSavedResume(resumeId, res => {
        if (res.Success) {
          enqueueSnackbar(deleteSuccessMessage, {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        } else {
          enqueueSnackbar(deleteErrorMessage, {
            variant: 'error',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        }
      }),
    );
  };

  const buttonList = [
    {
      label: editResumeText,
      action: event => onEditResume(event?.resume_uuid),
    },
    {
      label: resumePreviewLabel,
      action: event => setPreviewResume(event),
    },
    {
      label: deleteResumeText,
      action: event => setDeleteResume(event),
    },
  ];

  return (
    <>
      <Stack
        gap={1}
        alignItems='center'
        justifyContent='center'
        direction={{sm: 'column', md: 'row', lg: 'column'}}>
        {buttonList.map(buttonContent => {
          return (
            <Button
              variant='outlined'
              key={buttonContent?.label}
              onClick={() => buttonContent?.action(resumeContent)}
              sx={{
                width: {xs: '250px', sm: '200px', md: '250px'},
              }}>
              {buttonContent?.label}
            </Button>
          );
        })}
      </Stack>

      {deleteResume?.resume_name && (
        <DialogBox
          openDialog={deleteResume.resume_name}
          closeDialog={() => setDeleteResume({})}
          actions={
            <>
              <Button
                variant='outlined'
                onClick={() => setDeleteResume({})}
                sx={{minWidth: {xs: '80px', md: '168px'}}}>
                {cancelText}
              </Button>
              <Button
                variant='contained'
                sx={{minWidth: {xs: '80px', md: '168px'}}}
                onClick={() => onDeleteResume(deleteResume.resume_uuid)}>
                {deleteResumeText}
              </Button>
            </>
          }>
          <Stack spacing={1}>
            <Typography textAlign='center' variant='h4' color='text.primary'>
              {`${dialogHeading} ${deleteResume.resume_name}?`}
            </Typography>
            <Typography textAlign='center' variant='body1' color='text.primary'>
              {dialogDescription}
            </Typography>
          </Stack>
        </DialogBox>
      )}

      {previewResume?.resume_uuid && (
        <ResumePreview
          data={previewResume}
          onOpenModal={previewResume?.resume_uuid}
          onCloseModal={() => setPreviewResume({})}
        />
      )}
    </>
  );
};

export default MenuOptions;

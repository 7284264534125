import {Link} from 'react-router-dom';
import {Breadcrumb} from 'antd';
import {breadcrumbArrow} from 'assets/images';
import './style.less';

/**
 * AppBreadcrumb Component is a functional component
 * @component
 * @alias AppBreadcrumb
 * @property {array} dataList Takes a list of objects
 * @property {object} ListItem ListItem takes following properties
 * @property {string} ListItem.name name is required
 * @property {string} [ListItem.path] path is optional
 * @property {function} [ListItem.goto] goto is optional
 * @component
 * @example <AppBreadcrumb dataList={data} />
 */

const Arrow = () => '/';
const AppBreadcrumb = ({dataList}) => {
  return (
    <div className='breadcrumb_container mx-3' data-cy='breadcrumb'>
      <Breadcrumb className='py-1' separator={<Arrow />}>
        <Breadcrumb.Item>
          <Link to='/'>Home</Link>
        </Breadcrumb.Item>
        {dataList &&
          dataList.map((item, idx) => {
            if (item.name && item.path) {
              return (
                <Breadcrumb.Item key={idx}>
                  <Link to={item.path}>{item.name}</Link>
                </Breadcrumb.Item>
              );
            }
            if (item.name && item.goto) {
              return (
                <Breadcrumb.Item key={idx}>
                  <a onClick={item.goto}>{item.name}</a>
                </Breadcrumb.Item>
              );
            }
            if (item.name && !item.goto && !item.path) {
              return <Breadcrumb.Item key={idx}>{item.name}</Breadcrumb.Item>;
            }
          })}
      </Breadcrumb>
    </div>
  );
};

export default AppBreadcrumb;

import React from 'react';

import {Image, Text, View, StyleSheet} from '@react-pdf/renderer';

// import { pdflogo } from 'assets/images';
import {getLogo} from 'core/utils';

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'row',
    backgroundColor: '#FBFBFB',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#DDDDDD',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#DDDDDD',
    height: '40px',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  footerTxt: {
    color: '#000000',
    //fontFamily: 'Source Sans Pro',
    fontSize: 12,
    marginRight: 2,
  },
  footerImg: {
    width: 75,
    height: 15,
    padding: 1,
  },
});

export default () => (
  <View style={styles.footer} wrap={false}>
    <Text style={styles.footerTxt}>Powered By </Text>
    <Image
      source={{
        uri: `${getLogo(
          'goeducate_footer_logo_shs8hr',
          'png',
        )}?noCache=${Math.random().toString()}`,
        method: 'GET',
        header: {
          'Access-Control-Allow-Origin': '*',
          'Cache-Control': 'no-cache',
        },
      }}
      // src={pdflogo + '?noCache=' + Math.random().toString()}
      // source={{
      //   header: {
      //     'Access-Control-Allow-Origin': '*',
      //   },
      // }}
      style={styles.footerImg}
    />
  </View>
);

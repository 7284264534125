import {Grid} from 'mui-core';
import {getFilesAndTotalSize} from 'core/utils';
import DocUploader from 'app/modules/dashboard/components/DocUploader';

const ResumeCoverLetter = props => {
  const {
    profileData: {data: student_profile, request, error},
  } = props;

  const {data: document_files, total_size_consumed} = getFilesAndTotalSize(
    student_profile,
    'related_document',
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DocUploader
          viewOnly
          profileData={{
            data: document_files,
            total_size_consumed,
            request,
            error,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ResumeCoverLetter;

import {useEffect, useState} from 'react';
import {useUser} from 'core/hooks';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {useDispatch, useSelector} from 'react-redux';
import {Button, ContentBox, RequestErrorLoader} from 'mui-core';
import {
  getUserTypeList,
  fetchUserTypelist,
  updateUserProfile,
} from 'redux/modules/profile';
import {
  Box,
  Select,
  MenuItem,
  useTheme,
  Typography,
  useMediaQuery,
} from '@mui/material';
// data
import SettingsJSON from 'data/dashboard-settings.json';

const {
  personalDetails: {successMsg, saveBtn},
  userType: {title, errorMsg, selectlabel},
} = SettingsJSON;

const UserType = ({
  onCloseHandler,
  dashboard = true,
  btnTxt = saveBtn,
  headLine = title,
  showLabel = true,
  userTypeContentSx,
  showHeading = true,
  label = selectlabel,
  btnAlignment = 'left',
  btnVariant = 'outlined',
  dropdownSize = 'medium',
}) => {
  // hooks
  const form = useForm();
  const {reset} = form;
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {data: userData, request: userDataRequest} = useUser();
  const {data: userTypeList = []} = useSelector(getUserTypeList) || {};
  const {user_type} = userData || {};
  const {handleSubmit} = form;
  const selectedUserType = userTypeList?.find(
    userType => userType.user_type_uuid === user_type,
  );

  const defaultUserTypeValue = selectedUserType
    ? selectedUserType.user_type_uuid
    : '';

  useEffect(() => {
    (!userTypeList ||
      (Array.isArray(userTypeList) && userTypeList?.length === 0)) &&
      dispatch(fetchUserTypelist());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    reset({user_type: user_type}); // Reset form with user type
  }, [reset, user_type]);

  const onSubmit = ({user_type = ''}) => {
    const formData = {user_type};
    dispatch(
      updateUserProfile(formData, success => {
        if (success) {
          if (onCloseHandler) {
            onCloseHandler(true);
          }
          enqueueSnackbar(successMsg, {variant: 'success'});
        } else enqueueSnackbar(errorMsg, {variant: 'error'});
      }),
    );
  };

  return (
    <ContentBox elevation={0} sx={{p: 4, ...userTypeContentSx}}>
      <RequestErrorLoader
        hideEmpty
        fullScreen
        body={{request: userDataRequest}}
      />
      {showHeading && (
        <Box mb={4}>
          <Typography variant='h4' color='text.primary'>
            {headLine}
          </Typography>
        </Box>
      )}
      {createField('user_type', {
        form,
        size: dropdownSize,
        label: showLabel ? label : '',
        defaultValue: defaultUserTypeValue,
        rules: {
          required: [true, errorMsg || ''],
        },
      })(
        <Select
          displayEmpty
          id='user_type'
          renderValue={selected => {
            if (!dashboard) {
              if (!selected) {
                return (
                  <Typography variant='Label-md' component='p'>
                    *Enter the user type that best describes you.
                  </Typography>
                );
              } else {
                return userTypeList?.find(
                  item => item?.user_type_uuid === selected,
                )?.user_type;
              }
            } else {
              return userTypeList?.find(
                item => item?.user_type_uuid === selected,
              )?.user_type;
            }
          }}
          label={showLabel ? label : ''}
          sx={{width: {xs: 300, md: 530}}}>
          {!dashboard && (
            <MenuItem disabled value=''>
              <Typography variant='Label-md' component='p'>
                Enter the user type that best describes you.
              </Typography>
            </MenuItem>
          )}
          {Array.isArray(userTypeList) &&
            userTypeList.map(item => (
              <MenuItem key={item.user_type_uuid} value={item.user_type_uuid}>
                {item.user_type}
              </MenuItem>
            ))}
        </Select>,
      )}
      <Box textAlign={btnAlignment} sx={{mt: 4}}>
        <Button
          variant={btnVariant}
          onClick={handleSubmit(onSubmit)}
          size={isMobile ? 'medium' : 'large'}>
          {btnTxt}
        </Button>
      </Box>
    </ContentBox>
  );
};

export default UserType;

import * as TYPES from './constants';
export const requestLocalSalary = () => ({
  type: TYPES.REQUEST_LOCAL_SALARY,
});
export const recieveLocalSalary = json => ({
  type: TYPES.RECIEVE_LOCAL_SALARY,
  payload: json,
});
export const requestLocalSalaryError = err => ({
  type: TYPES.REQUEST_LOCAL_SALARY_ERROR,
  err,
});

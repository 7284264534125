import React, {useState} from 'react';
import {useEffect, useMemo} from 'react';
import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {useHistory, Link} from 'react-router-dom';
import {InterestRating} from 'data/occupation.json';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {Check} from '@mui/icons-material';
import {Alert, Snackbar} from '@mui/material';
import {
  Accordion,
  BarChart,
  RequestErrorLoader,
  NoContentNavigator,
  Button,
} from 'mui-core';
import DeleteBar from './DeleteBar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {getInstanceVersion} from 'redux/modules/instance';

const sortInterest = interestData =>
  interestData &&
  Array.isArray(interestData) &&
  interestData?.sort((a, b) => b.score - a.score);
const ExploreBtn = styled(Box)(({theme}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '& .exploreBtn:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}));
const CareerTaskSurvey = ({
  occupationInterest,
  fetchOccupationInterests,
  deleteOccupationInterest,
}) => {
  const history = useHistory();
  const {data: interestData, request, deleteRequest} = occupationInterest || {};
  const data = useMemo(() => sortInterest(interestData), [interestData]);
  const {noResults} = InterestRating;
  const [removeInterestRating, setremoveInterestRating] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [snackBarSuccessMsg, setSnackBarSuccessMsg] = useState(null);
  const [baruuId, setBaruuId] = useState(null);
  const {dashboard: {my_careers: {career_task_survey = false} = {}} = {}} =
    useSelector(getInstanceVersion) || {};

  useEffect(() => {
    fetchOccupationInterests();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onLabelClick = label => {
    if (label) {
      history.push(`/careers/${encodeURIComponent(label)}`);
    }
  };
  const onDeleteClick = (idx, lbl) => {
    setSelectedValue(lbl, idx);
    setremoveInterestRating(true);
    if (idx !== null && data[idx] && data[idx].uuid) {
      setBaruuId(data[idx].uuid);
    }
  };
  const barDialogClose = () => {
    setremoveInterestRating(false);
  };
  const DeleteOccupationInterest1 = async () => {
    if (baruuId) {
      await deleteOccupationInterest(baruuId);
      setSnackBarSuccessMsg('Removed Successfully');
      setremoveInterestRating(false);
    }
    fetchOccupationInterests();
  };
  return (
    <>
      <Accordion defaultOpen outlined={false} header='Career Tasks Survey'>
        <RequestErrorLoader
          hideBackground
          body={{data, request: request || deleteRequest}}
          overrideNoDataContainer={
            <NoContentNavigator
              pathTo='/careers'
              message={
                career_task_survey
                  ? 'This feature is currently inactive.'
                  : noResults.title
              }
              label={career_task_survey ? '' : 'EXPLORE CAREERS'}
              subHeading={
                career_task_survey
                  ? 'Reach out to your institution to gain insights on how to activate this feature and enhance your user experience.'
                  : noResults.description
              }
            />
          }>
          <ExploreBtn>
            <Box>
              <Link to='/careers'>
                <Button variant='outlined' className='exploreBtn'>
                  Explore Careers
                </Button>
              </Link>
            </Box>
          </ExploreBtn>
          <BarChart
            data={data?.map(i => (i && i.score ? i.score : 0))}
            labels={data?.map(i =>
              i && i.occupation_details && i.occupation_details.occupation_name
                ? i.occupation_details.occupation_name
                : '',
            )}
            onLabelClick={onLabelClick}
            onActionClick={onDeleteClick}
            actionComponent={
              <IconButton aria-label='close'>
                <DeleteIcon />
              </IconButton>
            }
          />
          {removeInterestRating === true && (
            <DeleteBar
              barDialogClose={barDialogClose}
              barDialogConfirm={DeleteOccupationInterest1}
              selectedValue={selectedValue && selectedValue}
              open={removeInterestRating}
            />
          )}
        </RequestErrorLoader>
      </Accordion>
      {snackBarSuccessMsg && (
        <Snackbar
          autoHideDuration={5000}
          open={snackBarSuccessMsg}
          onClose={() => setSnackBarSuccessMsg('')}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
          <Alert
            variant='filled'
            severity='success'
            icon={<Check fontSize='inherit' />}
            sx={{width: '100%', color: 'white'}}>
            {snackBarSuccessMsg}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
export default CareerTaskSurvey;

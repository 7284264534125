// hooks
import {useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import {
  TabPage,
  StaticBanner,
  AppBreadcrumb,
  ErrorBoundary,
  QualifyingPrograms,
  RequestErrorLoader,
} from 'mui-core';
import {About} from './subModules';
import {getGoBanner} from 'mui-core/utils';
import {Box, Container} from '@mui/material';
// redux
import {
  getGoSurveyResultDetails,
  fetchGoSurveyResultDetails,
} from 'redux/modules/survey';
import {queryStringParse} from 'core/utils';
import {NewJobs, Apprenticeships} from 'app/modules/opportunities';
// json
import {goSurveyResults} from 'data/goSurvey.json';

const tabKeys = ['about', 'programs', 'jobs', 'apprenticeships'];
const {jobsTab, tabsTitles, programsTab, apprenticeshipsTab} = goSurveyResults;

const GoSurveyDetails = () => {
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const {career_name = '', cip_code = ''} = useParams();
  // selectors
  const goSurveyResultDetails = useSelector(getGoSurveyResultDetails);
  // destructuring
  const {request: goSurveyResultDetailsRequest, data = {}} =
    goSurveyResultDetails || {};
  const {tagline = '', occupation_details = {}} = data?.description || {};
  const {cip4_image_cloudinary = ''} = occupation_details || {};

  useEffect(() => {
    if (!data?.description || Object.keys(data?.description)?.length === 0) {
      dispatch(fetchGoSurveyResultDetails(career_name, cip_code));
    }
  }, [career_name]);

  const {
    location: {search},
  } = history;
  const {active: activeView = 'about'} = queryStringParse(search) || {};

  const tabs = useMemo(() => {
    let tabsList = [];

    tabsList.push({
      key: tabKeys[0],
      title: tabsTitles[0],
      children: (
        <Container maxWidth='xl' sx={{pt: 2}}>
          <About goSurveyResultDetails={goSurveyResultDetails} />
        </Container>
      ),
    });
    tabsList.push({
      key: tabKeys[1],
      title: tabsTitles[1],
      children: (
        <QualifyingPrograms
          cipCode={cip_code}
          applyRouting={false}
          title={programsTab.title}
          noResults={programsTab.noResults}
        />
      ),
    });

    tabsList.push({
      key: tabKeys[2],
      title: tabsTitles[2],
      children: (
        <Box pt={4}>
          <NewJobs
            tabPage={true}
            title={jobsTab.title}
            search_query={decodeURIComponent(career_name || '')}
          />
        </Box>
      ),
    });

    tabsList.push({
      key: tabKeys[3],
      title: tabsTitles[3],
      children: (
        <Box pt={4}>
          <Apprenticeships tabPage={true} title={apprenticeshipsTab.title} />
        </Box>
      ),
    });

    return tabsList;
  }, [data?.description, activeTab]);

  useEffect(() => {
    if (tabs.length) {
      const idx = tabs.findIndex(x => x.key === activeView);
      if (activeView && activeTab !== idx) {
        if (idx !== -1) {
          setActiveTab(idx);
        }
      }
    }
  }, [activeView, tabs]);

  const onTabChange = (e, index) => {
    const tabName = e?.target?.textContent?.toString()?.toLowerCase();
    const idx = tabs.findIndex(x => x?.key === tabName);
    const url = `/go-survey/${career_name}/${cip_code}?active=${tabs[idx].key}`;
    history.push(url);
    const upperCase =
      tabKeys[idx].charAt(0).toUpperCase() + tabKeys[idx].slice(1);
    const newIndex = tabs.findIndex(tab => tab.title == upperCase);
    if (tabKeys[idx] && activeTab !== newIndex) {
      if (newIndex !== -1) {
        setActiveTab(newIndex);
      }
    }
  };

  return (
    <ErrorBoundary nameOfComponent='home-gosurvey-gosurveyResults-gosurveyResultDetails'>
      <RequestErrorLoader
        fullScreen
        hideBackground
        title='Please wait while we are fetching the career details'
        body={{
          data: data,
          request: goSurveyResultDetailsRequest,
        }}>
        <Container maxWidth='xl'>
          <AppBreadcrumb
            dataList={[
              {
                name: 'Go Survey',
                path: '/go-survey',
              },
              {
                name: decodeURIComponent(career_name || ''),
              },
            ]}
          />
        </Container>
        <StaticBanner
          desc={tagline}
          titleVariant='title55'
          descVariant='pagedesc'
          title={career_name || ''}
          url={getGoBanner(cip4_image_cloudinary)}
        />
        <Box sx={{mt: 4}}>
          <TabPage
            list={tabs}
            active={activeTab}
            onChange={(e, index) => onTabChange(e, index)}
          />
        </Box>
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default GoSurveyDetails;

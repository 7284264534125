import * as TYPES from './constants';
import {API} from 'config';

import {
  requestProfileUpdate,
  recieveProfileUpdate,
  recieveSeekingData,
  profileUpdateError,
  requestProfileData,
  recieveProfileData,
  profileDataError,
  recieveUserData,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {
  request: false,
  data: null,
  error: false,
  updating: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PROFILE_DATA_LOAD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.PROFILE_DATA_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.PROFILE_DATA_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.PROFILE_UPDATE_LOAD:
      return {
        ...state,
        updating: true,
      };
    case TYPES.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case TYPES.PROFILE_UPDATE_ERROR:
      return {
        ...state,
        updating: false,
      };
    default:
      return state;
  }
};

export const fetchProfileData = (file, cb) => (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      dispatch(requestProfileData());
      return services
        .fetchRecords(`${API.gps.student_profile}?isAuth=true`, token)
        .then(res => {
          const data = res && res[0];
          cb && cb((file && file.name) || '');
          dispatch(recieveProfileData(data));

          return res;
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(profileDataError(err));
  }
};

export const fetchSharedProfileData = userName => (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    dispatch(requestProfileData());
    return services
      .fetchRecords(
        `${API.gps.share_student_profile}?username=${userName}&isAuth=false`,
      )
      .then(res => {
        const data = res && res[0];
        dispatch(recieveProfileData(data));
        dispatch(recieveUserData(data?.student_details));
        return res;
      });
  } catch (err) {
    console.log(err);
    dispatch(profileDataError(err));
  }
};

export const updateProfile = (endPoint, method, data) => (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      dispatch(requestProfileUpdate());
      return services
        .createUpdateRecord(token, endPoint, data, method)
        .then(async res => {
          await dispatch(fetchProfileData());
          await dispatch(recieveProfileUpdate(res));
          return res;
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(profileUpdateError(err));
  }
};

export const uploadUserDocs = ({file, key}, onSuccess) => (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {user, token = null} = {}},
      profile: {
        profileData: {data},
      },
    } = getState();
    if (token && data) {
      dispatch(requestProfileData());
      const endPoint = `${API.gps.student_profile}/${data.student_profile_uuid}/`;
      return services.uploadFile(token, endPoint, file, key).then(res => {
        if (res) {
          // onSuccess(file.name);
          dispatch(fetchProfileData(file, onSuccess));
        }
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteUserDocs = (docId, onDeleteSuccess) => (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {user, token = null} = {}},
      profile: {
        profileData: {data},
      },
    } = getState();
    if (token && data) {
      dispatch(requestProfileData());
      const endPoint = `${API.gps.student_profile}/${docId}/`;
      return services.deleteRecord(token, endPoint).then(res => {
        if (res) {
          dispatch(fetchProfileData(null, onDeleteSuccess));
        }
      });
    }
  } catch (err) {
    console.log(err);
  }
};

import {API} from 'config';
import * as TYPES from './constants';
import {Services} from 'core/Services';
import {
  requestUpdate,
  recieveUpdate,
  requestUpdateError,
  requestResume,
  requestResumeError,
  initiateResumeAction,
} from './actions';

const services = new Services();
const initialState = {
  request: false,
  error: false,
  data: {
    city: '',
    state: '',
    summary: '',
    zipcode: '',
    job_title: '',
    status: false,
    last_name: '',
    first_name: '',
    resume_name: '',
    resume_uuid: '',
    phone_number: '',
    street_address: '',
    resume_strength: 0,
    skills: [],
    work_exp: [],
    credentials: [],
    volunteer_exp: [],
    awards_publications: [],
    display_experience_level: true,
    show_skills: true,
    show_awards: true,
    show_license: true,
    show_education: true,
    show_publication: true,
    show_volunteer_experience: true,
    show_professional_summary: true,
    show_professional_experience: true,
  },
  currStep: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.RESUME_DETAILS_REQUEST:
    case TYPES.UPDATE_RESUME_DATA_REQUEST:
      return {
        ...state,
        error: false,
        request: true,
      };
    case TYPES.RECIEVED_UPDATE_RESUME_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        request: false,
        currStep: action.payload.currStep,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    case TYPES.RESUME_DETAILS_ERROR:
    case TYPES.UPDATE_RESUME_DATA_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.INITIATE_RESUME_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        error: false,
        request: false,
      };
    case TYPES.RESET_RESUME_DATA:
      return initialState;
    default:
      return state;
  }
};

export const initiateResumeData = obj => (dispatch, getState) => {
  try {
    const newData = {
      ...initialState.data,
      ...obj,
    };
    dispatch(initiateResumeAction(newData));
  } catch (err) {
    console.log(err);
  }
};

export const updateResumeData = (currStep, data) => async (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
    resumeBuilder: {
      Steps: {data: bodyData},
    },
  } = getState();
  if (
    // !!currStep &&
    data
  ) {
    // dispatch(recieveUpdate({data, currStep})).then(async () => {
    let isSaved = false;
    try {
      let endPoint = `${API.gps.student_resume}`;
      let formdata = {...bodyData, ...data};
      if (bodyData?.resume_uuid) {
        // endPoint += bodyData.resume_uuid;
        // Do nothing. Just pass resume_uuid to the network for edit resume
      } else {
        delete formdata.resume_uuid;
      }
      // delete unwanted data here before sending over network
      delete formdata.email;
      formdata?.student_uuid && delete formdata.student_uuid;
      formdata?.created_at && delete formdata.created_at;
      formdata?.updated_at && delete formdata.updated_at;

      dispatch(requestUpdate());
      const res = await services.createUpdateRecord(
        token,
        endPoint,
        formdata,
        'POST',
      );
      // Check for error otherwise assume success
      if (res?.error) {
        dispatch(recieveUpdate({data, currStep: currStep - 1}));
        dispatch(requestUpdateError(res.error));
      } else {
        dispatch(recieveUpdate({data: res, currStep}));
        isSaved = true;
      }
    } catch (err) {
      dispatch(recieveUpdate({data, currStep: currStep - 1}));
      dispatch(requestUpdateError(err));
    }
    return isSaved;
    // });
  } else {
    dispatch(recieveUpdate({data: {}, currStep}));
  }
};

export const fetchResumeDetails = resume_id => (dispatch, getState) => {
  dispatch(requestResume());
  try {
    const {
      auth: {auth: {token = null, user: {email: userEmail = ''} = {}} = {}},
    } = getState();
    let endPoint = `${API.gps.student_resume}${resume_id}`;
    return services.fetchRecords(endPoint, token).then(res => {
      // Check for error otherwise assume success
      if (res?.error) {
        dispatch(requestResumeError());
      } else {
        dispatch(initiateResumeData({...res, email: userEmail}));
      }
    });
  } catch (err) {
    dispatch(requestResumeError(err));
  }
};

export const patchResumeData = (data, currStep) => async (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
    resumeBuilder: {
      Steps: {data: bodyData},
    },
  } = getState();
  try {
    let endPoint = `${API.gps.student_resume}${bodyData.resume_uuid}`;
    dispatch(requestUpdate());
    await services.updateStatus(token, endPoint, data);
    dispatch(recieveUpdate({data, currStep}));
  } catch (err) {
    dispatch(recieveUpdate({data, currStep}));
    dispatch(requestUpdateError(err));
  }
};

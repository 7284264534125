import {Modal} from 'antd';
import {LocalSalarySearch} from 'core/components';
const LocalSalaryModal = ({onCancel, occupationName}) => {
  return (
    <Modal
      visible
      title={false}
      maskStyle={{backgroundColor: '#ffffffdb'}}
      footer={false}
      className='local-salary-modal'
      onCancel={onCancel}>
      <br />
      <div className='ttl'>
        See salary information for <span>{occupationName}</span> in your area.
      </div>
      <div className='s-ttl'>
        Enter your ZIP code or state below to get started:
      </div>
      <LocalSalarySearch occupationName={occupationName} />
      <br />
    </Modal>
  );
};

export default LocalSalaryModal;

import {ContentBox, ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {licensesAndCertifications} from 'data/settings-profile.json';
import {EditProfileWrapper} from '../../components';
import {useFormatCredentailData} from 'core/hooks';
import {Typography} from '@mui/material';
import LicenseList from './LicenseList';

const Licenses = props => {
  const {studentCredentials = {}} = props;
  const {
    data: studentCredentialData,
    request: studentCredentialRequest,
    error,
  } = studentCredentials;
  const {subHeading, collapseBarHeading} = licensesAndCertifications || {};
  const {licenseAndCertification: licenseData} = useFormatCredentailData(
    studentCredentialData,
  );

  return (
    <ContentBox variant='outlined' sx={{mb: 2, p: 3}}>
      <Typography
        mb={1}
        variant='h5'
        color='text.primary'
        title={collapseBarHeading}
        // fontFamily='fontFamilyBold'
      >
        {collapseBarHeading}
      </Typography>
      <Typography variant='body1' title={subHeading} color='text.secondary'>
        {subHeading}
      </Typography>
      <ErrorBoundary
        nameOfComponent='dashboard-profile-courses'
        typeOfUi='subPage'>
        <RequestErrorLoader
          hideEmpty
          fullWidth
          body={{
            data: studentCredentialData,
            request: studentCredentialRequest,
            error,
          }}>
          <LicenseList {...props} licenseData={licenseData} />
        </RequestErrorLoader>
      </ErrorBoundary>
    </ContentBox>
  );
};

export default EditProfileWrapper(Licenses);

import {CloseCircleFilled} from '@ant-design/icons';
import {Global} from '@emotion/react';
import {
  Box,
  IconButton,
  Paper,
  Skeleton,
  SwipeableDrawer,
  Typography,
  styled,
} from '@mui/material';
import {themeColorMode} from 'mui-core';
import React, {useImperativeHandle} from 'react';
const drawerWidth = 320;
const headerHeight = 64;
const drawerEdge = 100;
const viewChangeFrom = 'md';

const Root = styled(Box)(({theme}) => ({
  position: 'fixed',
  zIndex: 802,
  top: 86 + 58,
  height: `calc(100% - ${headerHeight}px - 64px - 58px)`,
  width: drawerWidth,
  right: 0,
  //   marginLeft: '84px',
  transition: `0.16s all ease-in`,
  paddingRight: '10px',
  borderRadius: 7,
  [theme.breakpoints.up(viewChangeFrom)]: {
    transform: ` translateX(${drawerWidth - drawerEdge}px);`,
    [`&:hover`]: {
      transform: ` translateX(0px);`,
    },
  },
  [theme.breakpoints.down(viewChangeFrom)]: {
    transform: ` translateX(${drawerWidth}px);`,
    [`&.mobile-active`]: {
      transform: ` translateX(0px);`,
      top: 70,
      height: 'calc(100% - 76px)',
      width: '100%',
      padding: '0px 11px',
    },
  },
  [`&.force-active`]: {
    transform: ` translateX(0px);`,
  },
}));
const Content = styled(Box)(({theme}) => ({
  height: '100%',
  borderRadius: 8,
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'relative',
  padding: '0px 12px 12px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: `0px 0px 12px 0px #71717140, 0px 0px 20px 8px #7171712e`,
}));
const CloseIcon = styled(IconButton)(({theme}) => ({
  top: 0,
  right: 12,
  position: 'absolute',
  [theme.breakpoints.up(viewChangeFrom)]: {
    display: 'none',
  },
}));
const Drawer = ({drawerRef, children, forceOpen}) => {
  const [open, setOpen] = React.useState(false);
  const classes = [];
  if (open) classes.push('mobile-active');
  if (forceOpen) classes.push('force-active');
  useImperativeHandle(
    drawerRef,
    () => {
      return {
        open() {
          setOpen(true);
        },
        close() {
          setOpen(false);
        },
        toggle() {
          setOpen(!open);
        },
      };
    },
    [],
  );
  return (
    <Root className={classes.join(' ')}>
      <Content>
        <CloseIcon onClick={() => setOpen(false)}>
          <CloseCircleFilled />
        </CloseIcon>
        {children}
      </Content>
    </Root>
  );
};

export default Drawer;

import {
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import {
  Box,
  Badge,
  Stack,
  Dialog,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useLocation} from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {Button, ContentBox, BackgroundPaper} from 'mui-core';

let scroller;

const StyledFilterContainer = styled(Box)(({theme, fixposition}) => ({
  maxWidth: 320,
  minWidth: 320,
  [`&.stick`]: {
    zIndex: 99,
    top: fixposition,
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      left: 0,
      top: 50,
      borderRadius: 0,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
}));

const StyledFilterLayout = styled(Box)(({theme}) => ({
  '.MuiFormControl-fullWidth': {
    background:
      theme.palette.mode === 'dark' ? theme.palette.darkGray.main : 'white',
  },
}));

const LayoutFilter = (
  {
    title,
    loading,
    children,
    filterContent,
    // fixedScrollHeight,
    existFilter = false,
    filterLabel = 'Filter Results',
  },
  ref,
) => {
  const theme = useTheme();
  const {pathname} = useLocation();
  const [showDialog, setShowDialog] = useState(false);
  const isDeskTop = useMediaQuery(theme.breakpoints.up('md'));
  const fixposition =
    pathname === '/programs' || pathname === '/job-opportunities' ? 110 : 80;

  // const onScroll = useCallback(() => {
  //   const el = document.getElementById('filter-container');
  //   if (el) {
  //     if (window.scrollY > fixedScrollHeight) {
  //       el.classList.add('stick');
  //     } else {
  //       el.classList.remove('stick');
  //     }
  //   }
  // });

  // const scrollerEvent = () => {
  //   scroller = window;
  //   scroller.addEventListener('scroll', onScroll, false);
  // };

  // useEffect(() => {
  //   scrollerEvent();
  //   return () => {
  //     scroller.removeEventListener('scroll', onScroll, false);
  //   };
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    closeFilterDialog() {
      setShowDialog(false);
    },
  }));

  useEffect(() => {
    setShowDialog(false);
  }, [isDeskTop]);

  const filterContainer = () => {
    return (
      <BackgroundPaper
        borderRadius={4}
        background={{
          light: theme.palette.purplebg8perc.main,
        }}>
        <Box p={2}>
          <StyledFilterLayout mt={0.5}>
            {title && (
              <Typography variant='h5' color={'text.primary'}>
                {title}
              </Typography>
            )}
            <Divider sx={{my: 2}} />
            {filterContent}
          </StyledFilterLayout>
        </Box>
      </BackgroundPaper>
    );
  };

  return (
    <>
      {!isDeskTop && (
        <Stack alignItems={'flex-end'} mb={1}>
          <Badge color='primary' variant={existFilter ? 'dot' : ''}>
            <Button
              size='small'
              variant='outlined'
              disabled={loading}
              startIcon={<FilterAltIcon />}
              onClick={() => setShowDialog(true)}>
              {filterLabel}
            </Button>
          </Badge>

          <Dialog
            fullWidth
            maxWidth={'xs'}
            open={showDialog}
            onClose={() => setShowDialog(false)}>
            {filterContainer()}
          </Dialog>
        </Stack>
      )}
      <Stack direction={{md: 'row'}} spacing={2} pb={2}>
        {isDeskTop && (
          <Stack
            sx={{
              maxWidth: 320,
              minWidth: 320,
              width: '30%',
            }}>
            <StyledFilterContainer
              id='filter-container'
              fixposition={fixposition}>
              {filterContainer()}
            </StyledFilterContainer>
          </Stack>
        )}
        <Stack flexGrow={1}>
          <ContentBox variant='outlined'>{children}</ContentBox>
        </Stack>
      </Stack>
    </>
  );
};
export default forwardRef(LayoutFilter);

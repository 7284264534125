import {useHistory} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {defaultClusterCard} from 'assets/images';
import {explorePrograms} from 'data/explorePrograms.json';
import {Card, Carousel, ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {
  Box,
  Stack,
  Divider,
  CardMedia,
  Typography,
  CardContent,
  CardActionArea,
} from '@mui/material';

const {exploreProgramsByClusterArea} = explorePrograms;

const CareerContainer = styled(Stack)(({theme}) => ({
  '&.clusterWrapper': {
    marginRight: '20px',
    ' & .prevBtn': {
      '& .slick-prev': {
        left: '-42px',
      },
    },
    ' & .nextBtn': {
      '& .slick-next': {
        right: '-42px',
      },
    },
  },
  '& .clusterCard': {
    width: '300px',
    height: '78px',
    display: 'flex',
    cursor: 'pointer',
    margin: '0px auto',
    [theme.breakpoints.between('xs', 'lg')]: {
      width: '200px',
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: '200px',
    },
    [theme.breakpoints.only('md')]: {
      minWidth: '220px',
      maxWidth: '280px',
    },
  },
  '& .clusterCardContent': {
    width: '100%',
    padding: '8px',
    '& h6': {
      lineHeight: '19px',
      overflowWrap: 'anywhere',
    },
  },
  '& .clusterTitle': {
    height: '40px',
    overflow: 'hidden',
    WebkitLineClamp: '2',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
  },
  '& .clusterBackgroundImage': {
    width: '82px',
    height: '80px',
    objectFit: 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

const ProgramsClusterArea = ({careerAreaList = []}) => {
  let finalData = [];
  let activePathsCount = 0;
  const history = useHistory();
  const totalCards = careerAreaList?.length || 0;

  const navigateTo = param => {
    history.push(`/cluster?cluster_name=${encodeURIComponent(param)}`);
  };

  if (totalCards > 0) {
    finalData = careerAreaList.sort((a, b) => {
      if (a.active_path_count === b.active_path_count) {
        return a.cluster_name > b.cluster_name ? 1 : -1;
      } else {
        return b.active_path_count - a.active_path_count;
      }
    });
    activePathsCount = finalData?.reduce((counter, value) => {
      if (value?.active_path_count > 0) counter += value?.active_path_count;
      return counter;
    }, 0);
  }

  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }
  const formattedResults = sliceIntoChunks(careerAreaList, 3) || [];

  return (
    <ErrorBoundary nameOfComponent='home-educationprograms-clusterarea'>
      <RequestErrorLoader
        hideEmpty
        hideEmptyIcon
        minHeight={360}
        body={{data: careerAreaList}}>
        <Typography variant='h2' textAlign='left' color='text.primary'>
          {exploreProgramsByClusterArea.heading}
        </Typography>
        <Divider sx={{my: 2}} />
        <CareerContainer spacing={4} mb={4} className='clusterWrapper'>
          <Carousel
            slidesToShow={4}
            content={formattedResults.map((subArr, index) => (
              <Stack
                useFlexGap
                spacing={2}
                flexWrap='wrap'
                direction='column'
                className='clusterBlock'
                key={`subArray-${index}`}>
                {subArr.map(cluster => (
                  <Box
                    key={cluster?.cluster_uuid}
                    sx={{padding: '2px 8px 8px'}}>
                    <Card
                      className='clusterCard'
                      component={CardActionArea}
                      onClick={() => navigateTo(cluster?.cluster_name)}>
                      <CardMedia
                        alt='cover'
                        component='img'
                        image={defaultClusterCard}
                        className='clusterBackgroundImage'
                      />
                      <CardContent className='clusterCardContent'>
                        <Typography
                          variant='h6'
                          color='text.primary'
                          className='clusterTitle'
                          title={cluster?.cluster_name}>
                          {cluster?.cluster_name}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                          {`${cluster?.active_path_count} ${
                            cluster?.active_path_count === 1 ||
                            cluster?.active_path_count === 0
                              ? 'Program'
                              : 'Programs'
                          }`}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              </Stack>
            ))}
          />
        </CareerContainer>
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default ProgramsClusterArea;

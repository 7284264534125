import {useHistory} from 'react-router-dom';
import {publishButtonIcon} from 'assets/images';
import {publishResumeNewIcon} from 'assets/images';
import {DialogBox, themeColorMode} from 'mui-core';
import resumeBuilderData from 'data/resumeBuilder.json';
import {Download, Visibility} from '@mui/icons-material';
import {styled, Stack, Button, useTheme, Typography} from '@mui/material';

const {
  stepsMeta: {publications},
} = resumeBuilderData || {};

const {successModal = {}} = publications;

const StyledButton = styled(Button)(({theme, backgroundColor}) => ({
  color: 'white',
  height: '40px',
  borderRadius: '8px',
  padding: '0px 24px',
  background: backgroundColor,
  [`&:hover`]: {
    opacity: 0.8,
  },
  [theme.breakpoints.down('md')]: {
    width: '280px',
    height: '30px',
  },
}));

const SuccessModal = ({showModal, onClose}) => {
  const theme = useTheme();
  const history = useHistory();

  const handleProfileButton = () => {
    onClose();
    history.push('/dashboard/goResume');
  };

  const handlePublishButton = () => {
    onClose();
    history.push('/dashboard/settings');
  };

  return (
    <DialogBox
      size='md'
      closeDialog={onClose}
      openDialog={showModal}
      sx={{borderRadius: '20px'}}
      showIcon={publishResumeNewIcon}>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{textAlign: 'center'}}>
        <Typography
          mb={2}
          variant='h2'
          color={themeColorMode(
            theme,
            theme.palette.shadeyBlack.main,
            'white',
          )}>
          {successModal.newTitle}
        </Typography>
        <Typography
          variant='pagedesc'
          color={themeColorMode(theme, theme.palette.shadeyBlack.main, 'white')}
          sx={{
            width: '600px',
            maxWidth: '100%',
          }}
          dangerouslySetInnerHTML={{
            __html: successModal.newSubHeading,
          }}
        />
      </Stack>
      <Stack
        mt={4}
        mb={2}
        spacing={2}
        alignItems='center'
        justifyContent='center'
        direction={{xs: 'column', md: 'row'}}>
        <StyledButton
          endIcon={<Download />}
          onClick={handleProfileButton}
          backgroundColor='linear-gradient(180deg, rgba(92,82,244,1) 0%, rgba(80,64,238,1) 100%)'>
          {successModal.profileButtonLabel}
        </StyledButton>
        <StyledButton
          onClick={handlePublishButton}
          endIcon={<Visibility sx={{mb: 0.2}} />}
          backgroundColor='linear-gradient(180deg, rgba(77,160,242,1) 0%, rgba(46,106,246,1) 100%)'>
          {successModal.newPublishButtonLabel}
        </StyledButton>
      </Stack>
    </DialogBox>
  );
};

export default SuccessModal;

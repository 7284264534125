import {createTheme} from '@mui/material/styles';
const ButtonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
        },
      },
      variants: [
        {
          props: {rounded: true},
          style: {
            borderRadius: `50em`,
          },
        },
        {
          props: {variant: 'contained', color: 'primary'},
          style: ({theme}) => ({
            background: 'linear-gradient(180deg, #635BFF 0%, #4E36F5 100%)',
            boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.08)`,
          }),
        },
        {
          props: {uppercase: true},
          style: {
            textTransform: `uppercase`,
          },
        },
      ],
    },
  },
});
export {ButtonTheme};

import * as TYPES from './constants';
import {API} from 'config';
import {
  recieveLocalSalary,
  requestLocalSalary,
  requestLocalSalaryError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_LOCAL_SALARY:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_LOCAL_SALARY:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_LOCAL_SALARY_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchLocalSalary = (searchKey, occupationName) => (
  dispatch,
  getState,
) => {
  const endPoint = `${API.srm.occupations}?occupation_name=${occupationName}&location=${searchKey}&data=local_salary_info`;
  dispatch(requestLocalSalary());
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveLocalSalary(res));
        return;
      }
      dispatch(requestLocalSalaryError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestLocalSalaryError(err));
  }
};

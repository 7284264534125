import {Grid, SkillsStack} from 'mui-core';
import {formatViewDate} from 'core/utils';
import {Typography, Divider} from '@mui/material';
import {educationData} from 'data/settings-profilePreview.json';

const Education = ({data: education, getAwardType}) => (
  <>
    {education &&
      Array.isArray(education) &&
      education.length > 0 &&
      education.map((ed, idx) => {
        return (
          <>
            {ed?.org_name && (
              <Grid container key={idx}>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={2}
                  sx={{textAlign: 'right', paddingRight: '20px'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    component='span'
                    fontWeight={600}
                    color='text.primary'>
                    {educationData.schoolLabel}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={10}
                  sx={{textAlign: 'left'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'
                    color='text.primary'>
                    {ed?.org_name}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {((ed?.school_start_date &&
              (ed?.school_end_date || ed?.is_present)) ||
              (ed?.start_date && (ed?.end_date || ed?.is_present))) && (
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={2}
                  sx={{textAlign: 'right', paddingRight: '20px'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'
                    color='text.primary'>
                    {educationData.attendedLabel}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={10}
                  sx={{textAlign: 'left'}}>
                  <Typography
                    variant='h6'
                    fontWeight={600}
                    color='text.primary'
                    fontSize={{xs: 12, sm: 14, md: 16}}>
                    {/* {ed.active_path ? (
                      formatViewDate(ed.updated_at)
                    ) : ( */}
                    {/* )} */}

                    {ed?.student_onboard_uuid ? (
                      <>
                        {ed?.start_date && (ed?.end_date || ed?.is_present)
                          ? `${formatViewDate(ed?.start_date)} - ${
                              ed?.is_present
                                ? 'Present'
                                : formatViewDate(ed?.end_date)
                            }`
                          : formatViewDate(ed?.updated_at)}
                      </>
                    ) : (
                      <>
                        {ed?.school_start_date &&
                        (ed?.school_end_date || ed?.is_present)
                          ? `${formatViewDate(ed?.school_start_date)} - ${
                              ed.is_present
                                ? 'Present'
                                : formatViewDate(ed?.school_end_date)
                            }`
                          : '-'}
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {ed?.award_type && (
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={2}
                  sx={{textAlign: 'right', paddingRight: '20px'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'>
                    {educationData.degreeAwardLabel}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={10}
                  sx={{textAlign: 'left'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'>
                    {getAwardType(ed?.award_type)}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {ed?.area_of_focus && (
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={2}
                  sx={{textAlign: 'right', paddingRight: '20px'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'
                    color='text.primary'>
                    {educationData.areaOfFocusLabel}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={10}
                  sx={{textAlign: 'left'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'
                    color='text.primary'>
                    {ed?.area_of_focus}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {ed?.gpa && (
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={2}
                  sx={{textAlign: 'right', paddingRight: '20px'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'
                    color='text.primary'>
                    {educationData.gpaLabel}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={10}
                  sx={{textAlign: 'left'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'
                    color='text.primary'>
                    {ed?.gpa}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {ed?.comment && (
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={2}
                  sx={{textAlign: 'right', paddingRight: '20px'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'
                    color='text.primary'>
                    {educationData.commentsLabel}{' '}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={10}
                  sx={{textAlign: 'left'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'
                    color='text.primary'>
                    {ed?.comment}{' '}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {ed.skills?.length !== 0 && (
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={2}
                  mt={2}
                  sx={{textAlign: 'right', paddingRight: '20px'}}>
                  <Typography
                    variant='h6'
                    fontSize={15}
                    fontWeight={600}
                    component='span'
                    color='text.primary'>
                    {educationData.SkillsLabel}{' '}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={10}
                  sx={{
                    textAlign: 'left',
                  }}>
                  <SkillsStack
                    emptyMsg='-'
                    displayLimit={15}
                    skillSet={ed.skills}
                    displayShowAll={true}
                  />
                </Grid>
              </Grid>
            )}

            {idx + 1 !== education.length && <Divider sx={{margin: '20px'}} />}
          </>
        );
      })}
  </>
);

export default Education;

import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {ErrorBoundary, RequestErrorLoader} from 'mui-core';
import VolunteerExperienceList from './VolunteerExperienceList';
import {getResumeData} from 'redux/modules/resume-builder';

const VolunteerExperience = ({formRef, handleSave}) => {
  const [isLoading, setisLoading] = useState(false);
  const {data: resumeData, request} = useSelector(getResumeData);

  let volunteerExperienceList = resumeData?.volunteer_exp || [];

  const onSubmit = (data, action) => {
    const payload = getPayload(data, action);
    handleSave(payload);
  };

  const getPayload = (data, action) => {
    if (action === 'edit') {
      volunteerExperienceList = volunteerExperienceList?.map(
        volunteerExpObj => {
          if (volunteerExpObj.display_order === data.display_order) {
            return data;
          } else {
            return volunteerExpObj;
          }
        },
      );
    } else if (action === 'delete') {
      volunteerExperienceList = volunteerExperienceList
        ?.filter(item => item.display_order !== data.display_order)
        ?.map((item, index) => {
          return {...item, display_order: index + 1};
        });
    } else {
      volunteerExperienceList?.push({
        ...data,
        display_order: volunteerExperienceList.length + 1,
      });
    }
    return {volunteer_exp: volunteerExperienceList};
  };

  return (
    <ErrorBoundary
      typeOfUi='subPage'
      nameOfComponent='resume-builder-volunteer-experience'>
      <RequestErrorLoader
        hideEmpty
        body={{data: volunteerExperienceList, request: isLoading || request}}>
        <VolunteerExperienceList
          hideTitle
          hideEmpty
          formRef={formRef}
          data={resumeData}
          onChange={onSubmit}
          getPayload={getPayload}
        />
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default VolunteerExperience;

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestLocationSearch,
  recieveLocationSearch,
  requestLocationSearchError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();
const defaultMinimumCharacter = 2;
const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LOCATION_SEARCH_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.LOCATION_SEARCH_RECEIVE:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.LOCATION_SEARCH_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchLocationSearch = keyword => (dispatch, getState) => {
  dispatch(requestLocationSearch());
  try {
    let endPoint = `${API.srm.Location}`;
    if (keyword && keyword.length >= defaultMinimumCharacter) {
      return services
        .fetchRecords(`${endPoint}?location_name=${keyword}`, null, true)
        .then(res => {
          if (res) {
            dispatch(recieveLocationSearch(res));
            return;
          }
          dispatch(requestLocationSearchError(res));
        });
    } else {
      dispatch(recieveLocationSearch([]));
    }
  } catch (err) {
    console.log(err);
    dispatch(requestLocationSearchError(err));
  }
};

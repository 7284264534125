import {useEffect} from 'react';
const HELP_WIDGET_ID = 65000000315;

const useScript = (url, async, defer) => {
  const getWindow = window;
  const getDocument = document;
  const getBody = getDocument.body;
  useEffect(() => {
    getWindow.fwSettings = {
      widget_id: HELP_WIDGET_ID,
    };
    const script = document.createElement('script');
    script.id = 'freshdesk-script';
    script.src = url;
    script.defer = typeof defer === 'undefined' ? true : defer;
    script.async = typeof async === 'undefined' ? true : async;
    getBody.appendChild(script);
    (function () {
      if ('function' != typeof getWindow.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        n.q = [];
        getWindow.FreshworksWidget = n;
      }
    })();

    return () => {
      let el = getDocument.querySelector('#freshworks-container');
      let elem = getDocument.querySelector('iframe#freshworks-frame');
      script && getBody.removeChild(script);
      el && getBody.removeChild(el);
      elem && getBody.removeChild(elem);
      el = null;
      elem = null;
    };
  }, [
    async,
    defer,
    getBody,
    getDocument,
    getWindow.FreshworksWidget,
    getWindow.fwSettings,
    url,
  ]);
};

export default function HelpWidgetLoad({src, async = true, defer = true}) {
  useScript(src, async, defer);
  return null;
}

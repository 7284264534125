import {useState, useEffect, useCallback} from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Checkbox,
  Divider,
  AutoComplete,
} from 'antd';
import {FormControl, FormLabel} from '@mui/material';
import {LocationSearch} from 'mui-core';
import {styled} from '@mui/material/styles';
import debounce from 'lodash/debounce';
import {RequestErrorLoader, ErrorBoundary} from 'core/components';
import {CloseOutlined, SearchOutlined} from '@ant-design/icons';
import {FilterAutoComplete} from 'core/components';
import Types from './Types';
import {API} from 'config';
import {employOpportData} from 'data/occupation.json';
const StyledLocation = styled(FormControl)(({theme}) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const {Option} = Select;

const TypeOptions = [
  {
    title: employOpportData.employmentTypes.jobsTitle,
    value: 'local_jobs',
  },
  {
    title: employOpportData.employmentTypes.apprenticeshipsTitle,
    value: 'apprenticeship',
  },
  {
    title: employOpportData.employmentTypes.internshipsTitle,
    value: 'internship',
  },
  {
    title: employOpportData.employmentTypes.otherTrainingTitle,
    value: 'other',
  },
];

const WorkLocationOptions = [
  {
    title: employOpportData.workLocations.allLocationsText,
    value: 'all',
  },
  {
    title: employOpportData.workLocations.oneLocationText,
    value: 'one_location',
  },
  {
    title: employOpportData.workLocations.remoteLocationsText,
    value: 'remote',
  },
  {
    title: employOpportData.workLocations.hybridLocationsText,
    value: 'hybrid',
  },
];

const RadiusData = [5, 10, 15, 25];

const Radius = ({form}) => {
  useEffect(() => {
    form.setFieldsValue({
      radius: 5,
    });
  }, [form]);

  return (
    <Form.Item label='Within' name='radius'>
      <Select dropdownClassName='search-grid' className='radiusDropdown'>
        {RadiusData.map((value, idx) => (
          <Option value={value} key={idx}>
            {`${value} Miles`}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const FiltersForm = props => {
  const {
    form,
    onFinish,
    onFinishFailed,
    filters,
    onReset,
    type,
    isMobileView,
    onHideFilter,
    isBusinessPartner,
    isGlobalSearch,
    onTypeChange,
    onRemoteChange,
    onWorkLocationChange,
    location_type,
    initialData,
    occupationList,
    onCareerChange,
    occupationDetails,
    isProgramOverview,
    onlyLocal,
    hideLabels,
    formLabel,
    reqParams,
    locationSearchData,
    fetchLocationSearch,
    nearbyMSA = [],
  } = props;
  let showReset = false;
  const isLocalJob = type !== 'job_boards';
  const autocompleteURL = API.srm.opportunity;
  const [locationForm] = Form.useForm();
  const [careerForm] = Form.useForm();
  const [showRadius, setShowRadius] = useState(false);
  const [showAll, setShowAll] = useState(false);
  //const [isRemoteDisabled, setIsRemoteDisabled] = useState(false);
  const [isLocationDisabled, setIsLocationDisabled] = useState(false);
  const filterKeys = Object.keys(filters);
  const [searchKey, setSearchKey] = useState('');

  if (filterKeys?.length) {
    filterKeys.map(key => {
      if (filters[key]) {
        showReset = true;
      }
    });
  }

  const resetForms = useCallback(() => {
    form.resetFields();
    locationForm.resetFields();
    careerForm.setFieldsValue({
      career_list: occupationList?.allOccupations?.occupation?.[0]?.uuid || '',
    });
  }, [careerForm, form, locationForm, occupationList]);

  const localStateReset = () => {
    setShowRadius(false);
    setIsLocationDisabled(false);
    //setIsRemoteDisabled(false);
    resetForms();
  };

  const onResetClick = () => {
    localStateReset();
    onReset();
  };

  const onInputChange = val => {
    form.setFieldsValue({location: val.location});
  };

  const handleLocationChange = v => {
    let updatedParams = {...filters};
    if (v.length < 2) {
      return;
    }
    updatedParams = {
      ...filters,
      page: 1,
      company: form.getFieldValue('company'),
      title: form.getFieldValue('title'),
      location: v?.location,
    };
    form.setFieldsValue({location: v?.location});
    onFinish(updatedParams);
  };

  const onLocationChange = useCallback(
    event => {
      const value = event;
      //setIsRemoteDisabled(value && isLocalJob);
      if (type === 'job_boards') {
        setShowRadius(false);
        const reg = /^\d+$/;
        if (value) {
          showReset = true;
          if (reg.test(value) && value.length >= 2) {
            // Check for number and length > 2
            setShowRadius(true);
          }
        }
      }
    },
    [type],
  );
  useEffect(() => {
    localStateReset();
  }, [type]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (occupationDetails?.data?.occupation_uuid) {
      careerForm.setFieldsValue({
        career_list: occupationDetails?.data?.occupation_uuid,
      });
    }
  }, [careerForm, occupationDetails]);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     title: filters['title'] || reqParams?.gsearch,
  //   });
  // }, [filters['title'], reqParams]);

  let careerData = [];
  if (
    isProgramOverview &&
    occupationList?.allOccupations?.occupation &&
    Array.isArray(occupationList?.allOccupations?.occupation)
  ) {
    careerData = occupationList?.allOccupations?.occupation;
  }
  const careerDataLength = careerData?.length;

  const careerItem = ({onnet, name, uuid}) => (
    <li
      title={name}
      onClick={() =>
        onCareerChange(null, {
          key: onnet,
          children: name,
          value: uuid,
        })
      }
      className='career-item'
      key={onnet}>
      {name}
    </li>
  );
  const {data: {location_details = []} = {}, request: locationSearchRequest} =
    locationSearchData || {};
  const options =
    (location_details &&
      Array.isArray(location_details) &&
      location_details.length > 0 &&
      location_details.map(d => (
        <Option key={d.id} text={d} value={`${d.city}, ${d.state_code}`}>
          {`${d.city}, ${d.state_code}`}
        </Option>
      ))) ||
    [];

  const onSearch = debounce(keyword => {
    setSearchKey(keyword);
    if (keyword) {
      fetchLocationSearch(keyword);
    }
  }, 800);

  return (
    <div className='employment-filters'>
      <h2>{formLabel || employOpportData.filterByHeading}</h2>
      {isMobileView && <CloseOutlined onClick={onHideFilter} />}

      {/* {!isBusinessPartner && !onlyLocal && !isMobileView && (
        <Types
          onChange={onTypeChange}
          selectedValue={type}
          initialData={initialData}
          isBusinessPartner={isBusinessPartner}
        />
      )} */}

      {isLocalJob && !isBusinessPartner && !isGlobalSearch && (
        <>
          <Form
            layout={hideLabels ? 'horizontal' : 'vertical'}
            name='employment_filters'
            initialValues={{
              type: type || 'local_jobs',
            }}>
            <Form.Item label={employOpportData.typeByLabel} name='type'>
              <Select
                className='radiusDropdown'
                value={type}
                onChange={onTypeChange}>
                {TypeOptions.map(({title, value}) => (
                  <Option key={value} value={value}>
                    {title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Form
            layout={hideLabels ? 'horizontal' : 'vertical'}
            name='location_type_filter'>
            <Form.Item
              initialValue={location_type || WorkLocationOptions[0].value}
              label={employOpportData.workLocationLabel}
              name='location_type'>
              <Select
                className='radiusDropdown'
                onChange={onWorkLocationChange}
                value={location_type}>
                {WorkLocationOptions.map(({title, value}) => (
                  <Option key={value} value={value}>
                    {title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </>
      )}
      {!isGlobalSearch && !isBusinessPartner && type !== 'job_boards' && (
        <Divider />
      )}
      <Form
        form={form}
        layout='vertical'
        name='employment_filters'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        //initialValues={{ title: filters['title'] }}
      >
        {!isBusinessPartner && isLocalJob ? (
          <>
            {!isGlobalSearch && (
              <FilterAutoComplete
                type='title'
                name='title'
                resetKey={type}
                url={autocompleteURL}
                noResultsText={employOpportData.noResultsFoundTxt}
                placeholder={employOpportData.searchByTitlePlaceholder}
                label={!hideLabels && employOpportData.searchByTitleLabel}
              />
            )}
            <FilterAutoComplete
              type='company_name'
              name='company_name'
              resetKey={type}
              url={autocompleteURL}
              noResultsText={employOpportData.noResultsFoundTxt}
              placeholder={employOpportData.searchByCompanyPlaceholder}
              label={!hideLabels && employOpportData.searchByCompanyLabel}
            />
          </>
        ) : (
          <>
            <Form.Item
              name='title'
              //initialValue={filters['title']}
              label={!hideLabels && employOpportData.searchByTitleLabel}>
              <Input
                defaultValue={filters['title'] || ''}
                placeholder={employOpportData.searchByTitlePlaceholder}
              />
            </Form.Item>
            {!isBusinessPartner && (
              <Form.Item
                name='company'
                label={!hideLabels && employOpportData.searchByCompanyLabel}>
                <Input
                  placeholder={employOpportData.searchByCompanyPlaceholder}
                />
              </Form.Item>
            )}
          </>
        )}
        <Form.Item
          label={!hideLabels && employOpportData.searchByLocationLabel}
          name='location'>
          <StyledLocation>
            <LocationSearch
              locationLabel=''
              filters={filters}
              LocationOptions={nearbyMSA}
              onLocationChange={handleLocationChange}
              onInputChange={onInputChange}
              calledFromForm
              onEnterPress={v => handleLocationChange({location: v})}
            />
          </StyledLocation>
        </Form.Item>

        {/* {showRadius && <Radius form={form} />} */}
        <Form.Item>
          <Button
            type='primary'
            className='btn-purple w-100 mt-4'
            htmlType='submit'>
            {employOpportData.searchBtnTxt}
          </Button>
        </Form.Item>
        {isBusinessPartner && (
          <Form.Item
            name='job_type'
            className='remote_checkbox'
            valuePropName={'checked'}
            style={{display: 'inline-block'}}>
            <Checkbox onChange={onRemoteChange}>
              {employOpportData.searchByRemoteLocationLabel}
            </Checkbox>
          </Form.Item>
        )}
        {showReset && (
          <>
            {type === 'job_boards' && (
              <Form.Item
                name='job_type'
                className='remote_checkbox'
                valuePropName={'checked'}
                style={{display: 'inline-block'}}>
                <Checkbox onChange={onRemoteChange}>
                  {employOpportData.searchByRemoteLocationLabel}
                </Checkbox>
              </Form.Item>
            )}
            {!isMobileView && (
              <div className='reset-filters'>
                <span onClick={onResetClick}>
                  {employOpportData.resetFilterBtnTxt}
                </span>
              </div>
            )}
          </>
        )}
      </Form>

      {type === 'job_boards' && isProgramOverview && (
        <div className='career-list'>
          <h3>New Search By - Related Careers</h3>
          <ul>
            {careerData?.map((_obj, idx) => {
              if (showAll || idx <= 4) {
                return careerItem(_obj);
              }
            })}

            {careerDataLength > 5 && (
              <li className='showMoreLess' onClick={() => setShowAll(!showAll)}>
                {showAll ? 'Show Less' : 'Show More'}
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FiltersForm;

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'mui-core/SvgIcon';
import {styled} from '@mui/material/styles';
import {Typography, Button} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const StyledButton = styled(Button)(
  ({theme, variant, size = 'medium', iconSize = '14px'}) => ({
    color:
      variant === 'outlined'
        ? theme.palette.mode === 'dark'
          ? theme.palette.primary.light
          : theme.palette.primary.main
        : variant === 'contained'
        ? 'white'
        : theme.palette.primary.main,
    padding: '8px 24px',
    '&:hover': {
      backgroundColor: variant === 'outlined' && theme.palette.primary.main,
      color: variant === 'outlined' && theme.palette.primary.contrastText,
      '.MuiButton-endIcon, .MuiButton-startIcon': {
        filter:
          variant === 'contained' || variant === 'outlined'
            ? theme.palette.iconLightColor.main
            : theme.palette.iconBrandColor.main,
      },
    },
    '.MuiButton-endIcon, .MuiButton-startIcon': {
      width: size === 'medium' ? iconSize : size === 'large' ? '20px' : '14px',
      height: size === 'medium' ? iconSize : size === 'large' ? '20px' : '14px',
      filter:
        variant === 'contained'
          ? theme.palette.iconLightColor.main
          : theme.palette.iconBrandColor.main,
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 24px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 24px',
      '.MuiButton-endIcon, .MuiButton-startIcon': {
        width: '12px',
        height: '12px',
      },
    },
  }),
);

const IconButton = props => {
  const {
    label,
    height,
    loading,
    endIcon,
    iconSize,
    startIcon,
    iconPadding,
    textCapital,
    ...restProps
  } = props;
  return (
    <>
      {!loading ? (
        <StyledButton
          endIcon={
            endIcon ? (
              <SvgIcon
                src={endIcon}
                altText='button end icon'
                sx={{width: iconSize, height: iconSize, padding: iconPadding}}
              />
            ) : null
          }
          startIcon={
            startIcon ? (
              <SvgIcon
                src={startIcon}
                altText='button start icon'
                sx={{width: iconSize, height: iconSize, padding: iconPadding}}
              />
            ) : null
          }
          {...restProps}>
          <Typography variant='label-md'>
            {textCapital ? label.toUpperCase() : label}
          </Typography>
        </StyledButton>
      ) : (
        <LoadingButton
          loading
          endIcon={
            endIcon ? <SvgIcon altText='button end icon' src={endIcon} /> : null
          }
          startIcon={
            startIcon ? (
              <SvgIcon altText='button start icon' src={startIcon} />
            ) : null
          }
          {...restProps}>
          <Typography variant='label-md'>
            {textCapital ? label.toUpperCase() : label}
          </Typography>
        </LoadingButton>
      )}
    </>
  );
};
IconButton.propTypes = {
  ...Button.propTypes,
  rounded: PropTypes.bool,
  loading: PropTypes.bool,
  uppercase: PropTypes.bool,
  iconSize: PropTypes.string,
  iconPadding: PropTypes.string,
  ...LoadingButton.propTypes,
};
export default IconButton;

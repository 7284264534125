import * as TYPES from './constants';
import {API} from 'config';
import isArray from 'lodash/isArray';

import {
  requestStudentCredentials,
  receiveStudentCredentials,
  studentCredentialsError,
  requestSaveStudentCredentials,
  receiveSaveStudentCredentials,
  studentSaveCredentialsError,
  requestUpdateStudentCredentials,
  receiveUpdateStudentCredentials,
  studentUpdateCredentialsError,
  requestDeleteStudentCredentials,
  receiveDeleteStudentCredentials,
  studentDeleteCredentialsError,
  studentCredentialsClear,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.STUDENT_CREDENTIALS_LOAD:
    case TYPES.STUDENT_CREDENTIALS_SAVE_LOAD:
    case TYPES.STUDENT_CREDENTIALS_UPDATE_LOAD:
    case TYPES.STUDENT_CREDENTIALS_DELETE_LOAD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.STUDENT_CREDENTIALS_SUCCESS:
    case TYPES.STUDENT_CREDENTIALS_SAVE_SUCCESS:
    case TYPES.STUDENT_CREDENTIALS_UPDATE_SUCCESS:
    case TYPES.STUDENT_CREDENTIALS_DELETE_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        ...(isArray(action.payload) && {data: action.payload}),
      };
    case TYPES.STUDENT_CREDENTIALS_ERROR:
    case TYPES.STUDENT_CREDENTIALS_SAVE_ERROR:
    case TYPES.STUDENT_CREDENTIALS_UPDATE_ERROR:
    case TYPES.STUDENT_CREDENTIALS_DELETE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.STUDENT_CREDENTIALS_CLEAR:
      return {...initialState};
    default:
      return state;
  }
};

export const fetchSharedStudentCredentials = userName => async (
  dispatch,
  _getState,
) => {
  try {
    let endPoint = userName
      ? `${API.gps.share_student_credentials}?username=${userName}&shareable=true`
      : `${API.gps.student_credentials}`;
    dispatch(requestStudentCredentials());
    return services.fetchRecords(endPoint).then(res => {
      if (Array.isArray(res)) dispatch(receiveStudentCredentials(res));
      else dispatch(studentCredentialsError());
    });
  } catch (err) {
    console.log(err);
    dispatch(studentCredentialsError(err));
  }
};

export const fetchStudentCredentials = userName => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();

    let endPoint = userName
      ? `${API.gps.student_credentials}?username=${userName}&shareable=true`
      : `${API.gps.student_credentials}`;

    dispatch(requestStudentCredentials());
    return services.fetchRecords(endPoint, token).then(res => {
      if (Array.isArray(res)) dispatch(receiveStudentCredentials(res));
      else dispatch(studentCredentialsError());
    });
  } catch (err) {
    console.log(err);
    dispatch(studentCredentialsError(err));
  }
};

export const saveStudentCredential = (body, callback) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();

    let endPoint = `${API.gps.student_credentials}`;
    dispatch(requestSaveStudentCredentials());
    return services
      .createUpdateRecord(token, endPoint, body, 'POST')
      .then(res => {
        if (callback) callback(res);
        dispatch(receiveSaveStudentCredentials(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(studentSaveCredentialsError(err));
  }
};

export const updateStudentCredential = (body, callback) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();

    let endPoint = `${API.gps.student_credentials}${body.uuid}`;
    dispatch(requestUpdateStudentCredentials());
    return services
      .createUpdateRecord(token, endPoint, body, 'PUT')
      .then(res => {
        if (callback) callback(res);
        dispatch(receiveUpdateStudentCredentials(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(studentUpdateCredentialsError(err));
  }
};

export const deleteStudentCredential = (uuid, callback) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    const endPoint = `${API.gps.student_credentials}${uuid}`;
    dispatch(requestDeleteStudentCredentials());
    return services.deleteRecord(token, endPoint).then(res => {
      if (callback) callback(res);
      dispatch(receiveDeleteStudentCredentials(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(studentDeleteCredentialsError(err));
  }
};

export const clearStudentCredentials = () => async dispatch => {
  dispatch(studentCredentialsClear());
};

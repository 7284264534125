import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import {Box, Stack, styled, Container} from '@mui/material';

const VideoBannerWrapper = styled(Box)(({theme}) => ({
  width: '100%',
  height: '100%',
  maxHeight: '680px',
  overflow: 'hidden',
  position: 'relative',
  backgroundPosition: 'center',
  [`& .overlay`]: {
    width: '100%',
    marginTop: '-5%',
  },
}));

const TextWrapper = styled(Box)(({theme, customRibbon}) => ({
  top: '0',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  backgroundColor: theme.palette.shadow33.main,
  [theme.breakpoints.down('lg')]: {
    top: customRibbon ? '-4px' : '-5px',
  },
}));

const ContentBox = styled(Stack)(({theme, customRibbon}) => ({
  width: '65%',
  padding: '0px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: '36px 8px 42px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: customRibbon ? '0px 8px 90px' : '36px 8px 42px',
  },
}));

const VideoBanner = ({bannerURL, customRibbon, children}) => {
  return (
    <VideoBannerWrapper>
      <Box className='overlay'>
        <ReactPlayer
          loop
          muted
          playing
          playsinline
          width='100%'
          height='100%'
          url={bannerURL}
        />
      </Box>
      <TextWrapper customRibbon>
        <Container maxWidth='xl'>
          <ContentBox
            spacing={2}
            alignItems='left'
            justifyContent='left'
            customRibbon={customRibbon}>
            {children}
          </ContentBox>
        </Container>
      </TextWrapper>
      {customRibbon}
    </VideoBannerWrapper>
  );
};

VideoBanner.propTypes = {
  children: PropTypes.node,
  customRibbon: PropTypes.node,
  bannerURL: PropTypes.string.isRequired,
};

export default VideoBanner;

import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {createField} from 'mui-core/Form';
import {AutoCompleteWrapper, Button} from 'mui-core';
import {skillsSelector} from 'redux/modules/profile';
import {Box, OutlinedInput, Stack, Typography} from '@mui/material';
import {getResumeData, getLinkedInDetails} from 'redux/modules/resume-builder';

const AIModal = ({fetchData, request}) => {
  const form = useForm();
  const {handleSubmit} = form;
  const linkedInData = useSelector(getLinkedInDetails) || {};
  const {data: resumeData} = useSelector(getResumeData) || {};
  const {data: skill_name = []} = useSelector(skillsSelector) || {};
  const {job_title: desired_job_title_options = []} = linkedInData;

  const additionalSkills =
    resumeData?.skills &&
    Array.isArray(resumeData?.skills) &&
    resumeData?.skills.length > 0
      ? resumeData?.skills.map(item => {
          return {
            ...item,
            key: item.uuid,
            label: item.skill,
            title: item.skill,
          };
        })
      : [];

  let wrkExpSkills = [];
  resumeData?.work_exp &&
    Array.isArray(resumeData?.work_exp) &&
    resumeData?.work_exp.length > 0 &&
    resumeData?.work_exp.map(exp => {
      if (exp.skills.length) {
        exp.skills.map((skill, i) => {
          wrkExpSkills.push({
            ...skill,
            key: skill.skill + i,
            label: skill.skill,
            title: skill.skill,
          });
        });
      }
    });

  const filteredSkillsData = [
    ...new Set([...additionalSkills, ...wrkExpSkills]),
  ];

  const onSubmit = values => {
    const data = {...values};
    data['skills'] = values.skills
      ? values.skills.map(skill => skill.skill)
      : filteredSkillsData?.map(skill => skill.skill);
    fetchData(data);
  };

  return (
    <Box>
      <Box sx={{py: 3, px: 5}}>
        <Typography variant='body1' color={'darkGray'} mb={2}>
          Alternatively, fill in the statement below to help us create a version
          customized to your preferences. Once you provide your input, you'll
          receive new personalized ChatGPT responses above.
        </Typography>
        <form>
          <Stack
            spacing={2}
            direction={'row'}
            alignItems={{xs: 'start', md: 'start'}}>
            <Typography
              variant='h6'
              sx={{minWidth: 140, textAlign: 'right', marginTop: '14px'}}>
              I am a...
            </Typography>
            <Box flexGrow={1}>
              <Stack
                direction={{xs: 'column', md: 'row'}}
                alignItems={{xs: 'start', md: 'start'}}
                spacing={3}>
                {createField('job_title', {
                  form,
                  defaultValue: '',
                  helperText:
                    'Enter a brief statement about yourself. Example: I am a software engineer, or student.',
                  //   label: formStaticData.company.label,
                  rules: {
                    required: [true, 'Required'],
                  },
                })(
                  <OutlinedInput
                    fullWidth
                    size='small'
                    variant='outlined'
                    id='work-exp-comp-name'
                    // label={formStaticData.company.label}
                    placeholder={'Software Developer'}
                  />,
                )}
                <Box>
                  <Stack
                    direction={{xs: 'column', md: 'row'}}
                    alignItems={'center'}
                    spacing={3}>
                    <Typography variant='h6'>with...</Typography>
                    {createField('years_of_exp', {
                      form,
                      defaultValue: '',
                      //   label: formStaticData.company.label,
                      //   rules: {
                      //     required: [true, formStaticData.company.requiredMsg],
                      //     maxLength: [60, formStaticData.company.maxLengthMsg],
                      //   },
                      rules: {
                        required: [true, 'Required'],
                        pattern: [/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/, ''],
                      },
                    })(
                      <OutlinedInput
                        fullWidth
                        size='small'
                        sx={{minWidth: 80}}
                        variant='outlined'
                        id='work-exp-comp-name'
                        type={'number'}
                        // label={formStaticData.company.label}
                        placeholder={'2'}
                      />,
                    )}
                    <Typography sx={{minWidth: 135}} variant='h6'>
                      years experience.
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Stack spacing={2} alignItems={'center'} direction={'row'} mt={2}>
            <Typography variant='h6' sx={{minWidth: 140, textAlign: 'right'}}>
              My Skills include...
            </Typography>
            <Box flexGrow={1}>
              {createField('skills', {
                form,
                size: 'small',
              })(
                <AutoCompleteWrapper
                  multiple
                  form={form}
                  label='Add Skill'
                  fieldName='skills'
                  keyNameIfMultiple='skill'
                  disableCloseOnSelect={true}
                  placeholder='Example: Python, NodeJS, CSS, etc. '
                  options={
                    skill_name &&
                    Array.isArray(skill_name) &&
                    skill_name.length > 0
                      ? skill_name?.map(item => {
                          return {
                            ...item,
                            key: item.id,
                            label: item.skill_name || item.name,
                            title: item.skill_name || item.name,
                            skill: item.skill_name || item.name,
                          };
                        })
                      : []
                  }
                  value={filteredSkillsData || []}
                  defaultValue={filteredSkillsData || []}
                />,
              )}
            </Box>
          </Stack>
          <Stack spacing={2} alignItems={'center'} direction={'row'} mt={4}>
            <Typography variant='h6' sx={{minWidth: 140, textAlign: 'right'}}>
              Desired job title...
            </Typography>
            <Box flexGrow={1}>
              {createField('desired_job_title', {
                form,
                defaultValue: '',
                //   label: formStaticData.company.label,
                //   rules: {
                //     required: [true, formStaticData.company.requiredMsg],
                //     maxLength: [60, formStaticData.company.maxLengthMsg],
                //   },
                rules: {
                  required: [true, 'Required'],
                },
              })(
                <AutoCompleteWrapper
                  required
                  form={form}
                  fieldName='desired_job_title'
                  options={
                    desired_job_title_options &&
                    Array.isArray(desired_job_title_options) &&
                    desired_job_title_options.length > 0
                      ? desired_job_title_options?.map(item => {
                          return {
                            ...item,
                            key: item.id,
                            label: item.job_title,
                            title: item.job_title,
                            desired_job_title: item.job_title,
                          };
                        })
                      : []
                  }
                  placeholder='Example: Software Developer'
                />,
              )}
            </Box>
          </Stack>
          <Stack direction={'row'} justifyContent={'center'} mt={3}>
            <Button
              loading={request}
              disabled={request}
              variant='contained'
              sx={{
                borderRadius: '18px',
                background:
                  'linear-gradient(180deg, rgba(120,78,229,1) 0%, rgba(90,34,170,1) 100%)',
              }}
              onClick={handleSubmit(onSubmit)}
              // endIcon={<img src={goAIButtonIcon} />}
            >
              {'Submit'}
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};

export default AIModal;

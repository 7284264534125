import * as TYPES from './constants';
import {API} from 'config';
import {
  clearEventDetails,
  receiveEventDetails,
  requestEventDetails,
  requestEventDetailsError,
  requestEventSignUp,
  receiveEventSignUp,
  requestEventSignUpError,
  requestEventWithdraw,
  receiveEventWithdraw,
  requestEventWithdrawError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {
  request: false,
  data: {},
  error: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CLEAR_EVENT_DETAILS:
      return initialState;
    case TYPES.REQUEST_EVENT_DETAILS:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECEIVE_EVENT_DETAILS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_EVENT_DETAILS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const clearEventData = () => (dispatch, getState) =>
  dispatch(clearEventDetails());

export const fetchEventDetails = event_id => (dispatch, getState) => {
  const endPoint = `${API.srm.upcoming_events}?event_id=${event_id}`;
  dispatch(requestEventDetails());
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(receiveEventDetails(res));
        return;
      }
      dispatch(requestEventDetailsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestEventDetailsError(err));
  }
};

export const signUpForEvent = data => (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      dispatch(requestEventSignUp());
      return services
        .createUpdateRecord(token, API.srm.event_signup, data, 'POST')
        .then(async res => {
          await dispatch(receiveEventSignUp(res));
          return res;
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestEventSignUpError(err));
  }
};

export const withdrawEvent = (event_uuid, cb) => (dispatch, getState) => {
  try {
    const endPoint = `${API.srm.event_signup}/${event_uuid}`;
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      dispatch(requestEventWithdraw());
      return services.deleteRecord(token, endPoint).then(async res => {
        await dispatch(receiveEventWithdraw(res));
        if (cb) {
          cb(res);
        }
        return res;
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestEventWithdrawError(err));
  }
};

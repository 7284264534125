import * as TYPES from './constants';

// Event List
export const requestEventList = () => ({
  type: TYPES.REQUEST_EVENT_LIST,
});
export const recieveEventList = json => ({
  type: TYPES.RECIEVE_EVENT_LIST,
  payload: json,
});
export const requestEventListError = err => ({
  type: TYPES.REQUEST_EVENT_LIST_ERROR,
  err,
});
export const clearEventList = () => ({
  type: TYPES.CLEAR_EVENT_LIST,
});
export const recieveAllEventsList = json => ({
  type: TYPES.RECIEVE_ALL_EVENTS_LIST,
  payload: json,
});

// Event Details
export const requestEventDetails = () => ({
  type: TYPES.REQUEST_EVENT_DETAILS,
});
export const receiveEventDetails = json => ({
  type: TYPES.RECEIVE_EVENT_DETAILS,
  payload: json,
});
export const requestEventDetailsError = err => ({
  type: TYPES.REQUEST_EVENT_DETAILS_ERROR,
  err,
});
export const clearEventDetails = () => ({
  type: TYPES.CLEAR_EVENT_DETAILS,
});

// My Events
export const requestMyEvents = () => ({
  type: TYPES.REQUEST_MY_EVENTS,
});
export const recieveMyEvents = (json, noAppend) => ({
  type: TYPES.RECIEVE_MY_EVENTS,
  payload: {...json, noAppend: noAppend},
});
export const requestMyEventsError = err => ({
  type: TYPES.REQUEST_MY_EVENTS_ERROR,
  err,
});
export const clearMyEvents = () => ({
  type: TYPES.CLEAR_MY_EVENTS,
});

export const removeMyEvent = eventId => ({
  type: TYPES.REMOVE_MY_EVENT,
  eventId: eventId,
});

// Student EventSignUp Status
export const requestStudentEventSignUpStatus = () => ({
  type: TYPES.REQUEST_STUDENT_EVENT_SIGN_UP_STATUS,
});
export const receiveStudentEventSignUpStatus = json => ({
  type: TYPES.RECEIVE_STUDENT_EVENT_SIGN_UP_STATUS,
  payload: json,
});
export const requestStudentEventSignUpStatusError = err => ({
  type: TYPES.REQUEST_STUDENT_EVENT_SIGN_UP_STATUS_ERROR,
  err,
});

// Event SignUp
export const requestEventSignUp = () => ({
  type: TYPES.REQUEST_EVENT_SIGN_UP,
});
export const receiveEventSignUp = json => ({
  type: TYPES.RECEIVE_EVENT_SIGN_UP,
  payload: json,
});
export const requestEventSignUpError = err => ({
  type: TYPES.REQUEST_EVENT_SIGN_UP_ERROR,
  err,
});

// Event Withdraw
export const requestEventWithdraw = () => ({
  type: TYPES.REQUEST_EVENT_WITHDRAW,
});
export const receiveEventWithdraw = json => ({
  type: TYPES.RECEIVE_EVENT_WITHDRAW,
  payload: json,
});
export const requestEventWithdrawError = err => ({
  type: TYPES.REQUEST_EVENT_WITHDRAW_ERROR,
  err,
});

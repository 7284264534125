import PropTypes from 'prop-types';
import {Chip, Stack} from '@mui/material';
import {RequestErrorLoader} from 'mui-core';

const SkillsChip = ({data: {data: chipData}, data, color, backgroundColor}) => (
  <RequestErrorLoader hideEmpty body={data}>
    <Stack direction='row' flexWrap='wrap'>
      {chipData &&
        Array.isArray(chipData) &&
        chipData.length > 0 &&
        chipData.map(label => {
          return (
            <Chip
              label={label}
              sx={{
                backgroundColor: backgroundColor,
                color: color,
                mr: 1,
                mb: 1,
              }}
            />
          );
        })}
    </Stack>
  </RequestErrorLoader>
);

SkillsChip.propTypes = {
  data: PropTypes.array,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};
export default SkillsChip;

import React from 'react';
import {Text, View} from '@react-pdf/renderer';

import {formatViewDate} from 'core/utils';

import List, {Item} from './List';
import Divider from './Divider';

export default ({styles, studentProfile}) => {
  const {work_exp = []} = studentProfile;
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text break style={styles.headerText}>
          Work Experience
        </Text>
      </View>
      <View style={styles.content}>
        {!work_exp ||
          !Array.isArray(work_exp) ||
          (work_exp?.length === 0 && (
            <Text style={styles.value}>No work experience added.</Text>
          ))}
        {work_exp &&
          Array.isArray(work_exp) &&
          work_exp?.length > 0 &&
          work_exp.map((exp, exp_idx) => {
            const Responsibilities =
              work_exp?.job_responsibilities &&
              Array.isArray(exp?.job_responsibilities) &&
              work_exp?.job_responsibilities?.length > 0 &&
              work_exp.job_responsibilities.filter(
                role => typeof role.job_responsibilities === 'string',
              );
            return (
              <View key={`work-exp-${exp_idx}`} style={styles.list}>
                {exp?.company_name && exp?.job_location && (
                  <View break style={styles.inline}>
                    <Text style={styles.boldLabel}>
                      {exp?.company_name}
                      {' - '}
                      {exp?.job_location}
                    </Text>
                  </View>
                )}
                {exp?.start_date && (exp?.is_present || exp?.end_date) && (
                  <View style={styles.inline}>
                    <Text style={styles.boldLabel}>
                      {formatViewDate(exp?.start_date)}
                      {' - '}
                      {exp?.is_present
                        ? 'Present'
                        : formatViewDate(exp?.end_date)}
                    </Text>
                  </View>
                )}
                {exp?.job_title && (
                  <View style={styles.inline}>
                    <Text style={styles.boldLabel}>{exp?.job_title}</Text>
                  </View>
                )}
                {Responsibilities &&
                  Array.isArray(Responsibilities) &&
                  Responsibilities?.length > 0 && (
                    <List>
                      {Responsibilities.map((role, id) => (
                        <Item key={id}>{role.job_responsibilities}</Item>
                      ))}
                    </List>
                  )}
                {exp_idx !== work_exp?.length - 1 ? <Divider /> : null}
              </View>
            );
          })}
      </View>
    </View>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import {IconButton} from 'mui-core';
import {BASE_URL} from 'assets/images';
import {
  Box,
  Stack,
  styled,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';

const Image = styled('img')(() => ({
  width: '100%',
  objectFit: 'cover',
  borderRadius: '12px',
}));

const LayoutStaticBanner = ({
  padding = '0px',
  button = '',
  buttonAction,
  buttonEndIcon,
  sectionPadding,
  maxWidth = 'lg',
  imageFirst = false,
  gap = {xs: 5, md: 0},
  backgroundColor = '',
  disableGutters = false,
  imageWidth = {xs: '100%', md: '50%'},
  textContentWidth = {xs: '100%', md: '50%'},
  detail: {
    heading = '',
    imgpath = '',
    subHeading = '',
    headingColor = 'text.primary',
    descriptionColor = 'text.primary',
  },
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        background: backgroundColor || theme.palette.background.main,
        width: '100%',
      }}>
      <Container maxWidth={maxWidth} disableGutters={disableGutters}>
        <Stack
          width='100%'
          gap={{...gap}}
          alignItems='center'
          paddingY={{...sectionPadding}}
          direction={isMobile ? 'column' : imageFirst ? 'row-reverse' : 'row'}>
          <Stack
            gap={{xs: 2, md: '30px'}}
            width={{...textContentWidth}}
            padding={isMobile ? 'initial' : imageFirst && padding}>
            {heading && (
              <Typography
                component='h2'
                color={headingColor}
                variant='header-md-bold'
                textTransform='capitalize'
                dangerouslySetInnerHTML={{__html: heading}}
              />
            )}
            {subHeading && (
              <Typography
                component='p'
                color={descriptionColor}
                variant='text-lg-regular'
                dangerouslySetInnerHTML={{__html: subHeading}}
              />
            )}
            {button && (
              <Box>
                <IconButton
                  label={button}
                  sx={{py: 1, px: 6}}
                  variant='contained'
                  onClick={buttonAction}
                  endIcon={buttonEndIcon}
                />
              </Box>
            )}
          </Stack>
          {imgpath && (
            <Box width={{...imageWidth}} display='flex' alignItems='flex-start'>
              <Image alt={heading} src={`${BASE_URL}${imgpath}`} />
            </Box>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

LayoutStaticBanner.propTypes = {
  button: PropTypes.string,
  imageFirst: PropTypes.bool,
  buttonAction: PropTypes.func,
  buttonEndIcon: PropTypes.element,
  backgroundColor: PropTypes.string,
  detail: PropTypes.shape({
    heading: PropTypes.string,
    imgpath: PropTypes.string,
    subHeading: PropTypes.string,
    titleImage: PropTypes.string,
    headingColor: PropTypes.string,
    descriptionColor: PropTypes.string,
  }),
};

export default LayoutStaticBanner;

import {Stack} from '@mui/material';
import CareerInterest from './CareerInterest';
import CareerFavorites from './CareerFavorites';
// import CareerTaskSurvey from './CareerTaskSurvey';
import FitAssessmentSurvey from './FitAssessmentSurvey';

const Career = () => {
  return (
    <Stack gap={2}>
      <CareerFavorites />
      <CareerInterest />
      {/* For future referance */}
      {/* <CareerTaskSurvey /> */}
      <FitAssessmentSurvey />
    </Stack>
  );
};

export default Career;

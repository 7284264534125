import * as TYPES from './constants';

// Path Details
export const requestPathDetails = () => ({
  type: TYPES.PATH_DETAILS_REQUEST,
});

export const recievePathDetails = json => ({
  type: TYPES.PATH_DETAILS_SUCCESS,
  payload: json,
});

export const requestPathDetailsError = err => ({
  type: TYPES.PATH_DETAILS_ERROR,
  err,
});

// Occupations
export const requestOccupations = () => ({
  type: TYPES.OCCUPATIONS_REQUEST,
});

export const recieveOccupations = json => ({
  type: TYPES.OCCUPATIONS_SUCCESS,
  payload: json,
});

export const recieveAllOccupations = json => ({
  type: TYPES.OCCUPATIONS_ALL_SUCCESS,
  payload: json,
});

export const requestOccupationsError = err => ({
  type: TYPES.OCCUPATIONS_ERROR,
  err,
});
export const clearOccupations = () => ({
  type: TYPES.OCCUPATIONS_CLEAR,
});
export const clearAllOccupations = () => ({
  type: TYPES.OCCUPATIONS_ALL_CLEAR,
});

// My Active Paths
export const requestActivePaths = () => ({
  type: TYPES.ACTIVE_PATHS_REQUEST,
});

export const recieveActivePaths = json => ({
  type: TYPES.ACTIVE_PATHS_SUCCESS,
  payload: json,
});

export const requestActivePathsError = err => ({
  type: TYPES.ACTIVE_PATHS_ERROR,
  err,
});

// My Active Path Data
export const requestActivePathData = () => ({
  type: TYPES.ACTIVE_PATH_DATA_REQUEST,
});

export const recieveActivePathData = json => ({
  type: TYPES.ACTIVE_PATH_DATA_SUCCESS,
  payload: json,
});

export const requestActivePathDataError = err => ({
  type: TYPES.ACTIVE_PATH_DATA_ERROR,
  err,
});

// Delete Active Path
export const requestDeleteActivePath = () => ({
  type: TYPES.DELETE_ACTIVE_PATH_REQUEST,
});

export const recieveDeleteActivePath = json => ({
  type: TYPES.DELETE_ACTIVE_PATH_SUCCESS,
  payload: json,
});

export const requestDeleteActivePathError = err => ({
  type: TYPES.DELETE_ACTIVE_PATH_ERROR,
  err,
});

// Courses
export const requestCourses = () => ({
  type: TYPES.COURSES_REQUEST,
});

export const recieveCourses = json => ({
  type: TYPES.COURSES_SUCCESS,
  payload: json,
});

export const requestCoursesError = err => ({
  type: TYPES.COURSES_ERROR,
  err,
});

// My Plan
export const requestMyPlan = () => ({
  type: TYPES.MY_PLAN_REQUEST,
});

export const recieveMyPlan = json => ({
  type: TYPES.MY_PLAN_SUCCESS,
  payload: json,
});

export const requestMyPlanError = err => ({
  type: TYPES.MY_PLAN_ERROR,
  err,
});
export const clearMyPlan = () => ({
  type: TYPES.MY_PLAN_CLEAR,
});

// Recommended Plan
export const requestRecommendedPlan = () => ({
  type: TYPES.RECOMMENDED_PLAN_REQUEST,
});

export const recieveRecommendedPlan = json => ({
  type: TYPES.RECOMMENDED_PLAN_SUCCESS,
  payload: json,
});

export const requestRecommendedPlanError = err => ({
  type: TYPES.RECOMMENDED_PLAN_ERROR,
  err,
});

export const clearRecommendedPlan = () => ({
  type: TYPES.CLEAR_RECOMMENDED_PLAN,
});

// GenEd Options
export const requestGenEdOptions = () => ({
  type: TYPES.GEN_ED_OPTIONS_REQUEST,
});

export const recieveGenEdOptions = json => ({
  type: TYPES.GEN_ED_OPTIONS_SUCCESS,
  payload: json,
});

export const requestGenEdOptionsError = err => ({
  type: TYPES.GEN_ED_OPTIONS_ERROR,
  err,
});
export const clearGenEdOptions = () => ({
  type: TYPES.CLEAR_GEN_ED_OPTIONS,
});

// Compare Paths
export const addPathToCompareRequest = () => ({
  type: TYPES.ADD_COMPARE_PATH_REQUEST,
});

export const addPathToCompareError = () => ({
  type: TYPES.ADD_COMPARE_PATH_ERROR,
});

export const addPathToCompareSuccess = json => ({
  type: TYPES.ADD_COMPARE_PATH_SUCCESS,
  payload: json,
});

export const removeComparePath = json => ({
  type: TYPES.REMOVE_COMPARE_PATH,
  payload: json,
});

export const removeAllComparePaths = () => ({
  type: TYPES.REMOVE_ALL_COMPARE_PATH,
});

// Get Saved Comparison Details
export const requestSavedComparePaths = json => ({
  type: TYPES.SAVED_COMPARISON_PATHS_REQUEST,
  payload: json,
});

export const receiveSavedComparePaths = json => ({
  type: TYPES.SAVED_COMPARISON_PATHS_SUCCESS,
  payload: json,
});

export const requestSavedComparePathsError = () => ({
  type: TYPES.SAVED_COMPARISON_PATHS_ERROR,
});

// Get Compare Paths List
export const requestComparePathsList = json => ({
  type: TYPES.COMPARE_PATHS_LIST_REQUEST,
  payload: json,
});

export const receiveComparePathsList = json => ({
  type: TYPES.COMPARE_PATHS_LIST_SUCCESS,
  payload: json,
});

export const requestComparePathsListError = () => ({
  type: TYPES.COMPARE_PATHS_LIST_ERROR,
});

// Delete Compared Path
export const requestDeleteComparedPath = () => ({
  type: TYPES.DELETE_COMPARED_PATH_REQUEST,
});

export const receiveDeleteComparedPath = json => ({
  type: TYPES.DELETE_COMPARED_PATH_SUCCESS,
  payload: json,
});

export const requestDeleteComparedPathError = err => ({
  type: TYPES.DELETE_COMPARED_PATH_ERROR,
  payload: err,
});

// Save Comparison Paths
export const requestSaveComparisonPaths = () => ({
  type: TYPES.SAVE_COMPARISON_PATHS_REQUEST,
});

export const recieveSaveComparisonPaths = json => ({
  type: TYPES.SAVE_COMPARISON_PATHS_SUCCESS,
  payload: json,
});

export const requestSaveComparisonPathsError = err => ({
  type: TYPES.SAVE_COMPARISON_PATHS_ERROR,
  payload: err,
});

// onboard types

// Save Comparison Paths
export const requestOnboardTypes = () => ({
  type: TYPES.ONBOARD_TYPE_REQUEST,
});

export const recieveOnboardTypes = json => ({
  type: TYPES.ONBOARD_TYPE_SUCCESS,
  payload: json,
});

export const onboardTypesErr = err => ({
  type: TYPES.ONBOARD_TYPE_ERROR,
  err,
});

export const clearOnboardTypes = () => ({
  type: TYPES.ONBOARD_TYPE_CLEAR,
});

// STUDENT_ONBOARD

export const requestStudentOnboard = () => ({
  type: TYPES.STUDENT_ONBOARD_REQUEST,
});
export const recieveStudentOnboard = res => ({
  type: TYPES.STUDENT_ONBOARD_SUCCESS,
  payload: res,
});
export const studentOnboardErr = () => ({
  type: TYPES.STUDENT_ONBOARD_ERR,
});
export const studentOnboardClear = () => ({
  type: TYPES.STUDENT_ONBOARD_CLEAR,
});

// SAVE PATH

export const requestSavePath = () => ({
  type: TYPES.REQUEST_SAVE_PATH_POST,
});
export const recieveSavePath = res => ({
  type: TYPES.RECIEVE_SAVE_PATH_POST,
  payload: res,
});
export const savePathError = () => ({
  type: TYPES.SAVE_PATH_POST_ERROR,
});
export const requestGetSavePath = () => ({
  type: TYPES.REQUEST_SAVE_PATH_GET,
});
export const recieveGetSavePath = res => ({
  type: TYPES.RECIEVE_SAVE_PATH_GET,
  payload: res,
});
export const savePathGetError = () => ({
  type: TYPES.SAVE_PATH_GET_ERROR,
});
export const requestRemoveSavePath = () => ({
  type: TYPES.REQUEST_SAVE_PATH_REMOVE,
});
export const recieveRemoveSavePath = res => ({
  type: TYPES.RECIEVE_SAVE_PATH_REMOVE,
  payload: res,
});
export const savePathRemoveGetError = () => ({
  type: TYPES.SAVE_PATH_REMOVE_ERROR,
});
export const clearSavedPaths = () => ({
  type: TYPES.CLEAR_SAVED_PATHS,
});

// PATHWAY OPPORTUNITIES
export const requestPathwayOpportunity = () => ({
  type: TYPES.PATHWAY_OPPORTUNITY_REQUEST,
});
export const recievePathwayOpportunity = json => ({
  type: TYPES.PATHWAY_OPPORTUNITY_RECEIVE,
  payload: json,
});
export const requestPathwayOpportunityError = err => ({
  type: TYPES.PATHWAY_OPPORTUNITY_ERROR,
  err,
});
export const resetPathwayOpportunity = () => ({
  type: TYPES.RESET_PATHWAY_OPPROTUNITY,
});
export const recieveAllPathwayOpportunities = json => ({
  type: TYPES.PATHWAY_OPPORTUNITY_LIST_RECEIVE,
  payload: json,
});

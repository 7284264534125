import React from 'react';
import {Accordion} from 'mui-core';
import {useHistory} from 'react-router-dom';
import {SurveyResultPage} from 'app/modules/go-survey/components';

const CareerInterestResults = ({
  title = '',
  surveyResults,
  description = '',
  isDashboard = true,
}) => {
  const history = useHistory();

  return (
    <Accordion
      header={title}
      defaultOpen={true}
      outlined={!isDashboard}
      collpsable={isDashboard}
      description={description}>
      <SurveyResultPage
        isDashboard={isDashboard}
        surveyResults={surveyResults}
        onModifySurvey={() => history.push('/go-survey?modify=true')}
      />
    </Accordion>
  );
};

export default CareerInterestResults;

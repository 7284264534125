import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const StripedBriefCase = props => {
  return (
    <SvgIcon
      {...props}
      viewBox='0 0 37 36'
      fontSize='inherit'
      style={{fontSize: props.fontSize || '1em'}}>
      <g id='Education / award-03'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='37'
          height='36'
          viewBox='0 0 37 36'
          fill='none'>
          <path
            d='M12.5469 31.5V10.5C12.5469 9.10504 12.5469 8.40756 12.7002 7.83531C13.1163 6.2824 14.3293 5.06944 15.8822 4.65333C16.4544 4.5 17.1519 4.5 18.5469 4.5C19.9418 4.5 20.6393 4.5 21.2116 4.65333C22.7645 5.06944 23.9774 6.2824 24.3935 7.83531C24.5469 8.40756 24.5469 9.10504 24.5469 10.5V31.5M8.34688 31.5H28.7469C30.427 31.5 31.2671 31.5 31.9088 31.173C32.4733 30.8854 32.9323 30.4265 33.2199 29.862C33.5469 29.2202 33.5469 28.3802 33.5469 26.7V15.3C33.5469 13.6198 33.5469 12.7798 33.2199 12.138C32.9323 11.5735 32.4733 11.1146 31.9088 10.827C31.2671 10.5 30.427 10.5 28.7469 10.5H8.34687C6.66672 10.5 5.82664 10.5 5.1849 10.827C4.62042 11.1146 4.16148 11.5735 3.87386 12.138C3.54688 12.7798 3.54688 13.6198 3.54688 15.3V26.7C3.54688 28.3802 3.54688 29.2202 3.87386 29.862C4.16148 30.4265 4.62042 30.8854 5.1849 31.173C5.82664 31.5 6.66672 31.5 8.34688 31.5Z'
            stroke='url(#paint0_linear_28913_101526)'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <defs>
            <linearGradient
              id='paint0_linear_28913_101526'
              x1='33.5469'
              y1='18'
              x2='3.54688'
              y2='18'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color={props.startColor || '#000'} />
              <stop offset='0.76' stop-color={props.endColor || '#000'} />
            </linearGradient>
          </defs>
        </svg>
      </g>
    </SvgIcon>
  );
};

export default StripedBriefCase;

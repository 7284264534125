import {getGoBanner} from 'mui-core/utils';
import LazyImage from 'mui-core/LazyImage';
import {styled} from '@mui/material/styles';
import {Box, useTheme, useMediaQuery} from '@mui/material';
import {
  noCollageLogo,
  defaultbannerprofile,
  defaultExternalJobBanner,
} from 'assets/images';

const BannerContainer = styled(Box)(({theme}) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  img: {
    width: '100%',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '150px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '275px',
    },
  },
}));

const LogoContainer = styled(Box)(({theme}) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.paper,
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  bottom: 0,
  [theme.breakpoints.down('sm')]: {
    height: '60px',
    width: '150px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '420px',
    height: '100px',
  },
  img: {
    width: 'auto',
    height: '100%',
    maxWidth: '80%',
    padding: '4px 0px',
    borderRadius: '0px',
    objectFit: 'contain',
  },
}));

const CompaniesBanner = ({
  jobType,
  bannerImage,
  bannerLogoImage,
  bannerImageDetails,
}) => {
  const theme = useTheme();
  const {data, request} = bannerImageDetails || {};
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const {bp_details} = data || {};
  if (bp_details && Array.isArray(bp_details) && bp_details.length) {
    bannerImage = bp_details[0].banner_cloudinary;
    bannerLogoImage = bp_details[0].logo_cloudinary;
  }

  return (
    !request && (
      <BannerContainer>
        {jobType === 'board' ? (
          <LazyImage alt='external-job-banner' src={defaultExternalJobBanner} />
        ) : (
          <>
            <LazyImage
              alt='banner'
              defaultImage={defaultbannerprofile}
              src={getGoBanner(bannerImage, {
                quality: 80,
                e_sharpen: 100,
                height: isMobileView ? 150 : 250,
                width: isMobileView ? window.screen.width : 1250,
              })}
            />
            <LogoContainer>
              <LazyImage
                alt='bp_logo'
                imageType='logo'
                sx={{objectFit: 'contain'}}
                src={bannerLogoImage}
                defaultImage={noCollageLogo}
              />
            </LogoContainer>
          </>
        )}
      </BannerContainer>
    )
  );
};

export default CompaniesBanner;

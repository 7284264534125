import {employerVideoBanner} from 'assets/images';
import {ErrorBoundary, VideoBanner} from 'mui-core';
import {styled, Stack, Typography, Box} from '@mui/material';
import {ForEmployer} from 'data/partner.json';
const {videoBanner} = ForEmployer;

const {heading, subHeading} = videoBanner || {};

const BannerTitle = styled(Typography)(({theme}) => ({
  '& span': {
    fontSize: '19px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));

const EmployerVideoBanner = () => (
  <ErrorBoundary nameOfComponent='for-employer-videoBanner'>
    <Box sx={{mt: {xs: 0, md: -8}}}>
      <VideoBanner bannerURL={employerVideoBanner}>
        <Stack gap={{xs: 2, md: 5}}>
          <BannerTitle
            color='white'
            component='h1'
            variant='header-lg-bold'
            width={{xs: '100%', sm: '70%', md: '62%', lg: '80%'}}
            dangerouslySetInnerHTML={{
              __html: heading,
            }}
          />
          <Typography
            color='white'
            component='p'
            variant='text-xl-regular'
            width={{xs: '100%', sm: '60%', md: '50%', lg: '72%'}}
            dangerouslySetInnerHTML={{
              __html: subHeading,
            }}
          />
        </Stack>
      </VideoBanner>
    </Box>
  </ErrorBoundary>
);

export default EmployerVideoBanner;

import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import {Button, Grid} from 'mui-core';
import Stack from '@mui/material/Stack';
import {createField} from 'mui-core/Form';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import OutlinedInput from '@mui/material/OutlinedInput';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import profileData, {awardsData} from 'data/settings-profile.json';
const {itemType, itemName, addAwardsBtn, additionalInformation} =
  awardsData || {};

const AddAwards = ({
  edit,
  onSubmit,
  onCancel,
  firstTime,
  awardTypes,
  data: {item_type, item_title, comments} = {},
}) => {
  const {data: awardTypeData} = awardTypes || {};
  const form = useForm({
    defaultValues: {
      item_type: item_type || '',
      item_title: item_title || '',
      additional_info: comments?.sort(
        (a, b) => a.display_order - b.display_order,
      ) || [{additional_info: ''}],
    },
  });
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = form;
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'additional_info',
  });

  const onFinish = (data, e) => {
    if (onSubmit && isEmpty(errors)) {
      onSubmit(data);
    }
  };

  return (
    <Box>
      <Stack>
        {!edit && !firstTime && (
          <Typography
            variant='h5'
            fontSize={18}
            fontWeight='600'
            color='text.primary'
            //fontFamily='fontFamilyBold'
            title={addAwardsBtn}>
            {addAwardsBtn}
          </Typography>
        )}
        <Box
          mx='auto'
          my={2}
          sx={{width: {md: '60%', sm: '60%', lg: '60%', xs: '100%'}}}>
          <form onSubmit={handleSubmit(onFinish)}>
            <Grid
              container
              spacing={4}
              display='flex'
              alignItems='center'
              flexDirection='column'
              justifyContent='center'>
              <Grid item xs={12}>
                {createField('item_type', {
                  form,
                  defaultValue: item_type || '',
                  label: itemType.label,
                  rules: {
                    required: [true, itemType.requiredMsg],
                  },
                })(
                  <Select
                    id='award-item-type'
                    placeholder={itemType.placeholder}>
                    {Array.isArray(awardTypeData) &&
                      awardTypeData.map(type => (
                        <MenuItem
                          key={type.item_type_uuid}
                          value={type.item_type_uuid}>
                          {type.item_type}
                        </MenuItem>
                      ))}
                  </Select>,
                )}
              </Grid>
              <Grid item xs={12}>
                {createField('item_title', {
                  form,
                  defaultValue: item_title || '',
                  label: itemName.label,
                  rules: {
                    required: [true, itemName.requiredMsg],
                    maxLength: [60, itemName.maxLengthMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='award-title/name'
                    label={itemName.label}
                    placeholder={itemName.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12} pt={2}>
                <Stack direction='column' spacing={4}>
                  {fields.map((item, index) => {
                    let currentErrorObject = {};
                    errors?.additional_info?.length &&
                      errors?.additional_info.forEach((errObj, idx) => {
                        if (idx === index) {
                          currentErrorObject = errObj;
                        }
                      });
                    return (
                      <Stack
                        spacing={4}
                        key={item.id}
                        direction='row'
                        alignItems='center'>
                        <Controller
                          control={control}
                          defaultValue={item.additional_info}
                          name={`additional_info.${index}.additional_info`}
                          rules={{
                            maxLength: {
                              value: 250,
                              message: additionalInformation.maxLengthMsg,
                            },
                          }}
                          render={({field}) => (
                            <TextField
                              fullWidth
                              {...field}
                              key={item.id}
                              placeholder={additionalInformation.placeholder}
                              helperText={
                                currentErrorObject?.additional_info?.message
                              }
                              label={additionalInformation.label}
                              error={currentErrorObject?.additional_info}
                            />
                          )}
                        />
                        <IconButton
                          py='auto'
                          onClick={() => {
                            remove(index);
                          }}>
                          <DeleteIcon
                            sx={{fontSize: {xs: 16, sm: 18, md: 20, lg: 24}}}
                            color='primary'
                          />
                        </IconButton>
                      </Stack>
                    );
                  })}
                  <Button
                    sx={{width: '150px', alignSelf: 'flex-end'}}
                    onClick={() => {
                      append({additional_info: ''});
                    }}>
                    + Add Role
                  </Button>
                </Stack>
              </Grid>
              <Grid item>
                {!firstTime && (
                  <Button variant='outlined' onClick={onCancel} sx={{mr: 2}}>
                    {profileData.cancelBtnTxt}
                  </Button>
                )}
                <Button variant='contained' type='submit' formNoValidate>
                  {edit ? profileData.saveBtnTxt : profileData.addBtnTxt}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default AddAwards;

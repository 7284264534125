import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {createField} from 'mui-core/Form';
import {Controller, useForm} from 'react-hook-form';
import {employOpportData} from 'data/occupation.json';
import {Button, LocationSearch as LocationSearchComponent} from 'mui-core';
import {fetchLocationSearch, getLocationSearch} from 'redux/modules/occupation';
import {
  Box,
  Stack,
  Select,
  Divider,
  Checkbox,
  MenuItem,
  useTheme,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
  FormControlLabel,
} from '@mui/material';
import SearchHistory from './SearchHistory';

const TypeOptions = [
  {
    title: employOpportData.employmentTypes.jobsTitle,
    value: 'job',
  },
  //   {
  //     title: 'Scholarships',
  //     value: 'scholarships',
  //   },
  {
    title: employOpportData.employmentTypes.apprenticeshipsTitle,
    value: 'apprenticeship',
  },
  {
    title: employOpportData.employmentTypes.internshipsTitle,
    value: 'internship',
  },
  // {
  //   title: 'Other',
  //   value: 'other',
  // },
];

const Filter = ({
  onBlur,
  onReset,
  onClear,
  filters = {},
  globalSearch,
  relatedSearch,
  onFilterSubmit,
  nearbyMSA = [],
  businessPartnerName,
  dashboardReferer,
}) => {
  const theme = useTheme();
  const form = useForm();
  const defaultViewCount = 5;
  const [hasFilterValue, setHasFilterValue] = useState();
  const [viewCount, setViewCount] = useState(defaultViewCount);
  const [remoteJob, setRemoteJob] = useState(filters.job_type);
  const {handleSubmit, reset, setValue, control, getValues} = form;

  useEffect(() => {
    if (!getValues('location')) {
      setValue('location', filters?.location);
    }
    if (!getValues('title')) {
      setValue('title', filters?.title);
    }
    if (!getValues('company')) {
      setValue('company', filters?.company);
    }
    if (globalSearch) {
      setValue('title', filters?.title);
      setValue('search', filters?.search);
      setValue('company', filters?.company);
      setValue('location', filters?.location);
      setValue('job_type', filters?.job_type || '');
      if (!filters.job_type) {
        setRemoteJob(false);
      }
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = data => {
    const {title, search, location} = data;
    if (title || search || location) {
      setHasFilterValue(true);
      // Commenting out because it rerender multiple times when URL is changing as it is not retaining filters values when re-renderning in companies page opportunity tab.
      // if (search) {
      //   const url = history.location.pathname + history.location.search + '&';
      //   let newUrl = url.replace(/(job_type=).*?(&)/, '$1' + search + '$2');
      //   newUrl = newUrl.substring(0, newUrl.length - 1);
      //   history.replace(newUrl);
      // }
      // data['location'] = getValues('location')
      //   ? getValues('location')
      //   : filters?.location;
      if (remoteJob) data['job_type'] = 'remote';
      if (onFilterSubmit) onFilterSubmit(data);
    } else {
      setHasFilterValue(false);
    }
  };
  const resetFilter = () => {
    setHasFilterValue(false);
    reset();
    setValue('location', null);
    setRemoteJob(false);
    onReset();
  };

  const onRemoteJobChange = e => {
    const v = e.target.checked;
    setRemoteJob(e.target.checked);
    const data = getValues();
    if (v) {
      data['job_type'] = 'remote';
      setHasFilterValue(true);
    } else {
      if (!(data.title || data.location)) {
        setHasFilterValue(false);
      }
      data['job_type'] = '';
    }
    if (onFilterSubmit) onFilterSubmit({...data});
  };

  const handleLocationChange = v => {
    let updatedParams = {...filters};
    updatedParams = {
      ...filters,
      search: getValues('search') || '',
      title: getValues('title') || '',
      company: getValues('company') || '',
      location: v?.location,
    };
    if (v?.location) {
      setHasFilterValue(true);
    } else {
      setHasFilterValue(false);
    }
    setValue('location', v?.location);
    onFilterSubmit(updatedParams);
  };

  const onInputChange = value => {
    setValue('location', value.location);
  };

  const onRelatedSearchClick = val => {
    setValue('title', val);
    setHasFilterValue(true);
    onFilterSubmit({...filters, title: val});
  };

  return (
    <Box
      sx={
        // relatedSearch.length
        //   ? {
        //       maxHeight: '72vh',
        //       overflowY: 'auto',
        //       padding: '8px 8px 0 0',
        //     } :
        {}
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          {createField('search', {
            form,
            defaultValue: filters?.search || 'job',
          })(
            <FormControl fullWidth>
              <InputLabel id='Type-label' htmlFor='Type'>
                Type
              </InputLabel>
              <Controller
                name='search'
                control={control}
                defaultValue={filters?.search || ''}
                render={({field}) => (
                  <Select
                    {...field}
                    label='Type'
                    variant='outlined'
                    aria-label='Type'
                    inputProps={{id: 'Type'}}
                    placeholder='Select Opportunity Type'
                    defaultValue={filters?.search || 'job'}
                    onChange={e => setValue('search', e.target.value)}>
                    {TypeOptions.map(type => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.title}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>,
          )}
          {createField('title', {
            form,
            defaultValue: filters?.title || '',
          })(
            <FormControl fullWidth>
              <InputLabel id='Keyword-label' htmlFor='Keyword'>
                Keyword
              </InputLabel>
              <Controller
                name='title'
                control={control}
                defaultValue={filters?.title || ''}
                render={({field}) => (
                  <OutlinedInput
                    {...field}
                    label={'Keyword'}
                    inputProps={{id: 'Keyword'}}
                    placeholder='Enter keyword search here'
                  />
                )}
              />
            </FormControl>,
          )}
          {!businessPartnerName &&
            createField('company', {
              form,
              defaultValue: filters?.company || '',
            })(
              <FormControl fullWidth>
                <InputLabel id='Company-label' htmlFor='Company'>
                  Company
                </InputLabel>
                <Controller
                  name='company'
                  control={control}
                  defaultValue={filters?.company || ''}
                  render={({field}) => (
                    <OutlinedInput
                      {...field}
                      label='Company'
                      inputProps={{id: 'Company'}}
                      placeholder='Enter company name'
                    />
                  )}
                />
              </FormControl>,
            )}
          <Box>
            {createField('location', {
              form,
              defaultValue: getValues('location') || '',
            })(
              <LocationSearchComponent
                calledFromForm
                onBlur={onBlur}
                onClear={onClear}
                onEnterPress={v => {}}
                LocationOptions={nearbyMSA}
                onInputChange={onInputChange}
                onLocationChange={handleLocationChange}
                filters={{location: getValues('location')}}
              />,
            )}
          </Box>
          <FormControlLabel
            sx={{'& .MuiFormControl-root': {width: 'auto'}}}
            control={
              <Checkbox checked={remoteJob} onChange={onRemoteJobChange} />
            }
            label='Remote Opportunities'
          />
        </Stack>
        <Stack direction={'row'} justifyContent={'center'} my={3}>
          <Button
            size='large'
            type={'submit'}
            variant='contained'
            sx={{minWidth: 160}}>
            Search
          </Button>
        </Stack>
      </form>
      {hasFilterValue && (
        <Stack direction={'row'} justifyContent={'center'} my={3}>
          <Button size='large' onClick={resetFilter} sx={{minWidth: 160}}>
            Reset
          </Button>
        </Stack>
      )}
      {relatedSearch.length ? (
        <Stack gap={2}>
          <Divider />
          <Typography variant='body1'>
            New Search by - Related Careers
          </Typography>
          {relatedSearch.map((searchValue, idx) => (
            <>
              {idx < viewCount ? (
                <a key={searchValue}>
                  <Typography
                    variant='body1'
                    color='secondary.main'
                    onClick={() => onRelatedSearchClick(searchValue)}>
                    {searchValue}
                  </Typography>
                </a>
              ) : (
                ''
              )}
            </>
          ))}
          {relatedSearch.length > viewCount && (
            <a>
              <Typography
                variant='body1'
                color={
                  theme.palette.mode === 'light'
                    ? 'primary.dark'
                    : 'primary.light'
                }
                onClick={() => setViewCount(relatedSearch.length)}>
                Show more
              </Typography>
            </a>
          )}
          {relatedSearch.length === viewCount && (
            <a>
              <Typography
                variant='body1'
                color={
                  theme.palette.mode === 'light'
                    ? 'primary.dark'
                    : 'primary.light'
                }
                onClick={() => setViewCount(defaultViewCount)}>
                Show less
              </Typography>
            </a>
          )}
        </Stack>
      ) : null}
      {dashboardReferer && (
        <SearchHistory
          onSubmit={data => {
            reset(data);
            onFilterSubmit(data);
          }}
        />
      )}
    </Box>
  );
};

Filter.propTypes = {
  onBlur: PropTypes.func,
  onReset: PropTypes.func,
  onClear: PropTypes.func,
  filters: PropTypes.object,
  nearbyMSA: PropTypes.array,
  globalSearch: PropTypes.string,
  relatedSearch: PropTypes.array,
  onFilterSubmit: PropTypes.func,
  businessPartnerName: PropTypes.string,
};

const mapStateToProps = state => ({
  locations: getLocationSearch(state),
});
export default connect(mapStateToProps, {
  fetchLocationSearch,
})(Filter);

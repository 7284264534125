import {API} from 'config';
import {useState} from 'react';
import {PATTERN} from 'core/regex';
import {useSnackbar} from 'notistack';
import {Grid, Button} from 'mui-core';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {styled} from '@mui/material/styles';
import {Stack, OutlinedInput} from '@mui/material';
import {updateProfile} from 'redux/modules/profile';
import {portfolio} from 'data/dashboard-settings.json';

const {
  errorMsg,
  successMsg,
  submitBtnTxt,
  portfolioLabel,
  invalidInputMsg,
  portfolioPlaceholder,
  portfolioRequiredMsg,
} = portfolio || {};

const StyledStack = styled(Stack)(() => ({
  '& .MuiFormControl-root ': {
    width: '100%',
    maxWidth: '400px',
  },
}));

const AddPortfolio = ({profileData, profileDataRequest}) => {
  const form = useForm();
  const {handleSubmit} = form;
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {portfolio_url} = profileData || {};
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async values => {
    if (values?.portfolio_url !== portfolio_url) {
      const method = 'PATCH',
        endPoint = `${API.gps.student_profile}/${profileData?.student_profile_uuid}/`;
      setIsLoading(true);
      await dispatch(updateProfile(endPoint, method, values))
        .then(() => {
          enqueueSnackbar(successMsg, {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar(errorMsg, {variant: 'error'});
        });
      setIsLoading(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} mt={3}>
        <form onSubmit={handleSubmit(onFinish)}>
          <StyledStack
            gap={2}
            justifyContent='flex-start'
            direction={{xs: 'column', sm: 'row'}}>
            {createField('portfolio_url', {
              form,
              size: 'small',
              label: portfolioLabel,
              disabled: profileDataRequest,
              defaultValue: portfolio_url || '',
              rules: {
                required: [true, portfolioRequiredMsg],
                pattern: [PATTERN?.Website, invalidInputMsg],
              },
            })(
              <OutlinedInput
                sx={{maxWidth: 400}}
                label={portfolioLabel}
                placeholder={portfolioPlaceholder}
              />,
            )}
            <Button
              type='submit'
              formNoValidate
              variant='outlined'
              loading={isLoading}
              sx={{fontSize: {xs: '11px', sm: '14px'}}}>
              {submitBtnTxt}
            </Button>
          </StyledStack>
        </form>
      </Grid>
    </Grid>
  );
};

export default AddPortfolio;

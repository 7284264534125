import React from 'react';
import {View, Text} from '@react-pdf/renderer';

import Divider from './Divider';
import List, {Item} from './List';

export default ({styles, voluntary_exp}) => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text break style={styles.headerText}>
          Volunteer Experience
        </Text>
      </View>
      <View style={styles.content}>
        {voluntary_exp &&
        Array.isArray(voluntary_exp) &&
        voluntary_exp?.length > 0 ? (
          voluntary_exp.map((exp, idx) => {
            const Responsibilities =
              exp?.roles_responsibilities &&
              Array.isArray(exp?.roles_responsibilities) &&
              exp?.roles_responsibilities?.length > 0 &&
              exp.roles_responsibilities.filter(
                role => typeof role.roles_responsibilities === 'string',
              );
            return (
              <View key={'experience-profile-' + idx}>
                {exp?.name && (
                  <View break style={styles.inline}>
                    <Text style={styles.boldLabel}>
                      Experience: <Text style={styles.value}>{exp?.name}</Text>
                    </Text>
                  </View>
                )}
                {exp?.hours && (
                  <View style={styles.inline}>
                    <Text style={styles.boldLabel}>
                      Hours: <Text style={styles.value}>{exp?.hours}</Text>
                    </Text>
                  </View>
                )}
                {Responsibilities &&
                  Array.isArray(Responsibilities) &&
                  Responsibilities?.length > 0 && (
                    <>
                      <View style={styles.inline}>
                        <Text style={styles.boldLabel}>
                          Roles and Responsibilities:
                        </Text>
                      </View>
                      <List>
                        {Responsibilities.map((role, id) => (
                          <Item key={id}>{role.roles_responsibilities}</Item>
                        ))}
                      </List>
                    </>
                  )}
                {idx + 1 !== voluntary_exp?.length && <Divider />}
              </View>
            );
          })
        ) : (
          <Text style={styles.value}>N/A</Text>
        )}
      </View>
    </View>
  );
};

import {IMG_CLOUDINARY} from 'config';
import {uniq, isObject, isString} from 'lodash';
import {defaultBannerImage} from 'assets/images';

export const themeColorMode = (theme, lightColor, background) =>
  theme.palette.mode === 'light' ? lightColor : background;

export const extractSkillsfromCourses = data => {
  let Skills = [];
  if (data && Array.isArray(data) && data?.length) {
    data.map(d => {
      d?.courses &&
        Array.isArray(d.courses) &&
        d.courses?.length &&
        d.courses.forEach(course => {
          if (course?.skills_learned?.length) {
            Skills = [...Skills, ...course.skills_learned];
          }
        });
    });
  }
  return Skills;
};

export const combinedUniqueSkillsListForProgram = pathData => {
  let CombineSkills = [];
  if (pathData?.skills_learned?.length) {
    pathData?.skills_learned.forEach(skill => {
      CombineSkills = [...CombineSkills, skill.name];
    });
  }
  if (pathData?.terms?.length) {
    CombineSkills = [
      ...CombineSkills,
      ...extractSkillsfromCourses(pathData?.terms),
    ];
  }
  if (pathData?.segment?.length) {
    CombineSkills = [
      ...CombineSkills,
      ...extractSkillsfromCourses(pathData?.segment),
    ];
  }
  if (pathData?.segment_groups?.length) {
    pathData.segment_groups.forEach(seg_group => {
      CombineSkills = [
        ...CombineSkills,
        ...extractSkillsfromCourses(seg_group?.segments),
      ];
    });
  }
  if (pathData?.ge_path_details) {
    if (pathData?.ge_path_details?.segments) {
      CombineSkills = [
        ...CombineSkills,
        ...extractSkillsfromCourses(pathData.ge_path_details.segments),
      ];
    }
    if (pathData?.ge_path_details?.segment_groups?.length) {
      pathData?.ge_path_details?.segment_groups.forEach(seg_group => {
        CombineSkills = [
          ...CombineSkills,
          ...extractSkillsfromCourses(seg_group?.segments),
        ];
      });
    }
  }
  return uniq(CombineSkills);
};

export const skillsLearned = (pathData, showSkillsOnlyFromOverview = null) => {
  let CombineSkills = [];
  if (!showSkillsOnlyFromOverview) {
    CombineSkills = combinedUniqueSkillsListForProgram(pathData);
  }

  if (pathData?.skills_learned?.length && showSkillsOnlyFromOverview) {
    CombineSkills = pathData.skills_learned.map(sk => sk.name);
  }

  if (CombineSkills?.length) {
    const UNIQ_SKILLS = uniq(CombineSkills);
    return UNIQ_SKILLS;
  }

  return CombineSkills;
};

export const bytesToMegaBytes = (bytes, roundTo = 2) => {
  var converted = bytes / (1024 * 1024);
  return roundTo ? converted.toFixed(roundTo) : converted;
};

/**
 * ======================== Cloudinary Images ====================================================
 * Following finctions are used to get the transpromed images based on UI requirements:
 * a) getLogo ->  use to get the logo
 * b) getWhiteLogo ->  used to convert the logo colors into white
 * c) getCardImage -> used to get the card size image
 * d) getBanner ->  used to get banner image
 * e) getProfilePhoto ->  used to get profile photo
 * f) getCardImgSet ->  used to get the card size image set
 *
 *
 * dpr_${dpr},e_auto_brightness,e_sharpen:${e_sharpen}
 * Effects:
 * e_sharpen:{value} =  1-2000 (100)
 * e_brightness:{value} =   -99 - 100 (100)
 * e_vibrance:{value}  = -100 - 100 (20)
 * e_vectorize
 * e_transition
 * e_brightness_hsb
 * e_auto_brightness
 * e_auto_contrast
 * e_auto_color
 * e_auto_saturation
 *
 *
 * Quality:
 * q_{value} =  auto, 0-100
 *
 * DPR:- Modify the image scaling using the given Device Pixel Ratio.
 * dpr_{value} =  auto, 0.75, 1, 1.3, 1.5, 2.0, 3.0, 3.5, 4.0
 * ======================================================================================
 */

export const getLogo = (publicID, ext) =>
  `${IMG_CLOUDINARY}/q_100/d_defaultlogoimage_zot4ua.svg/${publicID}${
    ext ? `.${ext}` : ''
  }`;

export const getProfilePhoto = publicID =>
  publicID
    ? `${IMG_CLOUDINARY}/f_auto,e_auto_brightness/h_200,w_200,r_max,c_thumb,ar_1:1,e_sharpen:120,dpr_auto/d_profile_default_htkxlw.png/${publicID}.png`
    : null;

export const getGoBanner = (publicID, config) => {
  const {height = 250, width = 1250, quality = 60, dpr = '2.0'} = config || {};
  let configString = `c_fill,g_auto,h_${height},q_${quality},dpr_${dpr},w_${width}`;
  if (config && isObject(config)) {
    Object.keys(config).map(key => {
      if (
        key === 'e_brightness' ||
        key === 'e_auto_contrast' ||
        key === 'e_auto_color' ||
        key === 'e_auto_saturation'
      ) {
        const property = key.substring(2);
        if (config[key] === 'auto') {
          configString += `,e_auto_${property}`;
        } else {
          configString += `,e_${property}:${config[key]}`;
        }
      }
      if (key === 'e_sharpen') {
        configString += `,e_sharpen:${config[key]}`;
      }
      if (key === 'e_vibrance') {
        configString += `e_vibrance:${config[key]}`;
      }
    });
  }

  if (publicID) {
    return `${IMG_CLOUDINARY}/${configString}/y_-0.5/d_defaultbannerprofile_hnyuqa.jpg/${publicID}.jpg`;
  } else {
    return defaultBannerImage;
  }
};

export const extractFileExtension = filePath => {
  let extension = null;
  if (filePath && isString(filePath) && filePath.includes('.')) {
    extension = filePath.split('.').pop();
  }
  return extension;
};

export const getCloudinaryImage = (publicID, type = 'banner') => {
  const defaultImage =
    type === 'logo'
      ? 'd_no_collage_logo_sozbbx.png'
      : 'd_defaultbannerprofile_hnyuqa.jpg';
  return publicID
    ? encodeURI(`${IMG_CLOUDINARY}/${defaultImage}/${publicID}.jpg`)
    : defaultBannerImage;
};

export const externalLinkHandler = url => {
  const validationRule = new RegExp('^(http|https)://', 'i');
  const testURL = validationRule.test(url);
  if (!testURL) {
    const ExternalURL = 'https://' + url;
    window.open(ExternalURL, '_blank');
  } else {
    window.open(url, '_blank');
  }
};

export function isOnlyWhitespace(htmlContent) {
  // Create a temporary element to hold the HTML content
  var tempElement = document.createElement('div');
  tempElement.innerHTML = htmlContent;

  // Extract the text content from the element
  var textContent = tempElement.textContent || tempElement.innerText || '';

  // Trim the text content and check if it is empty
  return textContent.trim().length === 0;
}

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EditIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5136 0.880294C11.8311 -0.293431 13.9738 -0.293431 15.2913 0.880294C15.9306 1.44542 16.2828 2.19892 16.2828 3.00169C16.2828 3.80446 15.9306 4.55797 15.2913 5.12599L6.53153 12.9074C6.45 12.9827 6.33585 13.0233 6.22171 13.0233C6.10756 13.0233 5.99342 12.9798 5.91189 12.9074L2.38318 9.77163L1.92986 12.7509L4.25189 12.4726C4.49323 12.4437 4.715 12.5944 4.74761 12.8088C4.78022 13.0233 4.61063 13.2204 4.3693 13.2493L1.47328 13.5971C1.45371 13.6 1.43414 13.6 1.41458 13.6C1.30043 13.6 1.18629 13.5565 1.10475 13.4841C1.01018 13.4 0.961257 13.2783 0.980825 13.1566L1.62982 8.88481C1.64287 8.80077 1.68526 8.72252 1.75375 8.66166L10.5136 0.880294ZM12.1554 3.14371L10.6845 1.83667L2.6911 8.93988L4.16194 10.2469L12.1554 3.14371ZM6.22612 12.0785L4.7879 10.8005L12.7813 3.69727L14.2195 4.97532L6.22612 12.0785ZM11.3113 1.2889L14.8302 4.41594C15.1987 4.0189 15.4009 3.52623 15.4009 3.00167C15.4009 2.40757 15.1433 1.84823 14.6704 1.43091C14.1975 1.01359 13.5713 0.781738 12.9028 0.781738C12.3157 0.781738 11.758 0.96142 11.3113 1.2889Z'
      />
    </SvgIcon>
  );
};

export default EditIcon;

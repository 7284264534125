// hooks
import {useAuth} from 'core/hooks';
import {memo, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// redux
import {
  getOccupationRoles,
  saveOccupationRates,
  subscribeForOccupation,
  getSubscribedOccupation,
  unSubscribeForOccupation,
  fetchSubscribedOccupation,
} from 'redux/modules/occupation';
import {openLoginScreen} from 'redux/modules/auth';
// components
import {
  Button,
  DialogBox,
  LazyImage,
  WrappedText,
  ErrorBoundary,
  themeColorMode,
} from 'mui-core';
import {useSnackbar} from 'notistack';
import RateMyInterest from './RateMyInterest';
import {Box, Stack, Typography} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
// utils
import PropTypes from 'prop-types';
import {styled, useTheme} from '@mui/material/styles';
import occupationData from 'data/occupation.json';

const occupationVideoEndPoint =
  'https://cdn.careeronestop.org/OccVids/OccupationVideos/';

const {
  cancelTxt,
  yesTxt,
  alsoCalledTxt,
  careerSurveyTxt,
  interestSavedTxt,
  confirmModalTitle,
  confirmModalHeader,
} = occupationData;

const StyledAvatar = styled(Stack)(({theme}) => ({
  borderRadius: '50%',
  backgroundColor: themeColorMode(
    theme,
    '#D9D2F6',
    theme.palette.darkGray.main,
  ),
  '& img': {
    width: 186,
    height: 186,
    objectFit: 'cover',
    borderRadius: '50%',
    border: '1px double transparent',
    backgroundClip: 'content-box,border-box',
    backgroundImage:
      'linear-gradient(#fff,#fff),radial-gradient(circle at top left,#4abaed,#fb55e4)',
  },
}));

const CareerBanner = ({occupationDetails = {}}) => {
  const [token] = useAuth();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const occupationRoles = useSelector(getOccupationRoles);
  const subscribedOccupation = useSelector(getSubscribedOccupation);
  const [showRateOccupation, setShowRateOccupation] = useState(false);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const {
    subscribeRequest,
    request: getSuscribeRequest,
    data: subscribedOccupationList = [],
  } = subscribedOccupation || {};
  const {
    request: occupationDetailsRequest,
    data: {occupation_uuid, occupation_name, occupation_details} = {},
  } = occupationDetails || {};
  const {occupation_title = '', also_called = [], video_details = []} =
    occupation_details || {};
  const {VideoCode} = video_details && video_details[0] ? video_details[0] : {};

  const openRateOccupation = () => {
    if (token) {
      setShowRateOccupation(true);
    } else {
      dispatch(
        openLoginScreen({
          callback: async () => setShowRateOccupation(true),
        }),
      );
    }
  };

  const onSaveCareerInterest = (score, callback) => {
    if (occupation_uuid && score) {
      const data = {occupation_uuid, score};
      dispatch(
        saveOccupationRates(data, res => {
          enqueueSnackbar(interestSavedTxt, {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
          callback && callback(res);
          setShowRateOccupation(false);
        }),
      );
    }
  };

  const unSubscribeOccupation = () => {
    const {data: {occupation_uuid} = {}} = occupationDetails || {};
    const filteredOccupation =
      subscribedOccupationList?.find(
        i => i?.occupation_id === occupation_uuid,
      ) || {};
    if (filteredOccupation?.uuid) {
      dispatch(
        unSubscribeForOccupation(filteredOccupation.uuid, res => {
          if (res === 'success') {
            dispatch(fetchSubscribedOccupation());
            enqueueSnackbar(
              `Career '${occupation_name}' Unsubscribed successfully`,
              {
                variant: 'success',
                anchorOrigin: {horizontal: 'center', vertical: 'top'},
              },
            );
          }
          toggleUnsubscribeModal();
        }),
      );
    }
  };

  const subscribeOccupation = () => {
    const {data: {occupation_uuid, occupation_onnet, occupation_details} = {}} =
      occupationDetails || {};
    const {video_details} = occupation_details || {};
    const {VideoCode = ''} =
      video_details && video_details[0] ? video_details[0] : {};
    const body = {
      occupation_uuid,
      video_code: VideoCode,
      onnet: occupation_onnet,
    };
    dispatch(
      subscribeForOccupation(body, res => {
        if (res && res.error) {
          enqueueSnackbar(res.error, {
            variant: res.error.includes('already subscribed')
              ? 'warning'
              : 'error',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        } else if (res?.uuid) {
          enqueueSnackbar(
            `Career '${occupation_name}' Subscribed successfully`,
            {
              variant: 'success',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            },
          );
        }
        dispatch(fetchSubscribedOccupation());
      }),
    );
  };

  const onOccupationSubscribe = () => {
    if (token) {
      subscribeOccupation();
    } else {
      dispatch(
        openLoginScreen({
          callback: () => {
            subscribeOccupation();
          },
        }),
      );
    }
  };

  const toggleUnsubscribeModal = () =>
    setShowUnsubscribeModal(!showUnsubscribeModal);

  const isOccupationSubscribed = useCallback(() => {
    if (Array.isArray(subscribedOccupationList)) {
      const subscribedObject = subscribedOccupationList.find(
        i => i.occupation_id === occupation_uuid,
      );
      if (subscribedObject?.uuid) return true;
      return false;
    } else return false;
  }, [occupation_uuid, subscribedOccupationList]);

  const getUnsubscribeModalDescription = useCallback(() => {
    return `${confirmModalTitle.slice(
      0,
      confirmModalTitle.length - 1,
    )} '${occupation_name}' ?`;
  }, [occupation_name]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ErrorBoundary nameOfComponent='mod-career-occupation-banner'>
      <Stack
        p={2}
        spacing={4}
        alignItems='center'
        direction={{xs: 'column', md: 'row'}}>
        <StyledAvatar>
          {!occupationDetailsRequest && (
            <LazyImage
              alt='video-thumb'
              src={`${occupationVideoEndPoint}${VideoCode}.jpg`}
            />
          )}
        </StyledAvatar>
        <Stack flexGrow={1} spacing={2}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'>
            <Typography variant='h2' color='text.primary'>
              {occupation_title}
            </Typography>
            {isOccupationSubscribed() ? (
              <>
                <Button
                  size='large'
                  color='success'
                  variant='contained'
                  sx={{px: 8, py: 1}}
                  onClick={toggleUnsubscribeModal}
                  aria-describedby='unsubscribe-popper'
                  loading={
                    subscribeRequest ||
                    getSuscribeRequest ||
                    occupationDetailsRequest
                  }
                  disabled={
                    subscribeRequest ||
                    getSuscribeRequest ||
                    occupationDetailsRequest
                  }
                  startIcon={<FavoriteIcon />}>
                  Saved
                </Button>
                <DialogBox
                  openDialog={showUnsubscribeModal}
                  closeDialog={toggleUnsubscribeModal}
                  actions={
                    <>
                      <Button
                        variant='outlined'
                        onClick={toggleUnsubscribeModal}
                        sx={{minWidth: {xs: '80px', md: '168px'}}}>
                        {cancelTxt}
                      </Button>
                      <Button
                        variant='contained'
                        onClick={unSubscribeOccupation}
                        sx={{minWidth: {xs: '80px', md: '168px'}}}
                        loading={subscribeRequest || getSuscribeRequest}>
                        {yesTxt}
                      </Button>
                    </>
                  }>
                  <Stack spacing={1}>
                    <Typography
                      variant='h4'
                      textAlign='center'
                      color='text.primary'>
                      {confirmModalHeader}
                    </Typography>
                    <Typography
                      variant='body1'
                      textAlign='center'
                      color='text.primary'
                      dangerouslySetInnerHTML={{
                        __html: getUnsubscribeModalDescription(),
                      }}
                    />
                  </Stack>
                </DialogBox>
              </>
            ) : (
              <Button
                size='large'
                variant='outlined'
                sx={{
                  px: 8,
                  py: 1,
                  color:
                    theme.palette.mode === 'light'
                      ? 'primary.dark'
                      : 'primary.light',
                }}
                startIcon={<FavoriteIcon />}
                onClick={onOccupationSubscribe}
                loading={subscribeRequest || getSuscribeRequest}
                disabled={subscribeRequest || getSuscribeRequest}>
                Save
              </Button>
            )}
          </Stack>
          <Stack spacing={1} direction='row' alignItems='start'>
            <Typography
              width={100}
              variant='body1'
              fontWeight={500}
              color='text.primary'>
              {alsoCalledTxt}:
            </Typography>
            <WrappedText
              length={250}
              data={also_called}
              title={occupation_title}
            />
          </Stack>
          <Box>
            <Button uppercase variant='contained' onClick={openRateOccupation}>
              {careerSurveyTxt}
            </Button>
          </Box>
        </Stack>
      </Stack>

      <RateMyInterest
        careerRoles={occupationRoles}
        careerTitle={occupation_title}
        onSaveCareerInterest={onSaveCareerInterest}
        onCancel={() => setShowRateOccupation(false)}
        open={showRateOccupation || occupationRoles.requestSave}
      />
    </ErrorBoundary>
  );
};

CareerBanner.propTypes = {
  occupationDetails: PropTypes.object,
};

export default memo(CareerBanner);

import {isString} from 'lodash';
import {styled} from '@mui/material/styles';
import Tab, {tabClasses} from '@mui/material/Tab';
import {Box, useTheme, useMediaQuery} from '@mui/material';
import {default as MTabs, tabsClasses} from '@mui/material/Tabs';

const StyledTabs = styled(MTabs)(({theme, isMobile}) => ({
  [theme.breakpoints.only('xs')]: {
    height: 40,
    minHeight: 40,
  },
  [theme.breakpoints.only('sm')]: {
    height: 45,
    minHeight: 45,
  },
  [`& .${tabsClasses.indicator}`]: {
    height: '100%',
    borderRadius: 40,
    zIndex: 1,
  },
  [`& .${tabsClasses.flexContainer}`]: {
    position: 'relative',
    zIndex: 2,
    justifyContent: isMobile ? 'left' : 'center',
  },
}));

const StyledTab = styled(Tab)(({theme}) => ({
  borderRadius: 40,
  border: `1px solid ${
    theme.palette.mode === 'light'
      ? theme.palette.shadeyBlack.main
      : theme.palette.primary.light
  }`,
  marginRight: 24,
  minWidth: 250,
  fontWeight: 500,
  height: 60,
  fontSize: 16,
  textTransform: 'capitalize',
  color:
    theme.palette.mode === 'light'
      ? theme.palette.shadeyBlack.main
      : theme.palette.secondary.midGray,
  [theme.breakpoints.only('xs')]: {
    minWidth: 100,
    fontSize: 14,
    height: 40,
    minHeight: 40,
  },
  [theme.breakpoints.only('sm')]: {
    minWidth: 160,
    fontSize: 15,
    height: 45,
    minHeight: 45,
  },
  [`&.${tabClasses.selected}`]: {
    color: theme.palette.getContrastText(theme.palette.shadeyBlack.main),
    backgroundColor: theme.palette.shadeyBlack.main,
    // boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)`,
  },
  [`&:last-child`]: {
    marginRight: 0,
  },
}));

const TabPage = ({list, onChange, active, passValue}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let children;
  if (typeof active === 'number') {
    children = list && list[active] && list[active].children;
  } else {
    const find = list?.find(itm => itm.key === active);
    children = find?.children;
  }
  return (
    <>
      <Box display='flex' justifyContent='center' width='100%'>
        <StyledTabs
          value={active}
          isMobile={isMobile}
          onChange={onChange}
          variant='scrollable'
          allowScrollButtonsMobile>
          {list.map(tab => (
            <StyledTab
              id={tab.key}
              key={tab.key}
              // value={tab.key}
              {...(passValue ? {value: tab.key} : {})}
              label={isString(tab.title) ? tab.title.toLowerCase() : tab.title}
            />
          ))}
        </StyledTabs>
      </Box>
      <Box sx={{mt: 2}}>
        {children}
        {/* {list && list[active] && list[active].children ? (
          list[active].children
        ) : (
          <></>
        )} */}
      </Box>
    </>
  );
};
export default TabPage;

import Drawer from './Drawer';
import Preview from './Preview';
import ResumeName from './ResumeName';
import GoAIButton from './GoAIButton';
import LayoutBanner from './LayoutBanner';
import WelcomePopup from './WelcomePopup';
import ResumeDetails from './ResumeDetails';
import AddToResumeToggle from './AddToResumeToggle';
import GoAIListForm from './GoAIForms/GoAIListForm';
import StepperFormWrapper from './StepperFormWrapper';

const datePickerSlotProp = {
  textField: {size: 'small'},
  popper: {
    modifiers: [
      {
        name: 'viewHeightModifier',
        enabled: true,
        phase: 'beforeWrite',
        fn: ({state}) => {
          state.styles.popper.height = '320px';
          if (state.placement.includes('top-start')) {
            state.styles.popper = {
              ...state.styles.popper,
              display: 'flex',
              alignItems: 'flex-end',
            };
          }
          if (state.placement.includes('bottom')) {
            state.styles.popper = {
              ...state.styles.popper,
              display: 'block',
            };
          }
        },
      },
    ],
  },
};

export {
  Drawer,
  Preview,
  GoAIButton,
  ResumeName,
  LayoutBanner,
  WelcomePopup,
  GoAIListForm,
  ResumeDetails,
  AddToResumeToggle,
  datePickerSlotProp,
  StepperFormWrapper,
};

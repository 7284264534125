import React, {useState, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {Row, Col, message, Collapse} from 'antd';
import {FixedSizeList as List} from 'react-window';

import {fetchCourses, getCourses} from 'redux/modules/pathways';

import {SearchField, ErrorBoundary} from 'core/components';
import {sortArrayOfObjPropertyBased} from 'core/utils';

import CourseItem from './CourseItem';
import ListItem from '../ListItem';

import './style.less';

const {success, error} = message;
const {Panel} = Collapse;

function AllCourseList({
  orignalData,
  displayListItem,
  disableCollapse,
  config,
  title,
  itemHeight,
  fetchCourses,
  onSelect,
}) {
  const [searchedTerm, setSearchedTerm] = useState(null);
  const [searchedCourses, setSearchedCourses] = useState(null);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  const onSearch = useCallback(term => {
    let searchedCourses = null;
    if (term && term !== '') {
      searchedCourses = sortArrayOfObjPropertyBased(
        orignalData &&
          orignalData.data &&
          orignalData.data.courses.filter(course => {
            if (
              course.course_id.toLowerCase().includes(term.toLowerCase()) &&
              course.status
            ) {
              return course;
            }
          }),
        'course_id',
      );
    }
    setSearchedCourses(searchedCourses);
    setSearchedTerm(term);
  });

  useEffect(() => {
    if (searchedTerm && searchedTerm != '') {
      onSearch(searchedTerm);
    }
  }, [orignalData && orignalData.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCourse = course => {
    if (course) {
      onSelect(course);
    }
    return false;
  };

  const RenderCourses = ({index, style}) => {
    const course = courses[index];
    return (
      <div
        className={index % 2 ? 'CourseItemOdd' : 'CourseItemEven'}
        style={style}>
        {displayListItem ? (
          <ListItem data={course} onClick={getCourse} {...config} />
        ) : (
          <CourseItem data={course} onClick={getCourse} />
        )}
      </div>
    );
  };

  let courses = [];
  if (searchedCourses) {
    courses = [...searchedCourses];
  } else if (
    orignalData &&
    orignalData.data &&
    orignalData.data.courses &&
    orignalData.data.courses.length
  ) {
    courses = [...orignalData.data.courses.filter(course => course.status)];
  }

  return (
    <ErrorBoundary nameOfComponent='core-all-course-list'>
      <div className='all-courses-list'>
        <Collapse
          className={`${
            disableCollapse
              ? 'disableCollapse courseContainer'
              : 'courseContainer'
          }`}
          defaultActiveKey='list-of-courses'
          expandIconPosition='right'>
          <Panel
            className='courseHeader'
            header={`${title || `Courses (${courses.length || 0})`}`}
            key='list-of-courses'>
            <div className='courseSearch'>
              <SearchField placeholder='Search' onSearch={onSearch} />
            </div>
            <div className='list'>
              <Row className='courseHeader floating'>
                <Col span={18} className='courese_id'>
                  COURSE ID
                </Col>
                <Col span={6} className='course_units'>
                  UNITS
                </Col>
              </Row>
              <div className='content'>
                {orignalData && orignalData.request ? (
                  <span className='onLoading'> Loading... </span>
                ) : searchedCourses &&
                  searchedCourses.length === 0 &&
                  courses &&
                  courses.length === 0 ? (
                  <span className='noSearch'>No results found.</span>
                ) : (
                  orignalData &&
                  orignalData.data &&
                  !orignalData.error && (
                    <List
                      className='List'
                      itemLeftWidth={18}
                      itemRightWidth={6}
                      height={350}
                      itemCount={courses.length}
                      itemSize={itemHeight || 42}
                      width={'100%'}>
                      {RenderCourses}
                    </List>
                  )
                )}
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = state => ({
  orignalData: getCourses(state),
});

export default connect(mapStateToProps, {
  fetchCourses,
})(AllCourseList);

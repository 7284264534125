import {Avatar, Button, Dropdown, Tooltip} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {CreateMenu, LazyImage, ErrorBoundary} from 'core/components';
import {getLogo} from 'core/utils';
import {useComparePaths} from 'core/hooks';

import {skillCertify, iconremove} from 'assets/images';
import './style.less';

const PathCard = ({
  target = '_self',
  data,
  enableNavigation,
  onClosePath,
  disLogo = false,
  displayThumbNail,
  displayEllipses,
  config,
  addToCompare,
  enableTitleNavigation,
  customClass = '',
}) => {
  const {onCompareClick, isPathExistInCompareList} = useComparePaths(
    data,
    true,
  );
  const {
    banner_cloudinary,
    award_type_name,
    title,
    institute_details: {logo_cloudinary, name} = {},
    occupation_details: {occupation_name} = {},
    uuid,
    tagType,
    program,
    program_id,
    in_demand,
    popular,
    new: new_path,
    //eslint-disable-next-line
    opp_application_uuid, // req field, used with eval,
    workforce_uuid, // req field, used with eval,
  } = data || {};

  const {
    buttonClass,
    buttonText,
    buttonAction,
    buttonDisabled,
    type,
    buttonActionData,
  } = config || {};

  const styles = {
    width: '100%',
    display: 'inline-block',
    padding: '10px',
  };

  const history = useHistory();

  const pathNavigateTo = path => {
    let url = `/programs/${
      path.institute_details && encodeURIComponent(path?.institute_details.name)
    }/${encodeURIComponent(path?.title)}/${encodeURIComponent(
      award_type_name,
    )}/${program_id || program}`;

    if (type && type === 'occupation') {
      url = `/occupation?query=${
        occupation_name && encodeURIComponent(occupation_name)
      }`;
    }

    if (type && type === 'appliedWorkforce') {
      url = `/programs/${encodeURIComponent(title)}/${encodeURIComponent(
        name,
      )}?type=${tagType}`;
      if (tagType && tagType === 'Job') {
        url = `/job/local/${encodeURIComponent(title)}/${encodeURIComponent(
          name,
        )}`;
      }
    }

    if (type && type === 'cluster') {
      url = `/search?cluster_name=${encodeURIComponent(path?.title)}`;
    }

    target === '_self' ? history.push(url) : window.open(url, target);
  };

  const onCardClose = e => {
    e.stopPropagation();
    onClosePath(data);
  };

  const menu = () => {
    let menu = [];
    let dataPassed = {};
    // Check if the data needs to be passed to Menu item if yes then evaluate the actual value
    if (displayEllipses && displayEllipses.length > 0) {
      displayEllipses.map((item, idx) => {
        if ('passingData' in item) {
          item.passingData.map(data => {
            dataPassed[data] = eval(data);
          });
          item['dataPassed'] = dataPassed;
        }
      });
      menu = displayEllipses;
    }
    return <CreateMenu data={menu} />;
  };

  return (
    <ErrorBoundary nameOfComponent='core-pathcard'>
      <div className={`pathcard ${customClass}`} style={styles}>
        {displayEllipses && (
          <span className='cardEllipses'>
            <Dropdown overlay={menu} overlayClassName='ddl_Unsubscribe'>
              <Button>...</Button>
            </Dropdown>
          </span>
        )}
        {addToCompare && (
          <Tooltip
            placement='bottomRight'
            title={
              isPathExistInCompareList
                ? 'Remove Path from Compare'
                : 'Add Path to Compare'
            }>
            <span
              className={`compareBtn ${isPathExistInCompareList && 'isAdded'}`}>
              <Button
                onClick={() =>
                  onCompareClick(isPathExistInCompareList ? 'remove' : 'add')
                }>
                +
              </Button>
            </span>
          </Tooltip>
        )}
        <div
          onClick={() => enableNavigation && pathNavigateTo(data)}
          className='pathcard_sec'>
          {tagType && (
            <span className={`tag ${tagType.toLowerCase()}`}>{tagType}</span>
          )}
          <div className='tag_section'>
            {in_demand && <span className='tags in_demand'>In-Demand</span>}
            {popular && <span className='tags popular'>Popular</span>}
            {new_path && <span className='tags new'>New</span>}
          </div>

          <LazyImage
            renderSrcSet
            type={type}
            src={banner_cloudinary}
            alt='search banner'
            className='img-fluid'
          />

          {onClosePath && (
            <div className='close' onClick={onCardClose}>
              <img src={iconremove} alt='close path' />
            </div>
          )}

          <div className='avatar' style={{display: disLogo ? 'block' : 'none'}}>
            <Avatar
              size={50}
              src={getLogo(
                data?.institute_details?.logo_cloudinary || logo_cloudinary,
              )}
              icon={<UserOutlined />}
            />
          </div>

          <div className='px-3'>
            <h4
              title={title || occupation_name}
              onClick={() => enableTitleNavigation && pathNavigateTo(data)}>
              {title || occupation_name}
            </h4>
            {!displayThumbNail && (
              <>
                {name && (
                  <p title={name} className='companyName'>
                    {config && config.nameLabel && config.nameLabel}
                    <span>{name}</span>
                  </p>
                )}
                {award_type_name && (
                  <h5>
                    <img
                      src={skillCertify}
                      alt='skill Certificate'
                      className='img-fluid pr-1'
                    />
                    {award_type_name}
                  </h5>
                )}
              </>
            )}
          </div>
        </div>
        {config && buttonText && (
          <Button
            disabled={buttonDisabled}
            className={buttonClass}
            onClick={
              buttonActionData ? () => buttonAction(data) : buttonAction
            }>
            {buttonText}
          </Button>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default PathCard;

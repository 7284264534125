import * as TYPES from './constants';
import {API} from 'config';

import {
  requestPrograms,
  recievePrograms,
  requestProgramsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PROGRAMS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.PROGRAMS_SUCCESS:
      if (action.loadMore) {
        return {
          ...state,
          request: false,
          error: false,
          data: {
            ...action.payload,
            data: [...state?.data?.data, ...action.payload?.data],
            datalength:
              state.data.datalength +
                (action.payload &&
                  action.payload.data &&
                  Array.isArray(action.payload.data) &&
                  action.payload.data.length) || 0,
          },
        };
      }
      return {
        ...state,
        request: false,
        error: false,
        data: {
          ...action.payload,
          datalength:
            (action.payload &&
              action.payload.data &&
              Array.isArray(action.payload.data) &&
              action.payload.data.length) ||
            0,
        },
      };
    case TYPES.CLEAR_PROGRAMS:
      return {
        request: false,
        error: false,
      };
    case TYPES.PROGRAMS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchPrograms = (reqParams, filters) => (dispatch, getState) => {
  dispatch(requestPrograms());
  const {
    occupation_id,
    company_name,
    gsearch,
    sort = 'date_desc',
    type = 'apprenticeship',
    location_type = 'all',
    page = 1,
    page_size = 25,
    loadMore,
  } = reqParams || {};
  try {
    let endPoint = `${API.srm.opportunity}?occupation__uuid=${occupation_id}&sort_by=${sort}&type=${type}&location_type=${location_type}&page_size=${page_size}&page=${page}`;
    if (company_name) {
      endPoint = `${API.srm.opportunity}?company_name=${company_name}&sort_by=${sort}&type=${type}&location_type=${location_type}&page_size=${page_size}&page=${page}`;
    }
    if (gsearch) {
      endPoint = `${API.srm.opportunity}?gsearch=${gsearch}&sort_by=${sort}&type=${type}&location_type=${location_type}&page_size=${page_size}&page=${page}`;
    }
    if (filters) {
      const getFilterKeys = Object.keys(filters);
      if (getFilterKeys.length) {
        getFilterKeys.map(key => {
          if (filters[key]) {
            endPoint += `&${key}=${filters[key]}`;
          }
        });
      }
    }
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recievePrograms(res, loadMore));
        return;
      }
      dispatch(requestProgramsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestProgramsError(err));
  }
};

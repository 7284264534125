import React from 'react';
import {Grid} from 'mui-core';
import PropTypes from 'prop-types';
import {PATTERN} from 'core/regex';
import isArray from 'lodash/isArray';
import {createField} from 'mui-core/Form';
import SectionHeader from './SectionHeader';
import {OutlinedInput, MenuItem, Select} from '@mui/material';

const CompanyInformation = ({form, statesList, structure, checkNameStatus}) => {
  const {setValue} = form;
  const {
    city,
    state,
    zipcode,
    web_url,
    sectionTitle,
    company_name,
    address_line1,
    address_line2,
  } = structure;

  const maskZipcode = value => {
    let maskedValue = '';
    const plainValue = value.slice(0, 10).replace('-', '');
    if (value.length <= 10) {
      if (value.length > 5) {
        const splited1 = plainValue.slice(0, 5);
        const splited2 = plainValue.slice(5, plainValue.length);
        maskedValue =
          splited2 !== '-' || splited2 !== ''
            ? `${splited1}-${splited2}`
            : splited1;
      } else {
        maskedValue = plainValue;
      }
      setValue('zipcode', maskedValue);
    }

    return maskedValue;
  };

  return (
    <Grid
      container
      rowSpacing={4}
      sx={{m: '10px'}}
      columnSpacing={{xs: 1, sm: 2, md: 3}}>
      <Grid item xs={12} py={0} pt={1}>
        <SectionHeader index={1} text={sectionTitle} />
      </Grid>
      <Grid item xs={12} sm={6}>
        {createField('name', {
          form,
          size: 'small',
          defaultValue: '',
          label: company_name.label,
          rules: {
            custom: [true, company_name.customError],
            maxLength: [60, company_name.maxLengthError],
            required: [company_name.required, company_name.requiredError],
          },
        })(
          <OutlinedInput
            variant='outlined'
            id={company_name.label}
            onBlur={checkNameStatus}
            label={company_name.label}
            placeholder={company_name.placeholder}
          />,
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createField('web_url', {
          form,
          size: 'small',
          defaultValue: '',
          label: web_url.label,
          rules: {
            maxLength: [60, web_url.maxLengthError],
            required: [web_url.required, web_url.requiredError],
            pattern: [PATTERN?.Website, web_url.customError],
          },
        })(
          <OutlinedInput
            variant='outlined'
            id={web_url.label}
            label={web_url.label}
            placeholder={web_url.placeholder}
          />,
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createField('address_line1', {
          form,
          size: 'small',
          defaultValue: '',
          label: address_line1.label,
          rules: {
            required: [address_line1.required, address_line1.requiredError],
            maxLength: [60, address_line1.maxLengthError],
          },
        })(
          <OutlinedInput
            variant='outlined'
            id={address_line1.label}
            label={address_line1.label}
            placeholder={address_line1.placeholder}
          />,
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createField('address_line2', {
          form,
          size: 'small',
          defaultValue: '',
          label: address_line2.label,
          rules: {
            maxLength: [60, address_line2.maxLengthError],
            required: [address_line2.required, address_line2.requiredError],
          },
        })(
          <OutlinedInput
            variant='outlined'
            id={address_line2.label}
            label={address_line2.label}
            placeholder={address_line2.placeholder}
          />,
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createField('city', {
          form,
          size: 'small',
          defaultValue: '',
          label: city.label,
          rules: {
            required: [city.required, city.requiredError],
            maxLength: [60, city.maxLengthError],
          },
        })(
          <OutlinedInput
            id={city.label}
            variant='outlined'
            label={city.label}
            placeholder={city.placeholder}
          />,
        )}
      </Grid>
      <Grid item xs={7} sm={3.5}>
        {createField('state', {
          form,
          size: 'small',
          defaultValue: '',
          label: state.label,
          rules: {
            required: [state.required, state.requiredError],
          },
        })(
          <Select
            label={state.label}
            variant='outlined'
            sx={{
              fontSize: '15px',
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}>
            <MenuItem value='' key='none'>
              None
            </MenuItem>
            {statesList &&
              isArray(statesList) &&
              statesList.length > 0 &&
              statesList.map(item => (
                <MenuItem value={item.name} key={item.state_uuid}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>,
        )}
      </Grid>
      <Grid item xs={5} sm={2.5}>
        {createField('zipcode', {
          form,
          size: 'small',
          defaultValue: '',
          label: zipcode.label,
          rules: {
            required: [zipcode.required, zipcode.requiredError],
            pattern: [PATTERN?.Zip_Code, zipcode.customError],
          },
        })(
          <OutlinedInput
            variant='outlined'
            id={zipcode.label}
            label={zipcode.label}
            inputProps={{maxLength: 10}}
            placeholder={zipcode.placeholder}
            onKeyUp={e => maskZipcode(e.target.value)}
          />,
        )}
      </Grid>
    </Grid>
  );
};

CompanyInformation.propTypes = {
  form: PropTypes.object,
  structure: PropTypes.object,
  statesList: PropTypes.array,
  checkNameStatus: PropTypes.func,
};

export default CompanyInformation;

import {LoadingOutlined} from '@ant-design/icons';
import {isArray, isObject, isEmpty} from 'lodash';
import jp from 'jsonpath';
import {goEducateLogo} from 'assets/images';

import './style.less';

const defaultStyle = {
  padding: 20,
  textAlign: 'center',
};
const RequestErrorLoader = ({
  displaySplash,
  txt = 'Loading...',
  errorMsg = 'Something went wrong.',
  emptyMsg = 'No Search Results Found',
  body = {},
  overideNoDataContainer,
  children,
  checkForMultipleDataKeys,
  waitUntil = false,
}) => {
  const {request, error, data} = body;
  if (error && !waitUntil) {
    return <div className='text-center error'>{errorMsg}</div>;
  }

  if (
    !request &&
    !error &&
    data &&
    (isArray(data) || isObject(data)) &&
    isEmpty(data) &&
    !waitUntil
  ) {
    return (
      overideNoDataContainer || (
        <div className='text-center no results no-path-found'>
          <h3>{emptyMsg}</h3>
        </div>
      )
    );
  }

  if (!request && !error && checkForMultipleDataKeys) {
    let haveData = false;
    checkForMultipleDataKeys.forEach(obj => {
      const key = Object?.keys(obj)[0];
      if (key && body?.[key]) {
        const checkData = jp.query(body[key], obj[key])[0];
        if (
          checkData &&
          (isArray(checkData) || isObject(checkData)) &&
          !isEmpty(checkData)
        ) {
          haveData = true;
        }
      }
    });

    return (
      (!haveData &&
        (overideNoDataContainer || (
          <div className='text-center no results no-path-found'>
            <h3>{emptyMsg}</h3>
          </div>
        ))) || (
        <div
          className='loadingSpinner'
          style={!children && request ? defaultStyle : {}}>
          <div className={`${request && children ? 'loading-overlay' : ''}`}>
            {children}
          </div>
        </div>
      )
    );
  }

  return (
    <div
      className='loadingSpinner'
      style={!children && request ? defaultStyle : {}}>
      <div className={`${request && children ? 'loading-overlay' : ''}`}>
        {request &&
          (displaySplash ? (
            <div className='splashPage'>
              <div>
                <img
                  src={goEducateLogo}
                  alt='logo'
                  className='img-fluid py-3'
                />
                <div className='text-center py-3'>
                  <div className='loader'></div>
                </div>
              </div>
            </div>
          ) : (
            <span className='text-center'>
              <span className='loader'>
                <LoadingOutlined spin />
                {txt}
              </span>
            </span>
          ))}
        {children}
      </div>
    </div>
  );
};

export default RequestErrorLoader;

import {
  Avatar,
  Box,
  Checkbox,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import {
  white_twitter,
  white_facebook,
  white_linkedin,
  color_instagram,
  color_medium,
  color_tiktok,
} from 'assets/images';
import {PATTERN} from 'core/regex';
import {useUser} from 'core/hooks';
import {Button} from 'mui-core';
import {createField} from 'mui-core/Form';
import {useForm} from 'react-hook-form';
import {useSnackbar} from 'notistack';

const medias = [
  {
    name: 'Facebook',
    icon: white_facebook,
    field: 'facebook_link',
    enabled: 'show_facebook_link',
    placeholder: 'Enter FaceBook URL',
    filter: `invert(43%) sepia(7%) saturate(5057%) hue-rotate(183deg)
              brightness(85%) contrast(86%)`,
  },
  {
    name: 'LinkedIn',
    icon: white_linkedin,
    field: 'linkedin_link',
    enabled: 'show_linkedin_link',
    placeholder: 'Enter LinkedIn URL',
    filter: `invert(52%) sepia(52%) saturate(799%) hue-rotate(159deg)
              brightness(98%) contrast(93%)`,
  },
  {
    name: 'Instagram',
    icon: color_instagram,
    field: 'instagram_link',
    enabled: 'show_instagram_link',
    placeholder: 'Enter Instagram URL',
  },
  {
    name: 'Twitter',
    icon: white_twitter,
    field: 'twitter_link',
    enabled: 'show_twitter_link',
    placeholder: 'Enter Twitter URL',
    filter: `invert(32%) sepia(47%) saturate(2227%) hue-rotate(174deg)
              brightness(95%) contrast(101%)`,
  },

  {
    name: 'TikTok',
    icon: color_tiktok,
    field: 'tiktok_link',
    enabled: 'show_tiktok_link',
    placeholder: 'Enter TikTok URL',
  },
  {
    name: 'Medium',
    icon: color_medium,
    field: 'medium_link',
    enabled: 'show_medium_link',
    placeholder: 'Enter Medium URL',
  },
];

const SocailLinks = ({updateUserProfile}) => {
  const {enqueueSnackbar} = useSnackbar();
  const {data: userData = {}, request} = useUser();
  const form = useForm();
  const {handleSubmit, clearErrors, setError} = form;
  const onSubmit = async (data = {}) => {
    await updateUserProfile(data, success => {
      if (success)
        enqueueSnackbar('Saved Successfully.', {
          variant: 'success',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant='body2' mb={2} maxWidth={'90%'}>
          Choose which social media options to include, if any, below and enter
          your social media URL in the spaces provided. Select a social media
          option to add your URL.
        </Typography>
        {medias.map(link => (
          <Stack
            direction={'row'}
            alignItems='center'
            gap={1}
            key={link.field}
            mb={3}>
            {createField(`${link.enabled}`, {
              form,
              fullWidth: false,
              valueField: 'checked',
              defaultValue:
                userData && userData[link.enabled]
                  ? userData[link.enabled]
                  : false,
            })(<Checkbox id={link.enabled} />)}

            <label htmlFor={link.enabled} style={{cursor: 'pointer'}}>
              <Stack
                direction={'row'}
                alignItems='center'
                sx={{width: 120}}
                gap={1}>
                <Avatar
                  alt='fb'
                  variant='square'
                  src={link.icon}
                  sx={{
                    width: 18,
                    height: 18,
                    filter: link.filter,
                    img: {width: 'initial'},
                  }}
                />
                <Typography>{link.name}</Typography>
              </Stack>
            </label>
            {createField(`${link.field}`, {
              form,
              rules: {
                pattern: [
                  /^(https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                  'Invalid URL format, use https://',
                ],
              },
              defaultValue: userData?.[link.field] || '',
            })(
              <OutlinedInput
                placeholder={link.placeholder}
                sx={{minWidth: '50%', maxWidth: 400}}
              />,
            )}
          </Stack>
        ))}
        <Box mt={4}>
          <Button
            variant='contained'
            formNoValidate
            loading={request}
            color='primary'
            type='submit'
            sx={{minWidth: 200}}>
            Save
          </Button>
        </Box>
      </form>
    </>
  );
};
export default SocailLinks;

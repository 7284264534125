import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useRouteMatch} from 'react-router-dom';
import {useInstance} from 'core/hooks';
import {Container} from '@mui/material';
import Banner from './components/Banner';
import NewDashboardModules from './NewDashboardModules';
import {Grid, AppBreadcrumb, BackgroundPaper} from 'mui-core';
import {fetchProfileData, profileDataSelector} from 'redux/modules/profile';

let scroller;
const getBreadcrumbLink = (path, modules) => {
  if (!path) path = '/';
  return path && modules?.find(module => module.path === path)?.label;
};

const DashBoardContainer = ({submodules}) => {
  const {url} = useRouteMatch();
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const {isCriminalJustice} = useInstance();
  const path = pathname && pathname.trim().split(url).join('');
  const {data: profileData = {}, request: profileRequest} =
    useSelector(profileDataSelector) || {};

  const onScroll = () => {
    const el = document.getElementById('dashboard-menu-container');
    const header = document.querySelector('header');
    const headerRect = header.getBoundingClientRect();
    const elementRect = el.getBoundingClientRect();
    if (el && header) {
      // if (window.scrollY > 461) {
      if (elementRect.top <= headerRect.bottom) {
        el.classList.add('stick');
        header.classList.add('no-shadow');
      } else {
        el.classList.remove('stick');
        header.classList.remove('no-shadow');
      }
    }
  };

  const scrollerEvent = () => {
    scroller = window;
    scroller.addEventListener('scroll', onScroll, false);
  };

  useEffect(() => {
    scrollerEvent();
    !profileData && !profileRequest && dispatch(fetchProfileData());
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [profileData, profileRequest]); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredDashboardMenu = isCriminalJustice
    ? submodules?.filter(route => route.key !== 'opportunities')
    : submodules;

  return (
    <BackgroundPaper isPurple>
      <Container>
        <AppBreadcrumb
          // showHome={true}
          dataList={[
            {
              name: 'Dashboard',
              path: '/dashboard',
            },
            {
              name: getBreadcrumbLink(path, filteredDashboardMenu),
            },
          ]}
        />
        <Banner submodules={filteredDashboardMenu} />
        <Grid container py={3}>
          <Grid item xs={12} id='dashboard-content'>
            <NewDashboardModules modules={filteredDashboardMenu} />
          </Grid>
        </Grid>
      </Container>
    </BackgroundPaper>
  );
};

export default DashBoardContainer;

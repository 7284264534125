import * as TYPES from './constants';
import {API} from 'config';

import {requestMetrics, recieveMetrics, errorMetrics} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.METRICS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.METRICS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLEAR_METRICS:
      return {
        request: false,
        error: false,
      };
    case TYPES.METRICS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchMetrics = () => (dispatch, getState) => {
  const {
    home: {MetricsData},
  } = getState();
  if (!MetricsData?.data) {
    try {
      dispatch(requestMetrics());
      let endPoint = `${API.srm.home_metrics}`;
      return services.fetchRecords(endPoint, null, true).then(res => {
        if (!res?.error) {
          dispatch(recieveMetrics(res));
          return;
        }
        dispatch(errorMetrics(res));
      });
    } catch (err) {
      dispatch(errorMetrics(err));
    }
  }
};

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ATSIcon = props => {
  return (
    <SvgIcon
      {...props}
      fontSize='inherit'
      viewBox='0 0 39 38'
      style={{fontSize: props.fontSize || '1em'}}>
      <g id='Education / award-03'>
        <svg
          width='42'
          fill='none'
          height='38'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M10.5846 31.668H8.20964C6.46073 31.668 5.04297 30.2502 5.04297 28.5013V6.33464C5.04297 4.58573 6.46073 3.16797 8.20964 3.16797H30.3763C32.1252 3.16797 33.543 4.58573 33.543 6.33464V28.5013C33.543 30.2502 32.1252 31.668 30.3763 31.668H28.0013M19.293 30.0846C21.9163 30.0846 24.043 27.958 24.043 25.3346C24.043 22.7113 21.9163 20.5846 19.293 20.5846C16.6696 20.5846 14.543 22.7113 14.543 25.3346C14.543 27.958 16.6696 30.0846 19.293 30.0846ZM19.293 30.0846L19.3269 30.0843L14.2717 35.1395L9.79336 30.6612L14.5741 25.8805M19.293 30.0846L24.3483 35.1395L28.8266 30.6612L24.0459 25.8805M14.543 9.5013H24.043M11.3763 15.043H27.2096'
            stroke='url(#paint0_linear_31893_105961)'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <defs>
            <linearGradient
              id='paint0_linear_31893_105961'
              x1='33.543'
              y1='19.1537'
              x2='5.04297'
              y2='19.1537'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#5E92F4' />
              <stop offset='0.76' stop-color='#A290F6' />
            </linearGradient>
          </defs>
        </svg>
      </g>
    </SvgIcon>
  );
};

export default ATSIcon;

import {useState} from 'react';
import PropTypes from 'prop-types';
import {ErrorBoundary} from 'mui-core';
import {styled} from '@mui/material/styles';
import {Box, Stack, Popover, Typography} from '@mui/material';

const StyledPopover = styled(Popover)(({}) => ({
  '& .MuiPopover-paper': {
    overflow: 'hidden',
  },
}));

const PopoverContent = styled(Box)(({theme}) => ({
  '& #list-container': {
    height: '350px',
    overflow: 'auto',
    paddingTop: '8px',
    '& ul': {
      paddingLeft: '16px',
      '& > li': {
        display: 'flex',
        lineHeight: '0.8',
        maxWidth: '400px',
        marginBottom: '8px',
        listStyleType: 'none',
        background: 'transparent',
        color: `${theme.palette.darkGray.main} !important`,
        '&::before': {
          top: '-1px',
          left: '-12px',
          color: '#3ac4ff',
          fontSize: '30px',
          content: '"\\2022"',
          position: 'relative',
          [theme.breakpoints.only('xs')]: {
            top: '-2px',
          },
        },
        [theme.breakpoints.only('sm')]: {
          maxWidth: '300px',
        },
        [theme.breakpoints.only('xs')]: {
          maxWidth: '200px',
        },
      },
    },
  },
}));

const WrappedText = ({data, length, title}) => {
  const conCatText =
    Array.isArray(data) && data.length > 0 ? data.join(', ') : '-';
  const isTextLengthExceeds = conCatText.length > length;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const wrappedText = isTextLengthExceeds
    ? conCatText.substring(0, length)
    : conCatText;

  const handleClose = () => setAnchorEl(null);
  const handleClick = event => setAnchorEl(event.currentTarget);

  return (
    <ErrorBoundary nameOfComponent='core-wrap-text'>
      <Stack
        spacing={1}
        direction='column'
        alignItems='start'
        justifyContent='start'>
        <Typography
          variant='body1'
          color='text.primary'
          sx={{
            lineClamp: '2',
            overflow: 'hidden',
            boxOrient: 'vertical',
            display: '-webkit-box',
            // '-webkit-line-clamp': '2',
            // '-webkit-box-orient': 'vertical',
          }}>
          {wrappedText}
        </Typography>
        {isTextLengthExceeds && (
          <Box>
            <a onClick={handleClick} aria-describedby={id}>
              <Typography variant='body1' color='secondary'>
                {open ? 'See less' : 'See more...'}
              </Typography>
            </a>
            <StyledPopover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}>
              <PopoverContent m={3}>
                <Stack pb={1} spacing={1} direction='row' alignItems='center'>
                  <Typography
                    variant='body1'
                    color='secondary'
                    fontWeight={600}>
                    {title}
                  </Typography>
                  <Typography variant='body1' color='text.primary'>
                    are also called as:
                  </Typography>
                </Stack>
                <Box id='list-container'>
                  <ul>
                    {data.map((txt, idx) => (
                      <li
                        key={idx}
                        style={{lineHeight: txt.length > 50 ? 1.4 : 0.8}}>
                        <Box>
                          <Typography variant='body1'>{txt}</Typography>
                        </Box>
                      </li>
                    ))}
                  </ul>
                </Box>
              </PopoverContent>
            </StyledPopover>
          </Box>
        )}
      </Stack>
    </ErrorBoundary>
  );
};

WrappedText.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  length: PropTypes.number,
};

export default WrappedText;

import {useEffect} from 'react';
import {Button, Col, Input, Row, Select, Form} from 'antd';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {getModuleBasePath, queryStringParse} from 'core/utils';
import {ErrorBoundary} from 'core/components';

import {fetchStates, getStates} from 'redux/modules/general';
import './style.less';

export const LocalSalarySearch = ({
  fetchStates,
  states,
  occupationName,
  islocalSalaryPage,
}) => {
  const {data: statesData, request} = states || {};
  const history = useHistory();
  const [zipForm] = Form.useForm();
  const [stateForm] = Form.useForm();
  const {
    location: {search},
  } = history;
  const params = queryStringParse(search);
  const {state, zip} = params;

  useEffect(() => {
    if (
      !statesData ||
      (statesData && Array.isArray(statesData) && statesData.length === 0)
    ) {
      fetchStates();
    }

    return () => {};
  }, [fetchStates, statesData]);

  const onSubmit = data => {
    const {value, type} = data;
    const BasePath = getModuleBasePath('local-salary');
    history.push(`${BasePath}?${type}=${value}&occupation=${occupationName}`);
  };
  const zipSubmit = () => {
    zipForm.validateFields().then(values => {
      if (onSubmit) onSubmit({value: values.zip, type: 'zip'});
      // zipForm.resetFields(['state']);
      stateForm.resetFields();
    });
  };
  const stateSubmit = () => {
    stateForm.validateFields().then(values => {
      if (onSubmit) onSubmit({value: values.state, type: 'state'});
      zipForm.resetFields(['zip']);
    });
  };
  return (
    <ErrorBoundary nameOfComponent='core-local-salary-search'>
      <Row justify={'space-around'} className='local-salry-search-form'>
        <Col sm={24} md={24} lg={12} xs={24}>
          <Form form={zipForm}>
            <Row className='s-itm' gutter={35} align='middle'>
              <Col sm={4} className='s-lbl px-1 text-right pr-1'>
                Zip Code
              </Col>
              <Col sm={15} xs={20} className='s-fld px-1'>
                <Form.Item
                  noStyle
                  name='zip'
                  initialValue={zip}
                  rules={[{required: true, message: 'required!'}]}>
                  <Input placeholder='Enter Zip Code' />
                </Form.Item>
              </Col>
              <Col sm={5} xs={4} className='s-btn px-1'>
                <Button type='primary' onClick={zipSubmit}>
                  Go
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col sm={24} md={24} lg={12} xs={24}>
          <Form form={stateForm}>
            <Row className='s-itm' gutter={35} align='middle'>
              <Col sm={4} className='s-lbl px-1 text-right pr-2'>
                State
              </Col>
              <Col sm={15} xs={20} className='s-fld px-1'>
                <Form.Item
                  noStyle
                  name='state'
                  initialValue={state ? decodeURIComponent(state) : null}
                  rules={[{required: true, message: 'required!'}]}>
                  <Select
                    style={{width: '100%'}}
                    dropdownClassName={`ddl-selectState ${
                      islocalSalaryPage ? 'ddl-localSalaryState' : ''
                    }`}
                    placeholder='Select State'
                    loading={request}>
                    {statesData &&
                      Array.isArray(statesData) &&
                      statesData.map(s => (
                        <Select.Option key={s.state_uuid} value={s.name}>
                          {s.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={5} xs={4} className='s-btn px-1'>
                <Button type='primary' onClick={stateSubmit}>
                  Go
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </ErrorBoundary>
  );
};
const mapStateToProps = state => ({
  states: getStates(state),
});
export default connect(mapStateToProps, {fetchStates})(LocalSalarySearch);

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {calculateCourseUnits} from 'core/utils';
import {grey} from '@mui/material/colors';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {styled} from '@mui/material/styles';

const StyledTableHead = styled(TableHead)(({theme}) => ({
  backgroundColor: theme.palette.action.hover,
  height: 45,
  color: theme.palette.primary.light,
}));

const StyledTableHeadCell = styled(TableCell)(({theme}) => ({
  color: grey[600],
  borderRightWidth: 1,
  borderRightColor: theme.palette.divider,
  borderRightStyle: 'solid',
  '&:last-child': {
    borderRight: 'none',
  },
}));
const StyledTableDataCell = styled(TableCell)(({theme}) => ({
  borderRightWidth: 1,
  borderRightColor: theme.palette.divider,
  borderRightStyle: 'solid',
  '&:last-child': {
    borderRight: 'none',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  height: 45,
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableView = ({
  term,
  colors,
  index,
  showModal = false,
  handleClickModalOpen,
}) => {
  return (
    <Paper
      elevation={1}
      key={term.uuid}
      sx={{mb: 3, pb: 2, maxWidth: {sm: '100%', md: '75%'}}}>
      <Box sx={{px: 2, py: 1}}>
        <Stack
          alignItems={'center'}
          direction='row'
          justifyContent={'space-between'}>
          <Typography variant='h5' color={'primary'} fontSize>
            Term {index + 1} {term.term_name && `- ${term.term_name}`}
          </Typography>
          <Typography variant='h5' color={'primary'} fontSize>
            {calculateCourseUnits(term.courses)} Credits/Units
          </Typography>
        </Stack>
      </Box>
      <Box>
        {term.courses &&
        Array.isArray(term.courses) &&
        term.courses.length > 0 ? (
          <TableContainer>
            <Table size='medium' sx={{minWidth: 650}} aria-label='simple table'>
              <StyledTableHead>
                <TableRow>
                  <StyledTableHeadCell width={170}>
                    <Typography variant='body1'>Course ID</Typography>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>
                    <Typography variant='body1'>Course Title</Typography>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align='center' width={140}>
                    <Typography variant='body1'>Credits / Units</Typography>
                  </StyledTableHeadCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {term.courses.map((course, cIdx) => {
                  const TYPE = course?.course_type?.toLowerCase() || 'none';

                  return (
                    <StyledTableRow
                      key={`course-${index}-${cIdx}`}
                      onClick={() => handleClickModalOpen(course)}
                      sx={{cursor: showModal ? 'pointer' : 'auto'}}>
                      <StyledTableDataCell component='th' scope='row'>
                        <Stack direction={'row'} alignItems='center'>
                          <FiberManualRecordIcon
                            fontSize='10'
                            color={colors[TYPE]}
                            sx={{mr: 1, opacity: colors[TYPE] ? 1 : 0}}
                          />
                          <Typography variant='body1' color={'secondary'}>
                            {course.course_id}
                          </Typography>
                        </Stack>
                      </StyledTableDataCell>
                      <StyledTableDataCell>
                        <Typography variant='body1'>
                          {course.course_name}
                        </Typography>
                      </StyledTableDataCell>
                      <StyledTableDataCell align='center'>
                        <Typography variant='body1'>{course.units}</Typography>
                      </StyledTableDataCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant='subtitle2' p={2}>
            No courses have been planned for this term.
          </Typography>
        )}
      </Box>
    </Paper>
  );
};
export default TableView;

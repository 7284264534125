import React from 'react';
import {Row, Col, Popover, Switch} from 'antd';

import {getModuleBasePath, numberInUSFormat} from 'core/utils';

import {
  outlookbright,
  outlookaverage,
  outlookbelow,
  statemap,
  localsalaryinfo,
  salarychart,
  brightIcon,
  avgIcon,
  belowIcon,
} from 'assets/images';
import {
  speedometerData,
  careerMetricsData,
  noneApplicableText,
  careerTrendsProgressData,
} from 'data/programs.json';
import {themeColorMode} from 'mui-core';
import {styled} from '@mui/material/styles';
import {Box, Divider, Stack, Typography} from '@mui/material';
import {Speedometer, MetricsTiles, LinearBarGraph} from 'mui-core';

const CareerTrendsContainer = styled(Box)(({theme}) => ({
  padding: 24,
  borderRadius: 24,
  marginBottom: 24,
  backgroundColor: themeColorMode(theme, '#f0edfc', '#222'),
  border: `1px solid ${themeColorMode(theme, '#f0edfc', '#601AAD')}`,
}));

const StyledDivider = styled(Divider)(({theme}) => ({
  background: theme.palette.primary.main,
}));

const CareerTrendsLabel = styled(Typography)(({theme}) => ({
  color:
    theme.palette.mode === 'dark' ? 'white !important' : 'black !important',
})); // written to fix the issue with font-color

const {
  marketDemandLabel,
  marketSupplyLabel,
  marketDemandDescription,
  marketSupplyDescription,
  marketSupplyDataSourcetext,
  marketDemandDataSourcetext,
} = careerTrendsProgressData;

const {
  avgCostLabel,
  jobOpeningLabel,
  avgCostDescription,
  avgWagesEarnedLabel,
  jobOpeningDescription,
  avgCostDataSourcetext,
  jobOpeningDataSourcetext,
  avgWagesEarnedDescription,
  avgWagesEarnedDataSourcetext,
} = careerMetricsData;

const {
  careerOutlooktxt,
  careerTrendsLabel,
  careerOutlookLabel,
  careerOutlookDescription,
} = speedometerData;

const JobOutlook = ({
  data,
  history,
  moduleName,
  onViewChange,
  view,
  onLocalSalaryInfoClick,
}) => {
  const JobOppPath = getModuleBasePath('job-opportunities');
  const {
    occupation_name,
    occupation_currency_details: {
      ave_cost = 0,
      ave_wage = 0,
      career_outlook = 0,
      job_openings = 0,
      market_demand = 0,
      market_supply = 0,
    } = {},
    occupation_details: {
      outlook_catagory,
      projected_growth,
      projected_openings,
      salary_annual_median = 0,
      salary_annual_10th_percentile = 0,
      salary_annual_90th_percentile_over = 0,
      salary_type,
    } = {},
  } = data || {};

  const jobIcon =
    outlook_catagory === 'Average'
      ? outlookaverage
      : outlook_catagory === 'Below Average'
      ? outlookbelow
      : outlookbright;

  const jobPopoverIcon =
    outlook_catagory === 'Average'
      ? avgIcon
      : outlook_catagory === 'Below Average'
      ? belowIcon
      : brightIcon;

  const jobPopoverDes =
    outlook_catagory === 'Average' ? (
      <div className='description'>
        This career will grow{' '}
        <span className='highlighter'>about as fast as average</span>. Large
        numbers of job openings are not expected.
      </div>
    ) : outlook_catagory === 'Below Average' ? (
      <div className='description'>
        This career will <span className='highlighter'>decline</span> in
        employment size. Large numbers of job openings are not expected.
      </div>
    ) : (
      <div className='description'>
        This career will <span className='highlighter'>grow rapidly</span> in
        the next few years.
      </div>
    );

  const outlookdesMsg =
    outlook_catagory === 'Average'
      ? 'likely'
      : outlook_catagory === 'Below Average'
      ? 'less likely'
      : 'very likely';

  const outlookdes =
    moduleName === 'occupation' ? (
      <p className='outlook-des'>
        New job opportunities are{' '}
        <span className='highlighter'>{outlookdesMsg}</span> in the future.
      </p>
    ) : (
      <p>
        Nationwide, new job opportunities for {occupation_name} are{' '}
        <span>{outlookdesMsg}</span> in the future.
      </p>
    );

  const projected_openings_count =
    projected_openings && numberInUSFormat(projected_openings);
  const averageSalary = numberInUSFormat(salary_annual_median);
  const lessPercentileSalary = numberInUSFormat(salary_annual_10th_percentile);
  const overPercentileSalary = numberInUSFormat(
    salary_annual_90th_percentile_over,
  );

  const averageSalaryTooltip = salary_type
    ? `Workers on average earn $${averageSalary} ${
        salary_type === 'Hourly' ? 'per hour' : ''
      }.`
    : '';
  const lessPercentileSalaryTooltip = salary_type
    ? `Workers on average earn $${lessPercentileSalary}  ${
        salary_type === 'Annual' ? 'or less' : 'or less per hour'
      }.`
    : '';
  const overPercentileSalaryTooltip = salary_type
    ? `Workers on average earn $${overPercentileSalary}  ${
        salary_type === 'Annual' ? 'or more' : 'or more per hour'
      }.`
    : '';

  const navigateTo = path => {
    history.push(`${path}?query=${occupation_name}`);
  };

  const careerMetricsDetails = [
    {
      label: jobOpeningLabel,
      description: jobOpeningDescription,
      valueNumber: job_openings,
      value: job_openings
        ? `${numberInUSFormat(job_openings)}`
        : noneApplicableText,
      dataSourcetext: jobOpeningDataSourcetext,
    },
    {
      label: avgWagesEarnedLabel,
      valueNumber: ave_wage,
      description: avgWagesEarnedDescription,
      dataSourcetext: avgWagesEarnedDataSourcetext,
      value: ave_wage ? `$${numberInUSFormat(ave_wage)}` : noneApplicableText,
    },
    {
      label: avgCostLabel,
      valueNumber: ave_cost,
      description: avgCostDescription,
      dataSourcetext: avgCostDataSourcetext,
      value: ave_cost ? `$${numberInUSFormat(ave_cost)}` : noneApplicableText,
    },
  ];

  const careerProgressData = [
    {
      label: marketDemandLabel,
      progressValue: market_demand,
      dataSourcetext: marketSupplyDataSourcetext,
      marketDescription: marketDemandDescription,
    },
    {
      label: marketSupplyLabel,
      progressValue: market_supply,
      dataSourcetext: marketDemandDataSourcetext,
      marketDescription: marketSupplyDescription,
    },
  ];

  const customLabelPlacementValues = {
    okPlacement: {
      lg: 44,
      md: 30,
      sm: 40,
      xs: 30,
    },
    goodPlacment: {
      lg: -18,
      md: -10,
      sm: -35,
      xs: -20,
    },
    greatPlacement: {
      lg: 18,
      md: 0,
      sm: 10,
      xs: 10,
    },
  };

  const careerTrendsBadgeContent = () => {
    return (
      <Stack spacing={2}>
        <Typography variant='h4' color='primary.main'>
          {careerOutlooktxt}
        </Typography>
        <StyledDivider variant='middle' />
        <Typography
          variant='body1'
          dangerouslySetInnerHTML={{
            __html: careerOutlookDescription,
          }}
        />
      </Stack>
    );
  };

  return (
    moduleName === 'occupation' && (
      <CareerTrendsContainer>
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          spacing={{xs: 1, sm: 1, md: 1, lg: 2}}>
          <CareerTrendsLabel variant='h3' color='text.primary'>
            {careerTrendsLabel}
          </CareerTrendsLabel>
          <Box pt={{xs: 4, sm: 2, md: 0, lg: 4}}>
            <Speedometer
              showBadge={true}
              dialValue={career_outlook}
              badgeLabel={careerOutlookLabel}
              badgeData={careerTrendsBadgeContent()}
              customLabelPlacement={customLabelPlacementValues}
            />
          </Box>
          <Box pr={2} width='100%'>
            <LinearBarGraph ProgressData={careerProgressData} />
          </Box>
          <Stack
            pt={2}
            width='100%'
            spacing={{xs: 2, md: 2}}
            justifyContent={'center'}
            direction={{md: 'column', lg: 'row'}}>
            <MetricsTiles metricsData={careerMetricsDetails} />
          </Stack>
        </Stack>
      </CareerTrendsContainer>
    )
  );
  // ) : (
  //   <div className='outerBorder mb-4'>
  //   <div className='header'>
  //     <div>Career Trends</div>
  //   </div>
  //   <Row className='content'>
  //     <Col
  //       xs={24}
  //       sm={24}
  //       md={12}
  //       lg={12}
  //       className='px-5 py-3 career-trend-section'>
  //       <h5 className='career-trend-label'>Future Outlook</h5>
  //       {outlook_catagory && (
  //         <Popover
  //           placement='bottomLeft'
  //           overlayClassName='bright-job-outlook-pop-over'
  //           title={
  //             <div>
  //               <Row>
  //                 <Col span={3}>
  //                   <img
  //                     src={jobPopoverIcon}
  //                     alt='bright-icon'
  //                     className='d-inline-block'
  //                   />
  //                 </Col>
  //                 <Col span={21}>
  //                   <h4 className='d-inline-block px-2'>
  //                     It's a {outlook_catagory} outlook for{' '}
  //                     <span className='highlighter'>{occupation_name}</span>
  //                   </h4>
  //                 </Col>
  //               </Row>
  //             </div>
  //           }
  //           content={jobPopoverDes}
  //           trigger='hover'>
  //           {/* <img src={jobIcon} alt='logo' className='img-fluid' /> */}
  //           <div
  //             className={`career-trend-value outlook-value bright_btn ${outlook_catagory?.toLowerCase()}`}>
  //             {outlook_catagory}
  //           </div>
  //         </Popover>
  //       )}
  //     </Col>
  //     <Col
  //       xs={24}
  //       sm={24}
  //       md={12}
  //       lg={12}
  //       className='px-5 py-3 career-trend-section'>
  //       <h5 className='career-trend-label'>Projected Job Openings</h5>
  //       <div className='career-trend-value'>
  //         {projected_openings_count || '-'}
  //       </div>
  //     </Col>
  //     <Col
  //       xs={24}
  //       sm={24}
  //       md={12}
  //       lg={12}
  //       className='px-5 py-3 career-trend-section'>
  //       <h5 className='career-trend-label'>Projected Growth Rate</h5>
  //       <div className='career-trend-value'>{projected_growth || '-'}</div>
  //       <ul className='pebbleList'>
  //         <li
  //           className={`pebbleItem ${
  //             projected_growth &&
  //             (projected_growth.toLowerCase().includes('little') ||
  //               projected_growth.toLowerCase().includes('average')) &&
  //             'activePebble'
  //           }`}
  //         />
  //         <li
  //           className={`pebbleItem ${
  //             projected_growth &&
  //             projected_growth.toLowerCase().includes('average') &&
  //             'activePebble'
  //           }`}
  //         />
  //         <li
  //           className={`pebbleItem ${
  //             projected_growth &&
  //             projected_growth.toLowerCase().includes('faster') &&
  //             'activePebble'
  //           }`}
  //         />
  //         <li
  //           className={`pebbleItem ${
  //             projected_growth &&
  //             projected_growth.toLowerCase().includes('much faster') &&
  //             'activePebble'
  //           }`}
  //         />
  //       </ul>
  //     </Col>
  //     <Col
  //       xs={24}
  //       sm={24}
  //       md={12}
  //       lg={12}
  //       className='px-5 py-3 career-trend-section'>
  //       <h5 className='career-trend-label'>Avg. Salary</h5>
  //       <div className='career-trend-value'>{`$${averageSalary}`}</div>
  //       <button className='btn btn-purple' onClick={onLocalSalaryInfoClick}>
  //         Check Local Salary Info
  //       </button>
  //     </Col>
  //     {/* <Col xs={24} sm={10} md={10} lg={10}>
  //       <div>{outlookdes}</div>
  //       <button
  //         className='btn btn-blue-outer d-block mb-2'
  //         onClick={() => navigateTo(JobOppPath)}>
  //         <img src={statemap} alt='logo' className='img-fluid pr-2' />
  //         Check out my state
  //       </button>
  //       <button
  //         className='btn btn-blue-outer d-block mb-2'
  //         onClick={onLocalSalaryInfoClick}>
  //         <img src={localsalaryinfo} alt='logo' className='img-fluid pr-2' />
  //         Local Salary Info
  //       </button>
  //     </Col>
  //     <Col xs={24} sm={5} md={5} lg={5}>
  //       <h4>Salary:</h4>
  //       <div className='salaryChart'>
  //         <div>
  //           <h5 className='text-center'>
  //             <label title={averageSalaryTooltip}>${averageSalary}</label>
  //           </h5>
  //         </div>
  //         <div>
  //           <img src={salarychart} alt='logo' className='img-fluid' />
  //         </div>
  //         <div className='d-block'>
  //           <h4 className='text-left d-inline-block'>
  //             <label title={lessPercentileSalaryTooltip}>
  //               ${lessPercentileSalary}
  //             </label>
  //           </h4>
  //           <h4 className='float-right d-inline-block'>
  //             <label title={overPercentileSalaryTooltip}>
  //               ${overPercentileSalary}
  //             </label>
  //           </h4>
  //         </div>
  //       </div>
  //     </Col> */}
  //   </Row>
  // </div>
  // ) : (

  // <>
  //   <div className='d-flex justify-content-between px-5 py-4'>
  //     <h1>National Outlook</h1>
  //     <div className='viewSelection'>
  //       <span className={`mapView pr-2 ${!view ? 'active' : ''}`}>
  //         Map View
  //       </span>
  //       <Switch checked={view} onChange={onViewChange} />
  //       <span className={`listView pl-2 ${view ? 'active' : ''}`}>
  //         List View
  //       </span>
  //     </div>
  //   </div>
  //   <Row className='px-5 py-1 pb-4 d-flex align-items-center'>
  //     <Col xs={3} sm={3} md={3} lg={3} className='text-center'>
  //       <img src={jobIcon} className='img-fluid' alt='job-icon' />
  //     </Col>
  //     <Col xs={21} sm={21} md={21} lg={21}>
  //       {outlookdes}
  //     </Col>
  //   </Row>
  //   <div className='borderline'></div>
  // </>
  // );
};

export default JobOutlook;

import MuiCard from '@mui/material/Card';
import {styled} from '@mui/material/styles';

const Card = styled(MuiCard)(({theme}) => ({
  borderRadius: '16px !important',
  '&:hover, &.Mui-focusVisible, &:focus-visible': {
    outline: 'none',
    transform: 'scale(1.02)',
    transition: 'all 0.4s linear',
    boxShadow: `0px 0px 8px 2px ${
      theme.palette.mode === 'light'
        ? theme.palette.grayBorder.main
        : theme.palette.text.secondary
    }`,
  },
  '&:hover .MuiCardActionArea-focusHighlight': {
    opacity: '0.01',
  },
}));

export default Card;

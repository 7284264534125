import {API} from 'config';
import * as TYPES from './constants';
import {Services} from 'core/Services';
import {
  empoweringCommunitiesError,
  requestEmpoweringCommunities,
  recieveEmpoweringCommunities,
} from './actions';

const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_EMPOWERING_COMMUNITIES:
      return {
        ...state,
        error: false,
        request: true,
      };
    case TYPES.EMPOWERING_COMMUNITIES_SUCCESS: {
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    }
    case TYPES.CLEAR_EMPOWERING_COMMUNITIES:
      return initialState;
    case TYPES.EMPOWERING_COMMUNITIES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchEmpoweringCommunities = id => dispatch => {
  dispatch(requestEmpoweringCommunities());
  try {
    let endPoint = `${API.srm.institute}?institution_type=consortium&id=${id}`;
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveEmpoweringCommunities(res));
        return;
      }
      dispatch(empoweringCommunitiesError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(empoweringCommunitiesError(err));
  }
};

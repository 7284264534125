import * as TYPES from './constants';
import {API} from 'config';

import {Services} from 'core/Services';
import {
  dashboardProgramsError,
  recieveDashboardPrograms,
  requestDashboardPrograms,
} from './actions';
const services = new Services();
const initialState = {
  request: false,
  data: false,
  error: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.DASHBOARD_PROGRAMS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.DASHBOARD_PROGRAMS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.DASHBOARD_PROGRAMS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.DASHBOARD_PROGRAMS_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchDashboardPrograms = () => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  try {
    let endPoint = `${API.gps.student_path}`;
    dispatch(requestDashboardPrograms());
    return services
      .fetchRecords(`${endPoint}?page_size=100`, token, true)
      .then(res => {
        if (res) {
          dispatch(recieveDashboardPrograms(res));
          return;
        }
        dispatch(dashboardProgramsError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(dashboardProgramsError(err));
  }
};

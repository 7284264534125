import {RequestErrorLoader} from 'mui-core';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import {useMemo} from 'react';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import {Stack, Typography} from '@mui/material';
import {Box} from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import {EditIcon} from 'mui-core/Icons';
const StyledTableCell = styled(TableCell)(({theme}) => ({
  border: 0,
  paddingLeft: 35,
  '&:first-child': {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));
const StyledTableRow = styled(TableRow)(({theme}) => ({
  border: 0,
}));

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 14,
  borderRadius: 14,
  [`&.${linearProgressClasses.colorSecondary}`]: {
    backgroundColor: theme.palette.divider,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 14,
    backgroundColor: theme.palette.secondary.main,
  },
}));
const SkillsTable = ({studentSkills, editSkill, deleteSkill}) => {
  const {data = []} = studentSkills || {};
  const sortedData = useMemo(() =>
    data && Array.isArray(data)
      ? data.sort((a, b) => b.experience - a.experience)
      : [],
  );
  return (
    <RequestErrorLoader body={studentSkills} hideBackground>
      <Box height={300} overflow='auto' maxWidth={{xs: '400px', sm: '100%'}}>
        <Table
          aria-label='customized table'
          size='small'
          sx={{
            height: 'max-content',
          }}>
          <TableHead>
            <TableRow>
              <StyledTableCell
                align='left'
                width={'30%'}
                sx={{verticalAlign: 'top'}}>
                <Typography variant='subtitle2' color='text.primary'>
                  Skills Tag
                </Typography>
              </StyledTableCell>
              <StyledTableCell align='left' valign={'top'}>
                <Box sx={{position: 'relative'}}>
                  <Box>
                    <Typography variant='subtitle2' color='text.primary'>
                      Years of Experience
                    </Typography>
                  </Box>
                  <Stack
                    sx={{
                      position: 'absolute',
                      top: `calc(100% - 15px)`,
                      width: '100%',
                    }}
                    mt={2}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <Typography variant='subtitle2' color='text.primary'>
                      0
                    </Typography>
                    <Typography variant='subtitle2' color='text.primary'>
                      10+
                    </Typography>
                  </Stack>
                </Box>
              </StyledTableCell>
              <StyledTableCell align='center' width={100}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map(row => (
              <StyledTableRow key={row.uuid}>
                <StyledTableCell align='left'>
                  <Chip
                    color='secondary'
                    label={`${row.skill} (${row.experience} yrs)`}
                    sx={{color: 'white', mr: 1, mb: 1}}
                  />
                </StyledTableCell>
                <StyledTableCell align='left'>
                  <BorderLinearProgress
                    color='secondary'
                    variant='determinate'
                    value={row.experience * 10}
                    sx={{
                      width: {xs: '150px', sm: '100%'},
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align='left'>
                  <Stack direction={'row'} alignItems={'center'}>
                    <IconButton
                      aria-label='edit'
                      onClick={() => editSkill(row)}>
                      <EditIcon
                        style={{position: 'relative', top: '5px', left: '3px'}}
                      />
                    </IconButton>

                    <IconButton
                      aria-label='delete'
                      onClick={() => deleteSkill(row)}>
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </RequestErrorLoader>
  );
};
export default SkillsTable;

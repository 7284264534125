import * as TYPES from './constants';
import {API} from 'config';
import {recieveStates, requestStates, requestStatesError} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STATES:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_STATES:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_STATES_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchStates = () => (dispatch, getState) => {
  const endPoint = `${API.gps.states}`;
  dispatch(requestStates());
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveStates(res));
        return;
      }
      dispatch(requestStatesError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestStatesError(err));
  }
};

import React from 'react';
import {Text, View} from '@react-pdf/renderer';
import List, {Item} from './List';
import Divider from './Divider';

export default ({styles, awardTypes, studentProfile}) => {
  const {awards_leadership_projects_publications: awards} = studentProfile;

  const getAwardType = id => {
    const {data: awardTypesData = []} = awardTypes || {};
    if (Array.isArray(awardTypesData)) {
      const idx = awardTypesData.map(i => i.item_type_uuid).indexOf(id);
      return awardTypesData[idx]?.item_type;
    } else {
      return id;
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text break style={styles.headerText}>
          Awards, Leadership, Projects & Publications
        </Text>
      </View>
      <View style={styles.content}>
        {awards &&
          Array.isArray(awards) &&
          awards?.length > 0 &&
          awards.map(
            ({item_type = '', item_title = '', comments = []}, idx) => {
              const additional_info =
                comments &&
                Array.isArray(comments) &&
                comments?.length > 0 &&
                comments.filter(
                  role => typeof role.additional_info === 'string',
                );
              return (
                <View key={idx} style={styles.inline}>
                  {item_type && item_title && (
                    <Text break style={styles.boldLabel}>
                      {`${getAwardType(item_type)}: ${item_title}`}
                    </Text>
                  )}
                  {additional_info &&
                    Array.isArray(additional_info) &&
                    additional_info?.length > 0 && (
                      <List>
                        {additional_info.map((role, id) => (
                          <Item key={id} style={styles.detailContainer}>
                            {role?.additional_info}
                          </Item>
                        ))}
                      </List>
                    )}
                  {idx !== awards?.length - 1 ? <Divider /> : null}
                </View>
              );
            },
          )}
        {!awards ||
          !Array.isArray(awards) ||
          (awards?.length === 0 && (
            <Text style={styles.value}>No awards added.</Text>
          ))}
      </View>
    </View>
  );
};

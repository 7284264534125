import {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {SearchOutlined, CloseCircleFilled} from '@ant-design/icons';
import debounce from 'debounce';
// import {API} from 'config';
import {
  getGlobalSearchItems,
  getGlobalSearchHistory,
  addGlobalSearchItem,
  deleteGlobalSearchItem,
  getAppConfig,
} from 'redux/modules/general';

import {ErrorBoundary} from 'core/components';
import {getModuleBasePath} from 'core/utils';
//import {Services} from 'core/Services';
import SearchComponent from './SearchComponent';
import {en as STATIC_DATA} from 'data/home';
import './style.less';
//const services = new Services();

const GlobalSearch = props => {
  const {
    floatingHeader,
    addGlobalSearchItem,
    getGlobalSearchItems,
    deleteGlobalSearchItem,
    globalSearchHistory = [],
  } = props;

  const autoCompleteRef = useRef();
  const history = useHistory();
  const {isMobileView} = props?.appConfig || {};

  const [searchKey, setSearchKey] = useState('');
  // toggles search component on mobiles
  const [searchEnabled, enableSearch] = useState(false);
  // search results
  //const [results, setResults] = useState([]);
  // loader state
  //const [loading, setLoading] = useState(false);

  useEffect(() => {
    // get search history from local storage
    getGlobalSearchItems?.();
  }, [getGlobalSearchItems]);

  useEffect(() => {
    history.location?.pathname === '/' && setSearchKey('');
  }, [history.location.pathname]);

  const onSearch = value => {
    //Stopping API tigger : may be require in future
    // if (value) {
    //   setLoading(true);
    //   setResults([]);
    //   fetchData(value);
    // }
    setSearchKey(value);
  };

  const addGlobalSearchHistoryItem = value => {
    // save to local storage
    addGlobalSearchItem?.(value);
  };

  const deleteGlobalSearchHistoryItem = (title, event) => {
    event.stopPropagation();
    // delete from local storage
    deleteGlobalSearchItem(title);
  };

  const navigateToSearch = value => {
    addGlobalSearchHistoryItem(value);
    const BasePath = getModuleBasePath('search');
    history.push(`${BasePath}?query=${value}&activetab=careers`);
    setSearchKey(value);
  };

  const onSelect = debounce(data => navigateToSearch(data), 10);
  const onEnter = () => searchKey && navigateToSearch(searchKey);

  const handleMobileVisibility = e => {
    e?.stopPropagation?.();
    enableSearch(!searchEnabled);
  };

  // const fetchData = async value => {
  //   const URL = `${API.srm?.global_search || ''}?search=${value}`;
  //   const data = await services.fetchRecords(URL);
  //   const modifiedResults = data?.map(item => ({
  //     value: item,
  //     label: item,
  //   }));
  //   setResults(modifiedResults || []);
  //   setLoading(false);
  // };

  const searchProps = {
    // results,
    // loading,
    onEnter,
    onSearch,
    onSelect,
    searchKey,
    floatingHeader,
    globalSearchHistory,
    deleteGlobalSearchHistoryItem,
    placeholder: props.placeholder || STATIC_DATA?.career?.placeHolder,
  };

  return (
    <ErrorBoundary nameOfComponent='core-global-search'>
      {isMobileView && floatingHeader ? (
        <div className='global-search mobile'>
          {<SearchOutlined onClick={handleMobileVisibility} />}
          {searchEnabled && (
            <div className='mobile-search'>
              <SearchComponent {...searchProps} ref={autoCompleteRef} />
              <CloseCircleFilled onClick={handleMobileVisibility} />
            </div>
          )}
        </div>
      ) : (
        <SearchComponent {...searchProps} ref={autoCompleteRef} />
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = state => ({
  appConfig: getAppConfig(state),
  globalSearchHistory: getGlobalSearchHistory(state),
});

export default connect(mapStateToProps, {
  addGlobalSearchItem,
  getGlobalSearchItems,
  deleteGlobalSearchItem,
})(GlobalSearch);

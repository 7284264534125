import PropTypes from 'prop-types';
import {instanceerroricon} from 'assets/images';
import {Button, BackgroundPaper} from 'mui-core';
import {useTheme, styled} from '@mui/material/styles';
import {Box, Stack, Typography, useMediaQuery} from '@mui/material';

const StyledBlueButtons = styled(Stack)(({theme}) => ({
  overflow: 'auto',
  position: 'relative',
  paddingRight: '10px',
  textTransform: 'capitalize',
  '& ul > li': {
    lineHeight: 2,
    paddingBottom: '8px',
    background: 'transparent',
    color:
      theme.palette.mode === 'dark' ? 'white' : theme.palette.darkGray.main,
    listStyleType: 'none',
    textAlign: 'left',
    marginLeft: '-16px',
    '&::before': {
      content: '"\\2022"',
      position: 'absolute',
      marginTop: '-16px',
      left: '0px',
      color: '#3ac4ff',
      fontSize: '30px',
    },
  },
}));

const ErrorPage = ({
  pageRefresh = false,
  heading = 'Look Back, Something is Missing..!',
  list = ['The URL you entered', 'The internet connection'],
  subHeading = 'An unexpected error occured. Try Checking below possibilities',
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <BackgroundPaper
      borderRadius={4}
      px={{xs: 4, md: 24, lg: 32}}
      background={theme.palette.purplebg8perc.main}>
      <Stack
        height='100vh'
        alignItems='center'
        justifyContent='center'
        direction={{sm: 'column', md: 'row'}}>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <img src={instanceerroricon} alt='instance-error-image' />
        </Box>
        <Stack
          spacing={2}
          px={{xs: 2, sm: 0}}
          alignItems={isMobile ? 'center' : 'flex-start'}>
          <Typography variant='h1' color='text.primary'>
            {heading}
          </Typography>
          <Typography
            variant='h6'
            color='text.disabled'
            textAlign={isMobile ? 'center' : 'left'}>
            {subHeading}
          </Typography>
          {list && Array.isArray(list) && list?.length > 0 && (
            <Stack width='100%'>
              <StyledBlueButtons>
                <ul>
                  {list?.map((point, idx) => {
                    return (
                      <Typography
                        key={idx}
                        variant='body1'
                        component={'li'}
                        color='text.primary'>
                        {point}
                      </Typography>
                    );
                  })}
                </ul>
              </StyledBlueButtons>
            </Stack>
          )}
          {pageRefresh && (
            <Button
              color='primary'
              variant='contained'
              sx={{width: '120px'}}
              onClick={() => window.location.reload()}>
              Refresh
            </Button>
          )}
        </Stack>
      </Stack>
    </BackgroundPaper>
  );
};

ErrorPage.prototype = {
  list: PropTypes.array,
  heading: PropTypes.string,
  pageRefresh: PropTypes.bool,
  subHeading: PropTypes.string,
};

export default ErrorPage;

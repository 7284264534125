import React from 'react';
import {View, Text, StyleSheet} from '@react-pdf/renderer';

import SkillTag from './SkillTag';

const stylesheet = StyleSheet.create({
  rowFlex: {
    flexDirection: 'row',
  },
  skillTagsHeading: {
    width: '40%',
    paddingRight: '10px',
  },
  yearsOfExpHeading: {
    width: '50%',
    flexDirection: 'column',
    marginLeft: '10px',
  },
  yearsNumbers: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: '2px',
  },
  skillTagsContainer: {
    width: '40%',
    borderRight: 1,
    paddingRight: '10px',
    borderColor: '#c6c6c6',
  },
  experienceBarContainer: {
    width: '50%',
    height: '15px',
    marginLeft: '10px',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  experienceOuterBar: {
    width: '100%',
    height: '8px',
    backgroundColor: '#dfdfdf',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  experienceInnerBar: {
    height: '8px',
    backgroundColor: '#6b35ee',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
});

export default ({styles, studentProfile}) => {
  const {skills = []} = studentProfile || {};

  const sortedSkills =
    skills && Array.isArray(skills)
      ? skills.sort((a, b) => b.experience - a.experience)
      : [];

  const skillsFunc = () => {
    const getLineWidth = value => (value * 100) / 10;

    let skillsLearned = <Text style={styles.noSkills}>No skills found</Text>;

    if (skills && Array.isArray(skills) && skills?.length > 0) {
      // skillsLearned = (
      //   <View style={styles.SkillsList}>
      //     {skills.map(({skill, experience}, idx) => (
      //       <SkillTag key={`skill-${idx}`}>
      //         <Text key={idx}>{`${skill} (${experience}-${
      //           experience !== 1 ? 'yrs' : 'yr'
      //         })`}</Text>
      //       </SkillTag>
      //     ))}
      //   </View>
      // );

      skillsLearned = (
        <View>
          <View break style={stylesheet.rowFlex}>
            <Text style={stylesheet.skillTagsHeading}>Skills Tag</Text>
            <View style={stylesheet.yearsOfExpHeading}>
              <Text>Years of Experience</Text>
              <View style={stylesheet.yearsNumbers}>
                <Text>0</Text>
                <Text>10+</Text>
              </View>
            </View>
          </View>
          <View>
            {sortedSkills?.map(({skill, experience}, idx) => (
              <View
                wrap={false}
                key={`skill-${idx}`}
                style={stylesheet.rowFlex}>
                <View style={stylesheet.skillTagsContainer}>
                  <View style={stylesheet.rowFlex}>
                    <SkillTag>
                      <Text>{`${
                        skill?.length > 45
                          ? skill.substring(0, 45) + '...'
                          : skill
                      } (${experience} ${
                        experience !== 1 ? 'yrs' : 'yr'
                      })`}</Text>
                    </SkillTag>
                  </View>
                </View>
                <View style={stylesheet.experienceBarContainer}>
                  <View>
                    <View
                      className='bar-perc-h'
                      style={stylesheet.experienceOuterBar}>
                      <View
                        style={{
                          width: `${getLineWidth(experience)}%`,
                          ...stylesheet.experienceInnerBar,
                        }}
                        className='bar-perc'></View>
                    </View>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
      );
    }
    return skillsLearned;
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text break style={styles.headerText}>
          My Declared Skills
        </Text>
      </View>
      <View style={styles.content}>{skillsFunc()}</View>
    </View>
  );
};

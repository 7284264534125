// hooks
import {useState, useEffect, useImperativeHandle} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useSnackbar} from 'notistack';
// redux
import {
  fetchCareerCraft,
  getCareerCraftData,
  getLinkedInDetails,
} from 'redux/modules/resume-builder';
import {getStates} from 'redux/modules/general';
import {skillsSelector} from 'redux/modules/profile';
// components
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {datePickerSlotProp} from '../../sharedComponents';
import {Grid, Button, TextEditor, AutoCompleteWrapper} from 'mui-core';
import {
  Box,
  Stack,
  Select,
  MenuItem,
  Checkbox,
  Typography,
  OutlinedInput,
  FormControlLabel,
} from '@mui/material';
import {GoAIButton, GoAIListForm} from '../../sharedComponents';
// utils
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {createField} from 'mui-core/Form';
import resumeBuilderData from 'data/resumeBuilder.json';

const {
  stepsMeta: {
    professionalExperience: {formData: formStaticData = {}, goAIButton},
  },
  goAIRequiredText,
} = resumeBuilderData || {};

const AddWorkExperience = ({
  edit,
  formRef,
  onSubmit,
  onCancel,
  data = {},
  firstTime,
  getPayload,
}) => {
  const {
    city,
    state,
    end_date,
    job_title,
    start_date,
    is_present,
    company_name,
    description,
    skills = [],
  } = data;
  const dispatch = useDispatch();
  const form = useForm({mode: 'all'});
  const {enqueueSnackbar} = useSnackbar();
  const {data: statesList} = useSelector(getStates);
  const [openDialog, setOpenDialog] = useState(false);
  const linkedInData = useSelector(getLinkedInDetails) || {};
  const {data: skill_name = []} = useSelector(skillsSelector) || {};
  const [stepDesc, setStepDesc] = useState(description || '');
  const {data: careerCraft = []} = useSelector(getCareerCraftData);

  const {
    watch,
    reset,
    trigger,
    setValue,
    getValues,
    resetField,
    clearErrors,
    handleSubmit,
    formState: {errors, isValid},
  } = form;
  const watchIsPresent = watch('is_present', is_present || false);
  const watchEndDate = watch('end_date', end_date ? moment(end_date) : null);
  const {job_title: job_title_options = []} = linkedInData;
  const watchStartDate = watch(
    'start_date',
    start_date ? moment(start_date) : null,
  );
  const isEndDateRequired = !!watchStartDate && !watchIsPresent ? true : false;

  useEffect(() => {
    watch(values => {
      const isFormEmpty = Object.values(values).every(value =>
        Array.isArray(value) ? value.length === 0 : !value,
      );

      if (isFormEmpty) {
        setTimeout(clearErrors);
      }
    });
  }, [watch]);

  const onFinish = (newData, e) => {
    const newObj = {...newData};
    newObj['skills'] = newData.skills
      ? newData?.skills?.map(item => {
          return {
            skill: item?.skill,
          };
        })
      : skills?.map(skill => {
          return {
            skill: skill?.skill,
          };
        });
    if (onSubmit && isEmpty(errors)) {
      const updatedData = {...data, ...newObj};
      if (updatedData.start_date) {
        const {years: expStartYear, months: expStartMonth} = moment(
          updatedData.start_date,
        ).toObject();
        updatedData.start_date = moment()
          .date(5)
          .year(expStartYear)
          .month(expStartMonth)
          .toISOString();
      }
      if (updatedData.end_date) {
        const {years: expEndYear, months: expEndMonth} = moment(
          updatedData.end_date,
        ).toObject();
        updatedData.end_date = moment()
          .date(5)
          .year(expEndYear)
          .month(expEndMonth)
          .toISOString();
      }
      onSubmit(updatedData);
    }
  };

  useEffect(() => {
    reset({
      skills: filteredSkillsData || [],
    });
  }, [reset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchIsPresent) {
      resetField('end_date');
      setValue('end_date', null);
    }
  }, [watchIsPresent]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(formRef, () => ({
    validateForm() {
      let isFormValid = true;
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      if (isFormFilled) {
        trigger();
        isFormValid = isValid;
      }
      return isFormValid;
    },
    getFormData() {
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      if (isFormFilled) {
        return getPayload({...data, ...getValues()});
      } else return null;
    },
  }));

  const addToResume = data => {
    if (stepDesc?.length > 0) {
      setStepDesc(stepDesc + data);
      setValue('description', (stepDesc + data).toString().replaceAll(',', ''));
      setOpenDialog(false);
    } else {
      setStepDesc(data);
      setValue('description', data.toString().replaceAll(',', ''));
      setOpenDialog(false);
    }
  };

  const fetchCareerCraftData = () => {
    const {
      city,
      state,
      end_date,
      job_title,
      start_date,
      is_present,
      company_name,
    } = getValues();
    if (job_title && company_name && start_date && (is_present || end_date)) {
      setOpenDialog(true);
      // ------Needs to check decimal number will be there or not-------
      // let duration = moment.duration(
      //   is_present
      //     ? moment(start_date).diff(moment())
      //     : moment(start_date).diff(moment(end_date)),
      // );
      // let years = duration.asYears();
      let years = is_present
        ? moment().diff(moment(start_date), 'years')
        : moment(end_date).diff(moment(start_date), 'years');
      let obj = {
        job_title: job_title,
        company_name: company_name,
        // years_of_exp: Math.abs(years.toFixed(2)),
        years_of_exp: years,
      };
      if (city && state) {
        obj.job_location = `${city}, ${state}`;
      }
      dispatch(fetchCareerCraft('work_experience', obj));
    } else {
      enqueueSnackbar(goAIRequiredText, {
        variant: 'warning',
        anchorOrigin: {horizontal: 'center', vertical: 'top'},
      });
    }
  };

  const filteredSkillsData =
    skills &&
    Array.isArray(skills) &&
    skills.length > 0 &&
    skills.map(item => {
      return {
        ...item,
        key: item.uuid,
        label: item.skill,
        title: item.skill,
      };
    });

  return (
    <Box>
      <Stack mt={firstTime ? 0 : 1}>
        {!edit && !firstTime && (
          <Typography
            mt={1}
            variant='h5'
            color='text.primary'
            title={formStaticData.addWorkExperienceBtn}>
            {formStaticData.addWorkExperienceBtn}
          </Typography>
        )}
        <Box mx='auto' my={2}>
          <form onSubmit={handleSubmit(onFinish)}>
            <Grid
              container
              spacing={1}
              display='flex'
              rowSpacing={4}
              alignItems='center'
              justifyContent='center'>
              <Grid item xs={12}>
                {createField('job_title', {
                  form,
                  size: 'small',
                  defaultValue: job_title || '',
                  rules: {
                    required: [true, formStaticData.position.requiredMsg],
                  },
                })(
                  <AutoCompleteWrapper
                    required
                    form={form}
                    fieldName='job_title'
                    value={job_title || ''}
                    label={`${formStaticData.position.label}`}
                    placeholder={formStaticData.position.placeholder}
                    options={
                      job_title_options &&
                      Array.isArray(job_title_options) &&
                      job_title_options.length > 0
                        ? job_title_options?.map(item => {
                            return {
                              ...item,
                              key: item.id,
                              label: item.job_title,
                              title: item.job_title,
                              job_title: item.job_title,
                            };
                          })
                        : []
                    }
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {createField('company_name', {
                  form,
                  size: 'small',
                  defaultValue: company_name || '',
                  label: formStaticData.company.label,
                  rules: {
                    required: [true, formStaticData.company.requiredMsg],
                    maxLength: [60, formStaticData.company.maxLengthMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='work-exp-comp-name'
                    label={formStaticData.company.label}
                    placeholder={formStaticData.company.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                {createField('start_date', {
                  form,
                  dependent: ['end_date'],
                  rules: {
                    required: [true, formStaticData.dateStarted.requiredMsg],
                    maxDate: [
                      watchEndDate,
                      formStaticData.dateStarted.maxDateMsg,
                    ],
                  },
                  defaultValue: start_date ? moment(start_date) : null,
                })(
                  <DatePicker
                    maxDate={watchEndDate}
                    views={['year', 'month']}
                    showDaysOutsideCurrentMonth
                    slotProps={datePickerSlotProp}
                    label={formStaticData.dateStarted.label}
                  />,
                )}
              </Grid>
              <Grid item xs={6} md={4}>
                {createField('end_date', {
                  form,
                  rules: {
                    required: [
                      isEndDateRequired,
                      formStaticData.dateFinish.requiredMsg,
                    ],
                    minDate: [
                      watchStartDate,
                      formStaticData.dateFinish.minDateMsg,
                    ],
                  },
                  disabled: watchIsPresent,
                  dependent: ['start_date'],
                  defaultValue: end_date ? moment(end_date) : null,
                })(
                  <DatePicker
                    minDate={watchStartDate}
                    views={['year', 'month']}
                    showDaysOutsideCurrentMonth
                    slotProps={datePickerSlotProp}
                    label={formStaticData.dateFinish.label}
                  />,
                )}
              </Grid>
              <Grid item xs={6} md={4}>
                <Stack direction='row' alignItems='center'>
                  <Typography
                    mx={2}
                    title='Or'
                    variant='h5'
                    fontSize={18}
                    color='text.primary'>
                    Or
                  </Typography>
                  {createField(`is_present`, {
                    form,
                    size: 'small',
                    fullWidth: false,
                    valueField: 'checked',
                    defaultValue: is_present || false,
                  })(
                    <FormControlLabel
                      label={formStaticData.presentTxt}
                      control={<Checkbox id='education-is-current' />}
                    />,
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={7}>
                {createField('city', {
                  form,
                  size: 'small',
                  defaultValue: city || '',
                  label: formStaticData.city.label,
                  rules: {
                    maxLength: [60, formStaticData.city.maxLengthMsg],
                    validate: [
                      (value, formValues) =>
                        !value
                          ? !formValues.state
                            ? clearErrors('city')
                            : false
                          : true,
                      formStaticData.city.requiredMsg,
                    ],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='work-exp-job-location'
                    label={formStaticData.city.label}
                    placeholder={formStaticData.city.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12} md={5}>
                {createField('state', {
                  form,
                  size: 'small',
                  defaultValue: state || '',
                  label: formStaticData.state.label,
                  rules: {
                    validate: [
                      (value, formValues) =>
                        !value
                          ? !formValues.city
                            ? clearErrors('state')
                            : false
                          : true,
                      formStaticData.state.requiredMsg,
                    ],
                  },
                })(
                  <Select
                    variant='outlined'
                    label={formStaticData.state.label}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}>
                    <MenuItem value='' key='none'>
                      None
                    </MenuItem>
                    {statesList &&
                      Array.isArray(statesList) &&
                      statesList.length > 0 &&
                      statesList.map(item => (
                        <MenuItem value={item.name} key={item.state_uuid}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>,
                )}
              </Grid>

              <Grid item xs={12}>
                <Box
                  mb={2}
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'>
                  <Typography variant='h5' color='text.primary'>
                    {formStaticData.responsibilities.title}
                  </Typography>
                </Box>
                {createField('description', {
                  form,
                  defaultValue: description || '',
                })(
                  <TextEditor
                    goAIButton={
                      <GoAIButton
                        children={
                          <GoAIListForm
                            list={careerCraft || []}
                            submitListForm={addToResume}
                          />
                        }
                        openDialog={openDialog}
                        descriptionCenter={true}
                        setOpenDialog={setOpenDialog}
                        fetchData={fetchCareerCraftData}
                        description={goAIButton.description}
                      />
                    }
                    label={formStaticData.responsibilities.label}
                    hint={formStaticData.responsibilities.hint}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h5' color='text.primary' mb={1}>
                  {formStaticData.skills.title}
                </Typography>
                <Typography variant='body2' mb={2} color='text.primary'>
                  {formStaticData.skills.subtitle}
                </Typography>

                {createField('skills', {
                  form,
                  size: 'small',
                })(
                  <AutoCompleteWrapper
                    multiple
                    form={form}
                    label='Add Skill'
                    fieldName='skills'
                    keyNameIfMultiple='skill'
                    disableCloseOnSelect={true}
                    value={filteredSkillsData || []}
                    defaultValue={filteredSkillsData || []}
                    placeholder='Example: Python, NodeJS, CSS, etc. '
                    options={
                      skill_name &&
                      Array.isArray(skill_name) &&
                      skill_name.length > 0
                        ? skill_name?.map((item, idx) => {
                            return {
                              ...item,
                              key: idx,
                              label: item.skill_name || item.name,
                              title: item.skill_name || item.name,
                              skill: item.skill_name || item.name,
                            };
                          })
                        : []
                    }
                  />,
                )}
              </Grid>
              <Grid item>
                {!firstTime && (
                  <Button
                    variant='outlined'
                    onClick={onCancel}
                    sx={{mr: 2, minWidth: '100px'}}>
                    {formStaticData.cancelBtnTxt}
                  </Button>
                )}
                <Button
                  type='submit'
                  formNoValidate
                  variant='contained'
                  sx={{minWidth: '100px'}}>
                  {formStaticData.addBtnTxt}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default AddWorkExperience;

import {useCheckMobileView} from 'core/hooks';
import {textloading} from 'assets/images';
import searchData from 'data/search.json';
import './style.less';

const SearchResultsCounter = ({
  className = 'search-result-text pt-1 pb-2',
  isClubbedResults,
  currentLength,
  totalResults,
  forTitle,
  request,
}) => {
  let formattedTotalResults =
    (totalResults && Number(totalResults).toLocaleString()) || 0;
  if (isClubbedResults && formattedTotalResults === '10,000') {
    formattedTotalResults = `${formattedTotalResults}+`;
  }
  const currentData = (
    <span>
      {request ? (
        <img
          alt='loader'
          src={textloading}
          height='5px'
          width='20px'
          style={{opacity: 0.5}}
        />
      ) : (
        currentLength
      )}
    </span>
  );
  const {isMobileView} = useCheckMobileView();
  if (isMobileView) {
    return (
      <div className={className}>
        {currentData} <em>/</em>
        <span className='strong'>{formattedTotalResults}</span>
      </div>
    );
  }
  return (
    <div className={className}>
      {searchData.showingOfResultsFor.showingTxt} {currentData}{' '}
      {searchData.showingOfResultsFor.ofTxt}{' '}
      <span className='strong'>{formattedTotalResults}</span>{' '}
      {searchData.showingOfResultsFor.resultsTxt}
      {` ${forTitle ? searchData.showingOfResultsFor.forTxt : ''}`}
      {forTitle && <span> '{`${unescape(forTitle)}`}'</span>}
    </div>
  );
};

export default SearchResultsCounter;

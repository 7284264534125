import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import {capitalizeFirstLetter} from 'core/utils';

const FormControlLabelComponent = styled(FormControlLabel)(({theme}) => ({
  '& 	.MuiFormControlLabel-label': {
    textTransform: 'capitalize',
  },
}));

const ViewLabel = styled(Typography)(({theme}) => ({
  color: theme.palette.mode === 'light' ? theme.palette.grayText.main : 'white',
}));

const Filter = ({typesOfCourses = [], onLabelChange, onSwitchChange}) => {
  const handleCheckBox = event => {
    const {name, checked} = (event && event.target) || {};
    console.log(name, checked);
    if (onLabelChange) onLabelChange(name, checked);
  };
  return (
    <Stack
      direction={{xs: 'column', md: 'row'}}
      spacing={2}
      alignItems='center'
      justifyContent={'space-between'}>
      <Stack direction='row' spacing={2} alignItems='center'>
        {typesOfCourses &&
          Array.isArray(typesOfCourses) &&
          typesOfCourses.length > 0 && (
            <Typography variant='h6' color='GrayText'>
              Show:
            </Typography>
          )}
        <FormGroup row>
          {Array.isArray(typesOfCourses) &&
            typesOfCourses.map(types => (
              <FormControlLabelComponent
                id={types.type}
                name={types.type}
                control={
                  <Checkbox
                    onChange={handleCheckBox}
                    defaultChecked
                    color={types.color}
                  />
                }
                label={
                  <Typography variant='h6' color='GrayText'>
                    {capitalizeFirstLetter(types.label)}
                  </Typography>
                }
              />
            ))}
        </FormGroup>
      </Stack>

      <Stack>
        <FormGroup>
          <FormControlLabelComponent
            control={
              <Stack direction='row' spacing={1} alignItems='center'>
                <ViewLabel variant='h6'>List View</ViewLabel>
                <Switch color='success' onChange={onSwitchChange} />
                <ViewLabel variant='h6'>Map View</ViewLabel>
              </Stack>
            }
          />
        </FormGroup>
      </Stack>
    </Stack>
  );
};
export default Filter;

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {noCollageLogo} from 'assets/images';
import {styled} from '@mui/material/styles';
import {numberInUSFormat} from 'core/utils';
import {Card, LazyImage, ErrorBoundary} from 'mui-core';
import {industryIconBlack, industryIconWhite} from 'assets/images';
import {
  Box,
  Stack,
  Paper,
  useTheme,
  Typography,
  CardActionArea,
} from '@mui/material';

const LogoContainer = styled(Paper)(({theme}) => ({
  zIndex: 1,
  width: 200,
  height: 60,
  display: 'flex',
  borderRadius: 4,
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  bottom: 'calc(100% - 30px)',
  padding: '8px auto !important',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
  backgroundColor: theme.palette.background.paper,
  '& img': {
    height: '100%',
    padding: '4px',
    maxWidth: '100%',
    objectFit: 'contain',
  },
}));

const BusinessPartnerCard = ({
  data,
  width,
  target = '_blank',
  enableNavigation = false,
}) => {
  const {
    name,
    active_jobs = 0,
    logo_cloudinary,
    banner_cloudinary,
    active_pathways = 0,
    industry_sector_name,
  } = data || {};

  const theme = useTheme();
  const history = useHistory();
  const {
    location: {pathname},
  } = history;
  const JobCount = active_jobs ? numberInUSFormat(active_jobs) : 0;
  const industryIcon =
    theme?.palette?.mode === 'light' ? industryIconBlack : industryIconWhite;

  const pathNavigateTo = () =>
    target === '_self' || pathname === '/'
      ? history.push(`/companies/${encodeURIComponent(name)}`)
      : window.open(`/companies/${encodeURIComponent(name)}`, target);

  return (
    <ErrorBoundary nameOfComponent='core-eventcard'>
      <Card
        sx={{
          mb: 2,
          width,
          textAlign: 'left',
          position: 'relative',
          display: 'inline-block',
        }}
        component={CardActionArea}
        onClick={() => enableNavigation && pathNavigateTo(data)}>
        <Box height={180}>
          <LazyImage
            type='bp'
            renderSrcSet
            alt='company-banner'
            src={banner_cloudinary}
          />
        </Box>
        <Box
          p={2}
          pt={3}
          display={'flex'}
          position='relative'
          justifyContent={'center'}>
          <LogoContainer>
            <LazyImage
              alt='bp_logo'
              imageType='cardLogo'
              src={logo_cloudinary}
              defaultImage={noCollageLogo}
            />
          </LogoContainer>
        </Box>
        <Stack mx={2} height={60}>
          <Typography
            noWrap
            variant='h6'
            color='text.primary'
            title={`${name || ''} `}>
            {name || ''}
          </Typography>
          {industry_sector_name && (
            <Stack direction={'row'} mt={1} spacing={1}>
              <img
                width={18}
                height={18}
                src={industryIcon}
                alt='industry Icon'
              />
              <Typography
                noWrap
                variant='body2'
                color='text.primary'
                title={`${industry_sector_name || ''} `}>
                {industry_sector_name || ''}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack mt={1} mb={2} spacing={1} sx={{height: 50}}>
          {active_jobs > 0 && (
            <Typography
              py='2px'
              pl='15px'
              width='180px'
              variant='body2'
              color={'white'}
              borderRadius='0 50px 50px 0'
              backgroundColor='primary.main'>{`${JobCount} Opportunities`}</Typography>
          )}
          {active_pathways > 0 && (
            <Typography
              py='2px'
              pl='15px'
              width='210px'
              variant='body2'
              color={'white'}
              borderRadius='0 50px 50px 0'
              backgroundColor='secondary.main'>{`${active_pathways} Qualifying Programs`}</Typography>
          )}
        </Stack>
      </Card>
    </ErrorBoundary>
  );
};

BusinessPartnerCard.propTypes = {
  data: PropTypes.object,
  width: PropTypes.number,
  enableNavigation: PropTypes.bool,
};

export default BusinessPartnerCard;

import mySkillsData from 'data/mySkills.json';
import {Stack, Typography} from '@mui/material';
import {Stats, ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {
  green,
  yellow,
  blueGrey,
  lightBlue,
  deepPurple,
} from '@mui/material/colors';

const MySkillsStats = ({studentMetrics}) => {
  const {
    data: {
      skills_metrics: {
        total_skills_count = 0,
        course_skills_count = 0,
        license_skills_count = 0,
        program_skills_count = 0,
        personal_skills_count = 0,
        education_skills_count = 0,
      } = {},
    } = {},
  } = studentMetrics || {};

  const prepareStatsData = () => {
    const statsData = [
      {
        title: 'Total Skills',
        count: total_skills_count,
        color: lightBlue[600],
      },
      {
        title: 'Education Skills',
        count: education_skills_count,
        color: green[400],
      },
      {
        title: 'Program Skills',
        count: program_skills_count,
        color: deepPurple[400],
      },
      {
        title: 'Course Skills',
        count: course_skills_count,
        color: yellow[400],
      },
      {
        title: 'Personal Skills',
        count: personal_skills_count,
        color: lightBlue[400],
      },
      {
        title: 'Licenses & Certifications Skills',
        count: license_skills_count,
        color: blueGrey[400],
      },
    ];
    return statsData;
  };

  const statsData = prepareStatsData();

  return (
    <Stack spacing={3}>
      <Typography variant='h5' color='text.primary'>
        {mySkillsData.mySkills.Heading}
      </Typography>
      <ErrorBoundary nameOfComponent='mod-comp-dashboard-stats'>
        <RequestErrorLoader fullWidth hideEmpty body={studentMetrics}>
          <Stats data={statsData} gridWidth={{md: 4, lg: 2.4}} />
        </RequestErrorLoader>
      </ErrorBoundary>
      <Typography
        variant='body1'
        color='text.primary'
        sx={{mb: '16px !important'}}>
        {mySkillsData.mySkills.welcomeText}
      </Typography>
    </Stack>
  );
};
export default MySkillsStats;

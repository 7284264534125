import {useEffect, useCallback, useMemo} from 'react';
import {
  Box,
  Stack,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  Typography,
  OutlinedInput,
  FormControlLabel,
} from '@mui/material';
import moment from 'moment';
import {PATTERN} from 'core/regex';
import isEmpty from 'lodash/isEmpty';
import {Button, Grid} from 'mui-core';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {SkillsSelect} from '../../components';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import profileData, {
  skillsData as skillsStaticData,
} from 'data/settings-profile.json';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const {
  labelsMui: labels = {},
  placeholdersMui: placeholders = {},
  errorMessagesMui: {
    orgName: {required: orgNameRequiredError, maxLength: orgNameCharLimitError},
    schoolStartDate: {
      required: schoolStartDateRequiredError,
      maxDate: schoolStartMaxDateErr,
    },
    schoolEndDate: {
      required: schoolEndDateRequiredError,
      minDate: schoolEndMinDateErr,
    },
    areaOfFocus: {maxLength: areaOfFocusCharLimitError},
    comment: {maxLength: commentCharLimitError},
  } = {},
} = skillsStaticData || {};

const AddEducation = ({
  edit,
  skills: SkillsDataSet,
  onSubmit,
  onCancel,
  data: {
    gpa,
    skills,
    comment,
    org_name,
    start_date,
    end_date,
    active_path = false,
    award_type,
    updated_at,
    is_present,
    area_of_focus,
    school_end_date,
    school_start_date,
    student_onboard_uuid,
  } = {},
  firstTime,
  awardTypes = {},
}) => {
  const form = useForm({});
  const {data: SkillsList = []} = SkillsDataSet || {};
  const {data: awardTypesData = []} = awardTypes || {};

  if (student_onboard_uuid) {
    if (start_date && end_date) {
      school_start_date = moment(start_date) || null;
      school_end_date = moment(end_date) || null;
    } else {
      school_start_date = moment(updated_at) || null;
      school_end_date = moment(updated_at) || null;
    }
  } else {
    school_start_date =
      (school_start_date && moment(school_start_date)) || null;
    school_end_date = (school_end_date && moment(school_end_date)) || null;
  }

  const getInitialSkills = useCallback(
    skillsSaved => {
      let selectedSkills = [];
      if (
        skillsSaved &&
        Array.isArray(skillsSaved) &&
        skillsSaved.length &&
        SkillsList &&
        Array.isArray(SkillsList) &&
        SkillsList.length
      ) {
        selectedSkills = SkillsList.filter(skill =>
          skillsSaved.includes(skill.name),
        );
      }
      return selectedSkills;
    },
    [SkillsList],
  );

  const {
    watch,
    control,
    setValue,
    resetField,
    handleSubmit,
    formState: {errors},
  } = form;

  const watchIsPresent = watch('is_present', is_present || false);
  const watchEndDate = watch(
    student_onboard_uuid ? 'end_date' : 'school_end_date',
    school_end_date,
  );
  const watchStartDate = watch(
    student_onboard_uuid ? 'start_date' : 'school_start_date',
    school_start_date,
  );
  const isEndDateRequired = !!watchStartDate && !watchIsPresent ? true : false;

  const onFinish = (data, e) => {
    if (onSubmit && isEmpty(errors)) {
      onSubmit(data, student_onboard_uuid);
    }
  };

  useEffect(() => {
    if (watchIsPresent) {
      resetField(student_onboard_uuid ? 'end_date' : 'school_end_date');
      setValue(student_onboard_uuid ? 'end_date' : 'school_end_date', null);
    }
  }, [watchIsPresent]); // eslint-disable-line react-hooks/exhaustive-deps

  const hideInputField = active_path === true ? true : false;

  return (
    <Box>
      <>
        {!edit && !firstTime && (
          <Typography
            variant='h5'
            fontSize={18}
            fontWeight='600'
            title='Education'
            color='text.primary'
            // fontFamily='fontFamilyBold'
          >
            Add Education
          </Typography>
        )}
        <Box
          mx='auto'
          my={2}
          sx={{width: {md: '60%', sm: '60%', lg: '60%', xs: '100%'}}}>
          <form onSubmit={handleSubmit(onFinish)}>
            <Grid
              container
              spacing={4}
              display='flex'
              alignItems='center'
              flexDirection='column'
              justifyContent='center'>
              <Grid item xs={12}>
                {createField('org_name', {
                  form,
                  label: labels.schoolText,
                  disabled: hideInputField,
                  defaultValue: org_name || '',
                  rules: {
                    required: [true, orgNameRequiredError],
                    maxLength: [60, orgNameCharLimitError],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='education-org-name'
                    label={labels.schoolText}
                  />,
                )}
              </Grid>
              <Grid container px={2} pb={2}>
                <Grid item xs={12} pb={0} px={3}>
                  <Typography
                    variant='h5'
                    color='text.primary'
                    // fontFamily='fontFamilyBold'
                    title={labels.timePeriodText}>
                    {labels.timePeriodText}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4.5}>
                  {createField(
                    student_onboard_uuid ? 'start_date' : 'school_start_date',
                    {
                      form,
                      rules: {
                        required: [true, schoolStartDateRequiredError],
                        // maxDate: [watchEndDate, schoolStartMaxDateErr],
                        validate: [
                          date => {
                            let inValid = moment(date).isAfter(new Date());
                            if (!inValid && !watchIsPresent)
                              inValid = moment(date).isAfter(watchEndDate);
                            return !inValid;
                          },
                          moment(watchStartDate).isAfter(new Date())
                            ? 'Must be less than present date'
                            : schoolStartMaxDateErr,
                        ],
                      },
                      dependent: [
                        student_onboard_uuid ? 'end_date' : 'school_end_date',
                      ],
                      defaultValue: school_start_date
                        ? moment(school_start_date)
                        : undefined,
                    },
                  )(
                    <DatePicker
                      disableFuture
                      maxDate={watchEndDate}
                      showDaysOutsideCurrentMonth
                      label={labels.startDateText}
                      views={['year', 'month', 'day']}
                    />,
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7.5}>
                  <Stack direction='row' alignItems='center'>
                    {createField(
                      student_onboard_uuid ? 'end_date' : 'school_end_date',
                      {
                        form,
                        rules: {
                          required: [
                            isEndDateRequired,
                            schoolEndDateRequiredError,
                          ],
                          validate: [
                            date => {
                              let inValid = moment(date).isAfter(new Date());
                              if (!inValid)
                                inValid = moment(date).isBefore(watchStartDate);
                              return !inValid;
                            },
                            moment(watchEndDate).isAfter(new Date())
                              ? 'Must be less than present date'
                              : schoolEndMinDateErr,
                          ],
                          // minDate: [watchStartDate, schoolEndMinDateErr],
                        },
                        disabled: watchIsPresent,
                        dependent: [
                          student_onboard_uuid
                            ? 'start_date'
                            : 'school_start_date',
                        ],
                        defaultValue: school_end_date
                          ? moment(school_end_date)
                          : undefined,
                      },
                    )(
                      <DatePicker
                        disableFuture
                        minDate={watchStartDate}
                        label={labels.endDateText}
                        showDaysOutsideCurrentMonth
                        views={['year', 'month', 'day']}
                      />,
                    )}
                    <Typography
                      mx={3}
                      variant='h5'
                      title='Or'
                      color='text.primary'>
                      Or
                    </Typography>
                    {createField(`is_present`, {
                      form,
                      fullWidth: false,
                      valueField: 'checked',
                      defaultValue: is_present,
                    })(
                      <FormControlLabel
                        label='Present'
                        control={<Checkbox id='education-is-current' />}
                      />,
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {createField('award_type', {
                  label: labels.degreeText,
                  form,
                  defaultValue: award_type || '',
                  disabled: hideInputField,
                })(
                  <Select
                    fullWidth
                    MenuProps={MenuProps}
                    label={labels.degreeText}
                    placeholder={placeholders.degreePlaceHolderText}>
                    {awardTypesData &&
                      Array.isArray(awardTypesData) &&
                      awardTypesData.length &&
                      awardTypesData.map(award => (
                        <MenuItem key={award.uuid} value={award.uuid}>
                          {award.options}
                        </MenuItem>
                      ))}
                  </Select>,
                )}
              </Grid>
              <Grid item xs={12}>
                {createField('area_of_focus', {
                  label: labels.areaOfFocusText,
                  form,
                  defaultValue: area_of_focus,
                  disabled: hideInputField,
                  rules: {
                    maxLength: [60, areaOfFocusCharLimitError],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='education-area-of-focus'
                    label={labels.areaOfFocusText}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {createField('gpa', {
                  form,
                  label: labels.gpsText,
                  defaultValue: gpa || '',
                  rules: {
                    pattern: [
                      PATTERN?.Number_Up_To_Two_Decimal,
                      'Enter a value greater than 0 having 2 decimal places',
                    ],
                  },
                })(
                  <OutlinedInput
                    type='number'
                    variant='outlined'
                    id='education-gpa'
                    error={errors?.gpa}
                    label={labels.gpaText}
                    helperText={errors?.gpa?.message}
                    placeholder={placeholders.gpaPlaceholderText}
                    inputProps={{
                      min: 0,
                      max: 999,
                      step: 0.1,
                    }}
                  />,
                )}
              </Grid>
              {SkillsList && Array.isArray(SkillsList) && SkillsList.length && (
                <Grid item xs={12}>
                  {createField('skills', {
                    form,
                    disabled: hideInputField,
                    defaultValue: getInitialSkills(skills) || [],
                  })(
                    <SkillsSelect
                      name={'skills'}
                      fullWidth={true}
                      control={control}
                      skillColor='green'
                      skills={SkillsDataSet}
                      errors={errors?.skills}
                      label={labels.skillsText}
                      id='education-skills-box'
                      placeholder={placeholders.skillsPlaceHolderText || ''}
                    />,
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                {createField(student_onboard_uuid ? 'comments' : 'comment', {
                  form,
                  defaultValue: comment,
                  rules: {
                    maxLength: [500, commentCharLimitError],
                  },
                })(
                  <TextField
                    multiline
                    minRows={2}
                    maxRows={6}
                    error={errors?.comment}
                    label={labels.commentsText}
                    helperText={errors?.comment?.message}
                    placeholder={placeholders.commentsPlaceHolderText}
                  />,
                )}
              </Grid>
              <Grid item>
                {!firstTime && (
                  <Button variant='outlined' onClick={onCancel} sx={{mr: 2}}>
                    {profileData.cancelBtnTxt}
                  </Button>
                )}
                <Button variant='contained' type='submit' formNoValidate>
                  {edit ? profileData.saveBtnTxt : profileData.addBtnTxt}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </>
    </Box>
  );
};

export default AddEducation;

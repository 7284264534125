import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Bar} from 'react-chartjs-2';
import {ErrorBoundary} from 'core/components';
import './rounded-bars';
import './style.less';

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  // aspectRatio: 1,
  cornerRadius: 10,
  // height: 200,
  legend: {
    display: false,
  },

  scales: {
    xAxes: [
      {
        //barThickness: 12,
        display: false,
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          // max: 5,
          // min: 0,
          // stepSize: 1,
        },
      },
    ],
  },
};

const BarChart = ({
  labels,
  data,
  height = 80,
  actionComponent,
  onActionClick,
  onLabelClick,
  className,
  yAxisLabelFormatter,
  toolTipFormatter,
  // scrollable,
  maxTicks,
  tickStepSize,
}) => {
  // const isScroll = scrollable && labels && labels.length > 5;
  // const canvasWidth =
  //   labels && labels.length > 5 && isScroll ? labels.length * 120 : '100%';
  // useEffect(() => {
  if (yAxisLabelFormatter) {
    chartOptions.scales.yAxes[0].ticks.callback = yAxisLabelFormatter;
  }
  if (maxTicks) {
    chartOptions.scales.yAxes[0].ticks.max = maxTicks;
  }
  if (tickStepSize) {
    chartOptions.scales.yAxes[0].ticks.stepSize = tickStepSize;
  }
  if (toolTipFormatter) {
    chartOptions['tooltips'] = {callbacks: {label: toolTipFormatter}};
  }
  // if (isScroll) {
  // chartOptions.responsive = false;
  // }
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const chartLabels =
    labels && labels.length < 5
      ? labels.concat(new Array(5 - labels.length).fill(''))
      : labels;
  const getData = canvas => {
    const chartData = {
      labels: chartLabels,
      datasets: [{data: data}],
    };
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(50, 0, 190, 500);
      canvas.style.borderRadius = '100px';
      gradient.addColorStop(0, '#76bedc');
      gradient.addColorStop(1, '#3bc4ff');
      chartData.datasets[0].backgroundColor = gradient;
      chartData.datasets[0].hoverBackgroundColor = gradient;
      chartData.datasets[0].borderWidth = 0;
      chartData.datasets[0].barThickness = 22;
      // if (isScroll) {
      // canvas.width = canvasWidth;
      // canvas.height = 300;
      // }
      // canvas.height = height;

      return chartData;
    }
  };
  const onClickAction = (idx, itm) => {
    if (onActionClick && itm) onActionClick(idx, itm);
  };
  const onClickLabel = (idx, label) => {
    if (onLabelClick && label) onLabelClick(idx, label);
  };
  const getAxisMargin = () => {
    if (chartLabels && chartLabels.length > 0 && data && data.length > 0) {
      let largest = 0;
      for (let i = 0; i <= largest; i++) {
        if (data[i] > largest) {
          largest = data[i];
        }
      }
      const len = yAxisLabelFormatter
        ? yAxisLabelFormatter(largest).toString().length
        : largest.toString().length;
      return 25 + len * 3;
    }
  };
  return (
    <ErrorBoundary nameOfComponent='core-bar-graph'>
      <div className={`bar-chart-container ${className ? className : ''}`}>
        <div>
          {/* <div> */}
          <Bar data={getData} options={chartOptions} height={height || 100} />
          {/* </div> */}
          <div
            className='chart-lbl-container'
            style={{
              marginLeft: getAxisMargin(),
              // width: canvasWidth,
              paddingRight: getAxisMargin(),
            }}>
            {chartLabels &&
              Array.isArray(chartLabels) &&
              chartLabels.map((itm, idx) => (
                <div className='axis-container' key={idx}>
                  <div
                    className='label-item'
                    title={itm}
                    onClick={() => onClickLabel(idx, itm)}>
                    {itm}
                  </div>
                  {actionComponent && itm && (
                    <div
                      className='action-btn'
                      onClick={() => onClickAction(idx, itm)}>
                      {actionComponent}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default BarChart;

BarChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.number),
  height: PropTypes.number,
  actionComponent: PropTypes.any,
  onLabelClick: PropTypes.func,
  onActionClick: PropTypes.func,
  className: PropTypes.string,
  yAxisLabelFormatter: PropTypes.func, // y axis label formatter: (label, index, labels) => {}
  toolTipFormatter: PropTypes.func, //formatter for tooltip: (tooltipItem, data) => {}
};

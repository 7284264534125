import {useState, useEffect, useImperativeHandle} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {Button, Grid, TextEditor} from 'mui-core';
import resumeBuilderData from 'data/resumeBuilder.json';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {Box, Stack, Typography, OutlinedInput} from '@mui/material';
import {
  GoAIButton,
  GoAIListForm,
  datePickerSlotProp,
} from '../../sharedComponents';
import {
  fetchCareerCraft,
  getCareerCraftData,
} from 'redux/modules/resume-builder';

const {
  stepsMeta: {
    volunteerExp: {formData = {}, goAIButton},
  },
} = resumeBuilderData || {};

const AddVolunteerExperience = ({
  edit,
  formRef,
  onSubmit,
  onCancel,
  firstTime,
  data = {},
  getPayload,
}) => {
  const dispatch = useDispatch();
  const {role, end_date, start_date, description, name} = data || {};

  const {
    endDate,
    addBtnTxt,
    startDate,
    optionalText,
    cancelBtnTxt,
    role: roleField,
    AddVolunteerExpTxt,
    volunteerProgramName,
    addAdditionalDetailsText,
    description: descriptionField,
  } = formData || {};

  const form = useForm({mode: 'all'});
  const {enqueueSnackbar} = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [stepDesc, setStepDesc] = useState(description || '');
  const {
    watch,
    trigger,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: {errors, isValid},
  } = form;
  const {data: careerCraft = []} = useSelector(getCareerCraftData);

  const watchStartDate = watch(
    'start_date',
    start_date ? moment(start_date) : null,
  );
  const watchEndDate = watch('end_date', end_date ? moment(end_date) : null);
  const isStartDateRequired = !!watchEndDate ? true : false;
  const isEndDateRequired = !!watchStartDate ? true : false;

  useEffect(() => {
    watch(values => {
      const isFormEmpty = Object.values(values).every(value =>
        Array.isArray(value) ? value.length === 0 : !value,
      );

      if (isFormEmpty) {
        setTimeout(clearErrors);
      }
    });
  }, [watch]);

  const onFinish = (newData, e) => {
    if (onSubmit && isEmpty(errors)) {
      const updatedData = {...data, ...newData};
      if (updatedData.start_date) {
        const {years: volExpStartYear, months: volExpStartMonth} = moment(
          updatedData.start_date,
        ).toObject();
        updatedData.start_date = moment()
          .date(5)
          .year(volExpStartYear)
          .month(volExpStartMonth)
          .toISOString();
      }
      if (updatedData.end_date) {
        const {years: volExpEndYear, months: volExpEndMonth} = moment(
          updatedData.end_date,
        ).toObject();
        updatedData.end_date = moment()
          .date(5)
          .year(volExpEndYear)
          .month(volExpEndMonth)
          .toISOString();
      }
      onSubmit(updatedData);
    }
  };

  useImperativeHandle(formRef, () => ({
    validateForm() {
      let isFormValid = true;
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      if (isFormFilled) {
        trigger();
        isFormValid = isValid;
      }
      return isFormValid;
    },
    getFormData() {
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      return isFormFilled ? getPayload({...data, ...getValues()}) : null;
    },
  }));

  const addToResume = data => {
    if (stepDesc?.length > 0) {
      setStepDesc(stepDesc + data);
      setValue('description', (stepDesc + data).toString().replaceAll(',', ''));
      setOpenDialog(false);
    } else {
      setStepDesc(data);
      setValue('description', data.toString().replaceAll(',', ''));
      setOpenDialog(false);
    }
  };

  const fetchCareerCraftData = () => {
    const {name, role, end_date, start_date} = getValues();
    if (name && role && start_date && end_date) {
      setOpenDialog(true);
      let years = start_date
        ? moment().diff(moment(start_date), 'years')
        : moment(end_date).diff(moment(start_date), 'years');
      let obj = {
        org_name: name,
        role: role,
        years: years,
      };
      dispatch(fetchCareerCraft('voluntary_exp', obj));
    } else {
      enqueueSnackbar('Please fill the required fields to proceed with GOAI.', {
        variant: 'warning',
        anchorOrigin: {horizontal: 'center', vertical: 'top'},
      });
    }
  };

  return (
    <Box>
      <Stack mt={firstTime ? 0 : 1}>
        {!edit && !firstTime && (
          <Typography
            mt={1}
            variant='h5'
            color='text.primary'
            title={AddVolunteerExpTxt}>
            {AddVolunteerExpTxt}
          </Typography>
        )}
        <Box mx='auto' my={2}>
          <form onSubmit={handleSubmit(onFinish)}>
            <Grid
              container
              spacing={1}
              display='flex'
              rowSpacing={4}
              columnSpacing={4}
              alignItems='center'
              justifyContent='center'>
              <Grid item xs={12}>
                {createField('name', {
                  form,
                  size: 'small',
                  label: volunteerProgramName.label,
                  defaultValue: name || '',
                  rules: {
                    required: [true, volunteerProgramName.requiredMsg],
                    maxLength: [120, volunteerProgramName.maxLengthMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    label={volunteerProgramName.label}
                    id='resume-volunteer-program-name'
                    placeholder={volunteerProgramName.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {createField('role', {
                  form,
                  size: 'small',
                  label: roleField.label,
                  defaultValue: role || '',
                  rules: {
                    maxLength: [60, roleField.maxLengthMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    label={roleField.label}
                    id='resume-volunteer-role'
                    placeholder={roleField.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                {createField('start_date', {
                  form,
                  dependent: ['end_date'],
                  rules: {
                    maxDate: [
                      watchEndDate || moment(new Date()),
                      startDate.maxDateMsg,
                    ],
                    required: [
                      isStartDateRequired || true,
                      startDate.requiredMsg,
                    ],
                  },
                  defaultValue: start_date ? moment(start_date) : null,
                })(
                  <DatePicker
                    label={startDate.label}
                    views={['year', 'month']}
                    showDaysOutsideCurrentMonth
                    slotProps={datePickerSlotProp}
                    maxDate={watchEndDate || moment(new Date())}
                  />,
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                {createField('end_date', {
                  form,
                  dependent: ['start_date'],
                  defaultValue: end_date ? moment(end_date) : null,
                  rules: {
                    maxDate: [new Date(), endDate.maxDateMsg],
                    minDate: [watchStartDate, endDate.minDateMsg],
                    required: [isEndDateRequired, endDate.requiredMsg],
                  },
                })(
                  <DatePicker
                    label={endDate.label}
                    minDate={watchStartDate}
                    views={['year', 'month']}
                    showDaysOutsideCurrentMonth
                    maxDate={moment(new Date())}
                    slotProps={datePickerSlotProp}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                <Stack
                  mb={2}
                  spacing={1}
                  direction='row'
                  alignItems='center'
                  justifyContent='start'>
                  <Typography
                    variant='h5'
                    color='text.primary'
                    title={addAdditionalDetailsText}>
                    {addAdditionalDetailsText}
                  </Typography>
                  <Typography
                    variant='body1'
                    color='text.primary'
                    title={addAdditionalDetailsText}>
                    {`(${optionalText})`}
                  </Typography>
                </Stack>
                {createField('description', {
                  form,
                  defaultValue: description || '',
                })(
                  <TextEditor
                    goAIButton={
                      <GoAIButton
                        children={
                          <GoAIListForm
                            list={careerCraft || []}
                            submitListForm={addToResume}
                          />
                        }
                        openDialog={openDialog}
                        descriptionCenter={true}
                        setOpenDialog={setOpenDialog}
                        fetchData={fetchCareerCraftData}
                        description={goAIButton.description}
                      />
                    }
                    label={descriptionField.label}
                  />,
                )}
              </Grid>
              <Grid item>
                {!firstTime && (
                  <Button
                    variant='outlined'
                    onClick={onCancel}
                    sx={{mr: 2, minWidth: '100px'}}>
                    {cancelBtnTxt}
                  </Button>
                )}
                <Button
                  type='submit'
                  formNoValidate
                  variant='contained'
                  sx={{minWidth: '100px'}}>
                  {addBtnTxt}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default AddVolunteerExperience;

import {useCallback, useEffect, useState} from 'react';
import Filter from './Filter';
import MapView from './MapView';
import Box from '@mui/material/Box';
import TableView from './TableView';
import {InfoModal} from './InfoModal';
import {ErrorBoundary} from 'mui-core';
const colors = {
  core: 'secondary',
  general: 'primary',
  elective: 'warning',
};
const CourseMap = ({myPlanData, allPrograms, showModal}) => {
  const [open, setOpen] = useState(false);
  const [mapView, setMapView] = useState(false);
  const [showLabels, setShowLabels] = useState([]);
  const [displayTerms, setDisplayTerms] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [typesOfCourses, setTypesOfCourses] = useState([]);
  const [courseDropDownValue, setCourseDropDownValue] = useState({
    title: allPrograms?.[0]?.path_details?.title,
    collegeName: allPrograms?.[0]?.path_details?.institute_details?.name,
  });

  useEffect(() => {
    if (
      myPlanData.data &&
      Array.isArray(myPlanData.data.terms) &&
      myPlanData.data.terms.length
    ) {
      setDisplayTerms([...myPlanData.data.terms]);
      const labels = [];
      myPlanData.data.terms.forEach(term => {
        if (term.courses && term.courses.length) {
          term.courses.forEach(course => {
            if (
              course.course_type &&
              !labels.includes(course.course_type.toLowerCase())
            ) {
              labels.push(course.course_type.toLowerCase());
            }
          });
        }
      });
      const types = labels.map(label => {
        const Label = label.toLowerCase();
        const obj = {
          type: Label,
          label: Label === 'general' ? 'General Ed' : Label,
          color: colors[Label],
        };
        return obj;
      });
      setTypesOfCourses([...types]);
      setShowLabels([...labels]);
    } else {
      setDisplayTerms([]);
      setShowLabels([]);
      setTypesOfCourses([]);
    }
    setCourseDropDownValue({
      title: myPlanData?.data?.title,
      collegeName: myPlanData?.data?.institute_details?.name,
    });
    return () => {};
  }, [myPlanData.data]);

  const filterTermCourses = useCallback(
    labels => {
      if (
        myPlanData.data &&
        myPlanData.data.terms &&
        Array.isArray(myPlanData.data.terms)
      ) {
        const filteredTermCourses = [];
        const terms = Object.assign([], myPlanData.data.terms);
        terms &&
          terms.forEach(term => {
            const filteredCourses = [];
            term &&
              Array.isArray(term.courses) &&
              term.courses.forEach(course => {
                if (course.course_type && course.course_type.toLowerCase()) {
                  if (
                    labels &&
                    labels.indexOf(course.course_type.toLowerCase()) !== -1
                  )
                    filteredCourses.push(course);
                }
                if (course.course_type === null) {
                  filteredCourses.push(course);
                }
              });
            filteredTermCourses.push({
              ...term,
              courses: filteredCourses,
            });
          });
        setDisplayTerms(filteredTermCourses);
      }
    },
    [myPlanData.data],
  );

  const onLabelChange = useCallback(
    (filter, value) => {
      let newLabels = showLabels;
      newLabels = value
        ? [...showLabels, filter]
        : showLabels.filter(val => val !== filter);
      setShowLabels(newLabels);
      filterTermCourses(newLabels);
    },
    [filterTermCourses, showLabels],
  );
  const onViewChange = event => {
    setMapView(event.target.checked);
  };
  const handleClickModalOpen = value => {
    setSelectedValue(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ErrorBoundary nameOfComponent='core-course-map'>
      <Filter
        typesOfCourses={typesOfCourses}
        onLabelChange={onLabelChange}
        onSwitchChange={onViewChange}
      />
      {showModal && (
        <InfoModal
          open={open}
          onClose={handleClose}
          selectedValue={selectedValue}
        />
      )}
      <Box mt={3}>
        {displayTerms &&
          Array.isArray(displayTerms) &&
          displayTerms.length > 0 &&
          displayTerms.map((term, sIdx) => {
            const isLast = sIdx === displayTerms.length - 1;
            return (
              <>
                {mapView ? (
                  <MapView
                    term={term}
                    index={sIdx}
                    colors={colors}
                    isLast={isLast}
                    showModal={showModal}
                    handleClickModalOpen={handleClickModalOpen}
                    awardTypeName={myPlanData?.data?.award_type_name}
                  />
                ) : (
                  <TableView
                    term={term}
                    index={sIdx}
                    colors={colors}
                    showModal={showModal}
                    handleClickModalOpen={handleClickModalOpen}
                  />
                )}
              </>
            );
          })}
      </Box>
    </ErrorBoundary>
  );
};
export default CourseMap;

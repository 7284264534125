import isString from 'lodash/isString';
import {Controller} from 'react-hook-form';
import {styled} from '@mui/material/styles';
import {useCheckMobileView} from 'core/hooks';
import {TextField, Popper, FormControl} from '@mui/material';
import {grey, green, yellow, blueGrey} from '@mui/material/colors';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import {RequestErrorLoader} from 'mui-core';
import ListboxComponent from './ListboxComponent';
import {useEffect, useState} from 'react';

const StyledPopper = styled(Popper)({
  zIndex: 1400,
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default function SkillsSelect({
  disabled = false,
  placeholder,
  skillColor,
  fullWidth,
  control,
  errors,
  skills,
  label,
  name,
  size,
  id,
  sx,
  passString,
  defaultValue,
  showOptionOnInput,
  rules,
}) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const {data, request} = skills || {};
  const skillsData = data === null ? [] : data;
  const {isMobileView} = useCheckMobileView();
  const getSkillColor = () => {
    switch (skillColor) {
      case 'green': {
        return {
          backgroundColor: green[400],
          color: 'background.paper',
        };
      }
      case 'yellow': {
        return {
          backgroundColor: yellow[400],
          color: grey[900],
        };
      }
      case 'blueGrey': {
        return {
          backgroundColor: blueGrey[400],
          color: 'background.paper',
        };
      }
      default: {
        return {
          backgroundColor: 'primary.main',
          color: 'white',
        };
      }
    }
  };

  // const onInputChange = (e, value, reason) => {
  //   setInputValue(value);
  //   if (!value) {
  //     setOpen(false);
  //   }
  // };

  // const otherProps = {};
  // if (showOptionOnInput) {
  //   otherProps['onInputChange'] = onInputChange;
  //   otherProps['open'] = open;
  //   otherProps['onOpen'] = () => {
  //     // only open when in focus and inputValue is not empty
  //     if (inputValue) {
  //       setOpen(true);
  //     }
  //   };
  //   otherProps['onClose'] = () => setOpen(false);
  //   // otherProps['inputValue'] = inputValue;
  // }

  return (
    <RequestErrorLoader
      hideEmptyIcon
      body={{request: request, data: skillsData}}>
      <FormControl fullWidth={fullWidth} error={errors}>
        <Controller
          name={name}
          rules={rules ? rules : {}}
          control={control}
          defaultValue={defaultValue ? defaultValue : null}
          render={({field: {onChange, value = []}}) => {
            return (
              <Autocomplete
                size={size}
                id={id}
                multiple
                fullWidth
                disableListWrap
                loading={request}
                disabled={disabled}
                value={value || []}
                options={skillsData}
                disableCloseOnSelect
                filterSelectedOptions
                sx={sx ? sx : {}}
                PopperComponent={StyledPopper}
                ListboxComponent={ListboxComponent}
                onChange={(_event, item) =>
                  onChange(passString ? item.name : item)
                }
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderOption={(props, option, state) => [
                  props,
                  option,
                  state.index,
                ]}
                renderInput={params => (
                  <TextField
                    size='small'
                    {...params}
                    label={label}
                    error={errors}
                    placeholder={placeholder}
                    helperText={errors?.message}
                  />
                )}
                getOptionLabel={option =>
                  option?.name
                    ? option.name
                    : option && isString(option)
                    ? option
                    : ''
                }
                ChipProps={{
                  size: isMobileView ? 'small' : 'medium',
                  sx: getSkillColor(),
                }}
              />
            );
          }}
        />
      </FormControl>
    </RequestErrorLoader>
  );
}

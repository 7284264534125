import {Tag, Button, Row, Col, message} from 'antd';
import isArray from 'lodash/isArray';
import {capitalizeFirstLetter, numberInUSFormat} from 'core/utils';
import {
  applayConfirmModalProps,
  applyJobSuccessModalProps,
  removeJobModalProps,
  removeSavedJobModalProps,
  saveConfirmModalProps,
  saveJobSuccessModalProps,
  signInModalProps,
  signInSaveModalProps,
  WarnModal,
} from './Actions';
import {RelatedPrograms, ConfirmModal} from 'core/components';
import './style.less';
import OpportunityActions from './Actions/OpportunityActions';
import {useAuth} from 'core/hooks';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';

let isAction = null;
let modalCallback = null;
let confirmModalCallback = null;
const defaultModalProps = {
  visible: false,
  title: '',
  description: [''],
  buttonText: '',
};
const defaultConfirmModalProps = {
  visible: false,
  title: '',
  description: [''],
  okText: '',
  cancelText: '',
};
const Overview = ({
  isMobileView,
  jobBoardDetails,
  jobType = 'local',
  OpportunitySave,
  savedOpportunities,
  AppliedOpportunities,
  updateOpportunityInterest,
  unSaveOpportunity,
  fetchAppliedOpportunities,
  openLoginScreen,
  removeAppliedOpportunities,
  fetchSavedOpportunities,
}) => {
  const [token, user] = useAuth();
  const {data: details} = jobBoardDetails || {};
  const {
    opportunity_type,
    title,
    description,
    job_type,
    pay_type,
    range_from,
    range_to,
    range_frequency,
    location_type,
    skills = [],
    ed_level_deal_breaker,
    min_education_level,
    benefits = [],
    contact_name,
    email,
    city,
    state,
    street_address,
    zip,
    opportunity_id,
    qualifying_programs = [],
    job_url_status,
    job_url,
    occupations,
    occupation_name,
    opp_application_uuid,
    job_post_thumbnail_cloudinary,
    institute_details: {institution_uuid, institution_name} = {},
    //job_culture_media,
  } = details || {};

  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [confirmModalProps, setConfirmModalProps] = useState(
    defaultConfirmModalProps,
  );
  const [loaders, setLoaders] = useState({apply: false, save: false});
  const history = useHistory();

  const openWarnModal = (modalProps, callback) => {
    setModalProps({...modalProps, visible: true});
    modalCallback = callback;
  };
  const openConfirmModal = (modalProps, callback) => {
    setConfirmModalProps({...modalProps, visible: true});
    confirmModalCallback = callback;
  };

  const isOpportunitySaved = () => {
    if (savedOpportunities && savedOpportunities.data) {
      const exists = savedOpportunities.data.filter(
        i => i.bp_opportunity_id === opportunity_id,
      );
      if (exists[0]) {
        return {
          app_id: exists[0].opp_application_uuid,
          status: true,
        };
      }
    }
    return {
      status: false,
    };
  };

  const onApplyJob = async () => {
    const data = {
      job_post_thumbnail_cloudinary: job_post_thumbnail_cloudinary,
      bp_opportunity_id: opportunity_id,
      opportunity_name: title,
      opportunity_type: opportunity_type,
      institute_uuid: institution_uuid,
      institute_name: institution_name,
      action_type: 'apply',
    };
    setLoaders({apply: true});
    let success = false;
    await updateOpportunityInterest(data, err => {
      if (err) {
        message.error(err);
      } else {
        // Check if the opportunity is saved, if yes then unSave after applying.
        const opportunitySavedData = isOpportunitySaved();
        if (opportunitySavedData.status) {
          // unsave opportunity
          unSaveOpportunity(opportunitySavedData.app_id);
        }
        success = true;
      }
    });
    if (success) {
      const successModal = applyJobSuccessModalProps({
        job_title: title,
        company_name: institution_name,
      });
      openWarnModal(successModal, () => {
        history.push('/dashboard/jobs');
      });
    }
    setLoaders({apply: false});
    fetchAppliedOpportunities();
    isAction = null;
  };

  const onApplyJobClick = () => {
    const {data: {URL, job_url} = {}} = jobBoardDetails || {};
    if (jobType === 'local') {
      if (token) {
        openWarnModal(applayConfirmModalProps, () => {
          onApplyJob();
        });
      } else {
        isAction = 'apply';
        openWarnModal(signInModalProps, () => {
          openLoginScreen({
            callback: () => {
              fetchAppliedOpportunities((jobs = []) => {
                const exists = jobs.filter(
                  i => i.bp_opportunity_id === opportunity_id,
                );
                if (exists[0]) {
                  message.success({
                    content: 'This job is already applied.',
                    className: 'toast-success',
                  });
                } else {
                  openWarnModal(applayConfirmModalProps, () => {
                    onApplyJob();
                  });
                }
              });
            },
          });
        });
      }
    } else {
      if (URL || job_url) {
        window.open(URL || job_url, '_blank');
      }
    }
  };

  const onSaveJob = async () => {
    const data = {
      job_post_thumbnail_cloudinary: job_post_thumbnail_cloudinary,
      bp_opportunity_id: opportunity_id,
      opportunity_name: title,
      opportunity_type: opportunity_type,
      institute_uuid: institution_uuid,
      institute_name: institution_name,
      action_type: 'save',
    };
    setLoaders({save: true});
    let success = false;
    await updateOpportunityInterest(data, err => {
      setLoaders({save: false});
      if (err) {
        message.error(err);
      } else {
        success = true;
      }
    });
    if (success) {
      const successModal = saveJobSuccessModalProps({
        job_title: title,
        company_name: institution_name,
      });
      openWarnModal(successModal, () => {
        history.push('/dashboard/jobs');
      });
    }
    setLoaders({save: false});
    fetchSavedOpportunities();
    isAction = null;
  };

  const onSaveJobClick = () => {
    if (token) {
      openWarnModal(saveConfirmModalProps, () => {
        onSaveJob();
      });
    } else {
      isAction = 'save';
      openWarnModal(signInSaveModalProps, () => {
        openLoginScreen({
          callback: () => {
            fetchSavedOpportunities((jobs = []) => {
              const exists = jobs.filter(
                i => i.bp_opportunity_id === opportunity_id,
              );
              if (exists[0]) {
                message.success({
                  content: 'This job is already saved.',
                  className: 'toast-success',
                });
              } else {
                openWarnModal(saveConfirmModalProps, () => {
                  onSaveJob();
                });
              }
            });
          },
        });
      });
    }
  };

  const formatString = str => str?.replace(/[\W_]/g, ' ');

  const salaryValidation = () => {
    let isValid = false;
    if (
      (pay_type === 'range' && range_from && range_to) ||
      (pay_type === 'up_to' && range_to) ||
      ((pay_type === 'starting_at' || pay_type === 'exact_rate') && range_from)
    ) {
      isValid = true;
    }
    return isValid;
  };

  const getPayDetails = () => {
    let PayDetails = 'Salary:';
    if (salaryValidation() && pay_type) {
      const PAY_TYPE = pay_type.toLowerCase();
      if (PAY_TYPE === 'range') {
        PayDetails = PayDetails.substring(0, PayDetails.length - 1) + ' Range:';
      } else if (PAY_TYPE === 'starting_at' || PAY_TYPE === 'up_to') {
        PayDetails += ` ${capitalizeFirstLetter(formatString(pay_type))}`;
      }

      const Frequency = capitalizeFirstLetter(formatString(range_frequency));

      switch (PAY_TYPE) {
        case 'exact_rate':
        case 'starting_at': {
          PayDetails += ` $${numberInUSFormat(range_from)} ${Frequency}`;
          break;
        }
        case 'up_to': {
          PayDetails += ` $${numberInUSFormat(range_to)} ${Frequency}`;
          break;
        }
        default: {
          PayDetails += ` $${numberInUSFormat(
            range_from,
          )} - $${numberInUSFormat(range_to)} ${Frequency}`;
        }
      }
    } else {
      PayDetails = '';
    }
    return PayDetails;
  };

  const getLocation = () => {
    let location;
    location = `${city}, ${state}`;
    return location_type && location_type.toLowerCase() === 'remote'
      ? location_type
      : location;
  };

  const getAddress = () => {
    return (
      <>
        <div>
          {street_address}
          <br /> {city}, {state}, {zip}
        </div>
      </>
    );
  };

  const opportunitySaved = () => {
    if (savedOpportunities && savedOpportunities.data) {
      const exists = savedOpportunities.data.filter(
        i => i.bp_opportunity_id === opportunity_id,
      );
      if (exists[0]) return true;
    }
    return false;
  };

  const opportunityApplied = isReturnAppliedJobData => {
    if (AppliedOpportunities && AppliedOpportunities.data) {
      const exists = AppliedOpportunities.data.filter(
        i => i.bp_opportunity_id === opportunity_id,
      );
      if (exists[0]) return isReturnAppliedJobData ? exists[0] : true;
    }
    return false;
  };

  const onModalOk = () => {
    setModalProps(defaultModalProps);
    if (modalCallback) modalCallback();
    modalCallback = null;
  };

  const onModalClose = () => {
    setModalProps(defaultModalProps);
  };

  const onRemoveApply = async () => {
    openConfirmModal(removeJobModalProps, async () => {
      const job = opportunityApplied(true);
      setLoaders({apply: true});
      try {
        await removeAppliedOpportunities(job.opp_application_uuid, data => {
          if (data.Success) {
            message.success({
              content: 'Job was successfully removed.',
              className: 'toast-success',
            });
          }
        });
        fetchAppliedOpportunities();
        setLoaders({apply: false});
      } catch (e) {
        setLoaders({apply: false});
      }
    });
  };

  const onUnSaveJob = async () => {
    openConfirmModal(removeSavedJobModalProps, async () => {
      const opportunitySavedData = isOpportunitySaved();
      console.log('opportunitySavedData', opportunitySavedData);
      setLoaders({save: true});
      try {
        await unSaveOpportunity(opportunitySavedData.app_id, err => {
          if (!err) {
            message.success({
              content: 'Job was successfully removed.',
              className: 'toast-success',
            });
          }
        });
        setLoaders({save: false});
        fetchSavedOpportunities();
      } catch (err) {
        setLoaders({save: false});
      }
    });
  };

  const onConfirmModalOk = () => {
    setConfirmModalProps(defaultConfirmModalProps);
    if (confirmModalCallback) confirmModalCallback();
    confirmModalCallback = null;
  };

  const onConfirmModalCancel = () => {
    setConfirmModalProps(defaultConfirmModalProps);
  };

  // const formatTOLowerCase = str => {
  //   try {
  //     return String(str || '').toLowerCase();
  //   } catch (err) {
  //     return '';
  //   }
  // };

  return (
    <>
      <div className='opportunity-overview'>
        {details && (
          <Row gutter={36} className='contentContainer'>
            <Col lg={16} md={16} sm={24} xs={24} className='content'>
              <div className='d-block w-100'>
                <div className='d-flex  justify-content-between '>
                  {title && (
                    <div id='type'>
                      <h1 className='capitalize'>{title}</h1>
                    </div>
                  )}
                </div>

                {description && (
                  <div id='description'>
                    <h3>Job Description</h3>
                    <div
                      className='description'
                      dangerouslySetInnerHTML={{__html: description}}
                    />
                  </div>
                )}
                {skills && isArray(skills) && skills.length > 0 && (
                  <div className='skills'>
                    <div className='header-with-color-label'>
                      <h3>Skills Required</h3>
                    </div>
                    {skills.map(
                      ({skills}, idx) =>
                        skills && (
                          <Tag
                            key={`skill - ${idx} `}
                            className='required-skills'>
                            <span>{`${skills}`}</span>
                          </Tag>
                        ),
                    )}
                  </div>
                )}

                {/* <div id='culture'>
          <h3>Company Culture</h3>
          <div className='d-flex video-player-section justify-content-start '>
            {isArray(job_culture_media) && job_culture_media.length > 0 ? (
              job_culture_media.map((item, index) => (
                <ReactPlayer
                  key={index}
                  url={item}
                  className='video-player-local'
                  style={{backgroundColor: '#000', marginRight: '20px'}}
                  controls
                  muted
                  playing
                  light
                  width={320}
                  height={240}
                  playIcon={<img src={iconPlay} alt='play' />}
                />
              ))
            ) : (
              <span>No video available</span>
            )}
          </div>
        </div> */}
                {/* <div className='skills'>
          <div className='header-with-color-label'>
            <h3>Skills</h3>
            <div className='color-label'>
              <div className='pink' />
              <h4 className='label'>Must Haves</h4>
            </div>
            <div className='color-label'>
              <div className='purple' />
              <h4 className='label'>Preferred</h4>
            </div>
          </div>
          <div className='skills-container'>
            {skills && isArray(skills) && skills.length > 0 ? (
              skills
                .sort((skill1, skill2) => (skill1.skills_deal_breaker ? -1 : 1))
                .map(
                  ({skills, skills_deal_breaker, min_year}, idx) =>
                    skills && (
                      <Tag
                        key={`skill - ${idx} `}
                        className={`skill-tag ${
                          skills_deal_breaker ? 'deal-breaker' : ''
                        } `}>
                        <span>{`${skills} (${min_year} ${
                          min_year == '1' ? 'yr' : 'yrs'
                        })`}</span>
                      </Tag>
                    ),
                )
            ) : (
              <p>Not Available</p>
            )}
          </div>
        </div> */}
              </div>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <div>
                <OpportunityActions
                  applied={opportunityApplied()}
                  jobURL={job_url_status ? job_url : false}
                  loaders={loaders}
                  appliedOpportunities={AppliedOpportunities}
                  savedOpportunities={savedOpportunities}
                  saved={opportunitySaved()}
                  isAction={isAction}
                  onApply={onApplyJobClick}
                  onSavePath={onSaveJobClick}
                  onRemoveApply={onRemoveApply}
                  onUnSaveJob={onUnSaveJob}
                  OpportunitySave={OpportunitySave}
                />
              </div>
              {job_type && (
                <div className='data-column'>
                  <div id='details'>
                    <h3>Job Details</h3>
                    <p className='capitalize'>
                      {job_type && formatString(job_type)}
                    </p>
                    <p>{getPayDetails()}</p>
                  </div>
                </div>
              )}
              {min_education_level && (
                <div className='data-column'>
                  <div>
                    <div className='skills education'>
                      <div className='header-with-color-label'>
                        <h3>Education</h3>
                        <div className='color-label'>
                          <div className='pink' />
                          <h4 className='label'>Must Haves</h4>
                        </div>
                        <div className='color-label'>
                          <div className='blue' />
                          <h4 className='label'>Preferred</h4>
                        </div>
                      </div>
                      <div className='education-container'>
                        <Tag
                          key='education'
                          className={`education-tag ${
                            ed_level_deal_breaker ? 'deal-breaker' : ''
                          } `}>
                          <span>{min_education_level}</span>
                        </Tag>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {benefits && isArray(benefits) && benefits.length > 0 && (
                <div>
                  <div className='data-column event_type'>
                    <div id='benefits'>
                      <h3>Benefits</h3>
                      <ul className='benefits-container list_blueDot'>
                        {benefits.map(
                          (benefit, idx) =>
                            benefit && (
                              <li key={`benefit-${idx}`} className='benefit'>
                                <span className='capitalize'>
                                  {formatString(benefit)}
                                </span>
                              </li>
                            ),
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {(occupations || qualifying_programs) && (
                <div>
                  <div className='data-column event_type'>
                    {occupations &&
                      isArray(occupations) &&
                      occupations.length > 0 && (
                        <div id='benefits'>
                          <h3>Occupations</h3>
                          <ul className='benefits-container list_blueDot'>
                            {occupations.map(
                              (occupationsData, idx) =>
                                occupationsData && (
                                  <Tag
                                    key={`occupationsData - ${idx} `}
                                    className='occupation'>
                                    <span>{`${occupationsData.occupation_name}`}</span>
                                  </Tag>
                                ),
                            )}
                          </ul>
                        </div>
                      )}
                    {/* {qualifying_programs &&
                      isArray(qualifying_programs) &&
                      qualifying_programs.length > 0 && (
                        <div id='benefits'>
                          <h3>Qualifying Programs</h3>
                          <ul className='benefits-container list_blueDot'>
                            {qualifying_programs.map(
                              (qualifyingprogramData, idx) =>
                                qualifyingprogramData && (
                                  <p>
                                    {qualifyingprogramData.title
                                      ? qualifyingprogramData.title
                                      : qualifyingprogramData}
                                  </p>
                                ),
                            )}
                          </ul>
                        </div>
                      )} */}
                  </div>
                </div>
              )}
              {(getLocation || getLocation) && (
                <div>
                  <div className='data-column event_type'>
                    <div id='location'>
                      <h3>Location(s)</h3>
                      <p className='capitalize'>{getLocation()}</p>
                    </div>
                    <div>
                      <h3>Address</h3>
                      <p className='color-value'>{getAddress()}</p>
                    </div>
                  </div>
                </div>
              )}
              {(contact_name || email) && (
                <div>
                  <div className='data-column event_type'>
                    <div id='contact'>
                      <h3>Contact</h3>
                      <div className='contacts-container'>
                        {contact_name && (
                          <p
                            key='contact-name'
                            className='color-value capitalize'>
                            <span>{contact_name}</span>
                          </p>
                        )}
                        {email && (
                          <p key='contact-email' className='color-value'>
                            <span className='text-lowercase'>{email}</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        )}
        <WarnModal
          visible={modalProps.visible}
          icon={modalProps.icon}
          title={modalProps.title}
          description={modalProps.description}
          buttonText={modalProps.buttonText}
          onOk={onModalOk}
          onClose={onModalClose}
        />
        <ConfirmModal
          visible={confirmModalProps.visible}
          title={confirmModalProps.title}
          description={confirmModalProps.description}
          okText={confirmModalProps.okText}
          cancelText={confirmModalProps.cancelText}
          onCancel={onConfirmModalCancel}
          onOk={onConfirmModalOk}
        />
      </div>
      <div>
        {qualifying_programs &&
          isArray(qualifying_programs) &&
          qualifying_programs.length > 0 && (
            <div className='related-programs mt-4 contentContainer'>
              <h3>Pathways Related to {title}</h3>
              <RelatedPrograms
                data={qualifying_programs}
                isMobileView={isMobileView}
              />
            </div>
          )}
      </div>
    </>
  );
};

export default Overview;

import {combineReducers} from 'redux';
import EventsInfo from './eventDetails';
import upcomingEvents from './searchEvents';
import studentEventSignUpStatus from './studentEventSignUpStatus';
import myEvents from './myEvents';

export const eventReducer = combineReducers({
  studentEventSignUpStatus: studentEventSignUpStatus,
  upcomingEvents: upcomingEvents,
  eventsInfo: EventsInfo,
  myEvents: myEvents,
});

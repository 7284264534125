import * as TYPES from './constants';
import {API} from 'config';
import {
  clearEducatorList,
  requestEducatorList,
  recieveEducatorList,
  recieveAllEducatorsList,
  requestEducatorListError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {
  request: false,
  data: {},
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CLEAR_EDUCATOR_LIST:
      return initialState;
    case TYPES.REQUEST_EDUCATOR_LIST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_EDUCATOR_LIST:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.RECIEVE_ALL_EDUCATOR_LIST:
      let data = action.payload;
      if (state.data && state.data.license) {
        data.license = [...state.data.license, ...data.license];
      }
      return {
        ...state,
        request: false,
        error: false,
        data,
      };
    case TYPES.REQUEST_EDUCATOR_LIST_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchEducatorDetails = (instanceId, filters, loadmore = false) => (
  dispatch,
  _getState,
) => {
  const pageSize = 12;
  let endPoint = `${API.srm.educators}&page_size=${pageSize}`;
  if (instanceId) {
    endPoint += `&parent=${instanceId}`;
  }
  dispatch(requestEducatorList());
  const filterKeys = Object.keys(filters);
  if (filterKeys && filterKeys.length) {
    filterKeys.forEach(key => {
      if (!(key === 'type' && filters[key] === 'all') && filters[key]) {
        endPoint += `&${key}=${filters[key]}`;
      }
    });
  }
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        if (loadmore) {
          dispatch(recieveAllEducatorsList(res)); // Concatinate prev result to current result on loadmore
        } else {
          dispatch(recieveEducatorList(res));
        }
        return;
      }
      dispatch(requestEducatorListError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestEducatorListError(err));
  }
};

export const clearEducatorDetails = () => (dispatch, getState) =>
  dispatch(clearEducatorList());

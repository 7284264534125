import StyleButton from './StyleButton';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

const BLOCK_TYPES = [
  //   {label: 'H1', style: 'header-one',},
  //   {label: 'H2', style: 'header-two'},
  //   {label: 'H3', style: 'header-three'},
  //   {label: 'H4', style: 'header-four'},
  //   {label: 'H5', style: 'header-five'},
  //   {label: 'H6', style: 'header-six'},
  //   {label: 'Blockquote', style: 'blockquote'},
  {label: 'UL', style: 'unordered-list-item', icon: FormatListBulletedIcon},
  // {label: 'OL', style: 'ordered-list-item', icon: FormatListNumberedIcon},
  //   {label: 'Code Block', style: 'code-block'},
];

const BlockStyleControls = props => {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className='RichEditor-controls'>
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
    </div>
  );
};

export default BlockStyleControls;

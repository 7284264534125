import {
  Grid,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import {Close} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
const WithDrawEventDialog = props => {
  const {
    withDrawDialogClose,
    selectedValue,
    open,
    withdrawDialogConfirm,
  } = props;

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullWidth={true}
      onClose={withDrawDialogClose}>
      <DialogTitle
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'>
        <Typography
          margin={0}
          variant='h2'
          gutterBottom
          fontSize={24}
          fontWeight={600}
          color='text.primary'>
          Withdraw Event Signup
        </Typography>
        <IconButton aria-label='close' onClick={withDrawDialogClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{height: '100px'}}>
        <Typography
          color='text.primary'
          variant='h6'
          textAlign='center'
          fontSize={14}>
          Are your sure that you no longer wish to attend the event
        </Typography>
        <Typography color='red' variant='h6' textAlign='center' fontSize={14}>
          {selectedValue && selectedValue?.title}&nbsp;?
        </Typography>
      </DialogContent>

      <DialogActions spacing={3} sx={{padding: '8px 32px 32px'}}>
        <Button
          color='midGray'
          variant='contained'
          onClick={withDrawDialogClose}>
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() =>
            withdrawDialogConfirm(selectedValue && selectedValue?.uuid)
          }>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WithDrawEventDialog.propTypes = {
  withDrawDialogClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  withdrawDialogConfirm: PropTypes.func,
};
export default WithDrawEventDialog;

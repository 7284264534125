import React from 'react';
import {Switch, FormControlLabel, Typography, Stack} from '@mui/material';

const AddToResumeToggle = ({isChecked, toggle}) => {
  return (
    <Stack direction='row' alignItems='center'>
      <FormControlLabel
        sx={{marginRight: 0}}
        aria-label='Add to resume toggle'
        control={<Switch size='small' checked={isChecked} onChange={toggle} />}
      />
      <Typography ml={0.5} variant='h5' fontWeight={300} color='text.primary'>
        Add To Resume
      </Typography>
    </Stack>
  );
};

export default AddToResumeToggle;

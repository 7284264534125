import {Menu as MuiMenu, MenuItem, ClickAwayListener} from '@mui/material';
import {ErrorBoundary} from 'core/components';
import PropTypes from 'prop-types';

const Menu = menuData => {
  const {data, open, onClose, anchorEl} = menuData;

  return (
    <ErrorBoundary nameOfComponent='core-menu'>
      <ClickAwayListener onClickAway={onClose}>
        <MuiMenu
          open={open}
          id='basic-menu'
          onClose={onClose}
          anchorEl={anchorEl}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          {data &&
            Array.isArray(data) &&
            data?.length > 0 &&
            data.map((item, idx) => (
              <MenuItem
                key={idx}
                onClick={e => {
                  e.stopPropagation();
                  return item?.dataPassed
                    ? item?.action(item.dataPassed)
                    : item.action(e, anchorEl);
                }}>
                {item.title}
              </MenuItem>
            ))}
        </MuiMenu>
      </ClickAwayListener>
    </ErrorBoundary>
  );
};

Menu.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.array,
  onClose: PropTypes.func,
  anchorEl: PropTypes.element,
};

export default Menu;

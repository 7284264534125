// INSTANCE
export const METRICS_REQUEST = 'METRICS_REQUEST';
export const METRICS_SUCCESS = 'METRICS_SUCCESS';
export const METRICS_ERROR = 'METRICS_ERROR';
export const CLEAR_METRICS = 'CLEAR_METRICS';

//Testimonials
export const REQUEST_TESTIMONIALS = 'REQUEST_TESTIMONIALS';
export const TESTIMONIALS_SUCCESS = 'TESTIMONIALS_SUCCESS';
export const TESTIMONIALS_ERROR = 'TESTIMONIALS_ERROR';
export const CLEAR_TESTIMONIALS = 'CLEAR_TESTIMONIALS';

// Empowering Communities
export const REQUEST_EMPOWERING_COMMUNITIES = 'REQUEST_EMPOWERING_COMMUNITIES';
export const EMPOWERING_COMMUNITIES_SUCCESS = 'EMPOWERING_COMMUNITIES_SUCCESS';
export const CLEAR_EMPOWERING_COMMUNITIES = 'CLEAR_EMPOWERING_COMMUNITIES';
export const EMPOWERING_COMMUNITIES_ERROR = 'EMPOWERING_COMMUNITIES_ERROR';

import {ContentBox, ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {bioData} from 'data/settings-profile.json';
import {EditProfileWrapper} from '../../components';
import {useSaveProfileLoader} from 'core/hooks';
import {Typography} from '@mui/material';
import Summary from './Summary';

const ProfessionalSummary = ({onProfileDataSubmit, profileData}) => {
  const {request} = profileData || {};
  const {subHeading, collapseBarHeading} = bioData || {};
  const [isLoading, onSubmit] = useSaveProfileLoader(
    request,
    onProfileDataSubmit,
  );
  return (
    <ContentBox variant='outlined' sx={{mb: 2, p: 3}}>
      <Typography
        mb={1}
        variant='h5'
        color='text.primary'
        // fontFamily='fontFamilyBold'
        title={collapseBarHeading}>
        {collapseBarHeading}
      </Typography>
      <Typography
        mb={2}
        variant='body1'
        title={subHeading}
        color='text.secondary'>
        {subHeading}
      </Typography>
      <ErrorBoundary nameOfComponent='dashboard-profile-bio' typeOfUi='subPage'>
        <RequestErrorLoader body={{...profileData, request: isLoading}}>
          <Summary profileData={profileData} onChange={onSubmit} />
        </RequestErrorLoader>
      </ErrorBoundary>
    </ContentBox>
  );
};

export default EditProfileWrapper(ProfessionalSummary);

import Steps from './steps';
import CareerCraft from './careerCraft';
import LinkedInDetails from './linkedInDetails';
import {combineReducers} from 'redux';

export const resumeBuilderReducer = combineReducers({
  Steps,
  CareerCraft,
  LinkedInDetails,
});

import * as TYPES from './constants';

// Occupation Search
export const requestPrograms = () => ({
  type: TYPES.PROGRAMS_REQUEST,
});
export const recievePrograms = (json, loadMore = false) => ({
  type: TYPES.PROGRAMS_SUCCESS,
  payload: json,
  loadMore,
});
export const requestProgramsError = err => ({
  type: TYPES.PROGRAMS_ERROR,
  err,
});
export const clearPrograms = () => ({
  type: TYPES.CLEAR_PROGRAMS,
});

// Program Details
export const requestProgramDetail = () => ({
  type: TYPES.PROGRAM_DETAIL_REQUEST,
});
export const recieveProgramDetail = json => ({
  type: TYPES.PROGRAM_DETAIL_SUCCESS,
  payload: json,
});
export const requestProgramDetailError = err => ({
  type: TYPES.PROGRAM_DETAIL_ERROR,
});

export const clearProgramDetail = () => ({
  type: TYPES.CLEAR_PROGRAM_DETAIL,
});

// SignedUp Programs

export const requestSignedPrograms = () => ({
  type: TYPES.SIGNED_PROGRAMS_REQUEST,
});
export const recieveSignedPrograms = json => ({
  type: TYPES.SIGNED_PROGRAMS_SUCCESS,
  payload: json,
});
export const requestSignedProgramsError = err => ({
  type: TYPES.SIGNED_PROGRAMS_ERROR,
  err,
});
export const clearSignedPrograms = () => ({
  type: TYPES.CLEAR_SIGNED_PROGRAMS,
});

export const requestSignUpProgram = () => ({
  type: TYPES.SIGN_UP_PROGRAM_REQUEST,
});
export const recieveSignUpProgram = json => ({
  type: TYPES.SIGN_UP_PROGRAM_SUCCESS,
  payload: json,
});
export const requestSignUpProgramError = err => ({
  type: TYPES.SIGN_UP_PROGRAM_ERROR,
  err,
});

// SAVED PROGRAMS

export const requestSavedPrograms = () => ({
  type: TYPES.SAVED_PROGRAMS_REQUEST,
});
export const recieveSavedPrograms = json => ({
  type: TYPES.SAVED_PROGRAMS_SUCCESS,
  payload: json,
});
export const requestSavedProgramsError = err => ({
  type: TYPES.SAVED_PROGRAMS_ERROR,
  err,
});
export const clearSavedPrograms = () => ({
  type: TYPES.CLEAR_SAVED_PROGRAMS,
});

export const requestSaveProgram = () => ({
  type: TYPES.SAVE_PROGRAM_REQUEST,
});
export const recieveSaveProgram = json => ({
  type: TYPES.SAVE_PROGRAM_SUCCESS,
  payload: json,
});
export const requestSaveProgramError = err => ({
  type: TYPES.SAVE_PROGRAM_ERROR,
  err,
});

// CAREER AREA LIST

export const requestCareerAreaList = () => ({
  type: TYPES.CAREER_AREA_LIST_REQUEST,
});
export const receiveCareerAreaList = json => ({
  type: TYPES.CAREER_AREA_LIST_SUCCESS,
  payload: json,
});
export const receiveAllCareerAreaList = json => ({
  type: TYPES.CAREER_AREA_ALL_LIST_SUCCESS,
  payload: json,
});
export const requestCareerAreaListError = err => ({
  type: TYPES.CAREER_AREA_LIST_ERROR,
  err,
});
export const clearCareerAreaList = () => ({
  type: TYPES.CLEAR_CAREER_AREA_LIST,
});

// Compare Programs
export const requestComparePrograms = () => ({
  type: TYPES.COMPARE_PROGRAMS_REQUEST,
});
export const recieveComparePrograms = data => ({
  type: TYPES.COMPARE_PROGRAMS_SUCCESS,
  payload: data,
});
export const requestCompareProgramsError = err => ({
  type: TYPES.COMPARE_PROGRAMS_ERROR,
  err,
});
export const clearComparePrograms = () => ({
  type: TYPES.CLEAR_COMPARE_PROGRAMS,
});

import {LoadingOutlined} from '@ant-design/icons';
import './style.less';

const Loader = ({text = 'Loading, Please Wait...'}) => (
  <div className='pageLoadingSpinnerContainer'>
    <div className='pageLoadingSpinner'>
      <LoadingOutlined spin />
      <span className='LoaderText'>{text}</span>
    </div>
  </div>
);

export default Loader;

import * as TYPES from './constants';
import {API} from 'config';
import {
  requestBusinessSignUp,
  receiveBusinessSignUp,
  requestBusinessSignUpError,
} from './actions';
import {Services} from 'core/Services';

const services = new Services();

const initialState = {
  data: null,
  error: false,
  request: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BUSINESS_SIGNUP:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECEIVE_BUSINESS_SIGNUP:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_BUSINESS_SIGNUP_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };

    default:
      return state;
  }
};

export const fetchBusinessSignup = (body, callBack) => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
    resumeBuilder: {
      CareerCraft: {params = null},
    },
  } = getState();
  dispatch(requestBusinessSignUp());

  const endPoint = `${API.srm.nonProfit}`;
  // const body = {ein_no: ein_no};
  try {
    return services
      .createUpdateRecord(token, endPoint, body, 'POST')
      .then(res => {
        if (res) {
          dispatch(receiveBusinessSignUp(res));
          if (callBack) callBack(res);
          return;
        } else {
          dispatch(
            requestBusinessSignUpError(
              'Something went wrong. Please try later.',
            ),
          );
          if (callBack)
            callBack({message: 'Something went wrong. Please try later.'});
        }
      });
  } catch (err) {
    console.log(err);
    dispatch(requestBusinessSignUpError(err));
    if (callBack) callBack(err);
  }
};

import {combineReducers} from 'redux';

import loginScreen from './loginScreen';
import auth from './login';
import forgotPassword from './forgotPassword';

export const authReducer = combineReducers({
  loginScreen,
  auth,
  forgotPassword,
});

import React from 'react';
import {View, Text, Link} from '@react-pdf/renderer';

export default ({portfolioUrl, styles}) => (
  <View style={styles.container}>
    <View style={styles.header}>
      <Text break style={styles.headerText}>
        Portfolio
      </Text>
    </View>
    <View style={styles.content}>
      <Text break style={styles.portfolioLabel}>
        Portfolio URL:
      </Text>
      <Link style={styles.portfolioLink}>{portfolioUrl}</Link>
    </View>
  </View>
);

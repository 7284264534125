import React from 'react';
import {Button} from 'mui-core';
import {useAuth} from 'core/hooks';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {openLoginScreen} from 'redux/modules/auth';
import {employerBanner, jobseekerBanner} from 'assets/images';
import {Box, Card, Stack, styled, Typography} from '@mui/material';

const TalentCardContainer = styled(Box)(({isActive, theme}) => ({
  borderRadius: '24px',
  border: isActive
    ? `4px solid ${theme.palette.lightBlue.main}`
    : '4px solid transparent',
  // border: isActive ? '3px solid #1db0f8' : '3px solid #f4f2fa',
  filter: !isActive && 'drop-shadow(0px 5px 24px rgba(0, 0, 0, 0.08))',
  boxShadow:
    isActive &&
    `0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)`,
}));

const CustomCard = styled(Card)(({theme}) => ({
  maxWidth: '520px',
  cursor: 'pointer',
  boxShadow: 'none',
  borderRadius: '20px !important',
  [`& img`]: {
    width: '101%',
    borderRadius: '19px 19px 0px 0px',
  },
}));

const TalentButton = styled(Button)(({theme}) => ({
  width: '100%',
  flexShrink: 0,
  height: '50px',
  border: 'none',
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '4px',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  background: theme.palette.primary.main,
  color: theme.palette.success.contrastText,
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

function TalentCard({cardKey, onClick, isActive, buttonText, description}) {
  const [token] = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOpenLogin = () => {
    if (token) {
      history.push('/dashboard');
    } else {
      dispatch(
        openLoginScreen({
          screen: 'signin',
          callback: () => history.push('/dashboard'),
        }),
      );
    }
  };

  const actionSignInHandler = e => {
    e.stopPropagation();
    if (cardKey === 'employer') {
      const externalLink = {
        target: '_blank',
        href: 'https://www.goeducate.io/',
      };
      window.open(externalLink.href, externalLink.target);
    } else if (cardKey === 'jobSeeker') {
      handleOpenLogin();
    }
  };

  const getCardImage = key => {
    if (key === 'employer') {
      return employerBanner;
    } else {
      return jobseekerBanner;
    }
  };

  return (
    <TalentCardContainer isActive={isActive} onClick={onClick}>
      <CustomCard>
        <img src={getCardImage(cardKey)} alt={`${cardKey}-logo`} />
        <Stack m={3} gap={3} textAlign='center'>
          <Typography
            lineHeight='28px'
            variant='subtitle1'
            color='text.primary'>
            {description}
          </Typography>
          <TalentButton onClick={actionSignInHandler}>
            {buttonText}
          </TalentButton>
        </Stack>
      </CustomCard>
    </TalentCardContainer>
  );
}

export default TalentCard;

import React from 'react';
import PropTypes from 'prop-types';
import {themeColorMode} from 'mui-core';
import {styled, useTheme} from '@mui/material/styles';
import {Stack, Typography, Divider} from '@mui/material';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';

const GradientDivider = styled(Divider)(() => ({
  borderImage:
    'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  borderImageSlice: '20%',
  borderImageWidth: '2px',
}));

const LightTooltip = styled(({className, ...props}) => (
  <Tooltip
    arrow
    {...props}
    classes={{popper: className}}
    placement={props.isMobile ? 'bottom' : 'right'}
  />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    padding: '1px',
    borderRadius: '8px',
    color: themeColorMode(theme, 'black', 'white'),
    boxShadow: `1px 3px 12px ${theme.palette.grayText.main}`,
    backgroundColor: themeColorMode(
      theme,
      'white',
      theme.palette.darkGray.main,
    ),
    background:
      'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}));

export default function ({
  title,
  isMobile,
  children,
  tooltipData,
  showGradient = true,
  titleVariant = 'h3',
}) {
  const theme = useTheme();
  return (
    <LightTooltip
      isMobile={isMobile}
      title={
        <Stack
          spacing={2}
          sx={{
            padding: '32px',
            borderRadius: '8px',
            background: theme.palette.mode === 'light' ? 'white' : 'black',
          }}>
          <Typography
            fontWeight={300}
            color='text.primary'
            variant={titleVariant}>
            {title}
          </Typography>
          {showGradient ? (
            <GradientDivider variant='middle' />
          ) : (
            <Divider variant='middle' />
          )}
          {tooltipData}
        </Stack>
      }>
      {children}
    </LightTooltip>
  );
}

LightTooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  tooltipData: PropTypes.node,
  showGradient: PropTypes.bool,
  titleVariant: PropTypes.string,
};

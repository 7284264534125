import React from 'react';
import {getLogo, formatPhoneNumber} from 'core/utils';
import {StyleSheet, View, Text, Image} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  body: {
    width: 'auto',
    paddingTop: 24,
    paddingBottom: 24,
    textAlign: 'center',
  },
  name: {
    color: 'white',
    fontSize: 16,
  },
  section: {
    paddingTop: 4,
    paddingBottom: 4,
    display: 'inline',
  },
  label: {
    fontSize: 10,
    fontWeight: '900',
  },
  value: {
    fontSize: 10,
    marginBottom: 8,
    fontWeight: '200',
  },
  content: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  header: {
    fontSize: 12,
    fontWeight: '900',
    marginBottom: 8,
  },
  avatarContainer: {
    paddingTop: 6,
    paddingBottom: 4,
    alignItems: 'center',
  },
  avatarImage: {
    width: '80px',
    height: '80px',
    borderRadius: '50',
  },
  qrCode: {
    padding: 4,
    alignItems: 'center',
  },
  image: {
    width: '60px',
    height: '60px',
  },
});

export default ({studentInfo, studentProfile}) => {
  const {
    photo,
    email,
    qr_code,
    last_name,
    first_name,
    address = {},
    phone_number,
  } = studentInfo || {};
  const {city, state} = address || {};
  const userLogo = photo && getLogo(photo);
  const {portfolio_url = ''} = studentProfile || {};
  const qrCodeImage = qr_code && getLogo(qr_code);
  const student_name = `${first_name} ${last_name}`;
  const phoneNumber = formatPhoneNumber(phone_number);
  const location = city && state ? city + ', ' + state : null;

  return (
    <View style={styles.body}>
      <Text style={styles.name}>{student_name}</Text>
      {userLogo && (
        <View style={styles.avatarContainer}>
          <Image
            style={styles.avatarImage}
            source={{
              uri: userLogo,
              method: 'GET',
              header: {
                'Access-Control-Allow-Origin': '*',
                'Cache-Control': 'no-cache',
              },
            }}
          />
        </View>
      )}
      {location && (
        <View style={styles.content}>
          <Text style={styles.header}>Location</Text>
          {location && <Text style={styles.value}>{location}</Text>}
        </View>
      )}
      {(email || (phoneNumber && phoneNumber !== '-')) && (
        <View style={styles.content}>
          <Text style={styles.header}>Contact</Text>
          {email && <Text style={styles.value}>{email}</Text>}
          {phoneNumber && phoneNumber !== '-' && (
            <Text style={styles.value}>{phoneNumber}</Text>
          )}
        </View>
      )}
      {typeof portfolio_url === 'string' && portfolio_url !== '' && (
        <View style={styles.content}>
          <Text style={styles.header}>Profile URL</Text>
          <Text style={styles.value}>{portfolio_url}</Text>
        </View>
      )}
      {qrCodeImage && (
        <View style={styles.content}>
          <Text style={styles.header}>Profile QR Code</Text>
          <View style={styles.qrCode}>
            <Image
              style={styles.image}
              source={{
                uri: qrCodeImage,
                method: 'GET',
                header: {
                  'Access-Control-Allow-Origin': '*',
                  'Cache-Control': 'no-cache',
                },
              }}
            />
          </View>
        </View>
      )}
    </View>
  );
};

import {Box} from '@mui/material';
import {alpha, styled} from '@mui/material/styles';
import {DataGridPro as MUIDataGrid, gridClasses} from '@mui/x-data-grid-pro';
import {EmptyBox} from 'mui-core';
import {themeColorMode} from 'mui-core/utils';
import {memo, useEffect, useState} from 'react';
const ODD_OPACITY = 0.04;
const StyledDataGrid = styled(MUIDataGrid)(({theme, disableColumnResize}) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.purplebg4perc.main,
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeaders}`]: {
    backgroundColor: themeColorMode(
      theme,
      theme.palette.purplebg12perc.main,
      theme.palette.action.hover,
    ),
  },
  [`& p`]: {
    marginTop: 'initial',
    marginBottom: 'initial',
  },
  [`& .${gridClasses.iconSeparator}`]: {
    display: disableColumnResize ? 'none' : 'inline-block',
  },
  [`& .${gridClasses.row}`]: {
    [`& .${gridClasses.cell}`]: {
      paddingTop: 14,
      paddingBottom: 14,
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const DataGrid = ({
  rows = [],
  columns = [],
  height,
  pageSize = 10,
  checkboxSelection = false,
  onSelectionModelChange,
  disableColumnResize = true,
  disableColumnMenu = true,
  pagination = true,
  hideFooter,
  loading,
  getRowId,
  sortingMode,
  onSortModelChange,
  paginationMode = 'client',
  rowsPerPageOptions,
  onPageChange,
  totalRowCount,
  onPageSizeChange,
  page,
  sx,
}) => {
  const [tablePageSize, setPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(0);
  const containerStyles = {};
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState,
    );
  }, [setRowCountState, totalRowCount]);
  if (height) containerStyles.height = height;
  return (
    <Box sx={{...containerStyles, width: '100%'}}>
      <StyledDataGrid
        id='paginationControl'
        sx={sx ? sx : {}}
        rows={rows}
        page={paginationMode === 'client' ? currentPage : page}
        rowCount={rowCountState}
        columns={columns}
        disableColumnResize={disableColumnResize}
        pageSize={paginationMode === 'client' ? tablePageSize : pageSize}
        loading={loading}
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={onSelectionModelChange}
        onPageSizeChange={page =>
          paginationMode === 'client'
            ? setPageSize(page)
            : onPageSizeChange(page)
        }
        autoHeight={!height}
        components={{
          NoRowsOverlay: EmptyBox,
        }}
        disableSelectionOnClick
        experimentalFeatures={{lazyLoading: true}}
        sortingMode={sortingMode}
        getRowId={getRowId}
        getRowHeight={() => 'auto'}
        onSortModelChange={onSortModelChange}
        paginationMode={paginationMode}
        onPageChange={page =>
          paginationMode === 'client'
            ? setCurrentPage(page)
            : onPageChange(page)
        }
        initialState={[]}
        disableColumnMenu={disableColumnMenu}
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        pagination={pagination}
        hideFooter={hideFooter}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </Box>
  );
};

export default memo(DataGrid);

import * as TYPES from './constants';
import {API} from 'config';
import {
  requestPathwayOpportunity,
  recievePathwayOpportunity,
  requestPathwayOpportunityError,
  resetPathwayOpportunity,
  recieveAllPathwayOpportunities,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, error: false, data: null};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PATHWAY_OPPORTUNITY_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.PATHWAY_OPPORTUNITY_LIST_RECEIVE:
      let data = action.payload;
      if (state.data && state.data.data) {
        data.data = [...state.data.data, ...data.data];
      }
      return {
        ...state,
        request: false,
        error: false,
        data,
      };
    case TYPES.PATHWAY_OPPORTUNITY_RECEIVE:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.PATHWAY_OPPORTUNITY_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.RESET_PATHWAY_OPPROTUNITY:
      return initialState;
    default:
      return state;
  }
};

export const fetchPathwayOpportunities = (
  path_uuid,
  page = 1,
  loadmore = false,
) => (dispatch, getState) => {
  const pageSize = 12;
  let endPoint = `${API.srm.opportunity}?path_uuid=${path_uuid}&page_size=${pageSize}&page=${page}`;
  dispatch(requestPathwayOpportunity());
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        if (loadmore) {
          dispatch(recieveAllPathwayOpportunities(res)); // Concatinate prev result to current result on loadmore
        } else {
          dispatch(recievePathwayOpportunity(res));
        }
        return;
      }
      dispatch(requestPathwayOpportunityError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestPathwayOpportunityError(err));
  }
};

export const resetPathwayOpportunities = () => dispatch => {
  dispatch(resetPathwayOpportunity());
};

import jwtDecode from 'jwt-decode';

//Utility
export const saveItem = (item, val) => localStorage.setItem(item, val);
export const getItem = item => localStorage.getItem(item);
export const removeItem = item => localStorage.removeItem(item);

export const isAccessTokenExpired = ({token: {exp = null} = {}}) =>
  exp && 1000 * exp - new Date().getTime() < 60000;

export const removeTokens = () => removeItem('auth');

export const loadSettings = () => ({
  general: {appConfig: JSON.parse(localStorage.getItem('settings'))},
});
export const loadTokens = () => {
  let auth;
  try {
    auth = getItem('auth');
    if (auth && isAccessTokenExpired(auth)) {
      removeTokens();
    }
  } catch (err) {
    console.log(err);
  }
  return auth
    ? {
        auth: {auth: JSON.parse(auth)},
      }
    : null;
};

export const loadInitialState = () =>
  Object.assign({}, loadTokens(), loadSettings());

export const saveTokens = payload => {
  const authtokens = {
    token: payload.access,
    refresh: payload.refresh,
    request: false,
    user: Object.assign(jwtDecode(payload.access), {
      first_name: payload.first_name,
      last_name: payload.last_name,
    }),
    error: null,
  };
  try {
    saveItem('auth', JSON.stringify(authtokens));
  } catch (err) {
    console.log(err);
  }
};

export const saveSettings = payload => {
  saveItem('settings', JSON.stringify(payload));
};
export const getSettings = () => JSON.parse(getItem('settings'));
export const resetSettings = () => {};

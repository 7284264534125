import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  Button,
  Accordion,
  JobOpportunities,
  RequestErrorLoader,
} from 'mui-core';
import {styled} from '@mui/material/styles';
import {opportunities} from 'data/dashboard.json';
import {Alert, Box, Stack, Typography} from '@mui/material';
import {profileDataSelector} from 'redux/modules/profile';
import {getModuleBasePath} from 'core/utils';
import {useHistory} from 'react-router';

const {
  jobMatches: {header, description, noMatchText, addResumeText},
} = opportunities;

const NoMatchContainer = styled(Alert)(({theme}) => ({
  padding: '16px 18px',
}));

const NoMatch = () => {
  const history = useHistory();
  const redirectToGoResume = () => {
    const baseUrl = getModuleBasePath('dashboard');
    history.push(`${baseUrl}/goResume`);
  };
  return (
    <NoMatchContainer
      severity='warning'
      action={
        <Button variant='contained' onClick={redirectToGoResume}>
          <Typography color='white' variant='subtitle2' fontWeight={500}>
            {addResumeText}
          </Typography>
        </Button>
      }>
      <Typography variant='caption'>{noMatchText}</Typography>
    </NoMatchContainer>
  );
};

const JobMatches = () => {
  const {data: profileData, request} = useSelector(profileDataSelector);
  const {active_resume, enrolled_program} = profileData || {};

  const shouldShowNoMatchWarning = useMemo(() => {
    return !(enrolled_program || active_resume);
  }, [active_resume, enrolled_program]);

  return (
    <Accordion
      defaultOpen
      outlined={false}
      id='job_matches'
      header={header}
      description={description}>
      {request && (
        <RequestErrorLoader
          fullScreen
          hideEmpty
          body={{
            request,
          }}
        />
      )}
      {shouldShowNoMatchWarning ? (
        <Stack alignItems='center' sx={{width: '100%'}}>
          <NoMatch />
        </Stack>
      ) : (
        <JobOpportunities scrollTop={400} dashboardReferer />
      )}
    </Accordion>
  );
};

export default JobMatches;

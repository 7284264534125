import {Button, Grid} from 'mui-core';
import {Typography} from '@mui/material';
import {getFilesAndTotalSize} from 'core/utils';
import DocUploader from 'app/modules/dashboard/components/DocUploader';

const Portfolio = props => {
  const {
    profileData: {data: student_profile, request, error},
  } = props;

  const {data: document_files = [], total_size_consumed} = getFilesAndTotalSize(
    student_profile,
    'portfolio_document',
  );

  const {portfolio_url} = student_profile;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {portfolio_url && (
          <>
            <Typography
              variant='h5'
              fontWeight={600}
              component='span'
              fontSize={18}>
              Portfolio URL:{' '}
            </Typography>

            <Typography
              component={'a'}
              sx={{textDecoration: 'underline'}}
              href={portfolio_url}
              variant={'h5'}
              fontSize={18}
              target='_blank'
              download='file'>
              {portfolio_url}
            </Typography>
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        {document_files &&
          Array.isArray(document_files) &&
          document_files.length > 0 && (
            <DocUploader
              viewOnly
              profileData={{
                data: document_files,
                total_size_consumed,
                request,
                error,
              }}
            />
          )}
      </Grid>
    </Grid>
  );
};

export default Portfolio;

import {useSelector} from 'react-redux';
import DataPreview from './DataPreview';
import {useEffect, useState} from 'react';
import NoDataPreview from './NoDataPreview';
import {Visibility, Speed} from '@mui/icons-material';
import resumeBuilderData from 'data/resumeBuilder.json';
import {getResumeData} from 'redux/modules/resume-builder';
import {Button, ContentBox, DialogBox, HighLighter} from 'mui-core';
import {Stack, useMediaQuery, useTheme, Paper} from '@mui/material';

const {
  previewBtnTxt,
  dashboardBtnTxt,
  highlighter: {
    previewDescription = '',
    drawerDescription = '',
    stepperDescription = '',
  } = {},
} = resumeBuilderData || {};

const Preview = ({active, onBack, onNext, toggleDrawer}) => {
  const theme = useTheme();
  const {data: resumeData} = useSelector(getResumeData);
  const [isPreviewModal, setPreviewModal] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMedium = useMediaQuery(theme.breakpoints.only('md'));
  const {first_name, work_exp = [], credentials = [], volunteer_exp = []} =
    resumeData || {};

  useEffect(() => {
    setPreviewModal(false);
  }, [isMobile]);

  return (
    <>
      {isMobile ? (
        <Stack
          m={0}
          pt={2}
          pr={3}
          gap={2}
          direction='row'
          alignItems='center'
          justifyContent='end'>
          <HighLighter
            showOnlyInMobile
            direction='bottom'
            iconPosition={'center'}
            scrollToId='stepper'
            active={active === 2}
            instructions={drawerDescription}
            onNext={() => onNext(-1)}
            isEnd
            onBack={() => onBack(1)}>
            <Button
              rounded
              size='small'
              variant='contained'
              sx={{width: '140px'}}
              onClick={toggleDrawer}
              endIcon={<Speed fontSize='large' />}>
              {dashboardBtnTxt}
            </Button>
          </HighLighter>
          <HighLighter
            showOnlyInMobile
            direction='bottom'
            iconPosition={'right'}
            scrollToId='stepper'
            active={active === 1}
            instructions={previewDescription}
            onNext={() => onNext(2)}
            onBack={() => onBack(0)}>
            <Button
              rounded
              size='small'
              variant={active === 1 ? 'contained' : 'outlined'}
              sx={{width: '120px'}}
              onClick={() => setPreviewModal(true)}
              endIcon={<Visibility fontSize='large' />}>
              {previewBtnTxt}
            </Button>
          </HighLighter>
        </Stack>
      ) : (
        <HighLighter
          onBack={() => onBack(0)}
          onNext={() => onNext(2)}
          active={active === 1}
          direction='left'
          scrollToId='resume-preview'
          instructions={previewDescription}
          sx={{
            width: isMedium ? '58%' : '55%',
            margin: '0px 24px 24px 24px !important',
          }}>
          <ContentBox
            elevation={2}
            id='resume-preview'
            sx={{
              height: 855,
              padding: '32px',
              marginTop: '34px !important',
            }}>
            {first_name ||
            credentials?.length ||
            work_exp.length ||
            volunteer_exp.length ? (
              <DataPreview data={resumeData} isMobile={isMobile} purpleHeader />
            ) : (
              <NoDataPreview isMobile={isMobile} purpleHeader />
            )}
          </ContentBox>
        </HighLighter>
      )}
      {isPreviewModal && isMobile && (
        <DialogBox
          fullScreen
          disableIcon
          openDialog={isPreviewModal}
          closeDialog={() => setPreviewModal(false)}>
          {first_name ||
          credentials?.length ||
          work_exp.length ||
          volunteer_exp.length ? (
            <DataPreview data={resumeData} isMobile={isMobile} purpleHeader />
          ) : (
            <NoDataPreview isMobile={isMobile} purpleHeader />
          )}
        </DialogBox>
      )}
    </>
  );
};

export default Preview;

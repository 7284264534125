import {useEffect, useState} from 'react';
import {Input, AutoComplete, Form} from 'antd';
import debounce from 'debounce';

import {API} from 'config';
import {Services} from 'core/Services';
import {RequestErrorLoader} from 'core/components';
import style from './style.module.less';

const services = new Services();

export default function FilterAutoComplete({
  name,
  label,
  dataCY,
  resetKey,
  url = API.srm.opportunity,
  type = 'company',
  placeholder = 'Search',
  noResultsText = 'No results found',
}) {
  const [data, setData] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(null);
    setSearchKey('');
  }, [resetKey]); // eslint-disable-line

  const fetchData = async (value = '') => {
    setData(null);
    setSearchKey(value);
    if (value) {
      setLoading(true);
      const endPoint = `${url}${
        !url.includes('?') ? '?' : '&'
      }autocomplete_type=${type?.toLowerCase()}&autocomplete_text=${value.toLowerCase()}`;
      const data = await services.createUpdateRecord({}, endPoint);
      if (Array.isArray(data)) {
        setData(data);
      }
      setLoading(false);
    }
  };

  return (
    <Form.Item data-cy={dataCY} label={label} name={name}>
      <AutoComplete
        dropdownClassName='filter-dropdown'
        className='autocomplete-filter'
        className={style.autocomplete_filter}
        onSearch={debounce(fetchData, 800)}
        options={data?.map(item => ({value: item}))}
        notFoundContent={
          loading ? (
            <RequestErrorLoader body={{request: loading}} />
          ) : searchKey ? (
            noResultsText
          ) : null
        }>
        <Input placeholder={placeholder} />
      </AutoComplete>
    </Form.Item>
  );
}

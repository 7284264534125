import {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {themeColorMode} from 'mui-core/utils';
import {stateToHTML} from 'draft-js-export-html';
import BlockStyleControls from './BlockStyleControls';
import resumeBuilderData from 'data/resumeBuilder.json';
import InlineStyleControls from './InlineStyleControls';
import {Box, Button, Divider, Stack, Typography, styled} from '@mui/material';

const {
  goAIButton: {prefixLabel},
} = resumeBuilderData || {};

const EditorRoot = styled(Box)(({theme, focused}) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${
    focused ? theme.palette.primary.main : theme.palette.divider
  }`,
  boxShadow: focused ? `0px 0px 0px 1px ${theme.palette.primary.main}` : 'none',
  fontSize: 14,
  padding: 15,
  position: 'relative',
  borderRadius: 4,
  transition: '0.1s',
  [`&:hover`]: {
    borderColor: focused
      ? theme.palette.primary.main
      : themeColorMode(theme, '#000', '#fff'),
  },
  [`&:hover .format-controls`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#f0f0f0' : '#333',
  },
}));
const EditorContainer = styled(Box)(({}) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  cursor: 'text',
  fontSize: 14,
  minHeight: 120,
  //   padding: 15,
  [`& .public-DraftEditorPlaceholder-root`]: {
    margin: '0 -15px -15px',
    padding: 15,
  },
  [`& .public-DraftEditor-content`]: {
    margin: '0 -15px -15px',
    padding: 15,
  },
  [`.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root`]: {
    display: 'none',
  },
}));
const styleMap = {
  CODE: {
    // backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

const TextEditor = ({
  placeholder,
  onChange,
  value,
  label,
  hint = '',
  goAIButton,
}) => {
  const editor = useRef();
  const [focused, setFocused] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const focus = () =>
    editor && editor.current ? editor.current.focus() : () => {};
  const onContentChange = editorState => {
    setEditorState(editorState);
    if (onChange) {
      var contentState = editorState.getCurrentContent();
      let html = stateToHTML(contentState);
      onChange(contentState && contentState.hasText() ? html : '');
    }
  };
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onContentChange(newState);
      return true;
    }
    return false;
  };

  const mapKeyToEditorCommand = e => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        onContentChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = blockType => {
    onContentChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = inlineStyle => {
    onContentChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };
  var contentState = editorState.getCurrentContent();
  const classNames = [];
  if (contentState.getBlockMap().first().getType() !== 'unstyled') {
    classNames.push('RichEditor-hidePlaceholder');
  }
  useEffect(() => {
    if (editorState) {
      var contentState = editorState.getCurrentContent();
      let html = stateToHTML(contentState);
      if (value !== html && value) {
        const blocksFromHTML = convertFromHTML(value);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        setEditorState(EditorState.createWithContent(state));
      }
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditorRoot focused={focused}>
        {label && (
          <Typography
            variant='caption'
            sx={{
              position: 'absolute',
              top: -10,
              fontSize: 12,
              padding: '0 5px',
              backgroundColor: theme => theme.palette.background.paper,
            }}>
            {label}
          </Typography>
        )}

        <Stack
          display={'inline-flex'}
          className='format-controls'
          direction={'row'}
          sx={{
            borderRadius: 1,
            padding: '2px 3px',
            transition: '0.1s all ease-in',
          }}>
          <InlineStyleControls
            editorState={editorState}
            onToggle={toggleInlineStyle}
          />
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            sx={{marginRight: '4px'}}
          />
          <BlockStyleControls
            editorState={editorState}
            onToggle={toggleBlockType}
          />
        </Stack>

        <EditorContainer onClick={focus} className={classNames.join(' ')}>
          <Editor
            blockStyleFn={getBlockStyle}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onContentChange}
            placeholder={placeholder}
            ref={editor}
            spellCheck={true}
          />
        </EditorContainer>
        {goAIButton && (
          <Box>
            <Divider
              sx={{
                mb: 1.6,
                mt: 0.3,
                marginLeft: '-15px',
                width: `calc(100% + 30px)`,
              }}
            />
            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography variant='body2'>{prefixLabel}</Typography>
              {goAIButton}
            </Stack>
          </Box>
        )}
      </EditorRoot>
      <Typography pl={2} mt={0.5} variant='body2' color='text.secondary'>
        {hint}
      </Typography>
    </>
  );
};

export default TextEditor;

TextEditor.propTypes = {
  label: PropTypes.string, // text field label
  onChange: PropTypes.func, // change event contains html content : (html) => {}
  placeholder: PropTypes.string, // placeholder for the text field
  value: PropTypes.string, // html content to bind the value to text field
};

import {useHistory} from 'react-router-dom';
import {Button, Dropdown} from 'antd';
import moment from 'moment';
import {CreateMenu, LazyImage, ErrorBoundary} from 'core/components';

import {maplocation, clockgrey} from 'assets/images';
import isString from 'lodash/isString';
import style from './style.module.less';

import './style.less';

const EventCard = ({data, enableNavigation, displayEllipses}) => {
  const {
    title,
    start_date,
    end_date,
    event_type,
    institute_id,
    institute_details: {
      name,
      address_details: {city: inst_city, state: inst_state} = {},
    } = {},
    business_partner_name: {company_name, city: bp_city, state: bp_state} = {},
    banner_cloudinary,
  } = data || {};
  const event_date = start_date ? moment(start_date).format('DD MMM') : '';
  const event_time = start_date ? moment(start_date).format('h:mm A') : '';

  const hideEllipse = moment().isAfter(end_date, 'second');

  let inst_name = name;
  let address = inst_city + ', ' + inst_state;
  if (!institute_id) {
    inst_name = company_name;
    address = bp_city + ', ' + bp_state;
  }
  const history = useHistory();
  const pathNavigateTo = resData => {
    history.push(`/events?event_id=${resData.event_id}`);
  };

  const menu = () => {
    let menu;
    if (displayEllipses?.length > 0) {
      menu = displayEllipses.map(item => {
        if (item.hasOwnProperty('passData')) {
          item['dataPassed'] = data[item.passData] || data;
        }
        return item;
      });
    }
    return <CreateMenu data={menu} />;
  };
  const top_notify_value =
    event_type && isString(event_type) && event_type.replace('-', '_');

  return (
    <>
      <ErrorBoundary nameOfComponent='core-eventcard'>
        <li className={style.eventCard}>
          {displayEllipses && !hideEllipse && (
            <span className={style.cardEllipses}>
              <Dropdown overlay={menu} overlayClassName='ddl_Unsubscribe'>
                <Button>...</Button>
              </Dropdown>
            </span>
          )}
          <div onClick={() => enableNavigation && pathNavigateTo(data)}>
            <LazyImage
              renderSrcSet
              type='event'
              src={banner_cloudinary}
              alt='event banner'
              className={style.img_fluid}
            />

            {event_type && (
              <div className={`${style.top_notify} ${style[top_notify_value]}`}>
                {event_type}
              </div>
              //className={`${style.top_notify} ${style.event_type}`}
            )}
            <div className={style.circle_date}>{event_date}</div>
            <div className={style.px_3}>
              {title && <h4 title={title}>{title}</h4>}
              <p>
                <span>Hosted By:</span>
                {inst_name}
              </p>
              <h5>
                <img src={maplocation} alt='location' className='img-fluid' />
                {address}
              </h5>
              <h5>
                <img src={clockgrey} alt='time' className='img-fluid' />
                {event_time}
              </h5>
            </div>
          </div>
        </li>
      </ErrorBoundary>
    </>
  );
};

export default EventCard;

export const CLEAR_CAREER_RANK = 'CLEAR_CAREER_RANK';
export const REQUEST_CAREER_RANK = 'REQUEST_CAREER_RANK';
export const RECIEVE_CAREER_RANK = 'RECIEVE_CAREER_RANK';
export const REQUEST_CAREER_RANK_ERROR = 'REQUEST_CAREER_RANK_ERROR';

// Occupation by cluster
export const REQUEST_OCCUPATION_BY_CLUSTER = 'REQUEST_OCCUPATION_BY_CLUSTER';
export const RECIEVE_OCCUPATION_BY_CLUSTER = 'RECIEVE_OCCUPATION_BY_CLUSTER';
export const REQUEST_OCCUPATION_BY_CLUSTER_ERROR =
  'REQUEST_OCCUPATION_BY_CLUSTER_ERROR';

//Related Career
export const REQUEST_RELATED_CAREERS = 'REQUEST_RELATED_CAREERS';
export const RECIEVE_RELATED_CAREERS = 'RECIEVE_RELATED_CAREERS';
export const REQUEST_RELATED_CAREERS_ERROR = 'REQUEST_RELATED_CAREERS_ERROR';

// Saved Career list
export const SAVED_CAREER_LIST_ERROR = 'SAVED_CAREER_LIST_ERROR';
export const SAVED_CAREER_LIST_CLEAR = 'SAVED_CAREER_LIST_CLEAR';
export const SAVED_CAREER_LIST_SUCCESS = 'SAVED_CAREER_LIST_SUCCESS';
export const SAVED_CAREER_LIST_REQUEST = 'SAVED_CAREER_LIST_REQUEST';

// Save Career
export const SAVE_CAREER_ERROR = 'SAVE_CAREER_ERROR';
export const SAVE_CAREER_REQUEST = 'SAVE_CAREER_REQUEST';
export const SAVE_CAREER_SUCCESS = 'SAVE_CAREER_SUCCESS';

import {useDispatch, useSelector} from 'react-redux';
import {getInputHistory} from 'redux/modules/general/selectors';
import CloseIcon from '@mui/icons-material/Close';
import {deleteInputHistoryItem} from 'redux/modules/general/inputHistory';

const {
  Box,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Typography,
  ListItemButton,
  Divider,
} = require('@mui/material');

const SearchHistory = ({onSubmit, dashboardReferer}) => {
  const dispatch = useDispatch();
  const searchHistory = useSelector(getInputHistory);
  const {jobs} = searchHistory || {};
  const onClickItem = data => {
    if (data.id) delete data.id;
    if (data._id) delete data._id;
    onSubmit?.(data);
  };
  const deleteItem = id => {
    dispatch(deleteInputHistoryItem(id, 'jobs'));
  };
  return (
    <Box>
      {Array.isArray(jobs) && jobs.length > 0 && (
        <>
          <Divider />
          <Typography variant='h6' py={2}>
            Recent Searches
          </Typography>
          <List dense sx={{bgcolor: 'background.paper', borderRadius: '4px'}}>
            {Array.isArray(jobs) &&
              jobs.map(data => {
                const {title, _id, location} = data;
                return (
                  <ListItem
                    disablePadding
                    key={_id}
                    secondaryAction={
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => deleteItem(_id)}>
                        <CloseIcon />
                      </IconButton>
                    }>
                    <ListItemButton onClick={() => onClickItem(data)}>
                      <ListItemText
                        primary={<Typography variant='h6'>{title}</Typography>}
                        secondary={
                          location ? (
                            <Typography variant='body2' color={'GrayText'}>
                              {location}
                            </Typography>
                          ) : null
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
        </>
      )}
    </Box>
  );
};

export default SearchHistory;

import {noBpIcon} from 'assets/images';
import './style.less';

const NoBusinessPartners = ({
  title = 'No Results Found',
  subTitle = 'Search for different jobs',
}) => {
  return (
    <>
      <div className='no-bp-found'>
        <div>
          <img src={noBpIcon} />
          {title && <h5>{title}</h5>}
          {subTitle && <p>{subTitle}</p>}
        </div>
      </div>
    </>
  );
};

export default NoBusinessPartners;

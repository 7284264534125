import React from 'react';
import {View, StyleSheet} from '@react-pdf/renderer';
import Divider from './Divider';
import CourseTable from './CourseTable';

const styles = StyleSheet.create({
  listView: {
    flexDirection: 'column',
    width: '100%',
  },
  listTables: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: 22,
    paddingRight: 8,
    paddingTop: 15,
  },
});

export default ({data}) => {
  let subSections = [[]];
  let subSectionIndex = 0;
  data?.terms?.length > 0 &&
    data.terms.forEach((term, idx) => {
      term['slNo'] = idx + 1;
      subSections[subSectionIndex].push(term);
      if (idx % 2 !== 0 && idx !== data.terms.length - 1) {
        subSectionIndex++;
        subSections.push([]);
      }
    });

  return (
    <View style={styles.listView}>
      <Divider title='LIST VIEW' />
      <View>
        {subSections?.length > 0 &&
          subSections.map((subSection, ssIdx) => {
            let pagebreak = false;
            ssIdx !== 0 &&
              subSections[ssIdx - 1].forEach(term => {
                if (
                  (ssIdx === 1 && term?.courses?.length >= 8) ||
                  term?.courses?.length >= 12
                ) {
                  pagebreak = true;
                }
              });

            return (
              <View
                break={pagebreak}
                key={`subSection-${ssIdx}`}
                style={styles.listTables}>
                {subSection?.length > 0 &&
                  subSection.map((term, termIndex) => {
                    return (
                      <CourseTable
                        data={term}
                        index={termIndex + 1}
                        key={`table-${termIndex}`}
                      />
                    );
                  })}
              </View>
            );
          })}
      </View>
      {/* <View style={styles.listTables}>
        {data &&
          data.terms &&
          data.terms.length > 0 &&
          data.terms.map((term, idx) => {
            return (
              term && (
                <CourseTable data={term} index={idx + 1} key={`table-${idx}`} />
              )
            );
          })}
      </View> */}
    </View>
  );
};

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const NetBanking = props => {
  return (
    <SvgIcon
      {...props}
      viewBox='0 0 46 46'
      fontSize='inherit'
      style={{fontSize: props.fontSize || '1em'}}>
      <g id='Education / award-03'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='46'
          height='46'
          viewBox='0 0 46 46'
          fill='none'>
          <path
            d='M5.75 40.2488H40.25M11.5 34.4988V19.1655M19.1667 34.4988V19.1655M26.8333 34.4988V19.1655M34.5 34.4988V19.1655M38.3333 13.4155L23.8127 4.34004C23.5178 4.15578 23.3704 4.06365 23.2124 4.02773C23.0726 3.99597 22.9274 3.99597 22.7876 4.02773C22.6296 4.06365 22.4822 4.15578 22.1873 4.34004L7.66667 13.4155H38.3333Z'
            stroke='url(#paint0_linear_31880_105938)'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <defs>
            <linearGradient
              id='paint0_linear_31880_105938'
              x1='40.25'
              y1='22.1264'
              x2='5.75'
              y2='22.1264'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#5E92F4' />
              <stop offset='0.76' stop-color='#A290F6' />
            </linearGradient>
          </defs>
        </svg>
      </g>
    </SvgIcon>
  );
};

export default NetBanking;

import * as TYPES from './constants';

// ROP List
export const requestROPList = () => ({
  type: TYPES.REQUEST_ROP_LIST,
});
export const receiveROPList = json => ({
  type: TYPES.RECEIVE_ROP_LIST,
  payload: json,
});
export const requestROPListError = err => ({
  type: TYPES.REQUEST_ROP_LIST_ERROR,
  err,
});
export const clearROPList = () => ({
  type: TYPES.CLEAR_ROP_LIST,
});

// Status Check
export const requestExistStatusCheck = () => ({
  type: TYPES.REQUEST_EXIST_STATUS_CHECK,
});
export const receiveExistStatusCheck = json => ({
  type: TYPES.RECEIVE_EXIST_STATUS_CHECK,
  payload: json,
});
export const requestExistStatusCheckError = err => ({
  type: TYPES.REQUEST_EXIST_STATUS_CHECK_ERROR,
  err,
});

// Business Sign-up
export const requestBusinessSignUp = () => ({
  type: TYPES.REQUEST_BUSINESS_SIGNUP,
});
export const receiveBusinessSignUp = json => ({
  type: TYPES.RECEIVE_BUSINESS_SIGNUP,
  payload: json,
});
export const requestBusinessSignUpError = err => ({
  type: TYPES.REQUEST_BUSINESS_SIGNUP_ERROR,
  err,
});

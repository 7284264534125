import * as TYPES from './constants';
import {getSettings, saveSettings} from 'redux/localStorage';

// Flash Message
export const displayMessage = message => ({
  type: TYPES.FLASH_MESSAGE,
  payload: message,
});

export const resetMessage = message => ({
  type: TYPES.RESET_FLASH_MESSAGE,
});

// States
export const requestStates = () => ({
  type: TYPES.REQUEST_STATES,
});
export const recieveStates = json => ({
  type: TYPES.RECIEVE_STATES,
  payload: json,
});
export const requestStatesError = err => ({
  type: TYPES.REQUEST_STATES_ERROR,
  err,
});

// Reset AppConfig
export const resetAppConfig = (isMobileView, winWidth) => ({
  type: TYPES.RESET_CONFIG,
  payload: {
    isMobileView,
    winWidth,
  },
});

//Change Language
export const changeLang = lang => {
  // get settings, than modfify lang and save to localstorage
  const Settings = getSettings();
  Settings.lang = lang;
  saveSettings(Settings);
  return {
    type: TYPES.CHANGE_LANG,
    payload: lang,
  };
};

// user input
export const getGlobalSearch = data => ({
  type: TYPES.GET_GLOBAL_SEARCH_ITEMS,
  payload: data,
});
export const addGlobalSearch = () => ({
  type: TYPES.ADD_GLOBAL_SEARCH_ITEM,
});
export const deleteGlobalSearch = () => ({
  type: TYPES.DELETE_GLOBAL_SEARCH_ITEM,
});

// theme

export const changeAppThemeMode = data => ({
  type: TYPES.CHANGE_APP_THEME_MODE,
  payload: data,
});

// input history

export const getInputHistoryAction = data => ({
  type: TYPES.GET_INPUT_HISTORY,
  payload: data,
});

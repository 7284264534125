import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowNarrowRight = props => {
  return (
    <SvgIcon {...props}>
      <path
        d='M4 12H20M20 12L14 6M20 12L14 18'
        stroke='currentColor'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </SvgIcon>
  );
};

export default ArrowNarrowRight;

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {Button, Grid, TextEditor} from 'mui-core';
import {useEffect, useImperativeHandle} from 'react';
import resumeBuilderData from 'data/resumeBuilder.json';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {datePickerSlotProp} from '../../sharedComponents';
import {Box, Stack, Typography, OutlinedInput} from '@mui/material';

const {
  stepsMeta: {licenses: {formData = {}} = {}},
} = resumeBuilderData || {};

const AddLicense = ({
  edit,
  formRef,
  onSubmit,
  onCancel,
  firstTime,
  data = {},
  getPayload,
}) => {
  const {org_name, description, program_name, date_received} = data || {};

  const {
    addBtnTxt,
    orgName = {},
    optionalText,
    cancelBtnTxt,
    AddLicenseTxt,
    licenseName = {},
    dateRecieved = {},
    descriptionObj = {},
    addAdditionalDetailsText,
  } = formData || {};

  const form = useForm({mode: 'all'});
  const {
    watch,
    trigger,
    getValues,
    clearErrors,
    handleSubmit,
    formState: {errors, isValid},
  } = form;

  useEffect(() => {
    watch(values => {
      const isFormEmpty = Object.values(values).every(value =>
        Array.isArray(value) ? value.length === 0 : !value,
      );

      if (isFormEmpty) {
        setTimeout(clearErrors);
      }
    });
  }, [watch]);

  const onFinish = (newData, e) => {
    if (onSubmit && isEmpty(errors)) {
      const updatedData = {...data, ...newData};
      if (updatedData.date_received) {
        const {years, months} = moment(updatedData.date_received).toObject();
        updatedData.date_received = moment()
          .date(5)
          .year(years)
          .month(months)
          .toISOString();
        updatedData.school_end_date = moment()
          .date(5)
          .year(years)
          .month(months)
          .format('YYYY-MM-DD');
      }
      onSubmit(updatedData);
    }
  };

  useImperativeHandle(formRef, () => ({
    validateForm() {
      let isFormValid = true;
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      if (isFormFilled) {
        trigger();
        isFormValid = isValid;
      }
      return isFormValid;
    },
    getFormData() {
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      return isFormFilled ? getPayload({...data, ...getValues()}) : null;
    },
  }));

  return (
    <Box>
      <Stack mt={firstTime ? 0 : 1}>
        {!edit && !firstTime && (
          <Typography
            mt={1}
            variant='h5'
            color='text.primary'
            title={AddLicenseTxt}>
            {AddLicenseTxt}
          </Typography>
        )}
        <Box mx='auto' my={2}>
          <form onSubmit={handleSubmit(onFinish)}>
            <Grid
              container
              spacing={1}
              display='flex'
              rowSpacing={4}
              alignItems='center'
              justifyContent='center'>
              <Grid item xs={12}>
                {createField('program_name', {
                  form,
                  size: 'small',
                  label: licenseName.label,
                  defaultValue: program_name || '',
                  rules: {
                    required: [true, licenseName.requiredMsg],
                    maxLength: [120, licenseName.maxLengthMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    label={licenseName.label}
                    id='resume-education-license-name'
                    placeholder={licenseName.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {createField('org_name', {
                  form,
                  size: 'small',
                  label: orgName.label,
                  defaultValue: org_name || '',
                  rules: {
                    maxLength: [120, orgName.maxLengthMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    label={orgName.label}
                    id='resume-education-org-name'
                    placeholder={orgName.placeholder}
                  />,
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                justifyContent='flex-start'>
                {createField('date_received', {
                  form,
                  rules: {
                    required: [true, dateRecieved.requiredMsg],
                  },
                  defaultValue: date_received ? moment(date_received) : null,
                })(
                  <DatePicker
                    sx={{width: '50%'}}
                    views={['year', 'month']}
                    label={dateRecieved.label}
                    maxDate={moment(new Date())}
                    slotProps={datePickerSlotProp}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                <Stack
                  spacing={1}
                  direction='row'
                  alignItems='center'
                  justifyContent='start'>
                  <Typography
                    variant='h5'
                    color='text.primary'
                    title={addAdditionalDetailsText}>
                    {addAdditionalDetailsText}
                  </Typography>
                  <Typography
                    variant='body1'
                    color='text.primary'
                    title={optionalText}>
                    {`(${optionalText})`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {createField('description', {
                  form,
                  defaultValue: description || '',
                })(<TextEditor label={descriptionObj.label} />)}
              </Grid>
              <Grid item>
                {!firstTime && (
                  <Button
                    variant='outlined'
                    onClick={onCancel}
                    sx={{mr: 2, minWidth: '100px'}}>
                    {cancelBtnTxt}
                  </Button>
                )}
                <Button
                  type='submit'
                  formNoValidate
                  variant='contained'
                  sx={{minWidth: '100px'}}>
                  {addBtnTxt}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default AddLicense;

import React, {useState} from 'react';
import LicenseList from './LicenseList';
import {useSelector} from 'react-redux';
import {ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {getResumeData} from 'redux/modules/resume-builder';

const Licenses = ({formRef, handleSave}) => {
  const [isLoading, setisLoading] = useState(false);
  const {data: resumeData, request} = useSelector(getResumeData);

  let licenseList = [];
  let otherCredentials = [];
  resumeData?.credentials?.forEach(ele => {
    if (ele.credential_type === 'license') {
      licenseList?.push(ele);
    } else {
      otherCredentials?.push(ele);
    }
  });

  const onSubmit = (data, action) => {
    const payload = getPayload(data, action);
    handleSave(payload);
  };

  const getPayload = (data, action) => {
    if (action === 'edit') {
      licenseList = licenseList?.map(licenseObj => {
        if (licenseObj.display_order === data.display_order) {
          return data;
        } else {
          return licenseObj;
        }
      });
    } else if (action === 'delete') {
      const updatedCredentials = licenseList?.filter(
        item => item.display_order !== data.display_order,
      );
      licenseList = updatedCredentials?.map((item, index) => {
        return {...item, display_order: index + 1};
      });
    } else {
      licenseList?.push({
        ...data,
        credential_type: 'license',
        display_order: licenseList.length + 1,
      });
    }

    return {credentials: [...otherCredentials, ...licenseList]};
  };

  return (
    <ErrorBoundary
      typeOfUi='subPage'
      nameOfComponent='resume-builder-licenses-&-certifications'>
      <RequestErrorLoader
        hideEmpty
        body={{data: licenseList, request: isLoading || request}}>
        <LicenseList
          hideTitle
          hideEmpty
          formRef={formRef}
          data={resumeData}
          onChange={onSubmit}
          getPayload={getPayload}
        />
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default Licenses;

import {useState} from 'react';
import {styled} from '@mui/material/styles';
import {Box, Stack, Divider, Typography} from '@mui/material';
import {
  Grid,
  PathCard,
  Accordion,
  SkillsStack,
  ErrorBoundary,
  skillsLearned,
  ProgramCourseMap,
  NoContentNavigator,
  RequestErrorLoader,
} from 'mui-core';
import {API} from 'config';
import moment from 'moment';
import {useAuth} from 'core/hooks';
import {useSnackbar} from 'notistack';
import {Services} from 'core/Services';
import {FmdGood} from '@mui/icons-material';
import RemoveProgramDialog from './RemoveProgramDialog';
import ProgramStatusChanger from './ProgramStatusChanger';
import {
  DeleteActivePath,
  NoActivePathFound,
  PathWillBeRemoved,
  ExploreProgramsBtnText,
  DeleteActivePathMessage,
} from 'data/pathway';

const services = new Services();

// const calcScrollerHeight = parent => {
//   if (parent) {
//     const children = parent.children;
//     if (children.length > 1) {
//       const height = children[0].offsetHeight * 1.5;
//       return height;
//     }
//   }
//   return null;
// };

const ButtonGroupOptions = [
  {
    key: 'onboard',
    label: 'In Progress',
  },
  {
    key: 'complete',
    label: 'Completed',
  },
];

const StyledProgramsContainer = styled(Box)(() => ({
  overflowY: 'auto',
  minHeight: 'auto',
  maxHeight: '700px',
  overflowX: 'hidden',
  '@media (min-width: 320px) and (max-width: 768px)': {
    overflowX: 'hidden',
  },
}));

const MyActivePrograms = props => {
  const {
    activePaths,
    fetchActivePaths,
    deleteActivePath,
    fetchStudentMetrics,
  } = props;
  const [token] = useAuth();
  // const scrollElement = useRef();
  const {enqueueSnackbar} = useSnackbar();
  const [deletePath, setDeletePath] = useState(false);
  const [loadingPathStatus, setLoadingPathStatus] = useState(false);
  const {
    data: activePathData,
    error: activePathError,
    request: activePathRequest,
  } = activePaths || {};
  const [showDeleteActivePathModal, setShowDeleteActivePathModal] = useState(
    false,
  );

  const handleMenuItemClick = (key, student_onboard_uuid) => {
    onActivePathStatusChange(key, student_onboard_uuid);
  };

  // useEffect(() => {
  //   if (scrollElement?.current) {
  //     const height = calcScrollerHeight(scrollElement.current);
  //     if (height && scrollElement?.current?.style) {
  //       scrollElement.current.style.height = height + 'px';
  //     } else {
  //       scrollElement.current.style.height = 'auto';
  //     }
  //   }
  // }, [activePaths]);

  const onDeleteActivePath = async () => {
    const onBoard_uuid = deletePath.uuid;
    if (onBoard_uuid) {
      setLoadingPathStatus(true);
      await deleteActivePath(onBoard_uuid, err => {
        if (err) {
          enqueueSnackbar(err, {
            variant: 'error',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        } else {
          enqueueSnackbar(DeleteActivePathMessage, {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
          fetchStudentMetrics();
        }
        onCloseDeleteActivePathModal();
        setLoadingPathStatus(false);
        setDeletePath(false);
      });
    }
  };

  const onCloseDeleteActivePathModal = () =>
    setShowDeleteActivePathModal(false);

  const onDeleteMenu = (title, uuid) => {
    setDeletePath({title, uuid});
    setShowDeleteActivePathModal(true);
  };

  const onActivePathStatusChange = async (key, student_onboard_uuid) => {
    const body = {completed_stage: key};
    setLoadingPathStatus(student_onboard_uuid);
    services
      .createUpdateRecord(
        token,
        `${API.gps.student_onboard}/${student_onboard_uuid}`,
        body,
        'PATCH',
      )
      .then(res => {
        if (res?.path_uuid && res.completed_stage === 'complete') {
          enqueueSnackbar('Program successfully added to your Education.', {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        }
        fetchActivePaths();
        fetchStudentMetrics();
        setLoadingPathStatus(false);
      })
      .catch(err => {
        setLoadingPathStatus(false);
      });
  };

  return (
    <ErrorBoundary nameOfComponent='mod-comp-settings-myeducation-mypaths'>
      <RequestErrorLoader minHeight={100} hideEmpty body={activePaths}>
        <StyledProgramsContainer
        // ref={scrollElement}
        >
          {activePathData &&
          !activePathError &&
          !activePathRequest &&
          Array.isArray(activePathData) &&
          activePathData.length > 0 ? (
            activePathData.map((pathInfo, index) => {
              const SkillSet =
                skillsLearned(pathInfo?.path_details, true) || [];
              let SkillsLabel = 'Skills Learned';
              if (SkillSet?.length) SkillsLabel += ` (${SkillSet.length})`;

              return (
                <Box key={pathInfo.path_uuid}>
                  <Stack
                    rowGap={1}
                    spacing={1}
                    direction='row'
                    marginBottom={2}
                    paddingRight={2}
                    alignItems='center'
                    alignContent='center'
                    justifyContent='space-between'>
                    <Typography variant='body1' fontSize={13}>
                      {`Started On: ${moment(pathInfo.created_at).format(
                        'MMM DD, YYYY',
                      )}`}
                    </Typography>
                    <ProgramStatusChanger
                      loadingPathStatus={loadingPathStatus}
                      options={ButtonGroupOptions}
                      id={pathInfo.student_onboard_uuid}
                      onMenuSelection={handleMenuItemClick}
                      defValue={
                        pathInfo.completed_stage === 'explore_paths' ||
                        pathInfo.completed_stage === 'onboard'
                          ? ButtonGroupOptions[0]
                          : ButtonGroupOptions[1]
                      }
                    />
                  </Stack>
                  <Grid
                    container
                    rowSpacing={{xs: 3, sm: 3, md: 3, lg: 3}}
                    columnSpacing={{xs: 3, sm: 3, md: 3, lg: 3}}>
                    <Grid item xs={12} md={'auto'}>
                      <PathCard
                        width={240}
                        enableNavigation
                        data={pathInfo?.path_details || {}}
                        displayEllipses={[
                          {
                            title: 'Remove',
                            action: () =>
                              onDeleteMenu(
                                pathInfo?.path_details?.title,
                                pathInfo?.student_onboard_uuid,
                              ),
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md>
                      <Box>
                        <Box
                          pb={1}
                          sx={{
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderBottomColor: 'divider',
                          }}>
                          <Typography
                            variant='body1'
                            margin={'0px 8px 12px 0px'}>
                            {SkillsLabel}
                          </Typography>
                        </Box>
                        <SkillsStack
                          displayLimit={15}
                          skillSet={SkillSet}
                          displayShowAll={true}
                          emptyMsg='No skills found for this program'
                        />
                      </Box>
                    </Grid>
                    {pathInfo?.path_details?.terms.length > 0 && (
                      <Grid item xs={12} sm={12} md={12} lg={12} pr={3}>
                        <Accordion
                          rounded
                          TransitionProps={{
                            unmountOnExit: true,
                            mountOnEnter: true,
                            timeout: 0,
                          }}
                          id='program_course_map'
                          icon={
                            <FmdGood
                              fontSize='large'
                              sx={{
                                color: 'white',
                                padding: '6px',
                                borderRadius: '4px',
                                marginRight: '10px',
                                backgroundColor: '#8050f4',
                              }}
                            />
                          }
                          titleDivider={false}
                          header='Program Course Map'>
                          <ProgramCourseMap
                            showModal={true}
                            pathInfo={pathInfo}
                          />
                        </Accordion>
                      </Grid>
                    )}
                  </Grid>

                  {activePathData.length === index + 1 ? (
                    <br />
                  ) : (
                    <Divider sx={{margin: '20px'}} />
                  )}
                </Box>
              );
            })
          ) : (
            <NoContentNavigator
              pathTo='/programs'
              message={NoActivePathFound}
              label={ExploreProgramsBtnText}
            />
          )}
        </StyledProgramsContainer>

        {showDeleteActivePathModal && (
          <RemoveProgramDialog
            title={DeleteActivePath}
            deleteObject={deletePath}
            loader={loadingPathStatus}
            description={PathWillBeRemoved}
            onConfirmDialog={onDeleteActivePath}
            showDialog={showDeleteActivePathModal}
            onCloseDialog={onCloseDeleteActivePathModal}
          />
        )}
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default MyActivePrograms;

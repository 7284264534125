import {useImperativeHandle, useEffect, useState} from 'react';
import {useUser} from 'core/hooks';
import {PATTERN} from 'core/regex';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import {useForm} from 'react-hook-form';
import Select from '@mui/material/Select';
import {createField} from 'mui-core/Form';
import {styled} from '@mui/material/styles';
import {AutoComplete, Button} from 'mui-core';
import MenuItem from '@mui/material/MenuItem';
import {getStates} from 'redux/modules/general';
import {getResumeList} from 'redux/modules/resumes';
import {useSelector, useDispatch} from 'react-redux';
import profileData from 'data/settings-profile.json';
import {Box, Divider, Typography} from '@mui/material';
import resumeBuilderData from 'data/resumeBuilder.json';
import {Grid, OutlinedInput, FormHelperText} from '@mui/material';
import {
  getResumeData,
  getLinkedInDetails,
  fetchlinkedInDetails,
  clearLinkedInDetails,
} from 'redux/modules/resume-builder';

let tempResumesList = [];

const {
  stepsMeta: {
    contactInfo: {title2, formData = {}},
  },
} = resumeBuilderData || {};

const {addBtnTxt} = formData;

const HeaderWrapper = styled(Box)(({}) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const GradientDivider = styled(Divider)(() => ({
  borderImage:
    'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  borderImageSlice: '20%',
  borderImageWidth: '2px',
}));

const ContactInformation = props => {
  const form = useForm({mode: 'all'});
  const {data: userData} = useUser();
  const {data: statesList = [], request: statesRequest} =
    useSelector(getStates) || {};
  const {
    reset,
    trigger,
    setValue,
    setError,
    getValues,
    formState,
    clearErrors,
    handleSubmit,
  } = form;
  const {job_title: linkedInData, job_title_request} =
    useSelector(getLinkedInDetails) || {};
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const {data: resumeData} = useSelector(getResumeData) || {};
  const {data: resumeList = []} = useSelector(getResumeList) || {};

  const {formRef, resume_uuid, handleSave} = props;
  const {isValid, errors, isDirty} = formState;

  const job_title = resumeData?.job_title || '';
  const resume_name = resumeData?.resume_name || '';
  const city = resumeData?.city || userData?.city || '';
  const state = resumeData?.state || userData?.state || '';
  const email = resumeData?.email || userData?.email || '';
  const zipcode = resumeData?.zipcode || userData?.zipcode || '';
  const last_name = resumeData?.last_name || userData?.last_name || '';
  const first_name = resumeData?.first_name || userData?.first_name || '';
  const phone_number = resumeData?.phone_number || userData?.phone_number || '';
  const street_address =
    resumeData?.street_address || userData?.street_address || '';

  const {
    zip,
    jobTitle,
    resumeName,
    firstName,
    lastName,
    phoneNumber,
    streetAddress,
    city: cityField,
    state: stateField,
    email: emailField,
  } = formData || {};

  useEffect(() => {
    tempResumesList = resumeList;
  }, [resumeList?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    maskZipcode(zipcode);
  }, [zipcode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    reset({
      city,
      state,
      email,
      job_title,
      last_name,
      first_name,
      resume_name,
      phone_number,
      street_address,
      zipcode: maskZipcode(zipcode),
    }); // eslint-disable-next-line
  }, [
    reset,
    city,
    state,
    email,
    zipcode,
    job_title,
    last_name,
    first_name,
    resume_name,
    phone_number,
    street_address,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(formRef, () => ({
    validateForm() {
      trigger();
      return isValid;
    },
    getFormData() {
      return isDirty ? getPayload(getValues()) : null;
    },
  }));

  const onFinish = (data, e) => {
    if (handleSave && isEmpty(errors)) {
      handleSave(getPayload(data));
    }
  };

  const getPayload = data => {
    return {...data, zipcode: data.zipcode.replace('-', '')};
  };

  const isNameUnique = resumeName => {
    const list = tempResumesList || [];
    const existingNames = list
      .filter(resume => resume.resume_uuid !== resume_uuid)
      .map(resume => resume.resume_name?.toLowerCase());
    return !existingNames.includes(resumeName.toLowerCase());
  };

  const maskZipcode = value => {
    let maskedValue = '';
    const plainValue = value.slice(0, 10).replace('-', '');
    if (value.length <= 10) {
      if (value.length > 5) {
        const splited1 = plainValue.slice(0, 5);
        const splited2 = plainValue.slice(5, plainValue.length);
        maskedValue =
          splited2 !== '-' || splited2 !== ''
            ? `${splited1}-${splited2}`
            : splited1;
      } else {
        maskedValue = plainValue;
      }
      setValue('zipcode', maskedValue);
    }

    return maskedValue;
  };

  function useDebounce(value, delay, initialValue) {
    const [state, setState] = useState(initialValue);

    useEffect(() => {
      const timer = setTimeout(() => setState(value), delay);
      // clear timeout should the value change while already debouncing
      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return state;
  }

  const debouncedValue = useDebounce(keyword, 500) || '';

  useEffect(() => {
    if (debouncedValue) {
      dispatch(fetchlinkedInDetails('job_title', debouncedValue));
    }
  }, [debouncedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (_, value, r) => {
    if (value.length === 0) {
      if (open) setOpen(false);
    } else {
      if (!open) setOpen(true);
    }
    if (r === 'clear') {
      setKeyword(null);
      setValue('job_title', null);
      dispatch(clearLinkedInDetails());
      setError('job_title', {type: 'required', message: jobTitle.requiredMsg});
    }
    if (r === 'input') {
      setKeyword(value);
      if (value.length === 0)
        setError('job_title', {
          type: 'required',
          message: jobTitle.requiredMsg,
        });
    }
  };

  const onClearField = () => {
    dispatch(clearLinkedInDetails());
  };

  return (
    <form onSubmit={handleSubmit(onFinish)}>
      <Grid
        container
        rowSpacing={3}
        sx={{pt: '10px', mb: '24px'}}
        columnSpacing={{xs: 1, sm: 2, md: 3}}>
        <Grid item xs={12} sm={12}>
          {createField('resume_name', {
            label: resumeName.label,
            form,
            rules: {
              required: [true, resumeName.requiredMsg],
              maxLength: [60, resumeName.maxLengthMsg],

              validate: [
                (value, formValues) => isNameUnique(value),
                resumeName.alreadyExistsMsg,
              ],
            },
            size: 'small',
            defaultValue: resume_name || '',
          })(
            <OutlinedInput
              variant='outlined'
              id='outlined-basic'
              label={resumeName.label}
              placeholder={resumeName.placeholder}
            />,
          )}
        </Grid>
        {/* Commenting as per the feedback of the customer : Sprint-122 */}
        {/* 
          <Grid item xs={12} sm={12}>
            <Typography
              variant='subtitle1'
              color='text.primary'
              fontWeight={500}
              sx={{mb: '10px'}}>
              {jobTitle.heading}
            </Typography>
            {createField('job_title', {
              form,
              size: 'small',
              rules: {
                required: [true, jobTitle.requiredMsg],
              },
              defaultValue: job_title || '',
            })(
              <AutoComplete
                open={open}
                fieldName='job_title'
                calledFromForm={true}
                forcePopupIcon={false}
                value={job_title || ''}
                clearErrors={clearErrors}
                filterSelectedOptions={true}
                onClearField={onClearField}
                request={job_title_request}
                errors={errors['job_title']}
                onInputChange={handleSearch}
                onClose={() => setOpen(false)}
                freeSolo={
                  !(keyword && !job_title_request && linkedInData?.length === 0)
                }
                setValue={val =>
                  setValue('job_title', val, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }
                options={
                  linkedInData &&
                  Array.isArray(linkedInData) &&
                  linkedInData?.length > 0
                    ? linkedInData?.map(item => {
                        return {
                          ...item,
                          key: item.id,
                          label: item.job_title,
                          title: item.job_title,
                        };
                      })
                    : []
                }
                label={`${jobTitle.label} *`}
                placeholder={jobTitle.placeholder}
              />,
            )}
            {!errors['job_title'] && (
              <FormHelperText sx={{fontSize: 12}}>
                {jobTitle.helperText}
              </FormHelperText>
            )}
          </Grid> */}
      </Grid>
      <Box sx={{mb: '15px'}}>
        {/* <HeaderWrapper>
            <Typography variant='h3' color='text.primary' fontWeight={300}>
              {title2}
            </Typography>
          </HeaderWrapper>
          <GradientDivider sx={{my: 2}} /> */}
        <Grid
          container
          rowSpacing={4}
          display='flex'
          sx={{pt: '10px'}}
          justifyContent='center'
          columnSpacing={{xs: 1, sm: 2, md: 3}}>
          <Grid item xs={12} sm={6}>
            {createField('first_name', {
              label: firstName.label,
              form,
              size: 'small',
              rules: {
                required: [true, firstName.requiredMsg],
              },
              defaultValue: first_name || '',
            })(
              <OutlinedInput
                id='outlined-basic'
                label={firstName.label}
                variant='outlined'
              />,
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createField('last_name', {
              label: lastName.label,
              form,
              size: 'small',
              rules: {
                required: [true, lastName.requiredMsg],
              },
              defaultValue: last_name || '',
            })(
              <OutlinedInput
                label={lastName.label}
                variant='outlined'
                id='outlined-basic'
              />,
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createField('phone_number', {
              label: phoneNumber.label,
              form,
              size: 'small',
              rules: {
                pattern: [PATTERN?.Phone, phoneNumber.requiredMsg],
              },
              defaultValue: phone_number,
            })(
              <OutlinedInput
                variant='outlined'
                id='outlined-basic'
                label={phoneNumber.label}
                inputProps={{maxLength: 10}}
              />,
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createField('email', {
              label: emailField.label,
              form,
              size: 'small',
              rules: {
                pattern: [PATTERN?.Email, emailField.requiredMsg],
              },
              defaultValue: email,
              disabled: true,
            })(
              <OutlinedInput
                variant='outlined'
                id='outlined-basic'
                label={emailField.label}
              />,
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {createField('street_address', {
              label: streetAddress.label,
              form,
              size: 'small',
              defaultValue: street_address,
              rules: {
                validate: [
                  (value, formValues) =>
                    !value
                      ? !formValues.state &&
                        !formValues.zipcode &&
                        !formValues.city
                        ? clearErrors('street_address')
                        : false
                      : true,
                  streetAddress.requiredMsg,
                ],
              },
            })(
              <OutlinedInput
                variant='outlined'
                id='outlined-basic'
                label={streetAddress.label}
              />,
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            {createField('city', {
              label: cityField.label,
              form,
              size: 'small',
              defaultValue: city,
              rules: {
                validate: [
                  (value, formValues) =>
                    !value
                      ? !formValues.state &&
                        !formValues.zipcode &&
                        !formValues.street_address
                        ? clearErrors('city')
                        : false
                      : true,
                  cityField.requiredMsg,
                ],
              },
            })(
              <OutlinedInput
                label={cityField.label}
                variant='outlined'
                id='outlined-basic'
              />,
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            {createField('state', {
              label: stateField.label,
              form,
              size: 'small',
              defaultValue: state,
              rules: {
                validate: [
                  (value, formValues) =>
                    !value
                      ? !formValues.city &&
                        !formValues.zipcode &&
                        !formValues.street_address
                        ? clearErrors('state')
                        : false
                      : true,
                  stateField.requiredMsg,
                ],
              },
            })(
              <Select
                label={stateField.label}
                variant='outlined'
                sx={{
                  fontSize: '15px',
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}>
                <MenuItem value='' key='none'>
                  None
                </MenuItem>
                {statesList &&
                  isArray(statesList) &&
                  statesList.length > 0 &&
                  statesList.map(item => (
                    <MenuItem value={item.name} key={item.state_uuid}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>,
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            {createField('zipcode', {
              label: zip.label,
              form,
              size: 'small',
              defaultValue: zipcode,
              rules: {
                pattern: [PATTERN?.Zip_Code, zip.validationMsg],
                validate: [
                  (value, formValues) =>
                    !value
                      ? !formValues.state &&
                        !formValues.city &&
                        !formValues.street_address
                        ? clearErrors('zipcode')
                        : false
                      : true,
                  zip.requiredMsg,
                ],
              },
            })(
              <OutlinedInput
                label={zip.label}
                variant='outlined'
                id='outlined-basic'
                inputProps={{maxLength: 10}}
                onKeyUp={e => maskZipcode(e.target.value)}
              />,
            )}
          </Grid>
          <Grid item>
            <Button
              type='submit'
              formNoValidate
              variant='contained'
              sx={{minWidth: '100px'}}>
              {addBtnTxt}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default ContactInformation;

import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {debounce, uniqBy} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Stack, TextField, Typography, Divider} from '@mui/material';
import {
  fetchCareerCraft,
  getCareerCraftData,
  getResumeData,
  updateResumeData,
} from 'redux/modules/resume-builder';
import resumeBuilderData from 'data/resumeBuilder.json';
import {GoAIButton, GoAIListForm} from '../../sharedComponents';

import AIModal from './AIModal';
import {clearCareerCraft} from 'redux/modules/resume-builder/actions';
import {Button} from 'mui-core';
import {AIModalID} from '../../sharedComponents/GoAIButton';

const {
  stepsMeta: {
    professionalSummary: {hint = '', description = ''},
    skills: {addBtnTxt},
  },
  goAIButton: {prefixLabel},
} = resumeBuilderData || {};

const Summary = ({handleSave, formRef}) => {
  //states
  const [aiModal, setAIModal] = useState(false);
  const [summaryText, setSummaryText] = useState();
  //hooks
  const {data: resumeData} = useSelector(getResumeData);
  const {data: careerCraft = [], request} = useSelector(getCareerCraftData);
  const dispatch = useDispatch();
  //vars
  const summary = resumeData?.summary;
  const {skills, work_exp} = resumeData || {};
  const [exp, setExp] = useState(
    (resumeData?.total_experience < 0 ? 0 : resumeData?.total_experience) || 0,
  );

  useEffect(() => {
    dispatch(clearCareerCraft());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //func
  const update = useCallback(
    debounce(value => {
      dispatch(updateResumeData(4, {summary: value}));
    }, 600),
    [],
  );
  const onChange = e => {
    setSummaryText(e.target.value);
    // update(e.target.value);
  };
  const fetchCareerCraftData = values => {
    // dispatch(clearCareerCraft());
    const {job_title, years_of_exp, skills, desired_job_title} = values || {};
    const obj = {};
    if (job_title) obj.job_title = job_title;
    if (years_of_exp) obj.years_of_exp = years_of_exp;
    if (skills) obj.skills = skills;
    if (desired_job_title) obj.desired_job_title = desired_job_title;
    if (Object.keys(obj).length > 0) {
      dispatch(fetchCareerCraft('professional_summary', obj));
    }
  };
  const addToResume = v => {
    if (v && Array.isArray(v) && v.length > 0) {
      const value = v.join('\r\n');
      setSummaryText(value);
      update(value);
    }
    setAIModal(false);
  };
  let skillsData =
    skills && Array.isArray(skills) ? skills.map(item => item.skill) : [];
  if (work_exp && Array.isArray(work_exp) && work_exp.length > 0) {
    work_exp.forEach(item => {
      const {skills} = item;
      if (Array.isArray(skills) && skills.length > 0) {
        skills.forEach(sk => {
          if (skillsData.indexOf(sk.skill) === -1) skillsData.push(sk.skill);
        });
      }
    });
  }
  const getFormValues = () => {
    return {summary: summaryText, total_experience: exp};
  };

  useImperativeHandle(formRef, () => ({
    validateForm() {
      return true;
    },
    getFormData() {
      return getFormValues();
    },
  }));

  const saveSummary = () => {
    const payload = getFormValues();
    handleSave(payload);
  };

  useEffect(() => {
    if (!request && careerCraft && careerCraft.length > 0) {
      const el = document.getElementById(AIModalID);
      if (el) {
        el.scroll({
          top: 500,
          behavior: 'smooth',
        });
      }
    }
  }, [request, careerCraft]);
  return (
    <Stack gap={3}>
      <Typography variant='body2' color='text.primary'>
        {description}
      </Typography>
      <Box>
        <TextField
          minRows={5}
          fullWidth
          value={summaryText}
          defaultValue={summary}
          label='Professional Summary'
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          onChange={onChange}
          // onKeyPress={update}
          placeholder='Summary...'
        />
        <Stack direction='row' alignItems='center' gap={1.5} mt={2}>
          <Typography variant='body2' color='text.primary'>
            {prefixLabel}
          </Typography>
          <GoAIButton
            openDialog={aiModal}
            setOpenDialog={setAIModal}
            descriptionCenter={true}
            fetchData={() => {
              setAIModal(true);
            }}>
            <Box>
              <AIModal
                request={request}
                fetchData={fetchCareerCraftData}
                skillsData={skillsData}
                careerCraft={careerCraft || []}
                submitListForm={() => {}}
              />
              {careerCraft && careerCraft.length > 0 && (
                <>
                  <Divider />
                  <Box sx={{py: 3, px: 5}} color={'darkGray'}>
                    <Typography mb={3}>
                      Choose a response to add to your resume:
                    </Typography>
                    <GoAIListForm
                      isPlainText
                      list={careerCraft || []}
                      submitListForm={addToResume}
                    />
                  </Box>
                </>
              )}
            </Box>
          </GoAIButton>
        </Stack>
        <Box mt={4}>
          <TextField
            fullWidth
            value={exp}
            type='number'
            defaultValue={exp}
            label='Years Of Experience'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              e.target.value >= 0 ? setExp(e.target.value) : setExp(0);
            }}
            // onKeyPress={update}
            placeholder='Enter your total years of experience'
          />
        </Box>
        <Stack mt={4} direction={'row'} justifyContent={'center'}>
          <Button
            onClick={saveSummary}
            // disabled={!dirtyFields.skills}
            variant={'contained'}>
            {addBtnTxt}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};
export default Summary;

import PropTypes from 'prop-types';
import {useHistory} from 'react-router';
import {useTheme} from '@mui/material/styles';
import {Stack, Typography} from '@mui/material';
import {Button, BackgroundPaper} from 'mui-core';
import {noResultsIcon, noSearchResults} from 'assets/images';

const NoResults = ({
  navPath,
  navTitle,
  subTitle,
  onButtonClick,
  showSearchIcon = false,
  hideNavigationButton = false,
  title = 'Oops! No Results Found',
}) => {
  const theme = useTheme();
  const history = useHistory();
  const onNavigation = () => {
    navPath && history.push(navPath);
    onButtonClick && onButtonClick();
  };

  return (
    <BackgroundPaper
      p={2}
      borderRadius={4}
      background={{
        dark: theme.palette.darkGray.main,
        light: theme.palette.purplebg4perc.main,
      }}>
      <Stack alignItems='center' spacing={2} borderRadius={2}>
        {showSearchIcon ? (
          <img width='42' height='42' src={noSearchResults} />
        ) : (
          <img src={noResultsIcon} width={80} height={80} />
        )}
        {title && (
          <Typography fontSize={20} variant='h4' color='text.primary'>
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography variant='bodyL' textAlign='center' color='text.primary'>
            {subTitle}
          </Typography>
        )}
        {!hideNavigationButton && (navPath || onButtonClick) && navTitle && (
          <Button size='large' variant='contained' onClick={onNavigation}>
            {navTitle}
          </Button>
        )}
      </Stack>
    </BackgroundPaper>
  );
};

NoResults.prototype = {
  title: PropTypes.string,
  navPath: PropTypes.string,
  navTitle: PropTypes.string,
  subTitle: PropTypes.string,
  onButtonClick: PropTypes.func,
  showSearchIcon: PropTypes.bool,
  hideNavigationButton: PropTypes.bool,
};

export default NoResults;

import {useEffect, useRef} from 'react';
import {Input, Button, Form} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {ErrorBoundary} from 'core/components';

import styles from './style.module.less';

const InputWithIcons = ({
  value,
  label,
  name,
  inlineForm,
  placeholder,
  handleSave,
  enableClear,
  enableCheck,
}) => {
  const iconClasses = `anticon anticon-close-circle ant-input-clear-icon`;
  const inputRef = useRef();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      [name]: value,
    });
  }, [form, name, value]);

  const onClear = () => {
    form.setFieldsValue({
      [name]: '',
    });
    form.submit();
  };

  return (
    <ErrorBoundary nameOfComponent='core-inputwithicons'>
      <Form
        form={form}
        className={styles.inputWithIcons}
        layout={inlineForm ? 'horizontal' : 'vertical'}
        onFinish={formData => handleSave(formData, inputRef)}>
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              type: 'url',
              message: `Invalid URL format, use https://`,
            },
          ]}>
          <Input
            ref={inputRef}
            title={value}
            placeholder={placeholder}
            suffix={
              <>
                {enableCheck && !value && (
                  <Button
                    htmlType='submit'
                    size='small'
                    aria-label='save'
                    style={{
                      color: 'green',
                      fontSize: '110%',
                      height: 15,
                      padding: 0,
                    }}
                    className={iconClasses}>
                    <CheckCircleOutlined />
                  </Button>
                )}
                {enableClear && value && (
                  <Button
                    htmlType='reset'
                    size='small'
                    aria-label='reset'
                    onClick={onClear}
                    style={{
                      color: 'red',
                      fontSize: '110%',
                      height: 15,
                      padding: 0,
                    }}
                    className={iconClasses}>
                    <CloseCircleOutlined />
                  </Button>
                )}
              </>
            }
          />
        </Form.Item>
      </Form>
    </ErrorBoundary>
  );
};

InputWithIcons.propTypes = {
  handleSave: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  enableCheck: PropTypes.bool,
  enableClear: PropTypes.bool,
};

export default InputWithIcons;

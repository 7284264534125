import {Accordion, Button, DialogBox, RequestErrorLoader} from 'mui-core';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {SuccessTickGreen, SuccessTickGray} from 'mui-core/Icons';
import {useEffect, useState} from 'react';
import {getOnboardSteps} from 'redux/modules/pathways';
import {Services} from 'core/Services';
import {useAuth} from 'core/hooks';
import {API} from 'config';
import {tickGreenCircle} from 'assets/images';
import {useHistory} from 'react-router-dom';
import Welcome from './Welcome';
import {Box, useTheme, Typography, StepButton, Stack} from '@mui/material';
import {gotoDashboardTxt, congratulationsTxt} from 'data/programs.json';

const services = new Services();

const OnBoardSteps = ({
  studentOnboard,
  onboardTypes,
  fetchStudentOnboard,
  programData,
  onCancelOnboard,
  onGoBackOnBoard,
}) => {
  // others
  const onboardSteps = getOnboardSteps({
    studentOnboard: {data: studentOnboard},
    onboardTypes,
  });

  //   hooks
  const theme = useTheme();
  const history = useHistory();
  const [token, user] = useAuth();
  const {steps} = onboardSteps || {};

  //   states
  const [loading, setLoading] = useState(false);
  const [onBoardCompleteModal, setOnboadCompleteModal] = useState(false);
  const [init, setInit] = useState(false);
  const [completed, setCompleted] = useState({
    completed_step_uuid: [],
    completed_task_uuid: [],
  });
  const [onboardStatus, setOnboardStatus] = useState({
    currentStep: -1,
    currentTask: -1,
  });

  const navigateTo = link => history.push(link);

  const setStepStatus = () => {
    const {completed_step_uuid, completed_task_uuid} = studentOnboard;
    let currentStep = -1;
    let currentTask = -1;
    setCompleted({
      completed_step_uuid,
      completed_task_uuid,
    });
    if (completed_step_uuid && completed_step_uuid.length > 0)
      currentStep = completed_step_uuid.length;
    if (completed_task_uuid && completed_task_uuid.length > 0) {
      if (currentStep === -1) currentStep = 0;
      currentTask = steps[completed_step_uuid.length]
        ? steps[completed_step_uuid.length].tasks
            .map(i => i.uuid)
            .indexOf(completed_task_uuid[completed_task_uuid.length - 1])
        : -1;
    }
    setOnboardStatus({
      ...onboardStatus,
      currentTask: currentTask + 1,
      currentStep,
    });
  };

  const isOnboardCompleted = completed_tasks => {
    const {completed_task_uuid: completed_tasks_uuids} = completed;
    const completed_task_uuid = completed_tasks
      ? completed_tasks
      : completed_tasks_uuids;
    if (steps && steps.length === 0) return true;
    let lastTask = null;
    steps &&
      steps.length > 0 &&
      steps.forEach(step => {
        const tasks = step.tasks;
        tasks &&
          tasks.length > 0 &&
          tasks.forEach(task => {
            lastTask = task;
          });
      });
    if (lastTask && completed_task_uuid.indexOf(lastTask.uuid) !== -1)
      return true;
    return false;
  };

  const saveSteps = (completed_steps, completed_tasks) => {
    const {student_onboard_uuid} = studentOnboard;
    const body = {
      completed_step_uuid: completed_steps,
      completed_task_uuid: completed_tasks,
    };
    return services
      .createUpdateRecord(
        token,
        `${API.gps.student_onboard}/${student_onboard_uuid}`,
        body,
        'PATCH',
      )
      .then(res => {
        fetchStudentOnboard(programData);

        setCompleted({
          completed_step_uuid: res.completed_step_uuid,
          completed_task_uuid: res.completed_task_uuid,
        });
        if (isOnboardCompleted(res.completed_task_uuid)) {
          setOnboadCompleteModal(true);
          services.createUpdateRecord(
            token,
            `${API.gps.student_onboard}/${student_onboard_uuid}`,
            {completed_stage: 'onboard'},
            'PATCH',
          );
        }
      });
  };

  const nextTask = async () => {
    setLoading(true);
    const {completed_step_uuid, completed_task_uuid} = completed;
    const noOfTaskInCurrentStep = steps[onboardStatus.currentStep].tasks.length;
    const taskAlreadyAdded =
      completed_task_uuid.indexOf(
        steps[onboardStatus.currentStep].tasks[onboardStatus.currentTask].uuid,
      ) !== -1;
    if (taskAlreadyAdded) {
      setOnboardStatus({
        ...onboardStatus,
        currentTask: onboardStatus.currentTask + 1,
      });
      setLoading(false);
      return;
    }
    if (noOfTaskInCurrentStep === onboardStatus.currentTask + 1) {
      const completed_steps = [
        ...completed_step_uuid,
        steps[onboardStatus.currentStep].step_uuid,
      ];
      const completed_tasks = [
        ...completed_task_uuid,
        steps[onboardStatus.currentStep].tasks[onboardStatus.currentTask].uuid,
      ];
      await saveSteps(completed_steps, completed_tasks).then(res => {
        setOnboardStatus({
          currentStep: parseInt(onboardStatus.currentStep) + 1,
          currentTask: 0,
        });
      });
    } else {
      const completed_steps = [...completed_step_uuid];
      const completed_tasks = [
        ...completed_task_uuid,
        steps[onboardStatus.currentStep].tasks[onboardStatus.currentTask].uuid,
      ];
      await saveSteps(completed_steps, completed_tasks).then(res => {
        setOnboardStatus({
          ...onboardStatus,
          currentTask: onboardStatus.currentTask + 1,
        });
      });
    }
    setLoading(false);
  };

  const isAllTaskCompleted = step => {
    let count = 0;
    if (step && step.tasks) {
      step.tasks.forEach(s => {
        if (completed.completed_task_uuid.indexOf(s.uuid) !== -1) count++;
      });
      return count !== step.tasks.length;
    }
    return false;
  };

  const onChangePanel = i => {
    // if (!i) return;
    if (
      !isAllTaskCompleted(steps[i]) ||
      (!isAllTaskCompleted(steps[i - 1]) && steps[i]) ||
      completed.completed_step_uuid.length === i
    ) {
      let currentTask = 0;
      if (completed.completed_step_uuid.length === i) {
        const index = steps[i].tasks
          .map(i => i.uuid)
          .indexOf(
            completed.completed_task_uuid[
              completed.completed_task_uuid.length - 1
            ],
          );
        currentTask = index + 1;
      }
      setOnboardStatus({...onboardStatus, currentStep: i, currentTask});
    }
  };

  const onChangeTask = i => {
    setOnboardStatus({...onboardStatus, currentTask: i});
  };

  const getTaskStatus = task => {
    if (completed.completed_task_uuid.indexOf(task.uuid) !== -1)
      return 'completed';
    return false;
  };

  const onStartOnBoard = () => {
    setOnboardStatus({...onboardStatus, currentStep: 0});
  };

  //   effect
  useEffect(() => {
    if (studentOnboard && steps) {
      if (!init) {
        setInit(true);
        setStepStatus();
      }
    }
  }, [studentOnboard, steps]); // eslint-disable-line react-hooks/exhaustive-deps

  console.log(
    'onboardSteps',
    steps,
    completed,
    onboardStatus,
    onBoardCompleteModal,
  );
  const modalDescription = [
    `Your have successfully completed onboarding into this program.,
  You can now begin viewing program requirements, job matches and other opportunities that match this progam.`,
  ];

  return (
    <Box sx={{my: 2}}>
      <RequestErrorLoader hideEmpty body={{request: loading}} fullScreen />
      <Welcome
        programData={programData}
        onStart={onStartOnBoard}
        completed={completed}
        onboardStatus={onboardStatus}
        onCancel={onCancelOnboard}
        onGoBackOnBoard={onGoBackOnBoard}
      />
      {onboardStatus.currentStep !== -1 && (
        <div className='steps'>
          {steps &&
            Array.isArray(steps) &&
            steps.map((stepMain, idx) => (
              <Accordion
                isAccordion
                onChange={() => onChangePanel(idx)}
                isExpand={onboardStatus.currentStep === idx}
                controlled
                icon={
                  completed.completed_step_uuid.indexOf(stepMain.step_uuid) !==
                  -1 ? (
                    <SuccessTickGreen sx={{fontSize: 25}} />
                  ) : (
                    <SuccessTickGray
                      color={theme.palette.mode === 'dark' ? 'white' : ''}
                      sx={{
                        fontSize: 25,
                      }}
                    />
                  )
                }
                // onChange={() => onChangePanel(idx)}
                header={stepMain.title}
                key={stepMain.step_uuid}>
                <Stepper
                  nonLinear
                  activeStep={onboardStatus.currentTask}
                  orientation='vertical'>
                  {Array.isArray(stepMain.tasks) &&
                    stepMain.tasks.map((step, index) => (
                      <Step key={step.uuid}>
                        <StepButton
                          onClick={
                            getTaskStatus(step) === 'completed'
                              ? () => onChangeTask(index)
                              : () => {}
                          }
                          sx={
                            getTaskStatus(step) === 'completed'
                              ? {
                                  '& svg': {
                                    color: `${theme.palette.success.main} !important`,
                                  },
                                }
                              : {}
                          }>
                          <Typography variant='h6' color={'text.primary'}>
                            {step.title}
                          </Typography>
                        </StepButton>
                        <StepContent sx={{pt: 2}}>
                          <div
                            style={{fontWeight: 'normal'}}
                            dangerouslySetInnerHTML={{__html: step.description}}
                          />
                          <Box sx={{mb: 1}}>
                            <div>
                              {isAllTaskCompleted(stepMain) && (
                                <Button
                                  variant='contained'
                                  onClick={nextTask}
                                  sx={{mt: 1, mr: 1, minWidth: 90}}>
                                  {index === steps.length - 1 &&
                                  stepMain.length - 1 === idx
                                    ? 'Finish'
                                    : 'Next'}
                                </Button>
                              )}
                              {/* <Button
                              disabled={index === 0}
                              //   onClick={handleBack}
                              sx={{mt: 1, mr: 1}}>
                              Back
                            </Button> */}
                            </div>
                          </Box>
                        </StepContent>
                      </Step>
                    ))}
                </Stepper>
              </Accordion>
            ))}
        </div>
      )}
      <DialogBox
        actions={
          <Button
            variant='contained'
            sx={{minWidth: '168px'}}
            onClick={() => {
              navigateTo('/dashboard');
              setOnboadCompleteModal(false);
            }}>
            {gotoDashboardTxt}
          </Button>
        }
        showIcon={tickGreenCircle}
        openDialog={onBoardCompleteModal}>
        <Stack spacing={1}>
          <Typography textAlign='center' variant='h4' color='text.primary'>
            {congratulationsTxt}
          </Typography>
          {modalDescription?.map(desc => (
            <Typography
              key={desc}
              variant='body1'
              textAlign='center'
              color='text.primary'
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            />
          ))}
        </Stack>
      </DialogBox>
    </Box>
  );
};
export default OnBoardSteps;

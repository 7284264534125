//INITIAL LOAD
export const RESET_INITIAL_LOAD = 'RESET_INITIAL_LOAD';

// CLUSTERS
export const CLUSTERS_REQUEST = 'CLUSTERS_REQUEST';
export const CLUSTERS_SUCCESS = 'CLUSTERS_SUCCESS';
export const CLUSTERS_ERROR = 'CLUSTERS_ERROR';
export const CLUSTERS_CLEAR = 'CLUSTERS_CLEAR';

// PATH OCCUPATION SEARCH
export const PATH_OCCUPATION_SEARCH_REQUEST = 'PATH_OCCUPATION_SEARCH_REQUEST';
export const PATH_OCCUPATION_SEARCH_SUCCESS = 'PATH_OCCUPATION_SEARCH_SUCCESS';
export const PATH_OCCUPATION_SEARCH_ERROR = 'PATH_OCCUPATION_SEARCH_ERROR';

// AWARD TYPES
export const AWARD_TYPES_REQUEST = 'AWARD_TYPES_REQUEST';
export const AWARD_TYPES_SUCCESS = 'AWARD_TYPES_SUCCESS';
export const AWARD_TYPES_ERROR = 'AWARD_TYPES_ERROR';
export const AWARD_TYPES_CLEAR = 'AWARD_TYPES_CLEAR';

// SCHOOLS
export const SCHOOLS_REQUEST = 'SCHOOLS_REQUEST';
export const SCHOOLS_SUCCESS = 'SCHOOLS_SUCCESS';
export const SCHOOLS_ERROR = 'SCHOOLS_ERROR';
export const SCHOOLS_CLEAR = 'SCHOOLS_CLEAR';

// DURATION
export const DURATION_REQUEST = 'DURATION_REQUEST';
export const DURATION_SUCCESS = 'DURATION_SUCCESS';
export const DURATION_ERROR = 'DURATION_ERROR';
export const DURATION_CLEAR = 'DURATION_CLEAR';

// PATHS SEARCH
export const PATHS_SEARCH_REQUEST = 'PATHS_SEARCH_REQUEST';
export const PATHS_SEARCH_SUCCESS = 'PATHS_SEARCH_SUCCESS';
export const NEWEST_PATHS_SEARCH_SUCCESS = 'NEWEST_PATHS_SEARCH_SUCCESS';
export const PATHS_SEARCH_ERROR = 'PATHS_SEARCH_ERROR';
export const PATHS_SEARCH_CLEAR = 'PATHS_SEARCH_CLEAR';

// Career SEARCH
export const CAREER_SEARCH_REQUEST = 'CAREER_SEARCH_REQUEST';
export const CAREER_SEARCH_SUCCESS = 'CAREER_SEARCH_SUCCESS';
export const CAREER_SEARCH_ERROR = 'CAREER_SEARCH_ERROR';
export const CAREER_SEARCH_CLEAR = 'CAREER_SEARCH_CLEAR';

// POPULAR PATHS
export const POPULAR_PATH_REQUEST = 'POPULAR_PATH_REQUEST';
export const POPULAR_PATH_SUCCESS = 'POPULAR_PATH_SUCCESS';
export const POPULAR_PATH_REQUEST_ERROR = 'POPULAR_PATH_REQUEST_ERROR';

// INDEMAND PATHS
export const INDEMAND_PATH_REQUEST = 'INDEMAND_PATH_REQUEST';
export const INDEMAND_PATH_SUCCESS = 'INDEMAND_PATH_SUCCESS';
export const INDEMAND_PATH_REQUEST_ERROR = 'INDEMAND_PATH_REQUEST_ERROR';

// PATH FILTERS OPTIONS
export const PATH_FILTERS_OPTION_REQUEST = 'PATH_FILTERS_OPTION_REQUEST';
export const PATH_FILTERS_OPTION_SUCCESS = 'PATH_FILTERS_OPTION_SUCCESS';

import React from 'react';
import PropTypes from 'prop-types';
import {Box, Stack, Typography, useTheme} from '@mui/material';

const SectionHeader = ({index, text}) => {
  const theme = useTheme();
  return (
    <Stack display='flex' flexDirection='row'>
      <Box
        sx={{
          width: '40px',
          height: '40px',
          borderRadius: '100px',
          color: theme.palette.background.paper,
          background: theme.palette.primary.main,
        }}>
        <Typography
          variant='body1'
          sx={{
            margin: 'auto',
            color: 'white',
            lineHeight: '40px',
            textAlign: 'center',
          }}>
          {index}
        </Typography>
      </Box>
      <Typography variant='h5' sx={{margin: 'auto', ml: 2}}>
        {text}
      </Typography>
    </Stack>
  );
};

SectionHeader.propTypes = {
  index: PropTypes.number,
  text: PropTypes.string,
};
export default SectionHeader;

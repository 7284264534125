import React, {useState} from 'react';
import EducationList from './EducationList';
import {useDispatch, useSelector} from 'react-redux';
import {ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {updateResumeData, getResumeData} from 'redux/modules/resume-builder';

const Education = ({formRef, handleSave}) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const {data: resumeData, request} = useSelector(getResumeData);

  let educationList = [];
  let otherCredentials = [];
  resumeData?.credentials?.forEach(ele => {
    if (ele.credential_type === 'education') {
      educationList.push(ele);
    } else {
      otherCredentials.push(ele);
    }
  });

  const onSubmit = (data, action) => {
    const payload = getPayload(data, action);
    handleSave(payload);
  };

  const getPayload = (data, action) => {
    if (action === 'edit') {
      educationList = educationList?.map(educationObj => {
        if (educationObj.display_order === data.display_order) {
          return data;
        } else {
          return educationObj;
        }
      });
    } else if (action === 'delete') {
      const updatedCredentials = educationList?.filter(
        item => item.display_order !== data.display_order,
      );
      educationList = updatedCredentials?.map((item, index) => {
        return {...item, display_order: index + 1};
      });
    } else {
      educationList?.push({
        ...data,
        credential_type: 'education',
        display_order: educationList.length + 1,
      });
    }

    return {credentials: [...otherCredentials, ...educationList]};
  };

  return (
    <ErrorBoundary
      typeOfUi='subPage'
      nameOfComponent='resume-builder-work-experience'>
      <RequestErrorLoader
        hideEmpty
        body={{data: educationList, request: isLoading || request}}>
        <EducationList
          hideTitle
          hideEmpty
          formRef={formRef}
          data={resumeData}
          onChange={onSubmit}
          getPayload={getPayload}
        />
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default Education;

import * as TYPES from './constants';
import {API} from 'config';
import {
  recieveRelatedCareers,
  requestRelatedCareers,
  requestRelatedCareersError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_RELATED_CAREERS:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_RELATED_CAREERS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_RELATED_CAREERS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchRelatedCareers = payload => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();

  const {page, search, cip_code, page_size, career_name} = payload || {};

  try {
    dispatch(requestRelatedCareers());
    const endPoint = `${API.gps.student_cis_data}`;
    return services
      .createUpdateRecord(
        token,
        endPoint,
        {
          page,
          search,
          cip_code,
          page_size,
          career_name,
          go_ai: 'get_related_careers',
        },
        'POST',
      )
      .then(res => {
        dispatch(recieveRelatedCareers(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestRelatedCareersError(err));
  }
};

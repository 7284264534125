import * as TYPES from './constants';
import {API} from 'config';

import {
  requestJobZoneDetails,
  recieveJobZoneDetails,
  requestJobZoneDetailsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.JOBZONE_DETAILS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.JOBZONE_DETAILS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.JOBZONE_DETAILS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchJobZoneDetails = () => (dispatch, getState) => {
  dispatch(requestJobZoneDetails());
  try {
    let endPoint = `${API.srm.job_zone_details}`;
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveJobZoneDetails(res));
        return;
      }
      dispatch(requestJobZoneDetailsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestJobZoneDetailsError(err));
  }
};

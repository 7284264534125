import * as TYPES from './constants';

// RESUME
export const requestResume = () => ({
  type: TYPES.RESUME_LIST_REQUEST,
});
export const recieveResume = data => ({
  type: TYPES.RESUME_LIST_RECIEVE,
  payload: data,
});
export const resumeError = err => ({
  type: TYPES.RESUME_LIST_ERROR,
  err,
});
export const clearResume = () => ({
  type: TYPES.RESUME_LIST__CLEAR,
});
// UPDATE RESUME
export const requestUpdatedResume = () => ({
  type: TYPES.UPDATE_RESUME_LIST_REQUEST,
});
export const recieveUpdatedResume = data => ({
  type: TYPES.UPDATE_RESUME_LIST_RECIEVE,
  payload: data,
});
export const resumeUpdatedError = err => ({
  type: TYPES.UPDATE_RESUME_LIST_ERROR,
  err,
});
export const clearUpdatedResume = () => ({
  type: TYPES.UPDATE_RESUME_LIST__CLEAR,
});

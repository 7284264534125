import * as TYPES from './constants';
import {API} from 'config';
import {recievePosList, requestPosList, requestPosListError} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, error: false, data: null};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_POS_LIST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_POS_LIST:
      let data = action.payload;
      if (state.data && state.data.program_of_study) {
        data.program_of_study = {
          ...state.data.program_of_study,
          ...data.program_of_study,
        };
      }
      return {
        ...state,
        request: false,
        error: false,
        data,
      };
    case TYPES.REQUEST_POS_LIST_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.CLEAR_POS_LIST:
      return initialState;
    default:
      return state;
  }
};

export const fetchPosList = (pageNo = 1) => (dispatch, getState) => {
  const pageSize = 4;
  const endPoint = `${API.srm.program_of_study_list}?page_size=${pageSize}&page_num=${pageNo}`;
  dispatch(requestPosList());
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recievePosList(res));
        return;
      }
      dispatch(requestPosListError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestPosListError(err));
  }
};

import React, {useState} from 'react';
import {DialogBox} from 'mui-core';
import {Box, Stack, Typography, useTheme} from '@mui/material';
import UserType from 'app/modules/newDashboard/subModules/Settings/UserType';

import SettingsJSON from 'data/dashboard-settings.json';
import {spotlightBgImg} from 'assets/images';

const {
  userTypeModal: {heading, description, buttonTxt},
} = SettingsJSON;

const UserTypeSelect = () => {
  const theme = useTheme();
  const [close, setClose] = useState(false);

  const onCloseHandler = value => {
    setClose(value);
  };

  return (
    <DialogBox
      size='md'
      customizeable
      openDialog={!close}
      customDialogsx={{
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
      }}>
      <Box sx={{position: 'relative', height: '100%'}}>
        <Box
          sx={{
            height: '100%',
            position: 'relative',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${spotlightBgImg})`,
          }}>
          <Box
            sx={{
              top: 0,
              left: 0,
              zIndex: 1,
              width: '100%',
              opacity: 0.62,
              height: '100%',
              position: 'absolute',
              backgroundColor: theme.palette.Gray900.main,
            }}
          />
          <Stack
            sx={{
              zIndex: 2,
              height: '100%',
              padding: '64px',
              alignItems: 'center',
              position: 'relative',
              justifyContent: 'center',
            }}
            spacing={1}
            direction='column'>
            <Typography
              component='h1'
              variant='header-md-medium'
              sx={{textAlign: 'center', color: 'white'}}>
              {heading}
            </Typography>
            <Typography
              component='p'
              variant='title-xs-thin'
              sx={{textAlign: 'center', color: 'white'}}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            <Stack sx={{alignItems: 'center', justifyContent: 'center'}}>
              <UserType
                dashboard={false}
                showLabel={false}
                btnTxt={buttonTxt}
                showHeading={false}
                dropdownSize='small'
                btnAlignment='center'
                btnVariant='contained'
                userTypeContentSx={{
                  backgroundColor: 'transparent',
                  '.MuiOutlinedInput-input': {
                    background: 'white',
                  },
                }}
                onCloseHandler={onCloseHandler}
              />
            </Stack>
          </Stack>
        </Box>
      </Box>
    </DialogBox>
  );
};

export default UserTypeSelect;

import {useCallback} from 'react';
import isEmpty from 'lodash/isEmpty';

import {ErrorBoundary} from 'core/components';

import {noSurveyIcon} from 'assets/images';

import CardsList from './CardsList';

import './style.less';

const getClusters = (surveyAnswers, clusters = []) => {
  const {assessment_result = {}} = surveyAnswers || {};
  const priorities = [...new Set(Object.values(assessment_result))].sort(
    (a, b) => b - a,
  );
  const clusterLength = clusters.length;
  const clusterList = clusters?.map((cluster = {}) => {
    let rank = 0;
    if (assessment_result[cluster.cluster_uuid]) {
      rank = priorities.indexOf(assessment_result[cluster.cluster_uuid]) + 1;
    } else {
      rank = clusterLength;
    }
    return {
      title: cluster.cluster_name,
      uuid: cluster.cluster_uuid,
      banner_cloudinary: cluster.cluster_card_url,
      rank,
      count: assessment_result[cluster.cluster_uuid] || 0,
    };
  });
  return clusterList?.sort((a, b) => a.rank - b.rank);
};

export default function SurveyResults({
  surveyAnswers,
  clusters,
  showHeaderButton = false,
  showButton = false,
}) {
  const clusterData = useCallback(getClusters(surveyAnswers, clusters), [
    surveyAnswers,
    clusters,
  ]);

  return (
    <ErrorBoundary nameOfComponent='core-career-interest-survey-results'>
      <div className='career-results-container'>
        <div className='results-header'>
          <h1>Results</h1>
        </div>
        {isEmpty(surveyAnswers?.assessment_result) ? (
          <>
            <div className='no-results-container'>
              <img src={noSurveyIcon} alt='No survey icon' />
              <h2>No Results Found.</h2>
            </div>
          </>
        ) : (
          <CardsList
            showButton={showButton}
            clusterData={clusterData}
            showHeaderButton={showHeaderButton}
          />
        )}
      </div>
    </ErrorBoundary>
  );
}

import PropTypes from 'prop-types';
import {React, useEffect, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {
  Box,
  Stack,
  Badge,
  styled,
  useTheme,
  Container,
  IconButton,
  badgeClasses,
  useMediaQuery,
  InputAdornment,
} from '@mui/material';
import {createField} from 'mui-core/Form';
import {MUIInput, Button} from 'mui-core';
import {Search, TuneTwoTone, Close} from '@mui/icons-material';

const StyledButton = styled(Button)(({theme}) => ({
  width: 'auto',
  height: '48px',
  color: 'white',
  minWidth: '150px',
  background: theme.palette.shadeyBlack.main,
  '&:hover': {
    background: 'black',
  },
  [theme.breakpoints.only('xs')]: {
    width: '100%',
    height: '42px',
  },
}));

const ContainerMain = styled(Stack)(({theme}) => ({
  ['&.stick']: {
    ['& .fragment']: {
      height: 85,
    },
    ['& .search-icon']: {
      display: 'flex !important',
      ['& i']: {
        fontSize: '8px',
      },
    },
    ['& .search-submit']: {
      display: 'none',
    },
    ['& .j-container']: {
      position: 'fixed',
      backgroundColor: 'transparent',
      width: '100%',
      zIndex: 222,
      ['& .search-field']: {
        display: 'none',
      },
      [' .stack']: {
        paddingTop: '12px',
        paddingBottom: '4px',
        position: 'relative',
        justifyContent: 'end',
        marginLeft: '90px',
        [theme.breakpoints.down('sm')]: {
          [`& .${badgeClasses.root}`]: {
            width: 'auto',
          },
        },
        ['& button']: {
          marginLeft: '8px',
          height: '40px !important',
          minHeight: '40px !important',
          minWidth: '40px !important',
          position: 'relative',
        },
        [' .stack-fields']: {
          flexDirection: 'row !important',
          [theme.breakpoints.up('sm')]: {
            marginTop: '-42px',
          },
        },
      },
    },
  },
}));

let scroller;

const SearchFilter = ({
  name,
  onReset,
  filters,
  onSearch,
  onDrawerOpen,
  showSearch = true,
  showFilterBtn = true,
  searchBtnText = 'Search',
  filterBtnText = 'Show Filter',
  searchPlaceholder = 'Search by title or other keywords',
}) => {
  const form = useForm();
  const theme = useTheme();
  const inputRef = useRef();
  const {handleSubmit, reset, getValues, setValue, watch} = form;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const search = watch();

  const onSubmit = data => {
    onSearch(data);
  };

  const onResetClick = () => {
    if (name) {
      const resetObj = {
        [name]: '',
      };
      onReset(resetObj);
      reset(resetObj);
    }
  };

  const scrollerEvent = () => {
    scroller = window;
    scroller.addEventListener('scroll', onScroll, false);
  };

  const onScroll = () => {
    const el = document?.getElementById('job-filter');
    const header = document?.querySelector('header');
    const headerRect = header?.getBoundingClientRect();
    const elementRect = el?.getBoundingClientRect();
    if (el && header) {
      if (elementRect.top <= headerRect.bottom) {
        el.classList?.add('stick');
      } else {
        el.classList?.remove('stick');
      }
    }
  };

  const scrollToSearch = () => {
    window.scrollTo({top: 500, behavior: 'smooth'});
    console.log('inputRef', inputRef);
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    scrollerEvent();
    return () => {
      scroller.removeEventListener('scroll', onScroll, false);
    };
  }, []);

  useEffect(() => {
    setValue(name, filters[name]);
  }, [filters]);

  return (
    <>
      {(showSearch || showFilterBtn) && (
        <ContainerMain id='job-filter'>
          <Box className='fragment' />
          <Box className='j-container' top={{xs: 40, sm: 98}}>
            <Container>
              <Stack
                spacing={2}
                className='stack'
                alignItems='center'
                direction={{xs: 'column', sm: 'row'}}
                transition='background-color 0.3s ease'
                justifyContent={showSearch ? 'center' : 'flex-end'}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{width: isMobile ? '100%' : 'auto'}}>
                  <Stack
                    spacing={1}
                    alignItems='center'
                    justifyContent='center'
                    className='stack-fields'
                    direction={{xs: 'column', sm: 'row'}}>
                    {showSearch && (
                      <>
                        {createField(name, {
                          form,
                          defaultValue: '',
                        })(
                          <MUIInput
                            name={name}
                            ref={inputRef}
                            className='search-field'
                            placeholder={searchPlaceholder}
                            startAdornment={
                              <InputAdornment position='start'>
                                <Search />
                              </InputAdornment>
                            }
                            endAdornment={
                              getValues(name) ? (
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='close'
                                    onClick={onResetClick}>
                                    <Close />
                                  </IconButton>
                                </InputAdornment>
                              ) : null
                            }
                            sx={{
                              minWidth: {
                                xs: '100%',
                                sm: '300px',
                                md: '450px',
                              },
                            }}
                          />,
                        )}
                        <Button
                          rounded
                          variant='contained'
                          className='search-icon'
                          onClick={scrollToSearch}
                          sx={{
                            width: '40px',
                            height: '40px',
                            padding: '0px',
                            display: 'none',
                            minWidth: '40px',
                            minHeight: '40px',
                          }}>
                          <Search />
                        </Button>
                        <Button
                          type='submit'
                          variant='contained'
                          className='search-submit'
                          disabled={search[name] ? false : true}
                          sx={{
                            padding: '8px 22px',
                            width: {xs: '100%', sm: 'auto'},
                            height: {xs: '42px', sm: '48px'},
                          }}>
                          {searchBtnText}
                        </Button>
                      </>
                    )}
                    {showFilterBtn && (
                      <Badge
                        variant='dot'
                        color='primary'
                        invisible={
                          filters &&
                          Object.values(filters).filter(Boolean).length === 0
                        }
                        sx={{width: {xs: '100%', sm: 'auto'}}}>
                        <StyledButton
                          variant='contained'
                          onClick={onDrawerOpen}
                          startIcon={<TuneTwoTone style={{color: 'white'}} />}>
                          {filterBtnText}
                        </StyledButton>
                      </Badge>
                    )}
                  </Stack>
                </form>
              </Stack>
            </Container>
          </Box>
        </ContainerMain>
      )}
    </>
  );
};

SearchFilter.propTypes = {
  showFilterBtn: PropTypes.bool,
  searchBtnText: PropTypes.string,
  filterBtnText: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
};

export default SearchFilter;

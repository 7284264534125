import React, {useEffect} from 'react';
import {useInstance} from 'core/hooks';
import {styled} from '@mui/material/styles';
import {
  getComparedPrograms,
  clearComparePrograms,
  fetchComparePrograms,
} from 'redux/modules/programs';
import {useSelector} from 'react-redux';
import {numberInUSFormat} from 'core/utils';
import ComparePrograms from './ComparePrograms';
import {Box, Grid, Stack, Divider, Typography} from '@mui/material';
import {
  Button,
  EmptyBox,
  ContentBox,
  SkillsStack,
  skillsLearned,
  RequestErrorLoader,
} from 'mui-core';
import {licenseTypeKeys} from 'config';
import {externalLinkHandler} from 'mui-core/utils';
import {Launch, CheckCircle} from '@mui/icons-material';

const StyledTypography = styled(Typography)(({theme}) => ({
  color: 'text.primary',
  //textTransform: 'capitalize',
  '& iframe': {
    border: 'none',
    maxWidth: '100%',
  },
  '& img': {
    height: '100%',
    maxWidth: '100%',
  },
  '& a': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.secondary.dark
        : theme.palette.secondary.light,
  },
  '& p': {
    textTransform: 'None',
  },
  '& pre': {
    display: 'block',
    background: '#edecee',
    color: `${theme.palette.darkGray.main} !important`,
  },
  '& .d-flex.ws-nowrap': {
    display: 'block !important',
  },
  '& strong': {
    fontWeight: 'bold',
  },
  '& h1, h2, h3, h4': {
    color:
      theme.palette.mode === 'dark' ? 'white' : theme.palette.darkGray.main,
  },
  '& ul, ol': {
    paddingLeft: '5px',
  },
  '& ul > li': {
    lineHeight: '1.2',
    // paddingLeft: '6px',
    paddingBottom: '8px',
    background: 'transparent',
    color:
      theme.palette.mode === 'dark' ? 'white' : theme.palette.darkGray.main,
    marginLeft: '30px',
    textTransform: 'initial',
    // listStyleType: 'none',
    // '&::before': {
    //   content: '"\\2022"',
    //   position: 'absolute',
    //   marginTop: '-15px',
    //   left: '30px',
    //   color: '#3ac4ff',
    //   fontSize: '30px',
    // },
    '& .priceColor': {
      color: '#de4279',
    },
  },
  '& ol > li': {
    color: `${theme.palette.darkGray.main} !important`,
    listStyleType: 'decimal !important',
  },
  '& pre span': {
    color: `${theme.palette.darkGray.main} !important`,
  },
  '& table span': {
    color: `${theme.palette.darkGray.main} !important`,
  },
}));

const RelatedCareersList = styled(Box)(({theme}) => ({
  textTransform: 'capitalize',
  '& ul > li': {
    marginLeft: '-16px',
    color:
      theme.palette.mode === 'dark' ? 'white' : theme.palette.darkGray.main,
  },
}));

const ProgramDetailsValueContainer = styled(Stack)(({theme}) => ({
  height: 40,
  minWidth: 120,
  borderRadius: 50,
  color:
    theme.palette.mode === 'light'
      ? theme.palette.primary.main
      : theme.palette.primary.light,
  border: `1px solid ${
    theme.palette.mode === 'light'
      ? theme.palette.primary.main
      : theme.palette.primary.light
  }`,
}));

const Overview = props => {
  const {isCriminalJustice} = useInstance();
  const comparePrograms = useSelector(getComparedPrograms);
  const {request: compareProgramsRequest} = comparePrograms || {};
  const {
    programData,
    overviewData,
    isProgramsLoading,
    onStartPathwayClick,
    isCurrentPathActive,
    studentOnboard,
    onAddToProfile,
    onSavePathClick,
    savedPath,
  } = props;
  const {
    programDetailSection: {
      programType,
      buttonLabels,
      avgAnnualCost,
      programLengthLabel,
      programDetailsLabel,
      unitsAndCreditsLabel,
    },
  } = overviewData;
  const {
    location,
    uuid = '',
    overviews = [],
    program_url_link,
    overview_description,
    total_units_required,
    related_careers = [],
    credential_currency_details: {ave_cost = null} = {},
    duration: {
      duration_days = null,
      duration_hours = null,
      duration_weeks = null,
      duration_years = null,
      duration_months = null,
    } = {},
    program_details: {program_name: programOfStudy = ''} = {},
  } = programData || {};

  let durationValue = null;
  let unitLabel = null;

  if (duration_years) {
    unitLabel = `Year${duration_years > 1 ? 's' : ''}`;
    durationValue = duration_years;
  } else if (duration_months) {
    unitLabel = `Month${duration_months > 1 ? 's' : ''}`;
    durationValue = duration_months;
  } else if (duration_weeks) {
    unitLabel = `Week${duration_weeks > 1 ? 's' : ''}`;
    durationValue = duration_weeks;
  } else if (duration_hours) {
    unitLabel = `Hour${duration_hours > 1 ? 's' : ''}`;
    durationValue = duration_hours;
  } else if (duration_days) {
    unitLabel = `Day${duration_days > 1 ? 's' : ''}`;
    durationValue = duration_days;
  } else {
    return durationValue;
  }

  let averageAnnualCost = avgAnnualCost?.valueNotAvailable;
  if (ave_cost == 0) {
    averageAnnualCost = avgAnnualCost?.valueNoCost;
  } else if (ave_cost && parseFloat(ave_cost) > 0) {
    averageAnnualCost = `$${numberInUSFormat(parseFloat(ave_cost))}`;
  }

  const isCriminalJusticeProgram =
    programData?.institute_details?.super_type_name ===
    licenseTypeKeys?.criminalJustice;
  const {data: saved_path, request: savedPathRequest} = savedPath || {};
  const isPathSaved =
    programData &&
    saved_path &&
    Array.isArray(saved_path) &&
    saved_path.map(i => i.path_uuid).indexOf(uuid) !== -1;

  let SkillsLabel = 'Skills you will gain';
  const SkillSet = skillsLearned(programData, true) || [];
  if (SkillSet?.length) SkillsLabel += ` (${SkillSet.length})`;

  const OverviewContent = () => {
    if (overviews?.length) {
      return overviews.map((overview, idx) => {
        return (
          <Box key={idx} pb={2}>
            <StyledTypography mt={3} mb={2} variant='h5' color={'text.primary'}>
              {overview.title}
            </StyledTypography>
            <Box>
              {overview.value.map((value, index) => {
                return (
                  <StyledTypography
                    dangerouslySetInnerHTML={{__html: value}}
                    variant='body1'
                    key={index}
                  />
                );
              })}
            </Box>
          </Box>
        );
      });
    } else {
      return '';
    }
  };

  return (
    <RequestErrorLoader
      body={{
        request: isProgramsLoading || compareProgramsRequest,
        data: programData,
      }}
      overview_contents={<EmptyBox />}>
      {programData && (
        <Grid container pb={2}>
          <Grid item xs={12} sm={8}>
            <ContentBox variant='outlined' sx={{mb: 2, p: 3}}>
              <StyledTypography mb={2} variant='h5' color={'text.primary'}>
                {overviewData.headersText.aboutProgram}
              </StyledTypography>
              <Divider />
              <StyledTypography
                my={2}
                variant='body1'
                dangerouslySetInnerHTML={{
                  __html: overview_description || '-',
                }}
              />
              {related_careers &&
                Array.isArray(related_careers) &&
                related_careers.length > 0 && (
                  <RelatedCareersList>
                    <Typography variant='h5' color='text.primary' mb={2}>
                      Potential Career Opportunities
                    </Typography>
                    <ul>
                      {related_careers.map((res, index) => (
                        <Typography
                          component='li'
                          variant='body1'
                          key={`related-careers-style-${index}-${index}`}>
                          {res}
                        </Typography>
                      ))}
                    </ul>
                  </RelatedCareersList>
                )}
              {SkillSet && SkillSet.length > 0 && (
                <Box mb={2} mt={3}>
                  <StyledTypography variant='h5' color='text.primary' mb={2}>
                    {SkillsLabel}
                  </StyledTypography>
                  <SkillsStack
                    displayLimit={30}
                    skillSet={SkillSet}
                    displayShowAll={true}
                    emptyMsg='No skills found for this program'
                  />
                </Box>
              )}
              {OverviewContent()}
            </ContentBox>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{paddingLeft: {sm: 1, md: 3}, paddingTop: {xs: 2, sm: 0}}}>
            <ContentBox variant='outlined' sx={{mb: 2, p: 3}}>
              <Stack spacing={2}>
                {isCriminalJusticeProgram && !isCurrentPathActive ? (
                  <Button
                    color={'primary'}
                    sx={{height: 50}}
                    variant='contained'
                    endIcon={<Launch sx={{marginBottom: '4px'}} />}
                    onClick={() => externalLinkHandler(program_url_link)}>
                    {buttonLabels?.exploreProgramDetails}
                  </Button>
                ) : (
                  <Button
                    sx={{height: 50}}
                    variant='contained'
                    onClick={onStartPathwayClick}
                    loading={studentOnboard?.request}
                    color={isCurrentPathActive ? 'success' : 'primary'}
                    startIcon={isCurrentPathActive ? <CheckCircle /> : null}>
                    {isCurrentPathActive
                      ? buttonLabels?.enrolled
                      : buttonLabels?.enrollInProgram}
                  </Button>
                )}
                {!isCurrentPathActive && (
                  <Button
                    onClick={onAddToProfile}
                    sx={{height: 50}}
                    variant='outlined'>
                    Already Enrolled? Add to Profile
                  </Button>
                )}

                <Button
                  sx={{height: 50}}
                  startIcon={isPathSaved ? <CheckCircle /> : null}
                  color={isPathSaved ? 'success' : 'primary'}
                  variant={isPathSaved ? 'contained' : 'outlined'}
                  loading={savedPathRequest}
                  onClick={onSavePathClick}>
                  {isPathSaved ? 'Saved' : 'Save'}
                </Button>
              </Stack>
            </ContentBox>

            {((total_units_required && parseFloat(total_units_required) > 0) ||
              (!programOfStudy &&
                (durationValue ||
                  averageAnnualCost !== avgAnnualCost?.valueNotAvailable)) ||
              location) && (
              <ContentBox variant='outlined' sx={{mb: 2, p: 3}}>
                <Stack spacing={2}>
                  <Typography
                    variant='h5'
                    textAlign='center'
                    color='text.primary'>
                    {programDetailsLabel}
                  </Typography>
                  <Divider my={2} variant='middle' />
                  <Grid
                    container
                    rowGap={2}
                    alignContent='center'
                    justifyContent='center'>
                    {total_units_required &&
                      parseFloat(total_units_required) > 0 && (
                        <Grid item xs={12} sm={6}>
                          <Stack
                            spacing={1}
                            alignItems='center'
                            justifyContent='center'>
                            <Typography textAlign='center' variant='body2'>
                              {unitsAndCreditsLabel}
                            </Typography>
                            <ProgramDetailsValueContainer
                              alignItems='center'
                              color='primary.main'
                              justifyContent='center'>
                              <Typography textAlign='center' variant='body1'>
                                {total_units_required}
                              </Typography>
                            </ProgramDetailsValueContainer>
                          </Stack>
                        </Grid>
                      )}
                    {!programOfStudy && (
                      <Grid item xs={12} sm={6}>
                        <Stack
                          spacing={1}
                          alignItems='center'
                          justifyContent='center'>
                          <Typography textAlign='center' variant='body2'>
                            {programLengthLabel}
                          </Typography>
                          <ProgramDetailsValueContainer
                            alignItems='center'
                            color='primary.main'
                            justifyContent='center'>
                            <Typography textAlign='center' variant='body1'>
                              {`${durationValue} ${unitLabel}`}
                            </Typography>
                          </ProgramDetailsValueContainer>
                        </Stack>
                      </Grid>
                    )}
                    {isCriminalJusticeProgram && location && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Stack
                            spacing={1}
                            alignItems='center'
                            justifyContent='center'>
                            <Typography textAlign='center' variant='body2'>
                              {programType}
                            </Typography>
                            <ProgramDetailsValueContainer
                              alignItems='center'
                              color='primary.main'
                              justifyContent='center'>
                              <Typography textAlign='center' variant='body1'>
                                {location}
                              </Typography>
                            </ProgramDetailsValueContainer>
                          </Stack>
                        </Grid>
                      </>
                    )}
                    {!programOfStudy &&
                      averageAnnualCost !==
                        avgAnnualCost?.valueNotAvailable && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Stack
                              spacing={1}
                              alignItems='center'
                              justifyContent='center'>
                              <Typography textAlign='center' variant='body2'>
                                {avgAnnualCost?.label}
                              </Typography>
                              <ProgramDetailsValueContainer
                                alignItems='center'
                                color='primary.main'
                                justifyContent='center'>
                                <Typography textAlign='center' variant='body1'>
                                  {averageAnnualCost}
                                </Typography>
                              </ProgramDetailsValueContainer>
                            </Stack>
                          </Grid>
                        </>
                      )}
                  </Grid>
                </Stack>
              </ContentBox>
            )}
          </Grid>
          {!isCriminalJustice && (
            <ComparePrograms
              programData={programData}
              programList={comparePrograms}
            />
          )}
        </Grid>
      )}
    </RequestErrorLoader>
  );
};

export default Overview;

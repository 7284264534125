import {Box, Button} from '@mui/material';
import {completeResumeIcon} from 'assets/images';
import {styled, useTheme} from '@mui/material/styles';
import resumeBuilderData from 'data/resumeBuilder.json';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {StepperFormWrapper} from 'app/modules/resume-builder/sharedComponents';

const {backButtonText, nextButtonText, publishButtonText} =
  resumeBuilderData || {};

const PublishButton = styled(Button)(({theme}) => ({
  color: 'white',
  padding: '6px 32px',
  borderRadius: '32px',
  background: theme.palette.primary.dark,
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  ':hover': {
    background: theme.palette.primary.main,
  },
}));

const NextButton = styled(Button)(({theme}) => ({
  width: 160,
  color: 'white',
  padding: '6px 32px',
  borderRadius: '30px',
  background: 'rgba(0, 0, 0, 0.87)',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  ':hover': {
    background: theme.palette.shadeyBlack.main,
  },
}));

export default ({
  mode,
  steps,
  formRef,
  resumeData,
  activeStep,
  handleSave,
  handleNext,
  handleBack,
  resume_uuid,
  handlePublish,
  handleAddToResumeToggle,
}) => {
  const Component = steps[activeStep].component;
  const theme = useTheme();
  return (
    <Box sx={{p: 3}} id={'stepper'}>
      <Box
        sx={{
          width: 1,
          gap: '12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {activeStep > 0 ? (
          <Button
            rounded
            variant='outlined'
            onClick={handleBack}
            sx={{
              width: 120,
              padding: '6px 24px',
              borderRadius: '36px',
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.primary.dark
                  : 'white',
            }}
            startIcon={<ChevronLeftIcon />}>
            {backButtonText}
          </Button>
        ) : null}

        {activeStep === steps.length - 1 ? (
          <PublishButton
            rounded
            onClick={handlePublish}
            endIcon={<img src={completeResumeIcon} alt='publish' />}>
            {publishButtonText}
          </PublishButton>
        ) : (
          <NextButton
            rounded
            onClick={handleNext}
            endIcon={<ArrowForwardIcon />}>
            {nextButtonText}
          </NextButton>
        )}
      </Box>

      <Box sx={{paddingTop: '24px'}}>
        <StepperFormWrapper
          resumeData={resumeData}
          activeStep={activeStep}
          formData={steps[activeStep]}
          handleAddToResumeToggle={handleAddToResumeToggle}>
          {steps[activeStep].component ? (
            <Component
              mode={mode}
              formRef={formRef}
              handleSave={handleSave}
              resume_uuid={resume_uuid}
            />
          ) : (
            steps[activeStep].key
          )}
        </StepperFormWrapper>
      </Box>
    </Box>
  );
};

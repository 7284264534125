import {Link} from 'react-router-dom';
import {noSearchResults} from 'assets/images';
import style from './style.module.less';

const NoContentNavigator = ({
  heading,
  message,
  subHeading,
  pathTo,
  label,
  customClass,
  imgSrc,
  showImg,
}) => {
  const classname = customClass
    ? `${style.no_path_found} ${customClass}`
    : `${style.no_path_found}`;
  return (
    <div className={classname}>
      {showImg && <img src={imgSrc || noSearchResults} alt={subHeading} />}
      {heading && <h1>{heading}</h1>}
      <h3>
        {message}
        {subHeading && <span>{subHeading}</span>}
      </h3>
      {pathTo && label && (
        <Link to={pathTo} className={style.btn_blue_outer}>
          {label}
        </Link>
      )}
    </div>
  );
};

export default NoContentNavigator;

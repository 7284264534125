import * as TYPES from './constants';
import {API} from 'config';

import {
  requestSeekingData,
  recieveSeekingData,
  seekingDataError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {
  request: false,
  data: null,
  error: false,
  updating: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SEEKING_DATA_LOAD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.SEEKING_DATA_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.SEEKING_DATA_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchSeekingData = () => dispatch => {
  try {
    dispatch(requestSeekingData());
    return services.fetchRecords(API.gps.student_seeking).then(res => {
      dispatch(recieveSeekingData(res));
      return res;
    });
  } catch (err) {
    console.log(err);
    dispatch(seekingDataError(err));
  }
};

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestActivePaths,
  recieveActivePaths,
  requestActivePathsError,
  requestActivePathData,
  recieveActivePathData,
  requestActivePathDataError,
  requestDeleteActivePath,
  recieveDeleteActivePath,
  requestDeleteActivePathError,
  clearMyPlan,
} from './actions';

import {Services} from 'core/Services';

const services = new Services();

const initialState = {request: false, data: null, pathInfo: {}, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.DELETE_ACTIVE_PATH_REQUEST:
    case TYPES.ACTIVE_PATH_DATA_REQUEST:
    case TYPES.ACTIVE_PATHS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.DELETE_ACTIVE_PATH_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
      };
    case TYPES.ACTIVE_PATHS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.ACTIVE_PATH_DATA_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        pathInfo: action.payload,
      };
    case TYPES.DELETE_ACTIVE_PATH_ERROR:
    case TYPES.ACTIVE_PATH_DATA_ERROR:
    case TYPES.ACTIVE_PATHS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchActivePaths = () => (dispatch, getState) => {
  try {
    const endPoint = `${API.gps.student_onboard}`;
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      dispatch(requestActivePaths());
      return services.fetchRecords(endPoint, token).then(res => {
        if (res && Array.isArray(res)) {
          dispatch(recieveActivePaths(res));
          return res;
        }
        dispatch(requestActivePathsError(res));
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestActivePathsError(err));
  }
};

export const fetchActivePathData = () => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
      pathways: {
        activePaths: {data},
      },
    } = getState();
    let endPoint = `${API.gps.student_onboard}`;
    if (token) {
      let activePath = data;
      if (
        !activePath ||
        (activePath && Array.isArray(activePath) && activePath.length === 0)
      ) {
        dispatch(requestActivePathData());
        activePath = await services.fetchRecords(endPoint, token);
        dispatch(recieveActivePaths(activePath));
      }
      if (activePath && activePath.length) {
        dispatch(requestActivePathData());
        endPoint = `${API.srm.pathway_details}${activePath[0].path_uuid}`;
        const result = await services.fetchRecords(endPoint, token);
        dispatch(recieveActivePathData(result));
        return;
      }
    }
  } catch (err) {
    console.log(err);
    dispatch(requestActivePathDataError(err));
  }
};

export const deleteActivePath = (onBoard_uuid, callback) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  try {
    dispatch(requestDeleteActivePath());
    const endPoint = `${API.gps.student_onboard}/${onBoard_uuid}`;
    return services
      .deleteRecord(token, endPoint)
      .then(res => {
        if (res) {
          dispatch(recieveDeleteActivePath(res));

          if (callback) {
            if (res.Success) {
              dispatch(recieveActivePathData({}));
              dispatch(fetchActivePaths());
              dispatch(clearMyPlan());
              callback(false);
            } else {
              callback(
                res.error ? res.error : 'Error in deleting the active path',
              );
            }
          }
          return;
        }
        dispatch(requestDeleteActivePathError(res));
      })
      .catch(err => {
        dispatch(requestDeleteActivePathError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestDeleteActivePathError(err));
  }
};

import React from 'react';
import {Text, View, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  divider: {
    flexDirection: 'row',
    backgroundColor: '#dfdfdf',
    height: '2px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10,
    // flexGrow: 1,
  },
  dividerTxt: {
    color: '#8050F4',
    fontSize: 12,
  },
});

export default ({title}) => (
  <View style={styles.divider} wrap={false}>
    <Text style={styles.dividerTxt}>{title}</Text>
  </View>
);

import {combineReducers} from 'redux';
import CareerRank from './careerRank';
import SavedCareers from './savedCareers';
import RelatedCareers from './relatedCareers';
import OccupationByCluster from './occupationByCluster';

export const careerDestinationReducer = combineReducers({
  CareerRank,
  SavedCareers,
  RelatedCareers,
  OccupationByCluster,
});

import * as TYPES from './constants';
import {API} from 'config';

import {
  recieveGetSubscribeOccupation,
  requestGetSubscribeOccupation,
  requestGetSubscribeOccupationError,
  recieveSubscribeOccupation,
  requestSubscribeOccupation,
  requestSubscribeOccupationError,
  resetSubscribedOccupationData,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, subscribeRequest: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OCCUPATION_SUBSCRIBE_GET_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.OCCUPATION_SUBSCRIBE_GET_SUCCESS:
      return {
        ...state,
        request: false,
        data: action.payload,
        error: false,
      };
    case TYPES.OCCUPATION_SUBSCRIBE_REQUEST:
      return {
        ...state,
        subscribeRequest: true,
      };
    case TYPES.OCCUPATION_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        subscribeRequest: false,
      };
    case TYPES.OCCUPATION_SUBSCRIBE_RESET:
      return {
        ...state,
        data: null,
      };
    case TYPES.OCCUPATION_SUBSCRIBE_GET_ERROR:
    case TYPES.OCCUPATION_SUBSCRIBE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
        subscribeRequest: false,
      };
    default:
      return state;
  }
};

export const fetchSubscribedOccupation = () => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestGetSubscribeOccupation());
  try {
    let endPoint = `${API.srm.occupation_subscribe}`;
    return services.fetchRecords(endPoint, token, true).then(res => {
      if (res) {
        dispatch(recieveGetSubscribeOccupation(res));
        return;
      }
      dispatch(requestGetSubscribeOccupationError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestGetSubscribeOccupationError(err));
  }
};

export const subscribeForOccupation = (data, callback) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestSubscribeOccupation());
  try {
    let endPoint = `${API.srm.occupation_subscribe}`;
    return services
      .createUpdateRecord(token, endPoint, data, 'POST')
      .then(res => {
        if (res) {
          dispatch(recieveSubscribeOccupation(res));
          if (callback) callback(res);
          return;
        }
        dispatch(recieveSubscribeOccupation(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestSubscribeOccupationError(err));
  }
};

export const unSubscribeForOccupation = (id, callback) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestSubscribeOccupation());
  try {
    let endPoint = `${API.srm.occupation_subscribe}${id}`;
    return services
      .createUpdateRecord(token, endPoint, {}, 'DELETE')
      .then(res => {
        if (res) {
          dispatch(recieveSubscribeOccupation(res));
          if (callback) callback(res);
          return;
        }
        dispatch(recieveSubscribeOccupation(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestSubscribeOccupationError(err));
  }
};

export const resetSubscribedOccupation = () => dispatch => {
  dispatch(resetSubscribedOccupationData());
};

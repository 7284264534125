import {useEffect} from 'react';
import Survey from './Survey';
import {connect} from 'react-redux';
import {NoCareerInterest} from 'data/pathway';
import {getAppConfig} from 'redux/modules/general';
import {
  getCISResults,
  fetchCISResults,
  fetchCareerInterestQuestions,
} from 'redux/modules/survey';
import {getInstanceVersion} from 'redux/modules/instance';
import {
  Accordion,
  NoContentNavigator,
  RequestErrorLoader,
  ErrorBoundary,
} from 'mui-core';
import {fetchClusters, getClusters} from 'redux/modules/search';

const CareerInterest = ({
  version,
  clusters,
  fetchClusters,
  careerInterest,
  fetchCISResults,
  fetchCareerInterestQuestions,
}) => {
  const {
    dashboard: {my_careers: {take_career_interest_survey = false} = {}} = {},
  } = version;
  useEffect(() => {
    fetchClusters();
    fetchCareerInterestQuestions();
    fetchCISResults();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ErrorBoundary nameOfComponent='explore-career'>
        <RequestErrorLoader
          hideEmpty
          body={{
            data: careerInterest?.data,
            request: careerInterest?.request || clusters?.request,
          }}>
          {careerInterest?.answers &&
          Array.isArray(careerInterest?.answers?.student_responses) &&
          careerInterest.answers.student_responses?.length > 0 ? (
            <>
              <Survey
                surveyAnswers={careerInterest?.answers}
                clusters={clusters?.data}
                showHeaderButton
              />
            </>
          ) : (
            <Accordion
              collpsable={true}
              defaultOpen={true}
              header={'My Career Interest Survey'}>
              <NoContentNavigator
                pathTo='/career-survey/my-career-survey?reset=true'
                label={
                  take_career_interest_survey
                    ? ''
                    : 'Take Career Interest Survey'
                }
                message={
                  take_career_interest_survey
                    ? 'This feature is currently inactive.'
                    : NoCareerInterest
                }
                subHeading={
                  take_career_interest_survey
                    ? 'Reach out to your institution to gain insights on how to activate this feature and enhance your user experience.'
                    : ''
                }
              />
            </Accordion>
          )}
        </RequestErrorLoader>
      </ErrorBoundary>
    </>
  );
};

const mapStateToProps = state => ({
  clusters: getClusters(state),
  appConfig: getAppConfig(state),
  version: getInstanceVersion(state),
  careerInterest: getCISResults(state),
});

export default connect(mapStateToProps, {
  getCISResults,
  fetchClusters,
  fetchCISResults,
  getInstanceVersion,
  fetchCareerInterestQuestions,
})(CareerInterest);

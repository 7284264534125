import {combineReducers} from 'redux';

import ProgramsData from './programs';
import ProgramDetail from './programDetail';
import SavedPrograms from './savedPrograms';
import CareerAreaList from './careerAreaList';
import ComparePrograms from './comparePrograms';
import SignedUpPrograms from './signedUpPrograms';

export const programReducer = combineReducers({
  ProgramsData,
  ProgramDetail,
  SavedPrograms,
  CareerAreaList,
  ComparePrograms,
  SignedUpPrograms,
});

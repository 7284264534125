import React, {useState} from 'react';
import ProfessionalExperienceList from './ProfessionalExperienceList';
import {useSelector} from 'react-redux';
import {ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {getResumeData} from 'redux/modules/resume-builder';

const ProfessionalExperience = ({formRef, handleSave}) => {
  const [isLoading, setisLoading] = useState(false);
  const {data: resumeData, request} = useSelector(getResumeData);

  const onSubmit = (data, action) => {
    const payload = getPayload(data, action);
    handleSave(payload);
  };

  const getPayload = (data, action) => {
    let workExp = resumeData.work_exp || [];
    if (action === 'edit') {
      workExp = workExp.map(item => {
        if (item.display_order === data.display_order) {
          return data;
        } else {
          return item;
        }
      });
    } else if (action === 'delete') {
      const newArr = workExp.filter(
        item => item.display_order !== data.display_order,
      );
      workExp = newArr.map((item, index) => {
        return {...item, display_order: index + 1};
      });
    } else {
      workExp.push({...data, display_order: resumeData.work_exp.length + 1});
    }
    return {work_exp: workExp};
  };

  return (
    <ErrorBoundary
      nameOfComponent='resume-builder-work-experience'
      typeOfUi='subPage'>
      <RequestErrorLoader
        body={{data: resumeData, request: isLoading || request}}>
        <ProfessionalExperienceList
          hideTitle
          hideEmpty
          formRef={formRef}
          data={resumeData}
          onChange={onSubmit}
          getPayload={getPayload}
        />
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default ProfessionalExperience;

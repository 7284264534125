import {useState, useEffect} from 'react';
import {Row, Col, Form, Badge, Button} from 'antd';
import {FilterOutlined} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isBoolean from 'lodash/isBoolean';

import {useEnableDisableScroll} from 'core/hooks';
import {
  RequestErrorLoader,
  ErrorBoundary,
  NoResults,
  PathCard,
  SearchResultsCounter,
  NoContentNavigator,
} from 'core/components';

//Local Components
import FiltersForm from './FiltersForm';
import Sorter from './Sorter';
import DataGrid from './DataGrid';
import Types from './Types';

import {employOpportData, defaultLocation} from 'data/occupation.json';
import {useInstance} from 'core/hooks';

const config = {
  type: 'appliedWorkforce',
  nameLabel: 'Company: ',
};

const EmploymentOpportunites = props => {
  const {
    params,
    pathData: {title: pathTitle = ''} = {},
    onChange,
    occupationDetails,
    fetchPrograms,
    fetchLocalJobs,
    fetchJobBoards,
    //fetchPublicInsightJobs,
    programs,
    localJobs,
    jobBoards,
    publicInsightJobs,
    clearPrograms,
    clearLocalJobs,
    clearJobBoards,
    //clearPublicInsightJobs,
    isProgramOverview,
    isBusinessPartner,
    isGlobalSearch,
    businessPartnerName,
    appConfig: {isMobileView},
    onCareerChange,
    onlyLocal,
    gridView = true,
    //stopFilterScrollOnMobile,
    history,
    fetchAllJobs,
    allJobs,
    locationData,
    clearAllJobs = () => {},
    fetchLocationSearch,
  } = props;

  const {
    location: {pathname},
  } = history;

  const {
    data: {
      nearby_msa = [],
      super_type_name = null,
      request: instanceRequest,
      default_msa: {city = '', state_code = '', msa_code = ''} = {},
      physical_address = {},
    } = {},
  } = useInstance();
  const defaultLoc = city ? `${city}, ${state_code}` : defaultLocation;

  const [form] = Form.useForm();
  const {
    isFilterVisible,
    onFilterClick,
    setIsFilterVisible,
  } = useEnableDisableScroll();

  const isRelevant = pathname === '/search' && params?.query;
  const defaultSortByValue = 'relevant';

  const [reqParams, setReqParams] = useState({
    occupation_id: occupationDetails?.data?.occupation_uuid,
    occupation_onnet: occupationDetails?.data?.occupation_onnet,
    occupation_name: occupationDetails?.data?.occupation_name,
    company_name: isBusinessPartner ? businessPartnerName : null,
    type: isBusinessPartner || onlyLocal ? 'local_jobs' : 'job_boards',
    gsearch: params?.query,
    sort: defaultSortByValue,
    days: 7,
    page: 1,
    location_type: 'all',
    loadMore: false,
    location: defaultLoc,
  });
  const [initialReqParams, setInitialReqParams] = useState(reqParams);
  const searchTitle = isProgramOverview
    ? pathTitle
    : params?.query
    ? params?.query
    : '';
  const [filters, setFilters] = useState({title: searchTitle || ''});

  const TotalRecords =
    (programs?.data && programs.data.no_of_records) ||
    (allJobs?.data && allJobs?.data?.page_details?.total_results) ||
    (publicInsightJobs?.data && publicInsightJobs.data.no_of_records) ||
    0;

  const apiCall = (type, Obj, filters = null) => {
    fetchAllJobs(Obj, filters);
    // if (type === 'local_jobs') {
    //   fetchLocalJobs(Obj, filters);
    //   return;
    // }
    // if (type === 'job_boards') {
    //   fetchJobBoards(Obj, filters);
    //   //fetchPublicInsightJobs(Obj, filters);
    //   return;
    // }
    // fetchPrograms(Obj, filters);
  };

  const clearReducer = type => {
    clearAllJobs();
    if (type === 'local_jobs') {
      clearLocalJobs();
      return;
    }
    if (type === 'job_boards') {
      clearJobBoards();
      //clearPublicInsightJobs();
      return;
    }
    clearPrograms();
  };

  useEffect(() => {
    const defaultLoc = city ? `${city}, ${state_code}` : defaultLocation;
    const {
      type = isBusinessPartner || onlyLocal ? 'local_jobs' : 'job_boards',
      query,
    } = params || {};
    if (occupationDetails?.data || businessPartnerName || !onlyLocal) {
      const prevGlobalSearchValue = reqParams.gsearch;
      const Obj = Object.assign({}, reqParams, {
        occupation_id: occupationDetails?.data?.occupation_uuid,
        occupation_onnet: occupationDetails?.data?.occupation_onnet,
        occupation_name: occupationDetails?.data?.occupation_name,
        company_name: isBusinessPartner ? businessPartnerName : null,
        type: type,
        gsearch: query && decodeURIComponent(query),
        sort: defaultSortByValue,
        days: 7,
        page: 1,
        page_size: 25,
        loadMore: false,
        location: defaultLoc,
      });
      setReqParams(Obj);
      form.setFieldsValue('location', Obj.location);
      setInitialReqParams(Obj);
      setFilters({...filters, location: Obj.location, msa_code: Obj.msa_code});
      if (type === 'job_boards') {
        const newFilters = Object.assign(
          {},
          prevGlobalSearchValue !== Obj.gsearch ? {} : filters,
          {
            title: Obj?.gsearch || filters?.title,
            location: Obj.location,
          },
        );
        setFilters(newFilters);
        prevGlobalSearchValue !== Obj.gsearch && form.resetFields();
        form.setFieldsValue({title: newFilters['title']});
        apiCall(type, Obj, newFilters);
      } else {
        setFilters({});
        form.resetFields();
        apiCall(type, Obj);
      }
    }
    return () => {
      clearReducer(type);
    };
  }, [
    occupationDetails?.data,
    businessPartnerName,
    params?.type,
    params?.query,
    msa_code,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTypeChange = e => {
    let value = e;
    if (e && isObject(e)) {
      value = e.target.value;
    }
    onChange(value);
  };
  const onLocationChange = e => {
    let value = e;
    if (e && isObject(e)) {
      value = e.target.value;
    }
    const Obj = Object.assign({}, reqParams, {location_type: value});
    refetchData(Obj, filters);
  };

  const onFinish = values => {
    let filterApply = false;
    if (values) {
      const filterKeys = Object.keys(values);
      const Obj = Object.assign({}, reqParams, {page: 1});
      if (
        filterKeys.includes('job_type') ||
        filterKeys.includes('location_type')
      ) {
        delete values[isBusinessPartner ? 'location_type' : 'job_type'];
        form.setFieldsValue(
          isBusinessPartner ? {location_type: false} : {job_type: false},
        );
      }
      filterKeys.map(key => {
        if (values[key] || isBoolean(values[key])) {
          filterApply = true;
        }
      });
      setFilters(values);
      if (filterApply) {
        refetchData(Obj, values);
      } else {
        // clearJobBoards();
        clearAllJobs();
      }
      isMobileView && setIsFilterVisible(false);
    }
  };

  // to handle error conditions
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onSorterChange = (key, value) => {
    const sort_column = value !== 'relevant' ? 'accquisitiondate' : 'jobtitle';
    const Obj = Object.assign(
      {},
      reqParams,
      {[key]: value, page: 1},
      {sort_column},
    );
    let filterApply = false;
    const filterKeys = Object.keys(filters);
    filterKeys.map(key => {
      if (filters[key]) {
        filterApply = true;
      }
    });
    if (filterApply) {
      refetchData(Obj, filters);
    }
  };

  const onPageChange = page => {
    const Obj = Object.assign({}, reqParams, {page});
    refetchData(Obj, filters);
    window.scrollTo({
      top: isBusinessPartner ? 300 : 470,
      behavior: 'smooth',
    });
  };

  const onRemoteChange = e => {
    let value = e;
    if (e && isObject(e)) {
      value = e.target.checked;
    }
    const Obj = Object.assign({}, reqParams);
    const newFilters = {...filters};
    if (value) {
      newFilters[isBusinessPartner ? 'job_type' : 'job_type'] = 'remote';
    } else {
      delete newFilters[isBusinessPartner ? 'job_type' : 'job_type'];
      form.setFieldsValue(
        isBusinessPartner ? {job_type: false} : {job_type: false},
      );
    }
    setFilters(newFilters);
    refetchData(Obj, newFilters);
    isMobileView && setIsFilterVisible(false);
  };

  const onOccupationChange = (_, obj) => {
    const {children: occupationName} = obj;
    // const newFilters = Object.assign({}, filters, {title: occupationName});
    setFilters({title: occupationName});
    onCareerChange(_, obj);
  };

  const onFilterReset = () => {
    const Obj = Object.assign({}, reqParams, {page: 1});
    const updatedFilters = {
      location: initialReqParams.location,
      title: searchTitle ? decodeURI(searchTitle) : '',
    };
    setFilters(updatedFilters);
    clearAllJobs();
    if (Obj.type === 'job_boards') {
      clearJobBoards();
    }
    refetchData({...initialReqParams, page: 1}, updatedFilters);
    // isMobileView && setIsFilterVisible(false);
  };

  const refetchData = (Obj, filters) => {
    setReqParams(Obj);
    apiCall(reqParams.type, Obj, filters);
  };

  const InitialData = allJobs || programs;

  const dataLength = InitialData?.data && InitialData.data.datalength;

  const hideLoadMore =
    InitialData?.data &&
    InitialData?.data?.data &&
    InitialData?.data?.data?.length >= InitialData?.data?.no_of_records &&
    !InitialData.request &&
    !InitialData.error;

  const loadMore = () => {
    const Obj = Object.assign(
      {},
      reqParams,
      {page: reqParams.page + 1},
      {loadMore: true},
    );
    refetchData(Obj, filters);
  };
  let LocationInfo = locationData?.LocationSearch;
  const errorStyle = {
    fontWeight: 'bold',
  };
  const errorMsg = (
    <h3>
      <span>
        Oops! No matches were found for
        <span style={errorStyle}>&nbsp;“{searchTitle}”.&nbsp;</span>
        Please try a different keyword or explore other jobs
      </span>
    </h3>
  );

  return (
    <ErrorBoundary
      nameOFComponent='mod-comp-opportunites-employment-opportunties'
      showDialog={true}>
      {isMobileView && (
        <>
          {/* <Types
            onChange={onTypeChange}
            selectedValue={reqParams?.type}
            initialData={InitialData}
            isBusinessPartner={isBusinessPartner}
          /> */}

          <span className='filterCareer'>
            <span className='w_text'>
              {employOpportData.filterByMobileHeading}
            </span>
            <Badge dot={!isEmpty(filters)}>
              <FilterOutlined onClick={onFilterClick} />
            </Badge>
          </span>
        </>
      )}
      <RequestErrorLoader body={{request: InitialData.request}}>
        {searchTitle &&
        isGlobalSearch &&
        TotalRecords === 0 &&
        filters['title'] === searchTitle &&
        Object.keys(filters).filter(item => item !== 'title').length === 0 ? (
          !InitialData.request && (
            <NoContentNavigator
              message={errorMsg}
              pathTo='/job-opportunities'
              label='Explore Jobs'
            />
          )
        ) : (
          <div className='employment-content py-4'>
            <Row>
              {isFilterVisible && (
                <Col
                  xs={24}
                  sm={24}
                  md={6}
                  lg={6}
                  className='opportunity-filters px-3'>
                  <FiltersForm
                    {...props}
                    form={form}
                    reqParams={reqParams}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    filters={filters}
                    onReset={onFilterReset}
                    type={reqParams.type}
                    isMobileView={isMobileView}
                    onHideFilter={onFilterClick}
                    isBusinessPartner={isBusinessPartner}
                    isGlobalSearch={isGlobalSearch}
                    onTypeChange={onTypeChange}
                    initialData={InitialData}
                    onRemoteChange={onRemoteChange}
                    onCareerChange={onOccupationChange}
                    onWorkLocationChange={onLocationChange}
                    formLabel={isBusinessPartner ? 'Search By' : ''}
                    locationSearchData={LocationInfo}
                    fetchLocationSearch={fetchLocationSearch}
                    nearbyMSA={nearby_msa?.map((l, idx) => {
                      return {
                        ...l,
                        id: idx + 1,
                        nearby_msa: 'Nearby Locations',
                      };
                    })}
                  />
                </Col>
              )}
              {gridView && (
                <Col xs={24} sm={24} md={18} lg={18} className='pl-3'>
                  <div className='employment-data'>
                    <Sorter
                      {...reqParams}
                      onChange={onSorterChange}
                      initialData={InitialData}
                      calcData={{
                        totalRecords: TotalRecords,
                        page: reqParams.page,
                        length: dataLength,
                      }}
                      isRelevant={isRelevant}
                      isGlobalSearch={isGlobalSearch}
                      isClubbedResults={true}
                    />
                    <DataGrid
                      {...props}
                      {...reqParams}
                      total={TotalRecords}
                      onChange={onPageChange}
                      initialData={InitialData}
                    />
                  </div>
                </Col>
              )}
            </Row>
            {!gridView && (
              <RequestErrorLoader
                body={{...InitialData, data: InitialData?.data?.data}}
                overideNoDataContainer={<NoResults />}>
                {InitialData?.data?.data && (
                  <div className='pathway-opportunities-section'>
                    <SearchResultsCounter
                      className='opportunities-count my-4'
                      currentLength={dataLength}
                      totalResults={TotalRecords}
                      request={InitialData?.request}
                    />
                    {InitialData?.data?.data.map(d => {
                      const data = {
                        banner_cloudinary: d?.job_post_thumbnail_cloudinary,
                        title: d?.title,
                        tagType: d?.opportunity_type,
                        institute_details: {
                          name: d?.institute_details?.institution_name,
                        },
                      };
                      return (
                        <PathCard
                          enableNavigation
                          data={data}
                          key={d?.opportunity_id}
                          disLogo={false}
                          config={config}
                          target
                        />
                      );
                    })}
                    {!hideLoadMore && (
                      <div className='text-center'>
                        <Button
                          type='primary'
                          className='events-load-more'
                          onClick={loadMore}>
                          {InitialData?.request ? 'LOADING...' : 'LOAD MORE'}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </RequestErrorLoader>
            )}
          </div>
        )}
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default EmploymentOpportunites;

import React, {Fragment} from 'react';
import {Row, Col} from 'antd';
import {ErrorBoundary} from 'core/components';
import './style.less';

const Stats = ({data, headingtop, colWidth}) => {
  const StatItem = (
    color,
    count,
    title,
    className = '',
    arrow,
    children,
    insideColWidth,
    uniqueKey,
  ) => (
    <li
      xs={12}
      sm={8}
      md={8}
      lg={insideColWidth || colWidth || 4}
      key={uniqueKey}
      className={`count-col bor-${color} ${className} ${
        (children && children.length > 0 && 'show-pointer-events') || ''
      }`}>
      {headingtop ? (
        <>
          <h4 className='count-txt'>{title}</h4>
          <span className='label-txt'>{count}</span>
        </>
      ) : (
        <>
          <h4 className='count-txt'>
            {count}
            {arrow && <span className={`icon-arrow-${arrow}`} />}
          </h4>
          <span className='label-txt'>{title}</span>
        </>
      )}
    </li>
  );

  return (
    <ErrorBoundary>
      <div className='stats-summary'>
        <ul className='stats-counts'>
          {data &&
            data.length > 0 &&
            data.map(
              (
                {
                  color,
                  count,
                  title,
                  className,
                  arrow,
                  children,
                  insideColWidth,
                },
                idx,
              ) => {
                return (
                  <Fragment key={idx}>
                    {StatItem(
                      color,
                      count,
                      title,
                      className,
                      arrow,
                      children,
                      insideColWidth,
                      `${idx}-parent`,
                    )}
                    {children &&
                      children.length > 0 &&
                      children.map(
                        ({color, count, title, className, arrow}, idx) =>
                          StatItem(
                            color,
                            count,
                            title,
                            className,
                            arrow,
                            null,
                            insideColWidth,
                            `${idx}-child`,
                          ),
                      )}
                  </Fragment>
                );
              },
            )}
        </ul>
      </div>
    </ErrorBoundary>
  );
};

export default Stats;

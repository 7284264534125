import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Grid, themeColorMode} from 'mui-core';
import {infoIcon, InfoIconwhite} from 'assets/images';
import {styled, useTheme} from '@mui/material/styles';
import {getResumeData} from 'redux/modules/resume-builder';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {Stack, Divider, Typography, FormControlLabel} from '@mui/material';
import {CheckStar} from 'mui-core/Icons';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

const StyledInfoRounded = styled('img')(() => ({
  width: 15,
  height: 15,
  marginLeft: 4,
  marginTop: -7,
  cursor: 'pointer',
}));

const LightTooltip = styled(({className, ...props}) => (
  <Tooltip arrow {...props} classes={{popper: className}} placement='bottom' />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    padding: '1px',
    borderRadius: '8px',
    color: themeColorMode(theme, 'black', 'white'),
    boxShadow: `1px 3px 12px ${theme.palette.grayText.main}`,
    backgroundColor: themeColorMode(
      theme,
      'white',
      theme.palette.darkGray.main,
    ),
    background:
      'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}));

const GradientDivider = styled(Divider)(() => ({
  borderImage:
    'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  borderImageSlice: '20%',
  borderImageWidth: '2px',
}));

const DetailsSection = ({
  title,
  sectionInfo,
  tooltipData,
  handleStepChange,
}) => {
  const [sections, setSections] = useState([]);

  const theme = useTheme();
  const {currStep: activeStep} = useSelector(getResumeData) || {};
  const InfoIconImage =
    theme?.palette?.mode === 'dark' ? InfoIconwhite : infoIcon;

  useEffect(() => {
    setSections([...sectionInfo]);
  }, [sectionInfo]);

  return (
    <Stack
      p={2}
      // direction={{xs: 'column', lg: 'row'}}
      // alignItems={{xs: 'flex-start', lg: 'center'}}
    >
      <Stack minWidth={150} direction='row' mb={{xs: 2, lg: 0}}>
        <Typography variant='body1' textAlign={{xs: 'start', lg: 'center'}}>
          {title}
        </Typography>
        <LightTooltip
          title={
            <Stack
              spacing={2}
              sx={{
                padding: '32px',
                borderRadius: '8px',
                background: theme.palette.mode === 'light' ? 'white' : 'black',
              }}>
              <Typography variant='h3' fontWeight={300} color='text.primary'>
                {title}
              </Typography>
              <GradientDivider variant='middle' />
              {tooltipData}
            </Stack>
          }>
          <StyledInfoRounded alt='info-icon' src={InfoIconImage} />
        </LightTooltip>
      </Stack>
      <Grid container width='100%' spacing={1} pt={2}>
        {sections?.map(({key, label, value}) => (
          <Grid item xs={12} key={key}>
            <FormControlLabel
              sx={{
                gap: '12px',
                margin: '5px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => handleStepChange(key)}
              label={
                <Stack
                  justifyContent='center'
                  sx={
                    key === activeStep
                      ? {
                          pl: 1,
                          minHeight: 40,
                          minWidth: 186,
                          borderRadius: '4px',
                          backgroundColor: theme.palette.purplebg12perc.main,
                        }
                      : {}
                  }>
                  <Typography
                    variant='body2'
                    color={
                      theme.palette.mode === 'light'
                        ? 'darkGray.main'
                        : 'darkGray.contrastText'
                    }>
                    {label}
                  </Typography>
                </Stack>
              }
              control={
                value ? (
                  <CheckStar size='small' />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon
                    sx={{
                      bgcolor: 'grey.200',
                      border: '1px solid',
                      borderColor: 'grey.200',
                      borderRadius: '4px',
                      color: 'grey.200',
                      width: 18,
                      height: 18,
                    }}
                  />
                )
              }
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default DetailsSection;

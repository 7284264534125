import * as TYPES from './constants';
import {API} from 'config';
import {
  requestEventList,
  recieveEventList,
  requestEventListError,
  clearEventList,
  recieveAllEventsList,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, error: false, data: null};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_EVENT_LIST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_ALL_EVENTS_LIST:
      let data = action.payload;
      if (state.data && state.data.event_details) {
        data.event_details = [
          ...state.data.event_details,
          ...data.event_details,
        ];
      }
      return {
        ...state,
        request: false,
        error: false,
        data,
      };
    case TYPES.RECIEVE_EVENT_LIST:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_EVENT_LIST_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.CLEAR_EVENT_LIST:
      return initialState;
    default:
      return state;
  }
};

export const fetchUpcomingEvents = (reqParams, filters, loadmore = false) => (
  dispatch,
  getState,
) => {
  const {sort = 'upcoming_events', event_type = 'all', page = 1} =
    reqParams || {};
  const pageSize = 12;
  let endPoint = `${API.srm.upcoming_events}?page_size=${pageSize}&page=${page}&sort_by=${sort}`;
  dispatch(requestEventList());
  if (filters) {
    const getFilterKeys = Object.keys(filters);
    if (getFilterKeys.length) {
      if (!getFilterKeys.includes('bp_name')) {
        // endPoint += `&event_type=${event_type}`;
      }
      getFilterKeys.map(key => {
        if (filters[key]) {
          endPoint += `&${key}=${filters[key]}`;
        }
      });
    }
  }
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        if (loadmore) {
          dispatch(recieveAllEventsList(res)); // Concatinate prev result to current result on loadmore
        } else {
          dispatch(recieveEventList(res));
        }
        return;
      }
      dispatch(requestEventListError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestEventListError(err));
  }
};

export const resetEventList = () => dispatch => {
  dispatch(clearEventList());
};

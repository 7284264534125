//OPEN JOBS
export const REQUEST_BUSINESS_PARTNERS = 'REQUEST_BUSINESS_PARTNERS';
export const RECIEVE_BUSINESS_PARTNERS = 'RECIEVE_BUSINESS_PARTNERS';
export const REQUEST_BUSINESS_PARTNERS_ERROR =
  'REQUEST_BUSINESS_PARTNERS_ERROR';
export const CLEAR_BUSINESS_PARTNERS = 'CLEAR_BUSINESS_PARTNERS';
export const RECIEVE_ALL_BUSINESS_PARTNERS = 'RECIEVE_ALL_BUSINESS_PARTNERS';

// Business Partner Details
export const REQUEST_BUSINESS_PARTNER_DETAILS =
  'REQUEST_BUSINESS_PARTNER_DETAILS';
export const RECIEVE_BUSINESS_PARTNER_DETAILS =
  'RECIEVE_BUSINESS_PARTNER_DETAILS';
export const REQUEST_BUSINESS_PARTNER_DETAILS_ERROR =
  'REQUEST_BUSINESS_PARTNER_DETAILS_ERROR';
export const CLEAR_BUSINESS_PARTNER_DETAILS = 'CLEAR_BUSINESS_PARTNER_DETAILS';

// Become Business Partner
export const REQUEST_TO_BECOME_BUSINESS_PARTNER =
  'REQUEST_TO_BECOME_BUSINESS_PARTNER';
export const RECEIVE_BECOME_BUSINESS_PARTNER_RESPONSE =
  'RECEIVE_BECOME_BUSINESS_PARTNER_RESPONSE';
export const REQUEST_TO_BECOME_BUSINESS_PARTNER_ERROR =
  'REQUEST_TO_BECOME_BUSINESS_PARTNER_ERROR';
export const CLEAR_BECOME_BUSINESS_PARTNER_RESPONSE =
  'CLEAR_BECOME_BUSINESS_PARTNER_RESPONSE';

// Industry List
export const REQUEST_INDUSTRY_LIST = 'REQUEST_INDUSTRY_LIST';
export const RECEIVE_INDUSTRY_LIST = 'RECEIVE_INDUSTRY_LIST';
export const REQUEST_INDUSTRY_LIST_ERROR = 'REQUEST_INDUSTRY_LIST_ERROR';
export const CLEAR_INDUSTRY_LIST = 'CLEAR_INDUSTRY_LIST';

//nonProfit companies
export const CLEAR_NONPROFIT_COMPANY_LIST = 'CLEAR_NONPROFIT_COMPANY_LIST';
export const REQUEST_NONPROFIT_COMPANY_LIST = 'REQUEST_NONPROFIT_COMPANY_LIST';
export const RECIEVE_NONPROFIT_COMPANY_LIST = 'RECIEVE_NONPROFIT_COMPANY_LIST';
export const RECIEVE_ALL_NONPROFIT_COMPANY_LIST =
  'RECIEVE_ALL_NONPROFIT_COMPANY_LIST';
export const REQUEST_NONPROFIT_COMPANY_LIST_ERROR =
  'REQUEST_NONPROFIT_COMPANY_LIST_ERROR';

//non profit subtype list
export const REQUEST_NON_PROFIT_ERROR = 'REQUEST_NON_PROFIT_ERROR';
export const REQUEST_NON_PROFIT_SUBTYPES_LIST =
  'REQUEST_NON_PROFIT_SUBTYPES_LIST';
export const RECIEVE_NON_PROFIT_SUBTYPES_LIST =
  'RECIEVE_NON_PROFIT_SUBTYPES_LIST';

//nonProfit companies Details
export const CLEAR_NONPROFIT_COMPANY_DETAILS =
  'CLEAR_NONPROFIT_COMPANY_DETAILS';
export const REQUEST_NONPROFIT_COMPANY_DETAILS =
  'REQUEST_NONPROFIT_COMPANY_DETAILS';
export const RECIEVE_NONPROFIT_COMPANY_DETAILS =
  'RECIEVE_NONPROFIT_COMPANY_DETAILS';
export const REQUEST_NONPROFIT_COMPANY_DETAILS_ERROR =
  'REQUEST_NONPROFIT_COMPANY_DETAILS_ERROR';

//nonProfit company members List
export const CLEAR_NONPROFIT_COMPANY_MEMBERSLIST =
  'CLEAR_NONPROFIT_COMPANY_MEMBERSLIST';
export const REQUEST_NONPROFIT_COMPANY_MEMBERSLIST =
  'REQUEST_NONPROFIT_COMPANY_MEMBERSLIST';
export const RECIEVE_NONPROFIT_COMPANY_MEMBERSLIST =
  'RECIEVE_NONPROFIT_COMPANY_MEMBERSLIST';
export const RECIEVE_NONPROFIT_COMPANY_ALL_MEMBERSLIST =
  'RECIEVE_NONPROFIT_COMPANY_ALL_MEMBERSLIST';
export const REQUEST_NONPROFIT_COMPANY_MEMBERSLIST_ERROR =
  'REQUEST_NONPROFIT_COMPANY_MEMBERSLIST_ERROR';

import React from 'react';
import {getProfilePhoto} from 'core/utils';
import {styled} from '@mui/material/styles';
import {MuiProfileUpload} from 'core/hooks';
import {avatarGray, editpurple} from 'assets/images';
import {
  Box,
  Badge,
  Stack,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  CircularProgress,
} from '@mui/material';

const UploadProfileImage = ({updateUserProfile, viewOnly, isMobileView}) => {
  const [progress, userData, uploadImage] = MuiProfileUpload('photo');
  const {data} = userData || {};
  const [showTooltip, setShowTooltip] = React.useState(false);
  let userPhoto = avatarGray;
  if (data && data.photo) {
    userPhoto = getProfilePhoto(data.photo);
  }

  const handleImageChange = e => {
    e.preventDefault();
    uploadImage(e.target.files[0]);
  };
  const deletePhoto = async () => {
    const body = {
      photo: null,
    };
    await updateUserProfile(body);
  };

  const UploadImagecls = styled(Box)(({theme}) => ({
    zIndex: 9,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    '& .avatarContainerStyle': {
      position: 'relative',
      display: 'inline-block',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-8px',
      '& .avatarContainerStyle': {
        position: 'relative',
        display: 'inline-block',
      },
    },
    '& .MuiBadge-badge': {
      left: '-40px',
      bottom: '0px',
      display: 'flex',
      padding: '14px 6px',
      borderRadius: '50%',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
      backgroundColor: '#8050F4',
      [theme.breakpoints.down('sm')]: {
        left: '8px',
        bottom: '32px',
      },
      '& img': {
        width: '16px',
        height: '14px',
        filter:
          'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(279deg) brightness(108%) contrast(100%)',
      },
    },
  }));

  const actions = () => (
    <>
      <Stack
        direction='row'
        divider={<Divider orientation='vertical' />}
        spacing={1}>
        <Typography
          onClick={deletePhoto}
          sx={{
            cursor: 'pointer',
            pr: 1,
            borderRight: 'solid 1px #cccccc',
          }}
          color='primary'
          fontSize='14px'>
          Remove
        </Typography>

        <label htmlFor='profile-photo-upload' style={{marginLeft: '2px'}}>
          <Typography sx={{cursor: 'pointer'}} color='primary' fontSize='14px'>
            Edit
          </Typography>
        </label>
      </Stack>
    </>
  );

  return (
    <>
      <input
        type='file'
        accept='image/*'
        id='profile-photo-upload'
        //multiple
        onChange={handleImageChange}
        style={{display: 'none'}}
      />
      <UploadImagecls>
        <Box className='avatarContainerStyle'>
          <Avatar
            alt='profile'
            src={userPhoto}
            sx={{
              bgcolor: 'white',
              width: {xs: '60px', sm: '80px'},
              height: {xs: '60px', sm: '80px'},
            }}
          />
          {!!progress && (
            <CircularProgress
              thickness={1.9}
              value={progress}
              variant='indeterminate'
              size={isMobileView ? 60 : 80}
              style={{position: 'absolute', top: 0, left: 0}}
            />
          )}
        </Box>
        <Badge
          overlap='circular'
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          badgeContent={
            <>
              <Tooltip
                arrow
                placement='bottom'
                title={actions()}
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'white',
                      color: 'primary',
                      fontSize: '12px',
                    },
                  },
                }}>
                <img
                  alt='edit'
                  src={editpurple}
                  style={{cursor: 'pointer'}}
                  onClick={() => setShowTooltip(!showTooltip)}
                />
              </Tooltip>
            </>
          }></Badge>
      </UploadImagecls>
    </>
  );
};

export default UploadProfileImage;

import moment from 'moment';
import {useUser} from 'core/hooks';
import {useSnackbar} from 'notistack';
import {briefCaseIcon} from 'assets/images';
import {styled} from '@mui/material/styles';
import {useHistory} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  DialogBox,
  ContentBox,
  ErrorBoundary,
  RequestErrorLoader,
} from 'mui-core';
import {
  Box,
  Stack,
  Select,
  useTheme,
  MenuItem,
  InputLabel,
  Typography,
  FormControl,
  ListItemIcon,
  useMediaQuery,
} from '@mui/material';
import {
  fetchProfileData,
  getPrivacySetting,
  profileDataSelector,
  updatePrivacySetting,
} from 'redux/modules/profile';
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
} from '@mui/icons-material';
import {profileStatus} from 'data/dashboard-settings.json';

const {
  errMsg,
  successMsg,
  dialogHeader,
  publishOptions,
  dialogCancelBtn,
  dialogConfirmBtn,
  dialogDescription,
  profileSettingLabel,
  dialogActivationBtn,
  ageConfirmationDialog,
} = profileStatus || {};

const StyledSelectContainer = styled(Box)(({theme}) => ({
  minWidth: 110,
  width: 332,
  '& .MuiInputLabel-root': {
    fontSize: '14px',
  },
  '& .MuiSelect-outlined': {
    color: theme.palette.primary.main,
  },
  '& .MuiInputBase-root': {
    color: theme.palette.primary.light,
  },
  '& .MuiSelect-root:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
    },
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.light,
  },
  '& .MuiTypography-body2': {
    display: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .menuItemIcon': {
    display: 'none',
  },
}));

const AgeConfirmationDialog = ({show = false, handleClose, handleConfirm}) => {
  return (
    <DialogBox
      iconSize={96}
      openDialog={show}
      showIcon={briefCaseIcon}
      closeDialog={handleClose}
      actions={
        <>
          <Button
            variant='outlined'
            onClick={handleClose}
            sx={{mb: 2, minWidth: {xs: '80px', md: '168px'}}}>
            {ageConfirmationDialog.noText}
          </Button>
          <Button
            variant='contained'
            onClick={handleConfirm}
            sx={{mb: 2, minWidth: {xs: '80px', md: '168px'}}}>
            {ageConfirmationDialog.yesText}
          </Button>
        </>
      }>
      <Stack spacing={1}>
        <Typography textAlign='center' variant='h4' color='text.primary'>
          {ageConfirmationDialog.titleText}
        </Typography>
        <Typography
          variant='body1'
          textAlign='center'
          color='text.primary'
          dangerouslySetInnerHTML={{
            __html: ageConfirmationDialog.subTitleText,
          }}></Typography>
      </Stack>
    </DialogBox>
  );
};

const ProfileStatus = ({isCriminalJustice}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [isChecked, setIsChecked] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const privacy = useSelector(getPrivacySetting) || {};
  const {data: profileDetails, request: profileRequest} =
    useSelector(profileDataSelector) || {};
  const {data: userData, request: userDataRequest} = useUser();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showAgeConfirmationDialog, setShowAgeConfirmationDialog] = useState(
    false,
  );
  const {
    data: privacyData,
    request: privacyRequest,
    saverequest: privacySaveRequest,
  } = privacy || {};
  const {privacy_uuid: privacyUUID} = privacyData || {};

  useEffect(() => {
    if (privacyData && privacyData.publish_profile) {
      setIsChecked(true);
    } else {
      dispatch(fetchProfileData());
      setIsChecked(false);
    }
  }, [privacyData]); // eslint-disable-line react-hooks/exhaustive-deps

  const calculateAge = dob => {
    try {
      const birthDate = moment(dob);
      const years = moment().diff(birthDate, 'years', true);
      return Math.floor(years);
    } catch (err) {
      return 0;
    }
  };

  const handleProfileVisibilityChange = () => {
    if (!isChecked && !profileDetails?.active_resume) {
      setShowDialog(true);
    }
    const dob = userData?.dob;
    const userAge = dob ? calculateAge(dob) : null;
    if (isChecked || userAge >= 16) {
      changeProfileVisibility();
    } else {
      setShowAgeConfirmationDialog(true);
    }
  };

  const handleAgeConfirmation = confirmed => {
    if (confirmed) {
      changeProfileVisibility();
    }
    setShowAgeConfirmationDialog(false);
  };

  const changeProfileVisibility = () => {
    dispatch(
      updatePrivacySetting(privacyUUID, {publish_profile: !isChecked}, res => {
        if (res?.privacy_uuid) {
          enqueueSnackbar(successMsg, {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        } else {
          enqueueSnackbar(errMsg, {
            variant: 'error',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        }
      }),
    );
    setIsChecked(!isChecked);
  };

  const onDialogClose = () => {
    setShowDialog(false);
    setIsChecked(false);
  };

  const clickConfirm = () => {
    setShowDialog(false);
    history.push(`/dashboard/goResume`);
  };

  const closeAgeConfirmationDialog = () => {
    setShowAgeConfirmationDialog(false);
  };

  return (
    <ContentBox elevation={0} sx={{p: '20px', pt: {xs: 1, sm: 0.8}}}>
      {!isCriminalJustice && (
        <ErrorBoundary nameOfComponent='dashboard-profile-status'>
          <RequestErrorLoader
            hideEmpty
            fullWidth
            body={{
              data: privacyData,
              request: profileRequest || privacyRequest || privacySaveRequest,
            }}>
            <Stack
              mt={{xs: 2, sm: 0}}
              gap={{xs: 2, sm: 6}}
              direction={isMobile ? 'column' : 'row'}>
              <StyledSelectContainer>
                <FormControl fullWidth>
                  <InputLabel
                    size='small'
                    color='primary'
                    htmlFor='publish-profile-select'
                    id='publish-profile-select-label'>
                    {profileSettingLabel}
                  </InputLabel>
                  <Select
                    size='small'
                    value={isChecked}
                    variant='outlined'
                    label={profileSettingLabel}
                    labelId='publish-profile-select'
                    placeholder={profileSettingLabel}
                    aria-label='publish-profile-select'
                    inputProps={{id: 'publish-profile-select'}}
                    sx={{
                      fontSize: isMobile ? '12px' : '15px',
                    }}
                    onChange={handleProfileVisibilityChange}
                    MenuProps={{
                      MenuListProps: {
                        sx: {
                          padding: 0,
                          '& li': {
                            padding: '16px 8px',
                          },
                        },
                      },
                    }}
                    renderValue={selected => {
                      const selectedOption = publishOptions.find(
                        option => option.value === selected,
                      );
                      return selectedOption ? (
                        <Typography color='text.primary'>
                          {selectedOption.label}
                        </Typography>
                      ) : (
                        ''
                      );
                    }}>
                    {publishOptions.map(
                      ({value, label, description}, index) => (
                        <MenuItem value={value} key={index}>
                          <ListItemIcon>
                            {value ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </ListItemIcon>
                          <Stack gap={0.25} maxWidth='320px'>
                            <Typography variant='bodyL'>{label}</Typography>
                            <Typography
                              variant='body1'
                              sx={{
                                overflow: 'hidden',
                                whiteSpace: 'break-spaces',
                              }}>
                              {description}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </StyledSelectContainer>
            </Stack>
          </RequestErrorLoader>
        </ErrorBoundary>
      )}
      {showDialog && (
        <DialogBox
          openDialog={showDialog}
          closeDialog={onDialogClose}
          actions={
            <>
              <Button
                variant='outlined'
                onClick={onDialogClose}
                sx={{minWidth: {xs: '80px', md: '168px'}}}>
                {dialogCancelBtn}
              </Button>
              <Button
                variant='contained'
                onClick={clickConfirm}
                sx={{minWidth: {xs: '80px', md: '168px'}}}>
                {!profileDetails?.active_resume
                  ? dialogActivationBtn
                  : dialogConfirmBtn}
              </Button>
            </>
          }>
          <Stack spacing={1}>
            <Typography textAlign='center' variant='h4' color='text.primary'>
              {dialogHeader}
            </Typography>
            <Typography textAlign='center' variant='body1' color='text.primary'>
              {dialogDescription}
            </Typography>
          </Stack>
        </DialogBox>
      )}
      {showAgeConfirmationDialog && (
        <AgeConfirmationDialog
          show={showAgeConfirmationDialog}
          handleClose={closeAgeConfirmationDialog}
          handleConfirm={handleAgeConfirmation}
        />
      )}
    </ContentBox>
  );
};

export default ProfileStatus;

import React, {useState} from 'react';
import PublicationList from './PublicationList';
import {useSelector} from 'react-redux';
import {ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {awardTypesSelector as getAwardTypes} from 'redux/modules/profile';
import {getResumeData} from 'redux/modules/resume-builder';

const Publications = ({formRef, handleSave}) => {
  const [isLoading, setisLoading] = useState(false);
  const {data: resumeData, error} = useSelector(getResumeData);

  const {
    error: publicationError,
    data: publicationTypes = [],
    request: publicationRequest,
  } = useSelector(getAwardTypes);

  let awardsList = [];
  let publicationList = [];
  const publicationItem = publicationTypes?.find(
    i => i.item_type === 'Publication',
  );
  resumeData?.awards_publications?.forEach(ele => {
    if (ele.item_type === publicationItem?.item_type_uuid) {
      publicationList?.push(ele);
    } else {
      awardsList?.push(ele);
    }
  });

  const onSubmit = (data, action) => {
    const payload = getPayload(data, action);
    handleSave(payload);
  };

  const getPayload = (data, action) => {
    let publicationData = [...publicationList];
    if (action === 'edit') {
      publicationData = publicationData.map(item =>
        item.display_order === data.display_order ? data : item,
      );
    } else if (action === 'delete') {
      const newArr = publicationData.filter(
        item => item.display_order !== data.display_order,
      );
      publicationData = newArr.map((item, index) => {
        return {...item, display_order: index + 1};
      });
    } else {
      publicationData.push({
        ...data,
        display_order: publicationData.length + 1,
      });
    }
    return {
      awards_publications: [...awardsList, ...publicationData],
    };
  };

  return (
    <ErrorBoundary
      typeOfUi='subPage'
      nameOfComponent='resume-builder-publications'>
      <RequestErrorLoader
        hideEmpty
        fullWidth
        body={{
          error: error || publicationError,
          data: resumeData.awards_publications,
          request: isLoading || publicationRequest,
        }}>
        <PublicationList
          formRef={formRef}
          data={resumeData}
          onChange={onSubmit}
          getPayload={getPayload}
          publicationTypes={publicationTypes}
        />
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};
export default Publications;

import * as TYPES from './constants';
import {API} from 'config';

import {Services} from 'core/Services';
import {
  goSurveyResultDetailsError,
  goSurveyResultDetailsSuccess,
  requestGoSurveyResultDetails,
} from './actions';
const services = new Services();

const initialState = {request: false, data: false, error: null};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GO_SURVEY_RESULT_DETAILS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.GO_SURVEY_RESULT_DETAILS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.GO_SURVEY_RESULT_DETAILS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.GO_SURVEY_DETAILS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const fetchGoSurveyResultDetails = (careerName, cipCode, cb) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();

  try {
    dispatch(requestGoSurveyResultDetails());
    const endPoint = `${API.gps.student_cis_data}`;
    return services
      .createUpdateRecord(
        token,
        endPoint,
        {
          go_ai: 'get_specific_results',
          career_name: careerName,
          cip_code: cipCode,
        },
        'POST',
      )
      .then(res => {
        dispatch(goSurveyResultDetailsSuccess(res));
        cb && cb(res);
      });
  } catch (err) {
    console.log(err);
    dispatch(goSurveyResultDetailsError(err));
  }
};

import moment from 'moment';
import {Link} from 'react-router-dom';
import {capitalizeFirstLetter} from 'core/utils';
import {memo, useCallback, useMemo} from 'react';
import {DataGrid, Accordion, RequestErrorLoader} from 'mui-core';

const AppliedOpportunities = ({
  appliedOpportunities,
  fetchAppliedOpportunities,
}) => {
  const columns = useMemo(() => [
    {
      headerName: 'Title',
      field: 'opportunity_name',
      flex: 0.4,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (record = {}) => {
        const {institute_name, opportunity_name, opportunity_type} =
          record?.row || {};
        const opportunityPath =
          opportunity_name && institute_name && opportunity_type
            ? `/opportunity/local/${encodeURIComponent(
                opportunity_name,
              )}/${encodeURIComponent(
                institute_name,
              )}?job_type=${opportunity_type}`
            : '';

        return (
          <Link
            tabIndex={0}
            target='_target'
            to={opportunityPath}
            title={opportunity_name}>
            {opportunity_name}
          </Link>
        );
      },
    },
    {
      headerName: 'Company',
      field: 'institute_name',
      flex: 0.3,
      editable: false,
      sortable: false,
    },
    {
      headerName: 'Type',
      field: 'opportunity_type',
      flex: 0.15,
      editable: false,
      sortable: false,
      valueFormatter: params => {
        return capitalizeFirstLetter(params.value);
      },
    },
    {
      headerName: 'Date Applied',
      field: 'apply_date',
      flex: 0.15,
      type: 'number',
      align: 'center',
      editable: false,
      sortable: false,
      headerAlign: 'center',
      valueFormatter: params => {
        return moment(params.value).format('MM/DD/YYYY');
      },
    },
  ]);

  const {data, request} = appliedOpportunities;
  const fetchJobs = useCallback(expanded => {
    if (expanded) fetchAppliedOpportunities();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Accordion
      outlined={false}
      id='applied_jobs'
      onChange={fetchJobs}
      header='My Applied Opportunities'>
      {/* <RequestErrorLoader body={appliedOpportunities} hideEmpty> */}
      <DataGrid
        pageSize={10}
        columns={columns}
        pagination={true}
        loading={request}
        hideFooter={false}
        paginationMode='client'
        rows={data ? data : []}
        rowsPerPageOptions={[5, 10, 20]}
        totalRowCount={data ? data.length : 0}
        getRowId={params => params.opp_application_uuid}
      />
      {/* </RequestErrorLoader> */}
    </Accordion>
  );
};
export default memo(AppliedOpportunities);

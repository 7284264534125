import {Row, Col, Button} from 'antd';
import {instanceerroricon} from 'assets/images';

import './style.less';

const ErrorPage = ({
  heading = 'Look Back, Something is Missing..!',
  subHeading = 'An unexpected error occured. Try Checking below possibliity',
  list = ['The URL you entered', 'The internet connection'],
  pageRefresh = false,
}) => (
  <Row className='error_page'>
    <Col sm={10} className='text-right'>
      <img src={instanceerroricon} alt='instance-error-image' />
    </Col>
    <Col sm={14}>
      <h1>{heading}</h1>
      <p>{subHeading}</p>
      {list && Array.isArray(list) && list?.length > 0 && (
        <ul className='list_blueDot'>
          {list.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      )}
      {pageRefresh && (
        <div className='refresh_Btn'>
          <Button
            className='btn-purple'
            onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </div>
      )}
    </Col>
  </Row>
);

export default ErrorPage;

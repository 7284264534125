import {noSearchResults} from 'assets/images';
import Results from 'data/noResults';
import style from './style.module.less';

const NoResults = ({heading, subHeading}) => (
  <div className={`text-center py-3 ${style.noResult}`}>
    <img src={noSearchResults} alt='No search results' />
    <h1>{heading || Results.noSearchResultFoundHeading}</h1>
    <h5>{subHeading || Results.noSearchResultFoundDescription}</h5>
  </div>
);

export default NoResults;

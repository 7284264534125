import React from 'react';
import {Grid} from 'mui-core';
import PropTypes from 'prop-types';
import {PATTERN} from 'core/regex';
import {createField} from 'mui-core/Form';
import {OutlinedInput} from '@mui/material';
import SectionHeader from './SectionHeader';

const ContactInformation = ({form, structure}) => {
  const {sectionTitle, first_name, last_name, email, phone} = structure;
  return (
    <Grid
      container
      rowSpacing={4}
      sx={{m: '10px'}}
      columnSpacing={{xs: 1, sm: 2, md: 3}}>
      <Grid item xs={12} py={0} pt={1}>
        <SectionHeader index={2} text={sectionTitle} />
      </Grid>
      <Grid item xs={12} sm={6}>
        {createField('first_name', {
          form,
          size: 'small',
          defaultValue: '',
          label: first_name.label,
          rules: {
            maxLength: [60, first_name.maxLengthError],
            required: [first_name.required, first_name.requiredError],
          },
        })(
          <OutlinedInput
            variant='outlined'
            id={first_name.label}
            label={first_name.label}
            placeholder={first_name.placeholder}
          />,
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createField('last_name', {
          form,
          size: 'small',
          defaultValue: '',
          label: last_name.label,
          rules: {
            maxLength: [60, last_name.maxLengthError],
            required: [last_name.required, last_name.requiredError],
          },
        })(
          <OutlinedInput
            variant='outlined'
            id={last_name.label}
            label={last_name.label}
            placeholder={last_name.placeholder}
          />,
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createField('email', {
          form,
          size: 'small',
          defaultValue: '',
          label: email.label,
          rules: {
            required: [email.required, email.requiredError],
            pattern: [PATTERN?.Email, email.customError],
          },
        })(
          <OutlinedInput
            id={email.label}
            variant='outlined'
            label={email.label}
            placeholder={email.placeholder}
          />,
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createField('phone', {
          form,
          size: 'small',
          defaultValue: '',
          label: phone.label,
          rules: {
            required: [phone.required, phone.requiredError],
            pattern: [PATTERN?.Phone, phone.customError],
          },
        })(
          <OutlinedInput
            type='number'
            id={phone.label}
            variant='outlined'
            label={phone.label}
            placeholder={phone.placeholder}
            inputProps={{maxLength: 10}}
          />,
        )}
      </Grid>
    </Grid>
  );
};

ContactInformation.propTypes = {
  form: PropTypes.object,
  structure: PropTypes.object,
};

export default ContactInformation;

import {themeColorMode} from 'mui-core';
import {Visibility} from '@mui/icons-material';
import {resumePreviewIcon} from 'assets/images';
import {Box, Stack, useTheme, Typography} from '@mui/material';
import {lighten, styled} from '@mui/material/styles';

const StyledPreviewButton = styled(Box)(({theme, purpleHeader}) => ({
  // '&:hover': {
  //   backgroundColor: 'transparent',
  //   color: theme.palette.primary.main,
  // },
  height: '58px',
  display: 'flex',
  marginTop: '-32px',
  padding: '9px 16px',
  alignItems: 'center',
  justifyContent: 'center',
  width: `calc(100% + 64px)`,
  backgroundColor: purpleHeader
    ? lighten(theme.palette.primary.dark, 0.5)
    : themeColorMode(theme, theme.palette.grey[200], theme.palette.grey[600]),
}));

const NoDataPreview = ({
  isMobile,
  purpleHeader,
  heading = 'Preview',
  isShareProfile = false,
  disableEyeIcon = false,
}) => {
  const theme = useTheme();
  const StyledDescription = styled(Typography)(({theme}) => ({
    '& img ': {
      filter:
        theme.palette.mode === 'light'
          ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(5313%) hue-rotate(103deg) brightness(123%) contrast(93%);'
          : '',
    },
  }));
  return (
    <Stack>
      {!isMobile && (
        <Stack alignItems='center'>
          <StyledPreviewButton
            rounded
            size='small'
            variant='outlined'
            purpleHeader={purpleHeader}
            endIcon={<Visibility fontSize={'large'} />}>
            <Typography variant='h7'>{heading}</Typography>
            {!disableEyeIcon && (
              <Visibility fontSize={'small'} sx={{marginLeft: '8px'}} />
            )}
          </StyledPreviewButton>
        </Stack>
      )}
      {!isShareProfile && (
        <Box
          mb={1}
          p={isMobile ? 2 : 6}
          my={isMobile ? 7 : 2}
          sx={{
            width: '100%',
            borderRadius: 1,
            textAlign: 'center',
            background:
              theme.palette.mode === 'dark'
                ? theme.palette.dialogDarkBG.main
                : 'linear-gradient(0deg, rgba(123, 96, 223, 0.08), rgba(123, 96, 223, 0.08))',
          }}>
          <Typography
            color='primary'
            variant='body1'
            sx={{
              color:
                theme.palette.mode === 'light'
                  ? 'primary.main'
                  : 'primary.light',
            }}>
            As you complete the resume sections, your resume will dynamically
            start taking shape here, providing you with a real-time preview of
            your progress.
          </Typography>
        </Box>
      )}

      <StyledDescription
        display='flex'
        alignItems='center'
        justifyContent='center'
        py={8}>
        <img
          src={resumePreviewIcon}
          alt='Resume Preview'
          style={{
            maxWidth: isMobile ? 240 : 360,
          }}
        />
      </StyledDescription>
    </Stack>
  );
};

export default NoDataPreview;

// GET PROGRAMS
export const PROGRAMS_REQUEST = 'PROGRAMS_REQUEST';
export const PROGRAMS_SUCCESS = 'PROGRAMS_SUCCESS';
export const PROGRAMS_ERROR = 'PROGRAMS_ERROR';
export const CLEAR_PROGRAMS = 'CLEAR_PROGRAMS';

// GET PROGRAM DETAIL
export const PROGRAM_DETAIL_REQUEST = 'PROGRAM_DETAIL_REQUEST';
export const PROGRAM_DETAIL_SUCCESS = 'PROGRAM_DETAIL_SUCCESS';
export const PROGRAM_DETAIL_ERROR = 'PROGRAM_DETAIL_ERROR';
export const CLEAR_PROGRAM_DETAIL = 'CLEAR_PROGRAM_DETAIL';

// SIGNED PROGRAMS

export const SIGNED_PROGRAMS_REQUEST = 'SIGNED_PROGRAMS_REQUEST';
export const SIGNED_PROGRAMS_SUCCESS = 'SIGNED_PROGRAMS_SUCCESS';
export const SIGNED_PROGRAMS_ERROR = 'SIGNED_PROGRAMS_ERROR';
export const CLEAR_SIGNED_PROGRAMS = 'CLEAR_SIGNED_PROGRAMS';

export const SIGN_UP_PROGRAM_REQUEST = 'SIGN_UP_PROGRAM_REQUEST';
export const SIGN_UP_PROGRAM_SUCCESS = 'SIGN_UP_PROGRAM_SUCCESS';
export const SIGN_UP_PROGRAM_ERROR = 'SIGN_UP_PROGRAM_ERROR';

// SAVED PROGRAMS

export const SAVED_PROGRAMS_REQUEST = 'SAVED_PROGRAMS_REQUEST';
export const SAVED_PROGRAMS_SUCCESS = 'SAVED_PROGRAMS_SUCCESS';
export const SAVED_PROGRAMS_ERROR = 'SAVED_PROGRAMS_ERROR';
export const CLEAR_SAVED_PROGRAMS = 'CLEAR_SAVED_PROGRAMS';

export const SAVE_PROGRAM_REQUEST = 'SAVE_PROGRAM_REQUEST';
export const SAVE_PROGRAM_SUCCESS = 'SAVE_PROGRAM_SUCCESS';
export const SAVE_PROGRAM_ERROR = 'SAVE_PROGRAM_ERROR';

// CAREER AREA LIST
export const CAREER_AREA_LIST_ERROR = 'CAREER_AREA_LIST_ERROR';
export const CLEAR_CAREER_AREA_LIST = 'CLEAR_CAREER_AREA_LIST';
export const CAREER_AREA_LIST_REQUEST = 'CAREER_AREA_LIST_REQUEST';
export const CAREER_AREA_LIST_SUCCESS = 'CAREER_AREA_LIST_SUCCESS';
export const CAREER_AREA_ALL_LIST_SUCCESS = 'CAREER_AREA_ALL_LIST_SUCCESS';

// COMPARE PROGRAMS
export const COMPARE_PROGRAMS_REQUEST = 'COMPARE_PROGRAMS_REQUEST';
export const COMPARE_PROGRAMS_SUCCESS = 'COMPARE_PROGRAMS_SUCCESS';
export const COMPARE_PROGRAMS_ERROR = 'COMPARE_PROGRAMS_ERROR';
export const CLEAR_COMPARE_PROGRAMS = 'CLEAR_COMPARE_PROGRAMS';

import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from 'antd';
import {PathCard, ErrorBoundary} from 'core/components';

const cardsSize = 3;

const CardsList = ({clusterData, showButton, showHeaderButton}) => {
  const history = useHistory();
  const [showResults, setShowResults] = useState(showButton ? false : true);
  const toggleResults = () => {
    setShowResults(!showResults);
  };
  const calcPerc = count => {
    const high = clusterData[0].count;
    const res = ((100 * count) / high).toFixed(0);
    return res == 0 ? '4' : res;
  };
  const navigateToCluster = cluster => {
    let url = `/search?cluster_name=${encodeURIComponent(cluster?.title)}`;
    history.push(url);
  };
  const navigateSurvey = cluster => {
    let url = `/survey/my-survey?reset=true`;
    history.push(url);
  };

  return (
    <ErrorBoundary nameOfComponent='core-career-interest-survey'>
      <div className='results-body-header'>
        <div className='result_header_container'>
          <>
            <h3>Top Career Area Matches</h3>
            <p>
              Select a Career Area to discover related programs of study,
              educational pathways, and careers.
            </p>
          </>
          {showHeaderButton && (
            <Button
              type='primary'
              className='btn-retake'
              onClick={navigateSurvey}>
              Retake Survey
            </Button>
          )}
        </div>
      </div>
      <div className='d-flex cards-parent'>
        {clusterData
          ?.slice(
            0,
            showResults
              ? clusterData?.length
              : showButton
              ? cardsSize
              : clusterData?.length,
          )
          .map(
            (cluster, idx) =>
              cluster.rank < 4 && (
                <PathCard
                  key={cluster?.uuid || idx}
                  data={cluster}
                  disLogo={false}
                  enableNavigation
                  config={{
                    type: 'cluster',
                  }}
                  customClass='survey-result-card'
                />
              ),
          )
          .filter(Boolean)}
        <br />
      </div>
      {showButton && (
        <div className='ml-4 text-left'>
          <Button className='mr-3 btn-blue-outer' onClick={toggleResults}>
            {!showResults ? 'Show' : 'Hide'} Results
          </Button>

          <Button
            type='primary'
            className='btn-retake'
            onClick={navigateSurvey}>
            Retake Career Interest Survey
          </Button>

          <br />
          <br />
        </div>
      )}
      {showResults && (
        <>
          <div className='results-body-graph mt-0 mb-3'>
            <h3>Career Interest Survey Results</h3>
          </div>
          <div className='graph-results'>
            <table className='graph-table'>
              <thead>
                <tr>
                  <th style={{width: '10%'}}>RANK</th>
                  <th style={{width: '30%'}}>CAREER AREA</th>
                  <th style={{width: '60%'}}>RESULTS</th>
                </tr>
              </thead>
              <tbody>
                {clusterData
                  ?.filter(i => i.count !== 0)
                  .map(dt => (
                    <tr key={dt.uuid}>
                      <td style={{width: '10%', textAlign: 'center'}}>
                        {dt.rank}
                      </td>
                      <td style={{width: '30%'}} title={dt.title}>
                        <div onClick={() => navigateToCluster(dt)}>
                          {dt.title}
                        </div>
                      </td>
                      <td style={{width: '60%'}} title={dt.count}>
                        <div className='bar-perc-c'>
                          <div className='bar-perc-h'>
                            <div
                              style={{width: `${calcPerc(dt.count)}%`}}
                              className='bar-perc'></div>
                            <div className='bar-perc-count'>{dt.count}</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </ErrorBoundary>
  );
};

export default CardsList;

import jwtDecode from 'jwt-decode';
import * as TYPES from './constants';
import {API} from 'config';

import {
  loginError,
  loginSuccess,
  requestLogin,
  closeLoginScreen,
  triggerLoginSuccessCallBack,
  rememberCredentials,
  requestNewToken,
  receiveNewToken,
  errorNewToken,
  doLogout,
} from './actions';

import {Services} from 'core/Services';
import {
  studentOnboardClear,
  clearOnboardTypes,
  clearSavedPaths,
} from '../pathways/actions';
import {resetSubscribedOccupation} from '../occupation';
import {clearSavedPrograms, clearSignedPrograms} from './../programs/actions';
import {clearJobApplied, clearJobSaved} from './../jobs/actions';
import {
  studentCredentialsClear,
  studentMetricsClear,
  studentSkillsClear,
} from '../profile/actions';
import {
  clearOpportunityApplied,
  clearOpportunitySaved,
} from '../occupation/actions';
import {
  fetchSkills,
  fetchAwardTypes,
  fetchProfileData,
  fetchSeekingData,
} from '../profile';
import {fetchAwardTypes as fetchProfileAwardTypes} from '../search';
import {removeQueryParamToURL} from 'core/utils';
import {goSurveyAnswersClear, goSurveyResultsClear} from '../survey/actions';

const services = new Services();

const getUserObj = data => {
  const decode = jwtDecode(data.access);
  const user = {
    ...decode,
    first_name: data.first_name,
    last_name: data.last_name,
  };
  return user;
};

const initialState = {
  token: false,
  refresh: null,
  user: null,
  error: null,
  request: false,
  refreshTokenRequest: false,
  refreshTokenError: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_NEW_TOKEN:
      return {
        ...state,
        refreshTokenRequest: true,
        refreshTokenError: false,
      };
    case TYPES.REQUEST_LOGIN:
      return {
        ...state,
        request: true,
        token: false,
        user: null,
        error: null,
        refresh: null,
      };
    case TYPES.RECEIVE_NEW_TOKEN:
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.access,
        refresh: action.payload.refresh,
        request: false,
        user: getUserObj(action.payload),
        error: null,
        refreshTokenRequest: false,
        refreshTokenError: false,
      };
    case TYPES.LOGIN_ERROR:
      return {
        ...state,
        token: false,
        user: null,
        request: false,
        error: action.payload,
      };
    case TYPES.ERROR_NEW_TOKEN:
      return {
        ...state,
        refreshTokenError: action.payload,
        refreshTokenRequest: false,
      };
    case TYPES.LOG_OUT:
      return {
        ...state,
        token: false,
        user: null,
        request: false,
        error: false,
      };
    default:
      return state;
  }
};

export const login = (data, remember, history, callback) => (
  dispatch,
  getState,
) => {
  if (remember) {
    dispatch(rememberCredentials(data));
  } else {
    dispatch(rememberCredentials({}));
  }
  dispatch(requestLogin());
  try {
    let endPoint = `${data.session ? API.gps.reset_password : API.gps.login}`;
    services.createUpdateRecord({}, endPoint, data, 'POST').then(res => {
      if (callback) callback(res);
      if (res.access) {
        dispatch(loginSuccess(res));
        dispatch(fetchSkills());
        dispatch(fetchAwardTypes());
        dispatch(fetchSeekingData());
        dispatch(closeLoginScreen());
        dispatch(fetchProfileData());
        dispatch(fetchProfileAwardTypes(false, true));
        dispatch(triggerLoginSuccessCallBack(res));
      } else {
        dispatch(loginError(res));
      }
    });
  } catch (err) {
    console.log(err);
    dispatch(loginError(err));
  }
};

export const getNewToken = () => (dispatch, getState) => {
  const {
    auth: {auth: {refresh, user, refreshTokenRequest} = {}} = {},
  } = getState();
  const username = (user && user['cognito:username']) || null;
  try {
    if (username && refresh && !refreshTokenRequest) {
      dispatch(requestNewToken());
      const endPoint = `${API.gps.refresh_token}`;
      const bodyData = {
        username: username,
        refresh_token: refresh,
      };
      services
        .createUpdateRecord(null, endPoint, bodyData, 'POST')
        .then(res => {
          if (res.access) {
            dispatch(receiveNewToken(res));
          }
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(errorNewToken(err));
  }
};

export const logout = () => (dispatch, getState) => {
  dispatch(doLogout());
  dispatch(studentOnboardClear());
  dispatch(clearOnboardTypes());
  dispatch(resetSubscribedOccupation());
  dispatch(clearSavedPrograms());
  dispatch(clearSignedPrograms());
  dispatch(clearJobApplied());
  dispatch(clearJobSaved());
  dispatch(studentCredentialsClear());
  dispatch(studentSkillsClear());
  dispatch(clearOpportunityApplied());
  dispatch(clearOpportunitySaved());
  dispatch(studentMetricsClear());
  dispatch(clearSavedPaths());
  dispatch(goSurveyAnswersClear());
  dispatch(goSurveyResultsClear());
};

import {Button, DialogBox} from 'mui-core';
import OnBoardType from './OnBoardType';
import {tickGreenCircle} from 'assets/images';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {Stack, Typography} from '@mui/material';
import {programModalStaticData} from 'data/programs.json';

const {
  yesTxt,
  cancelTxt,
  saveProgramTxt,
  removeProgramTxt,
  viewEnrollmentTxt,
  enrollInProgramTxt,
  successEnrollInProgramTxt,
  enrollInProgramSubHeading1,
  enrollInProgramSubHeading2,
  confirmRemoveSavedProgramTxt,
  successEnrollInProgramSubHeading,
} = programModalStaticData;

const ProgramModals = ({
  enrollURLData,
  onBoardData,
  removeProgramData,
  removeSaved,
}) => {
  return (
    <>
      <DialogBox
        showIcon={tickGreenCircle}
        openDialog={enrollURLData.visible}
        closeDialog={enrollURLData?.onCancel}
        actions={
          <>
            <Button
              variant='outlined'
              sx={{minWidth: '168px'}}
              startIcon={<ExitToAppIcon />}
              onClick={enrollURLData?.onOk}>
              {enrollInProgramTxt}
            </Button>
          </>
        }>
        <Stack spacing={1}>
          <Typography textAlign='center' variant='h4' color='text.primary'>
            {enrollInProgramSubHeading1}
          </Typography>
          <Typography textAlign='center' variant='body1' color='text.primary'>
            {enrollInProgramSubHeading2}
          </Typography>
        </Stack>
      </DialogBox>

      <DialogBox
        showIcon={tickGreenCircle}
        openDialog={removeProgramData.visible}
        closeDialog={removeProgramData?.onCancel}
        actions={
          <>
            {removeProgramData?.data?.enrollment_url && (
              <Button
                sx={{mr: 2}}
                target={'_blank'}
                variant='contained'
                startIcon={<ExitToAppIcon />}
                href={removeProgramData?.data?.enrollment_url}>
                {viewEnrollmentTxt}
              </Button>
            )}
            <Button
              variant='contained'
              sx={{minWidth: '168px'}}
              onClick={removeProgramData?.onOk}>
              {removeProgramTxt}
            </Button>
          </>
        }>
        <Stack spacing={1}>
          <Typography textAlign='center' variant='h4' color='text.primary'>
            {successEnrollInProgramTxt}
          </Typography>
          <Typography textAlign='center' variant='body1' color='text.primary'>
            {successEnrollInProgramSubHeading}
          </Typography>
        </Stack>
      </DialogBox>

      <DialogBox
        showIcon={tickGreenCircle}
        openDialog={removeSaved.visible}
        closeDialog={removeSaved?.onCancel}
        actions={
          <>
            <Button
              variant='outlined'
              onClick={removeSaved?.onCancel}
              sx={{minWidth: {xs: '80px', md: '168px'}}}>
              {cancelTxt}
            </Button>
            <Button
              variant='contained'
              onClick={removeSaved?.onOk}
              sx={{minWidth: {xs: '80px', md: '168px'}}}>
              {yesTxt}
            </Button>
          </>
        }>
        <Stack spacing={1}>
          <Typography textAlign='center' variant='h4' color='text.primary'>
            {saveProgramTxt}
          </Typography>
          <Typography textAlign='center' variant='body1' color='text.primary'>
            {confirmRemoveSavedProgramTxt}
          </Typography>
        </Stack>
      </DialogBox>

      <OnBoardType onBoardData={onBoardData} />
    </>
  );
};
export default ProgramModals;

import {combineReducers} from 'redux';

import ethnicity from './ethnicity';
import citizenship from './citizenship';
import nativeLanguage from './nativeLanguage';
import profileData from './profileData';
import seekingData from './seekingData';
import awardTypes from './awardTypes';
import skills from './skills';
import studentSkills from './studentSkills';
import studentMetrics from './studentMetrics';
import studentCredentials from './studentCredentials';
import user from './user';
import occupationInterest from './occupationInterest';
import PrivacySetting from './privacysetting';
import programs from './programs';
import changeEmail from './changeEmail';
import userTypelist from './userTypeList';

export const profileReducer = combineReducers({
  ethnicity,
  citizenship,
  nativeLanguage,
  profileData,
  awardTypes,
  skills,
  user,
  occupationInterest,
  seekingData,
  studentSkills,
  studentCredentials,
  studentMetrics,
  privacysettinginfo: PrivacySetting,
  programs,
  changeEmail,
  userTypelist,
});

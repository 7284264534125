import React from 'react';

import {Text, View, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  courseTable: {
    flexDirection: 'column',
    width: '47%',
    fontSize: 10,
    marginBottom: 20,
    marginRight: 20,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  courseHeader: {
    flexDirection: 'row',
    backgroundColor: '#758A93',
    minHeight: 45,
    color: 'white',
    alignItems: 'center',
    fontSize: 12,
    paddingLeft: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  courseTRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#EAEAEA',
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: '#EAEAEA',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#EAEAEA',
    minHeight: 40,
    alignItems: 'center',
    justifyContent: 'space-around',
    color: '#333333',
  },
  courseTCol: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '33%',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  noCourseWrapper: {
    flexDirection: 'column',
    backgroundColor: 'white',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#EAEAEA',
    width: '100%',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  noCourse: {
    minHeight: 60,
    backgroundColor: '#F9F8FF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#EAEDF1',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  noCourseTxt: {
    width: '70%',
    color: '#3A3A3A',
    fontSize: 10,
    textAlign: 'center',
  },
});

const Row = ({data, applyBRadius}) => {
  return (
    <View
      style={[
        styles.courseTRow,
        {
          borderBottomRightRadius: applyBRadius ? 10 : 0,
          borderBottomLeftRadius: applyBRadius ? 10 : 0,
        },
      ]}
      wrap={false}>
      <View
        style={[
          styles.courseTCol,
          {alignItems: 'flex-start', paddingLeft: '10px'},
        ]}>
        <Text>{data && data.course_id}</Text>
      </View>
      <View
        style={[
          styles.courseTCol,
          {alignItems: 'flex-start', paddingLeft: '10px'},
        ]}>
        <Text>{(data && data.course_name) || (data && data.title)}</Text>
      </View>
      <View style={styles.courseTCol}>
        <Text>{(data && parseFloat(data.units)) || '0'}</Text>
      </View>
    </View>
  );
};

const NoCourse = () => {
  return (
    <View style={styles.noCourseWrapper} warp={false}>
      <View style={styles.noCourse}>
        <Text style={styles.noCourseTxt}>
          No courses have been planned for this term.
        </Text>
      </View>
    </View>
  );
};

export default ({data, index}) => {
  let totalUnits = 0;
  data &&
    data.courses &&
    data.courses.length > 0 &&
    data.courses.map(course => {
      if (course && parseFloat(course.units)) {
        totalUnits += parseFloat(course.units);
      }
    });
  return (
    <View
      style={[styles.courseTable, {marginRight: index % 2 === 0 ? '0' : '20'}]}
      key={`course-table-${index}`}
      // wrap={data && data.courses && data.courses.length > 0 ? true : false}
    >
      <View style={styles.courseHeader} wrap={false}>
        <Text>{`Term ${data?.slNo} - ${data?.term_name} (${
          totalUnits && totalUnits.toFixed(1)
        } Units)`}</Text>
      </View>
      {(data && data.courses && data.courses.length > 0 && (
        <View>
          <View
            style={[
              styles.courseTRow,
              {backgroundColor: '#FAFAFA', color: '#878890'},
            ]}
            wrap={false}>
            <View
              style={[
                styles.courseTCol,
                {alignItems: 'flex-start', paddingLeft: '10px'},
              ]}>
              <Text>COURSE ID</Text>
            </View>
            <View
              style={[
                styles.courseTCol,
                {alignItems: 'flex-start', paddingLeft: '10px'},
              ]}>
              <Text>COURSE TITLE</Text>
            </View>
            <View style={styles.courseTCol}>
              <Text>UNITS</Text>
            </View>
          </View>
          {data &&
            data.courses &&
            data.courses.length > 0 &&
            data.courses.map((course, idx) => {
              let applyBRadius = false;
              if (idx === data.courses.length - 1) {
                applyBRadius = true;
              }
              return (
                <Row
                  data={course}
                  applyBRadius={applyBRadius}
                  key={`row-${idx}`}
                />
              );
            })}
        </View>
      )) || <NoCourse />}
    </View>
  );
};

import React from 'react';
import jp from 'jsonpath';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import {useHistory} from 'react-router-dom';
import compareStaticData from 'data/compare.json';
import {styled, useTheme} from '@mui/material/styles';
import {
  Box,
  Paper,
  Stack,
  Divider,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
} from '@mui/material';
import {numberInUSFormat} from 'core/utils';
import {Button, themeColorMode} from 'mui-core';

const {
  rowsData: {
    pathLabel,
    avgCostLabel,
    locationLabel,
    durationLabel,
    offeredByLabel,
    awardTypeLabel,
    projectedSalaryLabel,
  },
  learnMoreBtnTxt,
} = compareStaticData;

const rowsData = [
  {
    label: pathLabel,
    path: '$.title',
  },
  {
    label: awardTypeLabel,
    path: '$.award_type_name',
  },
  {
    label: offeredByLabel,
    path: '$.offered_by',
  },
  {
    label: locationLabel,
    path: '$.location',
  },
  {
    label: avgCostLabel,
    path: '$.estimated_cost',
    render: value =>
      value ? `$${numberInUSFormat(value)}` : value == 0 ? 'No Cost' : 'N/A',
  },
  {
    label: projectedSalaryLabel,
    path: '$.avg_wage_earned',
    render: value =>
      value ? `$${numberInUSFormat(Math.round(value))}` : 'N/A',
  },
  {
    label: durationLabel,
    path: '$.program_duration',
  },
  {
    label: 'Credential Value',
    path: '$.credential_value',
    render: value => {
      if (value === null) {
        return 'N/A';
      } else if (value >= 0 && value <= 25) {
        return 'OK';
      } else if (value >= 26 && value <= 75) {
        return 'GOOD';
      } else if (value >= 76 && value <= 100) {
        return 'GREAT';
      } else {
        return 'N/A';
      }
    },
  },
  {
    label: '',
    learnMore: true,
  },
];

const StickyHeaderTableCell = styled(TableCell)(({theme}) => ({
  left: 0,
  zIndex: 3,
  minWidth: 240,
  overflowX: 'auto',
  position: 'sticky',
  padding: '0px 8px',
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.darkGray.main : 'white', // to avoid overlay on scroll
  [theme.breakpoints.down('sm')]: {
    left: 0,
    minWidth: 170,
  },
}));

const StyledTypography = styled(Typography)(({}) => ({
  overflow: 'hidden',
  display: '-webkit-box',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': '2',
  textTransform: 'capitalize',
  '-webkit-box-orient': 'vertical',
}));

const StickyCurrentTableCell = styled(TableCell)(({index, theme}) => ({
  minWidth: 300,
  position: 'sticky',
  left: index === 0 && 180,
  zIndex: index === 0 ? 3 : 1,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.darkGray.main
      : index === 0 && 'white', // to avoid overlay on scroll
  '& h1': {
    fontSize: 36,
    marginTop: 2,
  },

  [theme.breakpoints.down('sm')]: {
    zIndex: 1,
    left: -90,
    minWidth: 280,
  },

  '&.table-cell': {
    padding: 0, // to avoid overlay on scroll
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(even)': {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.darkGray.main : 'white', // to avoid overlay on scroll
  },
  '&:nth-child(2n) td ': {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white', // to avoid overlay on scroll
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },

  '& .img-fluid': {
    width: 200,
    borderRadius: 20,
    border: `1px solid ${themeColorMode(theme, '#f0edfc', '#601AAD')}`,
  },
  [theme.breakpoints.down('sm')]: {
    '&:nth-child(3) td': {
      padding: '5px 10px',
    },
  },
}));

const ComparePrograms = ({programData, programList}) => {
  const theme = useTheme();
  const history = useHistory();
  const {request: isCompareProgramRequest, data: comparePrograms} =
    programList || {};
  let comparedProgramsList = [];
  programData &&
    comparePrograms &&
    isArray(comparePrograms) &&
    comparePrograms.length &&
    comparedProgramsList.push(programData, ...comparePrograms);

  const pathLength = (comparedProgramsList && comparedProgramsList.length) || 0;
  const navigateTo = path => {
    let {
      program,
      title = '',
      program_id,
      award_type_name = '',
      institute_details: {name = ''} = {},
    } = path;
    let awardType = null;
    if (award_type_name && award_type_name !== 'null') {
      awardType = award_type_name;
    }
    let url = `/programs/${encodeURIComponent(name)}/${encodeURIComponent(
      title,
    )}/${encodeURIComponent(awardType)}/${encodeURIComponent(
      program_id || program,
    )}`;
    history.push(url);
  };

  const handleDecimals = value => {
    return value.includes('.0') ? value.replace('.0', '') : value;
  };

  const getDuration = data => {
    const {
      duration_days = null,
      duration_hours = null,
      duration_weeks = null,
      duration_years = null,
      duration_months = null,
    } = data || {};
    if (duration_years) {
      return `${handleDecimals(duration_years)} Year${
        duration_years > 1 ? 's' : ''
      }`;
    } else if (duration_months) {
      return `${handleDecimals(duration_months)} Month${
        duration_months > 1 ? 's' : ''
      }`;
    } else if (duration_weeks) {
      return `${handleDecimals(duration_weeks)} Week${
        duration_weeks > 1 ? 's' : ''
      }`;
    } else if (duration_hours) {
      return `${handleDecimals(duration_hours)} Hour${
        duration_hours > 1 ? 's' : ''
      }`;
    } else if (duration_days) {
      return `${handleDecimals(duration_days)} Day${
        duration_days > 1 ? 's' : ''
      }`;
    } else {
      return 'N/A';
    }
  };

  return (
    <Paper sx={{width: '100%', borderRadius: 2}}>
      {pathLength > 1 && (
        <Stack spacing={1} p={3}>
          <Typography mb={1} variant='h5' color='text.primary'>
            Compare with Similar Programs
          </Typography>
          <Divider variant='middle' />

          {!isCompareProgramRequest && (
            <TableContainer sx={{pt: 1}}>
              {rowsData?.map((row, rowIndex) => {
                return (
                  <StyledTableRow key={rowIndex}>
                    <StickyHeaderTableCell>
                      {row.label && (
                        <Typography variant='h6' color='text.primary'>
                          {row.label}
                        </Typography>
                      )}
                    </StickyHeaderTableCell>
                    {comparedProgramsList &&
                      isArray(comparedProgramsList) &&
                      !isEmpty(comparedProgramsList) &&
                      comparedProgramsList.map((comparePath, index) => {
                        let data = ['N/A'];
                        if (row.path === '$.program_duration') {
                          const durationData = getDuration(comparePath);
                          data = [durationData];
                        } else if (row.path === '$.estimated_cost') {
                          const {credential_currency_details} = comparePath;
                          const avgCost = credential_currency_details?.ave_cost;
                          data = [avgCost];
                        } else if (row.path === '$.credential_value') {
                          const {credential_currency_details} = comparePath;
                          const credentialValue =
                            credential_currency_details?.credential_value;
                          data = [credentialValue];
                        } else if (row.path === '$.avg_wage_earned') {
                          const {
                            credential_currency_details,
                            award_type_category,
                          } = comparePath;
                          const {
                            max_wage,
                            min_wage,
                            ave_wage,
                          } = credential_currency_details;
                          const getCostByAwardType = award => {
                            switch (award) {
                              case 'Grad': {
                                return max_wage;
                              }
                              case 'AA/AS':
                              case 'Certificate': {
                                return min_wage;
                              }
                              case 'BA/BS': {
                                return ave_wage;
                              }
                              default:
                                return ave_wage;
                            }
                          };
                          data = [getCostByAwardType(award_type_category)]; // [minAvgCal];
                        } else if (row.path === '$.offered_by') {
                          const {
                            institute_details: {name = ''} = {},
                          } = comparePath;
                          data = [name];
                        } else if (row.path === '$.location') {
                          const {
                            institute_details: {address = {}} = {},
                          } = comparePath;
                          const {city, state, state_code} = address;
                          const locationValue = `${city}, ${
                            state_code || state
                          }`;
                          data = [locationValue];
                        } else {
                          data =
                            row.path &&
                            comparePath &&
                            isObject(comparePath) &&
                            jp.query(comparePath, row.path);
                          if (data && isArray(data) && !data.length) {
                            data = ['N/A'];
                          }
                        }

                        return (
                          <StickyCurrentTableCell
                            index={index}
                            className='table-cell'>
                            {data &&
                              data.map((value, idx) => {
                                return (
                                  // to avoid overlay on scroll
                                  <Box
                                    sx={{
                                      zIndex: 4,
                                      padding: 1.5,
                                      position: 'relative',
                                      background:
                                        rowIndex % 2 === 0
                                          ? theme.palette.mode === 'dark'
                                            ? theme.palette.darkGray.main
                                            : theme.palette.background.paper
                                          : theme.palette.purplebg4perc.main,
                                    }}>
                                    {row.render ? (
                                      <Typography
                                        variant='body1'
                                        color='text.primary'
                                        key={
                                          (`table-row-${rowIndex}`,
                                          `table-cell-${idx}`)
                                        }
                                        textAlign={{
                                          xs: 'center',
                                          md: 'left',
                                        }}>
                                        {row.render(value)}
                                      </Typography>
                                    ) : row.path === '$.title' ? (
                                      <StyledTypography
                                        variant='h6'
                                        title={`${value} `}
                                        color='text.primary'
                                        textAlign={{
                                          xs: 'center',
                                          md: 'left',
                                        }}>
                                        {value}
                                      </StyledTypography>
                                    ) : (
                                      <StyledTypography
                                        title={`${value} `}
                                        variant='body1'
                                        color='text.primary'
                                        textAlign={{
                                          xs: 'center',
                                          md: 'left',
                                        }}>
                                        {value || 'N/A'}
                                      </StyledTypography>
                                    )}
                                  </Box>
                                );
                              })}
                            {index !== 0 && row.learnMore && (
                              // to avoid overlay on scroll
                              <Box
                                p={1.5}
                                textAlign={{xs: 'center', md: 'left'}}>
                                <Button
                                  variant='contained'
                                  onClick={() => navigateTo(comparePath)}>
                                  {learnMoreBtnTxt || ''}
                                </Button>
                              </Box>
                            )}
                          </StickyCurrentTableCell>
                        );
                      })}
                  </StyledTableRow>
                );
              })}
            </TableContainer>
          )}
        </Stack>
      )}
    </Paper>
  );
};

export default ComparePrograms;

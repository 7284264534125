import {ContentBox, ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {awardsData} from 'data/settings-profile.json';
import {EditProfileWrapper} from '../../components';
import {useSaveProfileLoader} from 'core/hooks';
import {Typography} from '@mui/material';
import AwardsList from './AwardsList';

const Awards = ({
  awardTypes,
  profileData,
  fetchAwardTypes,
  onProfileDataSubmit,
}) => {
  const {request, data, error} = profileData;
  const {subHeading, collapseBarHeading} = awardsData || {};
  const [isLoading, onSubmit] = useSaveProfileLoader(
    request,
    onProfileDataSubmit,
  );
  const {error: awardError, request: awardRequest} = awardTypes;

  return (
    <ContentBox variant='outlined' sx={{mb: 2, p: 3}}>
      <Typography
        mb={1}
        variant='h5'
        color='text.primary'
        title={collapseBarHeading}
        // fontFamily='fontFamilyBold'
      >
        {collapseBarHeading}
      </Typography>
      <Typography variant='body1' title={subHeading} color='text.secondary'>
        {subHeading}
      </Typography>
      <ErrorBoundary
        nameOfComponent='dashboard-profile-awards'
        typeOfUi='subPage'>
        <RequestErrorLoader
          body={{
            data,
            error: error || awardError,
            request: isLoading || awardRequest,
          }}>
          <AwardsList
            data={data}
            onChange={onSubmit}
            awardTypes={awardTypes}
            fetchAwardTypes={fetchAwardTypes}
          />
        </RequestErrorLoader>
      </ErrorBoundary>
    </ContentBox>
  );
};
export default EditProfileWrapper(Awards);

import PropTypes from 'prop-types';
import {useInstance} from 'core/hooks';
import {noCollageLogo} from 'assets/images';
import {styled} from '@mui/material/styles';
import {Card, LazyImage, ErrorBoundary} from 'mui-core';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Box, Stack, Paper, Typography, CardActionArea} from '@mui/material';

const LogoContainer = styled(Paper)(({theme}) => ({
  zIndex: 1,
  width: 200,
  height: 60,
  display: 'flex',
  borderRadius: 4,
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  bottom: 'calc(100% - 30px)',
  padding: '8px auto !important',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
  backgroundColor: theme.palette.background.paper,
  '& img': {
    height: '100%',
    padding: '4px',
    maxWidth: '100%',
    objectFit: 'contain',
  },
}));

const EducatorCard = ({data, width, enableNavigation = false}) => {
  const {data: {institution_id} = {}} = useInstance();
  const {
    name,
    subdomain,
    logo_cloudinary = '',
    banner_cloudinary = '',
    physical_address_details: {city, state} = {},
  } = data || {};

  const address =
    city && state ? `${city}, ${state}` : city ? city : state ? state : '';

  let pathNavigateTo = () => {
    const {host, protocol} = window.location;
    let redirectURL = '';
    if (institution_id) {
      let newHostArr = host.split('.');
      if (newHostArr[0] === 'www') {
        newHostArr.splice(0, 1);
      }
      newHostArr.splice(0, 1);
      let newHost = newHostArr.join('.');
      redirectURL = `${protocol}//${subdomain}.${newHost}`;
    } else {
      let newHostArr = host.split('.');
      if (newHostArr[0] === 'www') {
        newHostArr.splice(0, 1);
      }
      let newHost = newHostArr.join('.');
      redirectURL = `${protocol}//${subdomain}.${newHost}`;
    }
    subdomain && window.open(redirectURL, '_blank');
  };

  return (
    <ErrorBoundary nameOfComponent='core-institution-card'>
      <Card
        sx={{
          width,
          mb: 2,
          textAlign: 'left',
          position: 'relative',
          display: 'inline-block',
        }}
        component={CardActionArea}
        onClick={() => enableNavigation && pathNavigateTo()}>
        <Box height={180}>
          <LazyImage
            renderSrcSet
            src={banner_cloudinary}
            alt={`${name}-institution-banner`}
          />
        </Box>
        <Box
          p={2}
          pt={3}
          display={'flex'}
          position='relative'
          justifyContent={'center'}>
          <LogoContainer>
            <LazyImage
              imageType='cardLogo'
              src={logo_cloudinary}
              alt='institution-logo'
              defaultImage={noCollageLogo}
            />
          </LogoContainer>
        </Box>
        <Stack mx={2} mb={4} height='84px'>
          <Typography
            my={1}
            noWrap
            variant='h6'
            title={`${name || ''} `}
            color='text.primary'>
            {name || ''}
          </Typography>
          <Stack
            height={30}
            spacing={1}
            direction='row'
            color='text.primary'
            alignItems='center'>
            <LocationOnIcon style={{fontSize: '1.25rem'}} />
            <Typography
              noWrap
              variant='subtitle2'
              title={`${address} `}
              color='text.secondary'>
              {address || ''}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </ErrorBoundary>
  );
};

EducatorCard.propTypes = {
  data: PropTypes.object,
  width: PropTypes.number,
  enableNavigation: PropTypes.bool,
};

export default EducatorCard;

import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Tab, {tabClasses} from '@mui/material/Tab';
import Tabs, {tabsClasses} from '@mui/material/Tabs';
import {
  Box,
  Menu,
  Paper,
  Stack,
  Slide,
  styled,
  MenuItem,
  ButtonBase,
  Typography,
} from '@mui/material';
import {themeColorMode} from 'mui-core/utils';
import {getInstanceVersion} from 'redux/modules/instance';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const menuHeight = 36;

const movedUpStyles = (theme, movedUp) => {
  // if (movedUp)
  return {
    position: 'fixed',
    top: theme.mixins.toolbar,
    width: '100%',
    left: 0,
  };
  // else return {};
};

// const StyledPaper = styled(Paper)(({theme, menuBarOnHide, movedUp}) => ({
//   backgroundColor: themeColorMode(
//     theme,
//     theme.palette.background.paper,
//     theme.palette.darkGray.main,
//   ),
//   backgroundImage: 'none',
//   position: 'relative',
//   zIndex: 1000,
//   boxShadow:
//     !menuBarOnHide || movedUp
//       ? `0px 1px 0px 1px ${theme.palette.divider}`
//       : 'none',
//   ...movedUpStyles(theme, movedUp),
// }));

const StyledTabs = styled(Tabs)(({theme}) => ({
  [`& .${tabsClasses.indicator}`]: {
    top: 0,
    transition: 'none',
    borderRadius: '4px',
    height: '100%',
    background: 'linear-gradient(180deg, #635BFF 0%, #4E36F5 100%)',
    // `linear-gradient(180deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
    // backgroundColor: theme =>
    //   themeColorMode(theme, 'primary.dark', 'primary.light'),
  },
}));

const StyledTab = styled(Tab)(({theme}) => ({
  zIndex: 3,
  minWidth: '60px',
  height: menuHeight,
  minHeight: menuHeight,
  marginRight: theme.spacing(2),
  color: themeColorMode(theme, theme.palette.shadeyBlack.main, '#FFF'),
  [theme.breakpoints.only('xs')]: {
    marginRight: 0,
  },
  [`&.${tabClasses.selected}`]: {
    color: '#fff',
  },
  ['&:hover']: {
    backgroundColor: theme.palette.divider,
    borderRadius: '4px',
  },
}));

const MenuDropDown = ({data, label, base, selected}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{minHeight: menuHeight, display: 'flex', alignItems: 'center'}}>
      <Stack
        disableRipple
        flexDirection='row'
        alignItems='center'
        onClick={handleClick}
        component={ButtonBase}
        aria-controls='basic-menu'
        sx={{
          minHeight: menuHeight,
          textTransform: 'capitalize',
        }}>
        <Typography
          variant='label-md'
          sx={{color: 'inherit'}}
          textTransform={'initial'}>
          {label}
        </Typography>
        <KeyboardArrowDownRoundedIcon fontSize='small' />
      </Stack>

      <Menu
        open={open}
        id='basic-menu'
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        PaperProps={{
          elevation: 2,
          sx: {mt: 1, minWidth: 140, marginLeft: '-21px'},
        }}>
        {Array.isArray(data) &&
          data.map(({label, path, key}) => (
            <MenuItem
              key={key}
              component={Link}
              onClick={handleClose}
              to={`${base}${path}`}
              sx={{
                ...(selected === key
                  ? {color: theme => theme.palette.primary.main}
                  : {}),
              }}>
              {label}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};

const MenuBar = ({
  movedUp,
  MainMenu,
  menuBarOnHide,
  defaultMenuSelectedKey,
}) => {
  let filteredMainMenu = [];
  const {menu_bar = []} = useSelector(getInstanceVersion) || {};

  if (menu_bar && menu_bar.length > 0) {
    filteredMainMenu =
      MainMenu && Array.isArray(MainMenu)
        ? MainMenu.filter(a => menu_bar.includes(a.key))
        : [];
  } else {
    filteredMainMenu = MainMenu && Array.isArray(MainMenu) ? MainMenu : [];
  }
  const matchKey =
    defaultMenuSelectedKey && defaultMenuSelectedKey.length === 2
      ? defaultMenuSelectedKey[1].split('/')[1]
      : defaultMenuSelectedKey[0];

  const value =
    defaultMenuSelectedKey &&
    defaultMenuSelectedKey[0] &&
    filteredMainMenu &&
    Array.isArray(filteredMainMenu)
      ? filteredMainMenu.map(a => a.key).indexOf(matchKey)
      : -1;

  return (
    <>
      {/* <Slide
        appear={false}
        direction='down'
        in={!menuBarOnHide ? true : movedUp}>
        <StyledPaper square menuBarOnHide={menuBarOnHide} movedUp={movedUp}> */}
      <Box width='100%'>
        <StyledTabs
          value={value}
          scrollButtons='auto'
          variant='scrollable'
          allowScrollButtonsMobile
          sx={{
            height: menuHeight,
            minHeight: menuHeight,
          }}
          aria-label='scrollable auto tabs example'>
          {Array.isArray(filteredMainMenu) &&
            filteredMainMenu.map(({key, label, path, children}) => {
              const dynamicProps = {};
              if (children) dynamicProps['onClick'] = e => e.preventDefault();
              return (
                <StyledTab
                  key={key}
                  to={path}
                  exact={false}
                  label={
                    children ? (
                      <MenuDropDown
                        data={children}
                        label={label}
                        base={path}
                        selected={
                          defaultMenuSelectedKey
                            ? defaultMenuSelectedKey[0]
                            : false
                        }
                      />
                    ) : (
                      <Typography
                        variant='label-md'
                        sx={{color: 'inherit'}}
                        textTransform={'initial'}>
                        {label}
                      </Typography>
                    )
                  }
                  component={Link}
                  {...dynamicProps}
                />
              );
            })}
        </StyledTabs>
      </Box>
      {/* </StyledPaper>
      </Slide> */}
      {/* {movedUp && menuBarOnHide && ( */}
      <Box
        sx={{height: menuHeight, minHeight: menuHeight, position: 'relative'}}
      />
      {/* )} */}
    </>
  );
};
export default MenuBar;

import {useHistory} from 'react-router-dom';
import {LazyImage, ErrorBoundary} from 'core/components';
import {noCollageLogo} from 'assets/images';
import style from './style.module.less';

//import './style.less';

const BusinessPartnerCard = ({
  data,
  enableNavigation,
  displayEllipses,
  fullWidth,
}) => {
  const {
    industry_sector_name,
    active_jobs,
    active_pathways,
    name,
    logo_cloudinary,
    banner_cloudinary,
    physical_address: {city, state},
  } = data || {};

  const address =
    city && state ? `${city}, ${state}` : city ? city : state ? state : '';

  const history = useHistory();
  const pathNavigateTo = () =>
    history.push(`/companies/${encodeURIComponent(name)}`);
  const styles = {};
  if (fullWidth) {
    styles.width = '90%';
  }
  return (
    <ErrorBoundary nameOfComponent='core-eventcard'>
      <li
        onClick={() => enableNavigation && pathNavigateTo(data)}
        style={styles}
        className={style.business_Card}>
        <LazyImage
          renderSrcSet
          type='occupation'
          src={banner_cloudinary}
          alt='bp banner'
          className='img-fluid'
        />
        <span>
          <LazyImage
            imageType='logo'
            defaultImage={noCollageLogo}
            src={logo_cloudinary}
            alt='bp_logo'
            className='img-fluid'
          />
        </span>
        <div className={style.bp_address}>
          <h3 title={name}>{name || ''}</h3>
          <h4 title={address}>{address}</h4>
          <h5 title={industry_sector_name}>{industry_sector_name || ''}</h5>
        </div>
        <p className={style.c_blue}>View {active_jobs || 0} Jobs</p>
        <p className={style.c_purple}>View {active_pathways || 0} Pathways</p>
      </li>
    </ErrorBoundary>
  );
};

export default BusinessPartnerCard;

import {IconButton, lighten, styled} from '@mui/material';
import React from 'react';

const IconButtonStyled = styled(IconButton)(({theme}) => ({
  padding: 3,
  marginRight: 2,
}));

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    const Icon = this.props.icon;
    return (
      <IconButtonStyled
        size='small'
        sx={
          this.props.active
            ? {
                color: theme => lighten(theme.palette.secondary.dark, 0.1),
              }
            : {}
        }
        onMouseDown={this.onToggle}>
        <Icon fontSize='12' />
      </IconButtonStyled>
    );
  }
}

export default StyleButton;

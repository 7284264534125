// CAREER INTERREST SURVEY
export const CAREER_INTEREST_SURVEY_REQUEST = 'CAREER_INTEREST_SURVEY_REQUEST';
export const CAREER_INTEREST_SURVEY_SUCCESS = 'CAREER_INTEREST_SURVEY_SUCCESS';
export const CAREER_INTEREST_SURVEY_ERROR = 'CAREER_INTEREST_SURVEY_ERROR';

export const CAREER_INTEREST_SURVEY_RESULT_REQUEST =
  'CAREER_INTEREST_SURVEY_RESULT_REQUEST';
export const CAREER_INTEREST_SURVEY_RESULT_SUCCESS =
  'CAREER_INTEREST_SURVEY_RESULT_SUCCESS';
export const CAREER_INTEREST_SURVEY_RESULT_ERROR =
  'CAREER_INTEREST_SURVEY_RESULT_ERROR';

export const GO_SURVEY_QUESTIONS_REQUEST = 'GO_SURVEY_QUESTIONS_REQUEST';
export const GO_SURVEY_QUESTIONS_SUCCESS = 'GO_SURVEY_QUESTIONS_SUCCESS';
export const GO_SURVEY_QUESTIONS_ERROR = 'GO_SURVEY_QUESTIONS_ERROR';

export const GO_SURVEY_RESULTS_REQUEST = 'GO_SURVEY_RESULTS_REQUEST';
export const GO_SURVEY_RESULTS_SUCCESS = 'GO_SURVEY_RESULTS_SUCCESS';
export const GO_SURVEY_RESULTS_ERROR = 'GO_SURVEY_RESULTS_ERROR';
export const GO_SURVEY_RESULTS_CLEAR = 'GO_SURVEY_RESULTS_CLEAR';

export const GO_SURVEY_RESULT_DETAILS_REQUEST =
  'GO_SURVEY_RESULT_DETAILS_REQUEST';
export const GO_SURVEY_RESULT_DETAILS_SUCCESS =
  'GO_SURVEY_RESULT_DETAILS_SUCCESS';
export const GO_SURVEY_RESULT_DETAILS_ERROR = 'GO_SURVEY_RESULT_DETAILS_ERROR';
export const GO_SURVEY_DETAILS_CLEAR = 'GO_SURVEY_DETAILS_CLEAR';

export const GO_SURVEY_ANSWERS_REQUEST = 'GO_SURVEY_ANSWERS_REQUEST';
export const GO_SURVEY_ANSWERS_SUCCESS = 'GO_SURVEY_ANSWERS_SUCCESS';
export const GO_SURVEY_ANSWERS_ERROR = 'GO_SURVEY_ANSWERS_ERROR';
export const GO_SURVEY_ANSWERS_CLEAR = 'GO_SURVEY_ANSWERS_CLEAR';

import {Component} from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {styled} from '@mui/material/styles';
// import {useHistory} from 'react-router-dom';
import {ErrorPage, NoResults, Button} from 'mui-core';

const dialogOptions = {};

const StyledErrorContainer = styled(Box)(({theme}) => ({
  padding: '16px',
  textAlign: 'center',
  '& .error_label': {
    color: '#000000',
    fontSize: '18px',
    marginBottom: '6px',
  },
  '& .error_message': {
    color: 'red',
    fontSize: '16px',
    marginBottom: '12px',
  },
  '& .error_reset': {
    // fontFamily: theme.typography.fontFamilyBold,
  },
}));

const DefaultFallBack = ({error, typeOfBoundary, resetError}) => (
  <StyledErrorContainer>
    <Box className='error_label'>You have encountered an error</Box>
    <Box className='error_message'>{error?.toString()}</Box>
    {/* <Box className='error_trace'>{componentStack}</Box> */}
    {typeOfBoundary === 'sentry' && (
      <Button
        type='button'
        color='primary'
        variant='outlined'
        onClick={() => resetError()}
        className='error_reset'>
        Reset
      </Button>
    )}
  </StyledErrorContainer>
);

DefaultFallBack.prototypes = {
  resetError: PropTypes.func,
  typeOfBoundary: PropTypes.string,
};

const Fallback = ({
  error,
  componentStack,
  resetError,
  typeOfBoundary,
  showFallback = true,
  typeOfUi = 'default',
}) => {
  if (showFallback) {
    if (typeOfUi === 'fullPage') {
      return (
        <ErrorPage
          list={null}
          pageRefresh={true}
          heading='Oops! An error occurred'
          subHeading='We are constantly working to improve your GoEducate experience and apologize for any inconvenience with this disruption. Please refresh your browser to view the latest site version.'
        />
      );
    } else if (typeOfUi === 'subPage') {
      return (
        <Box sx={{width: '80%', margin: '20px auto'}}>
          <NoResults
            title='Oops! An error occured'
            subTitle='We are constantly working to improve your GoEducate experience and apologize for any inconvenience with this disruption. Please refresh your browser to view the latest site version.'
          />
        </Box>
      );
    } else if (typeOfUi === 'default') {
      return (
        <DefaultFallBack
          error={error}
          resetError={resetError}
          typeOfBoundary={typeOfBoundary}
        />
      );
    } else {
    }
  } else {
    return <></>;
  }
};

const SentryErrorBoundary = ({
  children,
  showDialog = false,
  showFallback = true,
  typeOfUi = 'default',
  nameOfComponent = 'notDefined',
}) => {
  // const {location: {pathname = 'notDefined'} = {}} = useHistory() || {};

  return (
    <Sentry.ErrorBoundary
      showDialog={showDialog}
      dialogOptions={dialogOptions}
      fallback={props => (
        <Fallback
          {...props}
          typeOfUi={typeOfUi}
          typeOfBoundary='sentry'
          showFallback={showFallback}
        />
      )}
      beforeCapture={scope => {
        // scope.setTag('location', pathname);
        scope.setTag('componentName', nameOfComponent);
      }}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

SentryErrorBoundary.propTypes = {
  children: PropTypes.node,
  typeOfUi: PropTypes.string,
  showDialog: PropTypes.bool,
  showFallback: PropTypes.bool,
  nameOfComponent: PropTypes.string,
};

class CustomErrorBoundary extends Component {
  state = {hasError: false, error: false, errorInfo: null};

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    // can send the logs to our own custom log service.
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    const {hasError, errorInfo, error} = this.state;
    if (hasError && errorInfo && error) {
      return (
        <Fallback
          error={error}
          {...this.props}
          typeOfBoundary='custom'
          componentStack={errorInfo?.componentStack}
        />
      );
    }
    return this.props.children;
  }
}

const ErrorBoundary = props => {
  const {typeOfBoundary = 'sentry'} = props || {};
  if (typeOfBoundary === 'sentry') {
    return <SentryErrorBoundary {...props} />;
  }
  return <CustomErrorBoundary {...props} />;
};

ErrorBoundary.propTypes = {
  typeOfBoundary: PropTypes.string,
};

export default ErrorBoundary;

/*
************************************************************************
* Ref Doc: //https://docs.sentry.io/platforms/javascript/guides/       *
react/enriching-events/user-feedback/#customizing-the-widget           *
*                                                                      *
*                                                                      *
*                                                                      *
************************************************************************
*/

import {Box, Divider, Stack, Typography} from '@mui/material';

const LayoutHeader = ({title, leftContent, rightContent, sx = {}}) => {
  return (
    <Box sx={sx}>
      <Stack
        direction={{xs: 'column', sm: 'row'}}
        alignItems={{sm: 'center'}}
        spacing={2}>
        <Stack
          flexGrow={1}
          direction={{xs: 'column', sm: 'row'}}
          spacing={2}
          alignItems={{sm: 'center'}}
          flexWrap={'wrap'}>
          {title && (
            <Typography variant='h3' color={'text.primary'}>
              {title}
            </Typography>
          )}
          {leftContent && <Box>{leftContent}</Box>}
        </Stack>
        {rightContent && (
          <Box sx={{mb: title ? 0 : '16px !important'}}>{rightContent}</Box>
        )}
      </Stack>
      {title && <Divider sx={{my: 2}} />}
    </Box>
  );
};
export default LayoutHeader;

import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
// Components
import {LayoutStaticBanner} from 'mui-core';
import {
  Box,
  Stack,
  styled,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';
import PlanLists from './components/PlanLists';
import ComparePlans from './components/ComparePlans';
import EmployerSignupForm from './components/EmployerSignupForm';
import EmployerVideoBanner from './components/EmployerVideoBanner';
// Utils
import {EMPLOYER_CONTACT_MAILTO, IMG_CLOUDINARY} from 'config';
import {ArrowRight, MailIcon, defaultBannerImage} from 'assets/images';
import {ForEmployer} from 'data/partner.json';

const {localTalent, sections, coloredBoxes, joinOurNetwork} = ForEmployer;
const {buttonText, imageUrl} = localTalent || {};
const {heading: joinNetworkHeading = '', logos = []} = joinOurNetwork || {};

const StyledHeading = styled(Typography)(({theme}) => ({
  '& span': {
    color: theme.palette.Gray400.main,
  },
}));

const StyledContainer = styled(Box)(({isMobile, image}) => ({
  borderRadius: '24px',
  position: 'relative',
  width: isMobile ? '320px' : '375px',
  height: isMobile ? '304px' : '417px',
  background: `url(${image})`,
  backgroundSize: 'cover',
}));

const StyledOverlay = styled(Box)(({isMobile}) => ({
  color: '#fff',
  padding: '16px',
  margin: '33px 28px',
  position: 'absolute',
  borderRadius: '12px',
  minHeight: isMobile ? '79%' : '84%',
  background: 'rgba(12, 17, 29, 0.80)',
  boxShadow:
    '0px 7px 8px -4px rgba(0, 0, 0, 0.20), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
}));

const getCloudinaryImage = name => {
  const defaultImage = 'd_no_collage_logo_sozbbx.png';
  return name
    ? encodeURI(`${IMG_CLOUDINARY}/${defaultImage}/${name}`)
    : defaultBannerImage;
};

const Employer = () => {
  const theme = useTheme();
  const {pathname, hash, key} = useLocation();
  const [infoDialog, setInfoDialog] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showSignupModal, setShowSignupModal] = useState(false);

  const handleSubmitSignupModal = () => {
    setShowSignupModal(false);
  };

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <Box
      sx={{
        '& #overview': {
          'scroll-margin-top': 100,
        },
        '& #compare_plans': {
          'scroll-margin-top': -8,
        },
        '& #pricing, #schedule_demo': {
          'scroll-margin-top': 96,
        },
      }}>
      <EmployerVideoBanner />
      <Stack gap={{xs: '32px', md: '150px'}} mt={{xs: 2, sm: 5, md: '100px'}}>
        {sections &&
          sections.map((sec, index) => (
            <Box
              key={sec.key}
              id={index === 0 ? 'overview' : `employer_${index}`}>
              <LayoutStaticBanner
                key={sec.key}
                gap={{xs: 5, md: 8}}
                button={sec.buttonLabel}
                buttonEndIcon={ArrowRight}
                imageFirst={sec.imageFirst}
                imageWidth={sec.imageWidth}
                sectionPadding={sec.sectionPadding}
                backgroundColor={sec.backgroundColor}
                textContentWidth={sec.textContentWidth}
                padding={sec.imageFirst && '0 0 0 64px'}
                buttonAction={() => setShowSignupModal(true)}
                detail={{
                  imgpath: sec.image,
                  heading: sec.title,
                  subHeading: sec.description,
                  headingColor: sec.headingColor,
                  descriptionColor: sec.descriptionColor,
                }}
              />
            </Box>
          ))}
      </Stack>
      <Container>
        <Stack
          alignItems='center'
          justifyContent='center'
          mt={{xs: 5, md: '80px'}}
          mb={{xs: 5, md: '100px'}}
          gap={{xs: '30px', md: '16px', lg: '32px'}}
          flexDirection={isMobile ? 'column' : 'row'}>
          {coloredBoxes &&
            coloredBoxes.length > 0 &&
            coloredBoxes.map((ele, i) => (
              <StyledContainer
                key={ele.heading}
                isMobile={isMobile}
                image={ele.imageUrl}>
                <StyledOverlay isMobile={isMobile} gap={{xs: 1, md: 3, lg: 7}}>
                  <Typography
                    component='h3'
                    variant='header-sm-bold'
                    dangerouslySetInnerHTML={{__html: ele.heading}}
                  />
                  <Typography
                    component='p'
                    pt={{xs: 4, md: 2}}
                    variant='text-md-regular'
                    sx={{'& span': {fontWeight: 700}}}
                    dangerouslySetInnerHTML={{__html: ele.subHeading}}
                  />
                </StyledOverlay>
              </StyledContainer>
            ))}
        </Stack>
        <Box id='pricing'>
          <PlanLists setShowSignupModal={setShowSignupModal} />
        </Box>
        <Box id='compare_plans'>
          <ComparePlans setShowSignupModal={setShowSignupModal} />
        </Box>
        <Box px={{xs: 0, md: 4}}>
          <StyledHeading
            component='h2'
            variant='header-md-bold'
            dangerouslySetInnerHTML={{__html: joinNetworkHeading}}
          />
          <Stack
            gap={6}
            display='flex'
            overflow='auto'
            alignItems='center'
            flexDirection='row'
            justifyContent='space-between'
            py={{xs: '16px', md: '50px'}}>
            {logos.map(logo => (
              <img
                alt={logo.name}
                key={logo.name}
                src={getCloudinaryImage(logo.url)}
                style={{
                  maxWidth: '240px',
                  maxHeight: '80px',
                }}
              />
            ))}
          </Stack>
        </Box>
      </Container>
      <Box id='schedule_demo' mt={{xs: 2, md: '100px'}}>
        <LayoutStaticBanner
          key='schedule_demo'
          imageFirst={false}
          button={buttonText}
          gap={{xs: 5, md: 6}}
          buttonEndIcon={MailIcon}
          sectionPadding={{xs: 2, md: 9}}
          imageWidth={{xs: '100%', md: '50%'}}
          textContentWidth={{xs: '100%', md: '50%'}}
          backgroundColor='linear-gradient(270deg, #5E92F4 0%, #A290F6 76%)'
          buttonAction={() => (window.location.href = EMPLOYER_CONTACT_MAILTO)}
          detail={{
            imgpath: imageUrl,
            headingColor: '#fff',
            descriptionColor: '#fff',
            heading: localTalent.heading,
            subHeading: localTalent.subHeading,
          }}
        />
      </Box>
      <EmployerSignupForm
        infoDialog={infoDialog}
        visible={showSignupModal}
        onSubmit={handleSubmitSignupModal}
        onClose={() => setShowSignupModal(false)}
        setInfoDialog={val => setInfoDialog(val)}
      />
    </Box>
  );
};

export default Employer;

import * as TYPES from './constants';
import {API} from 'config';
import {
  requestChangeEmail,
  recieveChangeEmail,
  changeEmailError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CHANGE_EMAIL_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CHANGE_EMAIL_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const changeEmail = (body, cb) => (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    const {student_uuid} = body || {};
    let endPoint = `${API.gps.change_email}${student_uuid}`;
    delete body['student_uuid'];
    dispatch(requestChangeEmail());
    return services
      .createUpdateRecord(token, endPoint, body, 'PATCH')
      .then(res => {
        dispatch(recieveChangeEmail(res));
        if (cb) cb(res);
      });
  } catch (err) {
    console.log(err);
    dispatch(changeEmailError(err));
  }
};

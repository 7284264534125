import {useState} from 'react';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {red} from '@mui/material/colors';
import {createField} from 'mui-core/Form';
import {doLogout} from 'redux/modules/auth/actions';
import {Box, Stack, styled, Typography} from '@mui/material';
import {deleteUserProfile} from 'redux/modules/profile/user';
import {accountManagement} from 'data/dashboard-settings.json';
import {Button, ContentBox, DialogBox, ErrorBoundary, MUIInput} from 'mui-core';

const {
  heading,
  subHeading,
  description,
  buttonLabel,
  modalPopup = {},
  deletePoints = [],
  passwordInput = {},
  deletePointsHeading,
} = accountManagement || {};

const StyledButton = styled(Button)(({theme}) => ({
  color: 'red',
  '&:hover': {
    backgroundColor: red[600],
    color: 'white',
  },
}));

const DialogContent = ({setDialogBox, isLoading, setLoading}) => {
  const form = useForm();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {handleSubmit, clearErrors, setValue, setError} = form;

  const onFinish = values => {
    setLoading(true);
    const body = {
      password: values.password,
    };

    dispatch(
      deleteUserProfile(body, success => {
        if (success.Success) {
          enqueueSnackbar(success.Success, {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
          setDialogBox(false);
          setValue('password', '');
          dispatch(doLogout(true));
          clearErrors();
        } else {
          setError('password', {
            type: 'custom',
          });
          enqueueSnackbar(success.Error, {
            variant: 'error',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        }
        setLoading(false);
      }),
    );
  };

  return (
    <Stack textAlign='center' gap={1}>
      <Typography variant='title32' color='text.primary'>
        {modalPopup?.heading}
      </Typography>
      <Typography variant='caption' color='text.primary'>
        {modalPopup?.subHeading}
      </Typography>
      <Typography variant='body2' color='text.primary'>
        {modalPopup?.description}
      </Typography>
      <form onSubmit={handleSubmit(onFinish)}>
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          justifyContent='center'>
          <Stack
            mt={3}
            mb={6}
            width='70%'
            alignItems='center'
            justifyContent='center'>
            {createField('password', {
              form,
              size: 'large',
              fullWidth: true,
              disabled: isLoading,
              label: passwordInput?.label,
              rules: {
                required: [true, passwordInput?.requiredMgs],
                custom: [true, passwordInput?.passwordDidNotMatchTxt],
              },
            })(
              <MUIInput
                variant='outlined'
                typeOfInput='password'
                label={passwordInput?.label}
                placeholder={passwordInput?.placeholder}
              />,
            )}
          </Stack>
          <Stack pb={4} gap={2} flexDirection='row' justifyContent='center'>
            <Button
              size='large'
              variant='outlined'
              loading={isLoading}
              onClick={() => setDialogBox(false)}>
              {modalPopup?.cancelButtonLabel}
            </Button>
            <StyledButton
              size='large'
              type='submit'
              color='error'
              formNoValidate
              variant='outlined'
              loading={isLoading}>
              {buttonLabel}
            </StyledButton>
          </Stack>
        </Box>
      </form>
    </Stack>
  );
};

const AccountManagement = () => {
  const [isLoading, setLoading] = useState(false);
  const [openDialog, setDialogBox] = useState(false);

  return (
    <ContentBox elevation={0} sx={{p: 4}}>
      <ErrorBoundary nameOfComponent='dashboard-account-management'>
        <Stack>
          <Typography variant='h4' color='text.primary'>
            {heading}
          </Typography>
          <Typography mt={2} variant='h6' color='text.primary'>
            {subHeading}
          </Typography>
          <Stack padding={2}>
            <Typography variant='caption' color='text.primary'>
              {deletePointsHeading}
            </Typography>
            {deletePoints.map((eachPoint, index) => {
              return (
                <Typography variant='caption' color='text.primary'>
                  {index + 1}.) {eachPoint}
                </Typography>
              );
            })}
            <Typography my={2} variant='caption' color='text.primary'>
              {description}
            </Typography>
            <Box>
              <StyledButton
                size='large'
                color='error'
                variant='outlined'
                onClick={() => setDialogBox(true)}>
                {buttonLabel}
              </StyledButton>
            </Box>
          </Stack>
        </Stack>
      </ErrorBoundary>
      {openDialog && (
        <DialogBox
          openDialog={openDialog}
          children={
            <DialogContent
              isLoading={isLoading}
              setLoading={setLoading}
              setDialogBox={setDialogBox}
            />
          }
        />
      )}
    </ContentBox>
  );
};

export default AccountManagement;

import {
  Box,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {useEffect, useState} from 'react';
import {ArrowForward} from '@mui/icons-material';
import {Button, RequestErrorLoader, themeColorMode} from 'mui-core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  getGoSurveyAnswers,
  fetchGoSurveyAnswers,
  getGoSurveyQuestions,
  fetchGoSurveyQuestions,
} from 'redux/modules/survey';
import {useDispatch, useSelector} from 'react-redux';
import {debounce} from 'lodash';
import {useUser} from 'core/hooks';

const Accordion = styled(props => (
  <MuiAccordion
    disableGutters
    TransitionProps={{timeout: 0}}
    square
    {...props}
  />
))(({theme}) => ({
  borderRadius: '20px',
  marginBottom: '28px',
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#f1f1f4',
  boxShadow: '0px 5px 24px 0px rgba(0, 0, 0, 0.08)',
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={
      <PlayArrowIcon
        sx={{
          fontSize: '0.9rem',
          color: theme => themeColorMode(theme, '#000', '#fff'),
        }}
      />
    }
    {...props}
  />
))(({theme}) => ({
  // flexDirection: 'row-reverse',
  padding: '27px 40px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const StyledChip = styled(Chip)(({theme}) => ({
  borderRadius: '3px',
  backgroundColor: themeColorMode(theme, '#000', '#666'),
  color: themeColorMode(theme, '#fff', '#fff'),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: '27px 40px',
  paddingTop: '0px',
}));

const Label = ({question}) => {
  return <Typography variant='bodyL'>{question}</Typography>;
};

const QuestionnairePage = ({saveStudentCIS}) => {
  // state
  const [answers, setAnswers] = useState({});
  const [expanded, setExpanded] = useState('1');

  // hooks
  const user = useUser();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {data: surveyQuestions, request} = useSelector(getGoSurveyQuestions);
  const {data: surveyAnswers = [], request: surveyAnswerRequest} = useSelector(
    getGoSurveyAnswers,
  );

  useEffect(() => {
    dispatch(fetchGoSurveyAnswers());
    dispatch(fetchGoSurveyQuestions());
  }, []);

  useEffect(() => {
    let answerObj = {};
    let Questions = [];
    surveyQuestions?.map(section => {
      Questions.push(...section.questions);
    });
    Questions?.forEach(question => {
      surveyAnswers?.forEach(ans => {
        if (question.id === ans.question_id) {
          answerObj[question.id] = {
            ...question,
            student_response: ans.student_response,
          };
        }
      });
    });
    setAnswers(answerObj);
  }, [surveyAnswers, surveyQuestions]);

  const scrollToSection = section_number => {
    const el = document.getElementById(`section-${section_number}`);
    if (el) {
      setTimeout(() => {
        el.scrollIntoView({behavior: 'smooth'});
      }, 200);
    }
  };

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const nextSection = section => {
    setExpanded(section);
    scrollToSection(section);
  };

  const selectAnswers = (question, student_response) => {
    if (student_response) {
      answers[question.id] = {...question, student_response};
    } else {
      if (answers[question.id]) {
        delete answers[question.id];
      } else {
        answers[question.id] = {...question, student_response: null};
      }
    }
    setAnswers({...answers});
  };

  const selectFreeTextsAnswers = (question, value, idx) => {
    if (!answers[question.id] || !answers[question.id].student_response) {
      answers[question.id] = {
        ...question,
        student_response: new Array(question.sub_questions.length).fill(''),
      };
    }
    const ans = answers?.[question.id]?.student_response;
    ans[idx] = value;
    answers[question.id].student_response = ans;
    if (ans.filter(a => a !== '').length === 0) delete answers[question.id];
    setAnswers({...answers});
  };

  const getField = question => {
    const {question_type, sub_questions} = question;
    const answer = answers?.[question.id]?.student_response || [];
    switch (question_type) {
      case 'free_texts':
        return (
          <Stack pl={1} spacing={1} pt={1}>
            {sub_questions.map((s_question, idx) => (
              <Stack
                key={s_question}
                direction={{xs: 'column', sm: 'row'}}
                spacing={2}
                alignItems={{sm: 'center'}}>
                <Box
                  sx={{
                    [theme.breakpoints.up('xs')]: {
                      minWidth: 210,
                    },
                  }}>
                  <Label question={s_question} />
                </Box>
                <OutlinedInput
                  onChange={e =>
                    selectFreeTextsAnswers(question, e.target.value, idx)
                  }
                  value={answer[idx]}
                  size='small'
                  sx={{maxWidth: 500, minWidth: '30%'}}
                  placeholder='Write your response here'
                />
              </Stack>
            ))}
          </Stack>
        );
      case 'free_text':
        console.log('OutlinedInput', answers?.[question.id]?.student_response);
        return (
          <OutlinedInput
            onChange={e => selectAnswers(question, e.target.value)}
            value={answers?.[question.id]?.student_response}
            size='small'
            sx={{maxWidth: 500}}
            placeholder='Write your response here'
          />
        );
      case 'radio_button':
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              defaultValue='female'
              value={answers?.[question.id]?.student_response}
              onChange={e => selectAnswers(question, e.target.value)}
              name='radio-buttons-group'>
              {Array.isArray(sub_questions) &&
                sub_questions.map(que => (
                  <FormControlLabel
                    key={que}
                    value={que}
                    control={
                      <Radio
                        checked={
                          answers?.[question.id]?.student_response === que
                        }
                      />
                    }
                    label={<Label question={que} />}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        );
      case 'checkbox':
        return (
          <FormGroup
            sx={{
              ['& > label']: {
                marginBottom: '11px',
              },
            }}>
            {Array.isArray(sub_questions) &&
              sub_questions.map(que => (
                <FormControlLabel
                  key={que}
                  control={
                    <Checkbox
                      onChange={e =>
                        selectAnswers(
                          question,
                          e.target.checked
                            ? [...answer, que]
                            : answer.filter(i => i !== que),
                        )
                      }
                      checked={answer.indexOf(que) !== -1}
                    />
                  }
                  label={<Label question={que} />}
                />
              ))}
          </FormGroup>
        );
    }
  };

  const showResults = () => {
    console.log('results', answers, user);
    const {data} = user || {};
    const {student_uuid} = data || {};
    const payload = [];
    Object.keys(answers).forEach(key => {
      const ans = answers[key];
      payload.push({
        question_id: ans.id,
        question: ans.question,
        student_uuid: student_uuid,
        question_type: ans.question_type,
        student_response: ans.student_response,
      });
    });
    if (typeof saveStudentCIS === 'function' && student_uuid)
      saveStudentCIS(payload);
  };
  console.log('answer', answers);

  return (
    <Container>
      <Typography variant='h3' mb={3}>
        Welcome to GoSurvey
      </Typography>
      {(request || surveyAnswerRequest) && (
        <RequestErrorLoader
          hideEmpty
          minHeight={300}
          body={{request: request || surveyAnswerRequest}}
        />
      )}
      {Array.isArray(surveyQuestions) &&
        surveyQuestions.map(section => (
          <Accordion
            key={section.section_number}
            expanded={expanded === String(section.section_number).toString()}
            onChange={handleChange(String(section.section_number).toString())}>
            <AccordionSummary
              aria-controls='panel1d-content'
              id={`section-${section.section_number}`}>
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <StyledChip label={`Section ${section.section_number}`} />
                <Typography variant='h4'>{section.section_name}</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {section.section_number !== 3 ? (
                <>
                  <Typography variant='pagedesc'>
                    {section.section_number === 1
                      ? `In this section, you'll find a comprehensive list of
                    activities that reflect various aspects of work and life.
                    From collaborating with others to solving technical
                    problems, each question is designed to help you recognize
                    your preferences and inclinations. Simply read through the
                    list and check the questions that resonate with who you are
                    and what you enjoy doing. In this section, you'll find a
                    comprehensive list of activities that reflect various
                    aspects of work and life. From collaborating with others to
                    solving technical problems.`
                      : `Here, you'll explore personal qualities that contribute to your strengths and character. From being compassionate to having good concentration skills, these questions delve into the essence of who you are. As you read through each question, check those that align with your personality traits and values.`}
                  </Typography>
                  <Typography
                    variant='pagedesc'
                    component={'div'}
                    fontWeight={600}
                    my={2}
                    mt={3}>
                    {section.section_number === 1
                      ? `For each statement, check the box if it aligns with your
                    interests and preferences.`
                      : `For each statement, check the box if it aligns with your personal qualities.`}
                  </Typography>
                  <FormGroup
                    sx={{
                      ['& > label']: {
                        marginBottom: '11px',
                      },
                    }}>
                    {Array.isArray(section.questions) &&
                      section.questions.map(question => (
                        <FormControlLabel
                          key={question.id}
                          control={
                            <Checkbox
                              onChange={() => selectAnswers(question)}
                              checked={!!answers[question.id]}
                            />
                          }
                          label={<Label question={question.question} />}
                        />
                      ))}
                  </FormGroup>
                  <Stack direction={'row'} justifyContent={'center'} mt={2}>
                    <Button
                      variant={'contained'}
                      sx={{minWidth: 160}}
                      onClick={() =>
                        nextSection(
                          (parseInt(section.section_number) + 1).toString(),
                        )
                      }
                      endIcon={<ArrowDropDownIcon />}>
                      Next Section
                    </Button>
                  </Stack>
                </>
              ) : (
                <>
                  <Typography variant='pagedesc'>
                    In this section, you'll find additional questions designed
                    to provide further clarity on your preferences, strengths,
                    and inclinations. These supplementary questions delve into
                    various aspects of your personality, work preferences, and
                    long-term career goals, empowering you to refine your career
                    interests with precision and confidence. Answering these
                    questions will help you gain a comprehensive understanding
                    of yourself, allowing you to make informed decisions about
                    your future career path.
                  </Typography>
                  <Typography
                    component={'div'}
                    variant='pagedesc'
                    fontWeight={600}
                    my={2}
                    mt={3}>
                    Only answer the questions that align with your interests and
                    preferences. Your responses help tailor the survey results.
                  </Typography>
                  <Box ml={2} mt={2}>
                    {Array.isArray(section.questions) &&
                      section.questions.map(question => (
                        <Stack spacing={1} mt={3}>
                          <Typography fontWeight={600} fontSize={16}>
                            {question.question}
                          </Typography>
                          {getField(question)}
                        </Stack>
                      ))}
                  </Box>
                  <Stack direction={'row'} justifyContent={'center'} mt={4}>
                    <Button
                      variant={'contained'}
                      onClick={showResults}
                      sx={{minWidth: 160}}>
                      View Results
                    </Button>
                  </Stack>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        ))}

      <br />
      <br />
    </Container>
  );
};

export default QuestionnairePage;

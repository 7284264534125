import {useEffect, useRef, useState} from 'react';
import Icon from './Icon';
import {themeColorMode} from 'mui-core/utils';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DoneIcon from '@mui/icons-material/Done';
const {
  Box,
  Typography,
  Stack,
  Button,
  useTheme,
  useMediaQuery,
  Zoom,
  Grow,
} = require('@mui/material');

const textPos = {
  bottom: {
    position: 'absolute',
    left: 0,
    top: '100%',
    marginTop: '20px',
    marginLeft: '80px',
  },
  right: {
    position: 'absolute',
    left: '100%',
    top: 0,
    marginTop: '130px',
    marginLeft: '20px',
    minWidth: '460px',
  },
  left: {
    position: 'absolute',
    top: 0,
    right: '100%',
    marginTop: '180px',
    marginRight: '10px',
    minWidth: '460px',
  },
  top: {
    position: 'absolute',
    bottom: '100%',
    left: '0',
    marginTop: '180px',
    marginRight: '10px',
    minWidth: '460px',
  },
};

const HighLighter = ({
  children,
  instructions,
  onNext,
  onBack,
  active: visible,
  direction = 'bottom',
  iconPosition,
  sx,
  scrollToId,
  isEnd,
  forcePosition,
  showMobile,
  showOnlyInMobile,
}) => {
  const theme = useTheme();
  const [elPositions, setElPositions] = useState({});
  const ref = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  if (showMobile && isMobile) direction = 'top';
  let active = visible ? (showMobile ? true : isMobile ? false : true) : false;
  if (showOnlyInMobile && visible && isMobile) active = true;
  useEffect(() => {
    if (active) {
      if (ref && ref.current) {
        setElPositions(ref.current.getBoundingClientRect());
      }
      const element = document.getElementById(scrollToId);
      if (element) {
        const y = element.getBoundingClientRect().top;
        if (isMobile) {
          window.scrollBy({
            top: -300,
            behavior: 'smooth',
          });
        } else {
          window.scrollBy({
            top: y - 90,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [active]);
  return (
    <Box sx={{position: 'relative', ...sx}}>
      {active && (
        <Box
          sx={{
            backgroundColor: '#000000cc',
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 700,
            transition: '1s all ease-in-out',
          }}
        />
      )}

      <Box
        ref={ref}
        sx={{
          position: 'relative',
          transform:
            active && showMobile && isMobile ? 'scale(0.86)' : 'initial',
          zIndex: active ? 702 : 'initial',
          ['& > :first-child']: {
            pointerEvents: active ? 'none' : 'initial',
            ['& button']: {
              opacity: active ? 0 : 'initial',
            },
          },
        }}>
        {children}
        {active && (
          <Grow
            timeout={500}
            style={{transformOrigin: '0 0 0'}}
            in={true}
            sx={
              forcePosition
                ? {position: 'fixed', ...forcePosition}
                : {
                    ...textPos[direction],
                    ...(showOnlyInMobile && elPositions.left
                      ? {left: -elPositions.left, width: '100vw', marginLeft: 0}
                      : {}),
                    ...(isMobile
                      ? {minWidth: '100%', marginBottom: '-20px'}
                      : {}),
                  }
            }>
            <Stack
              direction={direction === 'left' && !isMobile ? 'row' : 'column'}
              {...(isMobile ? {alignItems: 'center'} : {})}>
              {direction !== 'left' && direction !== 'top' && (
                <Icon direction={direction} iconPosition={iconPosition} />
              )}
              <Box
                sx={{
                  ml:
                    direction === 'left'
                      ? 0
                      : showOnlyInMobile && direction === 'bottom'
                      ? 0
                      : 8,
                  marginTop:
                    showOnlyInMobile && direction === 'bottom'
                      ? '21px'
                      : '-33px',
                  maxWidth: 400,
                  ...(isMobile ? {marginLeft: 0} : {}),
                }}>
                <Typography
                  color={'white'}
                  variant='h5'
                  textAlign={showOnlyInMobile ? 'center' : 'left'}>
                  {instructions}
                </Typography>
                <Stack
                  direction={'row'}
                  mt={3}
                  spacing={2}
                  justifyContent={showOnlyInMobile ? 'center' : 'initial'}>
                  {onBack && (
                    <Button
                      startIcon={<WestIcon />}
                      size='large'
                      onClick={onBack}
                      sx={{color: 'white'}}>
                      Back
                    </Button>
                  )}
                  {onNext && (
                    <Button
                      endIcon={isEnd ? <DoneIcon /> : <EastIcon />}
                      size='large'
                      variant='contained'
                      onClick={onNext}>
                      {isEnd ? 'Finish' : 'Next'}
                    </Button>
                  )}
                </Stack>
              </Box>
              {(direction === 'left' || direction === 'top') && (
                <Icon direction={direction} />
              )}
            </Stack>
          </Grow>
        )}
      </Box>
    </Box>
  );
};
export default HighLighter;

import {API} from 'config';
import * as TYPES from './constants';
import {Services} from 'core/Services';
import {
  clearInstitutionSubTypesList,
  requestInstitutionSubTypesList,
  recieveInstitutionSubTypesList,
  requestInstitutionSubTypesListError,
} from './actions';

const initialState = {
  data: [],
  error: false,
  request: false,
};
const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CLEAR_INSTITUTION_SUBTYPES_LIST:
      return initialState;
    case TYPES.REQUEST_INSTITUTION_SUBTYPES_LIST:
      return {
        ...state,
        error: false,
        request: true,
      };
    case TYPES.RECIEVE_INSTITUTION_SUBTYPES_LIST:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.REQUEST_EDUCATOR_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchInstitutionSubTypes = () => (dispatch, _getState) => {
  let endPoint = `${API.srm.institution_subtypes}`;
  dispatch(requestInstitutionSubTypesList());
  try {
    return services.fetchRecords(endPoint, null).then(res => {
      if (res) {
        dispatch(recieveInstitutionSubTypesList(res));
        return;
      }
      dispatch(requestInstitutionSubTypesListError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestInstitutionSubTypesListError(err));
  }
};

export const clearInstitutionSubTypes = () => (dispatch, getState) =>
  dispatch(clearInstitutionSubTypesList());

import Dashboard from './subModules/MyDashBoard';
import DashBoardContainer from './Container';
import {MyProfileModules} from './subModules/MyProfile';
import MySkills from './subModules/MySkills';
import MyOpportunities from './subModules/MyOpportunities';
import MyEvents from './subModules/MyEvents';
import MyCareer from './subModules/MyCareer';
import MyResume from './subModules/MyResume';
import PreviewProfile from './subModules/MyProfile/components/PreviewProfile';

const DashBoardModules = {
  Dashboard,
  DashBoardContainer,
  MyProfile: MyProfileModules.Container,
  MySkills,
  MyOpportunities,
  MyEvents,
  MyCareer,
  MyResume,
  MyProfileSubModules: MyProfileModules.subModules,
  PreviewProfile,
};

export default Dashboard;
export {DashBoardModules};

import {Accordion} from 'mui-core';
import MySavedPrograms from './MySavedPrograms';

const SavedPrograms = props => {
  // const {fetchSavedPaths} = props;
  return (
    <>
      <Accordion
        outlined={false}
        id='saved_programs'
        // onChange={fetchSavedPaths}
        header='Saved Programs'>
        <MySavedPrograms {...props} />
      </Accordion>
    </>
  );
};
export default SavedPrograms;

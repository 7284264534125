import {useState} from 'react';
import {Popover} from 'antd';

import {ErrorBoundary} from 'core/components';

const WrapText = ({text, length, className, title, isMobileView}) => {
  const [visibility, setVisibility] = useState(false);
  const isTextLengthExceeds = text.length > length;
  const wrappedText = isTextLengthExceeds
    ? text.substring(0, length) + '...'
    : text;
  const alsoCalledTextArray = text.split(', ');

  const onPopoverClick = val => setVisibility(val);

  const content = (
    <>
      <span className='highlight'>
        {title}
        <span> are also called :</span>
      </span>
      <ul className='list'>
        {alsoCalledTextArray.map((txt, idx) => (
          <li key={idx}>{txt}</li>
        ))}
      </ul>
    </>
  );
  return (
    <ErrorBoundary nameOfComponent='core-wrap-text'>
      <div className={className}>
        {wrappedText}
        {isTextLengthExceeds && (
          <Popover
            overlayClassName='popovertext'
            placement='bottomRight'
            content={content}
            visible={visibility}
            onVisibleChange={onPopoverClick}
            trigger={isMobileView ? 'click' : 'hover'}>
            <span className='bt pl-2'>
              {visibility ? 'See Less' : 'See More'}
            </span>
          </Popover>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default WrapText;

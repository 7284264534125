import {getGoBanner} from 'mui-core/utils';
import {Card, LazyImage, themeColorMode} from 'mui-core';
import {Box, Stack, Typography, alpha, styled} from '@mui/material';

const CardLayout = styled(Card)(({theme, selected}) => ({
  height: '108px',
  margin: '12px 0px',
  backgroundColor: themeColorMode(
    theme,
    theme.palette.background.paper,
    theme.palette.shadeyBlack.main,
  ),
  boxShadow:
    '0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)',
  '&:hover': {
    transform: 'none',
    boxShadow: `0px 0px 0px 1px ${themeColorMode(
      theme,
      alpha(theme.palette.primary.main, 0.7),
      'white',
    )}`,
    transition: '0.1s all',
    cursor: 'pointer',
    '& ._title': {
      textDecoration: 'underline',
    },
  },
  ...(selected
    ? {
        boxShadow: `0px 0px 0px 2px ${themeColorMode(
          theme,
          alpha(theme.palette.primary.main, 0.7),
          '#fff',
        )}`,
      }
    : {}),
}));

const CareerCard = ({data, key, onClickCard, selectedItem}) => {
  const {career_name} = data || {};
  const {occupation_details = {}} = data?.description || {};
  const {cip4_image_cloudinary = ''} = occupation_details || {};

  const image = getGoBanner(cip4_image_cloudinary);

  return (
    <CardLayout
      key={key}
      onClick={() => onClickCard(data)}
      selected={selectedItem?.career_name === career_name}>
      <Stack direction={'row'} alignItems={'center'} spacing={2.5}>
        <Box
          sx={{
            width: 108,
            height: 108,
            ['& img']: {
              width: 108,
              height: '100%',
              borderRadius: 1,
              objectFit: 'cover',
            },
          }}>
          <LazyImage src={image} defaultImage={image} alt='career-banner' />
        </Box>

        <Box>
          <Typography
            variant='h6'
            fontWeight={500}
            className='_title'
            color={'text.primary'}>
            {career_name}
          </Typography>
        </Box>
      </Stack>
    </CardLayout>
  );
};

export default CareerCard;

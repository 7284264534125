// import {HELP_WIDGET_ID} from 'config';
import {ErrorBoundary} from 'core/components';
import HelpWidgetLoad from './HelpWidgetLoad';
const HELP_WIDGET_ID = 65000000315;

const URI = `https://widget.freshworks.com/widgets/${HELP_WIDGET_ID}.js`;
const HelpWidget = () => (
  <ErrorBoundary nameOfComponent='core-help-widget' showFallback={false}>
    <HelpWidgetLoad src={URI} async={true} defer={true} />
  </ErrorBoundary>
);

export default HelpWidget;

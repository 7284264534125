import {useState} from 'react';
import {fileTypeIcon, formatBytes, bytesToMegaBytes} from 'core/utils';
import {uploadDocData} from 'data/settings.json';
import {
  Box,
  Divider,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import {Button, RequestErrorLoader, ErrorBoundary} from 'mui-core';
import {useSnackbar} from 'notistack';

const UploadFileFormats = [
  'text/csv',
  'image/bmp',
  'image/png',
  'image/jpg',
  'image/jpeg',
  'text/plain',
  'application/pdf',
  'application/xls',
  'application/msword', // doc
  'application/vnd.ms-excel', // xls
  'application/vnd.ms-excel.sheet.macroenabled.12', //xlsm
  'application/vnd.ms-excel.sheet.binary.macroenabled.12', //xlsb
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //xlsx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //docx
];

const DocUploader = ({
  title,
  viewOnly,
  profileData,
  uploadUserDocs,
  deleteUserDocs,
  maxFileSize = 10,
  noLoader,
  emptyMSg,
  type = 'related_document',
  label = uploadDocData.addDocBtn,
  description = '',
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const [isLoading, setIsLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const {data, total_size_consumed, request, error} = profileData;
  const onSuccess = name => {
    enqueueSnackbar(`${name} ${uploadDocData.uploadSuccessfullyMsg}`, {
      variant: 'success',
      anchorOrigin: {horizontal: 'center', vertical: 'top'},
    });
    setIsLoading(false);
  };
  const onDeleteSuccess = () => {
    enqueueSnackbar(uploadDocData.deleteSuccessfullyMsg, {
      variant: 'success',
      anchorOrigin: {horizontal: 'center', vertical: 'top'},
    });
    setIsLoading(false);
  };

  const handleFile = async e => {
    console.log('handleFile');
    const file = e.target.files[0];
    const FileSize = bytesToMegaBytes(file.size);
    if (
      parseFloat(FileSize) > maxFileSize ||
      parseFloat(total_size_consumed) + parseFloat(FileSize) > maxFileSize
    ) {
      enqueueSnackbar(
        uploadDocData.sizeExceedErroMsg.replace('{maxFileSize}', maxFileSize),
        {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        },
      );
      return;
    }
    if (!UploadFileFormats.includes(file.type)) {
      enqueueSnackbar(uploadDocData.AllowedFileFormatsMsg, maxFileSize, {
        variant: 'error',
        anchorOrigin: {horizontal: 'center', vertical: 'top'},
      });
      return;
    }
    setIsLoading(true);
    await uploadUserDocs({file, key: type}, onSuccess);
    setIsLoading(false);
  };

  //   const props = {
  //     showUploadList: false,
  //     beforeUpload: file => {
  //       const FileSize = bytesToMegaBytes(file.size);
  //       if (
  //         parseFloat(FileSize) > maxFileSize ||
  //         parseFloat(total_size_consumed) + parseFloat(FileSize) > maxFileSize
  //       ) {
  //         message.error(
  //           uploadDocData.sizeExceedErroMsg.replace('{maxFileSize}', maxFileSize),
  //         );
  //         return false;
  //       }
  //       if (!UploadFileFormats.includes(file.type)) {
  //         message.error(uploadDocData.AllowedFileFormatsMsg);
  //         return false;
  //       }
  //       return true;
  //     },
  //     customRequest: async ({file}) => {
  //       setIsLoading(true);
  //       await uploadUserDocs({file, key: type}, onSuccess);
  //     },
  //   };

  const deleteDoc = async docId => {
    setIsLoading(true);
    await deleteUserDocs(docId, onDeleteSuccess);
    setIsLoading(false);
  };

  const Float_Size_Consumed = parseFloat(total_size_consumed);
  const isTotalSizeReachMax =
    Float_Size_Consumed > Number(((85 / 100) * maxFileSize).toFixed(2));

  let totalSizeConsmed = 0;
  if (Float_Size_Consumed > 0) {
    totalSizeConsmed = Float_Size_Consumed;
    if (totalSizeConsmed > maxFileSize) {
      totalSizeConsmed = maxFileSize;
    }
  }

  return (
    <ErrorBoundary nameOfComponent='mod-comp-settings-myprofile-docupload'>
      <RequestErrorLoader body={{request: isLoading, error, data}} hideEmpty>
        {!viewOnly && (
          //   <div className='storage_used'>
          //     {uploadDocData.totalStorageConsumedLabel}
          //     {': '}
          //     <span style={{color: isTotalSizeReachMax ? 'red' : '#000'}}>
          //       {totalSizeConsmed}MB
          //     </span>{' '}
          //     / {maxFileSize}MB
          //   </div>
          <>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              mb={1}
              alignItems={'center'}>
              <Box>
                <Typography mr={1} variant='h5' color='text.primary'>
                  {title || 'Documents'}
                </Typography>
              </Box>
              <Stack spacing={1} direction='row'>
                <Typography color='text.primary'>
                  {uploadDocData.totalStorageConsumedLabel}:
                </Typography>
                <Typography sx={isTotalSizeReachMax ? {color: 'danger'} : {}}>
                  {totalSizeConsmed}MB
                </Typography>
                <Typography color='text.primary'>{' / '}</Typography>
                <Typography color='text.primary'>{maxFileSize}MB</Typography>
              </Stack>
            </Stack>
            {description && (
              <Box>
                <Typography variant='body2' color={'GrayText'}>
                  {description}
                </Typography>
              </Box>
            )}
          </>
        )}
        <>
          <List>
            {data &&
              Array.isArray(data) &&
              data.length > 0 &&
              data.map(({doc_name, doc_link, doc_uuid}) => {
                const Icon = fileTypeIcon(doc_link);
                return (
                  <ListItem
                    key={doc_uuid}
                    secondaryAction={
                      <>
                        {!viewOnly && (
                          <Button
                            onClick={() => deleteDoc(doc_uuid)}
                            color='primary'>
                            Delete
                          </Button>
                        )}
                      </>
                    }>
                    <ListItemAvatar>
                      <Icon style={{fontSize: 33}} />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        fontSize={isMobile ? '12px' : '17px'}
                        component={'a'}
                        sx={{textDecoration: 'underline'}}
                        href={doc_link}
                        variant='h5'
                        target='_blank'
                        download='file'>
                        {doc_name}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          {!viewOnly && (
            <>
              {data && Array.isArray(data) && data.length > 0 && <Divider />}
              <Box display={'flex'} justifyContent='center' mt={3}>
                <Button variant='contained' component='label' sx={{width: 220}}>
                  {label || 'Add Document'}
                  <input hidden type='file' onChange={handleFile} />
                </Button>
              </Box>
            </>
          )}
        </>
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default DocUploader;

import * as TYPES from './constants';
import {getItem, saveItem} from 'redux/localStorage';
import {getInputHistoryAction} from './actions';
import {uuid4} from 'core/utils';
const MAX_SAVE_ITEMS = 3;
const initialState = {
  jobs: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_INPUT_HISTORY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getInputHistory = () => async dispatch => {
  const parsedHistory = JSON.parse(
    getItem(TYPES.INPUT_HISTORY_STORAGE_NAME) || '{}',
  );
  dispatch(getInputHistoryAction(parsedHistory));
};

export const addInputHistoryItem = (data, key, duplicateCheckFunc) => async (
  dispatch,
  getState,
) => {
  if (data) data['_id'] = uuid4();
  const {general: {inputHistory = {}} = {}} = getState();
  const _data = inputHistory[key] || [];
  const existingItemIndex = _data.findIndex(
    duplicateCheckFunc ? duplicateCheckFunc : item => item.id === data.id,
  );
  let updatedData = [];
  if (existingItemIndex === -1) {
    updatedData =
      _data.length < MAX_SAVE_ITEMS
        ? [data, ..._data]
        : [data, ..._data.slice(0, -1)];
  } else {
    updatedData = _data;
  }
  saveItem(
    TYPES.INPUT_HISTORY_STORAGE_NAME,
    JSON.stringify({...inputHistory, [key]: updatedData}),
  );
  dispatch(getInputHistory());
};

export const deleteInputHistoryItem = (id, key) => async (
  dispatch,
  getState,
) => {
  const {general: {inputHistory = {}} = {}} = getState();
  const updatedData = inputHistory[key]?.filter(item => item._id !== id) || [];
  saveItem(
    TYPES.INPUT_HISTORY_STORAGE_NAME,
    JSON.stringify({...inputHistory, [key]: updatedData}),
  );
  dispatch(getInputHistory());
};

import {ThemeProvider} from '@mui/material/styles';
import {getAppTheme} from 'config/theme.config';
import {useThemeMode} from 'core/hooks';
import {useMemo} from 'react';
import {SnackbarProvider} from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
const ThemeContext = ({children}) => {
  const [themeMode] = useThemeMode();
  const theme = useMemo(() => getAppTheme(themeMode), [themeMode]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <SnackbarProvider
          maxSnack={3}
          preventDuplicate
          anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
          {children}
        </SnackbarProvider>
      </CssBaseline>
    </ThemeProvider>
  );
};
export default ThemeContext;

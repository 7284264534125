import * as TYPES from './constants';

const WIN = window;

const initialState = {
  isMobileView: WIN.innerWidth <= 768 ? true : false,
  winWidth: WIN.innerWidth,
  lang: 'en',
  themMode: 'light',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.RESET_CONFIG:
      return {
        ...state,
        isMobileView: action.payload.isMobileView,
        winWidth: action.payload.winWidth,
      };
    case TYPES.CHANGE_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
};

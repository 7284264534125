import React from 'react';

const SvgIcon = ({src, altText = 'icon', mode = 'light', sx = {}}) => {
  return (
    <img
      src={src}
      alt={altText}
      style={{
        width: '16px',
        height: '16px',
        filter: mode === 'dark' ? 'invert(1)' : 'unset',
        ...sx,
      }}
    />
  );
};

export default SvgIcon;

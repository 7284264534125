import * as TYPES from './constants';
import {API} from 'config';

import {
  requestLocalJobs,
  recieveLocalJobs,
  requestLocalJobsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LOCAL_JOBS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.LOCAL_JOBS_SUCCESS:
      if (action.loadMore) {
        return {
          ...state,
          request: false,
          error: false,
          data: {
            ...action.payload,
            data: [...state?.data?.data, ...action.payload?.data],
            datalength:
              state.data.datalength +
                (action.payload &&
                  action.payload.data &&
                  Array.isArray(action.payload.data) &&
                  action.payload.data.length) || 0,
          },
        };
      }
      return {
        ...state,
        request: false,
        error: false,
        data: {
          ...action.payload,
          datalength:
            (action.payload &&
              action.payload.data &&
              Array.isArray(action.payload.data) &&
              action.payload.data.length) ||
            0,
        },
      };
    case TYPES.CLEAR_LOCAL_JOBS:
      return {
        request: false,
        error: false,
      };
    case TYPES.LOCAL_JOBS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchLocalJobs = (reqParams, filters) => (dispatch, getState) => {
  dispatch(requestLocalJobs());
  const {
    occupation_id,
    company_name,
    sort = 'date_desc',
    page = 1,
    page_size = 25,
    location_type = 'all',
    gsearch,
    loadMore,
  } = reqParams || {};
  try {
    let endPoint = `${API.srm.opportunity}?occupation__uuid=${occupation_id}&type=job&sort_by=${sort}&location_type=${location_type}&page_size=${page_size}&page=${page}`;
    if (company_name) {
      endPoint = `${API.srm.opportunity}?company_name=${company_name}&type=job&sort_by=${sort}&location_type=${location_type}&page_size=${page_size}&page=${page}`;
    }
    if (gsearch) {
      endPoint = `${API.srm.opportunity}?gsearch=${gsearch}&type=job&sort_by=${sort}&location_type=${location_type}&page_size=${page_size}&page=${page}`;
    }
    if (filters) {
      const getFilterKeys = Object.keys(filters);
      if (getFilterKeys.length) {
        getFilterKeys.map(key => {
          if (filters[key]) {
            endPoint += `&${key}=${filters[key]}`;
          }
        });
      }
    }
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveLocalJobs(res, loadMore));
        return;
      }
      dispatch(requestLocalJobsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestLocalJobsError(err));
  }
};

import * as TYPES from './constants';
import {API} from 'config';

import {Services} from 'core/Services';
import {
  goSurveyAnswersError,
  goSurveyAnswersSuccess,
  requestGoSurveyAnswers,
} from './actions';
const services = new Services();

const initialState = {request: false, data: null, error: false};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GO_SURVEY_ANSWERS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.GO_SURVEY_ANSWERS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.GO_SURVEY_ANSWERS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.GO_SURVEY_ANSWERS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const fetchGoSurveyAnswers = () => (dispatch, getState) => {
  const {
    profile: {user},
    auth: {auth: {token = null} = {}},
  } = getState();
  const student_uuid = user?.data?.student_uuid;
  try {
    dispatch(requestGoSurveyAnswers());
    const endPoint = `${API.gps.student_cis_data}?student_response=true&student_uuid=${student_uuid}`;
    return services.fetchRecords(endPoint, token, true).then(data => {
      dispatch(goSurveyAnswersSuccess(data));
      return;
    });
  } catch (err) {
    console.log(err);
    dispatch(goSurveyAnswersError(err));
  }
};

import {noBpIcon} from 'assets/images';
import {styled} from '@mui/material/styles';
import {Box, Stack, Typography} from '@mui/material';

const StyledContainer = styled(Box)(({theme}) => ({
  border: `1px solid ${theme.palette.action.hover}`,
  borderRadius: '8px',
  padding: '18px',
}));

const StyledStack = styled(Stack)(({theme}) => ({
  border: `1px solid ${theme.palette.action.hover}`,
  backgroundColor: 'rgba(121, 96, 223, 0.08)',
  borderRadius: '8px',
  padding: '24px',
}));

const NoBusinessPartners = ({
  title = 'No Results Found',
  subTitle = 'Search for different jobs',
}) => {
  return (
    <StyledContainer>
      <StyledStack justifyContent='center' alignItems='center'>
        <img src={noBpIcon} />
        {title && (
          <Typography
            mt={2}
            variant='h6'
            fontWeight={600}
            fontSize={{xs: 12, sm: 14, md: 16}}>
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography variant='body2' fontSize={{xs: 9, sm: 12, md: 14}}>
            {subTitle}
          </Typography>
        )}
      </StyledStack>
    </StyledContainer>
  );
};

export default NoBusinessPartners;

export const getCISResults = ({survey}) => survey.carrerInterestSurvey;

export const getGoSurveyQuestions = ({survey}) => survey?.goSurveyQuestion;

export const getGoSurveyResults = ({survey}) => survey?.goSurveyResults;

export const getGoSurveyAnswers = ({survey}) => survey?.goSurveyAnswers;

export const getGoSurveyResultDetails = ({survey}) =>
  survey?.goSurveyResultDetails;

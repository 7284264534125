import React from 'react';
import UserType from './UserType';
import {Stack} from '@mui/material';
import Portfolio from './Portfolio';
import {useSelector} from 'react-redux';
import CoverLetter from './CoverLetter';
import Transcripts from './Transcripts';
import AccountAccess from './AccountAccess';
import PersonalDetails from './PersonalDetails';
import PublicProfileURL from './PublicProfileURL';
import BannerInformation from './BannerInformation';
import AccountManagement from './AccountManagement';
import {profileDataSelector} from 'redux/modules/profile';
// import SocialLinks from './SocialLinks';
// import ProfileStatus from './ProfileStatus';
// import ProfileSettings from './ProfileSettings';

const Settings = () => {
  const {data: studentData} = useSelector(profileDataSelector) || {};
  const {student_details: {social_account} = {}} = studentData || {};

  return (
    <Stack gap={2}>
      {/* <SocialLinks /> */}
      {/* <ProfileStatus /> */}
      {/* <ProfileSettings /> */}
      <PersonalDetails />
      <UserType />
      <BannerInformation />
      <Transcripts />
      <CoverLetter />
      <Portfolio />
      <PublicProfileURL />
      <AccountAccess />
      {social_account !== 'google' && <AccountManagement />}
    </Stack>
  );
};

export default Settings;

import {Accordion} from 'mui-core';
import {useCallback, useEffect, useState} from 'react';
import MyJobMatches from './MyJobMatches';

const JobMatches = props => {
  const [jobsOpen, setJobsOpen] = useState();
  useEffect(() => {
    setTimeout(() => {
      openJobs();
    }, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const openJobs = useCallback(() => {
    setJobsOpen(jobsOpen ? false : true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Accordion
      expanded={jobsOpen}
      rounded={true}
      outlined={false}
      onChange={openJobs}
      id='my_job_matches'
      header='My Job Matches'>
      {jobsOpen !== null && jobsOpen !== undefined && (
        <MyJobMatches {...props} isClubbedResults={true} />
      )}
    </Accordion>
  );
};

export default JobMatches;

import {useCallback} from 'react';
import {Button, Grid} from 'mui-core';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {SkillsSelect} from '../../components';
import SettingsModule from 'data/settings.json';
import {
  Box,
  Select,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
} from '@mui/material';
import profileData, {
  licensesAndCertifications,
  skillsData as skillsStaticData,
} from 'data/settings-profile.json';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const currentYear = new Date().getFullYear();

const getOptions = (start, end, type) => {
  const options = [];
  if (type && type === 'month') {
    for (let i = start; i <= end; i++) {
      options.push(
        <MenuItem value={i} key={i}>
          {SettingsModule.months[i - 1]}
        </MenuItem>,
      );
    }
  } else {
    for (let i = start; i <= end; i++) {
      options.push(
        <MenuItem value={i} key={i}>
          {i}
        </MenuItem>,
      );
    }
  }
  return options;
};

const {
  labelsMui: labels = {},
  placeholdersMui: placeholders = {},
  errorMessagesMui: {
    licenseName: {
      required: licenseNameRequiredError,
      maxLength: licenseNameCharLimitError,
    },
    orgName: {required: orgNameRequiredError, maxLength: orgNameCharLimitError},
    completedMonth: {required: completedMonthRequiredError},
    completedYear: {required: completedYearRequiredError},
    addNotes: {maxLength: addNotesCharLimitError},
  } = {},
} = skillsStaticData || {};
const {addLicensesBtn} = licensesAndCertifications || {};

const AddLicense = ({
  edit,
  onSubmit,
  onCancel,
  firstTime,
  skills: SkillsDataSet,
  data: {
    skills,
    comment,
    org_name,
    program_name,
    date_completed_year,
    date_completed_month,
  } = {},
}) => {
  const {data: SkillsList = []} = SkillsDataSet || {};

  const getInitialSkills = useCallback(
    skillsSaved => {
      let selectedSkills = [];
      if (
        skillsSaved &&
        Array.isArray(skillsSaved) &&
        skillsSaved.length > 0 &&
        SkillsList &&
        Array.isArray(SkillsList) &&
        SkillsList.length > 0
      ) {
        selectedSkills = SkillsList.filter(skill =>
          skillsSaved.includes(skill.name),
        );
      }
      return selectedSkills;
    },
    [SkillsList],
  );

  const form = useForm({
    defaultValues: {
      org_name: org_name || '',
      program_name: program_name || '',
      skills: getInitialSkills(skills),
      date_completed_year: date_completed_year || '',
      date_completed_month: date_completed_month || '',
    },
  });

  const {
    watch,
    control,
    handleSubmit,
    formState: {errors},
  } = form;

  const onFinish = (data, e) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const watchCompletedYear = watch(
    'date_completed_year',
    date_completed_year || null,
  );
  const watchCompletedMonth = watch(
    'date_completed_month',
    date_completed_month || null,
  );

  return (
    <Box>
      <>
        {!edit && !firstTime && (
          <Typography
            variant='h5'
            fontSize={18}
            fontWeight='600'
            title='Education'
            color='text.primary'
            // fontFamily='fontFamilyBold'
          >
            {addLicensesBtn}
          </Typography>
        )}
        <Box
          mx='auto'
          my={2}
          sx={{width: {md: '60%', sm: '60%', lg: '60%', xs: '100%'}}}>
          <form onSubmit={handleSubmit(onFinish)}>
            <Grid
              container
              spacing={4}
              display='flex'
              alignItems='center'
              flexDirection='column'
              justifyContent='center'>
              <Grid item xs={12}>
                {createField('program_name', {
                  form,
                  label: labels.nameText,
                  defaultValue: program_name || '',
                  rules: {
                    required: [true, licenseNameRequiredError],
                    maxLength: [60, licenseNameCharLimitError],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    label={labels.nameText}
                    id='license-program-name'
                    placeholder={placeholders.licenseNamePlaceHolderText}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {createField('org_name', {
                  form,
                  label: labels.issuingOrgText,
                  defaultValue: org_name || '',
                  rules: {
                    required: [true, orgNameRequiredError],
                    maxLength: [60, orgNameCharLimitError],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='license-org-name'
                    label={labels.issuingOrgText}
                    placeholder={placeholders.issuingOrgPlaceholderText}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Grid container px={0} spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={5}>
                      {createField('date_completed_month', {
                        form,
                        rules: {
                          required: [
                            watchCompletedYear,
                            completedMonthRequiredError,
                          ],
                        },
                        label: labels.completedMonthText,
                        dependent: ['date_completed_year'],
                        defaultValue: date_completed_month || '',
                      })(
                        <Select
                          fullWidth
                          MenuProps={MenuProps}
                          id='license-completed-month'
                          label={labels.completedMonthText}
                          placeholder={placeholders.completedMonth}>
                          {getOptions(1, 12, 'month')}
                        </Select>,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5}>
                      {createField('date_completed_year', {
                        form,
                        rules: {
                          required: [
                            watchCompletedMonth,
                            completedYearRequiredError,
                          ],
                        },
                        label: labels.completedYearText,
                        dependent: ['date_completed_month'],
                        defaultValue: date_completed_year || '',
                      })(
                        <Select
                          fullWidth
                          MenuProps={MenuProps}
                          id='license-completed-year'
                          label={labels.completedYearText}
                          placeholder={placeholders.completedYear}>
                          {getOptions(currentYear - 72, currentYear).reverse()}
                        </Select>,
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {createField('comment', {
                  form,
                  defaultValue: comment,
                  rules: {
                    maxLength: [500, addNotesCharLimitError],
                  },
                })(
                  <TextField
                    multiline
                    minRows={2}
                    maxRows={6}
                    error={errors?.comment}
                    label={labels.addNotesText}
                    helperText={errors?.comment?.message}
                    placeholder={placeholders.addNotesPlaceHolderText}
                  />,
                )}
              </Grid>
              {SkillsList && Array.isArray(SkillsList) && SkillsList?.length && (
                <Grid item xs={12}>
                  {createField('skills', {
                    form,
                    defaultValue: skills,
                  })(
                    <SkillsSelect
                      fullWidth
                      name='skills'
                      control={control}
                      skillColor='blueGrey'
                      skills={SkillsDataSet}
                      id='license-skills-box'
                      errors={errors?.skills}
                      label={labels.skillsText}
                      placeholder={placeholders.skillsPlaceHolderText || ''}
                    />,
                  )}
                </Grid>
              )}
              <Grid item>
                {!firstTime && (
                  <Button variant='outlined' onClick={onCancel} sx={{mr: 2}}>
                    {profileData.cancelBtnTxt}
                  </Button>
                )}
                <Button variant='contained' type='submit' formNoValidate>
                  {edit ? profileData.saveBtnTxt : profileData.addBtnTxt}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </>
    </Box>
  );
};

export default AddLicense;

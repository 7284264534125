import {useState, useMemo} from 'react';
import {Button, ContentBox, Grid, ErrorBoundary} from 'mui-core';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';
import {subModules} from './../../';
import PrivacySetting from '../PrivacySetting';
import Lists from './Lists';
import Progress from '../Progress';
import {Alert, Snackbar, Typography, Box, Stack} from '@mui/material';
import {Check} from '@mui/icons-material';
import {useUser, useAuth} from 'core/hooks';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import profileStaticData from 'data/settings-profile.json';
import {getLogo} from 'core/utils';
import {Link, useTheme, useMediaQuery} from '@mui/material';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {PDFDownloadLink} from '@react-pdf/renderer';
import profilePreviewData from 'data/settings-profilePreview.json';

import {MyProfileDocument} from 'core/components';

const ProfileNavigation = ({
  ready,
  awardTypes,
  completedTabs,
  srmAwardTypes,
  student_profile,
}) => {
  const [privacySuccessMsg, setPrivacySuccessMsg] = useState(null);
  const [openPrivacySettingModal, setOpenPrivacySettingModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const {data: userData = {}} = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const {qr_code, username = ''} = userData || {};
  const open = Boolean(anchorEl);

  const [isAuthenticated] = useAuth();

  const handleOpenPrivacySetting = () => {
    setOpenPrivacySettingModal(true);
  };
  const handleClosePrivacySetting = () => {
    setOpenPrivacySettingModal(false);
  };

  const handleDropDown = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleAfterClickClose = () => {
    setAnchorEl(null);
  };

  const renderDownload = useMemo(() => {
    return (
      isAuthenticated &&
      student_profile &&
      srmAwardTypes &&
      awardTypes &&
      userData && (
        <>
          <ErrorBoundary
            nameOfComponent='module-download-profile'
            typeOfUi='subPage'>
            {ready ? (
              <PDFDownloadLink
                document={
                  <MyProfileDocument
                    studentInfo={userData}
                    awardTypes={awardTypes}
                    srmAwardTypes={srmAwardTypes}
                    studentProfile={student_profile}
                  />
                }
                fileName='my_profile.pdf'>
                {({loading}) => (
                  <Link color='text.primary' underline='none'>
                    {loading
                      ? profilePreviewData.loadingBtnTxt
                      : profilePreviewData.downloadBtnTxt}
                  </Link>
                )}
              </PDFDownloadLink>
            ) : (
              <Link
                color='text.primary'
                underline='none'
                style={{cursor: 'not-allowed'}}>
                {profilePreviewData.downloadBtnTxt}
              </Link>
            )}
          </ErrorBoundary>
        </>
      )
    );
  }, [
    isAuthenticated,
    student_profile,
    userData,
    ready,
    awardTypes,
    srmAwardTypes,
  ]);

  const urlOrigin = `${window?.location?.origin}`;
  const qrCodeImage = qr_code && getLogo(qr_code);
  const profileUrl = `/profile/preview/${username || ''}`;

  const renderShareDropdown = (
    <Menu
      id='fade-menu'
      MenuListProps={{
        'aria-labelledby': 'fade-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleAfterClickClose}
      TransitionComponent={Fade}>
      {' '}
      <MenuItem onClick={handleAfterClickClose}>
        <CopyToClipboard
          text={`${urlOrigin}${profileUrl}`}
          onCopy={() => {
            setPrivacySuccessMsg(
              `${profileStaticData.shareProfileData.copySuccessMsg}`,
            );
          }}>
          <Typography>
            {profileStaticData.shareProfileData.copyProfileText}
          </Typography>
        </CopyToClipboard>
      </MenuItem>
      <MenuItem onClick={handleAfterClickClose}>
        <Link
          href={qrCodeImage}
          target='_blank'
          download='file'
          color='text.primary'
          underline='none'>
          {profileStaticData.shareProfileData.profileQRCodeText}
        </Link>
      </MenuItem>
      <MenuItem onClick={handleAfterClickClose} color='text.primary'>
        {renderDownload}
      </MenuItem>
    </Menu>
  );

  return (
    <Box mb={2}>
      <ContentBox variant='outlined'>
        <Stack direction={{xs: 'column', sm: 'row'}} m={3}>
          <Box flexGrow={1}>
            <Typography variant='h5' color={'text.primary'}>
              My Profile
            </Typography>
            <Typography variant='body1' color={'GrayText'}>
              Customize your profile by adding information you want others to
              see.
            </Typography>
          </Box>
          <Stack spacing={3} direction={'row'}>
            <Button
              sx={{
                fontSize: isMobile ? '9px' : '14px',
                paddingLeft: isMobile ? '0px' : '8px',
                paddingRight: isMobile ? '0px' : '8px',
              }}
              onClick={handleOpenPrivacySetting}
              // sx={{color: 'text.primary'}}
              startIcon={<SettingsIcon />}>
              Privacy Settings
            </Button>
            <Button
              startIcon={<VisibilityIcon />}
              target='_blank'
              sx={{color: 'text.primary'}}
              href={profileUrl}>
              Preview
            </Button>
            <Button
              id='fade-button'
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              sx={{color: 'text.primary'}}
              startIcon={<ShareIcon />}
              onClick={handleDropDown}>
              Share
            </Button>
            {renderShareDropdown}
          </Stack>
        </Stack>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            sm={3}
            display={'flex'}
            alignItems='center'
            justifyContent={'center'}>
            <Box>
              <Progress value={40} completedTabs={completedTabs} />
            </Box>
          </Grid>
          <Grid xs={12} sm={9}>
            <Lists subModules={subModules} completedTabs={completedTabs} />
          </Grid>
        </Grid>
      </ContentBox>

      {openPrivacySettingModal && (
        <PrivacySetting
          privacySuccessMsg={privacySuccessMsg}
          setPrivacySuccessMsg={setPrivacySuccessMsg}
          openPrivacySettingModal={openPrivacySettingModal}
          handleClosePrivacySetting={handleClosePrivacySetting}
        />
      )}
      {privacySuccessMsg && (
        <Snackbar
          autoHideDuration={5000}
          open={privacySuccessMsg}
          onClose={() => setPrivacySuccessMsg('')}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
          <Alert
            variant='filled'
            severity='success'
            icon={<Check fontSize='inherit' />}
            sx={{width: '100%', color: 'white'}}>
            {privacySuccessMsg}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default ProfileNavigation;

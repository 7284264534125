import {combineReducers} from 'redux';
import messageReducer from './message';
import statesReducer from './states';
import appConfigReducer from './appconfig';
import userInputReducer from './userInput';
import themeReducer from './theme';
import inputHistory from './inputHistory';

export const generalReducer = combineReducers({
  message: messageReducer,
  states: statesReducer,
  appConfig: appConfigReducer,
  userInput: userInputReducer,
  theme: themeReducer,
  inputHistory: inputHistory,
});

import {Accordion, ContentBox} from 'mui-core';
import {connect} from 'react-redux';
import {API} from 'config';
import PersonalInfo from './PersonalInfo';
import SocialLinks from './SocialLinks';
import {
  fetchSkills,
  fetchAwardTypes as fetchProfileAwardTypes,
  fetchSeekingData,
  fetchProfileData,
  updateProfile,
  updateUserProfile,
  uploadUserDocs,
  deleteUserDocs,
  profileDataSelector,
  awardTypesSelector,
  // About Me Section
  ethnicitySelector,
  citizenshipSelector,
  nativeLanguageSelector,
  fetchEthnicity,
  fetchCitizenship,
  fetchNativeLanguage,
  fetchStudentMetrics,
  studentCredentialsSelector,
  saveStudentCredential,
  updateStudentCredential,
  deleteStudentCredential,
  fetchStudentCredentials,
  skillsSelector,
} from 'redux/modules/profile';
import {fetchAwardTypes, getAwardTypes} from 'redux/modules/search';
import {fetchStates, getStates} from 'redux/modules/general';
import {useEffect} from 'react';
import {Box} from '@mui/material';
const ProfileDetails = ({
  fetchProfileData,
  profileData,
  nativeLanguageData,
  fetchNativeLanguage,
  ethnicityData,
  fetchEthnicity,
  updateUserProfile,
  statesList,
  fetchStates,
}) => {
  console.log(API);
  useEffect(() => {
    if (!profileData) fetchProfileData();
    if (!nativeLanguageData || !nativeLanguageData.data) fetchNativeLanguage();
    if (!ethnicityData || !ethnicityData.data) fetchEthnicity();
    if (!statesList) fetchStates();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <ContentBox variant='outlined'>
        <Accordion
          outlined={false}
          id='pers_info'
          defaultOpen
          header='Personal Information'>
          <PersonalInfo
            nativeLanguageData={nativeLanguageData}
            updateUserProfile={updateUserProfile}
            ethnicityData={ethnicityData}
            statesList={statesList}
            API={API}
          />
        </Accordion>
      </ContentBox>
      <ContentBox variant='outlined' sx={{mt: 2}}>
        <Accordion
          outlined={false}
          id='soc_links'
          defaultOpen
          header='Social Media Links'>
          <SocialLinks updateUserProfile={updateUserProfile} />
        </Accordion>
      </ContentBox>
    </>
  );
};

const mapStateToProps = state => ({
  profileData: profileDataSelector(state),
  // awardTypes: awardTypesSelector(state),
  // // About Me Section
  statesList: getStates(state).data,
  ethnicityData: ethnicitySelector(state),
  // citizenshipData: citizenshipSelector(state),
  nativeLanguageData: nativeLanguageSelector(state),
  // studentCredentials: studentCredentialsSelector(state),
  // skills: skillsSelector(state),
  // srmAwardTypes: getAwardTypes(state),
  // activePaths: getActivePath(state),
});
export default connect(mapStateToProps, {
  // fetchSkills,
  fetchProfileData,
  // fetchSeekingData,
  updateUserProfile,
  // uploadUserDocs,
  // deleteUserDocs,
  // updateProfile,
  // // About Me Section
  fetchStates,
  fetchEthnicity,
  // fetchCitizenship,
  fetchNativeLanguage,
  // fetchStudentMetrics,
  // saveStudentCredential,
  // updateStudentCredential,
  // deleteStudentCredential,
  // fetchStudentCredentials,
  // fetchProfileAwardTypes,
  // fetchAwardTypes,
  // fetchActivePaths,
})(ProfileDetails);

import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import {debounce} from '@mui/material';
let called = false;
const DebounceInput = ({value, onChange, ...props}) => {
  const [val, setVal] = React.useState(value);
  const onValChange = React.useCallback(e => {
    console.log('onValChange', e);
    setVal(e.target.value);
    called = false;
  }, []);
  const onKeyPress = React.useCallback(
    debounce(e => {
      if (onChange) onChange(e);
      called = true;
    }, 1000),
    [],
  );
  React.useEffect(() => {
    if (called) if (value !== val) setVal(value);
  }, [val, value]);
  return (
    <OutlinedInput
      value={val}
      onKeyUp={onKeyPress}
      onChange={onValChange}
      {...props}
    />
  );
};

export default DebounceInput;

import {useEffect, useMemo} from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import {Button, Grid} from 'mui-core';
import Stack from '@mui/material/Stack';
import {createField} from 'mui-core/Form';
import {useCheckMobileView} from 'core/hooks';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import OutlinedInput from '@mui/material/OutlinedInput';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import profileData, {workExperienceData} from 'data/settings-profile.json';
const {addWorkExperienceBtn} = workExperienceData || {};

const AddWorkExperience = ({
  edit,
  onSubmit,
  onCancel,
  data: {
    end_date,
    job_title,
    start_date,
    is_present,
    company_name,
    job_location,
    job_responsibilities,
  } = {},
  firstTime,
}) => {
  const form = useForm({
    defaultValues: {
      job_responsibilities: job_responsibilities?.sort(
        (a, b) => a.display_order - b.display_order,
      ) || [{job_responsibilities: ''}],
    },
  });

  const {
    watch,
    control,
    setValue,
    resetField,
    handleSubmit,
    formState: {errors},
  } = form;

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'job_responsibilities',
  });

  const {isMobileView} = useCheckMobileView();
  const watchIsPresent = watch('is_present', is_present || false);
  const watchEndDate = watch('end_date', end_date ? moment(end_date) : null);
  const watchStartDate = watch(
    'start_date',
    start_date ? moment(start_date) : null,
  );
  const isEndDateRequired = !!watchStartDate && !watchIsPresent ? true : false;

  const onFinish = (data, e) => {
    if (onSubmit && isEmpty(errors)) {
      onSubmit(data);
    }
  };

  useEffect(() => {
    if (watchIsPresent) {
      resetField('end_date');
      setValue('end_date', null);
    }
  }, [watchIsPresent]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Stack>
        {!edit && !firstTime && (
          <Typography
            variant='h5'
            fontSize={18}
            fontWeight='600'
            color='text.primary'
            // fontFamily='fontFamilyBold'
            title={addWorkExperienceBtn}>
            {addWorkExperienceBtn}
          </Typography>
        )}
        <Box
          mx='auto'
          my={2}
          sx={{width: {md: '60%', sm: '60%', lg: '60%', xs: '100%'}}}>
          <form onSubmit={handleSubmit(onFinish)}>
            <Grid
              container
              spacing={4}
              display='flex'
              alignItems='center'
              flexDirection='column'
              justifyContent='center'>
              <Grid item xs={12}>
                {createField('company_name', {
                  form,
                  defaultValue: company_name || '',
                  label: workExperienceData.company.label,
                  rules: {
                    required: [true, workExperienceData.company.requiredMsg],
                    maxLength: [60, workExperienceData.company.maxLengthMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='work-exp-comp-name'
                    label={workExperienceData.company.label}
                    placeholder={workExperienceData.company.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {createField('job_location', {
                  form,
                  defaultValue: job_location || '',
                  label: workExperienceData.location.label,
                  rules: {
                    required: [true, workExperienceData.location.requiredMsg],
                    maxLength: [60, workExperienceData.location.maxLengthMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='work-exp-job-location'
                    label={workExperienceData.location.label}
                    placeholder={workExperienceData.location.placeholder}
                  />,
                )}
              </Grid>
              <Grid container p={2}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  {createField('start_date', {
                    form,
                    dependent: ['end_date'],
                    rules: {
                      required: [
                        true,
                        workExperienceData.dateStarted.requiredMsg,
                      ],
                      maxDate: [
                        watchEndDate,
                        workExperienceData.dateStarted.maxDateMsg,
                      ],
                    },
                    defaultValue: start_date ? moment(start_date) : null,
                  })(
                    <DatePicker
                      maxDate={watchEndDate}
                      showDaysOutsideCurrentMonth
                      views={['year', 'month', 'day']}
                      label={workExperienceData.dateStarted.label}
                    />,
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6.5}>
                  <Stack
                    direction={isMobileView ? 'column' : 'row'}
                    alignItems='center'>
                    {createField('end_date', {
                      form,
                      rules: {
                        required: [
                          isEndDateRequired,
                          workExperienceData.dateFinish.requiredMsg,
                        ],
                        minDate: [
                          watchStartDate,
                          workExperienceData.dateFinish.minDateMsg,
                        ],
                      },
                      disabled: watchIsPresent,
                      dependent: ['start_date'],
                      defaultValue: end_date ? moment(end_date) : null,
                    })(
                      <DatePicker
                        minDate={watchStartDate}
                        showDaysOutsideCurrentMonth
                        views={['year', 'month', 'day']}
                        label={workExperienceData.dateFinish.label}
                      />,
                    )}
                    <Typography
                      mx={2}
                      title='Or'
                      variant='h5'
                      fontSize={18}
                      fontWeight='600'
                      color='text.primary'
                      mt={isMobileView ? 2 : 0}>
                      Or
                    </Typography>
                    {createField(`is_present`, {
                      form,
                      fullWidth: false,
                      valueField: 'checked',
                      defaultValue: is_present || false,
                    })(
                      <FormControlLabel
                        label={workExperienceData.presentTxt}
                        control={<Checkbox id='education-is-current' />}
                      />,
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {createField('job_title', {
                  form,
                  defaultValue: job_title || '',
                  label: workExperienceData.position.label,
                  rules: {
                    required: [true, workExperienceData.position.requiredMsg],
                    maxLength: [60, workExperienceData.position.maxLengthMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='work-exp-job-title'
                    label={workExperienceData.position.label}
                    placeholder={workExperienceData.position.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12} pt={2}>
                <Stack direction='column' spacing={4}>
                  {fields.map((item, index) => {
                    let currentErrorObject = {};
                    errors?.job_responsibilities?.length &&
                      errors?.job_responsibilities.forEach((errObj, idx) => {
                        if (idx === index) {
                          currentErrorObject = errObj;
                        }
                      });
                    return (
                      <Stack
                        spacing={4}
                        key={item.id}
                        direction='row'
                        alignItems='center'>
                        {/* {createField(
                          `job_responsibilities.${index}.job_responsibilities`,
                          {
                            form,
                            defaultValue: item.job_responsibilities || [],
                            label:
                              workExperienceData.roleResponsibilities.label,
                            rules: {
                              maxLength: [
                                250,
                                workExperienceData.roleResponsibilities
                                  .maxLengthMsg,
                              ],
                            },
                          },
                        )(
                          <OutlinedInput
                            variant='outlined'
                            id={`work-exp-job-responsibilities-${index}`}
                            label={
                              workExperienceData.roleResponsibilities.label
                            }
                            placeholder={
                              workExperienceData.roleResponsibilities
                                .placeholder
                            }
                          />,
                        )} */}
                        <Controller
                          control={control}
                          defaultValue={item.job_responsibilities}
                          name={`job_responsibilities.${index}.job_responsibilities`}
                          rules={{
                            maxLength: {
                              value: 250,
                              message:
                                workExperienceData.roleResponsibilities
                                  .maxLengthMsg,
                            },
                          }}
                          render={({field}) => (
                            <TextField
                              fullWidth
                              {...field}
                              key={item.id}
                              placeholder={
                                workExperienceData.roleResponsibilities
                                  .placeholder
                              }
                              helperText={
                                currentErrorObject?.job_responsibilities
                                  ?.message
                              }
                              label={
                                workExperienceData.roleResponsibilities.label
                              }
                              error={currentErrorObject?.job_responsibilities}
                            />
                          )}
                        />
                        <IconButton
                          py='auto'
                          onClick={() => {
                            remove(index);
                          }}>
                          <DeleteIcon
                            sx={{fontSize: {xs: 16, sm: 18, md: 20, lg: 24}}}
                            color='primary'
                          />
                        </IconButton>
                      </Stack>
                    );
                  })}
                  <Button
                    sx={{width: '150px', alignSelf: 'flex-end'}}
                    onClick={() => {
                      append({job_responsibilities: ''});
                    }}>
                    + Add Role
                  </Button>
                </Stack>
              </Grid>
              <Grid item>
                {!firstTime && (
                  <Button variant='outlined' onClick={onCancel} sx={{mr: 2}}>
                    {profileData.cancelBtnTxt}
                  </Button>
                )}
                <Button variant='contained' type='submit' formNoValidate>
                  {edit ? profileData.saveBtnTxt : profileData.addBtnTxt}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default AddWorkExperience;

import {Table} from 'antd';
import moment from 'moment';
import {RequestErrorLoader} from 'core/components';

import {employOpportData} from 'data/occupation.json';

const DataGrid = ({
  page,
  type,
  total,
  onChange,
  initialData,
  occupation_name,
  history,
}) => {
  const onTitleClick = (value, instName, job_source, job_type) => {
    //const BasePath = getModuleBasePath('occupation');
    let path = `/programs/${encodeURIComponent(value)}/${encodeURIComponent(
      instName,
    )}?type=${type}`;

    if (job_source === 'local') {
      path = `/opportunity/local/${encodeURIComponent(
        value,
      )}/${encodeURIComponent(instName)}?job_type=${job_type}`;
    }

    if (job_source === 'cos') {
      path = `/opportunity/board/${value}`;
    }

    if (occupation_name) {
      path = path.concat(
        `${
          type === 'local_jobs' || type === 'job_boards' ? '?' : '&'
        }related_occupation=${encodeURIComponent(occupation_name)}`,
      );
    }

    // window.open(`${path}`, '_blank').focus();
    history.push(path);
  };

  const GridColumns = [
    {
      title:
        type === 'local_jobs' || type === 'job_boards'
          ? employOpportData.jobTitleGridTxt
          : employOpportData.programTitleGridTxt,
      dataIndex: 'title',
      key: 'program_title',

      render: (
        text,
        {
          job_id,
          company,
          job_type,
          job_source,
          job_title = '-',
          institute_details = {},
        },
      ) => {
        const ID = job_source === 'cos' ? job_id : job_title;
        const label = job_title;

        const institution_name = job_source !== 'local' ? null : company || '-';
        return (
          <span
            className='program-job-title'
            onClick={() =>
              onTitleClick(ID, institution_name, job_source, job_type)
            }
            // style={{ pointerEvents: type === 'job_boards' ? 'none' : 'initial' }}
          >
            {label}
          </span>
        );
      },
    },
    {
      title: employOpportData.companyGridTxt,
      key: 'company',
      render: (text, record) => {
        const {institution_name} = (record && record.institute_details) || {};
        //const {company_name} = (record && record.business_partner) || {};
        const {company} = record || {};

        const Name = (company ? company : '-') || institution_name;

        return <span>{Name}</span>;
      },
    },
    {
      title: employOpportData.locationGridTxt,
      key: 'location',
      render: (text, record) => {
        const {city, state, location_type, location = '-'} = record || {};

        const region =
          city || state
            ? `${city ? city + ', ' : ''}${state ? state : ''}`
            : '-';
        const locationValue = location;
        return <span>{locationValue}</span>;
      },
    },
    {
      title: employOpportData.datePostedGridTxt,
      dataIndex: 'date_posted',
      key: 'date_posted',
      render: (text, record) => {
        const {
          created_on,

          date_posted,
          AccquisitionDate,
          parent_job_posting_date,
        } = record || {};
        const date =
          created_on ||
          date_posted ||
          AccquisitionDate ||
          parent_job_posting_date;

        let displayDate = '-';
        if (date) {
          // check for valid ISO String.......................
          if (moment(date, moment.ISO_8601, true).isValid()) {
            displayDate = moment(date).format('MM/DD/YYYY');
          } else {
            // Additional check is rrquired for the cross-browser fix
            //Note:: regex require to work in Safari browser
            const dateToIso = new Date(
              Date.parse(date.replace(/-/g, '/')),
            ).toISOString();
            displayDate = moment(dateToIso).format('MM/DD/YYYY');
          }
        }

        return <span>{displayDate}</span>;
      },
    },
  ];

  const GridData =
    (initialData &&
      initialData.data &&
      initialData.data.data &&
      Array.isArray(initialData.data.data) &&
      initialData.data.data) ||
    [];

  return (
    <div className='employment-grid'>
      <Table
        loading={{
          indicator: <RequestErrorLoader body={{request: true}} />,
          spinning: initialData && initialData.request,
        }}
        dataSource={GridData}
        columns={GridColumns}
        pagination={
          total > 25
            ? {
                current: page,
                defaultCurrent: 1,
                showSizeChanger: false,
                defaultPageSize: 25,
                pageSize: 25,
                total: total,
                onChange: onChange,
              }
            : false
        }
      />
    </div>
  );
};

export default DataGrid;

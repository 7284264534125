export const getOccupations = state =>
  state.occupation && state.occupation.OccupationSearch;

export const getOccupationDetails = state =>
  state.occupation && state.occupation.OccupationDetails;

export const getJobZoneDetails = state =>
  state.occupation && state.occupation.JobZoneDetails;

export const getJobOpportunities = state =>
  state.occupation && state.occupation.JobOpportunities;

export const getOccupationRoles = state =>
  state.occupation && state.occupation.OccupationRoles;

export const getSubscribedOccupation = state =>
  state.occupation && state.occupation.SubscribeOccupation;

export const getSavedOpportunities = state =>
  state.occupation && state.occupation.SavedOpportunity;

export const getAppliedOpportunities = state =>
  state.occupation && state.occupation.AppliedOpportunity;

export const getNewOpportunities = state =>
  state.occupation && state.occupation.NewOpportunity;

export const getLocationSearch = state => state.occupation && state.occupation;

export const getMyOpportunities = state =>
  state.occupation && state.occupation.MyOpportunities;

export const getFitAssessmentSurvey = state =>
  state.occupation && state.occupation.FitAssessmentSurvey;

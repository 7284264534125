import {Box, OutlinedInput, Stack, TextField} from '@mui/material';
import {createField} from 'mui-core/Form';
import {Button} from 'mui-core';
import {useState} from 'react';

const AddInterests = ({onCancel, onSubmit, interest}) => {
  const [value, setValue] = useState('');
  const exists =
    interest &&
    Array.isArray(interest) &&
    interest.map(i => i.toLowerCase()).indexOf(value && value.toLowerCase()) !==
      -1;
  return (
    <Box display={'flex'} justifyContent='center' mt={3}>
      <Box sx={{width: '80%'}}>
        <TextField
          value={value}
          error={exists}
          variant={'outlined'}
          label={'Personal Interests'}
          onChange={e => setValue(e.target.value)}
          fullWidth
          helperText={exists && 'Already exists'}
        />
        <Stack direction={'row'} mt={2} justifyContent={'center'} spacing={1}>
          <Button sx={{minWidth: 200}} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit(value)}
            sx={{minWidth: 200}}
            disabled={!value || exists}
            variant={'contained'}>
            Add
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
export default AddInterests;

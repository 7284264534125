import {Modal, Button} from 'antd';
import './styles.less';

// visible = modal visibility
// onClose = backdrop and close button event
// onOk = OK button event
// icon = cloudinary or local image src adresss, if not specified it will hide
// title = title of the modal
// description = description of the modal in array (supports html tags) (all array items printed as seperated lines)
// buttonText = center ok button text

const ConfirmModal = ({
  visible = false,
  onCancel,
  onOk,
  title = '',
  description = [],
  okText = 'Yes',
  cancelText = 'No',
}) => {
  return (
    <Modal
      wrapClassName='confirm-modal-wrap'
      visible={visible}
      onCancel={onCancel}
      footer={null}
      className='confirm-modal'>
      <div className='d-content'>
        {title && <div className='ttl'>{title}</div>}
        <br />
        {Array.isArray(description) &&
          description.length > 0 &&
          description.map(desc => (
            <>
              <div
                className='desc'
                key={desc}
                dangerouslySetInnerHTML={{__html: desc}}>
                {/* {desc} */}
              </div>
            </>
          ))}
        <div className='actions-btns'>
          {cancelText && (
            <Button
              type='primary'
              className='actn-btn cancel-btn'
              onClick={onCancel}>
              {cancelText}
            </Button>
          )}
          {okText && (
            <Button type='primary' className='actn-btn ok-btn' onClick={onOk}>
              {okText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;

import React from 'react';
import {Image, Text, View, StyleSheet} from '@react-pdf/renderer';
import {pdfaward, pdfarrow} from 'assets/images';

import Divider from './Divider';

const styles = StyleSheet.create({
  mapView: {
    flexDirection: 'column',
    width: '100%',
  },
  allMapContainer: {
    padding: 10,
  },
  mapOuterContainer: {
    flexDirection: 'column',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#4abaed',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 10,
    paddingTop: 5,
    paddingBottom: 0,
    marginBottom: 10,
    marginTop: 10,
    minHeight: 150,
  },
  mapInnerContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  mapLabel: {
    fontSize: 10,
    paddingBottom: 5,
  },
  courseBlock: {
    flexDirection: 'column',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#EAEDF1',
    width: '24%',
    minHeight: 130,
    marginBottom: 10,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    fontSize: 10,
    paddingTop: 5,
  },
  courseInfo: {
    paddingTop: 10,
    minHeight: 80,
    position: 'relative',
    flexDirection: 'column',
    flexGrow: 1,
  },
  courseTag: {
    backgroundColor: '#F5F5F5',
    borderLeftWidth: 3,
    borderLeftStyle: 'solid',
    borderLeftColor: '#DE4279',
    padding: 3,
    position: 'absolute',
    color: '#000000',
  },
  courseID: {
    fontSize: 10,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    color: '#333333',
  },
  courseUnits: {
    fontSize: 10,
    marginTop: 10,
    marginBottom: 10,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#A0A4B5',
  },
  courseTitle: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#EAEDF1',
    backgroundColor: '#F9F8FF',
    minHeight: 50,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
    paddingLeft: 3,
    paddingRight: 3,
  },
  nodata: {
    flexDirection: 'row',
    fontSize: 10,
    backgroundColor: '#F9F8FF',
    padding: 20,
    minHeight: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  courseCompletionBlock: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    height: 150,
  },
  arrowBlock: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
});

const CourseBlock = ({data}) => {
  const courseType = data && data.course_type;
  const borderColor =
    courseType === 'core'
      ? '#00AEF0'
      : courseType === 'general'
      ? '#DE4279'
      : '#FFB40A';
  const tagTitle =
    courseType === 'core'
      ? 'Core'
      : courseType === 'general'
      ? 'General Ed'
      : 'Elective';

  return (
    <View style={styles.courseBlock} wrap={false}>
      <View style={styles.courseInfo}>
        <View
          style={[
            styles.courseTag,
            {
              borderLeftColor: borderColor,
            },
          ]}>
          <Text>{tagTitle}</Text>
        </View>
        <View style={styles.courseID}>
          <Text>{data && data.course_id}</Text>
        </View>
        <View style={styles.courseUnits}>
          <Text>{`${(data && parseFloat(data.units)) || '0'} Unit(s)`}</Text>
        </View>
      </View>
      <View style={styles.courseTitle}>
        <Text>{(data && data.course_name) || (data && data.title)}</Text>
      </View>
    </View>
  );
};

const CourseContainer = ({data, index}) => {
  let totalUnits = 0;
  data &&
    data.courses &&
    data.courses.length &&
    data.courses.map(course => {
      if (course && parseFloat(course.units)) {
        totalUnits += parseFloat(course.units);
      }
    });
  return (
    <View
      key={`map-${index}`}
      break={data?.courses?.length > 20}
      wrap={data?.courses?.length < 20 ? false : true}>
      <View
        style={[
          styles.mapOuterContainer,
          {
            borderColor:
              data && data.courses && !data.courses.length
                ? '#EAEDF1'
                : '#4abaed',
            backgroundColor:
              data && data.courses && !data.courses.length
                ? '#F9F8FF'
                : 'white',
          },
        ]}
        wrap={data && data.courses && data.courses.length > 0 ? true : false}>
        <Text style={styles.mapLabel} wrap={false}>{`Term ${index + 1} - ${
          data && data.term_name
        } (${totalUnits && totalUnits.toFixed(1)} Units)`}</Text>
        <View style={styles.mapInnerContainer}>
          {(data &&
            data.courses &&
            data.courses.length > 0 &&
            data.courses.map((course, idx) => (
              <CourseBlock data={course} key={`course-block-${index}-${idx}`} />
            ))) || (
            <View style={styles.nodata} wrap={false}>
              <Text>No courses have been planned for this term.</Text>
            </View>
          )}
        </View>
      </View>
      <View style={styles.arrowBlock}>
        <Image
          src={`${pdfarrow}?noCache=${Math.random().toString()}`}
          source={{
            header: {
              'Access-Control-Allow-Origin': '*',
            },
          }}
          style={{
            height: 25,
            width: 10,
          }}
        />
      </View>
    </View>
  );
};

const CourseCompletionAward = ({data}) => (
  <View style={styles.courseCompletionBlock} wrap={false}>
    <Image
      src={`${pdfaward}?noCache=${Math.random().toString()}`}
      source={{
        header: {
          'Access-Control-Allow-Origin': '*',
        },
      }}
      style={{
        height: 80,
        width: 80,
      }}
    />
    <View
      style={{
        fontSize: 10,
      }}>
      <Text>{data && data.award_type_name}</Text>
    </View>
  </View>
);

export default ({data, path}) => {
  return (
    <View style={styles.mapView} break>
      <Divider title='MAP VIEW' />
      <View style={styles.allMapContainer}>
        {data &&
          data.terms &&
          data.terms.length > 0 &&
          data.terms.map((term, index) => (
            <CourseContainer
              data={term}
              index={index}
              key={`map-container-${index}`}
            />
          ))}
        <CourseCompletionAward data={path} />
      </View>
    </View>
  );
};

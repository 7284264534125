// ROP List
export const REQUEST_ROP_LIST = 'REQUEST_ROP_LIST';
export const RECEIVE_ROP_LIST = 'RECEIVE_ROP_LIST';
export const REQUEST_ROP_LIST_ERROR = 'REQUEST_ROP_LIST_ERROR';
export const CLEAR_ROP_LIST = 'CLEAR_ROP_LIST';

export const REQUEST_EXIST_STATUS_CHECK = 'REQUEST_EXIST_STATUS_CHECK';
export const RECEIVE_EXIST_STATUS_CHECK = 'RECEIVE_EXIST_STATUS_CHECK';
export const REQUEST_EXIST_STATUS_CHECK_ERROR =
  'REQUEST_EXIST_STATUS_CHECK_ERROR';

export const REQUEST_BUSINESS_SIGNUP = 'REQUEST_BUSINESS_SIGNUP';
export const RECEIVE_BUSINESS_SIGNUP = 'RECEIVE_BUSINESS_SIGNUP';
export const REQUEST_BUSINESS_SIGNUP_ERROR = 'REQUEST_BUSINESS_SIGNUP_ERROR';

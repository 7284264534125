export const profileDataSelector = ({profile: {profileData}}) => profileData;
export const seekingDataSelector = ({profile: {seekingData}}) => seekingData;
export const awardTypesSelector = ({profile: {awardTypes}}) => awardTypes;
export const skillsSelector = ({profile: {skills}}) => skills;
export const studentSkillsSelector = ({profile: {studentSkills}}) =>
  studentSkills;
export const ethnicitySelector = ({profile: {ethnicity}}) => ethnicity;
export const citizenshipSelector = ({profile: {citizenship}}) => citizenship;
export const nativeLanguageSelector = ({profile: {nativeLanguage}}) =>
  nativeLanguage;
export const getUser = ({profile: {user}}) => user;
export const getOccupationInterest = ({profile: {occupationInterest}}) =>
  occupationInterest;
export const studentCredentialsSelector = ({profile: {studentCredentials}}) =>
  studentCredentials;
export const studentMetricsSelector = ({profile: {studentMetrics}}) =>
  studentMetrics;
export const getPrivacySetting = ({profile: {privacysettinginfo}}) =>
  privacysettinginfo;
export const getDashboardPrograms = state => state.profile.programs;
export const getUserTypeList = ({profile: {userTypelist}}) => userTypelist;

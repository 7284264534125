import {useAuth} from 'core/hooks';
import {connect} from 'react-redux';
import {styled} from '@mui/material/styles';
import {useHistory} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {openLoginScreen} from 'redux/modules/auth';
import {Stack, Button, useMediaQuery, useTheme} from '@mui/material';

const ProfileNavigatorStyle = styled(Stack)(({theme}) => ({
  margin: 'auto',
  maxWidth: '84%',
  marginTop: '20px',
  padding: '14px 0px',
  borderRadius: '4px',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  '& .btnStyle': {
    width: '160px',
    borderRadius: '15px',
    padding: '4px 12px 2px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {backgroundColor: theme.palette.secondary.dark},
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    padding: '12px',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '90%',
    padding: '12px 24px',
  },
}));

const RibbonNavigator = ({openLoginScreen, heading, btnTxt}) => {
  const theme = useTheme();
  const [token] = useAuth();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const openProfile = () => {
    const path = '/dashboard';
    const navigate = history.push;
    token
      ? navigate(path)
      : openLoginScreen({
          callback: () => navigate(path),
        });
  };
  return (
    <ProfileNavigatorStyle
      spacing={1}
      direction={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'center' : 'space-evenly'}>
      <Typography variant='h6' color={'white'} textAlign='center'>
        {heading}
      </Typography>
      <Button size='small' className='btnStyle' onClick={openProfile}>
        <Typography variant='contained' color={'white'}>
          {btnTxt}
        </Typography>
      </Button>
    </ProfileNavigatorStyle>
  );
};
const mapStateToProps = state => ({});
export default connect(mapStateToProps, {openLoginScreen})(RibbonNavigator);

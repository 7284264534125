import * as TYPES from './constants';
import {API} from 'config';

import {
  requestJobOpportunities,
  recieveJobOpportunities,
  requestJobOpportunitiesError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.JOB_OPPORUNITIES_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.JOB_OPPORUNITIES_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLEAR_JOB_OPPORUNITIES:
      return {
        request: false,
        error: false,
      };
    case TYPES.JOB_OPPORUNITIES_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchJobOpportunities = keyword => (dispatch, getState) => {
  try {
    if (keyword) {
      dispatch(requestJobOpportunities());
      let endPoint = `${API.srm.occupations}?occupation_name=${keyword}`;
      return services
        .fetchRecords(`${endPoint}&data=state`, null, true)
        .then(response => {
          if (response) {
            // fetch map image data...
            if (
              response.occupation_details &&
              response.occupation_details.check_out_my_state &&
              response.occupation_details.check_out_my_state.constructor ===
                Object &&
              Object.keys(response.occupation_details.check_out_my_state)
                .length !== 0
            ) {
              return services
                .fetchRecords(`${endPoint}&file=map`, null, true, true)
                .then(mapresult => {
                  return {
                    ...response,
                    map_image_data:
                      mapresult && mapresult.substring(2, mapresult.length - 1),
                  };
                });
            }
            return response;
          }
          return response;
        })
        .then(result => {
          if (result) {
            dispatch(recieveJobOpportunities(result));
            return;
          }
          dispatch(requestJobOpportunitiesError(result));
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestJobOpportunitiesError(err));
  }
};

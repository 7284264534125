import React from 'react';
import AutoComplete from 'mui-core/AutoComplete';

const AutoCompleteWrapper = props => {
  const {
    form,
    onValueSelect,
    fieldName,
    multiple = false,
    keyNameIfMultiple, // if the payload needs the structure in object format eg: skills: {skill: 'python'}.
    required = false, //  Set 'required' to 'true' for dirty tracking and validation; include 'rules' in 'createField' for error handling.
    variantSize: size = 'medium', // size props is not working, so renamed it to variantSize
  } = props;
  const {
    setValue,
    formState: {errors},
  } = form;
  const handleSearch = (_, value, r) => {
    console.log(_, value, r);
    if (r === 'clear') {
      setValue(fieldName, null);
    }
  };

  const handleSelect = (event, value, reason) => {
    if (onValueSelect) onValueSelect(value, reason);
    if (reason === 'clear') {
      setValue(fieldName, null);
    }
    // If pressed enter
    if (reason === 'createOption') {
      setValue(
        fieldName,
        multiple
          ? value.map(item => {
              return {
                [keyNameIfMultiple]: item[keyNameIfMultiple]
                  ? item[keyNameIfMultiple]
                  : item[fieldName],
              };
            })
          : value[fieldName],
        required
          ? {
              shouldValidate: true,
              shouldDirty: true,
            }
          : {},
      );
    }
    //  If selected from an option
    if (value) {
      setValue(
        fieldName,
        multiple
          ? value.map(item => {
              return {
                [keyNameIfMultiple]: item[keyNameIfMultiple]
                  ? item[keyNameIfMultiple]
                  : item[fieldName],
              };
            })
          : value[fieldName],
        required
          ? {
              shouldValidate: true,
              shouldDirty: true,
            }
          : {},
      );
    }
  };

  return (
    <AutoComplete
      {...props}
      size={size}
      onChange={handleSelect}
      errors={errors[fieldName]}
      onInputChange={handleSearch}
    />
  );
};

export default AutoCompleteWrapper;

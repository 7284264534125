import {
  Box,
  Grid,
  Radio,
  Stack,
  Divider,
  RadioGroup,
  OutlinedInput,
  Typography,
  FormControlLabel,
} from '@mui/material';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {AutoCompleteWrapper, LocationSearch, FilterDrawer} from 'mui-core';

import apprenticeshipStaticData from 'data/apprenticeshipsList.json';

const {
  filterForm: {labels, placeholders, datePostedOptions, apprenticeshipTypes},
} = apprenticeshipStaticData || {};

const FilterForm = ({
  filters,
  onFinish,
  nearbyMSA,
  isDrawerOpen,
  industryList,
  onDrawerClose,
  onFilterReset,
  initialFilters,
  hideCompanyFilter,
}) => {
  const form = useForm();

  const {reset, handleSubmit, setValue, getValues} = form;

  const onResetClick = () => {
    const resetObj = {
      company: '',
      job_type: null,
      no_of_days: null,
      company_industry_sector: '',
      location: initialFilters?.location || '',
    };
    onFilterReset(resetObj);
    reset(resetObj);
  };

  const handleLocationChange = value => {
    setValue('location', value.location);
  };

  const onInputChange = value => {
    setValue('location', value.location);
  };

  const companyField = createField('company', {
    form,
    label: labels.company,
    defaultValue: getValues('company') || '',
  })(
    <OutlinedInput label={labels.company} placeholder={placeholders.company} />,
  );

  return (
    <Stack>
      <FilterDrawer
        onClose={onDrawerClose}
        onReset={onResetClick}
        openFilter={isDrawerOpen}
        handleSubmit={handleSubmit(onFinish)}>
        <Stack spacing={3}>
          {createField('location', {
            form,
            defaultValue: filters?.location || '',
          })(
            <LocationSearch
              calledFromForm
              onBlur={null}
              onClear={null}
              onEnterPress={() => {}}
              LocationOptions={nearbyMSA}
              onInputChange={onInputChange}
              onLocationChange={handleLocationChange}
              filters={{location: getValues('location')}}
            />,
          )}

          {createField('company_industry_sector', {
            form,
            defaultValue: getValues('company_industry_sector') || '',
          })(
            <AutoCompleteWrapper
              form={form}
              label={labels.industry}
              fieldName='company_industry_sector'
              placeholder={placeholders.industry}
              value={getValues('company_industry_sector') || ''}
              options={
                industryList &&
                Array.isArray(industryList) &&
                industryList.length > 0
                  ? industryList.map(item => {
                      return {
                        ...item,
                        key: item.id,
                        label: item.industry_name,
                        company_industry_sector: item.industry_name,
                      };
                    })
                  : []
              }
            />,
          )}

          {!hideCompanyFilter && companyField}
          <Box>
            <Typography mb={1} variant='h5' color='text.primary'>
              {labels.datePosted}
            </Typography>
            <Grid container>
              {createField('no_of_days', {
                form,
                defaultValue: getValues('no_of_days') || null,
              })(
                <RadioGroup row>
                  {datePostedOptions.map((item, index) => (
                    <Grid item xs={6} md={6} key={index}>
                      <FormControlLabel
                        value={item.value}
                        label={item.label}
                        control={<Radio />}
                      />
                    </Grid>
                  ))}
                </RadioGroup>,
              )}
            </Grid>
          </Box>

          <Divider />
          <Box>
            <Typography mb={1} variant='h5' color='text.primary'>
              {labels.apprenticeshipType}
            </Typography>
            <Grid container>
              {createField('job_type', {
                form,
                defaultValue: getValues('job_type') || null,
              })(
                <RadioGroup row>
                  {apprenticeshipTypes.map((item, index) => (
                    <Grid item xs={6} md={6} key={index}>
                      <FormControlLabel
                        value={item.value}
                        label={item.label}
                        control={<Radio />}
                      />
                    </Grid>
                  ))}
                </RadioGroup>,
              )}
            </Grid>
          </Box>
        </Stack>
      </FilterDrawer>
    </Stack>
  );
};

export default FilterForm;

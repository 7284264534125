import isString from 'lodash/isString';
import {Controller} from 'react-hook-form';
import {styled} from '@mui/material/styles';
import {useCheckMobileView} from 'core/hooks';
import {TextField, Popper, FormControl} from '@mui/material';
import {grey, green, yellow, blueGrey} from '@mui/material/colors';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import ListboxComponent from '../../../MySkills/PersonalSkill/AddSkills/ListboxComponent';

const StyledPopper = styled(Popper)({
  zIndex: 1400,
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default function SkillsSelect({
  disabled = false,
  placeholder,
  skillColor,
  fullWidth,
  control,
  errors,
  skills,
  label,
  name,
  id,
}) {
  const {data: skillsData = [], request} = skills || {};
  const {isMobileView} = useCheckMobileView();
  const getSkillColor = () => {
    switch (skillColor) {
      case 'green': {
        return {
          backgroundColor: green[400],
          color: 'background.paper',
        };
      }
      case 'yellow': {
        return {
          backgroundColor: yellow[400],
          color: grey[900],
        };
      }
      case 'blueGrey': {
        return {
          backgroundColor: blueGrey[400],
          color: 'background.paper',
        };
      }
      default: {
        return {
          backgroundColor: 'primary',
          color: 'white',
        };
      }
    }
  };

  return (
    <FormControl fullWidth={fullWidth} error={errors}>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({field: {onChange, value = []}}) => {
          return (
            <Autocomplete
              id={id}
              multiple
              fullWidth
              disableListWrap
              loading={request}
              disabled={disabled}
              value={value || []}
              options={skillsData}
              disableCloseOnSelect
              filterSelectedOptions
              PopperComponent={StyledPopper}
              ListboxComponent={ListboxComponent}
              onChange={(_event, item) => onChange(item)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              renderOption={(props, option, state) => [
                props,
                option,
                state.index,
              ]}
              renderInput={params => (
                <TextField
                  {...params}
                  label={label}
                  error={errors}
                  placeholder={placeholder}
                  helperText={errors?.message}
                />
              )}
              getOptionLabel={option =>
                option?.name
                  ? option.name
                  : option && isString(option)
                  ? option
                  : ''
              }
              ChipProps={{
                size: isMobileView ? 'small' : 'medium',
                sx: getSkillColor(),
              }}
            />
          );
        }}
      />
    </FormControl>
  );
}

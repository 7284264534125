import React, {useEffect} from 'react';
import moment from 'moment';
import {isArray} from 'lodash';
import {useUser} from 'core/hooks';
import {PATTERN} from 'core/regex';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {useDispatch, useSelector} from 'react-redux';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {Button, Grid, ContentBox, RequestErrorLoader} from 'mui-core';
import {
  Box,
  styled,
  Select,
  useTheme,
  MenuItem,
  Typography,
  useMediaQuery,
  OutlinedInput,
} from '@mui/material';

import {fetchStates, getStates} from 'redux/modules/general';
import {
  fetchEthnicity,
  fetchProfileData,
  ethnicitySelector,
  updateUserProfile,
} from 'redux/modules/profile';
import {personalDetails} from 'data/dashboard-settings.json';

const StyledBox = styled(Box)(() => ({
  maxWidth: 750,
  '& .MuiFormHelperText-root': {
    lineHeight: 1.2,
  },
}));

const PersonalDetails = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const {data: userData, request: userDataRequest} = useUser();
  const {data: statesList = []} = useSelector(getStates) || {};
  const {data: ethnicityList = []} = useSelector(ethnicitySelector) || {};
  const {last_name, first_name, ethnicity, dob, phone_number, gender, address} =
    userData || {};
  const {city, state} = address || {};

  //form
  const form = useForm();
  const {handleSubmit, clearErrors} = form;

  const {enqueueSnackbar} = useSnackbar();
  // const {data: ethnicityList} = ethnicityData;

  useEffect(() => {
    if (!userData) dispatch(fetchProfileData());
    if (statesList?.length === 0) dispatch(fetchStates());
    if (ethnicityList?.length === 0) dispatch(fetchEthnicity());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data = {}) => {
    const formData = {
      gender: data.gender,
      last_name: data.last_name,
      first_name: data.first_name,
      phone_number: data.phone_number,
    };

    if (data.dob) {
      try {
        formData['dob'] = moment(data.dob).format('YYYY-MM-DD');
      } catch (e) {
        console.error('dob catch');
      }
    }
    if (data.city && data.state) {
      const state =
        isArray(statesList) &&
        statesList.length > 0 &&
        statesList.find(i => i.name === data.state);
      if (state?.state_uuid) {
        formData.address = {
          city: data.city,
          state: state.state_uuid,
        };
      }
    } else {
      formData.address = {
        city: null,
        state: null,
      };
    }
    if (data.ethnicity) {
      let ethnicity =
        ethnicityList && isArray(ethnicityList) && ethnicityList.length > 0
          ? ethnicityList.find(item => item.name === data.ethnicity)
          : null;
      if (ethnicity?.ethnicity_uuid) {
        formData['ethnicity'] = ethnicity.ethnicity_uuid;
      } else formData['ethnicity'] = null;
    } else formData['ethnicity'] = null;

    dispatch(
      updateUserProfile(formData, success => {
        if (success)
          enqueueSnackbar(personalDetails.successMsg, {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        else
          enqueueSnackbar(personalDetails.errorMsg, {
            variant: 'error',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
      }),
    );
  };

  const clearAddressErrors = () => {
    clearErrors('city');
    clearErrors('state');
  };

  return (
    <ContentBox elevation={0} sx={{p: 4}}>
      <Typography variant='h4' color='text.primary' mb={1}>
        {personalDetails.heading}
      </Typography>
      <RequestErrorLoader
        body={{data: userData, request: userDataRequest}}
        hideEmpty>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledBox pt={2}>
            <Grid container rowSpacing={{xs: 3, sm: 4}} columnSpacing={2}>
              <Grid xs={12} sm={6}>
                {createField('first_name', {
                  label: personalDetails.form.firstName.label,
                  form,
                  rules: {
                    required: [
                      true,
                      personalDetails.form.firstName.requiredMsg,
                    ],
                  },
                  defaultValue: first_name || '',
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='outlined-basic'
                    label={personalDetails.form.firstName.label}
                  />,
                )}
              </Grid>
              <Grid xs={12} sm={6}>
                {createField('last_name', {
                  label: personalDetails.form.lastName.label,
                  form,
                  rules: {
                    required: [true, personalDetails.form.lastName.requiredMsg],
                  },
                  defaultValue: last_name || '',
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='outlined-basic'
                    label={personalDetails.form.lastName.label}
                  />,
                )}
              </Grid>
              <Grid xs={12} sm={12} md={4.5}>
                {createField('city', {
                  label: personalDetails.form.city.label,
                  form,
                  rules: {
                    validate: [
                      (value, formValues) =>
                        !value
                          ? !formValues.state
                            ? clearAddressErrors()
                            : false
                          : true,
                      personalDetails.form.city.requiredMsg,
                    ],
                  },
                  defaultValue: city,
                })(
                  <OutlinedInput
                    id='outlined-basic'
                    label={personalDetails.form.city.label}
                    variant='outlined'
                  />,
                )}
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                {createField('state', {
                  label: personalDetails.form.state.label,
                  form,
                  rules: {
                    validate: [
                      (value, formValues) =>
                        !value
                          ? !formValues.city
                            ? clearAddressErrors()
                            : false
                          : true,
                      personalDetails.form.state.requiredMsg,
                    ],
                  },
                  defaultValue: state,
                })(
                  <Select
                    variant='outlined'
                    label={personalDetails.form.state.label}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    sx={{
                      fontSize: isMobile ? '12px' : '15px',
                    }}>
                    <MenuItem value='' key='none'>
                      None
                    </MenuItem>
                    {statesList &&
                      isArray(statesList) &&
                      statesList.length > 0 &&
                      statesList.map(item => (
                        <MenuItem value={item.name} key={item.state_uuid}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>,
                )}
              </Grid>
              <Grid xs={6} sm={6} md={3.5}>
                {createField('dob', {
                  form,
                  rules: {
                    required: [
                      true,
                      personalDetails.form.dateOfBirth.requiredMsg,
                    ],
                  },
                  defaultValue: dob ? moment(dob) : dob,
                })(
                  <DatePicker
                    label={personalDetails.form.dateOfBirth.label}
                    maxDate={moment()}
                  />,
                )}
              </Grid>
              <Grid xs={6} sm={4} md={4}>
                {createField('gender', {
                  label: personalDetails.form.gender.label,
                  form,
                  defaultValue: gender,
                })(
                  <Select
                    variant='outlined'
                    label={personalDetails.form.gender.label}
                    sx={{
                      fontSize: isMobile ? '12px' : '15px',
                    }}>
                    {Object.keys(personalDetails.form.gender.options).map(
                      option => {
                        const genderJson = personalDetails.form.gender.options;
                        return (
                          <MenuItem key={genderJson[option]} value={option}>
                            {genderJson[option]}
                          </MenuItem>
                        );
                      },
                    )}
                  </Select>,
                )}
              </Grid>
              <Grid xs={12} sm={4} md={4}>
                {createField('ethnicity', {
                  label: personalDetails.form.raceEthnicity.label,
                  form,
                  defaultValue: ethnicity || '',
                })(
                  <Select
                    variant='outlined'
                    label={personalDetails.form.raceEthnicity.label}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    sx={{
                      fontSize: isMobile ? '12px' : '15px',
                    }}>
                    {ethnicityList &&
                      isArray(ethnicityList) &&
                      ethnicityList.length > 0 &&
                      ethnicityList
                        .sort((a, b) => a.display_order - b.display_order)
                        .map(
                          (item, index) =>
                            item && (
                              <MenuItem value={item.name} key={index}>
                                {item.name}
                              </MenuItem>
                            ),
                        )}
                  </Select>,
                )}
              </Grid>
              <Grid xs={12} sm={4} md={4}>
                {createField('phone_number', {
                  label: personalDetails.form.myPhoneNumber.label,
                  form,
                  rules: {
                    pattern: [
                      PATTERN?.Phone,
                      personalDetails.form.myPhoneNumber.requiredMsg,
                    ],
                  },
                  defaultValue: phone_number,
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='outlined-basic'
                    label={personalDetails.form.myPhoneNumber.label}
                    placeholder={personalDetails.form.myPhoneNumber.placeholder}
                  />,
                )}
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={{xs: 3, sm: 6}}
              columnSpacing={3}
              mt={{xs: 2, sm: 1}}>
              <Grid>
                <Button
                  type='submit'
                  color='primary'
                  formNoValidate
                  variant='outlined'
                  loading={userDataRequest}
                  sx={{fontSize: {xs: '11px', sm: '14px'}}}>
                  {personalDetails.saveBtn}
                </Button>
              </Grid>
            </Grid>
          </StyledBox>
        </form>
      </RequestErrorLoader>
    </ContentBox>
  );
};

export default PersonalDetails;

// PATH DETAILS
export const PATH_DETAILS_REQUEST = 'PATH_DETAILS_REQUEST';
export const PATH_DETAILS_SUCCESS = 'PATH_DETAILS_SUCCESS';
export const PATH_DETAILS_ERROR = 'PATH_DETAILS_ERROR';

// OCCUPATIONS
export const OCCUPATIONS_REQUEST = 'OCCUPATIONS_REQUEST';
export const OCCUPATIONS_SUCCESS = 'OCCUPATIONS_SUCCESS';
export const OCCUPATIONS_ALL_SUCCESS = 'OCCUPATIONS_ALL_SUCCESS';
export const OCCUPATIONS_ERROR = 'OCCUPATIONS_ERROR';
export const OCCUPATIONS_CLEAR = 'OCCUPATIONS_CLEAR';
export const OCCUPATIONS_ALL_CLEAR = 'OCCUPATIONS_ALL_CLEAR';

// MY ACTIVE PATHS
export const ACTIVE_PATHS_REQUEST = 'ACTIVE_PATHS_REQUEST';
export const ACTIVE_PATHS_SUCCESS = 'ACTIVE_PATHS_SUCCESS';
export const ACTIVE_PATHS_ERROR = 'ACTIVE_PATHS_ERROR';

// MY ACTIVE PATH DATA
export const ACTIVE_PATH_DATA_REQUEST = 'ACTIVE_PATH_DATA_REQUEST';
export const ACTIVE_PATH_DATA_SUCCESS = 'ACTIVE_PATH_DATA_SUCCESS';
export const ACTIVE_PATH_DATA_ERROR = 'ACTIVE_PATH_DATA_ERROR';

// DELETE ACTIVE PATH
export const DELETE_ACTIVE_PATH_REQUEST = 'DELETE_ACTIVE_PATH_REQUEST';
export const DELETE_ACTIVE_PATH_SUCCESS = 'DELETE_ACTIVE_PATH_SUCCESS';
export const DELETE_ACTIVE_PATH_ERROR = 'DELETE_ACTIVE_PATH_ERROR';

// COURSES
export const COURSES_REQUEST = 'COURSES_REQUEST';
export const COURSES_SUCCESS = 'COURSES_SUCCESS';
export const COURSES_ERROR = 'COURSES_ERROR';

// MY PLAN
export const MY_PLAN_REQUEST = 'MY_PLAN_REQUEST';
export const MY_PLAN_SUCCESS = 'MY_PLAN_SUCCESS';
export const MY_PLAN_ERROR = 'MY_PLAN_ERROR';
export const MY_PLAN_CLEAR = 'MY_PLAN_CLEAR';

// RECOMMENDED PLAN
export const RECOMMENDED_PLAN_REQUEST = 'RECOMMENDED_PLAN_REQUEST';
export const RECOMMENDED_PLAN_SUCCESS = 'RECOMMENDED_PLAN_SUCCESS';
export const RECOMMENDED_PLAN_ERROR = 'RECOMMENDED_PLAN_ERROR';
export const CLEAR_RECOMMENDED_PLAN = 'CLEAR_RECOMMENDED_PLAN';

// GEN ED OPTIONS
export const GEN_ED_OPTIONS_REQUEST = 'GEN_ED_OPTIONS_REQUEST';
export const GEN_ED_OPTIONS_SUCCESS = 'GEN_ED_OPTIONS_SUCCESS';
export const GEN_ED_OPTIONS_ERROR = 'GEN_ED_OPTIONS_ERROR';
export const CLEAR_GEN_ED_OPTIONS = 'CLEAR_GEN_ED_OPTIONS';

// COMPARE PATHS
export const ADD_COMPARE_PATH_REQUEST = 'ADD_COMPARE_PATH_REQUEST';
export const ADD_COMPARE_PATH_ERROR = 'ADD_COMPARE_PATH_ERROR';
export const ADD_COMPARE_PATH_SUCCESS = 'ADD_COMPARE_PATH_SUCCESS';
export const REMOVE_COMPARE_PATH = 'REMOVE_COMPARE_PATH';
export const REMOVE_ALL_COMPARE_PATH = 'REMOVE_ALL_COMPARE_PATH';

// Get Saved Comparison Details
export const SAVED_COMPARISON_PATHS_REQUEST = 'SAVED_COMPARISON_PATHS_REQUEST';
export const SAVED_COMPARISON_PATHS_SUCCESS = 'SAVED_COMPARISON_PATHS_SUCCESS';
export const SAVED_COMPARISON_PATHS_ERROR = 'SAVED_COMPARISON_PATHS_ERROR';

// Get Compare Paths List
export const COMPARE_PATHS_LIST_REQUEST = 'COMPARE_PATHS_LIST_REQUEST';
export const COMPARE_PATHS_LIST_SUCCESS = 'COMPARE_PATHS_LIST_SUCCESS';
export const COMPARE_PATHS_LIST_ERROR = 'COMPARE_PATHS_LIST_ERROR';

// Delete Comparison Path
export const DELETE_COMPARED_PATH_REQUEST = 'DELETE_COMPARED_PATH_REQUEST';
export const DELETE_COMPARED_PATH_SUCCESS = 'DELETE_COMPARED_PATH_SUCCESS';
export const DELETE_COMPARED_PATH_ERROR = 'DELETE_COMPARED_PATH_ERROR';

// Save Comparison Paths
export const SAVE_COMPARISON_PATHS_REQUEST = 'SAVE_COMPARISON_PATHS_REQUEST';
export const SAVE_COMPARISON_PATHS_SUCCESS = 'SAVE_COMPARISON_PATHS_SUCCESS';
export const SAVE_COMPARISON_PATHS_ERROR = 'SAVE_COMPARISON_PATHS_ERROR';

// ONBOARD TYPES
export const ONBOARD_TYPE_REQUEST = 'ONBOARD_TYPE_REQUEST';
export const ONBOARD_TYPE_SUCCESS = 'ONBOARD_TYPE_SUCCESS';
export const ONBOARD_TYPE_ERROR = 'ONBOARD_TYPE_ERROR';
export const ONBOARD_TYPE_CLEAR = 'ONBOARD_TYPE_CLEAR';

// STUDENT ONBOARD
export const STUDENT_ONBOARD_REQUEST = 'STUDENT_ONBOARD_REQUEST';
export const STUDENT_ONBOARD_SUCCESS = 'STUDENT_ONBOARD_SUCCESS';
export const STUDENT_ONBOARD_ERR = 'STUDENT_ONBOARD_ERR';
export const STUDENT_ONBOARD_CLEAR = 'STUDENT_ONBOARD_CLEAR';

// SAVE PATH
export const REQUEST_SAVE_PATH_POST = 'REQUEST_SAVE_PATH_POST';
export const RECIEVE_SAVE_PATH_POST = 'RECIEVE_SAVE_PATH_POST';
export const SAVE_PATH_POST_ERROR = 'SAVE_PATH_POST_ERROR';
export const REQUEST_SAVE_PATH_GET = 'REQUEST_SAVE_PATH_GET';
export const RECIEVE_SAVE_PATH_GET = 'RECIEVE_SAVE_PATH_GET';
export const SAVE_PATH_GET_ERROR = 'SAVE_PATH_GET_ERROR';
export const REQUEST_SAVE_PATH_REMOVE = 'REQUEST_SAVE_PATH_REMOVE';
export const RECIEVE_SAVE_PATH_REMOVE = 'RECIEVE_SAVE_PATH_REMOVE';
export const SAVE_PATH_REMOVE_ERROR = 'SAVE_PATH_REMOVE_ERROR';
export const CLEAR_SAVED_PATHS = 'CLEAR_SAVED_PATHS';

// PATHWAY OPPORTUNITIES
export const PATHWAY_OPPORTUNITY_REQUEST = 'PATHWAY_OPPORTUNITY_REQUEST';
export const PATHWAY_OPPORTUNITY_RECEIVE = 'PATHWAY_OPPORTUNITY_RECEIVE';
export const PATHWAY_OPPORTUNITY_LIST_RECEIVE =
  'PATHWAY_OPPORTUNITY_LIST_RECEIVE';
export const PATHWAY_OPPORTUNITY_ERROR = 'PATHWAY_OPPORTUNITY_ERROR';
export const RESET_PATHWAY_OPPROTUNITY = 'RESET_PATHWAY_OPPROTUNITY';

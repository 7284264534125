import * as TYPES from './constants';

// Business Partner List
export const requestBusinessPartners = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNERS,
});
export const recieveBusinessPartners = json => ({
  type: TYPES.RECIEVE_BUSINESS_PARTNERS,
  payload: json,
});
export const requestBusinessPartnersError = err => ({
  type: TYPES.REQUEST_BUSINESS_PARTNERS_ERROR,
  err,
});
export const clearBusinessPartners = () => ({
  type: TYPES.CLEAR_BUSINESS_PARTNERS,
});
export const recieveAllBusinessPartners = json => ({
  type: TYPES.RECIEVE_ALL_BUSINESS_PARTNERS,
  payload: json,
});

// Business Partner Details
// Event List
export const requestBusinessPartnerDetails = () => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_DETAILS,
});
export const recieveBusinessPartnerDetails = json => ({
  type: TYPES.RECIEVE_BUSINESS_PARTNER_DETAILS,
  payload: json,
});
export const requestBusinessPartnerDetailsError = err => ({
  type: TYPES.REQUEST_BUSINESS_PARTNER_DETAILS_ERROR,
  err,
});
export const clearBusinessPartnerDetails = () => ({
  type: TYPES.CLEAR_BUSINESS_PARTNER_DETAILS,
});

// Become Business Partner
export const requestToBecomeBusinessPartner = () => ({
  type: TYPES.REQUEST_TO_BECOME_BUSINESS_PARTNER,
});
export const receiveBecomeBusinessPartnerResponse = json => ({
  type: TYPES.RECEIVE_BECOME_BUSINESS_PARTNER_RESPONSE,
  payload: json,
});
export const requestToBecomeBusinessPartnerError = error => ({
  type: TYPES.REQUEST_TO_BECOME_BUSINESS_PARTNER_ERROR,
  payload: error,
});
export const clearBecomeBusinessPartnerResponse = () => ({
  type: TYPES.CLEAR_BECOME_BUSINESS_PARTNER_RESPONSE,
});

// Industry List Actions
export const requestIndustryList = () => ({
  type: TYPES.REQUEST_INDUSTRY_LIST,
});
export const receiveIndustryList = json => ({
  type: TYPES.RECEIVE_INDUSTRY_LIST,
  payload: json,
});
export const requestIndustryListError = err => ({
  type: TYPES.REQUEST_INDUSTRY_LIST_ERROR,
  err,
});
export const clearIndustryList = () => ({
  type: TYPES.CLEAR_INDUSTRY_LIST,
});

//nonProfit companies List
export const clearNonProfitCompanyList = () => ({
  type: TYPES.CLEAR_NONPROFIT_COMPANY_LIST,
});
export const requestNonProfitCompanyList = () => ({
  type: TYPES.REQUEST_NONPROFIT_COMPANY_LIST,
});
export const recieveNonProfitCompanyList = json => ({
  type: TYPES.RECIEVE_NONPROFIT_COMPANY_LIST,
  payload: json,
});
export const recieveAllNonProfitCompanyList = json => ({
  type: TYPES.RECIEVE_ALL_NONPROFIT_COMPANY_LIST,
  payload: json,
});
export const requestNonProfitCompanyListError = err => ({
  type: TYPES.REQUEST_NONPROFIT_COMPANY_LIST_ERROR,
  err,
});

// Non profit SubTypes List
export const requestNonProfitSubTypesList = () => ({
  type: TYPES.REQUEST_NON_PROFIT_SUBTYPES_LIST,
});
export const recieveNonProfitSubTypesList = json => ({
  type: TYPES.RECIEVE_NON_PROFIT_SUBTYPES_LIST,
  payload: json,
});
export const requestNonProfitSubTypesListError = err => ({
  type: TYPES.REQUEST_NON_PROFIT_ERROR,
  err,
});

//nonProfit companies Details
export const clearNonProfitCompanyDetails = () => ({
  type: TYPES.CLEAR_NONPROFIT_COMPANY_DETAILS,
});
export const requestNonProfitCompanyDetails = () => ({
  type: TYPES.REQUEST_NONPROFIT_COMPANY_DETAILS,
});
export const recieveNonProfitCompanyDetails = json => ({
  type: TYPES.RECIEVE_NONPROFIT_COMPANY_DETAILS,
  payload: json,
});
export const requestNonProfitCompanyDetailsError = err => ({
  type: TYPES.REQUEST_NONPROFIT_COMPANY_DETAILS_ERROR,
  err,
});

//nonProfit company Members List
export const clearNonProfitCompanyMembersList = () => ({
  type: TYPES.CLEAR_NONPROFIT_COMPANY_MEMBERSLIST,
});
export const requestNonProfitCompanyMembersList = () => ({
  type: TYPES.REQUEST_NONPROFIT_COMPANY_MEMBERSLIST,
});
export const recieveNonProfitCompanyMembersList = json => ({
  type: TYPES.RECIEVE_NONPROFIT_COMPANY_MEMBERSLIST,
  payload: json,
});
export const recieveNonProfitCompanyAllMembersList = json => ({
  type: TYPES.RECIEVE_NONPROFIT_COMPANY_ALL_MEMBERSLIST,
  payload: json,
});
export const requestNonProfitCompanyMembersListError = err => ({
  type: TYPES.REQUEST_NONPROFIT_COMPANY_MEMBERSLIST_ERROR,
  err,
});

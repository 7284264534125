import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import {Close} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import {InterestRating} from 'data/occupation.json';

const DeleteBar = ({barDialogClose, selectedValue, open, barDialogConfirm}) => {
  const {
    removeInterestRating: {title: header = '', description = ''},
  } = InterestRating;
  const {idx, lbl} = selectedValue;

  return (
    <Dialog open={open} maxWidth='sm' fullWidth={true} onClose={barDialogClose}>
      <DialogTitle
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'>
        <Typography
          margin={0}
          variant='h5'
          gutterBottom
          fontSize={24}
          fontWeight={600}
          color='text.primary'
          // fontFamily='fontFamilyBold'
        >
          {header}
        </Typography>
        <IconButton aria-label='close' onClick={barDialogClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{height: '100px'}}>
        <Typography
          color='text.primary'
          variant='h6'
          textAlign='center'
          // fontFamily='fontFamilyBold'
          fontSize={14}>
          {description}
        </Typography>
        <Typography
          color='#de4279'
          variant='h6'
          textAlign='center'
          fontSize={14}>
          {selectedValue && selectedValue}&nbsp;?
        </Typography>
      </DialogContent>

      <DialogActions spacing={3} sx={{padding: '8px 32px 32px'}}>
        <Button
          color='midGray'
          variant='contained'
          onClick={() => barDialogClose()}>
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => barDialogConfirm({idx, lbl})}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
DeleteBar.prototype = {
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  barDialogClose: PropTypes.func,
  barDialogConfirm: PropTypes.func,
};
export default DeleteBar;

import {API} from 'config';
import * as TYPES from './constants';
import {Services} from 'core/Services';
import {
  requestNonProfitSubTypesList,
  recieveNonProfitSubTypesList,
  requestNonProfitSubTypesListError,
} from './actions';

const initialState = {
  data: [],
  error: false,
  request: false,
};
const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_NON_PROFIT_SUBTYPES_LIST:
      return {
        ...state,
        error: false,
        request: true,
      };
    case TYPES.RECIEVE_NON_PROFIT_SUBTYPES_LIST:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.REQUEST_NON_PROFIT_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchNonProfitSubTypes = () => (dispatch, _getState) => {
  let endPoint = `${API.srm.non_profit_subtypes}`;
  dispatch(requestNonProfitSubTypesList());
  try {
    return services.fetchRecords(endPoint, null).then(res => {
      if (res) {
        dispatch(recieveNonProfitSubTypesList(res));
        return;
      }
      dispatch(requestNonProfitSubTypesListError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestNonProfitSubTypesListError(err));
  }
};

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestCareerSearch,
  recieveCareerSearch,
  requestCareerSearchError,
  clearCareerSearch as clearCareerSearchData,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CAREER_SEARCH_REQUEST:
      return {
        ...state,
        error: false,
        request: true,
      };
    case TYPES.CAREER_SEARCH_SUCCESS:
      const {data, page_details} = action.payload;
      return {
        ...state,
        error: false,
        page_details,
        request: false,
        data: [...(state.data ? state.data : []), ...data],
      };
    case TYPES.CAREER_SEARCH_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CAREER_SEARCH_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchCareerSearch = (keyword, pageNo, filters) => (
  dispatch,
  getState,
) => {
  dispatch(requestCareerSearch());
  try {
    let endPoint = `${API.srm.occupations}?gsearch=${keyword}&page_size=12&page=${pageNo}`;
    if (filters && Array.isArray(filters)) {
      filters.map(
        ({type, title}) => (endPoint = `${endPoint}&${type}=${title}`),
      );
    }
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveCareerSearch(res));
        return;
      }
      dispatch(requestCareerSearchError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestCareerSearchError(err));
  }
};

export const clearCareerSearch = () => (dispatch, getState) => {
  dispatch(clearCareerSearchData());
};

import {useState, useMemo} from 'react';
import {FmdGood} from '@mui/icons-material';
import {useHistory} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Box, Stack} from '@mui/material';
import SurveyDetails from './SurveyDetails';
import Typography from '@mui/material/Typography';
import {
  Grid,
  Button,
  Accordion,
  CareerCard,
  ErrorBoundary,
  CareerInterestResults,
} from 'mui-core';

const StyledBtn = styled(Box)(({theme}) => ({
  marginBottom: '20px',
  '& .surveyBtn:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}));
const CardList = ({clusterData}) => {
  const history = useHistory();

  const navigateSurvey = () => {
    let url = `/career-survey/my-career-survey?reset=true`;
    history.push(url);
  };
  const cardInfo = useMemo(() => {
    return clusterData && clusterData.length > 0 && Array.isArray(clusterData)
      ? clusterData
      : [][clusterData];
  });
  return (
    <ErrorBoundary nameOfComponent='core-career-interest-survey'>
      {/* <Stack direction='row' justifyContent='space-between' alignItems='start'>
        <Box>
          <Typography
            varient='h5'
            fontSize='18px'
            // fontFamily='fontFamilyBold'
          >
            Top Career Matches
          </Typography>

          <Typography varient='Body1' fontSize='16px'>
            Select a Career Area to discover related programs of study,
            educational pathways, and careers.
          </Typography>
        </Box>
        <StyledBtn>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0px',
            }}>
            <Button
              onClick={navigateSurvey}
              variant='outlined'
              size='medium'
              className='surveyBtn'>
              <Typography fontSize='16px'>Retake Survey</Typography>
            </Button>
          </Box>
        </StyledBtn>
      </Stack> */}

      {/* <Grid container spacing={2} direction='row'>
        {cardInfo
          ?.map(
            (cluster, idx) =>
              cluster.rank < 4 && (
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Box>
                    <CareerCard
                      key={cluster?.uuid || idx}
                      data={cluster}
                      enableNavigation
                      config={{
                        type: 'cluster',
                      }}
                    />
                  </Box>
                </Grid>
              ),
          )
          .filter(Boolean)}
      </Grid> */}
      {/* <Accordion
        defaultOpen
        outlined={true}
        icon={
          <FmdGood
            fontSize='large'
            sx={{
              color: 'white',
              padding: '6px',
              borderRadius: '4px',
              marginRight: '10px',
              backgroundColor: 'primary.main',
            }}
          />
        }
        header='Survey Details'> */}
      <CareerInterestResults
        retakeSurvey={false}
        clusters={clusterData?.data}
        // surveyAnswers={careerInterest?.answers}
      />
      {/* <SurveyDetails clusterData={clusterData} /> */}
      {/* </Accordion> */}
    </ErrorBoundary>
  );
};

export default CardList;

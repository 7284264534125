import * as TYPES from './constants';
import {API} from 'config';

import {Services} from 'core/Services';
import {
  goSurveyQuestionError,
  goSurveyQuestionSuccess,
  requestGoSurveyQuestion,
} from './actions';
const services = new Services();

const initialState = {request: false, data: null, error: false};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GO_SURVEY_QUESTIONS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.GO_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.GO_SURVEY_QUESTIONS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

const formatQuestions = data => {
  if (Array.isArray(data)) {
    const questions = [];
    const sections = {};
    data.forEach(question => {
      if (!sections[question.section_number]) {
        sections[question.section_number] = {
          section_name: question.section_name,
          section_number: question.section_number,
          questions: [question],
        };
      } else {
        sections[question.section_number]['questions'].push(question);
      }
    });
    Object.keys(sections).forEach(sectionKey => {
      const section = sections[sectionKey];
      questions.push(section);
    });
    return questions;
  }
};

export const fetchGoSurveyQuestions = () => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  try {
    dispatch(requestGoSurveyQuestion());
    const endPoint = `${API.gps.cis_questions}`;
    return services.fetchRecords(endPoint, token, true).then(data => {
      const questions = formatQuestions(data);
      dispatch(goSurveyQuestionSuccess(questions));
      return;
    });
  } catch (err) {
    console.log(err);
    dispatch(goSurveyQuestionError(err));
  }
};

import {isObject, isEmpty} from 'lodash';
import * as TYPES from './constants';
import {API} from 'config';

import {
  requestAwardTypes,
  recieveAwardTypes,
  requestAwardTypesError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.AWARD_TYPES_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.AWARD_TYPES_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.AWARD_TYPES_CLEAR:
      return {
        ...state,
        request: false,
        error: false,
        data: null,
      };
    case TYPES.AWARD_TYPES_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchAwardTypes = (params, all) => (dispatch, getState) => {
  const {search: {awardTypes: {request} = {}} = {}} = getState();
  try {
    if (!request && params && isObject(params) && !isEmpty(params)) {
      dispatch(requestAwardTypes());
      const endPoint = `${API.srm.acheivement_options}?${
        params.type
      }=${encodeURIComponent(params.value)}`;
      return services.fetchRecords(endPoint).then(res => {
        if (res && Array.isArray(res)) {
          dispatch(recieveAwardTypes(res));
          return;
        }
        dispatch(requestAwardTypesError(res));
      });
    }
    if (all) {
      dispatch(requestAwardTypes());
      const endPoint = `${API.srm.acheivement_options}`;
      return services.fetchRecords(endPoint).then(res => {
        if (res && Array.isArray(res)) {
          dispatch(recieveAwardTypes(res));
          return;
        }
        dispatch(requestAwardTypesError(res));
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestAwardTypesError(err));
  }
};

import * as TYPES from './constants';
import {API} from 'config';
import {
  clearROPList,
  requestROPList,
  receiveROPList,
  requestROPListError,
} from './actions';
import {Services} from 'core/Services';

const services = new Services();

const initialState = {
  data: null,
  error: false,
  request: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_ROP_LIST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECEIVE_ROP_LIST:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_ROP_LIST_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.CLEAR_ROP_LIST:
      return initialState;
    default:
      return state;
  }
};

export const fetchROPList = () => dispatch => {
  dispatch(requestROPList());

  const endPoint = `${API.srm.institute}?get_all_rop=true`;

  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(receiveROPList(res));
      } else {
        dispatch(requestROPListError('No data available'));
      }
    });
  } catch (err) {
    console.log(err);
    dispatch(requestROPListError(err));
  }
};

export const resetIndustries = () => dispatch => {
  dispatch(clearROPList());
};

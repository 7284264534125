import {connect} from 'react-redux';
import {
  profileDataSelector,
  uploadUserDocs,
  deleteUserDocs,
} from 'redux/modules/profile';
import {getFilesAndTotalSize} from 'core/utils';
import DocUploader from 'app/modules/dashboard/components/DocUploader';
import profileStaticData from 'data/settings-profile.json';
import {ContentBox} from 'mui-core';
const document_type = 'transcript';
const Transcripts = props => {
  const {
    // updateProfile,
    profileData: {data, request, error},
  } = props;

  const {data: document_files, total_size_consumed} = getFilesAndTotalSize(
    data,
    document_type,
  );
  return (
    <ContentBox variant='outlined' sx={{p: 3}}>
      <DocUploader
        {...props}
        title={'Transcripts'}
        noLoader
        description={`Adding education transcripts to your profile can enhance your credibility, highlight your expertise, and demonstrate your 
        qualifications and commitment to your field.`}
        type={document_type}
        profileData={{
          data: document_files,
          total_size_consumed,
          request,
          error,
        }}
      />
    </ContentBox>
  );
};

const mapStateToProps = state => ({
  profileData: profileDataSelector(state),
});
export default connect(mapStateToProps, {uploadUserDocs, deleteUserDocs})(
  Transcripts,
);

import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Grid, Alert, Snackbar, Box} from '@mui/material';
import {Check} from '@mui/icons-material';
import {
  RequestErrorLoader,
  EventCard,
  NoContentNavigator,
  Button,
} from 'mui-core';
import WithDrawEventDialog from './WithDrawEventDialog';
import {getInstanceVersion} from 'redux/modules/instance';

const EventList = props => {
  const {
    events,
    fetchMyEvents,
    withdrawEvent,
    removeMyEvent,
    clearMyEvents,
    fetchStudentMetrics,
  } = props;
  const [page, setPage] = useState(1);
  const [snackBarSuccessMsg, setSnackBarSuccessMsg] = useState(null);
  const [snackBarErrorMsg, setSnackBarErrorMsg] = useState(null);
  const [showWithdrawDialog, setShowWithdrawDialogStatus] = React.useState(
    false,
  );
  const [selectedValue, setSelectedValue] = React.useState(null);
  const {dashboard: {my_events: {explore_events = false} = {}} = {}} =
    useSelector(getInstanceVersion) || {};

  useEffect(() => {
    console.log('useEffect');
    if (!events?.data?.event_details && !events?.request) {
      fetchMyEvents(page, false, true);
    }

    return () => {
      clearMyEvents();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const loadMoreEvents = () => {
    setPage(page + 1);
    fetchMyEvents(page + 1);
  };

  const onWithdraw = data => {
    setShowWithdrawDialogStatus(true);
    setSelectedValue(data);
  };
  const withDrawDialogClose = () => {
    setShowWithdrawDialogStatus(false);
  };

  const displayEllipses = [
    {
      title: 'Withdraw',
      action: onWithdraw,
      passData: true,
    },
  ];

  const withDrawEvent = async event_uuid => {
    if (event_uuid) {
      try {
        const response = await withdrawEvent(event_uuid);
        if (response?.Success) {
          setSnackBarSuccessMsg(response?.Success);
          withDrawDialogClose();
          removeMyEvent(event_uuid);
          fetchStudentMetrics();
        }
      } catch (error) {
        setSnackBarErrorMsg(error.message || 'Something went wrong.');
      }
    }
  };

  return (
    <RequestErrorLoader
      minHeight={100}
      hideBackground
      body={{
        request: events.request,
        data: events?.data?.event_details,
      }}
      overrideNoDataContainer={
        <Box pb={3}>
          <NoContentNavigator
            pathTo='/events'
            label={explore_events ? '' : 'EXPLORE EVENTS'}
            message={
              explore_events
                ? 'This feature is currently inactive.'
                : 'It looks like you have not applied to any events.'
            }
            subHeading={
              explore_events
                ? 'Reach out to your institution to gain insights on how to activate this feature and enhance your user experience.'
                : 'Explore Events to apply.'
            }
          />
        </Box>
      }>
      <Grid container spacing={4} direction='row'>
        {events?.data?.event_details?.length > 0 &&
          events?.data.event_details.map((event, idx) => (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <EventCard
                width={300}
                data={event}
                enableNavigation
                key={event?.event_id}
                displayEllipses={displayEllipses}
              />
            </Grid>
          ))}
      </Grid>
      {events?.data?.page_details &&
        page < events?.data?.page_details?.no_of_pages && (
          <Box textAlign='center' alignContent='center' mt={3}>
            <Button variant='contained' onClick={loadMoreEvents}>
              {events?.request ? 'LOADING...' : 'LOAD MORE'}
            </Button>
          </Box>
        )}

      {showWithdrawDialog === true && (
        <WithDrawEventDialog
          selectedValue={selectedValue && selectedValue}
          open={showWithdrawDialog}
          withDrawDialogClose={withDrawDialogClose}
          withdrawDialogConfirm={withDrawEvent}
        />
      )}

      {snackBarSuccessMsg && (
        <Snackbar
          autoHideDuration={5000}
          open={snackBarSuccessMsg}
          onClose={() => setSnackBarSuccessMsg('')}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
          <Alert
            variant='filled'
            severity='success'
            icon={<Check fontSize='inherit' />}
            sx={{width: '100%', color: 'white'}}>
            {snackBarSuccessMsg}
          </Alert>
        </Snackbar>
      )}
      {snackBarErrorMsg && (
        <Snackbar
          open={snackBarErrorMsg}
          autoHideDuration={5000}
          onClose={() => setSnackBarErrorMsg('')}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
          <Alert
            severity='error'
            variant='filled'
            sx={{width: '100%', color: 'white'}}>
            {snackBarErrorMsg}
          </Alert>
        </Snackbar>
      )}
    </RequestErrorLoader>
  );
};
export default EventList;

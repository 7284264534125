export const getLocalJobs = state => state.jobs && state.jobs.LocalJobsData;
export const getJobBoards = state => state.jobs && state.jobs.JobBoardsData;
export const getPublicInsightJobs = state =>
  state.jobs && state.jobs.PublicInsightJobs;
export const getJobBoardsDetails = state =>
  state.jobs && state.jobs.JobBoardDetails;

export const getJobApplied = state => state.jobs && state.jobs.AppliedJobs;
export const getJobSaved = state => state.jobs && state.jobs.SavedJobs;
export const getAllJobs = state => state.jobs && state.jobs.AllJobs;

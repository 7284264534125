import * as TYPES from './constants';
import {getItem, saveItem} from 'redux/localStorage';
import {getGlobalSearch, addGlobalSearch, deleteGlobalSearch} from './actions';

const initialState = {
  globalSearch: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_GLOBAL_SEARCH_ITEMS:
      return {
        ...state,
        globalSearch: action.payload || [],
      };
    case TYPES.ADD_GLOBAL_SEARCH_ITEM:
    case TYPES.DELETE_GLOBAL_SEARCH_ITEM:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getGlobalSearchItems = () => async dispatch => {
  const globalSearch = getItem(TYPES.GLOBAL_SEARCH_STORAGE_NAME);
  const parsedGlobalSearch = (globalSearch && JSON.parse(globalSearch)) || [];
  dispatch(getGlobalSearch(parsedGlobalSearch));
};

export const addGlobalSearchItem = data => async (dispatch, getState) => {
  dispatch(addGlobalSearch());
  const {general: {userInput: {globalSearch = []} = {}} = {}} = getState();
  let updatedGlobalSearch = [];
  if (!globalSearch || !Array.isArray(globalSearch)) {
    updatedGlobalSearch = [data];
  } else {
    if (!globalSearch?.includes(data)) {
      updatedGlobalSearch =
        globalSearch.length < 5
          ? [data, ...globalSearch]
          : [data, ...globalSearch.slice(0, -1)];
    } else {
      updatedGlobalSearch = globalSearch || [];
    }
  }
  saveItem(
    TYPES.GLOBAL_SEARCH_STORAGE_NAME,
    JSON.stringify(updatedGlobalSearch),
  );
  dispatch(getGlobalSearchItems());
};

export const deleteGlobalSearchItem = item => async (dispatch, getState) => {
  dispatch(deleteGlobalSearch());
  const {general: {userInput: {globalSearch = []} = {}} = {}} = getState();
  let updatedGlobalSearch = [];
  if (globalSearch && Array.isArray(globalSearch)) {
    updatedGlobalSearch = globalSearch.filter(i => i !== item);
    saveItem(
      TYPES.GLOBAL_SEARCH_STORAGE_NAME,
      JSON.stringify(updatedGlobalSearch),
    );
  }
  dispatch(getGlobalSearchItems());
};

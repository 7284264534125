import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// components
import {
  ContentBox,
  ErrorBoundary,
  RequestErrorLoader,
  CareerInterestResults,
} from 'mui-core';
// redux
import {getGoSurveyResults, fetchGoSurveyResults} from 'redux/modules/survey';
// utils
import {career} from 'data/dashboard.json';

const {
  careerInterestSurvey: {accordionDesc, accordionHeader},
} = career || {};

const CareerInterest = () => {
  const dispatch = useDispatch();

  const {data: surveyResults = [], request: surveyResultsRequest} = useSelector(
    getGoSurveyResults,
  );

  useEffect(() => {
    if (!surveyResults || surveyResults?.length === 0) {
      dispatch(fetchGoSurveyResults());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentBox elevation={0}>
      <ErrorBoundary
        typeOfUi='subPage'
        nameOfComponent='module-dashboard-career-careerInterest'>
        <RequestErrorLoader
          hideEmpty
          fullWidth
          body={{
            data: surveyResults,
            request: surveyResultsRequest,
          }}>
          <CareerInterestResults
            title={accordionHeader}
            description={accordionDesc}
            surveyResults={surveyResults}
          />
        </RequestErrorLoader>
      </ErrorBoundary>
    </ContentBox>
  );
};

export default CareerInterest;

import React, {useEffect, useState} from 'react';
import {Menu, AutoComplete, Input} from 'antd';
//Button
//Popover
// import {UpOutlined, DownOutlined} from '@ant-design/icons';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'debounce';

import {RequestErrorLoader, ErrorBoundary} from 'core/components';
import {getModuleBasePath} from 'core/utils';

import {
  fetchClusters,
  fetchPathOccupationSearch,
  getClusters,
  getPathOccupations,
} from 'redux/modules/search';

import HOME_DATA from 'data/home';

import './style.less';
const {SubMenu} = Menu;

const MenuContent = ({fetchClusters, clusters, history, onPopoverClose}) => {
  useEffect(() => {
    if (!clusters.data) {
      fetchClusters();
    }
  }, [clusters.data, fetchClusters]);

  const onMenuClick = ({item, key, keyPath, domEvent}) => {
    const BasePath = getModuleBasePath('search');
    const pos = domEvent.target.innerHTML;
    history.push(`${BasePath}?pos=${pos}`);
    onPopoverClose(false);
  };

  return clusters.request ? (
    <RequestErrorLoader body={{request: clusters.request}} />
  ) : (
    <Menu
      style={{minWidth: 256}}
      selectable={false}
      triggerSubMenuAction='hover'
      mode='vertical-left'
      onClick={onMenuClick}>
      {clusters.data &&
        Array.isArray(clusters.data) &&
        clusters.data.map(cluster => (
          <SubMenu
            key={cluster.cluster_uuid}
            title={cluster.cluster_name}
            popupClassName='path-search-explore-submenu'>
            {Array.isArray(cluster.program) &&
              cluster.program.map(prog => (
                <Menu.Item key={prog.program_uuid}>
                  {prog.program_name}
                </Menu.Item>
              ))}
          </SubMenu>
        ))}
    </Menu>
  );
};

const PathSearch = props => {
  const {history, fetchPathOccupationSearch, pathSearch} = props;
  const [searchData, setSearchData] = useState([]);
  // const [visiblity, setVisiblity] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const {pathways} = HOME_DATA['en'];
  useEffect(() => {
    let searchArray = [];
    const {data} = pathSearch;
    if (data && data.occupation_data) {
      searchArray = searchArray.concat(data.occupation_data);
    }
    if (data && data.path_data) {
      searchArray = searchArray.concat(data.path_data);
    }
    setSearchData(searchArray.map(d => ({...d, label: d.name || d.title})));
    return () => {};
  }, [pathSearch]);

  const onSearch = debounce(value => {
    setSearchKey(value);
    if (value !== '') fetchPathOccupationSearch(value);
    else setSearchData([]);
  }, 700);

  const navigateToSearch = value => {
    const BasePath = getModuleBasePath('search');
    history.push(`${BasePath}?query=${value}`);
  };
  const onSelect = (value, data) => navigateToSearch(data.label);

  const onKeyDown = event => {
    if (event.key === 'Enter' && event.code === 'Enter') {
      navigateToSearch(event.target.value);
    }
  };
  // const onPopoverClick = val => setVisiblity(val);
  return (
    <ErrorBoundary nameOfComponent='core-path-search'>
      <div className='d-flex '>
        <div className='path-search-grp'>
          {/* <Popover
          content={<MenuContent {...props} onPopoverClose={onPopoverClick} />}
          trigger='click'
          placement='bottomLeft'
          overlayClassName='path-search-explore-overlay'
          visible={visiblity}
          onVisibleChange={onPopoverClick}>
          <Button
            type='primary'
            icon={visiblity ? <UpOutlined /> : <DownOutlined />}
            className='btn-explore'>
            {pathways.btnTxt}
          </Button>
        </Popover> */}
          <AutoComplete
            className='s-auto-complete'
            options={
              searchData.length === 0 || pathSearch.request
                ? []
                : searchData.map(data => ({
                    value: data.occupation_name || data.name || data.title,
                    label: data.occupation_name || data.name || data.title,
                  }))
            }
            dropdownClassName='path-s-au-dropdown'
            notFoundContent={
              pathSearch.request ? (
                <RequestErrorLoader body={{request: pathSearch.request}} />
              ) : searchKey === '' ? null : (
                <div>No results found</div>
              )
            }
            onSelect={onSelect}
            onSearch={onSearch}>
            <Input
              className='ant-input ant-input-lg'
              placeholder={pathways?.placeHolder}
              onKeyDown={onKeyDown}
            />
          </AutoComplete>
        </div>
      </div>
    </ErrorBoundary>
  );
};

PathSearch.propTypes = {
  searchData: PropTypes.object,
  onSearch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    clusters: getClusters(state),
    pathSearch: getPathOccupations(state),
  };
};

export default connect(mapStateToProps, {
  fetchClusters,
  fetchPathOccupationSearch,
})(PathSearch);

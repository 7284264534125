import PropTypes from 'prop-types';
import {Brightness1} from '@mui/icons-material';
import {List, ListItem, Avatar, Typography} from '@mui/material';

const MUIList = ({
  list,
  isBubble,
  hideEmptyMsg,
  fontSize = 14,
  emptyMsg = 'No List Items found',
}) => {
  return (
    <>
      {list && Array.isArray(list) && list?.length > 0 && (
        <List>
          {list &&
            Array.isArray(list) &&
            list?.length > 0 &&
            list.map((item, idx) => (
              <ListItem key={idx} sx={{padding: '4px 0px'}}>
                {isBubble && (
                  <Avatar variant='plain'>
                    <Brightness1 color='lightBlue' />
                  </Avatar>
                )}
                <Typography
                  variant='body1'
                  color='text.primary'
                  fontSize={{xs: 12, sm: 14, md: fontSize}}>
                  {item}
                </Typography>
              </ListItem>
            ))}
        </List>
      )}
      {!hideEmptyMsg && (!list || list.length === 0) && (
        <Typography
          variant='h6'
          color='text.primary'
          fontSize={{xs: 12, sm: 14, md: fontSize}}>
          {emptyMsg}
        </Typography>
      )}
    </>
  );
};

MUIList.prototype = {
  isBubble: PropTypes.bool,
  hideEmptyMsg: PropTypes.bool,
  list: PropTypes.array.isRequired,
  fontSize: PropTypes.string || PropTypes.number,
};
export default MUIList;

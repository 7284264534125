import {combineReducers} from 'redux';
import ropList from './ropList';
import checkStatus from './checkStatus';
import fetchBusinessSignup from './businessSignup';

export const businessSignUpReducer = combineReducers({
  ropList,
  checkStatus,
  fetchBusinessSignup,
});

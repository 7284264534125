import * as React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import {Button} from 'mui-core';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import ListboxComponent from './ListboxComponent';
import {useForm, Controller} from 'react-hook-form';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {generateRange} from 'core/utils';
import {isString} from 'lodash';

const StyledPopper = styled(Popper)({
  zIndex: 1400,
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const experienceCount = generateRange(1, 9, 1, true);
experienceCount.push(`${experienceCount.length + 1}+`);

const AddSkills = ({
  skills,
  onSkillSubmit,
  edit,
  onCancel,
  editData,
  studentSkills,
  isSmallScreen,
  experienceMenu,
  defaultBeginnerValue,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: {errors},
  } = useForm();
  const {data, request} = skills;
  const existSkills = React.useMemo(() => {
    return studentSkills?.data?.map(i => i.skill) || [];
  }, [studentSkills.data]); // eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (edit && editData) {
      setValue('experience', editData.experience);
      setValue('skill', editData.skill);
    }
  }, [editData, edit, setValue]);
  const onSubmit = (data, event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!edit && existSkills.indexOf(data ? data.skill : '') !== -1) {
      setError('skill', {type: 'custom', message: 'Skill already exist'});
      return;
    }
    if (
      editData &&
      editData.skill !== data.skill &&
      existSkills.indexOf(data ? data.skill : '') !== -1
    ) {
      setError('skill', {type: 'custom', message: 'Skill already exist'});
      return;
    }
    reset();
    setValue('experience', defaultBeginnerValue ? defaultBeginnerValue : 1);
    onSkillSubmit(data);
  };
  console.log('errors', errors);
  return (
    <form>
      <Box justifyContent={'center'} display={'flex'}>
        <Stack
          direction={
            isSmallScreen
              ? {xs: 'column', lg: 'row'}
              : {xs: 'column', sm: 'row'}
          }
          spacing={2}
          mb={3}
          mt={2}
          alignItems={
            isSmallScreen
              ? {xs: 'initial', lg: 'center'}
              : {xs: 'initial', sm: 'center'}
          }
          justifyContent={'center'}
          sx={{width: isSmallScreen ? '100%' : '90%', maxWidth: 760}}>
          <Box flexGrow={1} sx={isSmallScreen ? {minWidth: 150} : {}}>
            <FormControl fullWidth error={errors?.skill}>
              <Controller
                control={control}
                name='skill'
                rules={{required: true}}
                defaultValue={null}
                render={({field: {onChange, value}}) => (
                  <Autocomplete
                    size='small'
                    fullWidth
                    onChange={(event, item) => {
                      onChange(item && item.name);
                    }}
                    value={value}
                    loading={request}
                    disableListWrap
                    PopperComponent={StyledPopper}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option, state) => [
                      props,
                      option,
                      state.index,
                    ]}
                    getOptionLabel={option =>
                      option && option.name
                        ? option.name
                        : option && isString(option)
                        ? option
                        : ''
                    }
                    ListboxComponent={ListboxComponent}
                    id='combo-box-demo'
                    options={data ? data : []}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Search Skill Tags'
                        error={errors?.skill}
                      />
                    )}
                  />
                )}
              />
              {errors?.skill && (
                <FormHelperText
                  id='my-helper-text'
                  sx={{position: 'absolute', top: '100%'}}>
                  {errors?.skill?.message || 'Please select skill.'}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box textAlign={'center'}>
            <Typography variant='subtitle2' color='text.primary'>
              with
            </Typography>
          </Box>
          <Box sx={{minWidth: 145}}>
            <FormControl fullWidth size='small'>
              <InputLabel id='demo-simple-select-label'>Experience</InputLabel>
              <Controller
                control={control}
                name='experience'
                defaultValue={defaultBeginnerValue ? defaultBeginnerValue : 1}
                render={({field: {onChange, value}}) => (
                  <Select
                    label='Experience'
                    onChange={event => {
                      onChange(event.target.value);
                    }}
                    value={value}
                    fullWidth
                    placeholder='Enter years experience'
                    size='small'>
                    {experienceMenu
                      ? experienceMenu
                      : experienceCount.map(item => (
                          <MenuItem
                            key={item}
                            value={
                              item === `${experienceCount.length}+`
                                ? 10
                                : Number(item)
                            }>
                            {item} {`${item}` === '1' ? 'Yr' : 'Yrs'}
                          </MenuItem>
                        ))}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
          {!edit && (
            <Box textAlign={'center'}>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>
                Add Skill
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
      {edit && (
        <Stack direction={'row'} justifyContent='right' spacing={2}>
          <Box textAlign={'center'}>
            <Button onClick={onCancel}>Cancel</Button>
          </Box>
          <Box textAlign={'center'}>
            <Button variant='contained' onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </Box>
        </Stack>
      )}
    </form>
  );
};

export default AddSkills;

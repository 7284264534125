import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Job = props => {
  return (
    <SvgIcon {...props}>
      <path d='M19.1846 22.7514H1.77611C0.796522 22.7514 0 21.8324 0 20.7029V2.04851C0 0.918989 0.797005 0 1.77611 0H22.3751C23.2711 0 24 0.840458 24 1.87362V20.1459C24 20.3615 23.8488 20.5358 23.6619 20.5358C23.4749 20.5358 23.3238 20.3615 23.3238 20.1459V1.87362C23.3238 1.27043 22.8982 0.779749 22.3751 0.779749H1.77611C1.16942 0.779749 0.676247 1.34897 0.676247 2.04851V20.7029C0.676247 21.4024 1.16991 21.9716 1.77611 21.9716H19.1846C19.3716 21.9716 19.5228 22.146 19.5228 22.3615C19.5228 22.5771 19.3716 22.7514 19.1846 22.7514Z' />
      <path d='M21.2968 4.88864H2.39281C2.20588 4.88864 2.05469 4.71431 2.05469 4.49876C2.05469 4.28322 2.20588 4.10889 2.39281 4.10889H21.2968C21.4837 4.10889 21.6349 4.28322 21.6349 4.49876C21.6349 4.71431 21.4837 4.88864 21.2968 4.88864Z' />
      <path d='M3.04851 2.82341C3.20533 2.57001 3.1543 2.21801 2.93453 2.0372C2.71477 1.85639 2.4095 1.91523 2.25268 2.16862C2.09587 2.42202 2.1469 2.77402 2.36666 2.95483C2.58643 3.13565 2.8917 3.07681 3.04851 2.82341Z' />
      <path d='M4.744 2.89514C4.9349 2.67502 4.9349 2.31814 4.744 2.09802C4.5531 1.87791 4.24359 1.87791 4.05269 2.09802C3.86179 2.31814 3.86179 2.67502 4.05269 2.89514C4.24359 3.11526 4.5531 3.11526 4.744 2.89514Z' />
      <path d='M6.621 2.60707C6.67399 2.30183 6.50235 2.00485 6.23763 1.94375C5.97291 1.88265 5.71535 2.08056 5.66236 2.38579C5.60937 2.69103 5.78101 2.98801 6.04573 3.04911C6.31045 3.11022 6.56801 2.91231 6.621 2.60707Z' />
      <path d='M16.8475 20.7315C14.685 20.7315 12.9258 18.7031 12.9258 16.209C12.9258 13.7149 14.685 11.687 16.8475 11.687C19.0101 11.687 20.7698 13.7155 20.7698 16.209C20.7698 18.7025 19.0106 20.7315 16.8475 20.7315ZM16.8475 12.4668C15.0579 12.4668 13.602 14.1454 13.602 16.209C13.602 18.2725 15.0579 19.9518 16.8475 19.9518C18.6372 19.9518 20.0935 18.2731 20.0935 16.209C20.0935 14.1449 18.6377 12.4668 16.8475 12.4668Z' />
      <path d='M20.5568 20.8777C20.4704 20.8777 20.3839 20.8398 20.3177 20.7635L19.1575 19.4257C19.0256 19.2736 19.0256 19.0263 19.1575 18.8743C19.2894 18.7222 19.5038 18.7222 19.6357 18.8743L20.7959 20.2121C20.9278 20.3642 20.9278 20.6115 20.7959 20.7635C20.7298 20.8398 20.6433 20.8777 20.5568 20.8777Z' />
      <path d='M22.7841 24.0001C22.5653 24.0001 22.3595 23.902 22.2049 23.7232L20.3182 21.5477C20.1636 21.3695 20.0781 21.1323 20.0781 20.8799C20.0781 20.6276 20.1631 20.3904 20.3182 20.2121C20.6375 19.844 21.1572 19.844 21.477 20.2121L23.3637 22.3876C23.5183 22.5659 23.6038 22.8031 23.6038 23.0554C23.6038 23.3078 23.5188 23.545 23.3637 23.7232C23.2091 23.9015 23.0034 24.0001 22.7846 24.0001H22.7841ZM20.8973 20.7151C20.8606 20.7151 20.8239 20.7312 20.7964 20.7635C20.7602 20.8053 20.7544 20.8549 20.7544 20.8799C20.7544 20.905 20.7597 20.9546 20.7964 20.9964L22.6831 23.1719C22.7194 23.2142 22.7623 23.2203 22.7841 23.2203C22.8058 23.2203 22.8488 23.2142 22.885 23.1719C22.9213 23.1301 22.9271 23.0811 22.9271 23.0554C22.9271 23.0298 22.9217 22.9808 22.885 22.939L20.9983 20.7635C20.9703 20.7312 20.9336 20.7151 20.8973 20.7151Z' />
      <path d='M9.51473 9.7279C9.49251 9.87995 9.44228 10.022 9.36403 10.154C9.28578 10.286 9.17275 10.3963 9.02494 10.4854C8.87713 10.5739 8.67812 10.6185 8.42694 10.6185C8.2859 10.6185 8.1492 10.5957 8.01733 10.5505C7.88546 10.5054 7.7676 10.4347 7.66423 10.3389C7.56086 10.2431 7.4773 10.12 7.4145 9.96962C7.35171 9.81924 7.32031 9.63767 7.32031 9.4238V9.08739H7.98883V9.26117C7.98883 9.35529 7.99511 9.44218 8.00767 9.52183C8.02023 9.60147 8.04196 9.66942 8.07336 9.72567C8.10476 9.78193 8.14775 9.82593 8.20281 9.85879C8.25788 9.89165 8.32889 9.9078 8.4168 9.9078C8.51389 9.9078 8.58924 9.88775 8.64286 9.84821C8.69599 9.80866 8.73463 9.75686 8.7583 9.69337C8.78197 9.62988 8.79598 9.56137 8.80081 9.48729C8.80564 9.41322 8.80806 9.34137 8.80806 9.27286V6.65625H9.5471V9.31074C9.5471 9.43772 9.53599 9.57697 9.51425 9.72902L9.51473 9.7279Z' />
      <path d='M10.1638 7.80341C10.2392 7.55557 10.3474 7.33946 10.4889 7.15455C10.6299 6.9702 10.8033 6.82539 11.0091 6.72012C11.2149 6.61541 11.4463 6.5625 11.7037 6.5625C11.9612 6.5625 12.1964 6.61485 12.4007 6.72012C12.6046 6.82539 12.7775 6.96964 12.9186 7.15455C13.0596 7.33891 13.1683 7.55557 13.2436 7.80341C13.319 8.05126 13.3567 8.32028 13.3567 8.60934C13.3567 8.8984 13.319 9.15516 13.2436 9.39911C13.1683 9.64362 13.0601 9.85582 12.9186 10.0368C12.7775 10.2179 12.6046 10.3599 12.4007 10.4629C12.1969 10.566 11.9646 10.6177 11.7037 10.6177C11.4429 10.6177 11.2149 10.566 11.0091 10.4629C10.8033 10.3599 10.6299 10.2179 10.4889 10.0368C10.3478 9.85582 10.2392 9.64306 10.1638 9.39911C10.0885 9.15461 10.0508 8.89172 10.0508 8.60934C10.0508 8.32696 10.0885 8.05126 10.1638 7.80341ZM10.8396 9.08443C10.8724 9.23815 10.9251 9.37684 10.9975 9.49992C11.0695 9.62301 11.1637 9.7216 11.2801 9.79567C11.396 9.86975 11.5376 9.90706 11.7037 9.90706C11.8699 9.90706 12.0114 9.86975 12.1273 9.79567C12.2433 9.7216 12.3375 9.62301 12.4099 9.49992C12.4819 9.37684 12.5345 9.23871 12.5679 9.08443C12.6007 8.93071 12.6171 8.77253 12.6171 8.60934C12.6171 8.44615 12.6007 8.27461 12.5679 8.11531C12.535 7.95602 12.4824 7.814 12.4099 7.68924C12.3375 7.56448 12.2433 7.46478 12.1273 7.3907C12.0114 7.31663 11.8699 7.27931 11.7037 7.27931C11.5376 7.27931 11.396 7.31663 11.2801 7.3907C11.1642 7.46478 11.07 7.56448 10.9975 7.68924C10.9251 7.814 10.8729 7.95602 10.8396 8.11531C10.8067 8.27461 10.7903 8.43947 10.7903 8.60934C10.7903 8.77921 10.8067 8.93071 10.8396 9.08443Z' />
      <path d='M15.4423 6.65527C15.6022 6.65527 15.7485 6.67143 15.8799 6.70429C16.0118 6.73659 16.1248 6.79006 16.219 6.86469C16.3132 6.93877 16.3861 7.03735 16.4378 7.16044C16.4895 7.28353 16.5156 7.43558 16.5156 7.61659C16.5156 7.81209 16.4769 7.97472 16.4001 8.10505C16.3233 8.23538 16.2093 8.34232 16.0586 8.4253C16.2658 8.49437 16.4204 8.61467 16.5223 8.78622C16.6243 8.95832 16.6755 9.16551 16.6755 9.40779C16.6755 9.60328 16.6426 9.7726 16.5764 9.91518C16.5107 10.0583 16.4219 10.1747 16.3103 10.2655C16.1987 10.3557 16.0717 10.4231 15.9287 10.4666C15.7857 10.51 15.6394 10.5317 15.4886 10.5317H13.8594V6.65527H15.4413H15.4423ZM15.3481 8.22424C15.48 8.22424 15.5882 8.18804 15.6732 8.11563C15.7577 8.04323 15.8002 7.92571 15.8002 7.76252C15.8002 7.67229 15.7862 7.59766 15.7577 7.53973C15.7297 7.48181 15.692 7.43669 15.6447 7.40383C15.5978 7.37153 15.5437 7.34869 15.4824 7.33588C15.421 7.32307 15.3577 7.31695 15.2916 7.31695H14.5994V8.22368H15.3481V8.22424ZM15.3906 9.86895C15.4626 9.86895 15.5316 9.8606 15.5978 9.84445C15.6635 9.82829 15.722 9.801 15.7722 9.76313C15.8224 9.72525 15.8625 9.67346 15.8925 9.60829C15.9224 9.54313 15.9374 9.46014 15.9374 9.35877C15.9374 9.15994 15.8886 9.01791 15.7915 8.9327C15.6944 8.84748 15.5654 8.80515 15.4056 8.80515H14.6003V9.86951H15.3916L15.3906 9.86895Z' />
      <path d='M6.63172 13.604H3.1389C2.95197 13.604 2.80078 13.4296 2.80078 13.2141C2.80078 12.9985 2.95197 12.8242 3.1389 12.8242H6.6322C6.81914 12.8242 6.97033 12.9985 6.97033 13.2141C6.97033 13.4296 6.81914 13.604 6.6322 13.604H6.63172Z' />
      <path d='M10.7921 15.6001H3.1389C2.95197 15.6001 2.80078 15.4257 2.80078 15.2102C2.80078 14.9946 2.95197 14.8203 3.1389 14.8203H10.7921C10.979 14.8203 11.1302 14.9946 11.1302 15.2102C11.1302 15.4257 10.979 15.6001 10.7921 15.6001Z' />
      <path d='M10.7921 17.5962H3.1389C2.95197 17.5962 2.80078 17.4218 2.80078 17.2063C2.80078 16.9907 2.95197 16.8164 3.1389 16.8164H10.7921C10.979 16.8164 11.1302 16.9907 11.1302 17.2063C11.1302 17.4218 10.979 17.5962 10.7921 17.5962Z' />
      <path d='M10.7921 19.5893H3.1389C2.95197 19.5893 2.80078 19.415 2.80078 19.1994C2.80078 18.9839 2.95197 18.8096 3.1389 18.8096H10.7921C10.979 18.8096 11.1302 18.9839 11.1302 19.1994C11.1302 19.415 10.979 19.5893 10.7921 19.5893Z' />
    </SvgIcon>
  );
};

export default Job;

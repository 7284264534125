import {Tooltip} from 'mui-core';
import AddToResumeToggle from '../AddToResumeToggle';
import {styled, useTheme} from '@mui/material/styles';
import {infoIcon, InfoIconwhite} from 'assets/images';
import resumeBuilderData from 'data/resumeBuilder.json';
import {
  Box,
  Paper,
  Stack,
  Divider,
  Typography,
  useMediaQuery,
} from '@mui/material';

const TooltipData = styled(Typography)(({}) => ({
  fontWeight: 300,
}));

const FormContainer = styled(Paper)(({}) => ({
  padding: 32,
  width: '100%',
  minHeight: 550,
  borderRadius: 8,
}));

const StyledInfoRounded = styled('img')(({theme}) => ({
  width: 15,
  height: 15,
  marginLeft: 7,
  marginTop: -20,
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    marginLeft: 8,
  },
}));

const GradientDivider = styled(Divider)(() => ({
  borderImage:
    'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  borderImageSlice: '20%',
  borderImageWidth: '2px',
}));

const StepperFormWrapper = ({
  formData = {},
  children,
  activeStep,
  resumeData,
  handleAddToResumeToggle,
}) => {
  const theme = useTheme();
  const {stepsMeta} = resumeBuilderData;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const InfoIconImage =
    theme?.palette?.mode === 'dark' ? InfoIconwhite : infoIcon;

  const handleToggle = () => {
    handleAddToResumeToggle(formData?.toggleKey);
  };

  return (
    <Box
      sx={{
        padding: '2px',
        borderRadius: '8px',
        boxShadow: `1px 3px 12px ${theme.palette.grayText.main}`,
        background:
          'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
      }}>
      <FormContainer elevation={3}>
        <Stack gap={0.5}>
          <Typography
            variant='h4'
            fontWeight={300}
            sx={{my: 'auto'}}
            color='text.primary'>
            {stepsMeta[formData?.key]?.title}
            {activeStep !== 0 ? (
              <Tooltip
                isMobile={isMobile}
                titleVariant='h3'
                title={stepsMeta[formData?.key]?.title}
                tooltipData={
                  <TooltipData
                    variant='body1'
                    color='text.secondary'
                    dangerouslySetInnerHTML={{
                      __html: stepsMeta[formData?.key]?.quickTips,
                    }}
                  />
                }>
                <StyledInfoRounded alt='info-icon' src={InfoIconImage} />
              </Tooltip>
            ) : null}
          </Typography>
          {activeStep !== 0 ? (
            <AddToResumeToggle
              toggle={handleToggle}
              isChecked={resumeData[formData?.toggleKey]}
            />
          ) : null}
        </Stack>
        <GradientDivider sx={{my: 2}} />
        {children}
      </FormContainer>
    </Box>
  );
};

export default StepperFormWrapper;

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestOpportunityApplied,
  recieveOpportunityApplied,
  requestOpportunityAppliedError,
  clearOpportunityApplied,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPPORTUNITY_APPLIED_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.OPPORTUNITY_APPLIED_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLEAR_OPPORTUNITY_APPLIED:
      return {
        request: false,
        error: false,
        data: null,
      };
    case TYPES.OPPORTUNITY_APPLIED_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchAppliedOpportunities = callback => (dispatch, getState) => {
  dispatch(requestOpportunityApplied());
  const {
    auth: {auth: {token = null, user = {}} = {}},
  } = getState();
  try {
    let endPoint = `${API.gps.opportunity}?action_type=apply`;
    return services.fetchRecords(endPoint, token, true).then(res => {
      if (res) {
        if (callback) callback(res);
        dispatch(recieveOpportunityApplied(res));
        return;
      }
      dispatch(requestOpportunityAppliedError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestOpportunityAppliedError(err));
  }
};

export const removeAppliedOpportunities = (opp_application_uuid, callback) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  try {
    let endPoint = `${API.gps.opportunity}${opp_application_uuid}`;
    return services
      .createUpdateRecord(token, endPoint, {}, 'DELETE')
      .then(res => {
        if (res) {
          if (callback) callback(res);
          return;
        }
      });
  } catch (err) {
    console.log(err);
  }
};

export const resetAppliedOpportunities = () => dispatch => {
  dispatch(clearOpportunityApplied());
};

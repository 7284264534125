import {useEffect, useState} from 'react';
import {Select, Form} from 'antd';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';

export default function SkillSelect({
  errorMessage,
  placeholder,
  skills,
  label,
  name,
  initialValue,
}) {
  const [filteredSkills, setFilteredSkills] = useState([]);

  useEffect(() => {
    setFilteredSkills(skills?.data);
  }, [skills]);

  const options =
    filteredSkills?.length > 0 &&
    filteredSkills.map(({name}) => (
      <Select.Option key={name} value={name}>
        {name}
      </Select.Option>
    ));

  const onSearch = keyword => {
    if (keyword === '') {
      setFilteredSkills(skills?.data);
      return;
    }
    if (skills?.length > 0) {
      const filteredSkills = skills.filter(skill => {
        if (skill.name.toLowerCase().includes(keyword.toLowerCase())) {
          return skill;
        }
      });
      setFilteredSkills(uniqBy(filteredSkills.slice(0, 21), 'name'));
    }
  };

  return (
    <Form.Item
      name={name || 'skills'}
      label={label || 'Skills'}
      initialValue={initialValue}
      rules={[{required: !!errorMessage, message: errorMessage || ''}]}
      data-cy='credential-skills'>
      <Select
        mode='multiple'
        loading={skills?.request}
        onSearch={debounce(keyword => onSearch(keyword), 800)}
        placeholder={placeholder || 'Search Skill'}>
        {options}
      </Select>
    </Form.Item>
  );
}

import React, {useEffect, useState} from 'react';
import Cropper from 'react-cropper';
import './cropper.css';
import {Dialog, DialogActions, DialogContent} from '@mui/material';
import {Button} from 'mui-core/Button';
// function dataURLtoFile(dataurl, filename) {
//   var arr = dataurl.split(','),
//     mime = arr[0].match(/:(.*?);/)[1],
//     bstr = atob(arr[1]),
//     n = bstr.length,
//     u8arr = new Uint8Array(n);
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }
//   return new File([u8arr], filename, {type: mime});
// }

// function blobToFile(theBlob, file) {
//   return new File([theBlob], file.name);
//   //A Blob() is almost a File() - it's just missing the two properties below which we will add
//   // theBlob.lastModifiedDate = new Date();
//   // theBlob.name = file.name;
//   // return theBlob;
// }

const ImageCropper = ({visible, cropFile, onCrop, onCancel, aspectRatio}) => {
  console.log(cropFile);
  useEffect(() => {
    if (visible) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(cropFile);
    }
  }, [cropFile, visible]);
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState();
  const crop = () => {
    if (typeof cropper !== 'undefined') {
      console.log(cropper, cropper.getCroppedCanvas());
      cropper
        .getCroppedCanvas({
          imageSmoothingEnabled: false,
          fillColor: '#fff',
        })
        .toBlob(
          blob => {
            const image = URL.createObjectURL(blob);
            // var file = blobToFile(blob, cropFile);
            var file = blob;
            if (file && image) onCrop({file, image});
          },
          'image/jpeg',
          0.91,
        );

      // const image = cropper.getCroppedCanvas().toDataURL('image/png', 0.1);
      // console.log(cropper, image);
      // var file = dataURLtoFile(image, cropFile.name);
      // console.log(file);
      // if (file && image) onCrop({file, image});
    }
  };
  return (
    <Dialog
      open={visible}
      scroll={'body'}
      width={'auto'}
      onClose={onCancel}
      okText={'Crop'}
      onOk={crop}
      maxWidth={'auto'}
      wrapClassName='cropper-image'
      title={'Crop Image'}>
      <DialogContent sx={{p: 1}}>
        <Cropper
          style={{height: 'auto', maxWidth: '800px'}}
          aspectRatio={aspectRatio ? aspectRatio : 5 / 1}
          initialAspectRatio={1}
          preview='.img-preview'
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={0.6}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={instance => {
            setCropper(instance);
          }}
          guides={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={crop} variant={'contained'}>
          Crop
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropper;

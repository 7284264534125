import * as TYPES from './modules/auth/constants';
import {combineReducers} from 'redux';

import {instanceReducer} from './modules/instance';
import {homeReducer} from './modules/home';
import {authReducer} from './modules/auth';
import {generalReducer} from './modules/general/reducers';
import {jobsReducer} from './modules/jobs/reducers';
import {occupationReducer} from './modules/occupation/reducers';
import {pathwaysReducer} from './modules/pathways';
import {profileReducer} from './modules/profile';
import {programReducer} from './modules/programs/reducers';
import {salaryReducer} from './modules/salary/reducers';
import {searchReducer} from './modules/search/reducers';
import {directoryReducer} from './modules/directory/reducers';
import {careerDestinationReducer} from './modules/career-destination/reducers';
import {eventReducer} from './modules/events/reducers';
import {surveyReducer} from './modules/survey/reducer';
import {businessPartnerReducer} from './modules/business-partner/reducers';
import {educatorReducer} from './modules/educators/reducers';
import {resumeReducer} from './modules/resumes/reducers';
import {resumeBuilderReducer} from './modules/resume-builder/reducers';
import {opportunitiesReducer} from './modules/opportunities/reducers';
import {businessSignUpReducer} from './modules/business-signup/reducers';

// All modules base reducers should connect here
const defaultReducer = combineReducers({
  instance: instanceReducer,
  auth: authReducer,
  general: generalReducer,
  jobs: jobsReducer,
  occupation: occupationReducer,
  pathways: pathwaysReducer,
  profile: profileReducer,
  programs: programReducer,
  salary: salaryReducer,
  search: searchReducer,
  directory: directoryReducer,
  careerDestination: careerDestinationReducer,
  events: eventReducer,
  survey: surveyReducer,
  businessPartners: businessPartnerReducer,
  educators: educatorReducer,
  home: homeReducer,
  resumes: resumeReducer,
  resumeBuilder: resumeBuilderReducer,
  opportunities: opportunitiesReducer,
  //privacysetting: privacySettingReducer,
  businessSignUp: businessSignUpReducer,
});

const rootReducer = (state, action) => {
  // here clear all reducers after logout
  if (action.type === TYPES.LOG_OUT) {
    //Todo: need some good solution
    state.pathways.activePaths.data = null;
    state.pathways.genedOptions.data = null;
    state.pathways.myPlan.data = null;
    state.pathways.recommendedPlan.data = null;
    state.pathways.courses.data = null;
    state.pathways.activePaths.pathInfo = null;
    state.profile.user.data = null;
    state.profile.skills.data = null;
    state.search.awardTypes.data = null;
    state.profile.awardTypes.data = null;
    state.profile.profileData.data = null;
    state.profile.seekingData.data = null;
    if (state.survey.carrerInterestSurvey) {
      state.survey.carrerInterestSurvey.data = null;
      state.survey.carrerInterestSurvey.answers = null;
    }
    state = state;
  }
  return defaultReducer(state, action);
};

export default rootReducer;

import {useState, useRef, useEffect} from 'react';
import {useUser} from 'core/hooks';
import {useSnackbar} from 'notistack';
import {useHistory} from 'react-router-dom';
// redux
import {useDispatch, useSelector} from 'react-redux';
import {
  clearResume,
  getResumeList,
  fetchResumeList,
} from 'redux/modules/resumes';
import {
  fetchSkills,
  fetchAwardTypes,
  awardTypesSelector as getAwardTypes,
} from 'redux/modules/profile';
import {
  getResumeData,
  resetResumeData,
  patchResumeData,
  updateResumeData,
  fetchResumeDetails,
  initiateResumeData,
  getLinkedInDetails,
  fetchlinkedInDetails,
  clearLinkedInDetails,
} from 'redux/modules/resume-builder';
import {getStates, fetchStates} from 'redux/modules/general';
// components
import {Drawer, Preview, LayoutBanner, ResumeDetails} from './sharedComponents';
import {
  Awards,
  Skills,
  Summary,
  Education,
  Publications,
  ContactInformation,
  VolunteerExperience,
  ProfessionalExperience,
  LicenseAndCertifications,
} from './subModules';
import {Box, Stack, styled, useTheme, useMediaQuery} from '@mui/material';
import {
  Button,
  Stepper,
  HighLighter,
  BackgroundPaper,
  RequestErrorLoader,
} from 'mui-core';
// utils
import {queryStringParse} from 'core/utils';
import resumeBuilderData from 'data/resumeBuilder.json';
import SuccessModal from './sharedComponents/SuccessModal';
import {isOnlyWhitespace} from 'mui-core/utils';

const {
  stepsMeta: {
    contactInfo,
    education,
    professionalExperience,
    skills,
    professionalSummary,
    licenses,
    awards,
    volunteerExp,
    publications,
  },
  highlighter: {drawerDescription = '', stepperDescription = ''} = {},
  resumeSavedText,
  resumeNotSavedText,
  saveBeforePublishText,
  resumeNotPublishedText,
  updateRequiredFieldText,
} = resumeBuilderData || {};

// It will contain all the data related to step components
const STEPS = [
  {
    key: 'contactInfo',
    component: ContactInformation,
  },
  {
    key: 'education',
    component: Education,
    toggleKey: 'show_education',
  },
  {
    key: 'professionalExperience',
    component: ProfessionalExperience,
    toggleKey: 'show_professional_experience',
  },

  {
    key: 'skills',
    component: Skills,
    toggleKey: 'show_skills',
  },
  {
    key: 'professionalSummary',
    component: Summary,
    toggleKey: 'show_professional_summary',
  },
  {
    key: 'licenses',
    component: LicenseAndCertifications,
    toggleKey: 'show_license',
  },
  {
    key: 'awards',
    component: Awards,
    toggleKey: 'show_awards',
  },
  {
    key: 'volunteerExp',
    component: VolunteerExperience,
    toggleKey: 'show_volunteer_experience',
  },
  {
    key: 'publications',
    component: Publications,
    toggleKey: 'show_publication',
  },
];

const essentialInformation = [
  {
    key: 0,
    label: contactInfo.title,
    value: true,
  },
  {
    key: 1,
    label: education.title,
    value: false,
  },
  {
    key: 2,
    label: professionalExperience.title,
    value: false,
  },
  {
    key: 3,
    label: skills.title,
    value: false,
  },
  {
    key: 4,
    label: professionalSummary.title,
    value: false,
  },
];

const supportingDetails = [
  {
    key: 5,
    label: licenses.title,
    value: false,
  },
  {
    key: 6,
    label: awards.title,
    value: false,
  },
  {
    key: 7,
    label: volunteerExp.title,
    value: false,
  },
  {
    key: 8,
    label: publications.title,
    value: false,
  },
];

const LayoutStack = styled(Stack)(({theme}) => ({
  [theme.breakpoints.up('md')]: {width: `calc(100% - 80px)`},
}));

const ResumeBuilder = () => {
  const theme = useTheme();
  const formRef = useRef();
  const drawerRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [mode, setMode] = useState('add');
  const {enqueueSnackbar} = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [showModal, handleModal] = useState(false);
  const [highlightStep, setHighlightStep] = useState(-1);
  const {request: userRequest, data: userData} = useUser();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {request: statesRequest} = useSelector(getStates) || {};
  const {data: resumeList, request: resumeListRequest} =
    useSelector(getResumeList) || {};
  const {data: resumeData = {}, request: resumeDataRequest} =
    useSelector(getResumeData) || {};
  const {data: awardTypes = []} = useSelector(getAwardTypes);
  const {request: linkedInRequest} = useSelector(getLinkedInDetails) || {};
  const {
    location: {search},
  } = history;
  const params = queryStringParse(search);
  const {id: resume_uuid} = params;

  const onCloseModal = () => {
    handleModal(false);
  };

  useEffect(() => {
    dispatch(fetchStates());
    dispatch(fetchSkills());
    dispatch(fetchResumeList());
    dispatch(fetchAwardTypes());
    dispatch(fetchlinkedInDetails('job_title', 'all'));
    dispatch(fetchlinkedInDetails('skill_name', 'all'));
    dispatch(fetchlinkedInDetails('degree_name', 'all'));
    dispatch(fetchlinkedInDetails('field_of_study', 'all'));
    return () => {
      dispatch(clearResume());
      dispatch(resetResumeData());
      dispatch(clearLinkedInDetails());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userData?.email && resume_uuid) {
      dispatch(fetchResumeDetails(resume_uuid));
    }
    return () => {
      dispatch(initiateResumeData({}));
    };
  }, [userData, resume_uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resumeList && Array.isArray(resumeList) && resumeList.length === 0) {
      setHighlightStep(0);
    }
  }, [resumeList]);

  useEffect(() => {
    updateSupportingDetails(resumeData);
    updateEssentialInformation(resumeData);
  }, [resumeData]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateEssentialInformation = resumeData => {
    essentialInformation.forEach(step => {
      switch (step.key) {
        // Basic Information
        case 0:
          step.value = true;
          break;
        // Education
        case 1:
          step.value =
            resumeData?.credentials &&
            Array.isArray(resumeData?.credentials) &&
            resumeData?.credentials?.filter(
              item => item.credential_type === 'education',
            ).length > 0
              ? true
              : false;
          break;
        // Employment History
        case 2:
          step.value =
            resumeData?.work_exp &&
            Array.isArray(resumeData?.work_exp) &&
            resumeData?.work_exp.length > 0
              ? true
              : false;
          break;
        // Additional Skills
        case 3:
          step.value =
            resumeData?.work_exp &&
            Array.isArray(resumeData?.skills) &&
            resumeData?.skills.length > 0
              ? true
              : false;
          break;
        // Professional Summary
        case 4:
          step.value =
            typeof resumeData?.summary === 'string' &&
            resumeData?.summary.trim().length > 0
              ? true
              : false;

          break;
        default:
          break;
      }
    });
  };

  const updateSupportingDetails = resumeData => {
    const awardItem = awardTypes?.find(i => i.item_type === 'Award');

    supportingDetails.forEach(step => {
      switch (step.key) {
        // Licenses / Certifications
        case 5:
          step.value =
            resumeData?.credentials &&
            Array.isArray(resumeData?.credentials) &&
            resumeData?.credentials?.filter(
              item => item.credential_type === 'license',
            ).length > 0
              ? true
              : false;
          break;
        // Awards
        case 6:
          step.value =
            resumeData?.awards_publications?.filter(
              item => item.item_type === awardItem?.item_type_uuid,
            )?.length > 0
              ? true
              : false;
          break;
        // Volunteer Experience
        case 7:
          step.value =
            resumeData?.volunteer_exp &&
            Array.isArray(resumeData?.volunteer_exp) &&
            resumeData?.volunteer_exp.length > 0
              ? true
              : false;
          break;
        // Publications
        case 8:
          step.value =
            resumeData?.awards_publications?.filter(
              item => item.item_type !== awardItem?.item_type_uuid,
            )?.length > 0
              ? true
              : false;
          break;
        default:
          break;
      }
    });
  };

  const getOverallCompletion = resumeData => {
    updateSupportingDetails(resumeData);
    updateEssentialInformation(resumeData);
    const essentialInfoCompletionPercentage =
      essentialInformation?.filter(item => item.value).length * 16;

    const supportingDetailsCompletionPercentage =
      supportingDetails?.filter(item => item.value).length * 5;

    return (
      essentialInfoCompletionPercentage + supportingDetailsCompletionPercentage
    );
  };

  const handleBack = () => {
    dispatch(updateResumeData(activeStep - 1, null));
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const saveResumeData = async (resumeFormData, currStep, btnType) => {
    //btnType: 'next' | 'publish' | 'save'
    const overallCompletion = getOverallCompletion({
      ...resumeData,
      ...(resumeFormData || {}),
    });

    if (
      resumeFormData &&
      resumeFormData.credentials &&
      resumeFormData.credentials.length > 0
    ) {
      resumeFormData.credentials.forEach(cred => {
        if (cred.description && isOnlyWhitespace(cred.description)) {
          cred.description = '';
        }
      });
    }
    if (resumeFormData) {
      const payload = {
        resume_strength: overallCompletion,
        // status: resumeList?.length === 0 ? true : false,
        ...resumeFormData,
      };
      if (!resume_uuid) {
        payload.status = resumeList?.length === 0 ? true : false;
      }

      const isSaved = await dispatch(updateResumeData(currStep, payload));

      if (isSaved) {
        if (btnType === 'publish') {
          handleModal(true);
        } else if (btnType === 'next') {
          enqueueSnackbar(resumeSavedText, {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        }
        setActiveStep(currStep);
      } else {
        const msg =
          btnType === 'publish' ? resumeNotPublishedText : resumeNotSavedText;
        enqueueSnackbar(msg, {
          variant: 'warning',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    } else {
      if (btnType === 'publish') {
        handleModal(true);
      } else {
        let payload = null;
        if (resumeData?.resume_strength !== overallCompletion) {
          payload = {resume_strength: overallCompletion};
        }
        dispatch(updateResumeData(currStep, payload));
        setActiveStep(currStep);
      }
    }
  };

  const handleNext = () => {
    if (validateOnNext()) {
      const resumeFormData = formRef?.current?.getFormData();
      saveResumeData(resumeFormData, activeStep + 1, 'next');
    } else {
      enqueueSnackbar(updateRequiredFieldText, {
        variant: 'warning',
        anchorOrigin: {horizontal: 'center', vertical: 'top'},
      });
    }
  };

  const handlePublish = () => {
    if (resumeData?.resume_uuid) {
      if (validateOnNext()) {
        const resumeFormData = formRef?.current?.getFormData();
        saveResumeData(resumeFormData, activeStep, 'publish');
      } else {
        enqueueSnackbar(updateRequiredFieldText, {
          variant: 'warning',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    } else {
      enqueueSnackbar(saveBeforePublishText, {
        variant: 'info',
        anchorOrigin: {horizontal: 'center', vertical: 'top'},
      });
    }
  };

  const handleSave = resumeFormData => {
    saveResumeData(resumeFormData, activeStep, 'save');
  };

  const validateOnNext = () => {
    return formRef?.current ? formRef?.current?.validateForm() : true;
  };

  const handleAddToResumeToggle = toggleKey => {
    const payload = {[toggleKey]: !resumeData[toggleKey]};
    dispatch(patchResumeData(payload, activeStep));
  };

  const toggleDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.toggle();
    }
  };

  const startTour = () => {
    setHighlightStep(0);
  };

  const handleStepChange = currStep => {
    dispatch(updateResumeData(currStep, null));
    setActiveStep(currStep);
  };

  return (
    <BackgroundPaper isPurple py={0}>
      <LayoutBanner startTour={startTour} />
      <Box px={{xs: 0, sm: 5}}>
        <RequestErrorLoader
          fullScreen
          hideEmpty
          body={{
            request:
              userRequest ||
              statesRequest ||
              resumeListRequest ||
              linkedInRequest ||
              resumeDataRequest,
            data: userData,
          }}
        />
        <LayoutStack
          spacing={1}
          justifyContent='space-between'
          direction={isMobile ? 'column-reverse' : 'row'}>
          <Box sx={{width: isMobile ? 1 : '45%'}}>
            <HighLighter
              showMobile
              direction='right'
              scrollToId='stepper'
              active={highlightStep === 0}
              instructions={stepperDescription}
              onNext={() => setHighlightStep(1)}
              // onBack={() => setHighlightStep(0)}
            >
              <Stepper
                mode={mode}
                steps={STEPS}
                formRef={formRef}
                resumeData={resumeData}
                activeStep={activeStep}
                handleSave={handleSave}
                handleNext={handleNext}
                handleBack={handleBack}
                resume_uuid={resume_uuid}
                handlePublish={handlePublish}
                handleAddToResumeToggle={handleAddToResumeToggle}
              />
            </HighLighter>
          </Box>
          <Preview
            toggleDrawer={toggleDrawer}
            active={highlightStep}
            onBack={setHighlightStep}
            onNext={setHighlightStep}
          />
        </LayoutStack>
        <SuccessModal showModal={showModal} onClose={onCloseModal} />
        <HighLighter
          isEnd
          direction='left'
          scrollToId='drawer'
          active={highlightStep === 2}
          instructions={drawerDescription}
          onBack={() => setHighlightStep(1)}
          onNext={() => setHighlightStep(-1)}
          forcePosition={{
            right: 380,
            top: 'calc(50% - 64px)',
          }}>
          <Drawer
            drawerRef={drawerRef}
            key={resumeData?.resume_uuid}
            forceOpen={highlightStep === 2 && !isMobile}>
            <ResumeDetails
              handleStepChange={handleStepChange}
              resumeName={resumeData?.resume_name}
              supportingDetails={supportingDetails}
              essentialInformation={essentialInformation}
              resumeStrengthValue={resumeData?.resume_strength}
            />
          </Drawer>
        </HighLighter>
      </Box>
    </BackgroundPaper>
  );
};

export default ResumeBuilder;

import {useEffect} from 'react';
import Box from '@mui/material/Box';
import {connect} from 'react-redux';
import Banner from './components/Banner';
import {styled} from '@mui/material/styles';
import {Grid, AppBreadcrumb} from 'mui-core';
import DashboardRoutes from './DashboardRoutes';
import Container from '@mui/material/Container';
import Navigation from './components/Navigation';
import {
  fetchProfileData,
  updateUserProfile,
  profileDataSelector,
  fetchStudentMetrics,
  studentMetricsSelector,
  fetchStudentCredentials,
  studentCredentialsSelector,
} from 'redux/modules/profile';
import {useLocation, useRouteMatch} from 'react-router-dom';

let scroller;
const onScroll = () => {
  const el = document.getElementById('dashboard-nav');
  if (el) {
    if (window.scrollY > 330) {
      el.classList.add('stick');
    } else {
      el.classList.remove('stick');
    }
  }
};

const ProfileContainer = styled(Box)(({theme}) => ({
  background:
    theme.palette.mode === 'light'
      ? 'radial-gradient(50% 50% at 50% 50%, rgba(128, 80, 244, 0.26) 0%, #F9F8FF 100%)'
      : theme.palette.background.paper,
  // backgroundPosition: 'fixed',
}));

const getBreadcrumbLink = (path, modules) => {
  if (!path) path = '/';
  return path && modules?.find(module => module.path === path)?.label;
};

const DashBoardContainer = props => {
  const {
    submodules,
    profileData,
    fetchProfileData,
    studentCredentials,
    fetchStudentCredentials,
  } = props;
  const {url} = useRouteMatch();
  const {pathname} = useLocation();
  const path = pathname && pathname.trim().split(url).join('');

  useEffect(() => {
    !profileData?.data && !profileData?.request && fetchProfileData();
    (!studentCredentials?.data || !studentCredentials?.data?.length) &&
      !studentCredentials?.request &&
      fetchStudentCredentials();
    scrollerEvent();
    return () => {
      scroller.removeEventListener('scroll', onScroll, false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollerEvent = () => {
    scroller = window;
    scroller.addEventListener('scroll', onScroll, false);
  };

  return (
    <ProfileContainer>
      <Container>
        <AppBreadcrumb
          showHome={true}
          dataList={[
            {
              name: getBreadcrumbLink(path, submodules),
            },
          ]}
        />
        <Banner {...props} />
        <Grid container spacing={2}>
          <Grid xs={12} md={'auto'}>
            <Navigation modules={submodules} />
          </Grid>
          <Grid item xs={12} md id='dashboard-content' pb={4}>
            <DashboardRoutes modules={submodules} />
          </Grid>
        </Grid>
      </Container>
    </ProfileContainer>
  );
};

const mapStateToProps = state => ({
  profileData: profileDataSelector(state),
  studentMetrics: studentMetricsSelector(state),
  studentCredentials: studentCredentialsSelector(state),
});

export default connect(mapStateToProps, {
  fetchProfileData,
  updateUserProfile,
  fetchStudentMetrics,
  // fetchActivePathData,
  fetchStudentCredentials,
})(DashBoardContainer);

import {ContentBox, ErrorBoundary, RequestErrorLoader} from 'mui-core';
import {education} from 'data/settings-profile.json';
import {EditProfileWrapper} from '../../components';
import {useFormatCredentailData} from 'core/hooks';
import {Typography} from '@mui/material';
import Education from './Education';

const ProfileEducation = props => {
  const {
    activePaths = {},
    studentCredentials = {},
    skills: {request: SkillsRequest} = {},
  } = props;
  const {subHeading, collapseBarHeading} = education || {};
  const {data: studentCredentialData, request, error} = studentCredentials;
  const {request: activePathsRequest, data: activePathsData = []} = activePaths;

  const {education: EducationData} = useFormatCredentailData(
    studentCredentialData,
    activePathsData,
  );
  return (
    <ContentBox variant='outlined' sx={{mb: 2, p: 3}}>
      <Typography
        mb={1}
        variant='h5'
        color='text.primary'
        title={collapseBarHeading}
        // fontFamily='fontFamilyBold'
      >
        {collapseBarHeading}
      </Typography>
      <Typography variant='body1' title={subHeading} color='text.secondary'>
        {subHeading}
      </Typography>
      <ErrorBoundary
        nameOfComponent='dashboard-profile-education'
        typeOfUi='subPage'>
        <RequestErrorLoader
          hideEmpty
          fullWidth
          body={{
            data: studentCredentialData,
            request: request || SkillsRequest || activePathsRequest,
            error,
          }}>
          <Education {...props} educationData={EducationData} />
        </RequestErrorLoader>
      </ErrorBoundary>
    </ContentBox>
  );
};

export default EditProfileWrapper(ProfileEducation);

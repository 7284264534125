import {PATTERN} from 'core/regex';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import {Button, Grid} from 'mui-core';
import Stack from '@mui/material/Stack';
import {createField} from 'mui-core/Form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import OutlinedInput from '@mui/material/OutlinedInput';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import profileData, {volunteerExperienceData} from 'data/settings-profile.json';
const {addVolunteerExperienceBtn} = volunteerExperienceData || {};

const AddVolExperience = ({
  edit,
  onSubmit,
  onCancel,
  firstTime,
  data: {name, hours, roles_responsibilities} = {},
}) => {
  const form = useForm({
    defaultValues: {
      name: name || '',
      hours: hours || '',
      roles_responsibilities: roles_responsibilities?.sort(
        (a, b) => a.display_order - b.display_order,
      ) || [{roles_responsibilities: ''}],
    },
  });
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = form;
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'roles_responsibilities',
  });

  const onFinish = (data, e) => {
    if (onSubmit && isEmpty(errors)) {
      onSubmit(data);
    }
  };

  return (
    <Box>
      <Stack>
        {!edit && !firstTime && (
          <Typography
            variant='h5'
            fontSize={18}
            fontWeight='600'
            color='text.primary'
            // fontFamily='fontFamilyBold'
            title={addVolunteerExperienceBtn}>
            {addVolunteerExperienceBtn}
          </Typography>
        )}
        <Box
          mx='auto'
          my={2}
          sx={{width: {md: '60%', sm: '60%', lg: '60%', xs: '100%'}}}>
          <form onSubmit={handleSubmit(onFinish)}>
            <Grid
              container
              spacing={4}
              display='flex'
              alignItems='center'
              flexDirection='column'
              justifyContent='center'>
              <Grid item xs={12}>
                {createField('name', {
                  form,
                  defaultValue: name || '',
                  label: volunteerExperienceData.experience.label,
                  rules: {
                    required: [
                      true,
                      volunteerExperienceData.experience.requiredMsg,
                    ],
                    maxLength: [
                      60,
                      volunteerExperienceData.experience.maxLengthMsg,
                    ],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='volunteer-exp-name'
                    label={volunteerExperienceData.experience.label}
                    placeholder={volunteerExperienceData.experience.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {createField('hours', {
                  form,
                  defaultValue: hours || '',
                  label: volunteerExperienceData.hours.label,
                  rules: {
                    required: [true, volunteerExperienceData.hours.requiredMsg],
                    pattern: [
                      PATTERN?.Number_With_Float,
                      volunteerExperienceData.hours.validationMsg,
                    ],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id='work-exp-hours'
                    label={volunteerExperienceData.hours.label}
                    placeholder={volunteerExperienceData.hours.placeholder}
                  />,
                )}
              </Grid>
              <Grid item xs={12} pt={2}>
                <Stack direction='column' spacing={4}>
                  {fields.map((item, index) => {
                    let currentErrorObject = {};
                    errors?.roles_responsibilities?.length &&
                      errors?.roles_responsibilities.forEach((errObj, idx) => {
                        if (idx === index) {
                          currentErrorObject = errObj;
                        }
                      });
                    return (
                      <Stack
                        spacing={4}
                        key={item.id}
                        direction='row'
                        alignItems='center'>
                        <Controller
                          control={control}
                          defaultValue={item.roles_responsibilities}
                          name={`roles_responsibilities.${index}.roles_responsibilities`}
                          rules={{
                            maxLength: {
                              value: 250,
                              message:
                                volunteerExperienceData.rolesResponsibilities
                                  .maxLengthMsg,
                            },
                          }}
                          render={({field}) => (
                            <TextField
                              fullWidth
                              {...field}
                              key={item.id}
                              placeholder={
                                volunteerExperienceData.rolesResponsibilities
                                  .placeholder
                              }
                              helperText={
                                currentErrorObject?.roles_responsibilities
                                  ?.message
                              }
                              label={
                                volunteerExperienceData.rolesResponsibilities
                                  .label
                              }
                              error={currentErrorObject?.roles_responsibilities}
                            />
                          )}
                        />
                        <IconButton
                          py='auto'
                          onClick={() => {
                            remove(index);
                          }}>
                          <DeleteIcon
                            sx={{fontSize: {xs: 16, sm: 18, md: 20, lg: 24}}}
                            color='primary'
                          />
                        </IconButton>
                      </Stack>
                    );
                  })}
                  <Button
                    sx={{width: '150px', alignSelf: 'flex-end'}}
                    onClick={() => {
                      append({roles_responsibilities: ''});
                    }}>
                    + Add Role
                  </Button>
                </Stack>
              </Grid>
              <Grid item>
                {!firstTime && (
                  <Button variant='outlined' onClick={onCancel} sx={{mr: 2}}>
                    {profileData.cancelBtnTxt}
                  </Button>
                )}
                <Button variant='contained' type='submit' formNoValidate>
                  {edit ? profileData.saveBtnTxt : profileData.addBtnTxt}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default AddVolExperience;

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestClusters,
  recieveClusters,
  requestClustersError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CLUSTERS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.CLUSTERS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLUSTERS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchClusters = () => (dispatch, getState) => {
  dispatch(requestClusters());
  try {
    let endPoint = `${API.srm.clusters}?cluster_type=1&path=true`;
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res && Array.isArray(res)) {
        dispatch(recieveClusters(res));
        return;
      }
      dispatch(requestClustersError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestClustersError(err));
  }
};

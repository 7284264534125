import * as TYPES from './constants';
import {API} from 'config';

import {
  requestPathOccupationSearch,
  recievePathOccupationSearch,
  requestPathOccupationSearchError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PATH_OCCUPATION_SEARCH_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.PATH_OCCUPATION_SEARCH_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.PATH_OCCUPATION_SEARCH_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchPathOccupationSearch = keyword => (dispatch, getState) => {
  dispatch(requestPathOccupationSearch());
  try {
    let endPoint = `${API.srm.search_path_occupation}?search=${keyword}`;
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res && res.occupation_data && res.path_data) {
        dispatch(recievePathOccupationSearch(res));
        return;
      }
      dispatch(requestPathOccupationSearchError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestPathOccupationSearchError(err));
  }
};

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CheckDoneIcon = props => {
  return (
    <SvgIcon
      {...props}
      viewBox='0 0 36 36'
      fontSize='inherit'
      style={{fontSize: props.fontSize || '1em'}}>
      <g id='CheckDone'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='36'
          height='36'
          viewBox='0 0 36 36'
          fill='none'>
          <path
            d='M9 22.5L12 25.5L18.75 18.75M12 12V7.8C12 6.11984 12 5.27976 12.327 4.63803C12.6146 4.07354 13.0735 3.6146 13.638 3.32698C14.2798 3 15.1198 3 16.8 3H28.2C29.8802 3 30.7202 3 31.362 3.32698C31.9265 3.6146 32.3854 4.07354 32.673 4.63803C33 5.27976 33 6.11984 33 7.8V19.2C33 20.8802 33 21.7202 32.673 22.362C32.3854 22.9265 31.9265 23.3854 31.362 23.673C30.7202 24 29.8802 24 28.2 24H24M7.8 33H19.2C20.8802 33 21.7202 33 22.362 32.673C22.9265 32.3854 23.3854 31.9265 23.673 31.362C24 30.7202 24 29.8802 24 28.2V16.8C24 15.1198 24 14.2798 23.673 13.638C23.3854 13.0735 22.9265 12.6146 22.362 12.327C21.7202 12 20.8802 12 19.2 12H7.8C6.11984 12 5.27976 12 4.63803 12.327C4.07354 12.6146 3.6146 13.0735 3.32698 13.638C3 14.2798 3 15.1198 3 16.8V28.2C3 29.8802 3 30.7202 3.32698 31.362C3.6146 31.9265 4.07354 32.3854 4.63803 32.673C5.27976 33 6.11984 33 7.8 33Z'
            stroke='url(#paint0_linear_31880_105914)'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <defs>
            <linearGradient
              id='paint0_linear_31880_105914'
              x1='33'
              y1='18'
              x2='3'
              y2='18'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color={props.startColor || '#000'} />
              <stop offset='0.76' stop-color={props.endColor || '#000'} />
            </linearGradient>
          </defs>
        </svg>
      </g>
    </SvgIcon>
  );
};

export default CheckDoneIcon;

import {useCheckMobileView} from 'core/hooks';
import ListboxComponent from './ListboxComponent';
import {grey, green, yellow, blueGrey} from '@mui/material/colors';
import {Autocomplete, TextField, CircularProgress} from '@mui/material';

const AutoComplete = props => {
  const {
    label,
    errors,
    request,
    options,
    onChange,
    fieldName,
    skillColor,
    placeholder,
    size = 'medium',
    required = false,
    hideTags = false,
    fullWidth = false,
    noOptionsText = 'No Results Found',
  } = props;
  const {isMobileView} = useCheckMobileView();

  const getSkillColor = () => {
    switch (skillColor) {
      case 'green': {
        return {
          backgroundColor: green[400],
          color: 'background.paper',
        };
      }
      case 'yellow': {
        return {
          backgroundColor: yellow[400],
          color: grey[900],
        };
      }
      case 'blueGrey': {
        return {
          backgroundColor: blueGrey[400],
          color: 'background.paper',
        };
      }
      default: {
        return {
          backgroundColor: 'primary.main',
          color: 'white',
        };
      }
    }
  };

  return (
    <Autocomplete
      {...props}
      size={size}
      id={fieldName}
      options={options}
      loading={request}
      onChange={onChange}
      fullWidth={fullWidth}
      filterSelectedOptions
      slotProps={{
        paper: {
          // sx: {boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.4)'}
        },
      }}
      noOptionsText={noOptionsText}
      ListboxComponent={ListboxComponent}
      getOptionLabel={option =>
        option && option.label ? `${option.label}` : option
      }
      isOptionEqualToValue={(option, value) => option.label === value.label} // to hide the options in the dropdown when selected.
      {...(hideTags ? {renderTags: () => null} : {})}
      renderInput={params => (
        <TextField
          {...params}
          error={!!errors}
          variant='outlined'
          placeholder={placeholder}
          disabled={props.disabled}
          label={required && label ? `${label}*` : label}
          InputProps={{
            ...params.InputProps,
            endAdornment: request ? (
              <CircularProgress size={22} />
            ) : (
              params.InputProps.endAdornment
            ),
          }}
        />
      )}
      ChipProps={{
        size: isMobileView ? 'small' : 'medium',
        sx: getSkillColor(),
      }}
      renderOption={(props, option, state) => [props, option, state.index]}
    />
  );
};

export default AutoComplete;

import {
  Box,
  Divider,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import DocUploader from 'app/modules/dashboard/components/DocUploader';
import {API} from 'config';
import {Button, RequestErrorLoader} from 'mui-core';
import {useState} from 'react';
import AddPortfolio from './AddPortfolio';
import {getFilesAndTotalSize} from 'core/utils';
import profileStaticData from 'data/settings-profile.json';

const document_type = 'portfolio_document';

const Portfolio = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const {
    updateProfile,
    profileData: {data, request, error},
  } = props;
  const [urlRequest, setUrlRequest] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const {portfolio_url} = data || {};
  const {data: document_files, total_size_consumed} = getFilesAndTotalSize(
    data,
    document_type,
  );
  const onPortfolioSubmit = async values => {
    setAddForm(false);
    if (values !== false) {
      const endPoint = `${API.gps.student_profile}/${data?.student_profile_uuid}/`,
        method = 'PATCH';
      setUrlRequest(true);
      await updateProfile(endPoint, method, values);
      setUrlRequest(false);
    }
    setAddForm(false);
  };
  return (
    <>
      <RequestErrorLoader
        body={{
          data,
          error,
          request: urlRequest || request,
        }}>
        {portfolio_url && !addForm && (
          <Box>
            <Stack
              direction={isMobile ? 'column-reverse' : 'row'}
              alignItems={isMobile ? 'right' : 'center'}
              my={2}
              mx={isMobile ? 0 : 2}
              justifyContent={'space-between'}>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'right' : 'center'}
                spacing={2}>
                <Typography variant='h3' fontSize={16} color={'text.primary'}>
                  Portfolio URL:
                </Typography>
                <Typography
                  component={'a'}
                  href={portfolio_url}
                  fontSize={isMobile ? '12px' : '16px'}
                  target='_blank'
                  color={'secondary'}>
                  {portfolio_url}
                </Typography>
              </Stack>
              <Stack direction={isMobile ? 'row-reverse' : 'row'}>
                <Button onClick={() => setAddForm(true)} size='small'>
                  Edit
                </Button>
                <Button
                  onClick={() => onPortfolioSubmit({portfolio_url: ''})}
                  color='error'
                  size='small'>
                  Delete
                </Button>
              </Stack>
            </Stack>
            <Divider />
          </Box>
        )}

        {!addForm && (
          <Stack direction={'row'} spacing={3} justifyContent='center' mt={4}>
            {!portfolio_url && (
              <>
                <Button
                  variant={'contained'}
                  sx={{width: 220}}
                  onClick={() => setAddForm(true)}>
                  Add Portfolio URL
                </Button>
                <Divider />
              </>
            )}
          </Stack>
        )}
        {!addForm && (
          <Box mt={portfolio_url ? 1 : 5}>
            <DocUploader
              {...props}
              noLoader
              type={document_type}
              title='Upload Documents : '
              maxFileSize={profileStaticData.portfolioData.maxFileSize}
              profileData={{
                data: document_files,
                total_size_consumed,
                request,
                error,
              }}
            />
          </Box>
        )}

        {addForm && (
          <AddPortfolio onSubmit={onPortfolioSubmit} data={portfolio_url} />
        )}
      </RequestErrorLoader>
    </>
  );
};

export default Portfolio;

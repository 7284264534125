import * as TYPES from './constants';
import {API} from 'config';

import {Services} from 'core/Services';
import {
  goSurveyResultsError,
  goSurveyResultsSuccess,
  requestGoSurveyResults,
} from './actions';
const services = new Services();

const initialState = {request: false, data: null, error: false};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GO_SURVEY_RESULTS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.GO_SURVEY_RESULTS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.GO_SURVEY_RESULTS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.GO_SURVEY_RESULTS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const fetchGoSurveyResults = callback => (dispatch, getState) => {
  const {
    profile: {user},
    auth: {auth: {token = null} = {}},
  } = getState();
  const student_uuid = user?.data?.student_uuid;

  try {
    if (student_uuid) {
      dispatch(requestGoSurveyResults());
      const endPoint = `${API.gps.student_cis_data}?chatgpt_response=true&student_uuid=${student_uuid}`;
      return services.fetchRecords(endPoint, token, true).then(data => {
        if (data?.[0]?.cis_gpt_response?.career_suggestions) {
          dispatch(
            goSurveyResultsSuccess(
              data?.[0]?.cis_gpt_response?.career_suggestions,
            ),
          );
          callback && callback(data?.[0]?.cis_gpt_response?.career_suggestions);
        } else {
          dispatch(goSurveyResultsSuccess([]));
          callback && callback([]);
        }
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(goSurveyResultsError(err));
  }
};

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Switch from '@mui/material/Switch';
import {styled} from '@mui/material/styles';
import {
  getPrivacySetting,
  fetchPrivacySetting,
  updatePrivacySetting,
} from 'redux/modules/profile';
import CloseIcon from '@mui/icons-material/Close';
import {content} from 'data/profileSettings.json';
import {getAppConfig} from 'redux/modules/general';
import {RequestErrorLoader, ErrorBoundary, Button} from 'mui-core';
import {Modal, Typography, Box, Grid, Divider} from '@mui/material';
import {fetchProfileData, profileDataSelector} from 'redux/modules/profile';

const PrivacySetting = props => {
  const {
    privacy,
    profileData,
    fetchProfileData,
    fetchPrivacySetting,
    setPrivacySuccessMsg,
    updatePrivacySetting,
    openPrivacySettingModal,
    handleClosePrivacySetting,
  } = props;

  let toggleDataObj = {};

  const {data: privacyData} = privacy || {};

  let id = privacyData !== undefined && privacyData['privacy_uuid'];

  const onChange = (checked, data) => {
    toggleDataObj[data] = checked.target.checked;
  };

  const handlesubmit = () => {
    handleClosePrivacySetting();
    updatePrivacySetting(id, toggleDataObj, res => {
      if (res.privacy_uuid) {
        setTimeout(() => {
          fetchPrivacySetting();
          setPrivacySuccessMsg('Success! Profile privacy changes are updated.');
        }, 1000);
      }
    });
  };
  useEffect(() => {
    !profileData?.data && !profileData?.request && fetchProfileData();
    fetchPrivacySetting();

    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const StyledBox = styled(Box)(({theme}) => {
    return {
      [theme.breakpoints.down('md')]: {
        maxWidth: 600,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '90vw',
      },
    };
  });
  return (
    <ErrorBoundary nameOfComponent='myProfile-privacy-setting'>
      <Modal open={openPrivacySettingModal} onClose={handleClosePrivacySetting}>
        <StyledBox
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            overflowY: 'auto',
            maxWidth: 1041,
            maxHeight: 900,
            p: 3,
            width: '100%',
            maxHeight: 'calc(100% - 64px)',
          }}>
          <RequestErrorLoader
            hideBackground
            body={{
              data: privacyData,
              request: privacy.request,
            }}>
            <Grid
              mb={2}
              display='flex'
              direction='row'
              alignItems='center'
              justifyContent='space-between'>
              <Typography variant='h5' fontWeight={600} color='text.primary'>
                My Profile Privacy Settings
              </Typography>
              <Button onClick={handleClosePrivacySetting}>
                <CloseIcon sx={{color: 'text.primary'}} />
              </Button>
            </Grid>

            <Grid container>
              {content &&
                content.map((res, index) => (
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    sm={12}
                    key={index}
                    display='flex'
                    direction='column'
                    justifyContent='flex-end'
                    sx={{
                      mt: 2,
                      borderRadius: '4px',
                      boxShadow: '0px 0px 0px 1px #E0E0E0',
                    }}>
                    <Box
                      item
                      sx={{
                        p: 2,
                        display: 'flex',
                        borderRadius: '4px',
                        alignItems: 'center',
                        backgroundColor: '#AF8EFF',
                        justifyContent: 'space-between',
                      }}>
                      <Typography variant='h5' color='white'>
                        {res.cardheader}
                      </Typography>
                    </Box>
                    {res.cardcontent.map((content, index) => (
                      <React.Fragment key={index}>
                        <Grid
                          key={index}
                          item
                          sx={{
                            p: 1.2,
                            display: 'flex',
                            direction: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                          lg={6}
                          xs={12}
                          sm={12}>
                          <Typography variant='body1' color='text.primary'>
                            {content.info}
                          </Typography>

                          <Grid lg={6}>
                            {privacyData && (
                              <Switch
                                color='success'
                                defaultChecked={privacyData[content.key]}
                                onChange={checked =>
                                  onChange(checked, content.key)
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </Grid>
                ))}
            </Grid>

            <Box mt={3} direction='row' display='flex' justifyContent='center'>
              <Button variant='contained' onClick={handlesubmit}>
                Save And Close
              </Button>
            </Box>
          </RequestErrorLoader>
        </StyledBox>
      </Modal>
    </ErrorBoundary>
  );
};

PrivacySetting.propTypes = {
  setPrivacySuccessMsg: PropTypes.func,
  openPrivacySettingModal: PropTypes.bool,
  handleClosePrivacySetting: PropTypes.func,
};

const mapStateToProps = state => ({
  appConfig: getAppConfig(state),
  profileData: profileDataSelector(state),
  privacy: getPrivacySetting(state),
});

export default connect(mapStateToProps, {
  fetchProfileData,
  fetchPrivacySetting,
  updatePrivacySetting,
})(PrivacySetting);

import {useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {useCheckMobileView} from 'core/hooks';
import {Stack, Box, Chip, Button, Typography} from '@mui/material';

const StyledSkillsContainer = styled(Box)(({theme, margin}) => ({
  '> div': {
    overflowY: 'auto',
    minHeight: 'auto',
    alignContent: 'flex-start',
    margin: margin || '12px 8px 0px 0px !important',
  },
  h5: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
  },
}));

const CutsomChip = styled(Chip)(() => ({
  height: 'initial',
  padding: '6px 2px',
  '& .MuiChip-label': {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
}));

const SkillsStack = ({
  margin,
  displayLimit,
  skillSet = [],
  color = 'white',
  displayShowAll = false,
  emptyMsg = 'No Skills Found',
  showAllColor = 'primary.main',
  backgroundColor = 'primary.main',
}) => {
  const {isMobileView} = useCheckMobileView();
  const [isShowAll, setIsShowAll] = useState(false);
  const onShowClick = () => setIsShowAll(!isShowAll);
  const displaySet = !isShowAll ? skillSet.slice(0, displayLimit) : skillSet;

  return (
    <StyledSkillsContainer margin={margin}>
      <Stack direction='row' flexWrap='wrap' rowGap={1} columnGap={1}>
        {displaySet?.length ? (
          displaySet.map((skill, idx) => (
            <CutsomChip
              size={isMobileView ? 'small' : 'medium'}
              key={`skill-${idx}`}
              sx={{
                color: color,
                textTransform: 'capitalize',
                backgroundColor: backgroundColor,
              }}
              label={skill}
            />
          ))
        ) : (
          <Typography variant='h5' color='text.primary'>
            {emptyMsg}
          </Typography>
        )}
      </Stack>
      {displayShowAll && skillSet.length > displayLimit && (
        <Button onClick={onShowClick} sx={{mt: 1, color: showAllColor}}>
          {isShowAll ? 'Show Less' : 'Show All'}
        </Button>
      )}
    </StyledSkillsContainer>
  );
};

SkillsStack.propTypes = {
  color: PropTypes.string,
  margin: PropTypes.string,
  skillSet: PropTypes.array,
  emptyMsg: PropTypes.string,
  displayLimit: PropTypes.number,
  displayShowAll: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

export default SkillsStack;

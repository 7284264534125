import {useState, useEffect, useCallback, useMemo} from 'react';
import {API} from 'config';
import moment from 'moment';
import Sorter from './Sorter';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import {Stack, Typography} from '@mui/material';
import JobMatchStats from './JobMatchStats';
import {useCheckMobileView} from 'core/hooks';
import {NoContentNavigator, ErrorBoundary, DataGrid} from 'mui-core';
import {Link} from 'react-router-dom';

const MyJobMatches = ({
  fetchNewOpportunities,
  resetNewOpportunities,
  studentCredentials,
  isClubbedResults,
  studentMetrics,
  studentSkills,
  updateProfile,
  profileData,
  jobMatches,
}) => {
  const {isMobileView} = useCheckMobileView();
  const {data: studentSkillsData = []} = studentSkills || {};
  const {data: studentCredentialsData = []} = studentCredentials || {};
  const {data: jobMatchData = {}, request: jobMatchRequest} = jobMatches || {};
  const {data: profileInfo = {}, request: profileRequest} = profileData || {};
  const {job_match_min_perct = 50, student_profile_uuid} = profileInfo || {};

  const defaultReqParams = {
    page: 1,
    page_size: 10,
    sort: 'date_desc',
    min_perct: job_match_min_perct,
  };

  const [filters, setFilters] = useState({});
  const [reqParams, setReqParams] = useState(defaultReqParams);
  const dataLength = jobMatchData?.datalength || 0;
  const TotalRecords = jobMatchData?.Jobcount || 0;
  const calcData = {
    totalRecords: TotalRecords,
    page: reqParams.page,
    length: dataLength,
  };

  const onPageChange = useCallback(
    page => {
      const Obj = Object.assign({}, reqParams, {
        page: page + 1,
        page_size: reqParams.page_size,
      });
      refetchData(Obj, filters);
      window.scrollTo({
        top: 300,
        behavior: 'smooth',
      });
    },
    [reqParams, filters], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const onPageSizeChange = useCallback(
    pageSize => {
      const Obj = Object.assign({}, reqParams, {page_size: pageSize});
      refetchData(Obj, filters);
      window.scrollTo({
        top: 300,
        behavior: 'smooth',
      });
    },
    [filters, reqParams], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const onSliderChange = useCallback(
    async sliderValue => {
      const endpoint = `${API.gps.student_profile}/${student_profile_uuid}`;
      await updateProfile(endpoint, 'PATCH', {
        job_match_min_perct: sliderValue,
      });
      const Obj = Object.assign({}, reqParams, {min_perct: sliderValue});
      refetchData(Obj, filters);
      window.scrollTo({
        top: 300,
        behavior: 'smooth',
      });
    },
    [filters, reqParams], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    (!jobMatchData || isEmpty(jobMatchData)) &&
      !jobMatchRequest &&
      fetchNewOpportunities(reqParams, filters);
    return () => resetNewOpportunities();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Frame the table with required columns
  const getColumns = useMemo(() => {
    return [
      {
        headerName: 'TITLE',
        field: 'title',
        editable: false,
        filterable: false,
        sortable: false,
        key: 'title',
        minWidth: 180,
        flex: 0.3,
        renderCell: (record = {}) => {
          const {title} = record.row || {};
          const {text, type} = title || {};
          const getjobsViewURL = record => {
            console.log('getjobsViewURL', record);
            let params = '';
            if (record && record.job_type !== 'job') {
              params = `?job_type=${record.job_type}`;
            }
            return record.job_source === 'cos'
              ? `/opportunity/board/${record.key}`
              : `/opportunity/local/${encodeURIComponent(
                  record.title?.text,
                )}/${encodeURIComponent(record.company)}${params}`;
          };
          return (
            <Link
              title={text}
              target='_target'
              to={getjobsViewURL(record?.row || {})}>
              {text}
            </Link>
          );
        },
      },
      {
        headerName: 'COMPANY',
        field: 'company',
        editable: false,
        filterable: false,
        sortable: false,
        key: 'company',
        minWidth: 180,
        flex: 0.3,
        renderCell: record => {
          const {company} = record.row || {};
          return (
            <Typography variant='body2' title={company}>
              {company}
            </Typography>
          );
        },
      },
      {
        headerName: 'LOCATION',
        field: 'location',
        editable: false,
        filterable: false,
        sortable: false,
        key: 'location',
        minWidth: 150,
        flex: 0.2,
        renderCell: record => {
          const {location} = record.row || {};
          return (
            <Typography variant='body2' title={location}>
              {location}
            </Typography>
          );
        },
      },
      {
        headerName: 'DATE POSTED',
        field: 'date',
        flex: 0.2,
        editable: false,
        filterable: false,
        sortable: false,
        minWidth: 130,
        key: 'date',
      },
      {
        headerName: 'SKILLS MATCH',
        editable: false,
        filterable: false,
        // flex: 0.15,
        width: 130,
        sortable: false,
        field: 'skill_match_percentage',
        key: 'skills-match',
      },
    ];
  });

  const refetchData = useCallback((Obj, filters = null) => {
    setReqParams(Obj);
    fetchNewOpportunities(Obj, filters);
  });

  const onFinish = useCallback(
    values => {
      let filterApply = false;
      if (values) {
        const filterKeys = Object.keys(values);
        filterKeys.forEach(key => {
          if (values[key] || values[key] === '') {
            filterApply = true;
          }
        });
        if (filterApply) {
          const Obj = Object.assign({}, reqParams, {
            sort: 'date_desc',
            page: 1,
          });
          setFilters(values);
          refetchData(Obj, values);
        }
      }
    },
    [reqParams], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getRoundVal = v => {
    try {
      return v ? Math.round(v) : v;
    } catch (err) {
      console.error(err);
      return v;
    }
  };

  const onFilterReset = () => {
    const Obj = Object.assign({}, defaultReqParams);
    setFilters({});
    refetchData(Obj, null);
  };

  const onParamsChange = (key, value) => {
    const Obj = Object.assign({}, reqParams, {[key]: value, page: 1});
    refetchData(Obj, filters);
  };

  // Frame the Data in required table format
  const getSkillsOpportunityData = useMemo(() => {
    const actualData =
      (jobMatchData?.data &&
        Array.isArray(jobMatchData.data) &&
        jobMatchData?.data?.length > 0 &&
        jobMatchData.data) ||
      [];

    // Sort the Data in descending order based on skills match %
    reqParams?.sort === 'skills_match' &&
      actualData.sort((a, b) => b.skills_match - a.skills_match);
    let skills_opportunity_data = [];
    actualData &&
      actualData.length > 0 &&
      actualData.map(skills_data =>
        skills_opportunity_data.push({
          key: skills_data?.job_id,
          skill_match_percentage:
            getRoundVal(skills_data?.skill_match_percentage) + '%',
          date: moment(skills_data?.date_posted).format('MM/DD/YYYY'),
          company: skills_data?.company,
          job_type: skills_data?.job_type,
          job_source: skills_data?.job_source,
          location: skills_data?.location || '-',
          // type:
          //   skills_data?.opportunity_type?.charAt(0).toUpperCase() +
          //   skills_data?.opportunity_type.slice(1),
          title: {
            text: skills_data?.job_title,
            type: skills_data?.opportunity_type,
          },
        }),
      );
    return skills_opportunity_data;
  }, [jobMatches, reqParams]); // eslint-disable-line react-hooks/exhaustive-deps
  let bodyDataCheck = [];
  if (studentCredentialsData && studentCredentialsData.length > 0) {
    bodyDataCheck = studentCredentialsData;
  }
  if (studentSkillsData && studentSkillsData.length > 0) {
    bodyDataCheck = studentSkillsData;
  }
  if (jobMatchData?.data && jobMatchData?.data?.length > 0) {
    bodyDataCheck = jobMatchData?.data;
  }

  return (
    <ErrorBoundary
      typeOfUi='subPage'
      nameOfComponent='menu-dashboard-myJobMatches'>
      <Stack>
        {bodyDataCheck &&
        Array.isArray(bodyDataCheck) &&
        bodyDataCheck.length > 0 ? (
          <>
            <JobMatchStats
              calcData={calcData}
              studentMetrics={studentMetrics}
            />
            <Sorter
              {...reqParams}
              filters={filters}
              calcData={calcData}
              onFinish={onFinish}
              onChange={onParamsChange}
              isMobileView={isMobileView}
              onFilterReset={onFilterReset}
              onSliderUpdate={onSliderChange}
              isClubbedResults={isClubbedResults}
              request={jobMatchRequest || profileRequest}
            />
            <DataGrid
              pagination={true}
              hideFooter={false}
              columns={getColumns}
              paginationMode='server'
              onPageChange={onPageChange}
              totalRowCount={TotalRecords}
              pageSize={reqParams?.page_size}
              rows={getSkillsOpportunityData}
              getRowId={params => params.key}
              rowsPerPageOptions={[5, 10, 20]}
              onPageSizeChange={onPageSizeChange}
              loading={jobMatchRequest || profileRequest}
              page={reqParams && reqParams.page ? reqParams.page - 1 : 0}
            />
          </>
        ) : (
          <NoContentNavigator
            message='You have not added any skills to your profile. To get started add skills below'
            customClass='new-opportunities-no-skills'
            heading='No Matching Jobs'
            pathTo='/dashboard/skills'
            label='My Skills'
          />
        )}
      </Stack>
    </ErrorBoundary>
  );
};

MyJobMatches.propTypes = {
  fetchNewOpportunities: PropTypes.func,
  resetNewOpportunities: PropTypes.func,
  studentCredentials: PropTypes.object,
  isClubbedResults: PropTypes.bool,
  studentMetrics: PropTypes.object,
  studentSkills: PropTypes.object,
  jobMatches: PropTypes.object,
};

export default MyJobMatches;

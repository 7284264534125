import React from 'react';
import {View, Text} from '@react-pdf/renderer';

import SkillTag from './SkillTag';

export default ({styles, studentProfile}) => {
  const {interest = []} = studentProfile || {};

  const Interests = () => {
    return interest && Array.isArray(interest) && interest?.length > 0 ? (
      interest.map((int, idx) => (
        <SkillTag key={`int-${idx}`}>
          <Text wrap={false} key={idx}>
            {int.trim()}
          </Text>
        </SkillTag>
      ))
    ) : (
      <Text style={styles.noSkills}>N/A</Text>
    );
  };
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text break style={styles.headerText}>
          Interests
        </Text>
      </View>
      <View style={styles.content}>
        <View break style={styles.SkillsList}>
          {Interests()}
        </View>
      </View>
    </View>
  );
};

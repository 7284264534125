import {isArray} from 'lodash';
import * as TYPES from './constants';
import {API} from 'config';

import {
  requestSaveFitAssessmentSurvey,
  receiveSaveFitAssessmentSurvey,
  requestSaveFitAssessmentSurveyError,
  requestFitAssementSurvey,
  recieveFitAssementSurvey,
  requestFitAssementSurveyError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, requestSave: false, data: []};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.FIT_ASSESSMENT_SURVEY_SAVE_REQUEST:
      return {
        ...state,
        requestSave: true,
      };
    case TYPES.FIT_ASSESSMENT_SURVEY_SAVE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.FIT_ASSESSMENT_SURVEY_SAVE_SUCCESS:
      return {
        ...state,
        requestSave: false,
      };
    case TYPES.FIT_ASSESSMENT_SURVEY_REQUEST:
      return {
        ...state,
        request: true,
      };
    case TYPES.FIT_ASSESSMENT_SURVEY_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.FIT_ASSESSMENT_SURVEY_SUCCESS:
      return {
        ...state,
        request: false,
        data: action.payload,
      };
    case TYPES.CLEAR_FIT_ASSESSMENT_SURVEY:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const saveFitAssessmentSurvey = (data, callback) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestSaveFitAssessmentSurvey());
  try {
    let endPoint = `${API.gps.fit_assessment_survey}`;
    return services
      .createUpdateRecord(token, endPoint, data, 'POST')
      .then(res => {
        if (res) {
          dispatch(receiveSaveFitAssessmentSurvey(res));
          callback(res);
          return;
        }
        dispatch(requestSaveFitAssessmentSurveyError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestSaveFitAssessmentSurveyError(err));
  }
};

export const fetchFitAssessmentSurvey = student_uuid => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestFitAssementSurvey());
  try {
    let endPoint = `${API.gps.fit_assessment_survey}?student_uuid=${student_uuid}`;
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveFitAssementSurvey(res));
        // callback(res);
        // return;
      }
      dispatch(requestFitAssementSurveyError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestFitAssementSurveyError(err));
  }
};

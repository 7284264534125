import React, {useState, useEffect} from 'react';
import {
  Box,
  Stack,
  Divider,
  useTheme,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import {Grid, Button} from 'mui-core';
import AddEducation from './AddEducation';
import {Edit, Delete} from '@mui/icons-material';
import resumeBuilderData from 'data/resumeBuilder.json';

const {
  stepsMeta: {
    education: {formData = {}},
  },
} = resumeBuilderData || {};

const EducationList = ({data, formRef, onChange, viewOnly, getPayload}) => {
  const theme = useTheme();
  const {credentials = []} = data || {};
  const [editIndex, setEditIndex] = useState(-1);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {AddEducationTxt, editTxt, deleteTxt} = formData || {};
  const educationList =
    credentials.filter(
      credential => credential.credential_type === 'education',
    ) || [];
  const [isAdd, setAdd] = useState(educationList?.length === 0 ? true : false);

  useEffect(() => {
    if (educationList?.length === 0) setAdd(true);
    else setAdd(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatData = item => {
    const data = {...item};
    if (item['school_end_date'])
      data['school_end_date'] = moment(item['school_end_date']).format(
        'YYYY-MM-DD',
      );
    else data['school_end_date'] = null;
    if (item['school_start_date'])
      data['school_start_date'] = moment(item['school_start_date']).format(
        'YYYY-MM-DD',
      );
    else data['school_start_date'] = null;
    return data;
  };

  const cancelAddEdit = () => {
    setAdd(false);
    setEditIndex(-1);
  };

  const editData = idx => {
    setAdd(false);
    setEditIndex(idx);
  };

  const onFormContentUpdate = (data, action) => {
    if (onChange) {
      onChange(formatData(data), action);
      cancelAddEdit();
    }
  };

  const getFormattedPayload = (data, action) => {
    return getPayload(formatData(data), action);
  };

  return (
    <Stack overflow='auto' maxHeight='620px' pr={1} sx={{overflowX: 'hidden'}}>
      {educationList &&
        Array.isArray(educationList) &&
        educationList?.length > 0 &&
        educationList?.map((education, idx) => {
          return editIndex === idx ? (
            <Box key={idx}>
              <AddEducation
                edit={true}
                data={education}
                formRef={formRef}
                onCancel={cancelAddEdit}
                onSubmit={item => onFormContentUpdate(item, 'edit')}
                getPayload={item => getFormattedPayload(item, 'edit')}
              />
              <Divider sx={{my: 2}} />
            </Box>
          ) : (
            <Box key={idx}>
              <Grid py={2} container spacing={2} key={education?.org_name}>
                <Grid item xs={9} sm={9} md={8} lg={9}>
                  <Typography
                    variant='h5'
                    color='text.primary'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    fontSize={{xs: 12, sm: 14, md: 18}}>
                    {education?.org_name}
                  </Typography>
                  <Typography variant='body1' color='text.primary'>
                    {education?.award_type || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={3} p={0}>
                  {!viewOnly && (
                    <Stack direction='row'>
                      {isMobile ? (
                        <>
                          <IconButton onClick={() => editData(idx)}>
                            <Edit
                              color='primary'
                              sx={{fontSize: {xs: 16, sm: 18}}}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              onFormContentUpdate(education, 'delete')
                            }>
                            <Delete
                              color='primary'
                              sx={{fontSize: {xs: 16, sm: 18}}}
                            />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <Button size='large' onClick={() => editData(idx)}>
                            {editTxt}
                          </Button>
                          <Button
                            size='large'
                            onClick={() =>
                              onFormContentUpdate(education, 'delete')
                            }>
                            {deleteTxt}
                          </Button>
                        </>
                      )}
                    </Stack>
                  )}
                </Grid>
              </Grid>
              <Divider />
            </Box>
          );
        })}
      {!viewOnly && (
        <Box>
          {isAdd && (
            <AddEducation
              formRef={formRef}
              onCancel={cancelAddEdit}
              firstTime={educationList?.length === 0}
              onSubmit={formContent => onFormContentUpdate(formContent, 'add')}
              getPayload={formContent =>
                getFormattedPayload(formContent, 'add')
              }
            />
          )}
          {!isAdd && editIndex === -1 && (
            <Button
              variant='contained'
              sx={{width: '225px', mt: 2}}
              onClick={() => setAdd(true)}
              size={isMobile ? 'small' : 'large'}>
              {AddEducationTxt}
            </Button>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default EducationList;

import React from 'react';
import {Page, Document, StyleSheet, View} from '@react-pdf/renderer';
import {ErrorBoundary} from 'core/components';

// import Header from './components/Header';
// import Banner from './components/Banner';
// import ContentPanel from './components/ContentPanel';
// import OverviewPanel from './components/OverviewPanel';
import LeftPanel from './components/LeftPanel';
import RightPanel from './components/RightPanel';

const styles = StyleSheet.create({
  page: {
    height: '100%',
    paddingTop: 15,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    flexWrap: 'wrap',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  doc: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    paddingBottom: 4,
    paddingLeft: 20,
    paddingRight: 20,
    flex: '1',
  },
  leftPanel: {
    width: '35%',
    height: '100%',
    color: 'white',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    backgroundColor: '#9c7df7',
  },
  rightPanel: {
    width: '65%',
    paddingLeft: 20,
    paddingRight: 20,
    alignContent: 'center',
  },
  container: {
    flexDirection: 'row',
  },
  sidePanel: {
    paddingTop: 15,
    paddingBottom: 12,
    marginRight: 5,
  },
  contentPanel: {
    width: '70%',
    marginLeft: 5,
  },
});

const MyProfileDocument = ({
  awardTypes,
  studentInfo,
  srmAwardTypes,
  studentProfile,
  credentialsInfo,
  activePathsData,
}) => (
  <ErrorBoundary nameOfComponent='core-pdf-document'>
    <Document title={`${studentInfo?.first_name} ${studentInfo?.last_name}`}>
      <Page size='A4' wrap style={styles.page}>
        <View style={styles.doc}>
          <View style={styles.leftPanel}>
            <LeftPanel
              studentInfo={studentInfo}
              studentProfile={studentProfile}
            />
          </View>
          <View style={styles.rightPanel}>
            <RightPanel
              awardTypes={awardTypes}
              studentInfo={studentInfo}
              srmAwardTypes={srmAwardTypes}
              studentProfile={studentProfile}
              credentialsInfo={credentialsInfo}
              activePathsData={activePathsData}
            />
          </View>
          {/* <Header />
          {awardTypes && studentProfile && studentInfo && (
            <>
              <Banner studentInfo={studentInfo} />
              <OverviewPanel
                style={styles.sidePanel}
                studentInfo={studentInfo}
                studentProfile={studentProfile}
              />
              <ContentPanel
                awardTypes={awardTypes}
                studentProfile={studentProfile}
                credentialsInfo={credentialsInfo}
                activePathsData={activePathsData}
              />
            </>
          )} */}
        </View>
      </Page>
    </Document>
  </ErrorBoundary>
);

export default MyProfileDocument;

import {getCompleteDate} from 'core/utils';
import {Typography, Divider, Box, Stack} from '@mui/material';
import SettingsMonths from 'data/settings.json';
import profileData from 'data/settings-profile.json';

const WorkExperiences = ({data}) => {
  const {work_exp: work_experience} = data || {};
  const workExperienceDateFormat = exp_date => {
    return `${SettingsMonths.months[getCompleteDate(exp_date).month - 1]} ${
      getCompleteDate(exp_date).year
    }`;
  };

  return (
    <Box px={1}>
      {work_experience &&
      Array.isArray(work_experience) &&
      work_experience.length > 0 ? (
        work_experience.map((exp, idx) => {
          return (
            <>
              <Stack direction={'row'}>
                <Typography
                  variant='h5'
                  fontSize={18}
                  fontWeight={600}
                  component='span'>
                  {exp.company_name} -{' '}
                </Typography>
                <Typography
                  variant='body1'
                  fontSize={16}
                  fontWeight={400}
                  component='span'>
                  {exp.job_location}{' '}
                </Typography>
              </Stack>
              <Box pl={1}>
                <Typography
                  variant='h6'
                  fontSize={15}
                  component='span'
                  fontWeight={600}>
                  {`${workExperienceDateFormat(exp.start_date)} - ${
                    exp.is_present
                      ? profileData.workExperienceData.presentTxt
                      : workExperienceDateFormat(exp.end_date)
                  }`}{' '}
                </Typography>
                <Box mt={2}>
                  <Typography variant='h5' fontSize={18} fontWeight={600}>
                    {exp.job_title}
                  </Typography>
                  {exp.job_responsibilities &&
                    exp.job_responsibilities.map((role, idx) => {
                      if (role.job_responsibilities)
                        return (
                          <Stack
                            key={idx}
                            direction={'row'}
                            spacing={1}
                            mt={1}
                            alignItems={'center'}>
                            <Box
                              sx={{
                                minWidth: 8,
                                minHeight: 8,
                                backgroundColor: 'secondary.main',
                                borderRadius: 9,
                              }}
                            />
                            <Box>
                              <Typography variant='body1'>
                                {role.job_responsibilities}
                              </Typography>
                            </Box>
                          </Stack>
                        );
                    })}
                </Box>
                {idx + 1 !== work_experience.length && (
                  <Divider sx={{maxWidth: 500, my: 3}} />
                )}
              </Box>
            </>
          );
        })
      ) : (
        <Typography variant='body1' component='div'>
          N/A
        </Typography>
      )}
    </Box>
  );
};

export default WorkExperiences;

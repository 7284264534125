import {useState, useEffect, useImperativeHandle} from 'react';
import {
  Box,
  Stack,
  Select,
  MenuItem,
  Checkbox,
  Typography,
  OutlinedInput,
  FormControlLabel,
} from '@mui/material';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {getStates} from 'redux/modules/general';
import {Button, Grid, TextEditor} from 'mui-core';
import {useDispatch, useSelector} from 'react-redux';
import resumeBuilderData from 'data/resumeBuilder.json';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {GoAIButton, GoAIListForm} from '../../sharedComponents';
import {
  fetchCareerCraft,
  getCareerCraftData,
} from 'redux/modules/resume-builder';

const {
  stepsMeta: {
    internshipAndApprenticeship: {formData = {}, goAIButton},
  },
} = resumeBuilderData || {};

const AddInternshipAndApprenticeship = ({
  edit,
  formRef,
  onSubmit,
  onCancel,
  firstTime,
  data = {},
  getPayload,
}) => {
  const {
    city,
    state,
    org_name,
    is_present,
    description,
    program_name,
    credential_type,
    school_end_date,
    school_start_date,
  } = data || {};

  const {
    endDate,
    cityObj,
    typeObj,
    stateObj,
    titleObj,
    addBtnTxt,
    startDate,
    companyObj,
    presentTxt,
    saveBtnTxt,
    optionalText,
    typeOptions,
    descriptionObj,
    cancelBtnTxt,
    addmoreDetailsText,
    responsibilitiesObj,
  } = formData || {};

  const dispatch = useDispatch();
  const form = useForm({mode: 'all'});
  const {enqueueSnackbar} = useSnackbar();
  const {data: statesList} = useSelector(getStates);
  const [openDialog, setOpenDialog] = useState(false);
  const {data: careerCraft = []} = useSelector(getCareerCraftData);
  const {
    watch,
    trigger,
    setValue,
    getValues,
    resetField,
    clearErrors,
    handleSubmit,
    formState: {errors, isValid},
  } = form;
  const watchEndDate = watch(
    'school_end_date',
    school_end_date ? moment(school_end_date) : null,
  );
  const watchStartDate = watch(
    'school_start_date',
    school_start_date ? moment(school_start_date) : null,
  );
  const watchIsPresent = watch('is_present', is_present || false);
  const isStartDateRequired = !!watchEndDate || watchIsPresent ? true : false;
  const isEndDateRequired = !!watchStartDate && !watchIsPresent ? true : false;

  const onFinish = (newData, e) => {
    if (onSubmit && isEmpty(errors)) {
      onSubmit({...data, ...newData});
    }
  };

  useEffect(() => {
    if (watchIsPresent) {
      resetField('school_end_date');
      setValue('school_end_date', null);
    }
  }, [watchIsPresent]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(formRef, () => ({
    validateForm() {
      let isFormValid = true;
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      if (isFormFilled) {
        trigger();
        isFormValid = isValid;
      }
      return isFormValid;
    },
    getFormData() {
      const values = getValues();
      const isFormFilled = Object.values(values).some(value =>
        Array.isArray(value) ? value.length > 0 : value,
      );
      return isFormFilled ? getPayload({...data, ...getValues()}) : null;
    },
  }));

  const addToResume = data => {
    setValue('description', data.toString().replaceAll(',', ''));
    setOpenDialog(false);
  };

  const fetchCareerCraftData = () => {
    const {
      city,
      state,
      org_name,
      is_present,
      program_name,
      credential_type,
      school_end_date,
      school_start_date,
    } = getValues();
    if (
      program_name &&
      credential_type &&
      org_name &&
      school_start_date &&
      (is_present || school_end_date)
    ) {
      setOpenDialog(true);
      // ------Needs to check decimal number will be there or not-------
      // let duration = moment.duration(
      //   is_present
      //     ? moment(school_start_date).diff(moment())
      //     : moment(school_start_date).diff(moment(school_end_date)),
      // );
      // let years = duration.asYears();
      let years = is_present
        ? moment().diff(moment(school_start_date), 'years')
        : moment(school_end_date).diff(moment(school_start_date), 'years');
      let obj = {
        job_title: program_name,
        type: credential_type,
        company_name: org_name,
        // years_of_exp: Math.abs(years.toFixed(2)),
        years_of_exp: years,
      };
      if (city && state) {
        obj.job_location = `${city}, ${state}`;
      }
      dispatch(fetchCareerCraft('work_experience', obj));
    } else {
      enqueueSnackbar('Please fill the required fields to proceed with GOAI.', {
        variant: 'warning',
        anchorOrigin: {horizontal: 'center', vertical: 'top'},
      });
    }
  };

  return (
    <Box>
      <Stack mt={firstTime ? 0 : 1}>
        <Box mx='auto' my={2}>
          <form onSubmit={handleSubmit(onFinish)}>
            <Grid
              container
              spacing={1}
              display='flex'
              rowSpacing={4}
              alignItems='center'
              justifyContent='center'>
              <Grid item xs={12}>
                {createField('credential_type', {
                  form,
                  defaultValue: credential_type || '',
                  label: typeObj.label,
                })(
                  <Select
                    label={typeObj.label}
                    id='item-type'
                    placeholder={typeObj.placeholder}>
                    {Array.isArray(typeOptions) &&
                      typeOptions.map(type => (
                        <MenuItem key={type.key} value={type.key}>
                          {type.name}
                        </MenuItem>
                      ))}
                  </Select>,
                )}
              </Grid>
              <Grid item xs={6}>
                {createField('program_name', {
                  form,
                  label: titleObj.label,
                  defaultValue: program_name || '',
                  rules: {
                    required: [true, titleObj.requiredMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    label={titleObj.label}
                    placeholder={titleObj.placeholder}
                    id='resume-internshipAndApprenticeship-Title'
                  />,
                )}
              </Grid>
              <Grid item xs={6}>
                {createField('org_name', {
                  form,
                  label: companyObj.label,
                  defaultValue: org_name || '',
                  rules: {
                    required: [true, companyObj.requiredMsg],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    label={companyObj.label}
                    placeholder={companyObj.placeholder}
                    id='resume-internshipAndApprenticeship-company_name'
                  />,
                )}
              </Grid>

              <Grid item xs={6} lg={8}>
                {createField('city', {
                  form,
                  label: cityObj.label,
                  defaultValue: city || '',
                  rules: {
                    validate: [
                      (value, formValues) =>
                        !value
                          ? !formValues.state
                            ? clearErrors('city')
                            : false
                          : true,
                      cityObj.requiredMsg,
                    ],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    label={cityObj.label}
                    placeholder={cityObj.placeholder}
                    id='resume-internshipAndApprenticeship-city'
                  />,
                )}
              </Grid>
              <Grid item xs={6} lg={4}>
                {createField('state', {
                  form,
                  label: stateObj.label,
                  defaultValue: state || '',
                  rules: {
                    validate: [
                      (value, formValues) =>
                        !value
                          ? !formValues.city
                            ? clearErrors('state')
                            : false
                          : true,
                      stateObj.requiredMsg,
                    ],
                  },
                })(
                  <Select
                    variant='outlined'
                    label={stateObj.label}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}>
                    <MenuItem value='' key='none'>
                      None
                    </MenuItem>
                    {statesList &&
                      Array.isArray(statesList) &&
                      statesList.length > 0 &&
                      statesList.map(item => (
                        <MenuItem value={item.name} key={item.state_uuid}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>,
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={4}>
                {createField('school_start_date', {
                  form,
                  dependent: ['school_end_date'],
                  rules: {
                    maxDate: [watchEndDate, startDate.maxDateMsg],
                    required: [isStartDateRequired, startDate.requiredMsg],
                  },
                  defaultValue: school_start_date
                    ? moment(school_start_date)
                    : null,
                })(
                  <DatePicker
                    maxDate={watchEndDate}
                    label={startDate.label}
                    showDaysOutsideCurrentMonth
                    views={['year', 'month', 'day']}
                  />,
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                {createField('school_end_date', {
                  form,
                  disabled: watchIsPresent,
                  dependent: ['school_start_date'],
                  defaultValue: school_end_date
                    ? moment(school_end_date)
                    : null,
                  rules: {
                    minDate: [watchStartDate, endDate.minDateMsg],
                    required: [isEndDateRequired, endDate.requiredMsg],
                  },
                })(
                  <DatePicker
                    label={endDate.label}
                    minDate={watchStartDate}
                    showDaysOutsideCurrentMonth
                    views={['year', 'month', 'day']}
                  />,
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='start'>
                  <Typography
                    mr={2}
                    title='Or'
                    variant='h5'
                    ml={{xs: 0, lg: 1}}
                    color='text.primary'>
                    Or
                  </Typography>
                  {createField(`is_present`, {
                    form,
                    fullWidth: false,
                    valueField: 'checked',
                    defaultValue: is_present || false,
                  })(
                    <FormControlLabel
                      label={presentTxt}
                      control={
                        <Checkbox id='resume-internshipAndApprenticeship-is-current' />
                      }
                    />,
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  spacing={1}
                  direction='row'
                  alignItems='center'
                  justifyContent='start'>
                  <Typography
                    variant='h5'
                    color='text.primary'
                    title={addmoreDetailsText}>
                    {addmoreDetailsText}
                  </Typography>
                  <Typography
                    variant='body1'
                    color='text.primary'
                    title={addmoreDetailsText}>
                    {`(${optionalText})`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Box
                  mb={2}
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'>
                  <Typography variant='h5' color='text.primary'>
                    {responsibilitiesObj.title}
                  </Typography>
                  <GoAIButton
                    children={
                      <GoAIListForm
                        list={careerCraft || []}
                        submitListForm={addToResume}
                      />
                    }
                    openDialog={openDialog}
                    descriptionCenter={true}
                    setOpenDialog={setOpenDialog}
                    fetchData={fetchCareerCraftData}
                    description={goAIButton.description}
                  />
                </Box>
                {createField('description', {
                  form,
                  defaultValue: description || '',
                })(<TextEditor label={descriptionObj.label} />)}
              </Grid>
              <Grid item>
                {!firstTime && (
                  <Button variant='outlined' onClick={onCancel} sx={{mr: 2}}>
                    {cancelBtnTxt}
                  </Button>
                )}
                <Button variant='contained' type='submit' formNoValidate>
                  {edit ? saveBtnTxt : addBtnTxt}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default AddInternshipAndApprenticeship;

import {Box, Chip, Container, Stack, styled, Typography} from '@mui/material';
import {themeColorMode} from 'mui-core';

const RelatedSearchRoot = styled(Box)(({theme}) => ({
  borderRadius: '20px',
  backgroundColor: themeColorMode(
    theme,
    theme.palette.purplebg.main,
    theme.palette.background.paper,
  ),
}));

function RelatedSearch({relatedSearch, onClick}) {
  return (
    <RelatedSearchRoot>
      <Container>
        <Stack
          p={{xs: 3, md: 5}}
          alignItems={'center'}
          justifyContent={'center'}>
          <Box>
            <Typography variant='pagedesc' fontWeight={500}>
              Search Related Careers
            </Typography>
          </Box>
          <Stack
            mt={2.7}
            direction={{xs: 'column', sm: 'row'}}
            flexWrap={'wrap'}
            justifyContent={'center'}
            gap={{xs: 0.5, sm: 2}}
            spacing={{xs: 1, sm: 3}}>
            {Array.isArray(relatedSearch) &&
              relatedSearch.map(key => (
                <Chip
                  onClick={() => {
                    if (onClick) onClick(key);
                  }}
                  sx={{
                    color: theme => theme.palette.primary.main,
                    backgroundColor: theme => theme.palette.purplebg12perc.main,
                  }}
                  label={key}
                />
              ))}
          </Stack>
        </Stack>
      </Container>
    </RelatedSearchRoot>
  );
}

export default RelatedSearch;

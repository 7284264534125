import moment from 'moment';
import {useEffect} from 'react';
import MenuOptions from '../MenuOptions';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {infoIcon, InfoIconwhite} from 'assets/images';
import {Visibility, Add} from '@mui/icons-material';
import {Box, Divider, Stack, Typography, useMediaQuery} from '@mui/material';
import {
  clearResume,
  getResumeList,
  fetchResumeList,
} from 'redux/modules/resumes';
import {
  noResumeText,
  createResumeText,
  noResumeHelperText,
} from 'data/resume.json';
import {
  Grid,
  Button,
  Speedometer,
  NoContentNavigator,
  RequestErrorLoader,
} from 'mui-core';

import {
  resumeStrengthText,
  // resumeStatusText,
  // addtoProfileText,
} from 'data/resume.json';
import {styled, useTheme} from '@mui/material/styles';
// import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';

const customLabelPlacementValues = {
  okPlacement: {
    lg: 20,
    md: 32,
    sm: 40,
    xs: 32,
  },
  goodPlacment: {
    lg: 45,
    md: 20,
    sm: -4,
    xs: 10,
  },
  greatPlacement: {
    lg: 0,
    md: 12,
    sm: 16,
    xs: 15,
  },
};

const labelSizeValues = {
  xs: 10,
  sm: 13,
  md: 12,
  lg: 13,
};

const StyledSpeedometerContainer = styled(Box)(() => ({
  height: 150,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// const StyledInfoRounded = styled('img')(() => ({
//   width: 15,
//   height: 15,
//   cursor: 'pointer',
// }));

// const LightTooltip = styled(({className, ...props}) => (
//   <Tooltip arrow {...props} placement='bottom' classes={{popper: className}} />
// ))(({theme}) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     padding: '1px',
//     // color: themeColorMode(theme, 'black', 'white'),
//   },

//   [`& .${tooltipClasses.arrow}`]: {
//     color: 'white',
//   },
// }));

const ResumeList = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  // const [addProfile, setAddProfile] = useState(false);
  // const InfoIconImage =
  //   theme?.palette?.mode === 'dark' ? InfoIconwhite : infoIcon;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {data: resumeList = [], request: requestResumeList} = useSelector(
    getResumeList,
  );

  useEffect(() => {
    dispatch(fetchResumeList());
    return () => {
      dispatch(clearResume());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderResumeStrength = resume_strength => {
    return (
      <StyledSpeedometerContainer>
        <Speedometer
          width={230}
          size='small'
          showDialValue={false}
          labelSize={labelSizeValues}
          dialValue={resume_strength}
          badgeLabel={resumeStrengthText}
          customLabelPlacement={customLabelPlacementValues}
        />
      </StyledSpeedometerContainer>
    );
  };

  return (
    <>
      <Stack display='flex' alignItems='flex-end'>
        <Button
          size='large'
          color='primary'
          endIcon={<Add />}
          variant='outlined'
          sx={{maxWidth: '200px'}}
          onClick={() => history.push('/resume-builder')}>
          {createResumeText}
        </Button>
      </Stack>

      <RequestErrorLoader
        minHeight={100}
        body={{
          data: resumeList || [],
          request: requestResumeList,
        }}
        overrideNoDataContainer={
          <Box
            mt={2}
            mb={1}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background:
                'linear-gradient(0deg, rgba(123, 96, 223, 0.08), rgba(123, 96, 223, 0.08))',
            }}>
            <Box pb={3}>
              <NoContentNavigator
                noDataIcon={
                  <Visibility
                    color='primary'
                    fontSize={'large'}
                    sx={{mt: 2, mb: -2}}
                  />
                }
                heading={noResumeText}
                message={noResumeHelperText}
              />
            </Box>
          </Box>
        }>
        {resumeList &&
          Array.isArray(resumeList) &&
          resumeList.length > 0 &&
          resumeList.map((item, index) => (
            <>
              {/* <Box sx={{position: 'relative'}}>
                {item?.resume_uuid && (
                  <MenuOptionsPopper resumeContent={item} />
                )}
              </Box> */}
              <Grid
                mt={2}
                container
                key={item?.resume_uuid}
                justifyContent='space-evenly'>
                {/* <Grid item lg={3}>
                    <Stack direction='column' spacing={1}>
                      <Stack direction='row' spacing={1}>
                        <Typography variant='h5'>{resumeStatusText}</Typography>
                        <LightTooltip title={resumeStatusText}>
                          <StyledInfoRounded
                            alt='info-icon'
                            src={InfoIconImage}
                          />
                        </LightTooltip>
                      </Stack>
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <Switch
                          checked={addProfile}
                          onChange={() => setAddProfile(!addProfile)}
                        />
                        <Typography variant='body1' color='GrayText'>
                          {addtoProfileText}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid> */}

                {/* while uncommenting the above grid change lg value to 6 */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  mt={isMobile && 3}
                  mb={{xs: 0, sm: 3, lg: 0}}>
                  {renderResumeStrength(item?.resume_strength || 0)}
                </Grid>
                <Grid item xs={12} sm={7} md={6} lg={5}>
                  <Grid container alignItems='center'>
                    <Grid item textAlign='right' xs={5} sm={3} md={3} lg={3}>
                      <Typography
                        variant='body2'
                        textAlign='right'
                        color='GrayText'>
                        Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={9} md={9} lg={9}>
                      <Typography
                        variant='body2'
                        color='text.primary'
                        fontSize={{xs: 12, sm: 14, md: 16}}>
                        {(item?.resume_name && item?.resume_name) || '-'}
                      </Typography>
                    </Grid>
                    <Grid item textAlign='right' xs={5} sm={3} md={3} lg={3}>
                      <Typography
                        variant='body2'
                        textAlign='right'
                        color='GrayText'>
                        Created:
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={9} md={9} lg={9}>
                      <Typography
                        variant='body2'
                        color='text.primary'
                        fontSize={{xs: 12, sm: 14, md: 16}}>
                        {(item?.created_at &&
                          moment(item?.created_at).format('MM-DD-YYYY')) ||
                          '-'}
                      </Typography>
                    </Grid>
                    <Grid item textAlign='right' xs={5} sm={3} md={3} lg={3}>
                      <Typography
                        variant='body2'
                        textAlign='right'
                        color='GrayText'>
                        Modified:
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={9} md={9} lg={9}>
                      <Typography
                        variant='body2'
                        color='text.primary'
                        fontSize={{xs: 12, sm: 14, md: 16}}>
                        {(item?.updated_at &&
                          moment(item?.updated_at).format('MM-DD-YYYY')) ||
                          '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={12}
                  lg={3}
                  mb={{xs: 0, sm: 3, lg: 0}}>
                  <MenuOptions resumeContent={item} />
                </Grid>
              </Grid>
              {resumeList.length - 1 !== index && <Divider sx={{my: 2}} />}
            </>
          ))}
      </RequestErrorLoader>
    </>
  );
};

export default ResumeList;

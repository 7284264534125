import * as TYPES from './constants';
import {API} from 'config';

import {
  requestLocalJobsError,
  requestAllJobs,
  recieveAllJobs,
  requestAllJobsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ALL_JOBS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.ALL_JOBS_SUCCESS:
      if (action.loadMore) {
        return {
          ...state,
          request: false,
          error: false,
          data: {
            ...action.payload,
            data: [...state?.data?.data, ...action.payload?.data],
            datalength:
              state.data.datalength +
                (action.payload &&
                  action.payload.data &&
                  Array.isArray(action.payload.data) &&
                  action.payload.data.length) || 0,
          },
        };
      }
      return {
        ...state,
        request: false,
        error: false,
        data: {
          ...action.payload,
          datalength:
            (action.payload &&
              action.payload.data &&
              Array.isArray(action.payload.data) &&
              action.payload.data.length) ||
            0,
        },
      };
    case TYPES.CLEAR_ALL_JOBS:
      return {
        ...initialState,
      };
    case TYPES.ALL_JOBS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchAllJobs = (reqParams, filters) => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestAllJobs());
  const {
    sort = 'date_desc',
    page = 1,
    page_size = 25,
    location = '',
    min_perct = 0,
    skills_option,
    loadMore,
  } = reqParams || {};
  const body = {};
  try {
    let endPoint = `${API.srm.allJobs}`;
    if (skills_option) {
      body['skills'] = 'true';
      body['min_perct'] = min_perct;
    }
    body['search'] = 'jobs';
    body['sort'] = sort;
    body['page'] = page;
    body['location'] = location;
    body['page_size'] = page_size;
    if (filters.job_type) filters.job_type = 'remote';
    if (reqParams.company_name) body['company_name'] = reqParams.company_name;
    if (filters) {
      const getFilterKeys = Object.keys(filters);
      if (getFilterKeys.length) {
        getFilterKeys.map(key => {
          if (filters[key]) {
            // endPoint += `&${key}=${encodeURIComponent(filters[key])}`;
            body[key] = filters[key];
          }
        });
      }
    }
    if (body.job_type) body.job_type = 'remote';
    console.log('fetchAllJobs', body);
    // token, endpoint, data, method
    return services
      .createUpdateRecord(skills_option ? token : null, endPoint, body, 'POST')
      .then(res => {
        if (res) {
          dispatch(recieveAllJobs(res, loadMore));
          return;
        }
        dispatch(requestAllJobsError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestAllJobsError(err));
  }
};

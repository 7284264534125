import * as TYPES from './constants';
import {API} from 'config';

import {
  requestAllOpportunities,
  recieveAllOpportunities,
  requestAllOpportunitiesError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {
  job: {request: false, error: false, data: []},
  apprenticeship: {request: false, error: false, data: []},
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ALL_OPPORTUNITIES_REQUEST:
      return {
        ...state,
        [action.oppType]: {
          ...state[action.oppType],
          error: false,
          request: true,
        },
      };
    case TYPES.ALL_OPPORTUNITIES_SUCCESS:
      if (action.loadMore) {
        const prevData = state[action.oppType];
        const prevDataLength = state[action.oppType]?.datalength || 0;
        const oldList = prevData?.data?.data ? [...prevData?.data?.data] : [];
        const newList = action.payload?.data || [];
        const updatedList = [...oldList, ...newList];
        return {
          ...state,
          [action.oppType]: {
            error: false,
            request: false,
            data: {
              ...action.payload,
              data: updatedList,
              datalength:
                prevDataLength +
                  (newList && Array.isArray(newList) && newList.length) || 0,
            },
          },
        };
      }
      return {
        ...state,
        [action.oppType]: {
          error: false,
          request: false,
          data: {
            ...action.payload,
            datalength:
              (action?.payload?.data &&
                Array.isArray(action.payload.data) &&
                action.payload.data.length) ||
              0,
          },
        },
      };
    case TYPES.ALL_OPPORTUNITIES_ERROR:
      return {
        ...state,
        [action.oppType]: {error: true, request: false},
      };
    case TYPES.CLEAR_ALL_OPPORTUNITIES:
      return {
        ...state,
        [action.oppType]: initialState[action.oppType],
      };
    default:
      return state;
  }
};

export const fetchAllOpportunities = (type, reqParams, filters, loadMore) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  const oppType = type;
  dispatch(requestAllOpportunities(oppType));
  const {
    page = 1,
    // location = '',
    cip_code = '',
    min_perct = 0,
    skills_option,
    page_size = 25,
    onet_code = '',
    sort = 'date_desc',
  } = reqParams || {};
  const body = {};
  try {
    let endPoint = `${API.srm.allJobs}`;
    if (skills_option) {
      body['skills'] = 'true';
      body['min_perct'] = min_perct;
    }
    if (onet_code) {
      body['onet_code'] = onet_code;
    } else if (cip_code) {
      body['cip_code'] = cip_code;
    }
    body['sort'] = sort;
    body['page'] = page;
    // body['location'] = location;
    body['page_size'] = page_size;
    body['search'] = type || 'jobs';
    // if (filters.job_type) filters.job_type = 'remote';
    if (reqParams.company_name) body['company_name'] = reqParams.company_name;
    if (filters) {
      const getFilterKeys = Object.keys(filters);
      if (getFilterKeys.length) {
        getFilterKeys.map(key => {
          if (filters[key] || key === 'no_of_days') {
            // endPoint += `&${key}=${encodeURIComponent(filters[key])}`;
            body[key] = filters[key];
          }
        });
      }
    }
    // if (body.job_type) body.job_type = 'remote';
    // token, endpoint, data, method
    return services
      .createUpdateRecord(skills_option ? token : null, endPoint, body, 'POST')
      .then(res => {
        if (res) {
          dispatch(recieveAllOpportunities(res, loadMore, oppType));
          return;
        }
        dispatch(requestAllOpportunitiesError(res, oppType));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestAllOpportunitiesError(err, oppType));
  }
};

export const fetchAllHonestJobs = (reqParams, filters) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  const oppType = 'job';
  dispatch(requestAllOpportunities(oppType));
  const {page = 1, page_size = 30} = reqParams || {};
  const body = {};
  try {
    let endPoint = `${API.srm.honestJobs}`;
    body['page'] = page;
    body['page_size'] = 30;

    if (filters) {
      const getFilterKeys = Object.keys(filters);
      if (getFilterKeys.length) {
        getFilterKeys.map(key => {
          if (filters[key]) {
            body[key] = filters[key];
          }
        });
      }
    }

    return services
      .createUpdateRecord(token, endPoint, body, 'POST')
      .then(res => {
        if (res) {
          let results = {
            data: [],
            page_details: {
              page: res?.page || page,
              page_size: res?.page_size || page_size,
              total_results: res?.result?.length || 0,
            },
          };
          if (res?.result) {
            results['data'] = res.result.map(obj => {
              return {
                job_id: obj.id,
                job_source: 'honest',
                job_title: obj.title,
                job_type: 'job',
                company: obj.company_name,
                job_url: obj.apply_url,
                description: obj.description,
                date_posted: obj.posted_date,
                location: `${obj.address.city}, ${obj.address.state}`,
                skills: [],
                company_logo: '',
                company_industry_sector: '',
                program_length: '',
                banner: '',
                location_type: '',
                type: '',
              };
            });
          }
          dispatch(recieveAllOpportunities(results, false, oppType));
          return;
        }
        dispatch(requestAllOpportunitiesError(res, oppType));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestAllOpportunitiesError(err, oppType));
  }
};

import List from '@mui/material/List';
import {
  default as MUIListItemButton,
  listItemButtonClasses,
} from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import {useRouteMatch, NavLink} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {ContentBox} from 'mui-core';
import {Stack, Typography} from '@mui/material';
import Progress from '../../subModules/MyProfile/components/Progress';
import {useEffect} from 'react';
const ListItemButton = styled(MUIListItemButton)(({theme}) => {
  return {
    height: 40,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexGrow: 'initial',
      flexFlow: 'initial',
      alignItems: 'center',
      justifyContent: 'initial',
      borderRadius: 15,
      [`&.Mui-selected`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '& svg': {
          fill: theme.palette.primary.contrastText,
        },
      },
      '&.Mui-selected:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '& svg': {
          fill: theme.palette.primary.contrastText,
        },
      },
    },
  };
});
const StyledBox = styled(Box)(({theme}) => {
  return {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: '0 9px',
    },
  };
});

const getPath = (list, url) => {
  if (url[url.length - 1] === '/') url = url.substr(0, url.length - 1);
  const path =
    list.path === '/' ? '/dashboard' : `${url}/${list.path.replace('/', '')}`;
  return path;
};

const StylesBox = styled(List)(({theme}) => ({
  paddingTop: 0,
  [`&.stick`]: {
    width: 'initial',
    zIndex: 99,
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      top: 80,
    },
    [theme.breakpoints.down('sm')]: {
      left: 0,
      top: 55,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: 0,
    },
  },
  [`&.stick nav`]: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 15,
    // border: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      zIndex: 10000,
      // top: 56,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: 0,
    },
  },
}));

const StylesList = styled(List)(({theme}) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    overflowX: 'auto',
    alignItems: 'safe center',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
  },
}));

const Navigation = ({modules, completedTabs}) => {
  const {url} = useRouteMatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{width: '100%', minWidth: 270}}>
      <StylesBox sx={{width: '100%', minWidth: 270}} id={'dashboard-nav'}>
        <ContentBox variant='outlined'>
          <StylesList sx={{width: '100%', minWidth: 270}} component='nav'>
            <StyledBox>
              {modules &&
                Array.isArray(modules) &&
                modules.map(list => (
                  <ListItemButton
                    key={list.path}
                    exact={list.exact}
                    to={`${getPath(list, url)}`}
                    activeClassName={'Mui-selected'}
                    component={NavLink}>
                    {!mobile && (
                      <ListItemIcon>
                        <list.icon />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={list.label} />
                  </ListItemButton>
                ))}
            </StyledBox>
          </StylesList>
        </ContentBox>
        {!mobile && (
          <ContentBox sx={{minWidth: 270, mt: 2, py: 1}} variant='outlined'>
            <List>
              <ListItem selected>
                <ListItemText sx={{textAlign: 'center'}}>
                  <Typography variant='h5' color='text.primary'>
                    My Profile
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Stack direction={'row'} justifyContent='center' py={1}>
              <svg style={{position: 'absolute'}}>
                {/* required for gradient svg border inside <Progress /> componet*/}
                <linearGradient id='linearColors' x1='0' y1='0' x2='1' y2='1'>
                  <stop offset='30%' stopColor='#91B70C' />
                  <stop offset='90%' stopColor='#C2F01D' />
                </linearGradient>
              </svg>
              <Progress completedTabs={completedTabs} />
            </Stack>
          </ContentBox>
        )}
      </StylesBox>
    </Box>
  );
};
export default Navigation;

import React from 'react';
import {Box, Stack, styled, Typography} from '@mui/material';
import {goAIButtonBanner} from 'assets/images';
import resumeBuilderData from 'data/resumeBuilder.json';
import {Button} from 'mui-core';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const {bannerHeading, builderVersion} = resumeBuilderData || {};

const StyledBanner = styled(Box)(({theme}) => ({
  width: '100%',
  height: '48px',
  display: 'flex',
  padding: '0px 33px',
  whiteSpace: 'nowrap',
  backgroundSize: 'cover',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${goAIButtonBanner})`,
}));

const StyledText = styled(Typography)(({theme}) => ({
  color: 'white',
  fontWeight: 300,
  background: 'rgba(29, 15, 72, 0.5)',
  boxShadow: '2px 0px 5px #1d0f48',
}));

const LayoutBanner = ({startTour}) => {
  return (
    <StyledBanner>
      <StyledText
        variant='h5'
        dangerouslySetInnerHTML={{
          __html: bannerHeading,
        }}></StyledText>
      <Stack direction={'row'} alignItems={'center'} spacing={3}>
        <Button
          sx={{borderColor: 'white', color: 'white'}}
          onClick={startTour}
          size={'small'}
          startIcon={<HelpOutlineOutlinedIcon />}
          variant='outlined'>
          Help
        </Button>
        <StyledText variant='caption'>{builderVersion}</StyledText>
      </Stack>
    </StyledBanner>
  );
};

export default LayoutBanner;

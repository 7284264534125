import React from 'react';
import {Select} from 'antd';
import {SearchResultsCounter} from 'core/components';
import {calculateNoOfRecordsFetched} from 'core/utils';
import {textloading} from 'assets/images';
import {employOpportData} from 'data/occupation.json';
import SearchModule from 'data/search';
import './style.less';

const {Option} = Select;

const PAGE_SIZE = 25;

const SortData = [
  {
    value: 'relevant',
    text: SearchModule.sortTxtData.relevantLabel,
  },
  {
    value: 'date_desc',
    text: SearchModule.sortTxtData.newToOldLabel,
  },
  {
    value: 'date_asc',
    text: SearchModule.sortTxtData.oldToNewLabel,
  },
];

const SortJobBoardData = [
  {
    title: 'Last 1 Week',
    value: 7,
  },
  {
    title: 'Last 2 Weeks',
    value: 14,
  },
  {
    title: 'Last 3 Weeks',
    value: 21,
  },
  {
    title: 'Last 1 Month',
    value: 30,
  },
];

const Sorter = ({
  onChange,
  sort,
  days,
  type,
  calcData,
  initialData,
  isRelevant,
  isGlobalSearch,
  isClubbedResults,
}) => {
  const {totalRecords} = calcData || {};
  return (
    <div className='employment-sort'>
      <span className='no-of-records-matrix py-3'>
        <SearchResultsCounter
          isClubbedResults={isClubbedResults}
          currentLength={calculateNoOfRecordsFetched(calcData, PAGE_SIZE)}
          totalResults={totalRecords}
          request={initialData?.request}
        />
      </span>

      <div>
        {type === 'job_boards' && isRelevant && !isGlobalSearch && (
          <span className='text-right'>
            <span className='sortby jobsPostedSortby'>
              {employOpportData.jobsPostedTxt}
            </span>
            <Select
              dropdownClassName='sort-grid'
              className='px-2'
              defaultValue={days}
              value={days}
              onChange={value => onChange('days', value)}>
              {SortJobBoardData.map(({value, title}, idx) => (
                <Option value={value} key={idx}>
                  {title}
                </Option>
              ))}
            </Select>
          </span>
        )}
        {(type !== 'job_boards' || (type === 'job_boards' && isGlobalSearch)) &&
          isRelevant && (
            <span className='text-right'>
              <span className='sortby'>{employOpportData.datePostedTxt}</span>
              <Select
                dropdownClassName='sort-grid'
                className='pl-2'
                defaultValue={sort}
                value={sort}
                onChange={value => onChange('sort', value)}>
                {SortData.map(({value, text}, idx) => {
                  if (!isRelevant && idx === 0) {
                    return '';
                  }
                  return (
                    <Option value={value} key={idx}>
                      {text}
                    </Option>
                  );
                })}
              </Select>
            </span>
          )}
      </div>
    </div>
  );
};

export default Sorter;

import {ContentBox} from 'mui-core';
import CareerFavorites from './CareerFavorites';
import CareerInterest from './CareerInterest';
import CareerTaskSurvey from './CareerTaskSurvey';
import {connect} from 'react-redux';

import {
  fetchOccupationInterests,
  getOccupationInterest,
  deleteOccupationInterest,
  profileDataSelector,
  fetchProfileData,
} from 'redux/modules/profile';
import {
  fetchSubscribedOccupation,
  getSubscribedOccupation,
  unSubscribeForOccupation,
  updateOpportunityInterest,
} from 'redux/modules/occupation';
import {getAppConfig} from 'redux/modules/general';
const MyCareer = props => {
  return (
    <>
      <ContentBox variant='outlined'>
        <CareerFavorites {...props} />
      </ContentBox>
      <ContentBox variant='outlined' sx={{mt: 2}}>
        <CareerInterest />
      </ContentBox>
      <ContentBox variant='outlined' sx={{mt: 2}}>
        <CareerTaskSurvey {...props} />
      </ContentBox>
    </>
  );
};

const mapStateToProps = state => ({
  appConfig: getAppConfig(state),
  subscribedOccupation: getSubscribedOccupation(state),
  occupationInterest: getOccupationInterest(state),
  profileData: profileDataSelector(state),
});

export default connect(mapStateToProps, {
  fetchProfileData,
  unSubscribeForOccupation,
  fetchOccupationInterests,
  deleteOccupationInterest,
  updateOpportunityInterest,
  fetchSubscribedOccupation,
})(MyCareer);

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import AddSkills from './';
const EditSkill = ({
  open = false,
  toggleEditSkillModal,
  onSkillSubmit,
  skills,
  data,
  studentSkills,
  ...rest
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={open}
      onClose={() => toggleEditSkillModal()}
      aria-labelledby='draggable-dialog-title'>
      <DialogTitle sx={{m: 0, p: 2}}>
        <Typography variant='h6'>Edit Skill</Typography>
        <IconButton
          aria-label='close'
          onClick={() => toggleEditSkillModal()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{paddingTop: '12px'}}>
          <AddSkills
            skills={skills}
            onSkillSubmit={onSkillSubmit}
            edit
            onCancel={toggleEditSkillModal}
            editData={data}
            studentSkills={studentSkills}
            {...rest}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default EditSkill;

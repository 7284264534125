import * as TYPES from './constants';
import {API} from 'config';
import {
  requestBusinessPartnerDetails,
  recieveBusinessPartnerDetails,
  requestBusinessPartnerDetailsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, error: false, data: null};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BUSINESS_PARTNER_DETAILS:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_BUSINESS_PARTNER_DETAILS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_BUSINESS_PARTNER_DETAILS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.CLEAR_BUSINESS_PARTNER_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export const fetchBusinessPartnerDetails = bpName => dispatch => {
  let endPoint = `${API.srm.business_partners}?subdomain_name=${bpName}`;
  dispatch(requestBusinessPartnerDetails());
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveBusinessPartnerDetails(res));
        return;
      }
      dispatch(requestBusinessPartnerDetailsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestBusinessPartnerDetailsError(err));
  }
};

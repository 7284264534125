import {connect} from 'react-redux';
import EventList from './EventList';
import {ContentBox, Accordion} from 'mui-core';
import {
  fetchMyEvents,
  withdrawEvent,
  removeMyEvent,
  clearMyEvents,
  getMyEvents,
} from 'redux/modules/events';
import {fetchStudentMetrics} from 'redux/modules/profile';
const MyEvents = props => {
  return (
    <ContentBox variant='outlined' sx={{mb: 3}}>
      <Accordion
        defaultOpen
        id='my_events'
        outlined={false}
        header='My Events'
        collpsable={false}>
        <EventList {...props} />
      </Accordion>
    </ContentBox>
  );
};
const mapStateToProps = state => ({
  events: getMyEvents(state),
});

export default connect(mapStateToProps, {
  fetchMyEvents,
  clearMyEvents,
  withdrawEvent,
  removeMyEvent,
  fetchStudentMetrics,
})(MyEvents);

import {combineReducers} from 'redux';

import LocalJobsData from './localJobs';
import JobBoardsData from './jobBoards';
import JobBoardDetails from './jobBoardDetails';
import AppliedJobs from './appliedJobs';
import SavedJobs from './savedJobs';
import PublicInsightJobs from './publicInsightJobs';
import AllJobs from './jobs';

export const jobsReducer = combineReducers({
  LocalJobsData,
  PublicInsightJobs,
  JobBoardsData,
  JobBoardDetails,
  AppliedJobs,
  SavedJobs,
  AllJobs,
});

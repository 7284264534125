import PropTypes from 'prop-types';
import {Button, DialogBox} from 'mui-core';
import {exploreCareer} from 'data/exploreCareer.json';
import {Box, Typography, useTheme} from '@mui/material';

const {yesTxt, cancelTxt} = exploreCareer;
const RemoveCareerDialog = ({
  showDialog,
  dialogDesc,
  dialogTitle,
  dialogClose,
  dialogConfirm,
  selectedValueLabel,
  selectedValueIndex,
}) => {
  const theme = useTheme();
  return (
    <DialogBox
      openDialog={showDialog}
      closeDialog={dialogClose}
      dialogTitle={dialogTitle}
      actions={
        <>
          <Button
            variant='outlined'
            onClick={dialogClose}
            sx={{minWidth: {xs: '80px', md: '168px'}}}>
            {cancelTxt}
          </Button>
          <Button
            variant='contained'
            sx={{minWidth: {xs: '80px', md: '168px'}}}
            onClick={() => dialogConfirm(selectedValueIndex)}>
            {yesTxt}
          </Button>
        </>
      }>
      <Box textAlign='center'>
        <Typography variant='h4' color='text.primary'>
          {dialogTitle}
        </Typography>
        <Typography
          component='p'
          variant='bodyL'
          textAlign='center'
          color='text.primary'>
          {dialogDesc}{' '}
          <Typography
            variant='h6'
            component='span'
            color={theme.palette.primary.main}>
            {selectedValueLabel}
          </Typography>
          ?
        </Typography>
      </Box>
    </DialogBox>
  );
};

RemoveCareerDialog.prototype = {
  showDialog: PropTypes.bool.isRequired,
  dialogClose: PropTypes.func.isRequired,
  dialogDesc: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogConfirm: PropTypes.func.isRequired,
  selectedValueLabel: PropTypes.string.isRequired,
  selectedValueIndex: PropTypes.string.isRequired,
};

export default RemoveCareerDialog;

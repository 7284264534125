import {useEffect, useState} from 'react';
import {isEmpty, isArray} from 'lodash';
import {newSortArrayOfObjPropertyBased} from 'core/utils';
import {NoCourseMapFound, ExploreProgramsBtnText} from 'data/pathway';
import {
  ErrorBoundary,
  NoContentNavigator,
  RequestErrorLoader,
  CourseMap as CourseMapNew,
} from 'mui-core';
import PropTypes from 'prop-types';

const ProgramCourseMap = ({pathInfo, showModal = false}) => {
  const [programMapCoursePlan, setProgramMapCoursePlan] = useState({
    data: pathInfo.path_details,
  });
  useEffect(() => {
    let PATH_DATA =
      pathInfo &&
      pathInfo.path_details &&
      Object.keys(pathInfo.path_details).length > 0
        ? pathInfo.path_details
        : null;
    PATH_DATA = PATH_DATA
      ? JSON.parse(JSON.stringify(pathInfo.path_details))
      : {};
    if (PATH_DATA?.program_map) {
      PATH_DATA = {
        ...PATH_DATA,
        terms: [
          ...PATH_DATA.terms.map(term => {
            const electiveCourses = newSortArrayOfObjPropertyBased(
              term.electives,
              'sequence',
            ).map(el => ({
              course_id: el.elective_id,
              course_type: el.course_type,
              course_name: el.elective_name,
              units: el.units,
            }));
            // Filtering out duplicate entries
            return {
              ...term,
              courses: Object.values(
                [...term.courses, ...electiveCourses].reduce(
                  (acc, obj) => ({...acc, [obj.course_id]: obj}),
                  {},
                ),
              ),
            };
          }),
        ],
      };
    }
    setProgramMapCoursePlan({data: PATH_DATA});
  }, [pathInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  let message = NoCourseMapFound;

  const VERIFY_DATA = programMapCoursePlan;
  const CourseMapData =
    VERIFY_DATA?.data?.terms &&
    isArray(VERIFY_DATA.data.terms) &&
    !isEmpty(VERIFY_DATA.data.terms)
      ? VERIFY_DATA
      : {};

  return (
    <ErrorBoundary nameOfComponent='mod-comp-settings-myeducation-mycoursemap'>
      <RequestErrorLoader
        body={{
          data: CourseMapData,
        }}
        overideNoDataContainer={
          <NoContentNavigator
            pathTo='/programs'
            message={message}
            label={ExploreProgramsBtnText}
          />
        }>
        <CourseMapNew myPlanData={CourseMapData} showModal={showModal} />
        {/* <CourseMap showFilters myPlanData={CourseMapData} /> */}
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

ProgramCourseMap.propTypes = {
  pathInfo: PropTypes.object.isRequired,
  showModal: Boolean,
};

export default ProgramCourseMap;

import React, {useEffect, useState} from 'react';
import {Button, Grid} from 'mui-core';
import {useForm, Controller} from 'react-hook-form';
import resumeBuilderData from 'data/resumeBuilder.json';
import {Checkbox, Divider, FormControlLabel, useTheme} from '@mui/material';

const {
  goAIButton: {modalButtonLabel},
} = resumeBuilderData || {};

const GoAIListForm = ({list, submitListForm, isPlainText = false}) => {
  const {handleSubmit, register, control, getValues, watch} = useForm();
  const [isValueChanged, handleValueChanged] = useState(true);
  const theme = useTheme();

  const initialValue = {};
  list.map(ele => {
    initialValue[ele] = false;
  });

  const watchAllFields = watch({
    defaultValue: initialValue,
  });

  const addToResume = data => {
    let valueToPass = isPlainText ? [] : ['<ul>'];
    const values = Object.keys(data);
    list.forEach(ele => {
      if (
        values.includes(
          ele.replaceAll('.', '').replaceAll("'", '').replaceAll('"', ''),
        ) &&
        data[ele.replaceAll('.', '').replaceAll("'", '').replaceAll('"', '')]
      ) {
        if (isPlainText) valueToPass.push(ele);
        else valueToPass.push(`<li>${ele}</li>`);
      }
    });
    if (!isPlainText) valueToPass.push('</ul>');
    submitListForm(valueToPass);
  };

  useEffect(() => {
    const isSelected = Object.values(getValues()).some(ele => ele === true);

    if (isSelected) {
      handleValueChanged(false);
    } else handleValueChanged(true);
  }, [watchAllFields]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form>
      <Grid container>
        <Grid
          item
          sx={{
            width: '100%',
            overflowY: 'auto',
            mx: {xs: 0, md: 2},
            maxHeight: {xs: '100%', md: '450px'},
          }}
          spacing={3}>
          {list && list.length ? (
            list.map((ele, index) => (
              <Grid
                item
                xs={12}
                key={`${ele}-${index}`}
                pt={2}
                px={{xs: 5, md: 7}}
                sx={{
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.darkGray.main
                      : theme.palette.midGray.main,
                }}>
                <Controller
                  control={control}
                  inputRef={register}
                  defaultValue={false}
                  name={ele
                    .replaceAll('.', '')
                    .replaceAll("'", '')
                    .replaceAll('"', '')}
                  render={({field}) => (
                    <FormControlLabel
                      label={ele}
                      control={<Checkbox {...field} />}
                    />
                  )}
                />
                <Divider sx={{mt: 2}} />
              </Grid>
            ))
          ) : (
            <></>
          )}
        </Grid>
        <Grid item p={4} xs={12} display='flex' justifyContent='center'>
          <Button
            disabled={isValueChanged}
            type='button'
            variant='contained'
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              handleSubmit(addToResume)(event);
            }}>
            {modalButtonLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default GoAIListForm;

import React, {useState, useEffect} from 'react';
import AddPublications from './AddPublications';
import {Grid, Button} from 'mui-core';
import {useCheckMobileView} from 'core/hooks';
import {Edit, Delete} from '@mui/icons-material';
import resumeBuilderData from 'data/resumeBuilder.json';
import {Box, Stack, Divider, IconButton, Typography} from '@mui/material';

const {
  stepsMeta: {publications: {formData = {}} = {}},
} = resumeBuilderData || {};

const PublicationList = ({
  data,
  formRef,
  onChange,
  viewOnly,
  getPayload,
  publicationTypes = [],
}) => {
  const {isMobileView} = useCheckMobileView();
  const [editIndex, setEditIndex] = useState(-1);
  const {awards_publications: publications = []} = data || {};
  const {AddPublicationTxt, editTxt, deleteTxt} = formData || {};
  const publicationItem = publicationTypes?.find(
    i => i.item_type === 'Publication',
  );
  const publicationList =
    publications.filter(
      publication => publication.item_type === publicationItem.item_type_uuid,
    ) || [];
  const [isAdd, setAdd] = useState(
    publicationList?.length === 0 ? true : false,
  );

  useEffect(() => {
    if (publicationList?.length === 0) setAdd(true);
    else setAdd(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const cancelAddEdit = () => {
    setAdd(false);
    setEditIndex(-1);
  };

  const editData = idx => {
    setAdd(false);
    setEditIndex(idx);
  };

  const getAwardType = publication => {
    const data = {...publication};
    const publicationTypesData = publicationTypes || [];
    if (Array.isArray(publicationTypesData)) {
      const item = publicationTypesData.find(
        i => i.item_type === 'Publication',
      );
      if (item) {
        data['item_type'] = item.item_type_uuid;
      }
    }
    return data;
  };

  const onFormContentUpdate = (data, action) => {
    let updatedData = getAwardType(data) || {};
    if (onChange) {
      onChange(updatedData, action);
      cancelAddEdit();
    }
  };

  const getFormattedPayload = (data, action) => {
    return getPayload(getAwardType(data), action);
  };

  return (
    <Stack overflow='auto' maxHeight='600px' pr={1} sx={{overflowX: 'hidden'}}>
      {publicationList &&
        Array.isArray(publicationList) &&
        publicationList?.length > 0 &&
        publicationList?.map((publication, idx) => {
          return editIndex === idx ? (
            <Box key={idx}>
              <AddPublications
                edit={true}
                formRef={formRef}
                data={publication}
                onCancel={cancelAddEdit}
                onSubmit={obj => onFormContentUpdate(obj, 'edit')}
                getPayload={item => getFormattedPayload(item, 'edit')}
              />
              <Divider sx={{my: 2}} />
            </Box>
          ) : (
            <Box key={idx}>
              <Grid py={3} container spacing={2}>
                <Grid item xs={12} py={0}>
                  <div>
                    <Grid container p={0} alignItems='center'>
                      <Grid item xs={9} sm={9} md={8} lg={9}>
                        <Typography
                          variant='h5'
                          color='text.primary'
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          fontSize={{xs: 12, sm: 14, md: 18}}>
                          {publication?.item_title}
                        </Typography>
                        <Typography variant='body1' color='text.primary'>
                          {publication?.role || '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={4} lg={3} p={0}>
                        {!viewOnly && (
                          <Stack direction='row'>
                            {isMobileView ? (
                              <>
                                <IconButton onClick={() => editData(idx)}>
                                  <Edit
                                    sx={{fontSize: {xs: 16, sm: 18}}}
                                    color='primary'
                                  />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    onFormContentUpdate(publication, 'delete')
                                  }>
                                  <Delete
                                    sx={{fontSize: {xs: 16, sm: 18}}}
                                    color='primary'
                                  />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <Button
                                  size='large'
                                  onClick={() => editData(idx)}>
                                  {editTxt}
                                </Button>
                                <Button
                                  size='large'
                                  onClick={() =>
                                    onFormContentUpdate(publication, 'delete')
                                  }>
                                  {deleteTxt}
                                </Button>
                              </>
                            )}
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Divider />
            </Box>
          );
        })}
      {!viewOnly && (
        <Box py={3}>
          {isAdd && (
            <AddPublications
              formRef={formRef}
              onCancel={cancelAddEdit}
              firstTime={publicationList?.length === 0}
              onSubmit={formContent => onFormContentUpdate(formContent, 'add')}
              getPayload={formContent =>
                getFormattedPayload(formContent, 'add')
              }
            />
          )}
          {!isAdd && editIndex === -1 && (
            <Button
              variant='contained'
              onClick={() => setAdd(true)}
              size={isMobileView ? 'small' : 'large'}>
              {AddPublicationTxt}
            </Button>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default PublicationList;

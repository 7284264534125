import React from 'react';
import {StyleSheet, View, Text} from '@react-pdf/renderer';
import Bio from './Bio';
import Skills from './Skills';
import Awards from './Awards';
import Education from './Education';
import Interests from './Interests';
import Portfolio from './Portfolio';
import WorkExperience from './WorkExperience';
import VolunteerExperience from './VolunteerExperience';

const styles = StyleSheet.create({
  body: {
    width: 'auto',
  },
  titleContainer: {
    textAlign: 'center',
    paddingBottom: 6,
  },
  container: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  header: {
    padding: 6,
    fontSize: 12,
    width: '100%',
    borderBottomWidth: 1,
    paddingLeft: 8,
    textAlign: 'left',
    borderStyle: 'solid',
    borderColor: '#8050f4',
  },
  headerText: {
    color: '#8050f4',
  },
  content: {
    fontSize: 10,
    color: '#000',
    paddingLeft: 8,
    paddingTop: 10,
    paddingBottom: 10,
  },
  inline: {
    marginTop: 2,
    marginBottom: 2,
  },
  boldLabel: {
    width: '100%',
    fontSize: 10,
    color: '#000',
    marginBottom: 6,
    textAlign: 'left',
    fontWeight: '900',
  },
  value: {
    fontSize: 10,
    color: '#000',
    textAlign: 'left',
  },
  capitalText: {
    textTransform: 'capitalize',
  },
  title: {
    fontSize: 16,
    color: '#8050f4',
  },
  educationInfo: {
    flexDirection: 'row',
    marginVertical: 4,
    width: '100%',
  },
  educationLabel: {
    width: '33%',
    fontWeight: '900',
    textAlign: 'left',
    fontSize: 10,
    color: '#000',
    marginRight: 2,
  },
  largeText: {
    width: '65%',
  },
  skillsSection: {
    marginLeft: 10,
  },
  skillsHeading: {
    marginTop: 8,
    paddingBottom: 8,
    borderBottom: 1,
    borderBottomColor: '#cccccc',
  },
  list: {
    paddingLeft: 8,
    marginTop: 4,
    marginBottom: 4,
  },
  SkillsList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  portfolioLabel: {
    marginBottom: 10,
  },
  portfolioLink: {
    color: '#3bc4ff',
    width: '100%',
  },
});

export default ({
  awardTypes,
  srmAwardTypes,
  studentProfile,
  credentialsInfo,
}) => {
  const {
    bio,
    skills,
    work_exp,
    interest,
    education,
    portfolio_url = '',
    voluntary_exp,
    awards_leadership_projects_publications,
  } = studentProfile || {};
  return (
    <View style={styles.body}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Profile Preview</Text>
      </View>

      {bio && <Bio styles={styles} studentProfile={studentProfile} />}

      {credentialsInfo &&
        Array.isArray(credentialsInfo) &&
        credentialsInfo.length > 0 && (
          <Education
            styles={styles}
            education={education}
            awardTypes={srmAwardTypes}
            credentialsInfo={credentialsInfo}
          />
        )}

      {skills && Array.isArray(skills) && skills.length > 0 && (
        <Skills styles={styles} studentProfile={studentProfile} />
      )}

      {work_exp && Array.isArray(work_exp) && work_exp.length > 0 && (
        <WorkExperience styles={styles} studentProfile={studentProfile} />
      )}

      {voluntary_exp &&
        Array.isArray(voluntary_exp) &&
        voluntary_exp.length > 0 && (
          <VolunteerExperience styles={styles} voluntary_exp={voluntary_exp} />
        )}

      {typeof portfolio_url === 'string' && portfolio_url !== '' && (
        <Portfolio styles={styles} portfolioUrl={portfolio_url} />
      )}

      {awards_leadership_projects_publications &&
        Array.isArray(awards_leadership_projects_publications) &&
        awards_leadership_projects_publications.length > 0 && (
          <Awards
            styles={styles}
            awardTypes={awardTypes}
            studentProfile={studentProfile}
          />
        )}

      {interest && Array.isArray(interest) && interest.length > 0 && (
        <Interests styles={styles} studentProfile={studentProfile} />
      )}
    </View>
  );
};

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {isObject, isString} from 'lodash';
import {themeColorMode} from 'mui-core/utils';
import PropTypes from 'prop-types';

const BackgroundPaper = styled(Box)(({theme, background, isPurple}) => {
  let backgroundColor = theme.palette.background.paper;
  if (isPurple) {
    backgroundColor = themeColorMode(
      theme,
      theme.palette.purplebg.main,
      theme.palette.darkGray.main,
    );
  }
  if (isString(background)) {
    backgroundColor = themeColorMode(
      theme,
      background,
      theme.palette.darkGray.main,
    );
  }
  if (isObject(background) && (background.light || background.dark)) {
    backgroundColor = themeColorMode(
      theme,
      background.light || theme.palette.background.paper,
      background.dark || theme.palette.background.paper,
    );
  }
  if (backgroundColor)
    return {
      backgroundColor,
    };
  return {};
});

BackgroundPaper.propTypes = {
  background: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  // if string -> background is applied to light mode and auto color is applied to dark mode
  // if object -> background.light is applied to light mode and background.dark is applied to dark mode
  isPurple: PropTypes.bool,
  // if true -> background is applied with light purple color and takes auto dark mode.
};

export default BackgroundPaper;

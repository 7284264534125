import {Carousel, RequestErrorLoader, PathCard} from 'core/components';
import './style.less';

const RelatedPrograms = props => {
  return (
    <>
      <RequestErrorLoader
        overideNoDataContainer={<h4>No Programs Found</h4>}
        body={{data: props.data}}>
        {props.data?.length > 0 && (
          <Carousel
            className={`newestProgram-slider ${
              !props.isMobileView && props.data?.length < 4
                ? 'new-program-less-cards'
                : ''
            }`}
            config={{slidesToShow: 4}}
            data={props.data.map(path => {
              const {
                title,
                banner,
                award_type,
                institute_name,
                program_id,
              } = path;
              const pathdata = {
                title,
                banner_cloudinary: banner,
                award_type_name: award_type,
                institute_details: {name: institute_name},
                program_id,
              };
              return (
                <ul className='search-card p-0' key={path.uuid}>
                  <PathCard
                    data={pathdata}
                    enableNavigation
                    customClass={props.data.length < 4 ? 'customPathcard' : ''}
                  />
                </ul>
              );
            })}
          />
        )}
      </RequestErrorLoader>
    </>
  );
};

export default RelatedPrograms;

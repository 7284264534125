export const RESET_RESUME_DATA = 'RESET_RESUME_DATA';
export const INITIATE_RESUME_DATA = 'INITIATE_RESUME_DATA';
export const UPDATE_RESUME_DATA_ERROR = 'UPDATE_RESUME_DATA_ERROR';
export const UPDATE_RESUME_DATA_REQUEST = 'UPDATE_RESUME_DATA_REQUEST';
export const RECIEVED_UPDATE_RESUME_DATA_SUCCESS =
  'RECIEVED_UPDATE_RESUME_DATA_SUCCESS';

export const RESUME_DETAILS_ERROR = 'RESUME_DETAILS_ERROR';
export const RESUME_DETAILS_REQUEST = 'RESUME_DETAILS_REQUEST';
export const RECIEVED_RESUME_DETAILS_SUCCESS =
  'RECIEVED_RESUME_DETAILS_SUCCESS';

export const CAREER_CRAFT_ERROR = 'CAREER_CRAFT_ERROR';
export const CAREER_CRAFT_REQUEST = 'CAREER_CRAFT_REQUEST';
export const CAREER_CRAFT_SUCCESS = 'CAREER_CRAFT_SUCCESS';
export const CAREER_CRAFT_CLEAR = 'CAREER_CRAFT_CLEAR';
export const CAREER_CRAFT_PARAMS = 'CAREER_CRAFT_PARAMS';

// linkedIn
export const LINKEDIN_DETAILS_REQUEST = 'LINKEDIN_DETAILS_REQUEST';
export const LINKEDIN_DETAILS_RECEIVE = 'LINKEDIN_DETAILS_RECEIVE';
export const LINKEDIN_DETAILS_ERROR = 'LINKEDIN_DETAILS_ERROR';
export const LINKEDIN_DETAILS_CLEAR = 'LINKEDIN_DETAILS_CLEAR';

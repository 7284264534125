import * as TYPES from './constants';
import {API} from 'config';

import {
  recieveCitizenship,
  requestCitizenship,
  citizenshipError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CITIZENSHIP_LOAD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.CITIZENSHIP_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CITIZENSHIP_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchCitizenship = () => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    let endPoint = `${API.gps.citizenship}`;
    dispatch(requestCitizenship());
    return services.fetchRecords(endPoint, token).then(res => {
      if (Array.isArray(res)) {
        res = res.sort((a, b) => (a.name < b.name ? 1 : -1));
        dispatch(recieveCitizenship(res));
      } else dispatch(citizenshipError());
    });
  } catch (err) {
    console.log(err);
    dispatch(citizenshipError(err));
  }
};

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestPublicInsightJobs,
  recievePublicInsightJobs,
  requestPublicInsightJobsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PUBLIC_INSIGHT_JOBS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.PUBLIC_INSIGHT_JOBS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: {
          data: action?.payload?.data || [],
          no_of_records: action?.payload?.page_details?.total_records,
          datalength:
            (action.payload?.data &&
              Array.isArray(action.payload?.data) &&
              action.payload?.data.length) ||
            0,
        },
      };
    case TYPES.CLEAR_PUBLIC_INSIGHT_JOBS:
      return {
        request: false,
        error: false,
      };
    case TYPES.PUBLIC_INSIGHT_JOBS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchPublicInsightJobs = (reqParams, filters) => (
  dispatch,
  getState,
) => {
  dispatch(requestPublicInsightJobs());
  const {
    occupation_onnet,
    sort = 'date_desc',
    page = 1,
    page_size = 25,
    location_type = 'all',
  } = reqParams || {};
  try {
    let endPoint = `${API.gps.public_insight_jobs}?onnet_code=${occupation_onnet}&sort=${sort}&location_type=${location_type}&page=${page}&page_size=${page_size}`;
    if (filters) {
      const getFilterKeys = Object.keys(filters);
      if (getFilterKeys.length) {
        getFilterKeys.map(key => {
          if (filters[key]) {
            endPoint += `&${key}=${encodeURIComponent(filters[key])}`;
          }
        });
      }
    }
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recievePublicInsightJobs(res));
        return;
      }
      dispatch(requestPublicInsightJobsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestPublicInsightJobsError(err));
  }
};

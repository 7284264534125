import * as TYPES from './constants';
import {API} from 'config';
import {
  clearMyEvents,
  requestMyEvents,
  recieveMyEvents,
  requestMyEventsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, error: false, data: {}};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_MY_EVENTS:
    case TYPES.REQUEST_EVENT_WITHDRAW:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_MY_EVENTS:
      let data = action.payload;
      if (state.data && state.data.event_details && !action.payload.noAppend) {
        data.event_details = [
          ...state.data.event_details,
          ...data.event_details,
        ];
      }
      return {
        ...state,
        request: false,
        error: false,
        data,
      };
    case TYPES.REMOVE_MY_EVENT:
      let filterdata = {};
      if (state?.data?.event_details?.length) {
        const event_details = state?.data?.event_details?.filter(
          event => event.uuid !== action.eventId,
        );
        filterdata = {
          ...state.data,
          event_details,
        };
      }
      return {
        ...state,
        request: false,
        error: false,
        data: filterdata,
      };
    case TYPES.REQUEST_MY_EVENTS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.CLEAR_MY_EVENTS:
      return initialState;
    default:
      return state;
  }
};

export const fetchMyEvents = (
  pageNo = 1,
  isClearAll,
  noAppend,
  pageSize = 12,
) => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();

  if (isClearAll) {
    dispatch(clearMyEvents());
  }

  if (token) {
    const endPoint = `${API.srm.my_signedup_events}?page_size=${pageSize}&page=${pageNo}`;
    dispatch(requestMyEvents());
    try {
      return services.fetchRecords(endPoint, token).then(res => {
        if (res) {
          dispatch(recieveMyEvents(res, noAppend));
          return;
        }
        dispatch(requestMyEventsError(res));
      });
    } catch (err) {
      console.log(err);
      dispatch(requestMyEventsError(err));
    }
  }
};

import {isObject, isEmpty} from 'lodash';
import * as TYPES from './constants';
import {API} from 'config';

import {
  requestCareerIntrestSurveyResult,
  careerIntrestSurveyErrorResult,
  careerIntrestSurveySuccessResult,
  requestCareerIntrestSurvey,
  careerIntrestSurveySuccess,
  careerIntrestSurveyError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, requestAnswers: false, answers: null};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CAREER_INTEREST_SURVEY_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.CAREER_INTEREST_SURVEY_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    // case TYPES.SCHOOLS_CLEAR:
    //   return {
    //     ...state,
    //     request: false,
    //     error: false,
    //     data: null,
    //   };
    case TYPES.CAREER_INTEREST_SURVEY_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.CAREER_INTEREST_SURVEY_RESULT_SUCCESS:
      return {
        ...state,
        requestAnswers: false,
        answers: action.payload,
      };
    case TYPES.CAREER_INTEREST_SURVEY_RESULT_REQUEST:
      return {
        ...state,
        requestAnswers: true,
      };
    case TYPES.CAREER_INTEREST_SURVEY_RESULT_ERROR:
      return {
        ...state,
        requestAnswers: false,
      };
    default:
      return state;
  }
};

export const fetchCareerInterestQuestions = () => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  try {
    dispatch(requestCareerIntrestSurvey());
    const endPoint = `${API.gps.assessment_questions}`;
    return Promise.all([
      services.fetchRecords(
        `${endPoint}?assessment_type=activities`,
        token,
        true,
      ),
      services.fetchRecords(
        `${endPoint}?assessment_type=Personal Qualities`,
        token,
        true,
      ),
      services.fetchRecords(
        `${endPoint}?assessment_type=School Subjects`,
        token,
        true,
      ),
    ]).then(results => {
      let data = [];
      if (Array.isArray(results[0])) {
        data = data.concat(results[0]);
      }
      if (Array.isArray(results[1])) {
        data = data.concat(results[1]);
      }
      if (Array.isArray(results[2])) {
        data = data.concat(results[2]);
      }
      dispatch(careerIntrestSurveySuccess(data));
      return;
    });
    // return services.fetchRecords(endPoint, token, true).then(res => {
    //   if (res && Array.isArray(res)) {
    //     dispatch(careerIntrestSurveySuccess(res));
    //     return;
    //   }
    //   dispatch(careerIntrestSurveyError(res));
    // });
  } catch (err) {
    console.log(err);
    dispatch(careerIntrestSurveyError(err));
  }
};

export const saveCareerInterestSurvey = data => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  try {
    // dispatch(requestCareerIntrestSurvey());
    const endPoint = `${API.gps.student_cis}`;
    return services
      .createUpdateRecord(token, endPoint, data, 'POST')
      .then(res => {
        if (res && Array.isArray(res)) {
          //   dispatch(careerIntrestSurveySuccess(res));
          return;
        }
        // dispatch(careerIntrestSurveyError(res));
      });
  } catch (err) {
    console.log(err);
    // dispatch(careerIntrestSurveyError(err));
  }
};

export const fetchCISResults = () => (dispatch, getState) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  try {
    dispatch(requestCareerIntrestSurveyResult());
    const endPoint = `${API.gps.student_cis}`;
    return services.fetchRecords(endPoint, token, null).then(res => {
      if (res && res[0]) {
        dispatch(careerIntrestSurveySuccessResult(res[0]));
        return;
      }
      dispatch(careerIntrestSurveyErrorResult(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(careerIntrestSurveyErrorResult(err));
  }
};

import * as TYPES from './constants';

// Occupation Search
export const requestOccupationSearch = () => ({
  type: TYPES.OCCUPATION_SEARCH_REQUEST,
});
export const recieveOccupationSearch = json => ({
  type: TYPES.OCCUPATION_SEARCH_SUCCESS,
  payload: json,
});
export const requestOccupationSearchError = err => ({
  type: TYPES.OCCUPATION_SEARCH_ERROR,
  err,
});

// Occupation Details
export const requestOccupationDetails = () => ({
  type: TYPES.OCCUPATION_DETAILS_REQUEST,
});
export const recieveOccupationDetails = json => ({
  type: TYPES.OCCUPATION_DETAILS_SUCCESS,
  payload: json,
});
export const requestOccupationDetailsError = err => ({
  type: TYPES.OCCUPATION_DETAILS_ERROR,
  err,
});
export const clearOccupationDetails = () => ({
  type: TYPES.CLEAR_OCCUPATION_DETAILS,
});

// Job Opportunities
export const requestJobOpportunities = () => ({
  type: TYPES.JOB_OPPORUNITIES_REQUEST,
});
export const recieveJobOpportunities = json => ({
  type: TYPES.JOB_OPPORUNITIES_SUCCESS,
  payload: json,
});
export const clearJobOpportunities = () => ({
  type: TYPES.CLEAR_JOB_OPPORUNITIES,
});
export const requestJobOpportunitiesError = err => ({
  type: TYPES.JOB_OPPORUNITIES_ERROR,
  err,
});

// JobZone Details
export const requestJobZoneDetails = () => ({
  type: TYPES.JOBZONE_DETAILS_REQUEST,
});
export const recieveJobZoneDetails = json => ({
  type: TYPES.JOBZONE_DETAILS_SUCCESS,
  payload: json,
});
export const requestJobZoneDetailsError = err => ({
  type: TYPES.JOBZONE_DETAILS_ERROR,
  err,
});

// occupataion roles

export const requestOccupationRoles = () => ({
  type: TYPES.OCCUPATION_ROLE_REQUEST,
});
export const recieveOccupationRoles = json => ({
  type: TYPES.OCCUPATION_ROLE_SUCCESS,
  payload: json,
});
export const requestOccupationRolesError = err => ({
  type: TYPES.OCCUPATION_ROLE_ERROR,
  err,
});

// save rate my intrest

export const requestSaveRateOcuupation = () => ({
  type: TYPES.OCCUPATION_RATE_SAVE_REQUEST,
});
export const recieveSaveRateOcuupation = json => ({
  type: TYPES.OCCUPATION_RATE_SAVE_SUCCESS,
  payload: json,
});
export const requestSaveRateOcuupationError = err => ({
  type: TYPES.OCCUPATION_RATE_SAVE_ERROR,
  err,
});

// OCCUPATION SUBSCRIBE
export const requestGetSubscribeOccupation = () => ({
  type: TYPES.OCCUPATION_SUBSCRIBE_GET_REQUEST,
});
export const recieveGetSubscribeOccupation = json => ({
  type: TYPES.OCCUPATION_SUBSCRIBE_GET_SUCCESS,
  payload: json,
});
export const requestGetSubscribeOccupationError = err => ({
  type: TYPES.OCCUPATION_SUBSCRIBE_GET_ERROR,
  err,
});
export const requestSubscribeOccupation = () => ({
  type: TYPES.OCCUPATION_SUBSCRIBE_REQUEST,
});
export const recieveSubscribeOccupation = json => ({
  type: TYPES.OCCUPATION_SUBSCRIBE_SUCCESS,
  payload: json,
});
export const requestSubscribeOccupationError = err => ({
  type: TYPES.OCCUPATION_SUBSCRIBE_ERROR,
  err,
});
export const resetSubscribedOccupationData = () => ({
  type: TYPES.OCCUPATION_SUBSCRIBE_RESET,
});

// Opportunity Interest (Save/Apply)
export const requestOpportunityInterest = () => ({
  type: TYPES.OPPORTUNITY_INTEREST_REQUEST,
});
export const receiveOpportunityInterest = json => ({
  type: TYPES.OPPORTUNITY_INTEREST_SUCCESS,
  payload: json,
});
export const clearOpportunityInterest = () => ({
  type: TYPES.CLEAR_OPPORTUNITY_INTEREST,
});
export const requestOpportunityInterestError = err => ({
  type: TYPES.OPPORTUNITY_INTEREST_ERROR,
  err,
});

// unSave Opportunity
export const requestUnSaveOpportunity = () => ({
  type: TYPES.OPPORTUNITY_UNSAVE_REQUEST,
});
export const receiveUnSaveOpportunity = json => ({
  type: TYPES.OPPORTUNITY_UNSAVE_SUCCESS,
  payload: json,
});
export const handleUnSaveOpportunityError = err => ({
  type: TYPES.OPPORTUNITY_UNSAVE_ERROR,
  err,
});

// Saved Opportunities
export const requestOpportunitySaved = () => ({
  type: TYPES.OPPORTUNITY_SAVED_REQUEST,
});
export const recieveOpportunitySaved = json => ({
  type: TYPES.OPPORTUNITY_SAVED_SUCCESS,
  payload: json,
});
export const requestOpportunitySavedError = err => ({
  type: TYPES.OPPORTUNITY_SAVED_ERROR,
  err,
});
export const clearOpportunitySaved = () => ({
  type: TYPES.CLEAR_OPPORTUNITY_SAVED,
});

// Applied Opportunities
export const requestOpportunityApplied = () => ({
  type: TYPES.OPPORTUNITY_APPLIED_REQUEST,
});
export const recieveOpportunityApplied = json => ({
  type: TYPES.OPPORTUNITY_APPLIED_SUCCESS,
  payload: json,
});
export const requestOpportunityAppliedError = err => ({
  type: TYPES.OPPORTUNITY_APPLIED_ERROR,
  err,
});
export const clearOpportunityApplied = () => ({
  type: TYPES.CLEAR_OPPORTUNITY_APPLIED,
});

// New Opportunities
export const requestNewOpportunities = () => ({
  type: TYPES.NEW_OPPORTUNITIES_REQUEST,
});
export const receiveNewOpportunities = json => ({
  type: TYPES.NEW_OPPORTUNITIES_RECEIVE,
  payload: json,
});
export const requestNewOpportunitiesError = err => ({
  type: TYPES.NEW_OPPORTUNITIES_ERROR,
  err,
});
export const clearNewOpportunities = () => ({
  type: TYPES.CLEAR_NEW_OPPROTUNITIES,
});

export const requestLocationSearch = () => ({
  type: TYPES.LOCATION_SEARCH_REQUEST,
});
export const recieveLocationSearch = json => ({
  type: TYPES.LOCATION_SEARCH_RECEIVE,
  payload: json,
});
export const requestLocationSearchError = err => ({
  type: TYPES.LOCATION_SEARCH_ERROR,
  err,
});

// My Opportunities
export const requestMyOpportunities = () => ({
  type: TYPES.MY_OPPORTUNITY_REQUEST,
});
export const recieveMyOpportunities = json => ({
  type: TYPES.MY_OPPORTUNITY_SUCCESS,
  payload: json,
});
export const requestMyOpportunitiesError = err => ({
  type: TYPES.MY_OPPORTUNITY_ERROR,
  err,
});
export const clearMyOpportunities = () => ({
  type: TYPES.CLEAR_MY_OPPORTUNITY,
});

// Opportunity Interest (Save/Apply)
export const requestSaveFitAssessmentSurvey = () => ({
  type: TYPES.FIT_ASSESSMENT_SURVEY_SAVE_REQUEST,
});
export const receiveSaveFitAssessmentSurvey = json => ({
  type: TYPES.FIT_ASSESSMENT_SURVEY_SAVE_SUCCESS,
  payload: json,
});
export const requestSaveFitAssessmentSurveyError = err => ({
  type: TYPES.FIT_ASSESSMENT_SURVEY_SAVE_ERROR,
  err,
});

// delete Fit Assessment
export const requesteDeleteFitAssessmentSurvey = () => ({
  type: TYPES.FIT_ASSESSMENT_SURVEY_DELETE_REQUEST,
});
export const receiveDeleteFitAssessmentSurvey = json => ({
  type: TYPES.FIT_ASSESSMENT_SURVEY_DELETE_SUCCESS,
  payload: json,
});
export const handleDeleteFitAssessmentSurveyError = err => ({
  type: TYPES.FIT_ASSESSMENT_SURVEY_DELETE_ERROR,
  err,
});

// Saved Opportunities
export const requestFitAssementSurvey = () => ({
  type: TYPES.FIT_ASSESSMENT_SURVEY_REQUEST,
});
export const recieveFitAssementSurvey = json => ({
  type: TYPES.FIT_ASSESSMENT_SURVEY_SUCCESS,
  payload: json,
});
export const requestFitAssementSurveyError = err => ({
  type: TYPES.FIT_ASSESSMENT_SURVEY_ERROR,
  err,
});
export const clearFitAssementSurvey = () => ({
  type: TYPES.CLEAR_FIT_ASSESSMENT_SURVEY,
});

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {AwardIcon} from 'mui-core/Icons';
import {CLOUDINARY} from 'core/cloudinary';
import {useHistory} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {AdvancedImage} from '@cloudinary/react';
import {capitalizeFirstLetter} from 'core/utils';
import {MoreHoriz, LocationOn} from '@mui/icons-material';
import {Card, Menu, LazyImage, ErrorBoundary} from 'mui-core';
import {
  Box,
  Stack,
  Paper,
  IconButton,
  Typography,
  CardActionArea,
} from '@mui/material';
import {amber, lightBlue} from '@mui/material/colors';

const TagContainer = styled(Stack)(({}) => ({
  top: 20,
  left: 0,
  zIndex: 1,
  width: 120,
  position: 'absolute',
}));

const tagTypeColor = (tagType, theme) => {
  switch (tagType) {
    case 'in_demand': {
      return {background: theme.palette.teal600.main, color: 'white'};
    }
    case 'popular': {
      return {background: theme.palette.orange700.main, color: 'white'};
    }
    case 'new': {
      return {background: theme.palette.blue600.main, color: 'white'};
    }
    case 'online': {
      return {background: theme.palette.fuchsia600.main, color: 'white'};
    }
    case 'Enrolled': {
      return {background: `${amber[500]}`, color: 'black'};
    }
    case 'Saved': {
      return {background: `${lightBlue[300]}`, color: 'black'};
    }
    default:
      return {};
  }
};

const Tag = styled(Box)(({theme, type}) => ({
  display: 'flex',
  padding: '4px 0px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '0 12px 12px 0',
  ...tagTypeColor(type, theme),
}));

const StyledTitle = styled(Typography)(({}) => ({
  width: '100%',
  textAlign: 'left',
  overflow: 'hidden',
  WebkitLineClamp: '1',
  color: 'text.primary',
  display: '-webkit-box',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
}));

const EllipsesIcon = styled(IconButton)(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main,
  position: 'absolute',
  padding: '2px',
  float: 'right',
  right: '12px',
  top: '12px',
  zIndex: 1,
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
}));

const PathCard = ({
  data,
  config,
  width,
  displayEllipses,
  target = '_blank',
  enableNavigation,
  customClass = '',
  enableTitleNavigation,
  customTag = '',
  hideLocation = false,
  hideAwardType = false,
}) => {
  const {
    id,
    title = '',
    tagType,
    program,
    popular,
    in_demand,
    location = '',
    new: new_path,
    provider_name,
    award_type_name,
    banner_cloudinary,
    provider_logo_cloudinary,
    institute_details: {name, address, logo_cloudinary} = {},
    occupation_details: {occupation_name} = {},
  } = data || {};
  const {type} = config || {};
  const history = useHistory();
  const {
    location: {pathname},
  } = history;

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const getMenu = () => {
    let menu = [];
    let dataPassed = {};
    // Check if the data needs to be passed to Menu item if yes then evaluate the actual value
    if (displayEllipses && displayEllipses.length > 0) {
      displayEllipses.map((item, idx) => {
        if ('passingData' in item) {
          item.passingData.map(data => {
            dataPassed[data] = eval(data);
          });
          item['dataPassed'] = {...dataPassed};
        }
      });
      menu = displayEllipses;
    }
    return (
      <Menu data={menu} open={open} anchorEl={anchorEl} onClose={handleClose} />
    );
  };

  const pathNavigateTo = path => {
    let awardType = null;
    if (award_type_name && award_type_name !== 'null') {
      awardType = award_type_name;
    }
    let url = `/program-details/${
      path?.title && encodeURIComponent(path.title)
    }/${id}`;

    // if (type && type === 'occupation') {
    //   url = `/occupation?query=${
    //     occupation_name && encodeURIComponent(occupation_name)
    //   }`;
    // }

    // if (type && type === 'appliedWorkforce') {
    //   url = `/programs/${encodeURIComponent(title)}/${encodeURIComponent(
    //     name,
    //   )}?type=${tagType}`;
    //   if (tagType && tagType === 'Job') {
    //     url = `/opportunity/local/${encodeURIComponent(
    //       title,
    //     )}/${encodeURIComponent(name)}`;
    //   }
    // }

    // if (type && type === 'cluster') {
    //   url = `/search?cluster_name=${encodeURIComponent(path?.title)}`;
    // }

    target === '_self' || pathname === '/' || pathname.includes('/dashboard')
      ? history.push(url)
      : window.open(url, target);
  };

  const cardWidth = width ? {width} : {};
  // provider_log is for criminal justice created program
  let logoImage = provider_logo_cloudinary || logo_cloudinary || '';
  logoImage = CLOUDINARY.getLogoImage(logoImage);

  return (
    <ErrorBoundary nameOfComponent='core-pathcard'>
      <Card
        component={CardActionArea}
        sx={{
          height: '100%',
          display: 'flex',
          cursor: 'pointer',
          minHeight: '360px',
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          ...cardWidth,
        }}
        onClick={() => enableNavigation && pathNavigateTo(data)}>
        <TagContainer spacing={0.8}>
          {customTag ? (
            <>
              <Tag type={customTag}>
                <Typography variant='body2'>{customTag}</Typography>
              </Tag>
            </>
          ) : (
            <>
              {in_demand && (
                <Tag type='in_demand'>
                  <Typography variant='body2'>In-Demand</Typography>
                </Tag>
              )}
              {location === 'Online' && (
                <Tag type='online'>
                  <Typography variant='body2'>Online</Typography>
                </Tag>
              )}
              {popular && (
                <Tag type='popular'>
                  <Typography variant='body2'>Popular</Typography>
                </Tag>
              )}
              {new_path && (
                <Tag type='new'>
                  <Typography variant='body2'>New</Typography>
                </Tag>
              )}
            </>
          )}
        </TagContainer>
        {displayEllipses && (
          <>
            <EllipsesIcon
              size='small'
              id='basic-button'
              component='label'
              aria-haspopup='true'
              onClick={handleClick}
              aria-expanded={open ? 'true' : undefined}
              aria-controls={open ? 'basic-menu' : undefined}>
              <MoreHoriz />
            </EllipsesIcon>
            {getMenu()}
          </>
        )}
        <Box height={172} width='100%'>
          <LazyImage
            type={type}
            renderSrcSet
            src={banner_cloudinary}
            alt={`${title || occupation_name || ''}-program-banner`}
          />
        </Box>
        {(provider_logo_cloudinary || logo_cloudinary) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Paper
              sx={{
                width: 200,
                height: 60,
                display: 'flex',
                marginTop: '-30px',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '5px',
              }}>
              <AdvancedImage
                width='auto'
                height='100%'
                cldImg={logoImage}
                style={{maxWidth: '100%', objectFit: 'contain'}}
                alt={`${name || config?.nameLabel || ''}-college-logo`}
              />
            </Paper>
          </Box>
        )}

        <Box
          p={2}
          width='100%'
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
          }}>
          <StyledTitle
            variant='h6'
            title={`${title || occupation_name || ''} `}
            onClick={() => enableTitleNavigation && pathNavigateTo(data)}>
            {title || occupation_name}
          </StyledTitle>
          {(provider_name || name || config?.nameLabel) && (
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography
                noWrap
                variant='body2'
                color='grayText'
                title={`${name || config?.nameLabel} `}>
                {provider_name || name || config?.nameLabel}
              </Typography>
            </Stack>
          )}
          {!hideAwardType && (
            <Stack
              mt={3}
              height={30}
              spacing={1}
              direction='row'
              color='text.primary'
              alignItems='center'>
              {award_type_name && (
                <>
                  <AwardIcon style={{fontSize: '1.25rem'}} />
                  <Typography
                    noWrap
                    variant='body2'
                    title={`${award_type_name || ''} `}>
                    {award_type_name}
                  </Typography>
                </>
              )}
            </Stack>
          )}
          {!hideLocation && (
            <Stack
              height={30}
              spacing={1}
              direction='row'
              color='text.primary'
              alignItems='center'>
              {address?.city && (
                <>
                  <LocationOn style={{fontSize: '1.25rem'}} />
                  <Typography
                    noWrap
                    variant='body2'
                    title={`${capitalizeFirstLetter(address.city)}${
                      address?.state_code ? ', ' + address.state_code : ''
                    } `}>
                    {`${capitalizeFirstLetter(address.city)}${
                      address?.state_code ? ', ' + address.state_code : ''
                    }`}
                  </Typography>
                </>
              )}
            </Stack>
          )}
        </Box>
      </Card>
    </ErrorBoundary>
  );
};

PathCard.propTypes = {
  config: PropTypes.object,
  target: PropTypes.string,
  dispLogo: PropTypes.bool,
  onClosePath: PropTypes.func,
  addToCompare: PropTypes.bool,
  customClass: PropTypes.string,
  displayThumbNail: PropTypes.bool,
  enableNavigation: PropTypes.bool,
  data: PropTypes.object.isRequired,
  enableTitleNavigation: PropTypes.bool,
  displayEllipses: PropTypes.bool || PropTypes.array,
};

export default PathCard;

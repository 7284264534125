import * as TYPES from './constants';
import {API} from 'config';

import {
  removeComparePath,
  removeAllComparePaths,
  requestComparePathsList,
  receiveComparePathsList,
  requestComparePathsListError,
  requestSaveComparisonPaths,
  recieveSaveComparisonPaths,
  requestSaveComparisonPathsError,
  requestSavedComparePaths,
  receiveSavedComparePaths,
  requestSavedComparePathsError,
  requestDeleteComparedPath,
  receiveDeleteComparedPath,
  requestDeleteComparedPathError,
} from './actions';

import {Services} from 'core/Services';
import {isObject, isEmpty} from 'lodash';

const services = new Services();

const initialState = {
  data: [],
  list: [],
  flag: null,
  error: false,
  request: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ADD_COMPARE_PATH_SUCCESS:
      const filteredList = state.data.filter(
        obj => obj.uuid === action.payload.uuid,
      );
      let data = [...state.data];
      if (filteredList.length === 0) {
        data = [...state.data, action.payload];
      }
      return {
        ...state,
        request: false,
        error: false,
        data,
      };
    case TYPES.REMOVE_COMPARE_PATH:
      return {
        ...state,
        data: state.data.filter(path => path.uuid !== action.payload.uuid),
      };
    case TYPES.REMOVE_ALL_COMPARE_PATH:
      return {
        ...initialState,
      };
    case TYPES.COMPARE_PATHS_LIST_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
        list: [],
      };
    case TYPES.COMPARE_PATHS_LIST_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        list: action.payload,
      };
    case TYPES.COMPARE_PATHS_LIST_ERROR:
      return {
        ...state,
        request: false,
        error: true,
        list: [],
      };
    case TYPES.ADD_COMPARE_PATH_REQUEST:
    case TYPES.SAVED_COMPARISON_PATHS_REQUEST:
    case TYPES.SAVE_COMPARISON_PATHS_REQUEST:
    case TYPES.DELETE_COMPARED_PATH_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.SAVE_COMPARISON_PATHS_SUCCESS:
    case TYPES.DELETE_COMPARED_PATH_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
      };
    case TYPES.SAVED_COMPARISON_PATHS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        flag: 'viaAPI',
        data: action.payload,
      };

    case TYPES.ADD_COMPARE_PATH_ERROR:
    case TYPES.SAVED_COMPARISON_PATHS_ERROR:
    case TYPES.SAVE_COMPARISON_PATHS_ERROR:
    case TYPES.DELETE_COMPARED_PATH_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const removePathFromCompare = path => (dispatch, getState) =>
  dispatch(removeComparePath(path));

export const forceClearComparePaths = () => (dispatch, getState) =>
  dispatch(removeAllComparePaths());

export const clearComparePaths = () => (dispatch, getState) => {
  const {
    pathways: {comparePaths: {flag = null} = {}},
  } = getState();
  flag && dispatch(removeAllComparePaths());
};

export const fetchComparePathsList = () => (dispatch, getState) => {
  try {
    const endPoint = `${API.gps.compare_paths}`;
    const {
      auth: {auth: {token = null} = {}},
    } = getState();

    if (token) {
      dispatch(requestComparePathsList());
      return services.fetchRecords(endPoint, token).then(res => {
        if (Array.isArray(res)) {
          dispatch(receiveComparePathsList(res));
          return;
        }
        dispatch(requestComparePathsListError(res['Error']));
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestComparePathsListError(err));
  }
};

export const saveComparison = (comparisonTitle, callback) => (
  dispatch,
  getState,
) => {
  try {
    const body = {
      comparison_title: comparisonTitle,
    };
    const endPoint = `${API.srm.compare_path}`;
    const {
      auth: {auth: {token = null} = {}},
      pathways: {comparePaths: {data: comparePathsList = []} = {}},
    } = getState();

    if (token) {
      dispatch(requestSaveComparisonPaths());
      comparePathsList.map((path, index) => {
        body[`path_uuid_${index + 1}`] = path.uuid;
      });
      return services
        .createUpdateRecord(token, endPoint, body, 'POST')
        .then(res => {
          if (res && res.compare_path_uuid) {
            dispatch(recieveSaveComparisonPaths());
            dispatch(removeAllComparePaths());
            if (callback) {
              if (res.compare_path_uuid) {
                callback(false);
              } else {
                callback(
                  res.error ? res.error : 'Error in saving the compare path',
                );
              }
            }
            return;
          }
          dispatch(requestSaveComparisonPathsError(res['Error']));
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestSaveComparisonPathsError(err));
  }
};

export const deleteComparedPath = (compare_path_uuid, callback) => (
  dispatch,
  getState,
) => {
  try {
    const endPoint = `${API.gps.compare_paths}/${compare_path_uuid}`;
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      dispatch(requestDeleteComparedPath());
      return services
        .deleteRecord(token, endPoint)
        .then(res => {
          if (res) {
            dispatch(receiveDeleteComparedPath(res.Success));
            if (callback) {
              if (res.Success) {
                callback(false);
              } else {
                callback(
                  res.error ? res.error : 'Error in deleting the compare path',
                );
              }
            }
            return;
          }
          dispatch(requestDeleteComparedPathError(res));
        })
        .catch(err => {
          dispatch(requestDeleteComparedPathError(err));
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestDeleteComparedPathError(err));
  }
};

export const fetchComparedPathDetails = (compare_path_title, callback) => (
  dispatch,
  getState,
) => {
  try {
    const endPoint = `${API.gps.compare_paths}/?comparison_title=${compare_path_title}`;
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    if (token) {
      dispatch(requestSavedComparePaths());
      return services
        .fetchRecords(endPoint, token)
        .then(res => {
          if (Array.isArray(res) && res.length) {
            const {path_uuid_1, path_uuid_2, path_uuid_3} = res[0] || {};
            const path_arr = [path_uuid_1, path_uuid_2];
            if (path_uuid_3 && isObject(path_uuid_3) && !isEmpty(path_uuid_3)) {
              path_arr.push(path_uuid_3);
            }
            dispatch(receiveSavedComparePaths(path_arr));
            if (callback) {
              callback(false);
            }
            return;
          } else {
            if (callback) {
              const err_msg = res.error
                ? res.error
                : 'Error in fetching the saved compare path';
              callback(err_msg);
              dispatch(requestSavedComparePathsError(err_msg));
            }
            return;
          }
        })
        .catch(err => {
          dispatch(requestSavedComparePathsError(err));
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(requestSavedComparePathsError(err));
  }
};

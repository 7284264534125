import React, {useState} from 'react';
import {Button, Modal, Progress, Radio, Form} from 'antd';
import {isArray} from 'lodash';

import {ErrorBoundary} from 'core/components';

import {rateMyInterestData} from 'data/occupation.json';
import './style.less';

const getScoreTitle = score => {
  if (score <= 1.5) return rateMyInterestData.notInterestedTxt;
  if (score > 1.5 && score <= 2.5)
    return rateMyInterestData.slightlyInterestedTxt;
  if (score > 2.5 && score <= 3.5)
    return rateMyInterestData.moderatelyInterestedTxt;
  if (score > 3.5 && score <= 4.5) return rateMyInterestData.veryInterestedTxt;
  if (score > 4.5) return rateMyInterestData.extremelyInterestedTxt;
};

const RateMyInterest = ({
  occupationRoles,
  occupationDetails,
  onCancel,
  onSaveOccupationInterest,
}) => {
  const [form] = Form.useForm();
  const [avrRate, setAvgRate] = useState(null);
  const {data: occupationRolesData = [], requestSave, error} =
    occupationRoles || {};
  const {data: {occupation_details} = {}} = occupationDetails || {};
  const {occupation_title} = occupation_details || {};

  const setAverageRate = () => {
    let rates = form.getFieldValue('names');
    if (
      rates &&
      rates.length > 0 &&
      occupationRolesData &&
      occupationRolesData.length > 0
    ) {
      rates = rates.filter(i => i !== undefined && i !== null);
      if (rates.length === occupationRolesData.length) {
        let total = 0;
        rates.forEach(i => {
          total += i;
        });
        const avg = ((total * 5) / (occupationRolesData.length * 5)).toFixed(1);
        setAvgRate(avg);
      } else {
        setAvgRate(null);
      }
    } else {
      setAvgRate(null);
    }
  };
  const onFormChange = v => setAverageRate();

  if (error) {
    return <></>;
  }

  return (
    <Form onChange={onFormChange} form={form}>
      <Modal
        visible
        title={false}
        footer={false}
        className='rate-my-interest'
        onCancel={onCancel}>
        <ErrorBoundary nameOfComponent='mod-comp-occupation-rate-my-interest'>
          <div className='title-b'>
            <div className='ttl'>
              <span className='text-secondary'>{occupation_title}</span>{' '}
              {rateMyInterestData.rolesResponsibilitiesTxt}
            </div>
            <div className='s-ttl'>
              {rateMyInterestData.decriptionPre}{' '}
              <span className='text-secondary'>
                <b>{occupation_title}</b>
              </span>{' '}
              {rateMyInterestData.descriptionPost}
            </div>
          </div>
          <div className='bd'>
            {occupationRolesData &&
            Array.isArray(occupationRolesData) &&
            occupationRolesData?.length > 0 ? (
              <table className='tbl'>
                <thead>
                  <tr className='mob_title'>
                    <th className='fields'>
                      <div>{rateMyInterestData.yourInterestRatingTxt}</div>
                    </th>
                  </tr>
                  <tr>
                    <th className='lbl'>{rateMyInterestData.taskTxt}</th>
                    <th className='fields'>
                      <div className='rating-types'>
                        <div>{rateMyInterestData.notInterestedTxt}</div>
                        <div>{rateMyInterestData.slightlyInterestedTxt}</div>
                        <div>{rateMyInterestData.moderatelyInterestedTxt}</div>
                        <div>{rateMyInterestData.veryInterestedTxt}</div>
                        <div>{rateMyInterestData.extremelyInterestedTxt}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <Form.List
                    name='names'
                    initialValue={
                      occupationRolesData &&
                      isArray(occupationRolesData) &&
                      occupationRolesData.map(i => null)
                    }>
                    {(fields, {add, remove}, {errors}) => (
                      <>
                        {occupationRolesData &&
                          isArray(occupationRolesData) &&
                          occupationRolesData.map((i, idx) => (
                            <tr key={i.id || i.role}>
                              <td className='lbl'>{i.role}</td>
                              <td className='fields'>
                                <Form.Item
                                  //   name={`names[${idx}]`}
                                  {...fields[idx]}
                                  className='fields'
                                  noStyle>
                                  <Radio.Group className='rating-checks'>
                                    <Radio value={1} />
                                    <Radio value={2} />
                                    <Radio value={3} />
                                    <Radio value={4} />
                                    <Radio value={5} />
                                  </Radio.Group>
                                </Form.Item>
                              </td>
                            </tr>
                          ))}
                      </>
                    )}
                  </Form.List>
                </tbody>
              </table>
            ) : (
              <div
                style={{
                  color: '#222',
                  fontSize: '18px',
                  fontWeight: 400,
                  textAlign: 'center',
                }}>
                No Job Tasks Found.
              </div>
            )}
          </div>

          {avrRate && (
            <div className='score'>
              <div>
                <div className='ttl'>{rateMyInterestData.interestScoreTxt}</div>
                <div className='result'>
                  <span>{avrRate}</span> {getScoreTitle(avrRate)}
                </div>
                <Progress
                  percent={avrRate * 10 * 2}
                  showInfo={false}
                  strokeWidth={13}
                  strokeColor='#98BF0E'
                  trailColor='#CECECE'
                />
                <div className='i'>
                  <div>1</div>
                  <div>5</div>
                </div>
              </div>
            </div>
          )}

          <div className='footer'>
            <Button className='btn-cancel' onClick={onCancel}>
              {rateMyInterestData.cancelBtnTxt}
            </Button>
            {occupationRolesData &&
              Array.isArray(occupationRolesData) &&
              occupationRolesData?.length > 0 && (
                <Button
                  type='primary'
                  disabled={!avrRate}
                  loading={requestSave}
                  onClick={() => onSaveOccupationInterest(avrRate)}>
                  {rateMyInterestData.saveBtnTxt}
                </Button>
              )}
          </div>
        </ErrorBoundary>
      </Modal>
    </Form>
  );
};

export default RateMyInterest;

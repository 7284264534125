import {useEffect, useState} from 'react';
import {
  Box,
  Grid,
  Radio,
  Stack,
  Select,
  Divider,
  MenuItem,
  RadioGroup,
  Typography,
  OutlinedInput,
  FormControlLabel,
} from '@mui/material';
import {LocationSearch} from 'mui-core';
import {createField} from 'mui-core/Form';
import jobsStaticData from 'data/jobs.json';

const {
  filterForm: {
    labels,
    nlxJobTypes,
    placeholders,
    radiusOptions,
    datePostedOptions,
  },
} = jobsStaticData || {};

const NLXFilterForm = ({form, filters, nearbyMSA, hideCompanyFilter}) => {
  const {setValue, getValues} = form;
  const [showRadius, setShowRadius] = useState(false);
  const [radius, setRadius] = useState(filters?.radius || '20');

  useEffect(() => {
    if (filters?.location) {
      if (/(^\d{2})/.test(filters.location)) {
        setShowRadius(true);
        setValue('radius', radius);
      } else {
        setShowRadius(false);
        setRadius('20');
      }
      setValue('radius', '20');
      setValue('location', filters.location);
    }
  }, [filters]);

  const handleLocationChange = value => {
    setValue('location', value.location);
  };

  const onInputChange = value => {
    if (/(^\d{2})/.test(value.location)) {
      setShowRadius(true);
    } else {
      setShowRadius(false);
    }
    setValue('location', value.location);
  };

  const handleRadiusChange = event => {
    setRadius(event.target.value);
    setValue('radius', event.target.value);
  };

  const CompanyField = createField('company', {
    form,
    label: labels.company,
    defaultValue: getValues('company') || '',
  })(
    <OutlinedInput label={labels.company} placeholder={placeholders.company} />,
  );

  return (
    <Stack spacing={3}>
      {createField('location', {
        form,
        rules: {
          required: [true, 'Location is mandatory field'],
        },
        defaultValue: getValues('location') || '',
      })(
        <LocationSearch
          calledFromForm
          LocationOptions={nearbyMSA}
          onInputChange={onInputChange}
          onLocationChange={handleLocationChange}
          filters={{location: getValues('location')}}
        />,
      )}
      <Box
        height={showRadius ? 'auto' : 0}
        marginTop={showRadius ? 0 : '0 !important'}>
        {createField('radius', {
          form,
          label: showRadius ? 'Radius' : '',
        })(
          <>
            {showRadius && (
              <Select
                fullWidth
                label='Within'
                value={radius}
                placeholder='Select Radius'
                onChange={handleRadiusChange}>
                {radiusOptions &&
                  Array.isArray(radiusOptions) &&
                  radiusOptions.length > 0 &&
                  radiusOptions.map(type => (
                    <MenuItem value={type.value}>{type.label}</MenuItem>
                  ))}
              </Select>
            )}
          </>,
        )}
      </Box>
      {!hideCompanyFilter && CompanyField}
      <Divider />
      <Box>
        <Typography mb={1} variant='h5' color='text.primary'>
          {labels.datePosted}
        </Typography>
        <Grid container>
          {createField('no_of_days', {
            form,
            defaultValue: getValues('no_of_days') || 0,
          })(
            <RadioGroup row>
              {datePostedOptions.map((item, index) => (
                // <Grid item xs={6} md={6} key={index}>
                <FormControlLabel
                  key={index}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                  sx={{width: 'calc(50% - 5px)'}}
                />
                // </Grid>
              ))}
            </RadioGroup>,
          )}
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Typography mb={1} variant='h5' color='text.primary'>
          {labels.jobType}
        </Typography>
        <Grid container>
          {createField('job_type', {
            form,
            defaultValue: getValues('job_type') || '',
          })(
            <RadioGroup row>
              {nlxJobTypes.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                  sx={{width: 'calc(50% - 5px)'}}
                />
              ))}
            </RadioGroup>,
          )}
        </Grid>
      </Box>
    </Stack>
  );
};

export default NLXFilterForm;

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestOpportunitySaved,
  recieveOpportunitySaved,
  requestOpportunitySavedError,
  clearOpportunitySaved,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPPORTUNITY_SAVED_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.OPPORTUNITY_SAVED_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.OPPORTUNITY_SAVED_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.CLEAR_OPPORTUNITY_SAVED:
      return {
        data: null,
      };
    default:
      return state;
  }
};

export const fetchSavedOpportunities = callback => (dispatch, getState) => {
  dispatch(requestOpportunitySaved());
  const {
    auth: {auth: {token = null, user = {}} = {}},
  } = getState();
  try {
    let endPoint = `${API.gps.opportunity}?action_type=save`;
    return services.fetchRecords(endPoint, token, true).then(res => {
      if (res) {
        if (callback) callback(res);
        dispatch(recieveOpportunitySaved(res));
        return;
      }
      dispatch(requestOpportunitySavedError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestOpportunitySavedError(err));
  }
};

export const resetSavedOpportunities = () => dispatch => {
  dispatch(clearOpportunitySaved());
};

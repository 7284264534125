import * as TYPES from './constants';
import {API} from 'config';

import {
  requestGenEdOptions,
  recieveGenEdOptions,
  recieveActivePaths,
  requestGenEdOptionsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GEN_ED_OPTIONS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.GEN_ED_OPTIONS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLEAR_GEN_ED_OPTIONS:
      return {
        ...state,
        request: false,
        error: false,
        data: null,
      };
    case TYPES.GEN_ED_OPTIONS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchGenEdOptions = () => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
      pathways: {
        activePaths: {data},
      },
    } = getState();

    let endPoint = `${API.gps.student_onboard}`;
    if (token) {
      dispatch(requestGenEdOptions());
      let activePath = data;
      if (
        !activePath ||
        (Array.isArray(activePath) && activePath.length === 0)
      ) {
        activePath = await services.fetchRecords(endPoint, token);
        dispatch(recieveActivePaths(activePath));
      }
      if (activePath && activePath.length) {
        endPoint = `${API.srm.gened_options}?institute_uuid=${activePath[0].institute_uuid}`;
        const GenEdOptions = await services.fetchRecords(endPoint, null);
        dispatch(recieveGenEdOptions(GenEdOptions));
        return;
      }
      dispatch(requestGenEdOptionsError({error: 'something went wrong'}));
    }
  } catch (err) {
    console.log(err);
    dispatch(requestGenEdOptionsError(err));
  }
};

import {lazy} from 'react';

import {
  Dashboard,
  Profile,
  Education,
  CareerFavorites,
  Skills,
  DashboardJobs,
  Workforce,
  MyEvents,
  ProfilePreview,
  CareerInterestSurvey,
  MySettings,
  ProfileSetting,
} from './settings/subModules';
import ExploreCareer from './exploreCareer';
import CareerDetails from './career-details';
import EducationPrograms from './educationPrograms';
import {ProgramDetails} from './educationPrograms/subModules';
import {WhoIsHiring, WhoIsHiringDetails} from './who-is-hiring';
import {Companies, CompanyDetails} from './companies';
import {JobOpportunities, OpportunityDetails} from './job-opportunities';
import {PathsList} from './directory/subModules';
import {MySurvey, SurveyHome} from './survey/subModules';
import {
  MyCareerSurvey,
  CareerSurveyHome,
  ClusterArea,
} from './career-survey/subModules';
import {
  BecomeAPartner,
  PartnerDetails,
  PartnersList,
} from './business-partners/submodules';
import {retry} from 'core/utils';

const Login = lazy(() => retry(() => import('./auth')));
const Home = lazy(() => retry(() => import('./home')));
const Cluster = lazy(() => retry(() => import('./cluster')));
const Institutions = lazy(() => retry(() => import('./institutions')));
const GlobalSearch = lazy(() => retry(() => import('./globalSearch')));
const ExplorePrograms = lazy(() => retry(() => import('./explorePrograms')));
const Search = lazy(() => retry(() => import('./search')));
// const Pathways = lazy(() => retry(() => import('./pathways')));
const ComparePaths = lazy(() => retry(() => import('./compare')));
const Occupation = lazy(() => retry(() => import('./occupation')));
const Programs = lazy(() => retry(() => import('./programs')));
const OccupationDetails = lazy(() =>
  retry(() => import('./occupationDetails')),
);
const LocalSalary = lazy(() => retry(() => import('./local-salary')));
const Jobs = lazy(() => retry(() => import('./jobs')));
const Settings = lazy(() => retry(() => import('./settings')));
const Directory = lazy(() => retry(() => import('./directory')));
const Verify = lazy(() => retry(() => import('./verify')));
const CareerDestination = lazy(() =>
  retry(() => import('./career-destination')),
);
const ResetPassword = lazy(() =>
  retry(() => import('./auth/components/ResetPassword')),
);
const StudentProfile = lazy(() => retry(() => import('./student-profile')));
const Events = lazy(() => retry(() => import('./events')));
const Survey = lazy(() => retry(() => import('./survey')));
const CareerSurvey = lazy(() => retry(() => import('./career-survey')));
const GOSurvey = lazy(() => retry(() => import('./go-survey')));
const SurveyPage = lazy(() => retry(() => import('./go-survey/SurveyPage')));
const GOSurveyDetails = lazy(() =>
  retry(() => import('./go-survey/components/GoSurveyDetails')),
);
const BusinessPartners = lazy(() => retry(() => import('./business-partners')));
const TermsOfUse = lazy(() => retry(() => import('./terms-use')));
const PrivacyPolicy = lazy(() => retry(() => import('./privacy-policy')));
const AccessibilityStatement = lazy(() =>
  retry(() => import('./accessibility-statement')),
);
const NewJobs = lazy(() => retry(() => import('./opportunities/jobs')));
const Apprenticeships = lazy(() =>
  retry(() => import('./opportunities/apprenticeships')),
);
const ApprenticeshipDetails = lazy(() =>
  retry(() =>
    import('./opportunities/apprenticeships/components/ApprenticeshipDetails'),
  ),
);

export {
  Login,
  Home,
  Institutions,
  ExplorePrograms,
  GlobalSearch,
  Cluster,
  Search,
  // Pathways,
  ComparePaths,
  Occupation,
  Programs,
  OccupationDetails,
  LocalSalary,
  Jobs,
  NewJobs,
  Events,
  BusinessPartners,
  Settings,
  //Settings SubModules
  Dashboard,
  Profile,
  ProfilePreview,
  CareerFavorites,
  Education,
  Skills,
  DashboardJobs,
  Workforce,
  MyEvents,
  CareerInterestSurvey,
  ResetPassword,
  StudentProfile,
  Directory,
  CareerDestination,
  // Directory SubModules
  PathsList,
  Survey,
  MySurvey,
  SurveyHome,
  CareerSurvey,
  MyCareerSurvey,
  CareerSurveyHome,
  ClusterArea,
  //BusinessPartner SubModules
  BecomeAPartner,
  PartnerDetails,
  PartnersList,
  //Org
  TermsOfUse,
  PrivacyPolicy,
  Verify,
  AccessibilityStatement,
  MySettings,
  ProfileSetting,
  //
  Companies,
  Apprenticeships,
  ExploreCareer,
  CareerDetails,
  ProgramDetails,
  CompanyDetails,
  JobOpportunities,
  EducationPrograms,
  OpportunityDetails,
  ApprenticeshipDetails,
  //new
  WhoIsHiring,
  WhoIsHiringDetails,
  // GoSurvey
  GOSurvey,
  SurveyPage,
  GOSurveyDetails,
};

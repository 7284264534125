export const getClusters = ({search}) => search.clusters;
export const getPathOccupations = ({search}) => search.pathOccupationSearch;
export const getAwardTypes = ({search}) => search.awardTypes;
export const getSchools = ({search}) => search.schools;
export const getDuration = ({search}) => search.duration;
export const getPaths = ({search}) => search.pathsSearch;
export const getAwards = ({search}) => search.pathsSearch.data?.award_type_list;
export const getDurationType = ({search}) =>
  search.pathsSearch.data?.duration_list;
export const getCareerSearch = ({search}) => search.careerSearch;

import * as TYPES from './constants';
import {API} from 'config';

import {
  requestProgramDetail,
  recieveProgramDetail,
  requestProgramDetailError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PROGRAM_DETAIL_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.PROGRAM_DETAIL_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLEAR_PROGRAM_DETAIL:
      return {
        request: false,
        error: false,
      };
    case TYPES.PROGRAM_DETAIL_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchProgramDetail = (programId, type, instName) => (
  dispatch,
  getState,
) => {
  dispatch(requestProgramDetail());

  try {
    let endPoint = `${API.srm.opportunity}?type=${type}&title=${programId}&institute_name=${instName}`;
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res && Array.isArray(res) && res.length) {
        dispatch(recieveProgramDetail(res[0]));
        return;
      }
      dispatch(requestProgramDetailError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestProgramDetailError(err));
  }
};

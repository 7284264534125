import {useCallback} from 'react';
import CardList from './CardList';
import isEmpty from 'lodash/isEmpty';
import {noSurveyIcon} from 'assets/images';
import {Box, Typography} from '@mui/material';
import {ErrorBoundary, CareerInterestResults} from 'mui-core';

const getClusters = (surveyAnswers, clusters = []) => {
  const {assessment_result = {}} = surveyAnswers || {};
  const priorities = [...new Set(Object.values(assessment_result))].sort(
    (a, b) => b - a,
  );
  const clusterLength = clusters.length;
  const clusterList = clusters?.map((cluster = {}) => {
    let rank = 0;
    if (assessment_result[cluster.cluster_uuid]) {
      rank = priorities.indexOf(assessment_result[cluster.cluster_uuid]) + 1;
    } else {
      rank = clusterLength;
    }
    return {
      title: cluster.cluster_name,
      uuid: cluster.cluster_uuid,
      banner_cloudinary: cluster.cluster_card_url,
      rank,
      count: assessment_result[cluster.cluster_uuid] || 0,
    };
  });
  return clusterList?.sort((a, b) => a.rank - b.rank);
};

const Survey = ({
  clusters,
  surveyAnswers,
  showButton = false,
  showHeaderButton = false,
}) => {
  const clusterData = useCallback(getClusters(surveyAnswers, clusters), [
    clusters,
    surveyAnswers,
  ]);

  return (
    <ErrorBoundary nameOfComponent='core-career-interest-survey-cards'>
      <Box>
        {isEmpty(surveyAnswers?.assessment_result) ? (
          <>
            <Box>
              <img src={noSurveyIcon} alt='No survey icon' />
              <Typography>No Results Found.</Typography>
            </Box>
          </>
        ) : (
          <CareerInterestResults
            clusters={clusters}
            retakeSurvey={false}
            surveyAnswers={surveyAnswers}
            title='My Career Interest Survey'
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};
export default Survey;

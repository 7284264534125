import * as TYPES from './constants';
import {API} from 'config';

import {
  requestOccupationSearch,
  recieveOccupationSearch,
  requestOccupationSearchError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OCCUPATION_SEARCH_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.OCCUPATION_SEARCH_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.OCCUPATION_SEARCH_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchOccupations = keyword => (dispatch, getState) => {
  dispatch(requestOccupationSearch());
  try {
    let endPoint = `${API.srm.occupations}`;
    if (keyword) {
      endPoint += `?name=${keyword}`;
    }
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        dispatch(recieveOccupationSearch(res));
        return;
      }
      dispatch(requestOccupationSearchError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestOccupationSearchError(err));
  }
};

import * as TYPES from './constants';
import {API} from 'config';
import moment from 'moment';

import {
  requestMyOpportunities,
  recieveMyOpportunities,
  requestMyOpportunitiesError,
  clearMyOpportunities,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.MY_OPPORTUNITY_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.MY_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLEAR_MY_OPPORTUNITY:
      return {
        request: false,
        error: false,
        data: null,
      };
    case TYPES.MY_OPPORTUNITY_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchMyOpportunities = callback => (dispatch, getState) => {
  dispatch(requestMyOpportunities());
  const {
    auth: {auth: {token = null, user = {}} = {}},
  } = getState();
  try {
    let endPoint = `${API.gps.opportunity}?action_type=all`;
    return services.fetchRecords(endPoint, token, true).then(res => {
      if (res) {
        //Default Sorting by Recently Added
        const sortedList = [...res].sort(
          (item1, item2) => moment(item2.apply_date) - moment(item1.apply_date),
        );
        if (callback) callback(sortedList);
        dispatch(recieveMyOpportunities(sortedList));
        return;
      }
      dispatch(requestMyOpportunitiesError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestMyOpportunitiesError(err));
  }
};

export const removeMyOpportunity = (opp_application_uuid, callback) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  try {
    let endPoint = `${API.gps.opportunity}${opp_application_uuid}`;
    services.createUpdateRecord(token, endPoint, {}, 'DELETE').then(res => {
      if (res) {
        callback && callback(res);
      }
    });
  } catch (err) {
    console.log(err);
  }
};

export const resetMyOpportunities = () => dispatch => {
  dispatch(clearMyOpportunities());
};

import * as TYPES from './constants';
import {API} from 'config';

import {requestSkills, recieveSkills, skillsError} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SKILLS_LOAD:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.SKILLS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.SKILLS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchSkills = () => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();

    let endPoint = `${API.srm.skills_new}`;
    const promise1 = services.fetchRecords(`${endPoint}?source=emi`, token);
    const promise2 = services.fetchRecords(
      `${endPoint}?source=linkedin`,
      token,
    );

    dispatch(requestSkills());
    return Promise.all([promise1, promise2]).then(values => {
      const result = [
        ...(values && values[0] ? values[0] : []),
        ...(values && values[1] ? values[1] : []),
      ];
      if (Array.isArray(result)) dispatch(recieveSkills(result));
      else dispatch(skillsError());
    });
  } catch (err) {
    console.log(err);
    dispatch(skillsError(err));
  }
};

import './style.css';
import {themeColorMode} from 'mui-core/utils';
import {styled, alpha} from '@mui/material/styles';
import {Box, Stack, Paper, Typography} from '@mui/material';

const StyledPaper = styled(Paper)(({theme, isTransparent}) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100vh',
  width: '100%',
  flexDirection: 'column',
  backgroundColor: isTransparent
    ? alpha(theme.palette.background.paper, 0.7)
    : theme.palette.background.paper,
  zIndex: 1500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  '& .spinner': {
    position: 'relative',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&>.g-d': {
      position: 'absolute',
      height: 11.2,
      backgroundColor: `${themeColorMode(theme, '#ecedff', '#353536')}`,
      width: 17,
      marginLeft: -28,
    },
    '&>.t-d': {
      position: 'absolute',
      height: 11.2,
      backgroundColor: themeColorMode(
        theme,
        theme.palette.background.paper,
        '#202020',
      ),
      width: 19,
      marginLeft: -12,
      transform: 'rotateZ(-65deg)',
      marginTop: -32,
    },
    '&>.b-d': {
      position: 'absolute',
      height: 11.2,
      backgroundColor: themeColorMode(
        theme,
        theme.palette.background.paper,
        '#202020',
      ),
      width: 19,
      marginLeft: 12,
      transform: 'rotateZ(-58deg)',
      marginBottom: -32,
      zIndex: 2,
    },
    '&::before, &::after': {
      content: "' '",
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      border: `11.2px solid ${themeColorMode(theme, '#ecedff', '#353536')}`,
      borderRadius: '50%',
      position: 'absolute',
      transform: 'rotate(30deg)',
    },
    '&::before': {
      borderRightColor: '#7960df',
      right: 'calc(50% - 5.6px)',
      animation: `${themeColorMode(
        theme,
        'spinnerrfi6tkbefore',
        'spinnerrfi6tkbeforeDark',
      )} 1.2s infinite linear`,
      animationDirection: 'reverse',
    },
    '&::after': {
      borderLeftColor: '#7960df',
      left: 'calc(50% - 5.6px)',
      animation: `${themeColorMode(
        theme,
        'spinnerrfi6tkafter',
        'spinnerrfi6tkafterDark',
      )} 1.2s infinite linear`,
    },
  },
}));

const PageLoader = ({title, description, isTransparent}) => {
  return (
    <StyledPaper isTransparent={isTransparent}>
      <Box className='spinner'>
        <Box className='g-d'></Box>
        <Box className='t-d'></Box>
        <Box className='b-d'></Box>
      </Box>
      {(title || description) && (
        <Stack mt={3} gap={1.5} alignItems='center'>
          {title && <Typography variant='h4'>{title}</Typography>}
          {description && (
            <Typography variant='body1'>{description}</Typography>
          )}
        </Stack>
      )}
    </StyledPaper>
  );
};

export default PageLoader;

import PropTypes from 'prop-types';
import searchData from 'data/search.json';
import {numberInUSFormat} from 'core/utils';
import {styled} from '@mui/material/styles';
import {useCheckMobileView} from 'core/hooks';
import {Stack, Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const StyledStack = styled(Stack)(() => ({
  button: {
    minWidth: '32px',
    padding: '12px 0px',
  },

  '@media (min-width: 320px) and (max-width: 768px)': {
    button: {
      minWidth: '24px',
      padding: '0px 0px',
    },
  },
}));

const SearchResultsCounter = ({
  isClubbedResults,
  currentLength,
  totalResults,
  request,
}) => {
  let formattedTotalResults = 0;
  if (totalResults) formattedTotalResults = numberInUSFormat(totalResults);
  if (isClubbedResults && formattedTotalResults === '10,000')
    formattedTotalResults = `${formattedTotalResults}+`;

  const {isMobileView} = useCheckMobileView();
  if (isMobileView) {
    return (
      <>
        <StyledStack
          my={2}
          direction='row'
          alignItems='center'
          color='text.secondary'>
          {request ? (
            <LoadingButton loading />
          ) : (
            <Typography mx={0.5} variant='body1'>{`${numberInUSFormat(
              currentLength,
            )}`}</Typography>
          )}
          <Typography variant='body1'>
            {` / ${formattedTotalResults}`}
          </Typography>
        </StyledStack>
      </>
    );
  }
  return (
    <>
      <StyledStack
        my={2}
        direction='row'
        alignItems='center'
        color='text.secondary'>
        <Typography variant='body1'>
          {`${searchData.showingOfResultsFor.showingTxt}`}
        </Typography>
        {request ? (
          <LoadingButton loading />
        ) : (
          <Typography mx={0.5} variant='body1'>{`(${numberInUSFormat(
            currentLength,
          )})`}</Typography>
        )}
        <Typography variant='body1'>
          {`${searchData.showingOfResultsFor.ofTxt} ${formattedTotalResults} ${searchData.showingOfResultsFor.resultsTxt}`}
        </Typography>
      </StyledStack>
    </>
  );
};

SearchResultsCounter.propTypes = {
  isClubbedResults: PropTypes.bool,
  currentLength: PropTypes.number,
  totalResults: PropTypes.number,
  request: PropTypes.bool,
};

export default SearchResultsCounter;

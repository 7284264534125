import {combineReducers} from 'redux';

import clusters from './clusters';
import pathOccupationSearch from './pathOccupationSearch';
import pathsSearch from './pathsSearch';
import awardTypes from './awardType';
import schools from './schools';
import duration from './duration';
import careerSearch from './careerSearch';

export const searchReducer = combineReducers({
  clusters,
  pathOccupationSearch,
  pathsSearch,
  awardTypes,
  schools,
  duration,
  careerSearch,
});

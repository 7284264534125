import {useState} from 'react';
import {API} from 'config';
import AddInterests from './AddInterests';
import {useSaveProfileLoader} from 'core/hooks';
import {lightGreen} from '@mui/material/colors';
import {EditProfileWrapper} from '../../components';
import {Box, Chip, Stack, Typography} from '@mui/material';
import {Button, ContentBox, RequestErrorLoader} from 'mui-core';

const PersonalInterests = ({profileData, onProfileDataSubmit}) => {
  const [addForm, setAddForm] = useState(false);
  const {request, data} = profileData;
  const {interest} = data || {};
  const [isLoading, onChange] = useSaveProfileLoader(
    request,
    onProfileDataSubmit,
  );
  const onSubmit = async tag => {
    if (tag) {
      await onChange(
        'interest',
        API.gps.student_profile,
        [...(interest ? [...interest, tag] : [])],
        'PATCH',
      );
      setAddForm(false);
    }
  };
  const onDeleteInterest = async idx => {
    let newTags = [...(interest ? interest : [])];
    newTags.splice(idx, 1);
    if (onChange) {
      await onChange(
        'interest',
        API.gps.student_profile,
        [...newTags],
        'PATCH',
      );
    }
  };
  return (
    <ContentBox variant='outlined' sx={{p: 3}}>
      <RequestErrorLoader body={{request: isLoading}} hideEmpty>
        <Typography variant='h5' color='text.primary'>
          Personal Interests
        </Typography>
        <Typography color='GrayText' mt={1} variant='body2'>
          Adding personal interests to your profile can help you showcase your
          personality, passions, and balance in life. It can help you make
          connections with potential employers or collaborators and can set you
          apart from other candidates.
        </Typography>
        {interest && (
          <Stack direction='row' my={2} flexWrap='wrap'>
            {interest &&
              interest.map((i, idx) => (
                <Chip
                  key={idx}
                  label={i}
                  onDelete={() => onDeleteInterest(idx)}
                  sx={{mb: 1, mr: 1, backgroundColor: lightGreen[400]}}>
                  {i}
                </Chip>
              ))}
          </Stack>
        )}
        {addForm && (
          <AddInterests
            interest={interest}
            onSubmit={onSubmit}
            onCancel={() => setAddForm(false)}
          />
        )}
        {!addForm && (
          <Box display={'flex'} justifyContent='center' mt={3}>
            <Button
              variant='contained'
              sx={{minWidth: 250}}
              onClick={() => setAddForm(true)}>
              Add Interests
            </Button>
          </Box>
        )}
      </RequestErrorLoader>
    </ContentBox>
  );
};
export default EditProfileWrapper(PersonalInterests);

import * as TYPES from './constants';
import {API} from 'config';
import {
  clearUserTypeList,
  requestUserTypeList,
  receiveUserTypeList,
  requestUserTypeListError,
} from './actions';
import {Services} from 'core/Services';

const services = new Services();

const initialState = {
  data: null,
  error: false,
  request: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_USER_TYPE_LIST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECEIVE_USER_TYPE_LIST:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_USER_TYPE_LIST_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.CLEAR_USER_TYPE_LIST:
      return initialState;
    default:
      return state;
  }
};

export const fetchUserTypelist = () => dispatch => {
  dispatch(requestUserTypeList());
  const endPoint = API.gps.user_type_list;
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res && Array.isArray(res) && res.length > 0) {
        const list = res.sort((a, b) => a.order - b.order);
        dispatch(receiveUserTypeList(list));
      } else {
        dispatch(requestUserTypeListError('No data available'));
      }
    });
  } catch (err) {
    console.log(err);
    dispatch(requestUserTypeListError(err));
  }
};

import {Row, Col, Popover} from 'antd';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import {useDrag} from 'react-dnd';

import {isNumber} from 'core/utils';
import {ErrorBoundary} from 'core/components';

const MANDATORY_FIELDS = ['course_id', 'title', 'description', 'units'];

const style = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const ListItem = ({
  data = {},
  itemType,
  noDrag,
  checkIfAddedToTerm,
  termIndex,
  itemIndex,
  onDeleteCourse,
  onClick,
  notCheckedForRequiredFields,
  itemLeftWidth,
  itemRightWidth,
  cautionDisplay,
  currentIndex,
  allSegments,
}) => {
  const checkAllRequiredInfo = () => {
    if (!notCheckedForRequiredFields) {
      for (let i = 0; i < MANDATORY_FIELDS.length; i++) {
        const FIELD = MANDATORY_FIELDS[i];
        if (
          data[FIELD] === undefined ||
          data[FIELD] === '' ||
          data[FIELD] === null
        ) {
          if (isNumber(data[FIELD]) && data[FIELD] == 0) {
            continue;
          }
          return false;
        }
      }
    }
    return true;
  };

  const checkForCaution = (allSegments, currentIndex, currentCourse) => {
    let preReqs = [];
    let coReqs = [];
    let isPreCaution = false;
    let isCoreqCaution = false;
    if (
      currentCourse?.pre_requisites &&
      Array.isArray(currentCourse?.pre_requisites) &&
      currentCourse?.pre_requisites?.length
    ) {
      currentCourse?.pre_requisites?.forEach(requisite => {
        allSegments &&
          allSegments.forEach((seg, segIdx) => {
            seg?.data &&
              Array.isArray(seg?.data) &&
              seg?.data?.forEach((course, courseIdx) => {
                //let currentPosition = `${segIdx}.${courseIdx}`;
                let currentArr = currentIndex.split('.');
                if (requisite === course?.course_id) {
                  if (parseFloat(currentArr[0]) <= parseFloat(segIdx)) {
                    preReqs.push(requisite);
                    isPreCaution = true;
                  }
                }
              });
          });
      });
    }
    if (
      currentCourse?.co_requisites &&
      Array.isArray(currentCourse?.co_requisites) &&
      currentCourse?.co_requisites?.length
    ) {
      currentCourse?.co_requisites.forEach(requisite => {
        allSegments?.forEach((seg, segIdx) => {
          seg?.data &&
            Array.isArray(seg?.data) &&
            seg?.data?.forEach((course, courseIdx) => {
              // segment index have to check
              let currentArr = currentIndex.split('.');
              if (requisite === course.course_id) {
                if (parseFloat(currentArr[0]) < parseFloat(segIdx)) {
                  coReqs.push(requisite);
                  isCoreqCaution = true;
                }
              }
            });
        });
      });
    }
    return {
      preReqs,
      coReqs,
      isPreCaution,
      isCoreqCaution,
    };
  };

  let cautionObj = checkForCaution(allSegments, currentIndex, data);
  let isExists = checkIfAddedToTerm && checkIfAddedToTerm(data);

  const [{isDragging}, drag] = useDrag({
    item: {
      data,
      type: 'TERMS_ITEM',
      itemType,
      termIndex,
      itemIndex,
    },
    // canDrag: () => {
    //   return checkAllRequiredInfo();
    // },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const content = (
    <div className='caution-content' style={{maxWidth: '650px'}}>
      {cautionObj?.isPreCaution && (
        <p>
          This course has the following prerequisite(s) that may be planned out
          of order: <br />
          {cautionObj.preReqs.join(', ')}
        </p>
      )}

      {cautionObj?.isCoreqCaution && (
        <p>
          This course has the following corequisite(s) that may be planned out
          of order: <br />
          {cautionObj?.coReqs.join(', ')}
        </p>
      )}
      {(cautionObj?.isCoreqCaution || cautionObj?.isPreCaution) && (
        <p>
          Please rearrange your courses or get approval from your school to take
          these courses out of order.
        </p>
      )}
    </div>
  );

  const {course_id, units} = data;

  const isDragable = checkAllRequiredInfo();

  const opacity = isDragging ? 0.4 : 1;
  const cursor =
    (isDragable && (isDragging ? 'grabbing' : 'grab')) || 'pointer';

  return (
    <ErrorBoundary nameOfComponent='core-list-item'>
      <div
        className='card-list-item'
        ref={drag}
        style={{opacity, cursor, pointerEvents: isExists ? 'none' : 'initial'}}
        // isDragable &&
        // onClick={() => onClick && onClick(data)}
      >
        {data &&
          (itemType === 'course' ||
            itemType === 'core' ||
            itemType === 'general' ||
            itemType === 'elective' ||
            itemType === 'terms-item-course-type') && (
            <Row className='courseHeader courseItem course-data'>
              <Col
                span={itemLeftWidth || 20}
                style={{...style}}
                className='course-id'
                onClick={() => onClick && onClick(data)}>
                <span style={{marginRight: '10px'}}>{course_id}</span>
                {((cautionDisplay && cautionObj?.isPreCaution) ||
                  (cautionDisplay && cautionObj?.isCoreqCaution)) && (
                  <Popover
                    overlayClassName='course-warning-popover'
                    content={content}
                    trigger='hover'>
                    <WarningOutlined style={{color: '#fdc41e'}} />
                  </Popover>
                )}
              </Col>
              <Col span={itemRightWidth || 4}>
                <Row>
                  <Col span={12}>{units || '-'}</Col>
                  <Col span={12}>
                    {onDeleteCourse &&
                      itemType === 'terms-item-course-type' && (
                        <DeleteOutlined
                          className='item-icon'
                          onClick={() => onDeleteCourse(termIndex, itemIndex)}
                        />
                      )}
                    {isExists && (
                      <CheckCircleOutlined className='item-icon checked' />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        {data && itemType === 'terms-item' && (
          <div className='item'>
            <div
              className='item-title'
              onClick={() => onClick && onClick(data)}>
              {data.course_id ||
                data.segment_alter_name ||
                data.title ||
                data.name ||
                data.segment_name}
            </div>
            {onDeleteCourse && (
              <DeleteOutlined
                className='item-icon'
                onClick={() => onDeleteCourse(termIndex, itemIndex)}
              />
            )}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default ListItem;

import {useTheme} from '@emotion/react';
import {AwardIcon} from 'mui-core/Icons';
import {CLOUDINARY} from 'core/cloudinary';
import {AdvancedImage} from '@cloudinary/react';
import {Box, Stack, Typography} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {programMarketDetailsData} from 'data/programs.json';

const {offeredByLabel} = programMarketDetailsData;

const ProgramMarketDetails = ({programData, isCriminalJustice = false}) => {
  const theme = useTheme();
  const {
    title = '',
    provider_name = '',
    award_type_name = '',
    provider_logo_cloudinary,
    program_logo_cloudinary,
    institute_details: {
      name = '',
      logo_cloudinary,
      address: {city = '', state = '', state_code = ''} = {},
    } = {},
  } = programData || {};

  const logoImage =
    (isCriminalJustice ? provider_logo_cloudinary : logo_cloudinary) || '';

  const programName = isCriminalJustice ? provider_name : name;

  return (
    <Box flexGrow={1}>
      {isCriminalJustice && program_logo_cloudinary && (
        <Box mb={1}>
          <AdvancedImage
            height={60}
            width={'auto'}
            cldImg={CLOUDINARY.getLogoImage(program_logo_cloudinary)}
            alt={`${name}-program-logo`}
          />
        </Box>
      )}
      <Typography
        title={`${title} `}
        color='text.primary'
        variant='programtitle'
        textTransform='capitalize'
        sx={{
          overflow: 'hidden',
          display: '-webkit-box',
          wordBreak: 'break-word',
          textOverflow: 'ellipsis',
          '-webkit-line-clamp': '2',
          '-webkit-box-orient': 'vertical',
        }}>
        {title}
      </Typography>
      {award_type_name && (
        <Stack color='text.primary' direction='row' mt={1} spacing={1}>
          <AwardIcon />
          <Typography color='text.primary' variant='bodyL' lineHeight='24px'>
            {award_type_name}
          </Typography>
        </Stack>
      )}
      <Stack
        mt={1}
        spacing={{xs: 1, sm: 3}}
        alignItems={{xs: 'left', sm: 'center'}}
        direction={{xs: 'column', sm: 'row'}}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='body1' color='text.secondary'>
            {offeredByLabel}
          </Typography>
          <AdvancedImage
            height={28}
            cldImg={CLOUDINARY.getLogoImage(logoImage)}
            alt={`${name}-college-logo`}
          />
          <Typography
            fontWeight={500}
            variant='body1'
            color={
              theme.palette.mode === 'light'
                ? theme.palette.secondary.constrastDark
                : theme.palette.secondary.light
            }>
            {programName}
          </Typography>
        </Stack>
        {!isCriminalJustice && (
          <Stack
            spacing={0.1}
            direction='row'
            alignItems='center'
            pb={{xs: 2, sm: 0}}
            color='text.primary'>
            <LocationOnIcon sx={{mb: '4px'}} />
            <Typography
              variant='caption'
              color='text.secondary'
              textTransform='capitalize'>
              {`${city}, ${state_code || state}`}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default ProgramMarketDetails;

import {useState, useEffect, useCallback} from 'react';
import {Typography} from '@mui/material';
import {FmdGood} from '@mui/icons-material';
import mySkillsData from 'data/mySkills.json';
import {Accordion, SkillsChip} from 'mui-core';
import {green} from '@mui/material/colors';
import {Link} from 'react-router-dom';

const MyEducationSkills = ({studentCredentials}) => {
  const {data: credentialsInfo = [], request: studentCredentialRequest} =
    studentCredentials || {};

  let educationCredentialData = [];
  credentialsInfo &&
    Array.isArray(credentialsInfo) &&
    credentialsInfo.length > 0 &&
    credentialsInfo
      .filter(element => element.credential_type === 'education')
      .forEach(res => {
        educationCredentialData.push(...res.skills);
      });

  const uniqueProgramSkills = [...new Set(educationCredentialData)];
  const [showEducationSkill, setShowEducationSkill] = useState();
  useEffect(() => {
    setTimeout(() => {
      openEducationSkills();
    }, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const openEducationSkills = useCallback(() => {
    setShowEducationSkill(showEducationSkill ? false : true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Accordion
      outlined={true}
      onChange={openEducationSkills}
      expanded={showEducationSkill}
      icon={
        <FmdGood
          fontSize='large'
          sx={{
            color: 'white',
            padding: '6px',
            borderRadius: '4px',
            marginRight: '10px',
            backgroundColor: green[400],
          }}
        />
      }
      header={mySkillsData.myEducationSkill.Heading}>
      <Typography variant='body1' color='text.primary' mb={2}>
        Education skills are acquired from Education Institutions. Education
        skills are found in your Profile under the{' '}
        <Link to='/dashboard/profile/education' underline='none'>
          Education{' '}
        </Link>
        section.
      </Typography>
      <SkillsChip
        data={{
          data: uniqueProgramSkills,
          request: studentCredentialRequest,
        }}
        backgroundColor={green[400]}
        color='primary.contrastText'
      />
    </Accordion>
  );
};
export default MyEducationSkills;

import * as TYPES from './constants';
import {API} from 'config';
import {
  requestToBecomeBusinessPartner,
  clearBecomeBusinessPartnerResponse,
  requestToBecomeBusinessPartnerError,
  receiveBecomeBusinessPartnerResponse,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, error: false, data: null};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_TO_BECOME_BUSINESS_PARTNER:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECEIVE_BECOME_BUSINESS_PARTNER_RESPONSE:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_TO_BECOME_BUSINESS_PARTNER_ERROR:
      return {
        ...state,
        request: false,
        error: true,
        data: action.payload,
      };
    case TYPES.CLEAR_BECOME_BUSINESS_PARTNER_RESPONSE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const becomeBusinessPartner = data => async dispatch => {
  try {
    let endPoint = `${API.srm.become_business_partner}`;
    dispatch(requestToBecomeBusinessPartner());
    return services
      .createUpdateRecord(null, endPoint, data, 'POST')
      .then(res => {
        if (res?.Status) {
          dispatch(receiveBecomeBusinessPartnerResponse(res.Status));
        } else {
          dispatch(
            requestToBecomeBusinessPartnerError(
              res?.Error ? res.Error : 'Error',
            ),
          );
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(requestToBecomeBusinessPartnerError('Error'));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestToBecomeBusinessPartnerError(err));
  }
};

export const clearBecomeBusinessPartnerData = () => dispatch => {
  dispatch(clearBecomeBusinessPartnerResponse());
};

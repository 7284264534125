import {useMemo} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from '@mui/material';
import {ErrorBoundary} from 'mui-core';
import {styled} from '@mui/material/styles';
import {useHistory} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 14,
  [`&.${linearProgressClasses.colorSecondary}`]: {
    backgroundColor: theme.palette.divider,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 14,
    backgroundColor: theme.palette.primary.main,
  },
}));
const StyledTableCell = styled(TableCell)(({theme}) => ({
  '&:hover': {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
}));

const SurveyDetails = ({clusterData}) => {
  const history = useHistory();

  const calcPerc = count => {
    const high = clusterData[0].count;
    const res = ((100 * count) / high).toFixed(0);
    return res == 0 ? '4' : res;
  };
  const navigateToCluster = cluster => {
    let url = `/search?cluster_name=${encodeURIComponent(cluster?.title)}`;
    history.push(url);
  };
  const TableData = useMemo(() => {
    return clusterData && clusterData.length > 0 && Array.isArray(clusterData)
      ? clusterData?.filter(i => i.count !== 0)
      : [][clusterData];
  });

  return (
    <>
      <ErrorBoundary nameOfComponent='core-career-interest-survey-details'>
        <TableContainer>
          <Table sx={{minWidth: 650}} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Career Area</TableCell>
                <TableCell>Results</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {TableData?.map(result => (
                <TableRow>
                  <TableCell width='5%'>{result.rank}</TableCell>
                  <StyledTableCell
                    width='40%'
                    onClick={() => navigateToCluster(result)}>
                    {result.title}
                  </StyledTableCell>
                  <TableCell width='60%'>
                    <Stack direction='row' alignItems='center'>
                      <BorderLinearProgress
                        color='secondary'
                        variant='determinate'
                        sx={{width: `${calcPerc(result.count)}%`}}
                      />
                      <Typography ml={2}>{result.count}</Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ErrorBoundary>
    </>
  );
};
export default SurveyDetails;

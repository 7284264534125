import * as TYPES from './constants';

// Local Jobs
export const requestLocalJobs = () => ({
  type: TYPES.LOCAL_JOBS_REQUEST,
});
export const recieveLocalJobs = (json, loadMore = false) => ({
  type: TYPES.LOCAL_JOBS_SUCCESS,
  payload: json,
  loadMore,
});
export const requestLocalJobsError = err => ({
  type: TYPES.LOCAL_JOBS_ERROR,
  err,
});
export const clearLocalJobs = () => ({
  type: TYPES.CLEAR_LOCAL_JOBS,
});

// Public Insight Jobs
export const requestPublicInsightJobs = () => ({
  type: TYPES.PUBLIC_INSIGHT_JOBS_REQUEST,
});
export const recievePublicInsightJobs = (json, loadMore = false) => ({
  type: TYPES.PUBLIC_INSIGHT_JOBS_SUCCESS,
  payload: json,
});
export const requestPublicInsightJobsError = err => ({
  type: TYPES.PUBLIC_INSIGHT_JOBS_ERROR,
  err,
});
export const clearPublicInsightJobs = () => ({
  type: TYPES.CLEAR_PUBLIC_INSIGHT_JOBS,
});

// Third Party Jobs
export const requestJobBoards = () => ({
  type: TYPES.JOB_BOARDS_REQUEST,
});
export const recieveJobBoards = json => ({
  type: TYPES.JOB_BOARDS_SUCCESS,
  payload: json,
});
export const requestJobBoardsError = err => ({
  type: TYPES.JOB_BOARDS_ERROR,
  err,
});
export const clearJobBoards = () => ({
  type: TYPES.CLEAR_JOB_BOARDS,
});

// Third Party Jobs Details

export const requestJobBoardsDetails = () => ({
  type: TYPES.JOB_BOARDS_DETAILS_REQUEST,
});
export const recieveJobBoardsDetails = json => ({
  type: TYPES.JOB_BOARDS_DETAILS_SUCCESS,
  payload: json,
});
export const requestJobBoardsDetailsError = err => ({
  type: TYPES.JOB_BOARDS_DETAILS_ERROR,
  err,
});
export const clearJobBoardsDetails = () => ({
  type: TYPES.CLEAR_JOB_BOARDS_DETAILS,
});

// Applied Jobs

export const requestJobApplied = () => ({
  type: TYPES.JOB_APPLIED_REQUEST,
});
export const recieveJobJobApplied = json => ({
  type: TYPES.JOB_APPLIED_SUCCESS,
  payload: json,
});
export const requestJobAppliedError = err => ({
  type: TYPES.JOB_APPLIED_ERROR,
  err,
});
export const clearJobApplied = () => ({
  type: TYPES.CLEAR_APPLIED_DETAILS,
});

// applay Jobs

export const requestJobApply = () => ({
  type: TYPES.JOB_APPLY_REQUEST,
});
export const recieveJobJobApply = json => ({
  type: TYPES.JOB_APPLY_SUCCESS,
  payload: json,
});
export const requestJobApplyError = err => ({
  type: TYPES.JOB_APPLY_ERROR,
  err,
});
// Saved Jobs

export const requestJobSaved = () => ({
  type: TYPES.JOB_SAVED_REQUEST,
});
export const recieveJobSaved = json => ({
  type: TYPES.JOB_SAVED_SUCCESS,
  payload: json,
});
export const requestJobSavedError = err => ({
  type: TYPES.JOB_SAVED_ERROR,
  err,
});
export const clearJobSaved = () => ({
  type: TYPES.CLEAR_JOB_SAVED,
});
// SAVE Jobs

export const requestJobSave = () => ({
  type: TYPES.JOB_SAVE_REQUEST,
});
export const recieveJobSave = json => ({
  type: TYPES.JOB_SAVE_SUCCESS,
  payload: json,
});
export const requestJobSaveError = err => ({
  type: TYPES.JOB_SAVE_ERROR,
  err,
});

//al jobs
export const requestAllJobs = () => ({
  type: TYPES.ALL_JOBS_REQUEST,
});
export const recieveAllJobs = json => ({
  type: TYPES.ALL_JOBS_SUCCESS,
  payload: json,
});
export const requestAllJobsError = err => ({
  type: TYPES.ALL_JOBS_ERROR,
  err,
});
export const clearAllJobs = err => ({
  type: TYPES.CLEAR_ALL_JOBS,
  err,
});

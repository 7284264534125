import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollReset = ({children, config}) => {
  const {top = 0, behavior = 'auto'} = config || {};
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo({
      top,
      behavior,
    });
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps
  return children;
};

export default ScrollReset;

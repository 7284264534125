import React, {useState, useEffect} from 'react';
import {API} from 'config';
import AddAwards from './AddAwards';
import {useSnackbar} from 'notistack';
import {styled} from '@mui/material/styles';
import {useCheckMobileView} from 'core/hooks';
import {Grid, Button, MUIList} from 'mui-core';
import {Edit, Delete} from '@mui/icons-material';
import {awardsData} from 'data/settings-profile.json';
import {Box, Stack, Divider, IconButton, Typography} from '@mui/material';

const StyledBox = styled(Box)(() => ({
  ul: {
    padding: '0px',
    li: {
      alignItems: 'baseline',
      '> div': {
        svg: {
          fontSize: '12px',
        },
        width: '24px',
        height: '24px',
        background: 'transparent',
      },
    },
  },
}));

const AwardsList = ({
  data,
  onChange,
  viewOnly,
  awardTypes,
  fetchAwardTypes,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const {isMobileView} = useCheckMobileView();
  const [editIndex, setEditIndex] = useState(-1);
  const {addItemBtn, additionalInformation} = awardsData || {};
  const {awards_leadership_projects_publications: awards} = data || {};
  const [isAdd, setAdd] = useState(awards?.length === 0 ? true : false);

  useEffect(() => {
    (!awardTypes?.data || !awardTypes?.data?.length) &&
      !awardTypes?.request &&
      fetchAwardTypes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (awards?.length === 0) setAdd(true);
    else setAdd(false);
  }, [awards]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatData = (award, id) => {
    const data = {...award};
    if (
      award?.additional_info &&
      Array.isArray(award?.additional_info) &&
      award.additional_info.length > 0
    ) {
      data['additional_info'] = award?.additional_info
        .map(i => i.additional_info)
        .filter(item => {
          if (item) return item;
        });
    }
    if (id) {
      data['award_info_uuid'] = id;
    }
    return data;
  };

  const cancelAddEdit = () => {
    setAdd(false);
    setEditIndex(-1);
  };

  const editData = idx => {
    setAdd(false);
    setEditIndex(idx);
  };

  const getAwardType = id => {
    const {data: awardTypesData = {}} = awardTypes || {};
    if (Array.isArray(awardTypesData)) {
      const idx = awardTypesData.map(i => i.item_type_uuid).indexOf(id);
      return awardTypesData[idx]?.item_type;
    } else {
      return id;
    }
  };

  const onAddAward = async item => {
    if (onChange) {
      const resp = await onChange(
        false,
        API.gps.awards_leadership,
        formatData(item),
        'POST',
        false,
      );
      cancelAddEdit();
      if (resp?.item_title === item?.item_title) {
        enqueueSnackbar('Award added successfully.', {
          variant: 'success',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      } else {
        enqueueSnackbar('Something went wrong, please try again.', {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    }
  };

  const onEditAward = async (item, id) => {
    if (onChange) {
      const resp = await onChange(
        false,
        API.gps.awards_leadership,
        formatData(item, id),
        'PATCH',
        id,
      );
      cancelAddEdit();
      if (resp?.item_title === item?.item_title) {
        enqueueSnackbar('Award saved successfully.', {
          variant: 'success',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      } else {
        enqueueSnackbar('Something went wrong, please try again.', {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    }
  };

  const onDeleteAward = async award => {
    if (onChange) {
      const resp = await onChange(
        false,
        API.gps.awards_leadership,
        {},
        'DELETE',
        award.award_info_uuid,
      );
      cancelAddEdit();
      if (resp?.Success) {
        enqueueSnackbar(resp.Success, {
          variant: 'success',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      } else {
        enqueueSnackbar('Something went wrong, please try again.', {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    }
  };

  return (
    <Stack>
      {awards &&
        Array.isArray(awards) &&
        awards?.length > 0 &&
        awards?.map((award, idx) => {
          let getAdditionalInfos = [];
          award.comments &&
            award.comments
              .sort((a, b) => a.display_order - b.display_order)
              .map(comment => getAdditionalInfos.push(comment.additional_info));
          return editIndex === idx ? (
            <Box key={idx}>
              <AddAwards
                data={award}
                edit={true}
                awardTypes={awardTypes}
                onCancel={cancelAddEdit}
                onSubmit={newAward =>
                  onEditAward(newAward, award?.award_info_uuid)
                }
              />
              <Divider sx={{my: 2}} />
            </Box>
          ) : (
            <Box key={idx}>
              <Grid
                sx={{
                  paddingLeft: {md: '48px', sm: '48px', lg: '48px', xs: '0px'},
                }}
                py={3}
                container
                spacing={2}>
                <Grid item xs={12} py={0}>
                  <div>
                    <Grid container p={0} alignItems='center'>
                      <Grid item xs={9} sm={9} md={8} lg={9}>
                        <Typography
                          variant='h5'
                          color='text.primary'
                          // fontFamily='fontFamilyBold'
                          fontSize={{xs: 12, sm: 14, md: 18}}
                          title={`${getAwardType(award.item_type)}: ${
                            award.item_title
                          }`}>
                          {`${getAwardType(award.item_type)}: ${
                            award.item_title
                          }`}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={4} lg={3} p={0}>
                        {!viewOnly && (
                          <Stack direction='row'>
                            {isMobileView ? (
                              <>
                                <IconButton onClick={() => editData(idx)}>
                                  <Edit
                                    sx={{fontSize: {xs: 16, sm: 18}}}
                                    color='primary'
                                  />
                                </IconButton>
                                <IconButton
                                  onClick={() => onDeleteAward(award)}>
                                  <Delete
                                    sx={{fontSize: {xs: 16, sm: 18}}}
                                    color='primary'
                                  />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <Button
                                  size='large'
                                  onClick={() => editData(idx)}>
                                  Edit
                                </Button>
                                <Button
                                  size='large'
                                  onClick={() => onDeleteAward(award)}>
                                  Delete
                                </Button>
                              </>
                            )}
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} py={0}>
                  <Typography
                    variant='h6'
                    color='text.primary'
                    fontSize={{xs: 12, sm: 14, md: 16}}>
                    {additionalInformation.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={9} py={0}>
                  <StyledBox>
                    <MUIList
                      isBubble
                      emptyMsg='-'
                      fontSize={16}
                      list={getAdditionalInfos}
                    />
                  </StyledBox>
                </Grid>
              </Grid>
              <Divider />
            </Box>
          );
        })}
      {!viewOnly && (
        <Box py={3}>
          {isAdd && (
            <AddAwards
              onSubmit={onAddAward}
              awardTypes={awardTypes}
              onCancel={cancelAddEdit}
              firstTime={awards.length === 0}
            />
          )}
          {!isAdd && editIndex === -1 && (
            <Button
              variant='contained'
              onClick={() => setAdd(true)}
              size={isMobileView ? 'small' : 'large'}>
              {addItemBtn}
            </Button>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default AwardsList;

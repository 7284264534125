import * as TYPES from './constants';
import {API} from 'config';

import {requestUserData, recieveUserData, errorUserData} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {
  request: false,
  data: {},
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_USER:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.SUCCESS_USER:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.ERROR_USER:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchUserData = () => (dispatch, getState) => {
  try {
    const {
      auth: {auth: {user, token = null} = {}},
    } = getState();
    if (token && user) {
      dispatch(requestUserData());
      return services
        .fetchRecords(
          `${API.gps.student_details}?cognito_username=${user['cognito:username']}`,
          token,
        )
        .then(res => {
          if (res && res.student_uuid) {
            dispatch(recieveUserData(res));
            return res;
          }
        });
    }
  } catch (err) {
    console.log(err);
    dispatch(errorUserData(err));
  }
};

export const updateUserProfile = (bodyData, callback) => (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {user, token = null} = {}},
      profile: {
        user: {data},
      },
    } = getState();
    if (token && data) {
      dispatch(requestUserData());
      const endPoint = `${API.gps.student_details}/${data.student_uuid}/`;
      return services.updateStatus(token, endPoint, bodyData).then(res => {
        if (res && res.student_uuid) {
          if (callback) callback(true);
          dispatch(fetchUserData());
        }
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteUserProfile = (bodyData, callback) => (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
      profile: {
        user: {data},
      },
    } = getState();
    if (token && data) {
      dispatch(requestUserData());
      const endPoint = `${API.gps.delete_user_profile}/${data.student_uuid}`;
      return services.deleteRecord(token, endPoint, bodyData).then(res => {
        dispatch(fetchUserData());
        if (callback) callback(res);
      });
    }
  } catch (error) {
    console.log(error);
  }
};
